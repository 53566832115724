import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import EditOffIcon from "@mui/icons-material/EditOff";
// import ClearIcon from '@mui/icons-material/Clear';
// const props.days = [
//   { dayName: 'Monday', open: '10:00', close: '22:00' },
//   { dayName: 'Tuesday', open: '10:00', close: '22:00' },
//   { dayName: 'Wednesday', open: '10:00', close: '22:00' },
//   { dayName: 'Thursday', open: '10:00', close: '22:00' },
//   { dayName: 'Friday', open: '9:00', close: '23:00' },
//   { dayName: 'Saturday', open: '8:00', close: '23:00' },
//   { dayName: 'Sunday', open: '8:00', close: '20:00' }
// ];

export default function TableRow(props) {
  const [state, setState] = React.useState(props.row);
  const [editMode, setEditMode] = React.useState(false);

  useEffect(() => {
    setState(props.row);
  }, [props.row]);

  useEffect(() => {
    setEditMode(false);
  }, [props.column_info]);

  function handleEdit(index) {
    setEditMode(true);
  }

  function handleDelete() {
    const confirm = window.confirm("Are you sure you want to delete this row?");
    if (confirm) {
      handleSubmit("delete");
    }
  }

  function handleSubmit(edit_type) {
    setEditMode(false);
    props.edit_settings(
      props.put_api,
      props.get_api,
      props.table,
      state,
      edit_type
    );
  }

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function handleCancelEdit() {
    setState(props.row);
    setEditMode(false);
  }

  return (
    <tr className="w-full h-0.5 border-b border-gray-300" key={props.index}>
      {editMode
        ? props.column_info.map((val, i) => {
            return val.column_name === "action_button" ? (
              <td
                className={
                  "relative whitespace-nowrap py-2 text-right text-sm font-medium " +
                  val.header_css
                }
              >
                <button
                  className="px-2 py-1 text-white bg-blue-900 rounded"
                  onClick={() => handleSubmit("edit")}
                  style={{ fontSize: 12 }}
                >
                  Save
                </button>
                <EditOffIcon
                  style={{
                    marginLeft: "10px",
                    marginRight: "-5px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCancelEdit()}
                />
              </td>
            ) : (
              <td
                className={
                  "whitespace-nowrap py-2 text-sm font-medium text-gray-500 " +
                  val.header_css
                }
              >
                {val.column_type === "select" ? (
                  <FormControl size="small">
                    <Select
                      name={val.column_name}
                      value={state[val.column_name]}
                      disabled={val.disabled}
                      onChange={(event) => handleChange(event)}
                      style={{ fontSize: 10 }}
                    >
                      {Object.keys(val.column_select_options).map(
                        (key, ind) => {
                          return (
                            <MenuItem value={key} style={{ fontSize: 12 }}>
                              {val.column_select_options[key]}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    inputProps={{ style: { fontSize: 10 } }}
                    size="small"
                    name={val.column_name}
                    value={state[val.column_name]}
                    disabled={val.disabled}
                    onChange={(event) => handleChange(event)}
                  />
                )}
              </td>
            );
          })
        : props.column_info.map((val, i) => {
            return val.column_name === "action_button" ? (
              <td
                className={
                  "relative whitespace-nowrap py-2 text-right text-sm font-medium " +
                  val.header_css
                }
              >
                <button onClick={() => handleEdit(i)}>
                  <EditIcon />
                </button>
                <button className="ml-4" onClick={() => handleDelete()}>
                  <ClearIcon />
                </button>
              </td>
            ) : (
              <td
                className={
                  "whitespace-nowrap py-2 text-xs font-medium text-gray-500 " +
                  val.header_css
                }
              >
                {val.column_type === "select"
                  ? val.column_select_options[state[val.column_name]]
                  : state[val.column_name]}
              </td>
            );
          })}
    </tr>
  );
}
/*
<td className="whitespace-nowrap pl-4 py-4 text-sm font-medium text-gray-900">
{day.dow}
</td>
<td className="whitespace-nowrap py-4 text-sm text-gray-500">{day.open_time}</td>
<td className="whitespace-nowrap py-4 text-sm text-gray-500">{day.close_time}</td>
<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium">
<button onClick={() => handleEdit(index)}><EditIcon /></button>
</td>
*/
/*
  <div className="h-80">
            {props.formVisible && props.days.length < 8 &&
              <div>
                <div className="w-full h-0.5 border-b border-gray-300"></div>
                <div className="w-full h-50 mt-4 flex">
                    <div className="w-4/12 pl-4">
                      <TextField
                        sx={{ width: "80%" }}
                        size="small"
                        id="outlined-dayname"
                        label="DayName"
                        value={dayName}
                        onChange={event => setDayName(event.target.value)}
                        disabled
                      />
                    </div>
                    <div className="w-4/12">
                      <TextField
                        sx={{ width: "80%" }}
                        size="small"
                        id="outlined-open"
                        label="Open"
                        value={open}
                        onChange={event => setOpen(event.target.value)}
                      />
                    </div>
                    <div className="w-3/12">
                      <TextField
                        sx={{ width: "80%" }}
                        size="small"
                        id="outlined-close"
                        label="Close"
                        value={close}
                        onChange={event => setClose(event.target.value)}
                      />
                    </div>
                    <div className="w-1/12 flex justify-end pr-4">
                      <button className="px-3 py-2 text-white bg-blue-900 rounded" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
              </div>
              }
            </div>
*/
