import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingButton from "@mui/lab/LoadingButton";
import { text_to_num } from "../../../../../helpers/helpers";
import DrawerHeader from "../../../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonOutlined from "../../../requests/shared/helpers/ButtonOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { generateTimeOptions } from "../../../../../helpers/helpers";
import { PurpleSwitch } from "../../../../../helpers/helpers";





function HoursOfOpsButton(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [savingLoading, setSavingLoading] = React.useState(false);

  const [hooDict, setHooDict] = React.useState(props.hoo);
  const [hooDictString, setHooDictString] = React.useState({
    open_time: props.get_t_str(props.hoo?.open_time),
    close_time: props.get_t_str(props.hoo?.close_time),
  });
  const [defaultChecked, setDefaultChecked] = React.useState(
    props.default_checked
  );
  const [isClosed, setIsClosed] = React.useState(
    props.hoo?.open_time === 0 && props.hoo?.close_time === 0
  );

  const handleChange = (evt) => {
    const value = evt.target.value;
    setHooDictString({
      ...hooDictString,
      [evt.target.name]: value,
    });
    setHooDict({
      ...hooDict,
      [evt.target.name]: text_to_num(value),
    });
  };

  function handleCheckChange(e) {
    const { name, checked } = e.target;
    setDefaultChecked(checked);
  }

  const handleClosedToggle = (event) => {
    const checked = event.target.checked;
    setIsClosed(checked);

    if (checked) {
      // Set both times to 0 when marked as closed
      setHooDict({
        ...hooDict,
        open_time: 0,
        close_time: 0,
      });
      setHooDictString({
        open_time: props.get_t_str(0),
        close_time: props.get_t_str(0),
      });
    } else {
      // Reset to default business hours or some reasonable default
      const defaultOpen = 9;
      const defaultClose = 17;
      setHooDict({
        ...hooDict,
        open_time: defaultOpen,
        close_time: defaultClose,
      });
      setHooDictString({
        open_time: props.get_t_str(defaultOpen),
        close_time: props.get_t_str(defaultClose),
      });
    }
  };

  const checkIfValid = () => {
    let valid = true;
    if (hooDict?.close_time < hooDict?.open_time) {
      valid = false;
    }
    if (hooDict?.close_time - hooDict?.open_time > 24) {
      valid = false;
    }
    return valid;
  };

  function handleSubmit() {
    props.edit_hoo(hooDict, defaultChecked);
    props.view === "weekly" ? props.handleClose() : handleClose();
  }

  React.useEffect(() => {
    setDefaultChecked(props.default_checked)
    setHooDict(props.hoo)
    setHooDictString({
      open_time: props.get_t_str(props.hoo?.open_time),
      close_time: props.get_t_str(props.hoo?.close_time),
    })
  }, [open])


  return (
    <div>
      {props.view === "daily" ?
        <div className="w-48 px-4 py-3 rounded-xl border border-slate-100 relative text-slate-500">
          <div
            className="absolute right-1 top-1 cursor-pointer"
            onClick={() => handleOpen()}
          >
            <IconButton style={{ borderRadius: "8px" }}>
              <EditIcon style={{ fontSize: "16px" }} />
            </IconButton>
          </div>
          <h4 className="text-[12px] text-slate-500">Operating Hours</h4>
          {props.hoo?.open_time !== undefined && props.hoo?.close_time !== undefined && !(props.hoo?.open_time === 0 && props.hoo?.close_time === 0) ?
            <p className="text-[14px] text-slate-900 mt-1">
              {props.get_t_str(props.hoo?.open_time)}{" "}<span><ArrowForwardIcon style={{ fontSize: "14px", marginBottom: "2px" }} /></span>{" "}{props.get_t_str(props.hoo?.close_time)}
            </p> :
            <p className="text-[14px] text-slate-900 mt-1">
              Closed
            </p>
          }
        </div> :
        // <div
        //   className="px-4 py-2 hover:bg-slate-50 cursor-pointer flex items-center space-x-3"
        //   onClick={() => {
        //     handleOpen()
        //   }}
        // >
        //   <div className="text-slate-600">
        //     <WatchLaterIcon
        //       style={{ fontSize: "18px", marginBottom: "2px" }}
        //     />
        //   </div>
        //   <p className="text-sm">Adjust operating hours</p>
        // </div>
        <div></div>
      }
      <Drawer
        open={props.view === "weekly" ? props.open : open}
        anchor="right"
        onClose={props.view === "weekly" ? props.handleClose : handleClose}
      >
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader title="Adjust Hours of Operations" onClose={props.view === "weekly" ? props.handleClose : handleClose} />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-slate-500 text-sm">Date</p>
                <p className="text-slate-900 text-xl mt-1">{props.date_name}</p>
              </div>
              <div className="mt-5">
                <p className="text-slate-500 text-sm">Current hours</p>
                <p className="text-slate-900 text-xl mt-1">{(props.hoo?.open_time !== undefined && props.hoo?.close_time !== undefined && !(props.hoo?.open_time === 0 && props.hoo?.close_time === 0)) ? (props.get_t_str(props.hoo?.open_time) + " - " + props.get_t_str(props.hoo?.close_time)) : "Closed"}</p>
              </div>
              <div className="mt-5">
                <Divider />
              </div>
              <div className="mt-5">
                {!isClosed ?
                <div>
                  <h4 className="font-bold text-slate-900">Select new hours</h4>
                    <div className="flex items-center mt-4">
                      <div className="w-[45%]">
                        <p className="text-slate-500 text-sm">Open time</p>
                        <div className="mt-2 w-full">
                          <Select
                            fullWidth
                            value={hooDictString?.open_time}
                            name="open_time"
                            onChange={handleChange}
                            size="small"
                            sx={{
                              borderRadius: "8px",
                              paddingY: "5px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            }}
                          >
                            {generateTimeOptions(props.start_qhnum).map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="w-full flex-1">
                        <div className="mt-7 text-slate-500 flex items-center justify-center">
                          <ArrowForwardIcon />
                        </div>
                      </div>
                      <div className="w-[45%]">
                        <p className="text-slate-500 text-sm">Close time</p>
                        <div className="mt-2 w-full">
                          <Select
                            fullWidth
                            value={hooDictString?.close_time}
                            name="close_time"
                            onChange={handleChange}
                            size="small"
                            sx={{
                              borderRadius: "8px",
                              paddingY: "5px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            }}
                          >
                            {generateTimeOptions(props.start_qhnum).map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div> :
                  <h4 className="font-bold text-slate-900">Select new hours</h4>
                }
                <div className="mt-4 flex items-center space-x-2">
                  <PurpleSwitch
                    size="small"
                    checked={isClosed}
                    onChange={handleClosedToggle}
                  />
                  <p className="text-sm text-slate-500">Set as Closed</p>
                </div>
              </div>
              <div className="mt-7">
                <Divider />
              </div>
              <div className="w-full mt-5 flex justify-between">
                <div>
                  <h4 className="font-bold text-slate-900">Set as default for {props.dow_name}</h4>
                  <p className="text-sm text-slate-500 mt-1.5">
                    {(hooDict?.open_time !== undefined && hooDict?.close_time !== undefined && !(hooDict?.open_time === 0 && hooDict?.close_time === 0)) ? (props.get_t_str(hooDict?.open_time) + " - " + props.get_t_str(hooDict?.close_time)) : "Closed"}
                  </p>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="defaultChecked"
                        checked={defaultChecked}
                        onChange={handleCheckChange}
                        sx={{
                          color: "#6d28d9",
                          '&.Mui-checked': {
                            color: "#6d28d9",
                          },
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Save Changes
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={props.view === "weekly" ? props.handleClose : handleClose} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default HoursOfOpsButton;
