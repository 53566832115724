import React from 'react'
import TabsThree from '../../shared/helpers/TabsThree'
import TabsTwo from '../../shared/helpers/TabsTwo'
import ShiftPoolRequestsTable from '../../manager/shift_pool/ShiftPoolRequestsTable'
import ShiftPoolApprovedTable from '../../manager/shift_pool/ShiftPoolApprovedTable'
import ShiftPoolTable from '../../shared/shiftswaps/ShiftPoolTable'
import ShiftsTableEmployee from './ShiftsTableEmployee'
import PendingTableEmployee from './PendingTableEmployee'
import RequestHeader from '../../shared/helpers/RequestHeader'

export default function ShiftPoolDisplayEmployee(props) {

  const [activeTab, setActiveTab] = React.useState('Shift Pool')

  function handleTabButtonPress(target) {
    setActiveTab(target)
  }

  return (
    <div>
      <div className="w-1/2 mt-12 px-6">
        <TabsTwo
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Shift Pool"
          tab2="Requests"
        />
      </div>
      <div className="w-full mt-2">
        {
          activeTab === 'Shift Pool' ?
            <ShiftPoolTable
              shiftPoolData={props.shiftPoolData}
              create_pickup_request={props.create_pickup_request}
              rescind_pickup_shift={props.rescind_pickup_shift}
            /> :
          activeTab === 'Requests' ?
            <PendingTableEmployee
              shiftPoolData={props.shiftPoolData}
              shiftPickupResponses={props.shiftPickupResponses}
              rescind_drop_shift={props.rescind_drop_shift}
              rescind_trade_shift={props.rescind_trade_shift}
              rescind_pickup_shift={props.rescind_pickup_shift}
              respond_to_trade_request={props.respond_to_trade_request}
              archive_shift_change_request={props.archive_shift_change_request}
              myId={props.myId}
              myUserId={props.myUserId}
            /> :
          <div></div>
        }
      </div>
    </div>
  )
}
