import React from "react";
import TextField from "@mui/material/TextField";
import KeyIcon from "@mui/icons-material/Key";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import { API_PWD_RESET_CONFIRM } from "../constants";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from "@mui/material/Button";

function ForgotPasswordChange({
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  setPasswordResetFlow,
  uid,
  token,
}) {
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleResetPassword = async () => {
    try {
      // Verify that newPassword and confirmPassword are the same
      if (newPassword !== confirmPassword) {
        alert("Passwords do not match");
        console.error("Passwords do not match");
        // Handle error accordingly
        return;
      }

      // Call the API to set the new password
      // Need to get uid and token from url
      try {
        await axios.post(API_PWD_RESET_CONFIRM, {
          new_password1: newPassword,
          new_password2: confirmPassword,
          uid: uid,
          token: token,
        });
        setPasswordResetFlow(4);
      } catch (error) {
        alert(
          "New password not accepted, please ensure it is at least 8 letters, is not a common password, and is not too close to your username."
        );
      }
    } catch (error) {
      console.error("Error setting new password", error);
      // Handle error accordingly
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="w-96 flex flex-col items-center justify-center">
        <div className="h-24 w-24 bg-violet-50 flex items-center justify-center rounded-full">
          <div className="h-16 w-16 bg-violet-100 flex items-center justify-center rounded-full text-violet-700">
            <KeyIcon style={{ fontSize: 40 }} />
          </div>
        </div>
        <div className="mt-5 flex flex-col justify-center items-center">
          <h3 className="text-4xl text-slate-900">Set New Password</h3>
          <p className="text-slate-500 mt-4 text-lg text-center">
            Your password must be different than previously used passwords
          </p>
        </div>
        <div className="w-full mt-8">
          <div>
            <p className="text-slate-500 text-sm">New Password</p>
            <div className="mt-1.5">
              <TextField
                variant="outlined"
                // margin="normal"
                required
                fullWidth
                name="new-password"
                // label="Password"
                placeholder="Enter new password"
                type={showPassword ? "text" : "password"}
                id="new-password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                autoComplete="new-password"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px !important",
                    height: "55px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                    backgroundColor: "white"
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityIcon style={{ fontSize: "22px" }} /> : <VisibilityOffIcon style={{ fontSize: "22px" }} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </div>
          <p className="text-slate-500 mt-1 text-sm">
            Must be a minimum of 8 letters
          </p>
        </div>
        <div className="w-full mt-8">
          <div>
            <p className="text-slate-500 text-sm">New Password</p>
            <div className="mt-1.5">
              <TextField
                variant="outlined"
                // margin="normal"
                required
                fullWidth
                name="confirm-password"
                // label="Password"
                placeholder="Confirm new password"
                type={showPassword2 ? "text" : "password"}
                id="confirm-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                autoComplete="new-password"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px !important",
                    height: "55px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                    backgroundColor: "white"
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                      >
                        {showPassword2 ? <VisibilityIcon style={{ fontSize: "22px" }} /> : <VisibilityOffIcon style={{ fontSize: "22px" }} />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-9">
          <Button
            onClick={handleResetPassword}
            fullWidth
            variant="contained"
            //color="primary"
            sx={{
              textTransform: "none",
              borderRadius: "50px",
              paddingY: "14px",
              backgroundColor: "#6d28d9",
              "&:hover": {
                backgroundColor: "#5b21b6",
              },
              fontSize: "16px",
            }}
          >
            <span style={{ color: "white" }}>Reset Password</span>
          </Button>
        </div>
        <button
          className="mt-4 py-4 px-6 text-slate-500 hover:text-slate-900"
          onClick={() => setPasswordResetFlow(0)}
        >
          <KeyboardBackspaceIcon /> Back to log in
        </button>
      </div>
    </div>
  );
}

export default ForgotPasswordChange;
