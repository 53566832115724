import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useHistory } from "react-router-dom";

const ForgotPasswordSuccess = ({ setPasswordResetFlow }) => {
  const history = useHistory();

  const handlePasswordDone = () => {
    setPasswordResetFlow(0);
    // Once done, navigate to the desired URL (for example, the homepage)
    history.push("/");
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="w-96 flex flex-col items-center justify-center">
        <div className="h-24 w-24 bg-green-50 flex items-center justify-center rounded-full">
          <div className="h-16 w-16 bg-green-100 flex items-center justify-center rounded-full text-green-700">
            <CheckCircleOutlineIcon style={{ fontSize: 40 }} />
          </div>
        </div>
        <div className="mt-5 flex flex-col justify-center items-center">
          <h3 className="text-4xl text-slate-900">Password Reset</h3>
          <p className="text-slate-500 mt-8 text-lg">
            Your password has been successfully reset
          </p>
          <p className="text-slate-500 mt-2 text-lg">
            You can now log in with your new password
          </p>
        </div>
        <button
          className="w-full bg-primary hover:bg-blue-900 rounded text-white py-4 mt-8"
          onClick={handlePasswordDone}
        >
          Continue
        </button>
        <button
          className="mt-4 py-4 px-6 text-slate-500 hover:text-slate-900"
          onClick={handlePasswordDone}
        >
          <KeyboardBackspaceIcon /> Back to log in
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
