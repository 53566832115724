import React from "react";
import { ScheduleCoverageGraphD3 } from "./ScheduleCoverageGraphD3";
import {
  generateLaborSummary,
  get_t_str,
} from "../../../../../helpers/helpers";

export function ScheduleCoverageGraph({
  forecasted_labor,
  scheduled_labor,
  chartid,
  minOpenTime,
  maxCloseTime,
  roleType,
}) {
  const containerRef = React.useRef(null);

  function getHeatMapColor(buffer, labor_needed) {
    if (labor_needed === 0) {
      return "gray";
    } else if (buffer <= -6) {
      return "blue6";
    } else if (buffer === -5) {
      return "blue5";
    } else if (buffer === -4) {
      return "blue4";
    } else if (buffer === -3) {
      return "blue3";
    } else if (buffer === -2) {
      return "blue2";
    } else if (buffer === -1) {
      return "blue1";
    } else if (buffer === 0) {
      return "neutral";
    } else if (buffer === 1) {
      return "red1";
    } else if (buffer === 2) {
      return "red2";
    } else if (buffer === 3) {
      return "red3";
    } else if (buffer === 4) {
      return "red4";
    } else if (buffer === 5) {
      return "red5";
    } else {
      return "red6";
    }
  }

  const allDates = [...new Set(forecasted_labor.map((d) => d.date))];
  const allQhNums = Array.from(
    { length: (maxCloseTime - minOpenTime) * 4 },
    (_, i) => minOpenTime + i * 0.25
  );
  const allRoleTypes = [...new Set(forecasted_labor.map((d) => d.role_type))];

  // Generate all combinations of date, qh_num, and role_type
  const allCombinations = [];
  allDates.forEach((date) => {
    allQhNums.forEach((qh_num) => {
      allRoleTypes.forEach((role_type) => {
        allCombinations.push({ date, qh_num, role_type });
      });
    });
  });

  // Merge forecasted and scheduled labor data into the comprehensive dataset
  const joinedData = allCombinations.map((combination) => {
    const matchingForecast = forecasted_labor.find(
      (forecast) =>
        forecast.date === combination.date &&
        forecast.qh_num === combination.qh_num &&
        forecast.role_type === combination.role_type
    );

    const matchingScheduled = scheduled_labor.find(
      (scheduled) =>
        scheduled.date === combination.date &&
        scheduled.qh_num === combination.qh_num &&
        scheduled.role_type === combination.role_type
    );

    const forecastedLabor = matchingForecast ? matchingForecast.hours * 4 : 0;
    const scheduledLabor = matchingScheduled ? matchingScheduled.labor : 0;
    const buffer = scheduledLabor - forecastedLabor;

    return {
      date: combination.date,
      qh_num: combination.qh_num,
      role_type: combination.role_type,
      forecasted_labor: forecastedLabor,
      scheduled_labor: scheduledLabor,
      buffer: buffer,
    };
  });

  // Sum the values by date and qh_num
  const summedData = {};

  joinedData.forEach((item) => {
    const key = `${item.date}_${item.qh_num}`; // Unique key for grouping

    if (!summedData[key]) {
      summedData[key] = {
        date: item.date,
        qh_num: item.qh_num,
        forecasted_labor_sum: 0,
        scheduled_labor_sum: 0,
        buffer_sum: 0,
      };
    }

    summedData[key].forecasted_labor_sum += item.forecasted_labor;
    summedData[key].scheduled_labor_sum += item.scheduled_labor;
    summedData[key].buffer_sum += item.buffer;
  });

  const results = Object.values(summedData).map((item) => ({
    ...item,
    color: getHeatMapColor(item.buffer_sum, item.forecasted_labor_sum),
  }));

  console.log("results", results);

  React.useEffect(() => {
    let destroyFn;

    if (containerRef.current) {
      const { destroy } = ScheduleCoverageGraphD3(
        containerRef.current,
        results,
        minOpenTime,
        maxCloseTime,
        chartid
      );
      destroyFn = destroy;
    }

    return destroyFn;
  }, [results]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <section className="Main" style={{ textAlign: "center" }}>
        <div id={chartid} ref={containerRef} />
      </section>
    </div>
  );
}
