import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   menuItem: {
//     "&:hover": {
//       backgroundColor: "#ede9fe",
//     },
//     "&.Mui-selected": {
//       backgroundColor: "#f5f3ff", // Set the background color for the selected state
//       "&:hover": {
//         backgroundColor: "#ede9fe",
//       },
//     },
//   },
// }));


export default function MultipleSelectCheckmarks(props) {
  const names = props.roleTypeOptions;
  // const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const value_arr = typeof value === "string" ? value.split(",") : value;
    if (value_arr.length > 0) {
      props.setRoleType(value_arr);
    }
  };

  const calculateMenuWidth = () => {
    let maxWidth = 160; // Default width
    names.forEach((name) => {
      const itemWidth = ((name.length * 8) + 100); // Approximate width calculation based on characters
      if (itemWidth > maxWidth) {
        maxWidth = itemWidth;
      }
    });
    return maxWidth;
  };

  const menuWidth = calculateMenuWidth()

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: menuWidth,
      },
    },
  };



  return (
    <div className="min-w-[200px] flex justify-end">
      {names.length > 0 && (
        <FormControl size="small">
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={props.roleType}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) =>
              selected.length === props.roleTypeOptions.length && selected.length > 2
                ? "All Departments" :
              selected.length > 2 ?
                  `${selected.length} Departments Selected`
              : selected.join(", ")
            }
            MenuProps={MenuProps}
            style={{ textAlign: "center" }}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              borderRadius: "8px",
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            {names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // classes={{ root: classes.menuItem }}
              >
                <Checkbox
                  checked={props.roleType.indexOf(name) > -1}
                  style={{ color: "#6d28d9" }}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
