import React from 'react'
import CelebrationIcon from '@mui/icons-material/Celebration';

export default function CongratulationsScreen(props) {
  return (
    <div className="flex flex-col items-center">
      <div className="text-gray-300">
        <CelebrationIcon style={{ fontSize:"100px" }} />
      </div>
      <h2 className="text-4xl text-center text-gray-100 font-bold mt-10">Congratutions for onboarding to RightWork!</h2>
      <p className="text-gray-300 text-center mt-8">We are super excited to start helping you acheive your goals of lowering your labor costs and increasing your employee retention!</p>
      <p className="text-gray-300 text-center mt-8">Check your email for more information on next steps and the schedule.</p>
      <p className="text-gray-300 text-center mt-8">If you need anything at all or have any questions, please don't hesitate to reach out to support@getrightwork.com</p>
      {props.onboardingInfo.selectedPlan === "Introductory" &&
        <div className="mt-10">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full">
            Start Exploring
          </button>
        </div>
      }
    </div>
  )
}
