import React from 'react'
import StoreName from './StoreName'
import Account from './Account'
import Notifications from './Notifications'

function TopBarDisplayCreateTrainingContent(props) {
  return (
    <div className="py-5 px-6 border-b border-slate-200 flex items-center justify-between">
      <h2 className="text-2xl text-slate-900">{props.title}</h2>
      <div className="flex">
        {/* <div className="mr-6">
          <StoreName
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
          />
        </div>
        <div className="mr-8">
          <Notifications
            homeStore={props.homeStore}
          />
        </div> */}
        <div className="cursor-pointer">
          <Account />
        </div>
      </div>
    </div>
  )
}

export default TopBarDisplayCreateTrainingContent
