import React from "react";
import ShiftPoolDisplay from "../ShiftPoolDisplay";
import TradesDisplay from "../TradesDisplay";
import AvailabilityDisplay from "../AvailabilityDisplay";
import PreferencesDisplay from "../PreferencesDisplay";
import TimeOffDisplay from "../TimeOffDisplay";
import EmployeesDisplay from "../EmployeesDisplay";
import TopBarDisplayStoreOnly from "../../home/top_bar_components/TopBarDisplayStoreOnly";
import ScheduleDisplayEmployee from "../employee/schedule/ScheduleDisplayEmployee";
import {
  API_get_weather,
  API_get_events,
  API_get_default_availability,
  API_list_availability_for_employee,
  API_list_availability_requests_for_manager,
  API_list_availability_request_responses_for_manager,
  // API_list_preferred_availability_for_employee,
  // API_list_preferred_availability_requests_for_manager,
  // API_list_preferred_availability_request_responses_for_manager,
  API_get_list_time_off_requests_for_manager,
  API_get_schedule,
  API_get_stats,
  API_get_shift_pool_for_manager,
  API_get_manager_shift_pickup_request_responses,
  API_get_list_users,
  API_drop_shift,
  API_rescind_drop_shift,
  API_pickup_shift,
  API_rescind_pickup_shift,
  API_manager_reject_shift_drop,
  API_manager_approve_shift_pickup,
  API_archive_shift_pickup_request,
  API_hide_shift_change_request,
  API_manager_shift_pickup_request_response,
  API_create_availability_request,
  API_rescind_availability_request,
  API_delete_availability_request,
  API_hide_availability_request,
  API_create_preferred_availability_request,
  API_rescind_preferred_availability_request,
  API_delete_preferred_availability_request,
  API_hide_preferred_availability_request,
  API_request_time_off,
  API_rescind_time_off_request,
  API_delete_time_off_request,
  API_hide_time_off_request,
  API_manager_respond_to_availability_request,
  API_manager_respond_to_preferred_availability_request,
  API_respond_to_time_off_request,
  API_drawer_notifications,
  API_hide_drawer_notification,
  API_hide_all_drawer_notifications,
  API_get_notification_settings,
  API_update_notification_settings,
  API_store_team_member_set_hide,
  API_store_team_member_set_order,
  API_get_roles,
} from "../../../../constants";
import { getRightworkAPI } from "../utilities/requests";
import { postRightworkAPI } from "../utilities/requests";
import { formatDate, getMaxDatePlusOne } from "../utilities/helpers";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsCard from "../NotificationsCard";
import NotificationSettingsModal from "../NotificationSettingsModal";
import NotificationsDrawer from "../NotificationsDrawer";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import WavesIcon from "@mui/icons-material/Waves";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Divider from "@mui/material/Divider";
import StoreNameSidebar from "../../home/top_bar_components/StoreNameSidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Account from "../../home/top_bar_components/Account";
import LoadSpinner from "../../../../utilities/LoadSpinner";
import TopBarDisplayTitle from "../../home/top_bar_components/TopBarDisplayTitle";
import Notifications from "../../home/top_bar_components/Notifications";

export default function RequestsDisplay(props) {
  const [storeId, setStoreId] = React.useState(props.homeStore);
  // const [storeName, setStoreName] = React.useState(store_name);

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [display, setDisplay] = React.useState("Shift Pool");

  const [notificationsOpen, setNotificationsOpen] = React.useState(false);

  function handleNotificationClick() {
    setNotificationsOpen((prevState) => !prevState);
  }

  const notificationsRef = React.useRef(null);

  React.useEffect(() => {
    const closeNotificationsOnOutsideClick = (event) => {
      if (
        notificationsOpen &&
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setNotificationsOpen(false);
      }
    };

    document.addEventListener("click", closeNotificationsOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeNotificationsOnOutsideClick);
    };
  }, [notificationsOpen]);

  const default_api_calls = {
    schedule: API_get_schedule,
    stats: API_get_stats,
    availability2: API_list_availability_for_employee,
    availabilityRequests: API_list_availability_requests_for_manager,
    availabilityRequestsResponses:
      API_list_availability_request_responses_for_manager,
    // preferences2: API_list_preferred_availability_for_employee,
    // preferencesRequests: API_list_preferred_availability_requests_for_manager,
    // preferencesRequestsResponses:
    //   API_list_preferred_availability_request_responses_for_manager,
    timeOff: API_get_list_time_off_requests_for_manager,
    shiftPool: API_get_shift_pool_for_manager,
    requestResponses: API_get_manager_shift_pickup_request_responses,
    // users: API_get_list_users,
    notifications: API_drawer_notifications,
    notificationSettings: API_get_notification_settings,
    roleNames: API_get_roles,
  };

  // Get the current date
  let currentDate = new Date();
  // Get the start date of the previous month
  let lowerBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  // Get the end date of the next month
  let upperBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    0
  );

  let lowerBoundDateStr = formatDate(lowerBoundDate);
  let upperBoundDateStr = formatDate(upperBoundDate);

  let api_params = {
    store_id: props.homeStore,
    lower_bound_date: lowerBoundDateStr,
    upper_bound_date: upperBoundDateStr,
    specific_date: formatDate(currentDate),
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setIsLoading(false);
      setData(tempData);
    } catch (error) {
      console.log(error);
      //throw Error("Promise failed");
    }
  };

  // ----------- POST APIs ----------- //
  // const drop_shift = async (shift_drop_id, shift_pickup_id, drop_note) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     shift_drop_id: shift_drop_id,
  //     ...(shift_pickup_id && { shift_pickup_id: shift_pickup_id }),
  //     drop_note: drop_note
  //   };

  //   try {
  //     toast.loading("Submitting Add to Pool Request...", { autoClose: false });

  //     const res = await postRightworkAPI(API_drop_shift, requestData);

  //     if (res.status === 200) {
  //       toast.dismiss();
  //       toast.success("Add to Pool Request Submitted!", { autoClose: 3000 });

  //       fetch_data(
  //         {
  //           shiftPool: API_get_shift_pool_for_manager,
  //           schedule: API_get_schedule,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     toast.dismiss();

  //     if (error.response?.data?.system?.message) {
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.error("Request Failed", { autoClose: 3000 });
  //     }

  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  // const rescind_drop_shift = async (shift_drop_id) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     shift_drop_id: shift_drop_id,
  //   };

  //   try {
  //     toast.loading("Cancelling Add to Pool Request...", { autoClose: false });

  //     const res = await postRightworkAPI(API_rescind_drop_shift, requestData);

  //     if (res && res.status === 200) {
  //       // Handle success
  //       toast.dismiss();
  //       toast.success("Add to Pool Request Cancelled!", { autoClose: 3000 });
  //       fetch_data(
  //         {
  //           shiftPool: API_get_shift_pool_for_manager,
  //           shiftPickupResponses:
  //             API_get_manager_shift_pickup_request_responses,
  //           schedule: API_get_schedule,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     // Handle network error
  //     toast.dismiss();

  //     if (error.response?.data?.system?.message) {
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.error("Request Failed", { autoClose: 3000 });
  //     }
  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  // const trade_shift = async (shift_drop_id, shift_pickup_id) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     shift_drop_id: shift_drop_id,
  //     ...(shift_pickup_id && { shift_pickup_id: shift_pickup_id }),
  //   };

  //   try {
  //     toast.loading("Submitting Trade Request...", { autoClose: false });

  //     const res = await postRightworkAPI(API_drop_shift, requestData);

  //     if (res.status === 200) {
  //       toast.dismiss();
  //       toast.success("Trade Request Submitted!", { autoClose: 3000 });

  //       fetch_data(
  //         {
  //           shiftPool: API_get_shift_pool_for_manager,
  //           shiftPickupResponses:
  //             API_get_manager_shift_pickup_request_responses,
  //           schedule: API_get_schedule,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     toast.dismiss();

  //     if (error.response?.data?.system?.message) {
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.error("Request Failed", { autoClose: 3000 });
  //     }

  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  // const rescind_trade_shift = async (shift_drop_id) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     shift_drop_id: shift_drop_id,
  //   };

  //   try {
  //     toast.loading("Cancelling Trade Request...", { autoClose: false });

  //     const res = await postRightworkAPI(API_rescind_drop_shift, requestData);

  //     if (res && res.status === 200) {
  //       // Handle success
  //       toast.dismiss();
  //       toast.success("Trade Request Cancelled!", { autoClose: 3000 });
  //       fetch_data(
  //         {
  //           shiftPool: API_get_shift_pool_for_manager,
  //           shiftPickupResponses:
  //             API_get_manager_shift_pickup_request_responses,
  //           schedule: API_get_schedule,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     // Handle network error
  //     toast.dismiss();

  //     if (error.response?.data?.system?.message) {
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.error("Request Failed", { autoClose: 3000 });
  //     }
  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  const create_pickup_request = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Submitting Shift Pickup Request...", { autoClose: false });
      const res = await postRightworkAPI(API_pickup_shift, requestData);

      if (res && res.status === 200) {
        // Handle success
        toast.dismiss();
        toast.success("Shift Successfully Pickup Up!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_manager,
            requestResponses: API_get_manager_shift_pickup_request_responses,
            schedule: API_get_schedule,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  // const rescind_pickup_shift = async (shift_drop_id) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     shift_drop_id: shift_drop_id,
  //   };

  //   try {
  //     toast.loading("Cancelling Shift Pickup Request...", { autoClose: false });

  //     const res = await postRightworkAPI(API_rescind_pickup_shift, requestData);

  //     if (res && res.status === 200) {
  //       // Handle success
  //       toast.dismiss();
  //       toast.success("Shift Pickup Request Cancelled!", { autoClose: 3000 });
  //       fetch_data(
  //         {
  //           shiftPool: API_get_shift_pool_for_manager,
  //           shiftPickupResponses:
  //             API_get_manager_shift_pickup_request_responses,
  //           schedule: API_get_schedule,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     // Handle network error
  //     toast.dismiss();

  //     if (error.response?.data?.system?.message) {
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.error("Request Failed", { autoClose: 3000 });
  //     }
  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  const manager_reject_shift_drop = async (shift_drop_id) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_drop_id: shift_drop_id,
    };

    try {
      toast.loading("Rejecting Shift Drop Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_manager_reject_shift_drop,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Shift Drop Request Rejected!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_manager,
            schedule: API_get_schedule,
            requestResponses: API_get_manager_shift_pickup_request_responses,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  const manager_approve_shift_pickup = async (
    shift_change_request_uuid,
    response
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_change_request_uuid: shift_change_request_uuid,
      response: response,
    };

    try {
      toast.loading("Approving Shift Pickup Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_manager_approve_shift_pickup,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Shift Pickup Request Approved!", { autoClose: 3000 });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_manager,
            schedule: API_get_schedule,
            requestResponses: API_get_manager_shift_pickup_request_responses,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  const archive_shift_pickup_request = async (shift_change_request_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_change_request_uuid: shift_change_request_uuid,
    };

    try {
      toast.loading("Archiving Shift Pickup Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_hide_shift_change_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Shift Pickup Request Archived!", { autoClose: 3000 });
        fetch_data(
          {
            requestResponses: API_get_manager_shift_pickup_request_responses,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  const archive_trade_request = async (shift_change_request_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_change_request_uuid: shift_change_request_uuid,
    };

    try {
      toast.loading("Archiving Trade Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_hide_shift_change_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Trade Request Archived!", { autoClose: 3000 });
        fetch_data(
          {
            requestResponses: API_get_manager_shift_pickup_request_responses,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  // This function has been deprecated but is still being passed down
  const manager_shift_pickup_request_response = async (
    shift_pickup_request_uuid,
    response
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      shift_pickup_request_uuid: shift_pickup_request_uuid,
      response: response,
    };

    try {
      toast.loading("Responding to Shift Pickup Request...", {
        autoClose: false,
      });
      const res = await postRightworkAPI(
        API_manager_shift_pickup_request_response,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Shift Pickup Request Response Successful!", {
          autoClose: 3000,
        });
        fetch_data(
          {
            shiftPool: API_get_shift_pool_for_manager,
            schedule: API_get_schedule,
            requestResponses: API_get_manager_shift_pickup_request_responses,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  const create_availability_request = async (data) => {
    /*
    store_id = request.data.get('store_id')
    dow = request.data.get('dow')
    available_from = request.data.get('available_from')
    available_to = request.data.get('available_to')
    note = request.data.get('note')
    user_id = request.user.id
    */

    // Prepare data to send
    let requestData = data;
    requestData["store_id"] = storeId;

    try {
      toast.loading("Creating Availability Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_create_availability_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Availability Request Successful!", { autoClose: 3000 });
        fetch_data(
          {
            availability2: API_list_availability_for_employee,
            availabilityRequestsResponses:
              API_list_availability_request_responses_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  // Rescind availability request
  const rescind_availability_request = async (
    default_availability_request_id
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      default_availability_request_id: default_availability_request_id,
    };

    try {
      toast.loading("Rescinding Availability Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_rescind_availability_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Availability Request Deleted!", { autoClose: 3000 });
        fetch_data(
          {
            availability2: API_list_availability_for_employee,
            availabilityRequestsResponses:
              API_list_availability_request_responses_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  const archive_availability_request = async (
    default_availability_request_id
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      default_availability_request_id: default_availability_request_id,
    };

    try {
      toast.loading("Archiving Availability Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_hide_availability_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Availability Request Archived!", { autoClose: 3000 });
        fetch_data(
          {
            availability2: API_list_availability_for_employee,
            availabilityRequestsResponses:
              API_list_availability_request_responses_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  // const create_preferred_availability_request = async (data) => {
  //   /*
  //   store_id = request.data.get('store_id')
  //   dow = request.data.get('dow')
  //   available_from = request.data.get('available_from')
  //   available_to = request.data.get('available_to')
  //   note = request.data.get('note')
  //   user_id = request.user.id
  //   */

  //   // Prepare data to send
  //   let requestData = data;
  //   requestData["store_id"] = storeId;

  //   try {
  //     toast.loading("Creating Preferences Request...", { autoClose: false });
  //     const res = await postRightworkAPI(
  //       API_create_preferred_availability_request,
  //       requestData
  //     );

  //     if (res && res.status === 200) {
  //       toast.dismiss();
  //       toast.success("Preferences Request Successful!", { autoClose: 3000 });
  //       fetch_data(
  //         {
  //           preferences2: API_list_preferred_availability_for_employee,
  //           preferencesRequestsResponses:
  //             API_list_preferred_availability_request_responses_for_manager,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     // Handle network error
  //     if (error.response?.data?.system?.message) {
  //       toast.dismiss();
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.dismiss();
  //       toast.error("Request Failed");
  //     }
  //   }
  // };

  // // Rescind preferred availability request
  // const rescind_preferred_availability_request = async (
  //   preferred_availability_request_id
  // ) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     preferred_availability_request_id: preferred_availability_request_id,
  //   };

  //   try {
  //     toast.loading("Rescinding Preferences Request...", { autoClose: false });
  //     const res = await postRightworkAPI(
  //       API_rescind_preferred_availability_request,
  //       requestData
  //     );

  //     if (res && res.status === 200) {
  //       toast.dismiss();
  //       toast.success("Preferences Request Deleted!", { autoClose: 3000 });
  //       fetch_data(
  //         {
  //           preferences2: API_list_preferred_availability_for_employee,
  //           preferencesRequestsResponses:
  //             API_list_preferred_availability_request_responses_for_manager,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     // Handle network error
  //     if (error.response?.data?.system?.message) {
  //       toast.dismiss();
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.dismiss();
  //       toast.error("Request Failed");
  //     }
  //   }
  // };

  // const archive_preferred_availability_request = async (
  //   preferred_availability_request_id
  // ) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     preferred_availability_request_id: preferred_availability_request_id,
  //   };

  //   try {
  //     toast.loading("Archiving Preferred Availability Request...", {
  //       autoClose: false,
  //     });
  //     const res = await postRightworkAPI(
  //       API_hide_preferred_availability_request,
  //       requestData
  //     );

  //     if (res && res.status === 200) {
  //       toast.dismiss();
  //       toast.success("Preferred Availability Request Archived!", {
  //         autoClose: 3000,
  //       });
  //       fetch_data(
  //         {
  //           preferences2: API_list_preferred_availability_for_employee,
  //           preferencesRequestsResponses:
  //             API_list_preferred_availability_request_responses_for_manager,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     toast.dismiss();
  //     toast.error("Request Failed", { autoClose: 3000 });
  //     console.error("Error:", error);
  //   }
  // };

  const request_time_off = async (data) => {
    // Prepare data to send
    let requestData = data;
    requestData["store_id"] = storeId;

    try {
      toast.loading("Requesting Time Off...", { autoClose: false });
      const res = await postRightworkAPI(API_request_time_off, requestData);

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Time Off Request Successful!", { autoClose: 3000 });
        fetch_data(
          {
            timeOff: API_get_list_time_off_requests_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  // Rescind time off request
  const rescind_time_off_request = async (time_off_request_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      time_off_request_uuid: time_off_request_uuid,
    };

    try {
      toast.loading("Rescinding Time Off Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_rescind_time_off_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Time Off Request Deleted", { autoClose: 3000 });
        fetch_data(
          {
            timeOff: API_get_list_time_off_requests_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  const archive_time_off_request = async (time_off_request_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      time_off_request_uuid: time_off_request_uuid,
    };

    try {
      toast.loading("Archiving Time Off Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_hide_time_off_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Time Off Request Archived!", { autoClose: 3000 });
        fetch_data(
          {
            timeOff: API_get_list_time_off_requests_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  const manager_respond_to_availability_request = async (
    request_id,
    response,
    note
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      default_availability_request_id: request_id,
      response: response,
      note: note,
    };

    try {
      toast.loading("Responding to Availability Request...", {
        autoClose: false,
      });
      const res = await postRightworkAPI(
        API_manager_respond_to_availability_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Availability Request Response Successful!", {
          autoClose: 3000,
        });
        fetch_data(
          {
            availability2: API_list_availability_for_employee,
            availabilityRequests: API_list_availability_requests_for_manager,
            availabilityRequestsResponses:
              API_list_availability_request_responses_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  // const manager_respond_to_preferred_availability_request = async (
  //   request_id,
  //   response,
  //   note
  // ) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: storeId,
  //     preferred_availability_request_id: request_id,
  //     response: response,
  //     note: note,
  //   };

  //   try {
  //     toast.loading("Responding to Preferences Request...", {
  //       autoClose: false,
  //     });
  //     const res = await postRightworkAPI(
  //       API_manager_respond_to_preferred_availability_request,
  //       requestData
  //     );

  //     if (res && res.status === 200) {
  //       toast.dismiss();
  //       toast.success("Preferences Request Response Successful!", {
  //         autoClose: 3000,
  //       });
  //       fetch_data(
  //         {
  //           preferences2: API_list_preferred_availability_for_employee,
  //           preferencesRequests:
  //             API_list_preferred_availability_requests_for_manager,
  //           preferencesRequestsResponses:
  //             API_list_preferred_availability_request_responses_for_manager,
  //         },
  //         api_params
  //       );
  //     }
  //   } catch (error) {
  //     // Handle network error
  //     if (error.response?.data?.system?.message) {
  //       toast.dismiss();
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.dismiss();
  //       toast.error("Request Failed");
  //     }
  //   }
  // };

  const respond_to_time_off_request = async (request_uuid, response) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      time_off_request_uuid: request_uuid,
      status: response,
    };

    try {
      toast.loading("Responding to Time Off Request...", { autoClose: false });
      const res = await postRightworkAPI(
        API_respond_to_time_off_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Time Off Request Response Successful!", {
          autoClose: 3000,
        });
        fetch_data(
          {
            timeOff: API_get_list_time_off_requests_for_manager,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  const archive_drawer_notification = async (drawer_notification_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      drawer_notification_uuid: drawer_notification_uuid,
    };

    try {
      toast.loading("Archiving Drawer Notification...", { autoClose: false });
      const res = await postRightworkAPI(
        API_hide_drawer_notification,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Drawer Notification Archived!", { autoClose: 3000 });
        fetch_data(
          {
            notifications: API_drawer_notifications,
          },
          api_params
        );
        // Further handle success case as per your requirement
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  const archive_all_drawer_notifications = async () => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
    };

    try {
      toast.loading("Archiving All Drawer Notifications...", {
        autoClose: false,
      });

      const res = await postRightworkAPI(
        API_hide_all_drawer_notifications,
        requestData
      );

      if (res.status === 200) {
        // Handle success
        toast.success("All notifications were cleared successfully", {
          autoClose: 3000,
        });
        toast.dismiss();
        fetch_data(
          {
            notifications: API_drawer_notifications,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          notifications: API_drawer_notifications,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const update_notification_settings = async (fe_settings) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      fe_settings: JSON.stringify(fe_settings),
    };

    try {
      toast.loading("Updating Notification Settings...", { autoClose: false });

      const res = await postRightworkAPI(
        API_update_notification_settings,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        fetch_data(
          {
            notificationSettings: API_get_notification_settings,
          },
          api_params
        );
        toast.success("Your notification settings were successfully updated", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      fetch_data(
        {
          notificationSettings: API_get_notification_settings,
        },
        api_params
      );
      console.error("Error:", error); // Handle error as needed
    }
  };

  const hide_store_team_member = async (store_team_member_id, hidden) => {
    // Prepare data to send
    const requestData = {
      store_team_member_id: store_team_member_id,
      hidden: hidden,
    };

    try {
      toast.loading("Updating Employee...", { autoClose: false });
      const res = await postRightworkAPI(
        API_store_team_member_set_hide,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Employee Updated Successfully!", { autoClose: 3000 });
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
        // Further handle success case as per your requirement
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  const store_team_member_set_order = async (store_team_member_settings) => {
    // Prepare data to send
    const requestData = {
      store_id: storeId,
      store_team_member_settings: JSON.stringify(store_team_member_settings),
    };

    try {
      toast.loading("Setting Employees Order...", { autoClose: false });
      const res = await postRightworkAPI(
        API_store_team_member_set_order,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Order Set Successfully!", { autoClose: 3000 });
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
        // Further handle success case as per your requirement
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
  }, [props.homeStore]);

  const userData = data.users?.redux?.payload?.employees;
  const myId = data.schedule?.redux?.payload?.me?.store_team_member?.id;
  const scheduleData = data.schedule?.redux?.payload?.schedule;
  const shiftPoolData = data.shiftPool?.redux?.payload?.schedule;
  const availabilityRequests = data.availabilityRequests?.redux?.payload;
  const preferencesRequests = data.preferencesRequests?.redux?.payload;
  const timeOffData = data.timeOff?.redux?.payload?.timeOffRequests;
  const minEffectiveDate = getMaxDatePlusOne(scheduleData);

  const timeOffRequests = timeOffData?.filter((v, i) => v.status === "PENDING");

  const shiftPoolRequests = shiftPoolData?.filter(
    (v, i) => v.status !== "PENDING_TRADE"
  );

  const tradeRequests = shiftPoolData?.filter(
    (v) => v.status === "PENDING_TRADE" && v.pending_pickups.length > 0
  );

  let notMyUpcomingShifts = [];
  if (scheduleData) {
    notMyUpcomingShifts = scheduleData.filter((shift) => {
      // Include the item if its date is today or in the future
      return (
        (shift.date >= formatDate(currentDate)) &
        (shift.store_team_member?.id !== myId)
      );
    });
  }

  const notifications =
    data.notifications?.redux?.payload?.drawer_notifications;

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      {!isLoading ? (
        <div className="relative">
          {/* <div className="w-full px-10 my-7">
            <TopBarDisplayStoreOnly
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div> */}
          <div className="w-full min-h-screen flex">
            <div className="w-1/5">
              <div className="h-full flex flex-col gap-2 px-6 py-5 bg-slate-50 border-r border-slate-200">
                {/* <div className="w-full py-3 px-4 text-left flex items-center relative rounded-2xl bg-white border border-slate-200">
                  <p className="text-slate-900 text-sm">Back Bay</p>
                </div> */}
                {/* <div>
                  <StoreNameSidebar
                    storeOptions={props.storeOptions}
                    homeStore={props.homeStore}
                    storeNames={props.storeNames}
                    change_home_store={props.change_home_store}
                  />
                </div> */}
                {/* <button
                  className={`mt-12 w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Employees" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Employees")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Employees"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <PeopleOutlinedIcon />
                    </div>
                    <span>Employees</span>
                  </div>
                </button> */}
                {/* <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Schedule" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Schedule")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Schedule"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <CalendarTodayOutlinedIcon />
                    </div>
                    <span>Schedule</span>{" "}
                  </div>
                </button>
                <div className="mt-2">
                  <Divider />
                </div> */}
                <button
                  className={`mt-5 w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
                    display === "Shift Pool" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Shift Pool")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Shift Pool"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <WavesIcon />
                    </div>
                    <span>Shift Pool</span>
                  </div>
                  {shiftPoolRequests?.length > 0 && (
                    <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
                      {shiftPoolRequests?.length}
                    </span>
                  )}
                </button>
                <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Trades" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Trades")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Trades"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <SwapHorizIcon />
                    </div>
                    <span>Trades</span>
                  </div>
                  {tradeRequests?.length > 0 && (
                    <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
                      {tradeRequests?.length}
                    </span>
                  )}
                </button>
                <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Availability" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Availability")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Availability"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <BackHandOutlinedIcon />
                    </div>
                    <span>Availability</span>
                  </div>
                  {availabilityRequests?.pending?.length > 0 && (
                    <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
                      {availabilityRequests?.pending?.length}
                    </span>
                  )}
                </button>
                {/* <button
                  className={`w-full py-3 px-4 text-left rounded flex items-center relative ${
                    display === "Preferences"
                      ? "bg-blue-200 border-l-4 border-primary"
                      : ""
                  }`}
                  onClick={() => setDisplay("Preferences")}
                >
                  <span>Preferences</span>{" "}
                  {preferencesRequests?.pending?.length > 0 && (
                    <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
                      {preferencesRequests?.pending?.length}
                    </span>
                  )}
                </button> */}
                <button
                  className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                    display === "Time Off" ? "bg-white" : ""
                  }`}
                  onClick={() => setDisplay("Time Off")}
                >
                  <div
                    className={`flex flex-1 items-center space-x-4 ${
                      display === "Time Off"
                        ? "text-violet-700"
                        : "text-slate-500"
                    }`}
                  >
                    <div>
                      <ScheduleOutlinedIcon />
                    </div>
                    <span>Time Off</span>
                  </div>
                  {timeOffRequests?.length > 0 && (
                    <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
                      {timeOffRequests?.length}
                    </span>
                  )}
                </button>
                {/* <div>
                  <Divider />
                </div> */}
              </div>
            </div>
            <div className="w-4/5">
              <div>
                <TopBarDisplayTitle
                  title={display}
                  storeOptions={props.storeOptions}
                  homeStore={props.homeStore}
                  storeNames={props.storeNames}
                  change_home_store={props.change_home_store}
                />
              </div>
              {display === "Shift Pool" ? (
                <ShiftPoolDisplay
                  shiftPoolData={data.shiftPool?.redux?.payload?.schedule}
                  requestResponsesData={
                    data.requestResponses?.redux?.payload
                      ?.shift_pickup_responses
                  }
                  create_pickup_request={create_pickup_request}
                  manager_reject_shift_drop={manager_reject_shift_drop}
                  manager_approve_shift_pickup={manager_approve_shift_pickup}
                  manager_shift_pickup_request_response={
                    manager_shift_pickup_request_response
                  }
                  archive_shift_pickup_request={archive_shift_pickup_request}
                />
              ) : display === "Trades" ? (
                <TradesDisplay
                  shiftPoolData={data.shiftPool?.redux?.payload?.schedule}
                  requestResponsesData={
                    data.requestResponses?.redux?.payload
                      ?.shift_pickup_responses
                  }
                  manager_reject_shift_drop={manager_reject_shift_drop}
                  manager_approve_shift_pickup={manager_approve_shift_pickup}
                  manager_shift_pickup_request_response={
                    manager_shift_pickup_request_response
                  }
                  archive_trade_request={archive_trade_request}
                />
              ) : display === "Availability" ? (
                <AvailabilityDisplay
                  availabilityData2={data.availability2?.redux?.payload}
                  availabilityRequests={
                    data.availabilityRequests?.redux?.payload
                  }
                  availabilityRequestsResponses={
                    data.availabilityRequestsResponses?.redux?.payload
                  }
                  create_availability_request={create_availability_request}
                  archive_availability_request={archive_availability_request}
                  rescind_availability_request={rescind_availability_request}
                  manager_respond_to_availability_request={
                    manager_respond_to_availability_request
                  }
                  permissionRole={props.permissionRole}
                  minEffectiveDate={minEffectiveDate}
                />
              ) : // ) : display === "Preferences" ? (
              //   <PreferencesDisplay
              //     availabilityData2={data.availability2?.redux?.payload}
              //     preferencesData2={data.preferences2?.redux?.payload}
              //     preferencesRequests={data.preferencesRequests?.redux?.payload}
              //     preferencesRequestsResponses={
              //       data.preferencesRequestsResponses?.redux?.payload
              //     }
              //     create_preferred_availability_request={
              //       create_preferred_availability_request
              //     }
              //     archive_preferred_availability_request={
              //       archive_preferred_availability_request
              //     }
              //     rescind_preferred_availability_request={
              //       rescind_preferred_availability_request
              //     }
              //     manager_respond_to_preferred_availability_request={
              //       manager_respond_to_preferred_availability_request
              //     }
              //   />
              display === "Time Off" ? (
                <TimeOffDisplay
                  timeOffData={data.timeOff?.redux?.payload?.timeOffRequests}
                  myId={myId}
                  request_time_off={request_time_off}
                  archive_time_off_request={archive_time_off_request}
                  rescind_time_off_request={rescind_time_off_request}
                  respond_to_time_off_request={respond_to_time_off_request}
                  minEffectiveDate={minEffectiveDate}
                />
              ) : (
                // ) : display === "Schedule" ? (
                //   <ScheduleDisplayEmployee
                //     scheduleData={scheduleData}
                //     notMyUpcomingShifts={notMyUpcomingShifts}
                //     drop_shift={drop_shift}
                //     trade_shift={trade_shift}
                //     rescind_drop_shift={rescind_drop_shift}
                //     rescind_trade_shift={rescind_trade_shift}
                //     myId={myId}
                //     roleNames={data.roleNames}
                //   />
                // ) : display === "Employees" ? (
                //   <EmployeesDisplay
                //     employees={userData}
                //     hide_store_team_member={hide_store_team_member}
                //     store_team_member_set_order={store_team_member_set_order}
                //     roleNames={data.roleNames}
                //   />
                <div></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
      <NotificationsDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        notifications={notifications}
        archive_drawer_notification={archive_drawer_notification}
        archive_all_drawer_notifications={archive_all_drawer_notifications}
        notificationSettings={
          data?.notificationSettings?.redux?.payload?.notification_settings
        }
        update_notification_settings={update_notification_settings}
        permissionRole={props.permissionRole}
      />
    </>
  );
}
