import React, { useState, useEffect } from "react";
import DailyPlanTaskConfiguration from "./plan_contents/DailyPlanTaskConfiguration";
import TopBarDisplayTitleNoLocationSelect from "../home/top_bar_components/TopBarDisplayTitleNoLocationSelect";
import axios from "axios";
import {
  API_get_plan_tasks,
  API_post_plan_task,
  API_delete_plan_task
} from "../../../constants";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function DailyPlanTaskConfigurationDisplay(props) {

  const [isLoading, setIsLoading] = React.useState(false)
  const [planTasks, setPlanTasks] = React.useState([])

  const fetchPlanTasks = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = { company_id: props.homeCompany };
    let api = API_get_plan_tasks;
    try {
      const res = await getRightworkAPI(api, api_params);
      console.log(res);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.plan_tasks;
        setPlanTasks(data);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    fetchPlanTasks(true)
  }, [props.homeStore])

  console.log("PLAN TASKS", planTasks)

  // ----------- POST APIs ----------- //

  // * plan_task_uuid (optional)
  // * company_id (auth)
  // * name
  // * description
  // * lower_bound_qhnum
  // * upper_bound_qhnum
  // * cron_days_of_the_week_arr
  //     JSON.stringify([true, true, true, true, true, true, true])
  // * subtasks_json
  //     JSON.stringify([
  //         {
  //             "uuid",  (optional)
  //             "name",
  //             "description",
  //             "type",
  //             "is_image_required",
  //         }
  //     ])
  const post_plan_task = async (
    plan_task_uuid,
    name,
    description,
    category,
    lower_bound_qhnum,
    upper_bound_qhnum,
    cron_days_of_the_week,
    subtasks,
    file_uploads,
  ) => {
    // Prepare data to send
    const requestData = {
      plan_task_uuid: plan_task_uuid,
      company_id: props.homeCompany,
      name: name,
      description: description,
      category: category,
      lower_bound_qhnum: lower_bound_qhnum,
      upper_bound_qhnum: upper_bound_qhnum,
      cron_days_of_the_week: JSON.stringify(cron_days_of_the_week),
      subtasks: JSON.stringify(subtasks),
      file_upload_uuids: JSON.stringify(file_uploads),
    };

    try {
      toast.loading("Saving Task...", { autoClose: false });

      const res = await postRightworkAPI(API_post_plan_task, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Task Saved Successfully", { autoClose: 3000 });

        fetchPlanTasks(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };


  const delete_plan_task = async (plan_task_uuid) => {
    // Prepare data to send
    const requestData = {
      plan_task_uuid: plan_task_uuid,
      company_id: props.homeCompany,
    };

    try {
      toast.loading("Deleting Task...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_plan_task, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Task Deleted Successfully", { autoClose: 3000 });

        fetchPlanTasks(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };



  return (
    <div>
      <div>
        <TopBarDisplayTitleNoLocationSelect title={"Task Configuration"} />
      </div>
      {!isLoading ? (
        <div>
          <div className="mt-10">
            <DailyPlanTaskConfiguration
              planTasks={planTasks}
              post_plan_task={post_plan_task}
              delete_plan_task={delete_plan_task}
              homeCompany={props.homeCompany}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </div>
  );
}

export default DailyPlanTaskConfigurationDisplay;
