import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getInitials } from "../../utilities/helpers";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { getCardColor2 } from "../../../../../helpers/helpers";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import TaskNameCardEmployee from "../../../plan/plan_contents/TaskNameCardEmployee";

function EmployeeShiftCardToday(props) {
  const initialsArray = props.alsoWorking?.map((v, i) =>
    v.name === "undefined undefined" ? "O" : getInitials(v.name)
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  // const shiftCardColor = getCardColor2(
  //   props.shift_info.role_id,
  //   props.role_names,
  //   props.locked_mode,
  //   true
  // );

  const [showLighbox, setShowLightbox] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(null)

  return (
    <>
      <div
        className="w-full rounded-2xl bg-violet-50 hover:bg-violet-100 px-5 py-6 cursor-pointer relative"
        onClick={handleOpen}
      >
        {props.note && (
          <Tooltip title={props.note}>
            <div className="absolute top-3 right-3 text-slate-500">
              <CommentIcon />
            </div>
          </Tooltip>
        )}
        <div className="flex flex-row items-center">
          <p className="text-slate-900 text-[28px] font-bold">
            {props.shiftStartNumber}
          </p>
          <p className="text-slate-600 text-lg ml-1">
            {props.shiftStartLetters}
          </p>
          <div className="ml-3 text-slate-600">
            <ArrowForwardIcon color="#475569" size={22} />
          </div>
          <p className="text-slate-900 text-[28px] font-bold ml-3">
            {props.shiftEndNumber}
          </p>
          <p className="text-slate-600 text-lg ml-1">{props.shiftEndLetters}</p>
        </div>
        <div className="mt-3">
          <p className="text-slate-600 text-[18px]">
            {props.station
              ? props.station
              : props.role
              ? props.role
              : props.roleType}{" "}
            @ {props.location}
          </p>
          <p className="text-slate-600 mt-3">{props.break}</p>
        </div>
        <div className="absolute bottom-6 right-5 flex flex-row items-center justify-end">
          {initialsArray?.length > 0 && (
            <div
              className={`w-9 h-9 ${getCardColor2(
                props.alsoWorking?.[0]?.role_id?.id,
                props.roleNames,
                false,
                false
              )} border-2 border-white rounded-full flex items-center justify-center -mr-2 z-30`}
            >
              <p className="text-white text-[10px]">{initialsArray[0]}</p>
            </div>
          )}
          {initialsArray?.length > 1 && (
            <div
              className={`w-9 h-9 ${getCardColor2(
                props.alsoWorking?.[1]?.role_id?.id,
                props.roleNames,
                false,
                false
              )} border-2 border-white rounded-full flex items-center justify-center -mr-2 z-20`}
            >
              <p className="text-white text-[10px] ml-0.5">
                {initialsArray[1]}
              </p>
            </div>
          )}
          {initialsArray?.length > 2 && (
            <div
              className={`w-9 h-9 ${getCardColor2(
                props.alsoWorking?.[2]?.role_id?.id,
                props.roleNames,
                false,
                false
              )} border-2 border-white rounded-full flex items-center justify-center -mr-2 z-10`}
            >
              <p className="text-white text-[10px] ml-0.5">
                {initialsArray[2]}
              </p>
            </div>
          )}
          {initialsArray?.length > 3 && (
            <div
              className={`w-9 h-9 ${getCardColor2(
                props.alsoWorking?.[3]?.role_id?.id,
                props.roleNames,
                false,
                false
              )} border-2 border-white rounded-full flex items-center justify-center`}
            >
              <p className="text-white text-[10px] ml-0.5">
                {initialsArray[3]}
              </p>
            </div>
          )}
          {initialsArray?.length > 4 && (
            <div>
              <p className="text-xs text-slate-600 ml-0.5">
                +{initialsArray.length - 4}
              </p>
            </div>
          )}
        </div>
      </div>
      {props.tasks?.length > 0 &&
        <div className="w-full flex justify-end text-slate-500">
          <div className="mt-3 w-[92%]">
            <div>
              <p className="text-xs text-slate-600">Tasks for today</p>
            </div>
            {props.tasks?.map((task, index) => {
              return(
                <div key={index} className="mt-1.5">
                  <TaskNameCardEmployee
                    task={task}
                    save_plan_task_assignment={props.save_plan_task_assignment}
                    homeCompany={props.homeCompany}
                  />
                </div>
              )
            })}
          </div>
        </div>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <h2 className="text-3xl font-medium">Today's Shift</h2>
          <div>
            <div className="mt-5">
              <div className="w-full rounded-2xl bg-violet-50 px-5 py-6 relative">
                <div className="flex flex-row items-center">
                  <p className="text-slate-900 text-[28px] font-bold">
                    {props.shiftStartNumber}
                  </p>
                  <p className="text-slate-600 text-lg ml-1">
                    {props.shiftStartLetters}
                  </p>
                  <div className="ml-3 text-slate-600">
                    <ArrowForwardIcon color="#475569" size={22} />
                  </div>
                  <p className="text-slate-900 text-[28px] font-bold ml-3">
                    {props.shiftEndNumber}
                  </p>
                  <p className="text-slate-600 text-lg ml-1">
                    {props.shiftEndLetters}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="text-slate-600 text-[18px]">
                    {props.role} @ {props.location}
                  </p>
                  <p className="text-slate-600 mt-3">{props.break}</p>
                </div>
              </div>
            </div>
            {props.note && (
              <div className="px-4 py-3 bg-white border-b border-slate-200 flex gap-x-2">
                <div className="text-slate-500">
                  <CommentIcon
                    style={{ fontSize: "20px", marginBottom: "1px" }}
                  />
                </div>
                <p className="text-slate-500 text-sm">{props.note}</p>
              </div>
            )}
            <div className="px-4 pb-4 pt-10 bg-white border-b border-slate-200">
              <p className="text-slate-500">Today's Teammates</p>
            </div>
            {props.alsoWorking.length > 0 ? (
              <div className="max-h-[360px] overflow-scroll">
                {props.alsoWorking.map((teammate, index) => (
                  <div
                    className="py-4 border-b border-slate-200 px-4 flex"
                    key={index}
                  >
                    <div
                      className={`w-12 h-12 rounded-full ${getCardColor2(
                        teammate.role_id?.id,
                        props.roleNames,
                        false,
                        false
                      )} text-white flex items-center justify-center ${
                        teammate.name === "undefined undefined"
                          ? "text-sm"
                          : "text-base"
                      }`}
                    >
                      {teammate.name === "undefined undefined"
                        ? "Open"
                        : getInitials(teammate.name)}
                    </div>
                    <div className="ml-5">
                      <p className="text-slate-900 text-[18px]">
                        {teammate.name === "undefined undefined"
                          ? "Open Shift"
                          : teammate.name}
                      </p>
                      <p className="text-slate-500 text-sm">{teammate.shift}</p>
                      <p className="text-slate-500 text-sm">
                        {teammate.role} | {teammate.roleType}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="px-4 py-4">
                <p className="text-slate-900 text-[18px]">Nobody Scheduled</p>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EmployeeShiftCardToday;
