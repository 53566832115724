import React, { useState, useEffect } from "react";
import TopBarDisplayTitleNoLocationSelect from "../home/top_bar_components/TopBarDisplayTitleNoLocationSelect";
import CircularProgress from "@mui/material/CircularProgress";
import { API_get_dashboard } from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";

function OverviewDisplay(props) {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch dashboard data when the component is mounted
    const fetchData = async () => {
      setIsLoading(true);

      const api_params = {
        store_id: props.homeStore,
        company_id: props.homeCompany,
        week_count: 4, // Current week + next 3 weeks
      };

      try {
        const res = await getRightworkAPI(API_get_dashboard, api_params);
        setDashboardData(res.data?.redux?.payload?.stores);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [props.homeCompany]);

  console.log(dashboardData);

  return (
    <div className="bg-white min-h-screen">
      <div>
        <TopBarDisplayTitleNoLocationSelect title="Overview" />
      </div>
      <div className="mt-10 pb-10 px-8">
        <div className="flex">
          <div className="w-[18%]"></div>
          <div className="flex-1">
            <div className="px-4">
              <p className="text-slate-500 text-xxs">Current Week</p>
              <p className="text-slate-900 text-base">11/10 - 11/16</p>
            </div>
          </div>
          <div className="flex-1">
            <div className="px-4">
              <p className="text-slate-500 text-xxs">Current Week</p>
              <p className="text-slate-900 text-base">11/10 - 11/16</p>
            </div>
          </div>
          <div className="flex-1">
            <div className="px-4">
              <p className="text-slate-500 text-xxs">Current Week</p>
              <p className="text-slate-900 text-base">11/10 - 11/16</p>
            </div>
          </div>
          <div className="flex-1">
            <div className="px-4">
              <p className="text-slate-500 text-xxs">Current Week</p>
              <p className="text-slate-900 text-base">11/10 - 11/16</p>
            </div>
          </div>
        </div>
        <div>
          {dashboardData?.map((store, index) => {
            return (
              <div key={index} className="w-full border border-slate-200 rounded-xl flex my-2.5">
                <div className="w-[18%] p-4">
                  <h3 className="text-2xl text-slate-900">{store.store_name}</h3>
                </div>
                <div className="flex-1 p-4 border-l border-slate-200">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-slate-500 text-xxs">
                        Forecasted Sales
                      </p>
                      <p className="text-slate-900 text-base">$41,880</p>
                    </div>
                    <div className="px-2 py-1 bg-orange-100 border border-orange-300 rounded-full">
                      <p className="text-xxs text-orange-700">Unpublished</p>
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <div className="w-[75%]">
                      <p className="text-slate-500 text-xxs">
                        545.50 / 557.00 hrs
                      </p>
                      <div className="w-full h-1.5 bg-slate-200 rounded-full mt-0.5 overflow-hidden">
                        <div className="w-[75%] h-full bg-slate-800 rounded-full"></div>
                      </div>
                      <div className="mt-0.5">
                        <p className="text-emerald-700 text-xs">
                          97.9%{" "}
                          <span className="text-slate-500 text-xxs">
                            of forecasted
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="w-8 h-8 rounded-lg text-white flex items-center justify-center bg-emerald-600">
                      <p>A</p>
                    </div>
                  </div>
                </div>
                <div className="flex-1 p-4 border-l border-slate-200">
                  <p className="text-slate-500 text-xs">Next Week</p>
                </div>
                <div className="flex-1 p-4 border-l border-slate-200">
                  <p className="text-slate-500 text-xs">+2 Weeks</p>
                </div>
                <div className="flex-1 p-4 border-l border-slate-200">
                  <p className="text-slate-500 text-xs">+3 Week</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OverviewDisplay;
