import React, { useState, useEffect } from "react";
import ContentDisplay from "./content/ContentDisplay";
import NavigationSideBar from "./navbar/NavigationSideBar";
import SettingsMain from "./content/settings/SettingsMain";
import GoalsDisplay from "./content/contests/GoalsDisplay";
import DailyPlanDisplay from "./content/plan/DailyPlanDisplay";
import DailyPlanTaskConfigurationDisplay from "./content/plan/DailyPlanTaskConfigurationDisplay";
import RequestsDisplay from "./content/requests/screens/RequestsDisplay";
import RequestsDisplayEmployee from "./content/requests/screens/RequestsDisplayEmployee";
import ConfigurationsDisplay from "./content/configurations/ConfigurationsDisplay";
import ReportingDisplay from "./content/reporting/ReportingDisplay";
import DepartmentRoleStationSettingsDisplay from "./content/department_role_station_settings/DepartmentRoleStationSettingsDisplay";
import LocationsIntegrationsHoursSettingsDisplay from "./content/locations_integrations_hours_settings/LocationsIntegrationsHoursSettingsDisplay";
import LaborModelOuterDisplay from "./content/labor_model/LaborModelOuterDisplay";
import TimeClockDisplay from "./content/time_clock/TimeClockDisplay";
import TimeEntriesDisplay from "./content/time_clock/TimeEntriesDisplay";
import TimeEntriesDisplayEmployee from "./content/time_clock/TimeEntriesDisplayEmployee";
import EmployeesInformationDisplay from "./content/employees_information/EmployeesInformationDisplay";
import PublishedScheduleDisplay from "./content/published_schedule/PublishedScheduleDisplay";
import UserProfileEmployeeDisplay from "./content/employees_information/UserProfileEmployeeDisplay";
import CreateTrainingContentDisplay from "./content/create_training_content/CreateTrainingContentDisplay";
import TakeTrainingEmployeeDisplay from "./content/take_training_employee/TakeTrainingEmployeeDisplay";
import TrainingOverviewDisplay from "./content/training_overview/TrainingOverviewDisplay";
import ChatDisplay from "./content/chat/ChatDisplay";
import chatClient from "./content/chat/ChatClient";
import { Chat } from "stream-chat-react";
import LogbookDisplay from "./content/logbook/LogbookDisplay";
import ViewLogbookDisplay from "./content/logbook/ViewLogbookDisplay";
import ConfigureLogbookDisplay from "./content/logbook/ConfigureLogbookDisplay";
import OverviewDisplay from "./content/overview/OverviewDisplay";

function MainDisplay(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Right now, when employee, admin, or manager logs in on small screen size, goes straight to employee training
  const [currentTab, setCurrentTab] = React.useState(
    props.permissionRole === "company_timeclock"
      ? "timeClock"
      : props.permissionRole === "company_employee"
      ? windowWidth > 600
        ? "employeeHome"
        : "takeTrainingEmployee"
      : windowWidth > 600
      ? "home"
      : "takeTrainingEmployee"
  );

  function renderTabDisplay() {
    if (currentTab === "home") {
      return (
        <ContentDisplay
          storeOptions={props.storeOptions}
          homeStore={props.homeStore}
          storeNames={props.storeNames}
          change_home_store={props.change_home_store}
          breakWaiverEnabled={props.breakWaiverEnabled}
          defaultBreakLengthHours={props.defaultBreakLengthHours}
          tplhEnabled={props.tplhEnabled}
          editShiftsInPastEnabled={props.editShiftsInPastEnabled}
          copilotEnabled={props.copilotEnabled}
          defaultDailyCardChartEnabled={props.defaultDailyCardChartEnabled}
          windowWidth={windowWidth}
          minimizedViewDefaultEnabled={props.minimizedViewDefaultEnabled}
        />
      );
    }
    if (currentTab === "overview") {
      return (
        <OverviewDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
          laborPercentEnabled={props.laborPercentEnabled}
        />
      );
    }
    if (currentTab === "settings") {
      return (
        <SettingsMain
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "goals") {
      return (
        <GoalsDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "plan") {
      return (
        <DailyPlanDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "planConfiguration") {
      return (
        <DailyPlanTaskConfigurationDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "actions") {
      return (
        <RequestsDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
        />
      );
    }
    if (currentTab === "employeeHome") {
      return (
        <RequestsDisplayEmployee
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
        />
      );
    }
    if (currentTab === "configurations") {
      return (
        <ConfigurationsDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "departmentRoleStationSettings") {
      return (
        <DepartmentRoleStationSettingsDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
        />
      );
    }
    if (currentTab === "locationsIntegrationsHoursSettings") {
      return (
        <LocationsIntegrationsHoursSettingsDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "reporting") {
      return (
        <ReportingDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
        />
      );
    }
    if (currentTab === "laborModel") {
      return (
        <LaborModelOuterDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
        />
      );
    }
    if (currentTab === "timeEntries") {
      return (
        <TimeEntriesDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          setCurrentTab={setCurrentTab}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "timeEntriesEmployee") {
      return (
        <TimeEntriesDisplayEmployee
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          setCurrentTab={setCurrentTab}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "timeClock") {
      return (
        <TimeClockDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          change_home_store={props.change_home_store}
        />
      );
    }
    if (currentTab === "employeesInformation") {
      return (
        <EmployeesInformationDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
          timeEntriesEnabled={props.timeEntriesEnabled}
        />
      );
    }
    if (currentTab === "userProfileEmployee") {
      return (
        <UserProfileEmployeeDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
          timeEntriesEnabled={props.timeEntriesEnabled}
        />
      );
    }
    if (currentTab === "publishedSchedule") {
      return (
        <PublishedScheduleDisplay
          homeStore={props.homeStore}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
        />
      );
    }
    if (currentTab === "createTrainingContent") {
      return (
        <CreateTrainingContentDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
        />
      );
    }

    if (currentTab === "takeTrainingEmployee") {
      return (
        <TakeTrainingEmployeeDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
        />
      );
    }

    if (currentTab === "trainingOverview") {
      return (
        <TrainingOverviewDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
        />
      );
    }

    if (currentTab === "chat") {
      return (
        <Chat client={chatClient}>
          <ChatDisplay
            homeStore={props.homeStore}
            homeCompany={props.homeCompany}
            storeOptions={props.storeOptions}
            storeNames={props.storeNames}
            username={props.username}
            change_home_store={props.change_home_store}
            permissionRole={props.permissionRole}
            managerCreateChatEnabled={props.managerCreateChatEnabled}
            employeeCreateChatEnabled={props.employeeCreateChatEnabled}
          />
        </Chat>
      );
    }

    if (currentTab === "logbook") {
      return (
        <ViewLogbookDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
          laborPercentEnabled={props.laborPercentEnabled}
        />
      );
    }

    if (currentTab === "logbookConfiguration") {
      return (
        <ConfigureLogbookDisplay
          homeStore={props.homeStore}
          homeCompany={props.homeCompany}
          storeOptions={props.storeOptions}
          storeNames={props.storeNames}
          username={props.username}
          change_home_store={props.change_home_store}
          permissionRole={props.permissionRole}
          laborPercentEnabled={props.laborPercentEnabled}
        />
      );
    }
  }

  return (
    <div>
      {currentTab !== "timeClock" &&
      props.permissionRole !== "company_timeclock" &&
      windowWidth > 640 ? (
        <div>
          <NavigationSideBar
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            username={props.username}
            permissionRole={props.permissionRole}
            homeStore={props.homeStore}
            timeEntriesEnabled={props.timeEntriesEnabled}
            trainingEnabled={props.trainingEnabled}
            chatEnabled={props.chatEnabled}
            logbookEnabled={props.logbookEnabled}
            dailyPlanEnabled={props.dailyPlanEnabled}
          />
          <div style={{ marginLeft: "60px" }}>{renderTabDisplay()}</div>
        </div>
      ) : (
        <div>{renderTabDisplay()}</div>
      )}
    </div>
  );
}

export default MainDisplay;
