import React, { useState } from "react";
import TimeSlider from "./TimeSlider";
import axios from "axios";
import { getDefaultAxiosConfig } from "../../../../../../utilities/requests";

const axiosConfig = getDefaultAxiosConfig();

const DailyPlan = ({ data, schedule }) => {
  const [qhNum, setQhNum] = useState(0);
  const [assignedRoles, setAssignedRoles] = useState({});

  const handleTimeChange = (value) => {
    setQhNum(Math.round((value / 60) * 4) / 4);
  };

  const getCurrentData = () => {
    return data.find((item) => item.qh_num === qhNum);
  };

  const getFilteredSchedule = () => {
    return schedule.filter(
      (item) => item.shift_start <= qhNum && qhNum < item.shift_end
    );
  };

  const isOnBreak = (person, qhNum) => {
    const { break_start, break_length } = person;
    return break_start <= qhNum && qhNum < break_start + break_length;
  };

  const currentData = getCurrentData();
  const filteredSchedule = getFilteredSchedule();

  const getCardBgColor = (role) => {
    return role.startsWith("FOH") ? "bg-blue-200" : "bg-green-200";
  };

  const onDragStart = (event, name) => {
    event.dataTransfer.setData("name", name);
  };

  const onDrop = (event, roleIndex) => {
    event.preventDefault();

    const name = event.dataTransfer.getData("name");
    const fromRoleIndex = parseInt(event.dataTransfer.getData("roleIndex"), 10);

    console.log("FROM NAME: " + name);
    console.log("FROM INDEX: " + fromRoleIndex);
    console.log(assignedRoles);

    // Swap names if needed
    let swapName =
      assignedRoles[qhNum] && assignedRoles[qhNum][roleIndex]
        ? assignedRoles[qhNum][roleIndex]
        : null;

    console.log("TO NAME: " + swapName);
    console.log("TO INDEX: " + roleIndex);

    if (!isNaN(qhNum) && !isNaN(fromRoleIndex)) {
      if (swapName) {
        const newAssignedRolesFromQh = { ...assignedRoles[qhNum] };
        newAssignedRolesFromQh[fromRoleIndex] = swapName;
        newAssignedRolesFromQh[roleIndex] = name;
        setAssignedRoles((prevAssignedRoles) => ({
          ...prevAssignedRoles,
          [qhNum]: newAssignedRolesFromQh,
        }));
      } else {
        // Remove the name from the previous role card
        const newAssignedRolesFromQh = { ...assignedRoles[qhNum] };
        delete newAssignedRolesFromQh[fromRoleIndex];
        newAssignedRolesFromQh[roleIndex] = name;
        setAssignedRoles((prevAssignedRoles) => ({
          ...prevAssignedRoles,
          [qhNum]: newAssignedRolesFromQh,
        }));
      }
    } else {
      setAssignedRoles((prevAssignedRoles) => ({
        ...prevAssignedRoles,
        [qhNum]: { ...prevAssignedRoles[qhNum], [roleIndex]: name },
      }));
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const unassignRole = (roleIndex) => {
    const newAssignedRoles = { ...assignedRoles[qhNum] };
    delete newAssignedRoles[roleIndex];
    setAssignedRoles({ ...assignedRoles, [qhNum]: newAssignedRoles });
  };

  const clearAssignments = () => {
    setAssignedRoles({ ...assignedRoles, [qhNum]: {} });
  };

  const isAssigned = (name) => {
    return (
      assignedRoles[qhNum] && Object.values(assignedRoles[qhNum]).includes(name)
    );
  };

  const onAssignedRoleDragStart = (event, qhNum, roleIndex) => {
    event.dataTransfer.setData("name", assignedRoles[qhNum][roleIndex]);
    event.dataTransfer.setData("qhNum", qhNum);
    event.dataTransfer.setData("roleIndex", roleIndex);
  };

  const autoAssign = () => {
    const maxQhNum = 1440 / 15; // 1440 minutes in a day, divided by 15-minute intervals
    const newAssignedRoles = {};

    for (let qhNum = 0; qhNum < maxQhNum; qhNum++) {
      const quarterHour = qhNum / 4;
      const currentData = data.find((item) => item.qh_num === quarterHour);
      const filteredSchedule = schedule.filter(
        (item) =>
          item.shift_start <= quarterHour && quarterHour < item.shift_end
      );

      const namesToAssign = filteredSchedule.filter(
        (person) => !isOnBreak(person, quarterHour)
      );

      const currentRoles = currentData ? currentData.role_list_arr : [];

      newAssignedRoles[quarterHour] = namesToAssign.reduce(
        (assigned, person, index) => {
          const roleIndex = index % currentRoles.length;
          return { ...assigned, [roleIndex]: person.name };
        },
        {}
      );
    }

    setAssignedRoles(newAssignedRoles);
  };

  const saveAssignments = async () => {
    const assignmentsArray = [];

    Object.entries(assignedRoles).forEach(([qh, roles]) => {
      Object.entries(roles).forEach(([roleIndex, name]) => {
        const currentDataItem = data.find(
          (item) => item.qh_num === parseInt(qh, 10)
        );
        assignmentsArray.push({
          qh,
          role: currentDataItem
            ? currentDataItem.role_list_arr[parseInt(roleIndex, 10)]
            : undefined,
          name,
        });
      });
    });

    console.log("Formatted assignments:", assignmentsArray);

    // Replace with your API endpoint
    const apiUrl = "https://api.example.com/save_assignments";

    try {
      const response = await axios.post(
        apiUrl,
        { assignments: assignmentsArray },
        axiosConfig
      );
      console.log("API response:", response.data);
    } catch (error) {
      console.error("Error saving assignments:", error);
    }
  };

  return (
    <div className="daily-plan grid grid-cols-2 gap-8 pb-8">
      <div className="left-section max-h-80vh overflow-auto">
        <TimeSlider min={0} max={1440} step={15} onChange={handleTimeChange} />
        {currentData && (
          <div className="mt-4">
            <p>Recommended Labor: {currentData.labor}</p>
            <p>Actual Labor: {currentData.actual_labor}</p>
          </div>
        )}
        {currentData && (
          <div className="role-cards grid grid-cols-2 gap-4 mt-8">
            {currentData.role_list_arr.map((role, index) => {
              const routineStr = currentData.routine_list_arr[index];
              const routineList = routineStr
                .split("-")[1]
                .split(",")
                .map((r) => r.trim());

              return (
                <div
                  key={index}
                  className={`role-card p-4 border border-gray-300 rounded ${getCardBgColor(
                    role
                  )} relative`}
                  onDrop={(event) => onDrop(event, index)}
                  onDragOver={onDragOver}
                >
                  <div className="font-semibold">{role}</div>
                  <ul
                    className="text-sm text-gray-600 mt-2"
                    style={{ listStyleType: "disc", marginLeft: "1.5rem" }}
                  >
                    {routineList.map((routine, routineIndex) => (
                      <li key={routineIndex}>{routine}</li>
                    ))}
                  </ul>
                  {assignedRoles[qhNum] && assignedRoles[qhNum][index] && (
                    <div
                      className="absolute top-0 right-0 bg-white p-1 text-xs cursor-pointer"
                      onClick={() => unassignRole(index)}
                      draggable
                      onDragStart={(event) =>
                        onAssignedRoleDragStart(event, qhNum, index)
                      }
                    >
                      {assignedRoles[qhNum][index]}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="right-section max-h-80vh overflow-auto">
        <h2 className="text-xl font-bold mb-4">On Shift:</h2>
        <ul>
          {filteredSchedule.map(
            (person, index) =>
              !isAssigned(person.name) && (
                <li
                  key={index}
                  className={`mb-2 p-2 border border-gray-300 rounded ${
                    isOnBreak(person, qhNum) ? "text-gray-500" : ""
                  }`}
                  draggable
                  onDragStart={(event) => {
                    if (!isOnBreak(person, qhNum)) {
                      // Add this condition
                      event.dataTransfer.setData("name", person.name);
                    }
                  }}
                >
                  {person.name}{" "}
                  {isOnBreak(person, qhNum) && <span>(on break)</span>}
                </li>
              )
          )}
        </ul>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={autoAssign}
        >
          Auto Assign
        </button>
        <button
          className="ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={clearAssignments}
        >
          Clear
        </button>
        <button
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={saveAssignments}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default DailyPlan;
