import React from "react";
import StoreNameSidebar from "../home/top_bar_components/StoreNameSidebar";
import Divider from "@mui/material/Divider";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import DepartmentSettingsDisplay from "./DepartmentSettingsDisplay";
import RoleSettingsDisplay from "./RoleSettingsDisplay";
import StationSettingsDisplay from "./StationSettingsDisplay";
import LoadSpinner from "../../../utilities/LoadSpinner";
import {
  API_get_departments,
  API_get_roles,
  API_get_stations,
  API_put_role_type,
  API_put_role,
  API_put_station,
} from "../../../constants";
import {
  getRightworkAPI,
  postRightworkAPI,
} from "../requests/utilities/requests";
import { getDefaultAxiosConfig } from "../../../utilities/requests";
import axios from "axios";
import TopBarDisplayTitle from "../home/top_bar_components/TopBarDisplayTitle";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DepartmentRoleStationSettingsDisplay(props) {
  const [data, setData] = React.useState([]);
  const [display, setDisplay] = React.useState("Departments");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSavingData, setIsSavingData] = React.useState(false);

  const axiosConfig = getDefaultAxiosConfig();

  const default_api_calls = {
    departments: API_get_departments,
    roles: API_get_roles,
    stations: API_get_stations,
  };

  const api_params = {
    store_id: props.homeStore,
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setData(tempData);
      setIsLoading(false);
      setIsSavingData(false);
    } catch (error) {
      console.log(error);
      //throw Error("Promise failed");
    }
  };

  // ----------- POST APIs ----------- //
  const edit_department = async (data, edit_type) => {
    // Prepare data to send
    let requestData = { ...data, store_id: props.homeStore };
    setIsSavingData(true);

    try {
      if (edit_type === "delete") {
        toast.loading("Deleting Department...", { autoClose: false });
      }

      const res = await axios.put(
        `${API_put_role_type}${props.homeStore}&edit_type=${edit_type}`,
        requestData,
        axiosConfig
      );

      if (edit_type === "delete") {
        toast.dismiss();
        toast.success("Department Deleted Successfully", { autoClose: 3000 });
      }

      fetch_data(default_api_calls, api_params, false);
    } catch (error) {
      if (edit_type === "delete") {
        toast.dismiss();

        if (error.response?.data?.system?.message) {
          toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
          console.log("Error Info", error.response?.data?.system?.data);
        } else {
          toast.error("Request Failed", { autoClose: 3000 });
        }
      } else {
        alert("Something went wrong, please try again later.");
      }

      console.error("Error:", error); // Handle error as needed
    } finally {
      setIsSavingData(false);
    }
  };


  const edit_role = async (data, color, edit_type) => {
    // Prepare data to send
    let requestData = { ...data, store_id: props.homeStore, color: color };
    setIsSavingData(true);

    try {
      if (edit_type === "delete") {
        toast.loading("Deleting Role...", { autoClose: false });
      }

      const res = await axios.put(
        `${API_put_role}${props.homeStore}&edit_type=${edit_type}`,
        requestData,
        axiosConfig
      );

      if (edit_type === "delete") {
        toast.dismiss();
        toast.success("Role Deleted Successfully", { autoClose: 3000 });
      }

      fetch_data(default_api_calls, api_params, false);
    } catch (error) {
      if (edit_type === "delete") {
        toast.dismiss();

        if (error.response?.data?.system?.message) {
          toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
          console.log("Error Info", error.response?.data?.system?.data);
        } else {
          toast.error("Request Failed", { autoClose: 3000 });
        }
      } else {
        alert("Something went wrong, please try again later.");
      }

      console.error("Error:", error); // Handle error as needed
    } finally {
      setIsSavingData(false);
    }
  };


  const edit_station = async (data, edit_type) => {
    // Prepare data to send
    let requestData = { ...data, store_id: props.homeStore };
    setIsSavingData(true);

    try {
      if (edit_type === "delete") {
        toast.loading("Deleting Station...", { autoClose: false });
      }

      const res = await axios.put(
        `${API_put_station}${props.homeStore}&edit_type=${edit_type}`,
        requestData,
        axiosConfig
      );

      if (edit_type === "delete") {
        toast.dismiss();
        toast.success("Station Deleted Successfully", { autoClose: 3000 });
      }

      fetch_data(default_api_calls, api_params, false);
    } catch (error) {
      if (edit_type === "delete") {
        toast.dismiss();

        if (error.response?.data?.system?.message) {
          toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
          console.log("Error Info", error.response?.data?.system?.data);
        } else {
          toast.error("Request Failed", { autoClose: 3000 });
        }
      } else {
        alert("Something went wrong, please try again later.");
      }

      console.error("Error:", error); // Handle error as needed
    } finally {
      setIsSavingData(false);
    }
  };


  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
  }, [props.homeStore]);

  console.log(data);

  const store_name = props.storeNames.filter(
    (s) => s.id === props.homeStore
  )[0]["store_name"];

  return (
    <>
      {!isLoading ? (
        <div className="w-full min-h-screen flex">
          <div className="w-1/5">
            <div className="h-full flex flex-col gap-2 px-6 py-12 bg-slate-50 border-r border-slate-200">
              {/* <div>
                <StoreNameSidebar
                  storeOptions={props.storeOptions}
                  homeStore={props.homeStore}
                  storeNames={props.storeNames}
                  change_home_store={props.change_home_store}
                />
              </div> */}
              {/* <button
          className={`mt-12 w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
            display === "Import" ? "bg-white" : ""
          }`}
          onClick={() => setDisplay("Import")}
        >
          <div
            className={`flex flex-1 items-center space-x-4 ${
              display === "Import"
                ? "text-violet-700"
                : "text-slate-500"
            }`}
          >
            <div>
              <FileDownloadOutlinedIcon />
            </div>
            <span>Import Settings</span>
          </div>
          {shiftPoolRequests?.length > 0 && (
            <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
              {shiftPoolRequests?.length}
            </span>
          )}
        </button> */}
              {/* <div className="mt-8">
          <Divider />
        </div> */}
              <button
                className={`w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
                  display === "Departments" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Departments")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Departments"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <FolderOpenOutlinedIcon />
                  </div>
                  <span>Departments</span>
                </div>
                {/* {shiftPoolRequests?.length > 0 && (
            <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
              {shiftPoolRequests?.length}
            </span>
          )} */}
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Roles" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Roles")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Roles" ? "text-violet-700" : "text-slate-500"
                  }`}
                >
                  <div>
                    <PeopleOutlinedIcon />
                  </div>
                  <span>Roles</span>
                </div>
                {/* {availabilityRequests?.pending?.length > 0 && (
            <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
              {availabilityRequests?.pending?.length}
            </span>
          )} */}
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Stations" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Stations")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Stations"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <DesktopWindowsOutlinedIcon />
                  </div>
                  <span>Stations</span>
                </div>
                {/* {timeOffRequests?.length > 0 && (
            <span className="text-slate-500 rounded-full flex items-center justify-center text-sm">
              {timeOffRequests?.length}
            </span>
          )} */}
              </button>
            </div>
          </div>
          <div className="w-4/5">
            <div>
              <TopBarDisplayTitle
                title={display}
                storeOptions={props.storeOptions}
                homeStore={props.homeStore}
                storeNames={props.storeNames}
                change_home_store={props.change_home_store}
              />
            </div>
            {display === "Departments" ? (
              <DepartmentSettingsDisplay
                departments={data["departments"]}
                roles={data["roles"]}
                isSavingData={isSavingData}
                edit_department={edit_department}
                store_name={store_name}
                permissionRole={props.permissionRole}
              />
            ) : display === "Roles" ? (
              <RoleSettingsDisplay
                departments={data["departments"]}
                roles={data["roles"]}
                isSavingData={isSavingData}
                edit_role={edit_role}
                store_name={store_name}
                permissionRole={props.permissionRole}
              />
            ) : display === "Stations" ? (
              <StationSettingsDisplay
                stations={data["stations"]}
                roles={data["roles"]}
                isSavingData={isSavingData}
                edit_station={edit_station}
                store_name={store_name}
                permissionRole={props.permissionRole}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default DepartmentRoleStationSettingsDisplay;
