import React, { useState, useEffect } from "react";
import DailyTopBarDisplay from "./daily_top_bar_components/DailyTopBarDisplay";
import DailyTopBarDisplayDailyPlan from "./daily_top_bar_components/DailyTopBarDisplayDailyPlan";
import DailyPlanInfo from "./plan_contents/DailyPlanInfo";
import DailyPlanInfo2 from "./plan_contents/DailyPlanInfo2";
import DailyPlanTaskConfiguration from "./plan_contents/DailyPlanTaskConfiguration";
import axios from "axios";
import {
  API_get_actual_schedule,
  API_get_store_team_members,
  API_get_role_types,
  API_get_role_names,
  API_get_plan_tasks_for_date,
  API_assign_plan_task,
  API_unassign_plan_task
} from "../../../constants";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { getDefaultAxiosConfig } from "../../../utilities/requests";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosConfig = getDefaultAxiosConfig();

function DailyPlanDisplay(props) {

  const [date, setDate] = useState(new Date());
  const [roleType, setRoleType] = React.useState([]);
  const [roleTypeOptions, setRoleTypeOptions] = React.useState([]);
  const [scheduledEmployees, setScheduledEmployees] = useState([]);
  const [storeTeamMembers, setStoreTeamMembers] = useState([]);
  const [deploymentRoles, setDeploymentRoles] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [roleNames, setRoleNames] = useState([])
  const [dailyTasks, setDailyTasks] = React.useState([])
  // Add a new state variable to track if data has been loaded
  const [isLoading, setIsLoading] = useState(false);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  function increment_day(date, days) {
    const new_date = addDays(date, days);
    setDate(new_date);
  }

  const fetchData = async (isLoading) => {
    setIsLoading(isLoading);
    await fetchScheduledEmployees();
    await fetchStoreTeamMembers();
    await fetchRoleTypes();
    await fetchRoleNames();
    await fetchDailyTasks();
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [props.homeStore]);

  useEffect(() => {
    fetchData(true);
  }, [date, props.homeStore]);


  const fetchScheduledEmployees = async () => {
    const response = await axios.get(
      `${API_get_actual_schedule}${props.homeStore}&date_start=${formatDate(
        date.toString()
      )}&date_end=${formatDate(date.toString())}`,
      axiosConfig
    );
    setScheduledEmployees(response.data);
  };

  const fetchStoreTeamMembers = async () => {
    const response = await axios.get(
      `${API_get_store_team_members}${props.homeStore}`,
      axiosConfig
    );
    setStoreTeamMembers(response.data);
  };


  const fetchRoleTypes = async () => {
    const response = await axios.get(
      `${API_get_role_types}${props.homeStore}`,
      axiosConfig
    );
    setRoleTypes(response.data);
    const newRoleTypeOptions = response.data.map(function (i) {
      return i.role_type;
    });
    setRoleTypeOptions(newRoleTypeOptions);
    if (roleType.length === 0) {
      setRoleType(newRoleTypeOptions);
    }
  };

  const fetchRoleNames = async () => {
    const response = await axios.get(
      `${API_get_role_names}${props.homeStore}`,
      axiosConfig
    );
    setRoleNames(response.data);
  };

  const fetchDailyTasks = async () => {
    const response = await axios.get(`${API_get_plan_tasks_for_date}?store_id=${props.homeStore}&date=${formatDate(date)}`, axiosConfig)
    const data = response.data?.redux?.payload?.plan_tasks;
    setDailyTasks(data);
  }

  const joinData = (scheduledEmployees, storeTeamMembers) => {
    return scheduledEmployees.map((employee) => {
      const teamMember = storeTeamMembers.find(
        (member) => member.id === employee.store_team_member
      );
      return {
        ...employee,
        name: teamMember?.first_name + " " + teamMember?.last_name,
      };
    });
  };


  const getRolesByType = (typeId) => {
    return deploymentRoles.filter((role) => role.role_type_id === typeId);
  };

  const combinedData = joinData(scheduledEmployees, storeTeamMembers);

  console.log("COMBINED DATA", combinedData)

  const filterByRoleType = (data, roleTypeArray) => {
    return data.filter((item) => roleTypeArray.includes(item.role_type));
  };

  const filteredCombinedData = filterByRoleType(combinedData, roleType);
  const filteredDeploymentRoles = filterByRoleType(deploymentRoles, roleType);
  const filteredRoleTypes = filterByRoleType(roleTypes, roleType);

  console.log("ROLE TYPE OPTIONS", roleTypeOptions)


  console.log("DAILY TASKS", dailyTasks)

   // ----------- POST APIs ----------- //

  //  * store_id (auth)
  //  * plan_task_uuid
  //  * date
  //  * store_team_member_id
  const assign_plan_task = async (
    plan_task_uuid,
    date,
    store_team_member_id,
    published_schedule_id
  ) => {
    // Prepare data to send
    const requestData = {
      plan_task_uuid: plan_task_uuid,
      store_id: props.homeStore,
      date: date,
      store_team_member_id: store_team_member_id,
      published_schedule_id: published_schedule_id
    };

    try {
      toast.loading("Saving Task Assignment...", { autoClose: false });

      const res = await postRightworkAPI(API_assign_plan_task, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Task Assignment Saved Successfully", { autoClose: 3000 });

        fetchData(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // * store_id (auth)
  // * plan_task_assignment_uuid
  const unassign_plan_task = async (
    plan_task_assignment_uuid,
  ) => {
    // Prepare data to send
    const requestData = {
      plan_task_assignment_uuid: plan_task_assignment_uuid,
      store_id: props.homeStore,
    };

    try {
      toast.loading("Unassigning Task...", { autoClose: false });

      const res = await postRightworkAPI(API_unassign_plan_task, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Task Unassigned Successfully", { autoClose: 3000 });

        fetchData(false);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };





  return (
    <div className="my-5">
      <div className="mx-10">
        <DailyTopBarDisplayDailyPlan
          date={date}
          increment_day={increment_day}
          roleType={roleType}
          setRoleType={setRoleType}
          storeOptions={props.storeOptions}
          homeStore={props.homeStore}
          storeNames={props.storeNames}
          change_home_store={props.change_home_store}
          roleTypeOptions={roleTypeOptions}
          view="daily_plan"
        />
      </div>
      {!isLoading ? (
        <div>
          <div className="mt-10 mx-10">
            <DailyPlanInfo2
              combinedData={filteredCombinedData}
              deploymentRoles={filteredDeploymentRoles}
              getRolesByType={getRolesByType}
              roleTypes={filteredRoleTypes}
              roleNames={roleNames}
              dailyTasks={dailyTasks}
              assign_plan_task={assign_plan_task}
              unassign_plan_task={unassign_plan_task}
              date={date}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </div>
  );
}

export default DailyPlanDisplay;
