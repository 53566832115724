import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import DefaultAvailabilityCard from "../../shared/availability/DefaultAvailabilityCard.js";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";
import { formatDateReadable } from "../../utilities/helpers.js";
import { getInitials } from "../../utilities/helpers.js";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import { Drawer } from "@mui/material";
import AvatarSmall from "../../shared/helpers/AvatarSmall.js";
import DrawerHeader from "../../shared/helpers/DrawerHeader.js";
import DrawerFooterContainerLarge from "../../shared/helpers/DrawerFooterContainerLarge.js";
import ButtonFilled from "../../shared/helpers/ButtonFilled.js";
import ButtonOutlined from "../../shared/helpers/ButtonOutlined.js";
import StatusWithText from "../../shared/helpers/StatusWithText.js";

export default function TimeOffRequestsTableRow(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSubmit = (response) => {
    let request_uuid = props.uuid;
    props.respond_to_time_off_request(request_uuid, response);
    handleClose();
    setDrawerOpen(false);
  };

  return (
    <>
      <tr
        className="hover:bg-slate-50 cursor-pointer"
        onClick={toggleDrawer(true)}
      >
        <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
          <div className="flex items-center">
            <AvatarSmall
              initials={getInitials(props.firstName, props.lastName)}
            />
            <div className="ml-4">
              <div className="font-medium text-slate-900">
                {props.firstName} {props.lastName}
              </div>
            </div>
          </div>
        </td>
        {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-500">
          <div className="text-slate-900">{props.request.dateSubmitted}</div>
        </td> */}
        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
          <div className="flex items-center space-x-1">
            <p>{formatDateReadable(props.startDate)}</p>
            {props.endDate && (
              <div className="flex items-center space-x-1">
                <div className="text-slate-900">
                  <ArrowForwardIcon style={{ fontSize: "16px" }} />
                </div>
                <p>{formatDateReadable(props.endDate)}</p>
              </div>
            )}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
          <div className="text-slate-900">{props.title}</div>
        </td>
        <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium">
          {props.note ? (
            <span className="text-slate-500">
              <Tooltip title={props.note}>
                <CommentIcon />
              </Tooltip>
            </span>
          ) : (
            <span className="text-slate-500">
              No note
            </span>
          )}
          {/* <button className="bg-primary hover:bg-blue-700 text-white px-5 py-2 rounded-lg ml-8" onClick={handleOpen}>Approve / Deny</button> */}
        </td>
      </tr>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-2xl font-semibold">Time Off Request</h2>
          <div>
            <div className="mt-4">
              <Divider />
            </div>
            <div className="flex items-center mt-3">
              <div className="h-14 w-14 flex-shrink-0">
                <div className="h-14 w-14 rounded-full bg-primary text-white flex items-center justify-center">
                  {getInitials(props.firstName, props.lastName)}
                </div>
              </div>
              <div className="ml-4">
                <p className="font-medium text-slate-900">
                  {props.firstName} {props.lastName}
                </p>
                <p className="text-sm text-slate-500">{props.title}</p>
                <p className="text-sm text-slate-500">
                  {formatDateReadable(props.startDate)}{" "}
                  {props.endDate && `- ${formatDateReadable(props.endDate)}`}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <Divider />
            </div>
            {props.note && (
              <div className="w-full mt-4">
                <h3 className="text-base font-semibold">Note</h3>
                <p className="text-sm mt-1">{props.note}</p>
              </div>
            )}
            <div className="w-full mt-6 flex justify-end">
              <div className="flex items-center gap-5">
                <button
                  className="px-8 py-3 rounded-lg border border-primary text-primary hover:bg-slate-100 text-sm"
                  onClick={() => handleSubmit("REJECTED")}
                >
                  Deny
                </button>
                <button
                  className="px-8 py-3 rounded-lg bg-primary hover:bg-blue-700 text-white text-sm"
                  onClick={() => handleSubmit("APPROVED")}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-4 top-4 text-slate-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-screen bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title="Time Off Request"
              onClose={toggleDrawer(false)}
            />
            <div className="px-6">
              <div className="mt-5">
                <h3 className="text-slate-500 text-base">Name</h3>
                <p className="text-slate-900 text-xl mt-2">
                  {props.firstName} {props.lastName}
                </p>
              </div>
              <div className="mt-5">
                <h3 className="text-slate-500 text-base">Title</h3>
                <p className="text-slate-900 text-xl mt-2">{props.title}</p>
              </div>
              <div className="mt-5">
                <h3 className="text-slate-500 text-base">Note</h3>
                <p className="text-slate-900 text-xl mt-2">
                  {props.note ? props.note : "No note submitted"}
                </p>
              </div>
              <div className="mt-5">
                <h3 className="text-slate-500 text-base">Time off requested</h3>
                <div className="flex items-center space-x-1 text-slate-900 text-xl mt-2">
                  <p>{formatDateReadable(props.startDate)}</p>
                  {props.endDate && (
                    <div className="flex items-center space-x-1">
                      <div className="text-slate-900">
                        <ArrowForwardIcon style={{ fontSize: "16px" }} />
                      </div>
                      <p>{formatDateReadable(props.endDate)}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <h3 className="text-slate-500 text-base">Status</h3>
                <div className="mt-3">
                  <StatusWithText status="pending" />
                </div>
              </div>
            </div>
            <div className="h-[200px]"></div>
          </div>
          <DrawerFooterContainerLarge>
            <div className="w-full">
              <ButtonFilled
                text="Approve"
                onClick={() => handleSubmit("APPROVED")}
              />
            </div>
            <div className="w-full mt-3">
              <ButtonOutlined
                text="Deny"
                onClick={() => handleSubmit("REJECTED")}
              />
            </div>
          </DrawerFooterContainerLarge>
        </div>
      </Drawer>
    </>
  );
}
