import React from "react";
import InfoIcon from '@mui/icons-material/Info';

function NoStoreTeamMemberDisplay(props) {
  const formatRoleType = () => {
    const { roleType } = props;
    if (roleType.length === 1) {
      return roleType[0];
    } else if (roleType.length === 2) {
      return `${roleType[0]} or ${roleType[1]}`;
    } else {
      return roleType.slice(0, -1).join(', ') + `, or ${roleType.slice(-1)}`;
    }
  };

  return (
    <div className="w-full mt-48 flex items-center justify-center">
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
          <InfoIcon />
        </div>
        <div className="w-72">
          <p className="mt-2 text-sm text-center">There are no store team members assigned to <span className="font-bold">{formatRoleType()}</span>. Either add employees to {formatRoleType()} or select a different department.</p>
        </div>
      </div>
    </div>
  );
}

export default NoStoreTeamMemberDisplay;
