import React, { useState } from "react";

const BatchGuidanceTable = (props) => {
  // Function to render a data row
  const showDataRow = (name, bold) => {
    return (
      <tr key={name}>
        <td
          className={
            "border px-4 py-1.5 text-sm whitespace-nowrap bg-gray-100 " + bold
          }
        >
          {name}
        </td>
        {filteredBatchGuidance[name].map((entry, index) => (
          <td key={index} className="border">
            <div
              className={
                "h-full w-full flex items-center justify-center text-sm m-auto px-[7px]" +
                bold
              }
            >
              <p>{Math.round(entry.total_value)}</p>
            </div>
          </td>
        ))}
      </tr>
    );
  };

  // Step 1: Extract and filter relevant data
  const allMetrics = Object.keys(props.data || {}).flatMap((key) =>
    props.data[key].filter((i) => i.total_value > 0)
  );

  // Step 2: Compute min and max qh_nums
  const allQhNums = allMetrics.map((d) => d.qh_num);

  const start_qhnum = Math.floor(Math.min(...allQhNums));
  const end_qhnum = Math.ceil(Math.max(...allQhNums) + 0.25);

  // Step 3: Filter the dataset
  const filteredBatchGuidance = {};
  Object.keys(props.data || {}).forEach((item) => {
    filteredBatchGuidance[item] = props.data[item].filter(
      (i) => i.qh_num >= start_qhnum && i.qh_num < end_qhnum
    );
  });

  // Generate hour column headers based on available items
  const hourColumnHeaders =
    props.items.length > 0 ? (
      filteredBatchGuidance[props.items[0]]
        //.filter((entry, index) => index % 4 === 0)
        .map((entry, index) => {
          return (
            <th
              key={index}
              className="border px-1 py-2 bg-primary text-white text-xs"
              colSpan={1}
            >
              {props.get_t_str(entry.qh_num)}
            </th>
          );
        })
    ) : (
      <div></div>
    );

  return (
    <div>
      {props.items.length > 0 && (
        <div className="w-full">
          <h3 className="mt-3 font-bold">Item Forecasted Quantities</h3>
          <div className="mt-3 mr-12">
            <div className="flex flex-wrap items-center w-full">
              <table className="table-auto border-collapse w-full">
                <thead>
                  <tr>
                    <th className="border px-1 py-3 bg-primary text-white text-xs"></th>
                    {hourColumnHeaders}
                  </tr>
                </thead>
                <tbody>
                  {props.items.map((item) => showDataRow(item, ""))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BatchGuidanceTable;
