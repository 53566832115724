import React from "react";
import TabsThree from "./shared/helpers/TabsThree";
import TimeOffRequestsTable from "./manager/time_off/TimeOffRequestsTable";
import TimeOffApprovedTable from "./manager/time_off/TimeOffApprovedTable";
import TimeOffMineTable from "./shared/availability/TimeOffMineTable";
import RequestHeader from "./shared/helpers/RequestHeader";

export default function TimeOffDisplay(props) {
  const [activeTab, setActiveTab] = React.useState("Requests");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  const timeOffRequests = props.timeOffData.filter(
    (v, i) => v.status === "PENDING"
  );

  const timeOffHistory = props.timeOffData
    .filter(
      (v, i) =>
        (v.status === "APPROVED" || v.status === "REJECTED") &&
        v.store_team_member.id !== props.myId
    )

  const myTimeOffData = props.timeOffData.filter(
    (v, i) => v.store_team_member.id === props.myId
  );

  // const today = new Date();
  const timeOffFutureApproved = props.timeOffData.filter(
    (v) => (v.status === "APPROVED" || v.status === "PENDING")
      // new Date(v.date_lower_bound) >= today
  );

  console.log("TIMEOFFDATA", props.timeOffData)

  return (
    <div>
      {/* <RequestHeader title="Time Off" /> */}
      <div className="w-1/2 mt-12 px-6">
        <TabsThree
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Requests"
          tab2="Acted On"
          tab3="Mine"
        />
      </div>
      <div className="w-full">
        {activeTab === "Requests" ? (
          <TimeOffRequestsTable
            timeOffRequests={timeOffRequests}
            respond_to_time_off_request={props.respond_to_time_off_request}
            timeOffFutureApproved={timeOffFutureApproved}
          />
        ) : activeTab === "Acted On" ? (
          <TimeOffApprovedTable
            timeOffHistory={timeOffHistory}
            archive_time_off_request={props.archive_time_off_request}
            timeOffFutureApproved={timeOffFutureApproved}
          />
        ) : activeTab === "Mine" ? (
          <TimeOffMineTable
            myTimeOffData={myTimeOffData}
            request_time_off={props.request_time_off}
            archive_time_off_request={props.archive_time_off_request}
            rescind_time_off_request={props.rescind_time_off_request}
            minEffectiveDate={props.minEffectiveDate}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
