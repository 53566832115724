import * as React from "react";
import OpenShiftInputsModal from "./OpenShiftInputsModal";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import LoadSpinner from "../../../../utilities/LoadSpinner";

function NewOpenShiftCard(props) {
  // Shows modal when set to true
  const [open, setOpen] = React.useState(false);

  // Function for opening the modal
  const handleOpen = () => setOpen(true);

  // Function for closing the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveShiftFinished(false);
  };

  const [showPasteButton, setShowPasteButton] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // Default shift information
  const shift_info = {
    adjustment_type: "no_adjust",
    break_length: 0,
    break_start: 12,
    date: props.date_str,
    person: null,
    hours: 4,
    id: 0,
    role_id: props.role_names[0]["id"],
    role_type: props.role_names[0]["role_type"],
    station_id: null,
    station: null,
    shift_end: 16,
    shift_length: 4,
    shift_start: 12,
    store: props.store_id,
    store_team_member: null,
    note: null,
  };

  // Handle right-click context menu
  const handleContextMenu = (event) => {
    props.setCopiedShift(null);
    event.preventDefault();
    if (props.copiedShift) {
      setShowPasteButton((prevState) => !prevState);
    } else {
      setShowPasteButton(false);
    }
  };

  // Handle paste button click
  const handlePaste = (copiedShift) => {
    let new_shift = { ...copiedShift };
    new_shift["person"] = null;
    new_shift["store_team_member"] = null;
    new_shift["store"] = props.store_id;
    new_shift["date"] = props.date_str;
    let appliedTo = [false, false, false, false, false, false, false];
    appliedTo[props.d] = true;
    setIsLoading(true);
    props.addShift(new_shift, "add", appliedTo);
    props.setCopiedShift(null);
  };

  // Handle cut and paste
  const handleCutPaste = (copiedShift) => {
    let new_shift = { ...copiedShift };
    new_shift["person"] = null;
    new_shift["store_team_member"] = null;
    new_shift["store"] = props.store_id;
    new_shift["date"] = props.date_str;
    new_shift["action"] = "add";

    copiedShift["action"] = "delete";
    setIsLoading(true);
    props.addMultipleShifts([copiedShift, new_shift]);
    props.setCopiedShift(null);
  };

  // Handle drop event when shift is dragged and dropped
  const handleDrop = (e, copiedShift) => {
    e.target.classList.remove("border");
    e.target.classList.remove("border-red-500");
    handleCutPaste(copiedShift);
  };

  // Handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    const parentElement = e.currentTarget;
    parentElement.classList.remove("opacity-0");
    parentElement.classList.remove("border-2");
    parentElement.classList.remove("border-dashed");
    parentElement.classList.remove("border-slate-300");
    parentElement.classList.add("border-2");
    parentElement.classList.add("border-red-500");
  };

  // Handle drag leave event
  const handleDragLeave = (e) => {
    e.preventDefault();
    const parentElement = e.currentTarget;
    if (props.weekly_or_daily === "daily") {
      parentElement.classList.add("opacity-0");
    }
    parentElement.classList.remove("border-2");
    parentElement.classList.remove("border-red-500");
    parentElement.classList.add("border-2");
    parentElement.classList.add("border-dashed");
    parentElement.classList.add("border-slate-300");
  };

  React.useEffect(() => {
    if (props.saveShiftFinished) {
      setIsLoading(false);
    }
  }, [props.saveShiftFinished]);

  return (
    <div className="w-full h-full relative">
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      ) : (
        <div className="w-full h-full relative">
          <a
            onClick={
              props.copiedShift
                ? props.copiedShift["copy_type"] === "cut"
                  ? () => handleCutPaste(props.copiedShift)
                  : () => handlePaste(props.copiedShift)
                : handleOpen
            }
            className={
              "cursor-pointer hover:opacity-100 hover:border-violet-700 hover:border-solid hover:bg-white hover:text-violet-700 group w-full h-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-600 font-medium" +
              (props.weekly_or_daily === "daily" &&
                " hover:opacity-70 opacity-0")
            }
            onContextMenu={(event) => handleContextMenu(event)}
            onDrop={(e) => handleDrop(e, props.copiedShift)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {props.weekly_or_daily === "weekly" &&
              (props.copiedShift && false ? (
                <ContentPasteGoIcon
                  style={{
                    fontSize: "16px",
                    color: "currentColor",
                    fill: "currentColor",
                    marginTop: "5px",
                  }}
                />
              ) : (
                <svg
                  className="group-hover:text-violet-700 text-slate-400 mb-1"
                  width="20"
                  height="20"
                  fill="currentColor"
                  aria-hidden="true"
                  style={{ pointerEvents: "none" }}
                >
                  <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
                </svg>
              ))}
            {props.weekly_or_daily === "weekly" && (
              <p
                style={{ pointerEvents: "none" }}
                className={`text-xxs ${true && "mb-[3px]"}`}
              >
                {props.copiedShift ? "Paste shift" : "New open shift"}
              </p>
            )}
            {props.weekly_or_daily === "daily" && (
              <div className="flex items-center justify-between">
                <p
                  style={{ pointerEvents: "none" }}
                  className={
                    props.weekly_or_daily === "daily"
                      ? "text-sm group-hover:text-blue-500"
                      : "text-xxs"
                  }
                >
                  {props.copiedShift ? "Paste shift" : "New open shift"}
                </p>
                {props.copiedShift ? (
                  <ContentPasteGoIcon
                    style={{ fontSize: "16px", marginLeft: "8px" }}
                  />
                ) : (
                  <AddIcon style={{ fontSize: "16px", marginLeft: "8px" }} />
                )}
              </div>
            )}
          </a>
          <OpenShiftInputsModal
            open={open}
            handleClose={handleClose}
            shift_info={shift_info}
            shifts_info={[shift_info]}
            new_shift={true}
            addShift={props.addShift}
            date_name={props.date_name}
            get_t_str={props.get_t_str}
            availability_info={null}
            role_names={props.role_names}
            saveShiftFinished={props.saveShiftFinished}
            setSaveShiftFinished={props.setSaveShiftFinished}
            d={props.d}
            role_types={props.role_types}
            businessWeekStartDayIndex={props.businessWeekStartDayIndex}
            start_qhnum={props.start_qhnum}
            defaultBreakLengthHours={props.defaultBreakLengthHours}
          />
        </div>
      )}
    </div>
  );
}

export default NewOpenShiftCard;
