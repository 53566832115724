import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyIcon from "@mui/icons-material/Key";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ChangePasswordModal from "./ChangePasswordModal";
import ProfileDrawer from "./ProfileDrawer";

import Logout from "@mui/icons-material/ExitToApp";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/auth";

function Account(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutClicked = () => {
    props.logout();
  };

  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const handleOpenChangePassword = () => setOpenChangePassword(true);
  const handleCloseChangePassword = () => setOpenChangePassword(false);

  const [openProfile, setOpenProfile] = React.useState(false);
  const handleOpenProfile = () => setOpenProfile(true);
  const handleCloseProfile = () => setOpenProfile(false);


  return (
    <>
      <div>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account">
            <div
              style={{ backgroundColor: "#111827" }}
              onClick={handleClick}
              className="w-10 h-10 text-slate-100 flex items-center justify-center rounded-full text-base"
            >
              <p>{props.username[0].toUpperCase()}</p>
            </div>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            // onClick={handleOpenProfile}
          >
            <Avatar
              style={{ marginRight: "15px", width: "24px", height: "24px" }}
            />{" "}
            {props.username}
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleOpenChangePassword}>
            <KeyIcon fontSize="small" />
            <p className="ml-2">Change Password</p>
          </MenuItem>
          <MenuItem onClick={logoutClicked}>
            <LogoutIcon>
              <Logout fontSize="small" />
            </LogoutIcon>
            <p className="ml-2">Logout</p>
          </MenuItem>
        </Menu>
      </div>
      <ChangePasswordModal
        open={openChangePassword}
        handleClose={handleCloseChangePassword}
      />
      <ProfileDrawer
        open={openProfile}
        onClose={handleCloseProfile}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    username: state.user,
    email: state.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
