import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import "react-toastify/dist/ReactToastify.css";
import { formatDateReadableWithDayOfWeekNoYear } from "../../requests/utilities/helpers";
import { formatDateReadableWithDayOfWeek } from "../../requests/utilities/helpers";

function EventsListModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const get_impact = (event) => {
    if (event.impact == "High" && event.distance <= 2) {
      return "High";
    } else if (event.impact == "High" && event.distance <= 5) {
      return "Medium";
    } else if (event.impact == "Medium") {
      return "Low";
    } else {
      return "Minimal";
    }
  };

  const impactPriority = {
    High: 1,
    Medium: 2,
    Low: 3,
    Minimal: 4,
  };

  const filtered_eventsList = props.eventsList.sort((a, b) => {
    const impactA = get_impact(a);
    const impactB = get_impact(b);
    return impactPriority[impactA] - impactPriority[impactB];
  });
  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="pb-5 border-b border-slate-200">
              <h3 className="text-2xl text-slate-900">Local Events</h3>
              <p className="text-slate-500 mt-2">
                {formatDateReadableWithDayOfWeek(props.date_str)}
              </p>
            </div>
            <div>
              {filtered_eventsList.map((event, index) => (
                <div className="py-3 border-b border-slate-200">
                  <div>
                    <h4 className="text-slate-900 text-base">
                      {event.event_name}
                    </h4>
                  </div>
                  <div className="mt-1">
                    <p className="text-slate-500 text-sm">
                      {event.description}
                    </p>
                  </div>
                  <div className="w-full flex items-center justify-between mt-3">
                    <p className="text-xs px-3 py-0.5 rounded-full bg-slate-50 text-slate-600 border border-slate-300">
                      {event.distance} miles away
                    </p>
                    <p className="text-xs px-3 py-0.5 rounded-full bg-emerald-50 text-emerald-700 border border-emerald-300">
                      {get_impact(event)} potential impact
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div
                  className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EventsListModal;
