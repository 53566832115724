import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from '@mui/material/Checkbox';
import { formatName } from '../../../helpers/helpers';

function AddChannelMembersModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [selectedMembers, setSelectedMembers] = React.useState([]);

  const handleSelectMembersChange = (event, value) => {
    setSelectedMembers(value.map(user => user.id));
  };

  const currentChannelMemberIds = props.currentChannelMembers?.map(member => member.user_id);
  const filteredChatUsers = props.chatUsers?.filter(user => !currentChannelMemberIds?.includes(user.id));


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose()
          setSelectedMembers([])
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => {
              props.handleClose()
              setSelectedMembers([])
            }}
          >
            <CloseIcon />
          </div>
          <div>
            <h3 className="text-2xl">Add Members to <span className="font-bold">#{props.currentChannelName}</span></h3>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Select Members to Add</p>
              <div className="mt-1.5">
                <Autocomplete
                  multiple
                  options={filteredChatUsers}
                  getOptionLabel={(user) => formatName(user.name)}
                  onChange={handleSelectMembersChange}
                  disableCloseOnSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select members to add"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px !important",
                          minHeight: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                          backgroundColor: "white",
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                        sx={{
                          color: "#6d28d9",
                          "&.Mui-checked": {
                            color: "#6d28d9",
                          },
                        }}
                      />
                      {formatName(option.name)}
                    </li>
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div
                  className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.handleClose();
                    setSelectedMembers([]);
                  }}
                >
                  Cancel
                </div>
                {selectedMembers?.length > 0 ?
                <div
                  className="px-8 py-2 bg-violet-50 hover:bg-violet-100 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.handleAddMembersToChannel(props.channelID, selectedMembers);
                    props.handleClose();
                    setSelectedMembers([]);
                  }}
                >
                  Save
                </div> :
                <div
                  className="px-8 py-2 bg-slate-200 text-slate-400 rounded-full text-base w-fit"
                >
                  Save
                </div>
                }
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddChannelMembersModal;
