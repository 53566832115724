import React from "react";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
} from "recharts";

import {
  dateStrToObj,
  dateObjToShortStr,
} from "../../requests/utilities/helpers";

// This function takes a number and a format type and returns the formatted string.
function formatNumber(value, format, axis) {
  const minimumDigits = axis ? 0 : 2;
  switch (format) {
    case "dollars":
      return `$${parseFloat(value).toLocaleString("en-US", {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: 2,
      })}`; // e.g. $1,234.45
    case "percentage":
      return `${(value * 100).toFixed(minimumDigits)}%`; // e.g. 98.76%
    case "number":
      return `${parseFloat(value).toLocaleString("en-US", {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: 2,
      })}`; // e.g. 123.45
    default:
      return value.toString();
  }
}

// Function to get the label from the metrics data set
function getMetricLabel(metricValue, metrics) {
  const metric = metrics.find((m) => m.value === metricValue);
  return metric ? metric.label : metricValue;
}

const colors = [
  "#8884d8", // Purple
  "#82ca9d", // Green
  "#ffc658", // Yellow
  "#ff7300", // Orange
  "#d0ed57", // Light Green
  "#a4de6c", // Lighter Green
  "#ff69b4", // Pink
  "#8dd1e1", // Light Blue
  "#83a6ed", // Lighter Blue
  "#888888", // Gray
];

const LineGraph = ({
  data,
  rawData,
  selectedMetrics,
  metrics,
  compareLocations,
}) => {
  const firstMetric = metrics.find((m) => m.value === selectedMetrics[0]);
  const formatType = firstMetric ? firstMetric.format : "";

  // Group rawData by date
  const groupedData = rawData.reduce((acc, curr) => {
    const date = curr.date;
    if (!acc[date]) acc[date] = { date };
    acc[date][curr.location] = curr[selectedMetrics[0]];
    return acc;
  }, {});

  const processedData = Object.values(groupedData);

  const renderChart = () => {
    if (!compareLocations) {
      if (selectedMetrics.length === 1) {
        return (
          <AreaChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 60,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              label={{ value: "Date", position: "bottom" }}
              tickFormatter={(str) => {
                const date = dateStrToObj(str);
                return `${date.getMonth() + 1}/${date.getDate()}`;
              }}
            />
            <YAxis
              tickFormatter={(value) => formatNumber(value, formatType, true)}
            />
            {formatType === "percentage" && (
              <ReferenceLine
                y={1}
                stroke="#ff6969"
                label={{ value: "100%", position: "right" }}
              />
            )}
            <Tooltip
              formatter={(value, name) => [
                `${getMetricLabel(name, metrics)}: ${formatNumber(
                  value,
                  formatType,
                  false
                )}`,
              ]}
              labelFormatter={(str) => {
                return dateObjToShortStr(dateStrToObj(str));
              }}
            />
            <Legend
              layout="horizontal"
              align="center"
              verticalAlign="top"
              formatter={(value) => getMetricLabel(value, metrics)}
            />
            <Area
              type="monotone"
              dataKey={selectedMetrics[0]}
              stroke="#6d28d9"
              fill="#ede9fe"
              strokeWidth={2}
              dot={false}
            />
          </AreaChart>
        );
      } else {
        return (
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 60,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              label={{ value: "Date", position: "bottom" }}
              tickFormatter={(str) => {
                const date = dateStrToObj(str);
                return `${date.getMonth() + 1}/${date.getDate()}`;
              }}
            />
            <YAxis
              tickFormatter={(value) => formatNumber(value, formatType, true)}
            />
            {formatType === "percentage" && (
              <ReferenceLine
                y={1}
                stroke="#ff6969"
                label={{ value: "100%", position: "right" }}
              />
            )}
            <Tooltip
              formatter={(value, name) => [
                `${getMetricLabel(name, metrics)}: ${formatNumber(
                  value,
                  formatType,
                  false
                )}`,
              ]}
              labelFormatter={(str) => {
                return dateObjToShortStr(dateStrToObj(str));
              }}
            />
            <Legend
              layout="horizontal"
              align="center"
              verticalAlign="top"
              formatter={(value) => getMetricLabel(value, metrics)}
            />
            {selectedMetrics.map((metric, index) => (
              <Line
                key={metric}
                type="monotone"
                dataKey={metric}
                name={getMetricLabel(metric, metrics)} // Update name for the legend
                stroke={colors[index % colors.length]}
                strokeWidth={2}
                dot={false}
              />
            ))}
          </LineChart>
        );
      }
    } else {
      // Create lines for each location
      const locations = [...new Set(rawData.map((d) => d.location))];
      return (
        <LineChart
          width={500}
          height={300}
          data={processedData}
          margin={{
            top: 5,
            right: 60,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            label={{ value: "Date", position: "bottom" }}
            tickFormatter={(str) => {
              const date = dateStrToObj(str);
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }}
          />
          <YAxis
            tickFormatter={(value) => formatNumber(value, formatType, true)}
          />
          {formatType === "percentage" && (
            <ReferenceLine
              y={1}
              stroke="#ff6969"
              label={{ value: "100%", position: "right" }}
            />
          )}
          <Tooltip
            formatter={(value, name) => [
              `${getMetricLabel(name, metrics)}: ${formatNumber(
                value,
                formatType,
                false
              )}`,
            ]}
            labelFormatter={(str) => {
              return `${firstMetric.label} on ${dateObjToShortStr(
                dateStrToObj(str)
              )}`;
            }}
          />
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="top"
            formatter={(value) => getMetricLabel(value, metrics)}
          />
          {locations.map((location, index) => (
            <Line
              key={location}
              type="monotone"
              dataKey={location}
              name={location} // Use location name for the legend
              stroke={colors[index % colors.length]}
              strokeWidth={2}
              dot={false}
            />
          ))}
        </LineChart>
      );
    }
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default LineGraph;
