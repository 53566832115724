import LineGraph from "./charts/LineGraph";
import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { formatDate } from "../requests/utilities/helpers";
import { API_calculate_metric } from "../../../constants";
import axios from "axios";
import { getDefaultAxiosConfig } from "../../../utilities/requests";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";
import TopBarDisplayTitleNoLocationSelect from "../home/top_bar_components/TopBarDisplayTitleNoLocationSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import WarningIcon from "@mui/icons-material/Warning";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

const getBusinessWeekStartDayIndex = (storeNames, store_id) => {
  const day_of_week_names = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  try {
    return day_of_week_names.indexOf(
      storeNames.filter((v, i) => v.id === store_id)[0][
        "business_week_start_day"
      ]
    );
  } catch (error) {
    return 1;
  }
};

function ReportingDisplay(props) {
  const axiosConfig = getDefaultAxiosConfig();
  const [comparingMetricsFlag, setComparingMetricsFlag] = useState(false);
  const [selectedStores, setSelectedStores] = useState([props.homeStore]);
  const [selectedMetrics, setSelectedMetrics] = useState(["forecasted_sales"]);
  const [calculatedMetrics, setCalculatedMetrics] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [calculationStatus, setCalculationStatus] = useState("idle");
  const [downloadStatus, setDownloadStatus] = useState("notavailable");

  const [selectedDateRange, setSelectedDateRange] = React.useState("");
  const [showCustomDateRange, setShowCustomDateRange] = React.useState(false);
  const [compareLocationsChecked, setCompareLocationsChecked] = useState(false);
  const [compareLocationsEligible, setCompareLocationsEligible] =
    useState(false);
  const businessWeekStartDayIndex = getBusinessWeekStartDayIndex(
    props.storeNames,
    props.homeStore
  );
  console.log(businessWeekStartDayIndex);

  const handleCheckChange = (event) => {
    setCompareLocationsChecked(event.target.checked);
    if (selectedMetrics.length > 1) {
      setCalculationStatus("idle");
    }
  };

  function toggleCustomDateRange() {
    setShowCustomDateRange((prevState) => !prevState);
    setSelectedDateRange("");
  }

  function handleDateRangeChange(event) {
    setSelectedDateRange(event.target.value);

    const today = new Date();
    switch (event.target.value) {
      case "yesterday":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 1)));
        setSelectedEndDate(new Date());
        break;
      case "last_3_days":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 3)));
        setSelectedEndDate(new Date());
        break;
      case "last_week":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 7)));
        setSelectedEndDate(new Date());
        break;
      case "last_two_weeks":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 14)));
        setSelectedEndDate(new Date());
        break;
      case "last_month":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 30)));
        setSelectedEndDate(new Date());
        break;
      case "last_3_months":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 90)));
        setSelectedEndDate(new Date());
        break;
      case "last_6_months":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 183)));
        setSelectedEndDate(new Date());
        break;
      case "last_year":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 365)));
        setSelectedEndDate(new Date());
        break;
      case "last_5_years":
        setSelectedStartDate(new Date(today.setDate(today.getDate() - 1825)));
        setSelectedEndDate(new Date());
        break;
      case "custom":
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        toggleCustomDateRange();
        break;
      default:
        setSelectedStartDate(null);
        setSelectedEndDate(null);
    }
  }

  const dateRanges = [
    { value: "yesterday", label: "Yesterday" },
    { value: "last_3_days", label: "Recent 3 Days" },
    { value: "last_week", label: "Recent Week" },
    { value: "last_two_weeks", label: "Recent Two Weeks" },
    { value: "last_month", label: "Recent Month" },
    { value: "last_3_months", label: "Recent 3 Months" },
    { value: "last_6_months", label: "Recent 6 Months" },
    { value: "last_year", label: "Recent Year" },
    { value: "last_5_years", label: "Recent 5 Years" },
    { value: "custom", label: "Enter Custom Date Range" },
  ];

  const metrics = [
    { value: "forecasted_sales", label: "Forecasted Sales", format: "dollars" },
    {
      value: "forecasted_adjusted_sales",
      label: "Forecasted Adjusted Sales",
      format: "dollars",
    },
    { value: "actual_sales", label: "Actual Sales", format: "dollars" },
    { value: "splh", label: "Actual Sales Per Labor Hour", format: "dollars" },
    {
      value: "earned_splh",
      label: "Actual Sales Per Earned Labor Hour",
      format: "dollars",
    },
    {
      value: "scheduled_labor_hours",
      label: "Scheduled Hours",
      format: "hours",
    },
    {
      value: "forecasted_labor_hours",
      label: "Forecasted Hours",
      format: "hours",
    },
    {
      value: "actual_labor_hours",
      label: "Actual Hours",
      format: "hours",
    },
    {
      value: "earned_labor_hours",
      label: "Earned Hours",
      format: "hours",
    },
    {
      value: "forecasted_to_actual_sales",
      label: "Forecasted to Actual Sales",
      format: "percentage",
    },
    {
      value: "forecasted_adjusted_to_actual_sales",
      label: "Forecasted Adjusted to Actual Sales",
      format: "percentage",
    },
    {
      value: "scheduled_to_forecasted_hours",
      label: "Scheduled to Forecasted Hours",
      format: "percentage",
    },
    {
      value: "actual_to_earned_hours",
      label: "Actual to Earned Hours",
      format: "percentage",
    },
    {
      value: "scheduled_to_actual_hours",
      label: "Scheduled to Actual Hours",
      format: "percentage",
    },
    {
      value: "forecasted_to_earned_hours",
      label: "Forecasted to Earned Hours",
      format: "percentage",
    },
  ];

  const handleStoreChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      if (selectedStores.length === props.storeNames.length) {
        setSelectedStores([]);
      } else {
        setSelectedStores(props.storeNames.map((store) => store.id));
      }
      return;
    }
    setSelectedStores(value);
    if (value.length > 1) {
      setCompareLocationsEligible(true);
    } else {
      setCompareLocationsEligible(false);
      setCompareLocationsChecked(false);
    }
  };

  const handleMetricChange = (event) => {
    const {
      target: { value },
    } = event;
    const metrics = typeof value === "string" ? value.split(",") : value;
    setSelectedMetrics(metrics);
  };

  useEffect(() => {
    setCalculationStatus("idle");
  }, [selectedStores, selectedMetrics, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (calculationStatus === "completed") {
      setDownloadStatus("available");
    } else {
      setDownloadStatus("notavailable");
    }
  }, [calculationStatus]);

  const calculateMetric = () => {
    setCalculationStatus("loading");

    const params = {
      selectedStores: selectedStores.join(","),
      selectedMetrics: selectedMetrics.join(","),
      selectedStartDate: formatDate(selectedStartDate),
      selectedEndDate: formatDate(selectedEndDate),
      store_id: props.homeStore,
      comparingMetricsFlag: comparingMetricsFlag,
    };

    const url = API_calculate_metric;

    axios
      .get(url, { params: params, ...axiosConfig })
      .then((res) => {
        console.log(res);
        const newData = res.data.redux.payload.agg_data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        const newRawData = res.data.redux.payload.raw_data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        const newSummaryData = res.data.redux.payload.summary_data;
        const newLocationData = res.data.redux.payload.location_data;
        setData(newData);
        setRawData(newRawData);
        setSummaryData(newSummaryData);
        setLocationData(newLocationData);
        console.log(newSummaryData);
        setCalculatedMetrics(selectedMetrics);
        setCalculationStatus("completed");
      })
      .catch((error) => {
        console.error(error);
        setCalculationStatus("idle");
      });
  };

  function getMetricLabel(metricValue) {
    const metric = metrics.find((m) => m.value === metricValue);
    return metric ? metric.label.replace(/\s+/g, "_") : "";
  }

  function handleDownload() {
    setDownloadStatus("downloading");
    const csvData = objectToCSV(rawData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const fileName = `${selectedMetrics
      .map(getMetricLabel)
      .join("_")}_${formatDate(selectedStartDate)}_to_${formatDate(
      selectedEndDate
    )}.csv`;

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
    setDownloadStatus("completed");
  }

  function objectToCSV(obj) {
    const header = Object.keys(obj[0]).join(",");
    const rows = obj.map((row) => Object.values(row).join(","));
    return [header, ...rows].join("\n");
  }

  const checkIfTypesValid = (compareMetrics) => {
    // Check if selected metrics have the same format type
    const firstMetricFormat =
      compareMetrics.length > 0
        ? metrics.find((m) => m.value === compareMetrics[0]).format
        : "";
    const areMetricsSameFormat = compareMetrics.every(
      (metric) =>
        metrics.find((m) => m.value === metric).format === firstMetricFormat
    );
    return areMetricsSameFormat;
  };

  const areMetricsSameFormat = checkIfTypesValid(selectedMetrics);

  const checkIfSubmitValid = () => {
    let valid = true;
    if (selectedStores.length === 0 || selectedMetrics.length === 0) {
      valid = false;
    } else if (
      !selectedStartDate ||
      !selectedEndDate ||
      selectedStartDate >= selectedEndDate
    ) {
      valid = false;
    } else if (!areMetricsSameFormat) {
      valid = false;
    } else if (compareLocationsChecked && selectedMetrics.length > 1) {
      valid = false;
    }
    return valid;
  };

  const enrichedSummaryData = summaryData.map((data) => {
    const metricInfo = metrics.find((metric) => metric.value === data.metric);

    return {
      ...data,
      label: metricInfo ? metricInfo.label : "N/A",
      format: metricInfo ? metricInfo.format : "N/A",
    };
  });

  const enrichedLocationData = locationData.map((data) => {
    const metricInfo = metrics.find((metric) => metric.value === data.metric);

    return {
      ...data,
      label: metricInfo ? metricInfo.label : "N/A",
      format: metricInfo ? metricInfo.format : "N/A",
    };
  });

  console.log("LOCATION DATA", locationData);

  dayjs.extend(updateLocale);
  dayjs.updateLocale("en", {
    weekStart: businessWeekStartDayIndex,
  });

  return (
    <div className="bg-white min-h-screen">
      <div>
        <TopBarDisplayTitleNoLocationSelect title="Reporting" />
      </div>
      <div className="flex space-x-5 px-6 mt-6">
        <div>
          <div>
            <h4 className="text-slate-500 text-sm">Locations</h4>
          </div>
          <div className="mt-1.5">
            <Select
              label="Locations"
              multiple
              value={selectedStores}
              onChange={handleStoreChange}
              input={<OutlinedInput />}
              sx={{
                backgroundColor: "white",
                borderRadius: "12px",
                paddingX: "15px",
                height: "40px",
                fontSize: "14px",
                width: "235px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6d28d9",
                },
              }}
              renderValue={(selected) => {
                const selectedStoresNames = props.storeNames
                  .filter((store) => selected.includes(store.id))
                  .map((store) => store.store_name);

                if (selectedStoresNames.length <= 2) {
                  return selectedStoresNames.join(", ");
                } else {
                  return `${selectedStoresNames[0]}, ${selectedStoresNames[1]}, ...`;
                }
              }}
              className="w-full"
            >
              <MenuItem value="all">
                <Checkbox
                  checked={
                    props.storeNames.length > 0 &&
                    selectedStores.length === props.storeNames.length
                  }
                  indeterminate={
                    selectedStores.length > 0 &&
                    selectedStores.length < props.storeNames.length
                  }
                  sx={{
                    color: "#6d28d9",
                    "&.Mui-checked": {
                      color: "#6d28d9",
                    },
                    "&.MuiCheckbox-indeterminate": {
                      color: "#6d28d9",
                    },
                  }}
                />
                <ListItemText
                  primary={
                    selectedStores.length === props.storeNames.length
                      ? "Deselect All"
                      : "Select All"
                  }
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </MenuItem>
              {props.storeNames.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  <Checkbox
                    checked={selectedStores.indexOf(value.id) > -1}
                    sx={{
                      color: "#6d28d9",
                      "&.Mui-checked": {
                        color: "#6d28d9",
                      },
                    }}
                  />
                  <ListItemText primary={value.store_name} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div>
          <div>
            <h4 className="text-slate-500 text-sm">Metrics</h4>
          </div>
          <div className="mt-1.5">
            <Select
              label="Metrics"
              multiple
              value={selectedMetrics}
              onChange={handleMetricChange}
              input={<OutlinedInput />}
              sx={{
                backgroundColor: "white",
                borderRadius: "12px",
                paddingX: "15px",
                height: "40px",
                fontSize: "14px",
                width: showCustomDateRange ? "235px" : "300px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#6d28d9",
                },
              }}
              renderValue={(selected) => {
                const selectedMetricsLabels = metrics
                  .filter((metric) => selected.includes(metric.value))
                  .map((metric) => metric.label);

                if (selectedMetricsLabels.length <= 2) {
                  return selectedMetricsLabels.join(", ");
                } else {
                  return `${selectedMetricsLabels[0]}, ${selectedMetricsLabels[1]}, ...`;
                }
              }}
              className="w-full"
            >
              {metrics.map((metric) => (
                <MenuItem key={metric.value} value={metric.value}>
                  <Checkbox
                    checked={selectedMetrics.indexOf(metric.value) > -1}
                    sx={{
                      color: "#6d28d9",
                      "&.Mui-checked": {
                        color: "#6d28d9",
                      },
                    }}
                  />
                  <ListItemText
                    primary={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{metric.label}</span>
                        <span style={{ marginLeft: "16px", color: "#888" }}>
                          {metric.format}
                        </span>
                      </div>
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        {showCustomDateRange ? (
          <div className="flex">
            <div className="w-40">
              <div>
                <h4 className="text-slate-500 text-sm">Start Date</h4>
              </div>
              <div className="mt-1.5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    Text="Start Date"
                    value={selectedStartDate}
                    onChange={(newValue) => setSelectedStartDate(newValue)}
                    slotProps={{
                      day: {
                        sx: {
                          "&.MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#6d28d9",
                          },
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "40px",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        fontSize: "14px",
                        "& fieldset": {
                          borderRadius: "12px",
                          height: "45px",
                          paddingX: "25px",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                    className="w-full text-center"
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="w-40 ml-2">
              <div>
                <h4 className="text-slate-500 text-sm">End Date</h4>
              </div>
              <div className="mt-1.5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    Text="End Date"
                    value={selectedEndDate}
                    onChange={(newValue) => setSelectedEndDate(newValue)}
                    slotProps={{
                      day: {
                        sx: {
                          "&.MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#6d28d9",
                          },
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "40px",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        fontSize: "14px",
                        "& fieldset": {
                          borderRadius: "12px",
                          height: "45px",
                          paddingX: "25px",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                    className="w-full text-center"
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="h-full flex items-end justify-end ml-2">
              <Tooltip title="Back to preset date ranges">
                <div
                  className="w-10 h-10 rounded-full bg-slate-50 hover:bg-slate-100 text-slate-500 flex items-center justify-center cursor-pointer"
                  onClick={toggleCustomDateRange}
                >
                  <UndoIcon />
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="w-[200px]">
            <div>
              <h4 className="text-slate-500 text-sm">Date Range</h4>
            </div>
            <div className="mt-1.5">
              <Select
                fullWidth
                value={selectedDateRange}
                onChange={(event) => {
                  handleDateRangeChange(event);
                }}
                input={<OutlinedInput />}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "12px",
                  paddingX: "15px",
                  height: "40px",
                  fontSize: "14px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6d28d9",
                  },
                }}
                className="w-full"
              >
                {dateRanges.map((range) => (
                  <MenuItem key={range.value} value={range.value}>
                    {range.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}

        <div className="flex gap-4 items-end justify-between flex-1 xl:hidden">
          <LoadingButton
            variant="contained"
            loading={calculationStatus === "loading"}
            loadingPosition="end"
            endIcon={
              calculationStatus === "completed" ? (
                <CheckIcon />
              ) : (
                <AutoGraphIcon />
              )
            }
            onClick={() => calculateMetric()}
            disabled={!checkIfSubmitValid() || calculationStatus === "loading"}
            sx={{
              textTransform: "none",
              borderRadius: "50px",
              height: "40px",
              backgroundColor:
                calculationStatus === "completed" ? "green" : "#6d28d9",
              "&:hover": {
                backgroundColor:
                  calculationStatus === "completed" ? "darkgreen" : "#5b21b6",
              },
            }}
          >
            {calculationStatus === "loading"
              ? "Calculating..."
              : calculationStatus === "completed"
              ? "Metric Calculated"
              : "Calculate Metric"}
          </LoadingButton>

          <LoadingButton
            variant="contained"
            loading={downloadStatus === "downloading"}
            loadingPosition="end"
            endIcon={
              downloadStatus === "completed" ? <CheckIcon /> : <DownloadIcon />
            }
            onClick={handleDownload}
            disabled={
              downloadStatus === "downloading" ||
              downloadStatus === "notavailable" ||
              data.length === 0
            }
            sx={{
              textTransform: "none",
              borderRadius: "50px",
              height: "40px",
              backgroundColor:
                downloadStatus === "completed" ? "green" : "#6d28d9",
              "&:hover": {
                backgroundColor:
                  downloadStatus === "completed" ? "darkgreen" : "#5b21b6",
              },
            }}
          >
            {downloadStatus === "downloading"
              ? "Downloading..."
              : downloadStatus === "completed"
              ? "Download Completed"
              : "Download Data"}
          </LoadingButton>
        </div>
      </div>

      <div className="pt-0 mt-0 px-6">
        <div className="flex items-center space-x-16">
          <Tooltip
            title={
              !compareLocationsEligible
                ? "Select multiple locations to compare."
                : selectedMetrics.length > 1
                ? "Comparison will only use the first metric selected."
                : ""
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="compareLocationsChecked"
                  checked={compareLocationsChecked}
                  disabled={!compareLocationsEligible}
                  onChange={handleCheckChange}
                  size="small"
                  sx={{
                    color: "#6d28d9",
                    "&.Mui-checked": {
                      color: "#6d28d9",
                    },
                  }}
                />
              }
              label="Compare Locations"
            />
          </Tooltip>
          {compareLocationsChecked && selectedMetrics.length > 1 && (
            <div className="flex items-center space-x-2 text-amber-400">
              <div>
                <WarningIcon
                  style={{ fontSize: "16px", marginBottom: "3px" }}
                />
              </div>
              <div className="text-sm">
                Must select only one metric when comparing locations
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="hidden xl:flex items-center space-x-4 px-6 mt-2">
        <LoadingButton
          variant="contained"
          loading={calculationStatus === "loading"}
          loadingPosition="end"
          endIcon={
            calculationStatus === "completed" ? (
              <CheckIcon />
            ) : (
              <AutoGraphIcon />
            )
          }
          onClick={() => calculateMetric()}
          disabled={!checkIfSubmitValid() || calculationStatus === "loading"}
          sx={{
            textTransform: "none",
            borderRadius: "50px",
            height: "40px",
            backgroundColor:
              calculationStatus === "completed" ? "green" : "#6d28d9",
            "&:hover": {
              backgroundColor:
                calculationStatus === "completed" ? "darkgreen" : "#5b21b6",
            },
          }}
        >
          {calculationStatus === "loading"
            ? "Calculating..."
            : calculationStatus === "completed"
            ? "Metric Calculated"
            : "Calculate Metric"}
        </LoadingButton>

        <LoadingButton
          variant="contained"
          loading={downloadStatus === "downloading"}
          loadingPosition="end"
          endIcon={
            downloadStatus === "completed" ? <CheckIcon /> : <DownloadIcon />
          }
          onClick={handleDownload}
          disabled={
            downloadStatus === "downloading" ||
            downloadStatus === "notavailable" ||
            data.length === 0
          }
          sx={{
            textTransform: "none",
            borderRadius: "50px",
            height: "40px",
            backgroundColor:
              downloadStatus === "completed" ? "green" : "#6d28d9",
            "&:hover": {
              backgroundColor:
                downloadStatus === "completed" ? "darkgreen" : "#5b21b6",
            },
          }}
        >
          {downloadStatus === "downloading"
            ? "Downloading..."
            : downloadStatus === "completed"
            ? "Download Completed"
            : "Download Data"}
        </LoadingButton>
      </div>

      {calculationStatus === "completed" && (
        <div>
          {!compareLocationsChecked ? (
            <div className="mt-6 px-6 flex flex-wrap gap-x-8 gap-y-4">
              {enrichedSummaryData.map((metric, index) => (
                <div
                  key={index}
                  className="p-4 border border-slate-200 rounded-lg min-w-[180px]"
                >
                  <p className="text-xs text-slate-900">{metric.label}</p>
                  {metric.format === "dollars" ? (
                    <div className="mt-2 flex space-x-8">
                      {metric.metric !== "splh" &&
                        metric.metric !== "earned_splh" && (
                          <div>
                            <p className="text-xxs text-slate-500">Daily Avg</p>
                            <p className="text-lg text-slate-900">
                              {metric.average.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </p>
                          </div>
                        )}
                      <div>
                        <p className="text-xxs text-slate-500">Total</p>
                        <p className="text-lg text-slate-900">
                          {metric.total.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                      </div>
                    </div>
                  ) : metric.format === "hours" ? (
                    <div className="mt-2 flex space-x-8">
                      <div>
                        <p className="text-xxs text-slate-500">Daily Avg</p>
                        <p className="text-lg text-slate-900">
                          {metric.average.toFixed(2)}
                        </p>
                      </div>
                      <div>
                        <p className="text-xxs text-slate-500">Total</p>
                        <p className="text-lg text-slate-900">
                          {metric.total.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ) : metric.format === "percentage" ? (
                    <div className="mt-2 flex space-x-8">
                      <div>
                        <p className="text-xxs text-slate-500">Total</p>
                        <p className="text-lg text-slate-900">
                          {(metric.total * 100).toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-6 px-6 flex flex-wrap gap-x-8 gap-y-4">
              {enrichedLocationData.map((location, index) => (
                <div
                  key={index}
                  className="p-4 border border-slate-200 rounded-lg min-w-[180px]"
                >
                  <p className="text-xs text-slate-900">{location.location}</p>
                  {location.format === "dollars" ? (
                    <div className="mt-2 flex space-x-8">
                      {location.metric !== "splh" &&
                        location.metric !== "earned_splh" && (
                          <div>
                            <p className="text-xxs text-slate-500">Daily Avg</p>
                            <p className="text-lg text-slate-900">
                              {location.average.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </p>
                          </div>
                        )}
                      <div>
                        <p className="text-xxs text-slate-500">Total</p>
                        <p className="text-lg text-slate-900">
                          {location.total.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </p>
                      </div>
                    </div>
                  ) : location.format === "hours" ? (
                    <div className="mt-2 flex space-x-8">
                      <div>
                        <p className="text-xxs text-slate-500">Daily Avg</p>
                        <p className="text-lg text-slate-900">
                          {location.average.toFixed(2)}
                        </p>
                      </div>
                      <div>
                        <p className="text-xxs text-slate-500">Total</p>
                        <p className="text-lg text-slate-900">
                          {location.total.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ) : location.format === "percentage" ? (
                    <div className="mt-2 flex space-x-8">
                      <div>
                        <p className="text-xxs text-slate-500">Total</p>
                        <p className="text-lg text-slate-900">
                          {(location.total * 100).toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="min-h-[430px] h-100 mb-5 pt-6 xl:pt-2 pb-5 w-full">
        {!areMetricsSameFormat ? (
          <div className="flex items-center min-h-[430px] justify-center h-full">
            <h3 className="text-center text-xl text-slate-500">
              Please select metrics of the same data type.
            </h3>
          </div>
        ) : data.length === 0 ? (
          <div className="flex items-center min-h-[430px] justify-center h-full">
            {calculationStatus === "completed" ? (
              <h3 className="text-center text-xl text-slate-500">
                No data to show :(
              </h3>
            ) : checkIfSubmitValid() ? (
              <h3 className="text-center text-xl text-slate-500">
                Press Calculate Metric to see results.
              </h3>
            ) : (
              <h3 className="text-center text-xl text-slate-500">
                Select valid parameters and then press Calculate Metric to see
                results.
              </h3>
            )}
          </div>
        ) : data &&
          summaryData &&
          locationData &&
          calculationStatus === "completed" ? (
          <div>
            <LineGraph
              data={data}
              rawData={rawData}
              selectedMetrics={calculatedMetrics}
              metrics={metrics}
              compareLocations={compareLocationsChecked}
            />
          </div>
        ) : (
          <div className="flex items-center min-h-[430px] justify-center h-full">
            {checkIfSubmitValid() ? (
              <h3 className="text-center text-xl text-slate-500">
                Press Calculate Metric to see results.
              </h3>
            ) : (
              <h3 className="text-center text-xl text-slate-500">
                Select valid parameters and then press Calculate Metric to see
                results.
              </h3>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportingDisplay;
