import React from "react";
import Stack from "@mui/material/Stack";
import DateScheduleTypeGrouping from "./DateScheduleTypeGrouping";
import StoreName from "../../home/top_bar_components/StoreName";
import Account from "../../home/top_bar_components/Account";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import { createWarningsTooltip } from "../../../../helpers/helpers";
import TabsTwo from "../../requests/shared/helpers/TabsTwo";
import ScheduleTypeFilter from "../../home/top_bar_components/ScheduleTypeFilter";
import Notifications from "../../home/top_bar_components/Notifications";

function TopBarDisplay(props) {

  function toggleShowWarnings() {
    props.setShowWarnings((prevState) => !prevState);
  }

  const [activeTab, setActiveTab] = React.useState('Day')

  function handleTabButtonPress(target) {
    setActiveTab(target)
    props.toggleDailyWeekly()
  }




  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="w-72">
            <DateScheduleTypeGrouping
              date={props.date}
              increment_day={props.increment_day}
              roleType={props.roleType}
              setRoleType={props.setRoleType}
              roleTypeOptions={props.roleTypeOptions}
            />
          </div>
          {props.view !== "daily_plan" &&
            <div className="w-40">
              <TabsTwo
                activeTab={activeTab}
                handleTabButtonPress={handleTabButtonPress}
                tab1="Week"
                tab2="Day"
                type="TopBar"
              />
            </div>
          }
        </div>
        {/* {(props.countOfWarnings.countOfOvertime > 0 ||
          props.countOfWarnings.countOfConflicts > 0) && (
          <div className="flex-1 flex items-center justify-center">
            <Tooltip
              title={createWarningsTooltip(
                props.countOfWarnings.countOfOvertime,
                props.countOfWarnings.countOfConflicts
              )}
            >
              <button
                className={`px-5 py-2 ${
                  props.showWarnings
                    ? "bg-red-600 text-white"
                    : "border border-red-600 text-red-600"
                } rounded-full text-xs flex items-center gap-2`}
                onClick={toggleShowWarnings}
              >
                <WarningIcon style={{ fontSize: "14px" }} /> Schedule Warning
              </button>
            </Tooltip>
          </div>
        )} */}
        <div className="flex items-center">
          <div className="mr-4">
            <ScheduleTypeFilter
              roleType={props.roleType}
              setRoleType={props.setRoleType}
              roleTypeOptions={props.roleTypeOptions}
            />
          </div>
          <div className="mr-6">
            <StoreName
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div>
          <div className="mr-8">
            <Notifications
              homeStore={props.homeStore}
            />
            </div>
          <div className="h-10 border-gray-500 flex items-center justify-center cursor-pointer">
            <Account />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBarDisplay;
