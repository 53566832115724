import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function ChecklistScreen(props) {

  let checklist = []
  let intro = ''

  if (props.onboardingInfo.selectedPlan === "Introductory") {
    intro = 'In just a few moments, you will get access to the RightWork software, where you can begin exploring with our onboarding wizard. The following steps are how we get there.'
    checklist = [
      {
        name: 'Input personal, company, and pilot information',
        description: 'Already done!',
        day: '',
        status: 'complete',
      },
      {
        name: 'Establish your goals',
        description: 'Already done!',
        day: '',
        status: 'complete',
      },
      {
        name: 'Check your email for sales contract to review and sign',
        description: 'If you have any questions or concerns, let us know',
        day: '',
        status: 'todo',
      },

      {
        name: 'Create an account and start exploring the app',
        description: 'We have an onboarding wizard walk you through the process',
        day: '',
        status: 'todo',
      },
      {
        name: 'Your employees download RightWork mobile app',
        description: 'They can submit availability and preferences, swap shifts, request time off, and view their schedules',
        day: '',
        status: 'todo',
      },
      {
        name: 'Start utilizing the power of RightWork to automate scheduling!',
        description: 'We are here every step of the way if you have questions or concerns',
        day: '',
        status: 'todo',
      },
    ]

  } else if (props.onboardingInfo.selectedPlan === "Premium") {
    intro = 'Your team will have complete access to RightWork in just 10 days, which means we can start automating your scheduling and help you save money. The following steps are how we get there.'
    checklist = [
      {
        name: 'Input personal, company, and pilot information',
        description: 'Already done!',
        day: 'Day 1 (Today)',
        status: 'complete',
      },
      {
        name: 'Establish your goals',
        description: 'Already done!',
        day: 'Day 1 (Today)',
        status: 'complete',
      },
      {
        name: 'Check your email for sales contract to review and sign',
        description: 'If you have any questions or concerns, let us know',
        day: 'Day 1 (Today)',
        status: 'todo',
      },
      {
        name: 'Grant RightWork access to your point-of-sale data',
        description: 'So we can run our forecasting algorithm on your historical sales',
        day: 'Day 3',
        status: 'todo',
      },
      {
        name: 'Precision sales forecasting can begin!',
        description: 'See the breakdown of your expected sales all the way down to the product/channel level.',
        day: 'Day 5',
        status: 'todo',
      },
      {
        name: 'We conduct a training session with store management',
        description: 'We walk you through how to collect and input labor standards',
        day: 'Day 8',
        status: 'todo',
      },
      {
        name: 'Your employees download RightWork mobile app',
        description: 'They can submit availability and preferences, swap shifts, request time off, and view their schedules',
        day: 'Day 9',
        status: 'todo',
      },
      {
        name: 'Start utilizing the power of RightWork to automate scheduling!',
        description: 'We are here every step of the way if you have questions or concerns',
        day: 'Day 10',
        status: 'todo',
      },
    ]
  } else {
    intro = "Your team will have complete access to RightWork in just 10 days, which means we can start automating your scheduling and help you save money. The following steps are how we get there."
    checklist = [
      {
        name: 'Input personal, company, and pilot information',
        description: 'Already done!',
        day: 'Day 1 (Today)',
        status: 'complete',
      },
      {
        name: 'Establish your goals',
        description: 'Already done!',
        day: 'Day 1 (Today)',
        status: 'complete',
      },
      {
        name: 'Check your email for sales contract to review and sign',
        description: 'If you have any questions or concerns, let us know',
        day: 'Day 1 (Today)',
        status: 'todo',
      },
      {
        name: 'Grant RightWork access to your point-of-sale data',
        description: 'So we can run our forecasting algorithm on your historical sales',
        day: 'Day 3',
        status: 'todo',
      },
      {
        name: 'Precision sales forecasting can begin!',
        description: 'See the breakdown of your expected sales all the way down to the product/channel level.',
        day: 'Day 5',
        status: 'todo',
      },
      {
        name: 'We conduct a training session with store management',
        description: 'We walk you through how to collect and input labor standards',
        day: 'Day 8',
        status: 'todo',
      },
      {
        name: 'Your employees download RightWork mobile app',
        description: 'They can submit availability and preferences, swap shifts, request time off, and view their schedules',
        day: 'Day 9',
        status: 'todo',
      },
      {
        name: 'Start utilizing the power of RightWork to automate scheduling!',
        description: 'We are here every step of the way if you have questions or concerns',
        day: 'Day 10',
        status: 'todo',
      },
    ]
  }



  return (
    <div>
      <div className="relative">
        <button className="absolute text-gray-100 -left-12 top-1" onClick={props.decreaseStep}>
          <ArrowBackIosIcon style={{ fontSize:"26px" }}/>
        </button>
        <h2 className="text-3xl text-gray-100 font-bold">A couple more things!</h2>
        <p className="text-gray-300 mt-5 text-sm">{intro}</p>
        <p className="text-gray-300 mt-5 text-sm">Don't worry, you don't need to do anything on this page. We've sent you an email with all the important details. We can't wait to get started!</p>
      </div>
      <div className="mt-8">
        <ul day="list" className="divide-y divide-gray-500">
          {checklist.map((item) => (
            <li key={item.email} className="flex items-center justify-between gap-x-6 py-4">
              <div className="flex items-center gap-x-4">
                {item.status === "complete" ?
                  <div className="w-8 h-8 bg-orange-500 flex items-center justify-center text-white rounded-full">
                    <CheckIcon />
                  </div> :
                  <div className="w-8 h-8 border-2 border-orange-500 rounded-full"></div>
                }
                <div className={`min-w-0 flex-auto ${props.onboardingInfo.selectedPlan === "Introductory" ? "flex-1" : "w-80"}`}>
                  <p className="text-xs font-semibold leading-6 text-gray-100">{item.name}</p>
                  <p className="text-xs leading-6 text-gray-400">{item.description}</p>
                </div>
              </div>
              <div className="flex items-end">
                <p className="text-sm leading-6 text-gray-100">{item.day}</p>
              </div>
            </li>
          ))}
        </ul>
        <div className="w-1/2 mt-8">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full" onClick={props.increaseStep}>
            Finish
          </button>
        </div>
      </div>
    </div>
  )
}
