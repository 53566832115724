import React from 'react'
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import AnchorOutlinedIcon from '@mui/icons-material/AnchorOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import ChargingStationOutlinedIcon from '@mui/icons-material/ChargingStationOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from "@mui/material/Tooltip";

function EmployeeProfileStatisticsTab(props) {

  console.log("STATS DATA", props.selectedTeamMemberData)

  const totalShiftsScheduled = props.selectedTeamMemberData?.published_schedules_count
  const calledOffSick = props.selectedTeamMemberData?.published_schedules_called_off_sick_count
  const calledOffOther = props.selectedTeamMemberData?.published_schedules_called_off_other_count
  const noCallNoShow = props.selectedTeamMemberData?.published_schedules_no_show_count
  const missedShifts = calledOffSick + calledOffOther + noCallNoShow
  const totalShiftsWorked = totalShiftsScheduled - missedShifts
  const totalHoursWorked = props.selectedTeamMemberData?.published_schedules_hours_worked
  const averageShiftLength = totalShiftsWorked !== 0 ? (totalHoursWorked / totalShiftsWorked).toFixed(2) : 0
  const daysOffRequested = props.selectedTeamMemberData?.time_off_requests_approved_count
  const tradesInitiated = props.selectedTeamMemberData?.shifts_swapped_dropped_count
  const tradesAccepted = props.selectedTeamMemberData?.shifts_swapped_picked_up_count
  const shiftsDropped = props.selectedTeamMemberData?.shifts_dropped_count
  const shiftsPickedUp = props.selectedTeamMemberData?.shifts_picked_up_count

  return (
    <div className="w-full flex flex-col items-center">
      <div>
        <h3 className="text-slate-900 text-2xl font-bold">Statistics</h3>
      </div>
      <div className="mt-5 flex space-x-5">
        <div className="bg-white p-5 rounded-xl w-80">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">Experience</h3>
            <div>
              <HowToRegOutlinedIcon style={{ fontSize: "26px" }} />
            </div>
          </div>
          <div className="flex items-center justify-between mt-8">
            <h3 className="text-slate-500 text-sm">Shifts scheduled</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{totalShiftsScheduled}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Shifts worked</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{totalShiftsWorked}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Hours worked</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{totalHoursWorked ? totalHoursWorked?.toFixed(1) : 0}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Average shift length (hrs)</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{averageShiftLength}</h3>
          </div>
        </div>
        <div className="bg-white p-5 rounded-xl w-80">
          <div className="flex items-center justify-between">
            <div className="flex space-x-1">
              <h3 className="font-semibold">Reliability</h3>
              <Tooltip title="To record a missed shift, click on any past shift in the scheduling view and input the appropriate information in the Shift Recap modal.">
                <div>
                  <HelpOutlineOutlinedIcon style={{ fontSize: "16px" }} />
                </div>
              </Tooltip>
            </div>
            <div>
              <AnchorOutlinedIcon style={{ fontSize: "26px" }} />
            </div>
          </div>
          <div className="flex items-center justify-between mt-8">
            <h3 className="text-slate-500 text-sm">Shifts missed</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{missedShifts} <span className="text-xs">out of</span> {totalShiftsScheduled}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Called off sick</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{calledOffSick} <span className="text-xs">out of</span> {missedShifts}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Called off other</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{calledOffOther} <span className="text-xs">out of</span> {missedShifts}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">No-call no-show</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{noCallNoShow} <span className="text-xs">out of</span> {missedShifts}</h3>
          </div>
        </div>
        <div className="bg-white p-5 rounded-xl w-80">
          <div className="flex items-center justify-between">
            <div className="flex space-x-1">
              <h3 className="font-semibold">Activity</h3>
              <Tooltip title="These metrics only count successful instances of these actions. For example, a day off requested that was denied by the manager wouldn't count in `Days off requested`.">
                <div>
                  <HelpOutlineOutlinedIcon style={{ fontSize: "16px" }} />
                </div>
              </Tooltip>
            </div>
            <div>
              <FormatListBulletedOutlinedIcon style={{ fontSize: "26px" }} />
            </div>
          </div>
          <div className="flex items-center justify-between mt-8">
            <h3 className="text-slate-500 text-sm">Days off requested</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{daysOffRequested}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Trades</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{tradesInitiated + tradesAccepted}</h3>
          </div>
          {/* <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Trades accepted</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{tradesAccepted}</h3>
          </div> */}
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Shifts dropped</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{shiftsDropped}</h3>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h3 className="text-slate-500 text-sm">Shifts picked up</h3>
            <div className="h-4 flex-1 bg-yellow border-b border-dashed border-slate-200 mx-0.5"></div>
            <h3 className="text-lg">{shiftsPickedUp}</h3>
          </div>
        </div>
        {/* <div className="bg-white p-5 rounded-xl w-80">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">Happiness Index</h3>
            <div>
              <SentimentSatisfiedOutlinedIcon style={{ fontSize: "26px" }} />
            </div>
          </div>
        </div>
        <div className="bg-white p-5 rounded-xl w-80">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">Productivity</h3>
            <div>
              <ChargingStationOutlinedIcon style={{ fontSize: "26px" }} />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default EmployeeProfileStatisticsTab
