import React from "react";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonDelete from "../requests/shared/helpers/ButtonDelete";

function LaborDriversSalesTable(props) {
  // labor_driver_sales_uuid = request.data.get('labor_driver_sales_uuid')
  // company_id = request.data.get('company_id')
  // name = request.data.get('name')
  // filter_column = request.data.get('filter_column')
  // filter_values = request.data.get('filter_values')
  // target_column = request.data.get('target_column')
  // aggregate_type = request.data.get('aggregate_type')

  const default_data = {
    labor_driver_sales: {
      aggregate_type: undefined,
      filter_column: "",
      filter_values_str: "",
      target_column: "",
      uuid: "",
    },
    labor_driver_attribute: null,
    name: "",
    uuid: "",
  };

  const [salesLaborDrivers, setSalesLaborDrivers] = React.useState([]);

  React.useEffect(() => {
    // Filter props.laborDrivers to include only objects with non-null labor_driver_sales
    const filteredDrivers = props.laborDrivers.filter(
      (driver) => driver.labor_driver_sales !== null
    );
    // Set the filtered array to salesLaborDrivers state
    setSalesLaborDrivers(filteredDrivers);
  }, [props.laborDrivers]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "name") {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [name]: value,
      }));
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        labor_driver_sales: {
          ...prevModalData.labor_driver_sales,
          [name]: value,
        },
      }));
    }
  }

  function handleSubmit() {
    props.post_labor_driver_sales(
      modalData.labor_driver_sales.uuid,
      modalData.name,
      modalData.labor_driver_sales.filter_column,
      modalData.labor_driver_sales.filter_values_str,
      modalData.labor_driver_sales.target_column,
      modalData.labor_driver_sales.aggregate_type
    );
    handleClose();
  }

  console.log("MODAL DATA", modalData);
  console.log("SALES DRIVERS", salesLaborDrivers);

  const agg_func_lookup = (value) => {
    const agg_func_lookup_values = {
      sum: "Sum",
      count: "Count",
      distinct_count: "Count Distinct",
    };

    if (value in agg_func_lookup_values) {
      return agg_func_lookup_values[value];
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="mt-10">
        <div className="w-full flex items-center justify-between px-6">
          <div>
            <h1 className="text-3xl text-slate-900">Sales</h1>
          </div>
          <Tooltip title="Add sales driver">
            <button
              className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
              onClick={() => {
                setModalData(default_data);
                setModalEdit(false);
                handleOpen();
              }}
            >
              <AddIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
            </button>
          </Tooltip>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="flex-1 py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Filter Column
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Filter Values
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Aggregate Column
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Aggregate Type
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-6"
                      >
                        {/* <span className="sr-only">Edit</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {salesLaborDrivers?.map((salesLaborDriver, index) => (
                      <tr
                        key={index}
                        className="hover:bg-slate-50 cursor-pointer"
                        onClick={() => {
                          setModalData(salesLaborDriver);
                          setModalEdit(true);
                          handleOpen();
                        }}
                      >
                        <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                          <div>
                            <p className="text-slate-900">
                              {salesLaborDriver.name}
                            </p>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div>
                            <p className="text-slate-900">
                              {
                                salesLaborDriver.labor_driver_sales
                                  .filter_column
                              }
                            </p>
                          </div>
                        </td>
                        <td className="px-3 py-5 text-sm text-slate-900">
                          <div className="flex flex-wrap items-center gap-2 max-w-[450px]">
                            {
                              (salesLaborDriver.labor_driver_sales.filter_values_str).split(',').map((item, index) => {
                                return(
                                  <div className="bg-violet-100 text-violet-700 rounded px-3 py-1">
                                    {item}
                                  </div>
                                )
                              })
                            }
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div>
                            <p className="text-slate-900">
                              {
                                salesLaborDriver.labor_driver_sales
                                  .target_column
                              }
                            </p>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                          <div>
                            <p className="text-slate-900">
                              {agg_func_lookup(
                                salesLaborDriver.labor_driver_sales
                                  .aggregate_type
                              )}
                            </p>
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium w-5">
                          <div className="flex items-center justify-end w-fit h-full text-slate-500">
                            <ChevronRightIcon />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="border-b border-slate-200"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={modalEdit ? "Edit Sales Driver" : "Add Sales Driver"}
              onClose={handleClose}
            />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-sm text-slate-500">Name</p>
                <div className="mt-1">
                  <TextField
                    placeholder="Driver name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="name"
                    value={modalData.name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Filter Column</p>
                <div className="mt-1">
                  <TextField
                    placeholder="e.g. Order Type"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="filter_column"
                    value={modalData.labor_driver_sales.filter_column}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Filter Values</p>
                <div className="mt-1">
                  <TextField
                    placeholder="Separate values with comma"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="filter_values_str"
                    value={modalData.labor_driver_sales.filter_values_str}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
                <div className="mt-0.5">
                  <p className="text-xs text-slate-500">
                    Separate each value with comma
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Aggregate Column</p>
                <div className="mt-1">
                  <TextField
                    placeholder="e.g. Total Amount"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="target_column"
                    value={modalData.labor_driver_sales.target_column}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Aggregate Type</p>
                <div className="mt-1">
                  <Select
                    fullWidth
                    value={modalData.labor_driver_sales.aggregate_type}
                    name="aggregate_type"
                    onChange={handleChange}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "1px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      height: "45px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem value="sum">Sum</MenuItem>
                    <MenuItem value="count">Count</MenuItem>
                    <MenuItem value="distinct_count">Count Distinct</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Changes" : "Save Sales Driver"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                {modalEdit ? (
                  <ButtonDelete
                    text="Delete"
                    onClick={() => {
                      props.delete_labor_driver(modalData.uuid);
                      handleClose();
                    }}
                  />
                ) : (
                  <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
                )}
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default LaborDriversSalesTable;
