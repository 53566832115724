import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";

export default function StoreProficiencyDisplayConfigurations(props) {
  const proficiencyLevels = [
    {
      value: 0,
      label: "Beginner",
      description:
        "Employees have some experience but are still developing their skills. They may need occasional supervision. Service and product quality are generally good, with occasional issues.",
    },
    {
      value: 1,
      label: "Competent",
      description:
        "Employees have a reasonable amount of experience and have developed a good understanding of their role. They can handle regular tasks independently but may need help with more complex tasks. Service and product quality are generally good and becoming more consistent.",
    },
    {
      value: 2,
      label: "Intermediate",
      description:
        "Staff has a solid foundation in their roles and can handle most tasks independently. They may need help with challenging situations. Service and product quality are consistently good.",
    },
    {
      value: 3,
      label: "Advanced",
      description:
        "Employees are highly skilled and experienced. They can handle complex tasks with minimal supervision and may mentor less experienced staff. Service and product quality are excellent.",
    },
    {
      value: 4,
      label: "Expert",
      description:
        "Staffed by industry veterans with exceptional skills and knowledge. They handle any situation with ease and contribute to best practices. Service and product quality are consistently outstanding.",
    },
  ];

  const [sliderValue, setSliderValue] = useState(props.storeProficiency);

  useEffect(() => {
    setSliderValue(props.storeProficiency);
    //fetchAutoScheduleSettings();
  }, [props.storeProficiency]);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <div>
      <div>
        <h2 className="text-2xl font-bold">Store Proficiency</h2>
      </div>
      <div className="mt-8">
        <Slider
          aria-label="Store Proficiency"
          value={sliderValue}
          onChange={handleSliderChange}
          step={null}
          marks={proficiencyLevels}
          min={0}
          max={4}
        />
      </div>
      <div className="mt-10">
        <h3 className="text-lg">
          What do{" "}
          <span className="font-bold">
            {proficiencyLevels[sliderValue] &&
              proficiencyLevels[sliderValue].label}
          </span>{" "}
          stores look like?
        </h3>
        <p className="mt-4">
          {proficiencyLevels[sliderValue] &&
            proficiencyLevels[sliderValue].description}
        </p>
      </div>
      <div className="mt-10">
        <button
          className="bg-primary hover:bg-blue-700 text-white px-8 py-3 rounded-lg"
          onClick={() => props.saveProficiency(sliderValue)}
        >
          Save Proficiency
        </button>
      </div>
    </div>
  );
}
