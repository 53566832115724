import React from "react";
import RepeatIcon from "@mui/icons-material/Repeat";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import TradesRequestsTableRow from "./TradesRequestsTableRow";
import { add_shift_attributes } from "../../utilities/helpers";
import EmptyState from "../../shared/helpers/EmptyState";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function TradesRequestsTable(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let tradeRequests = props.shiftPoolData.filter(
    (v) => v.status === "PENDING_TRADE" && v.pending_pickups.length > 0
  );

  console.log("TRADE REQUESTS", tradeRequests);

  const hasTradeRequests = tradeRequests.length > 0;

  return (
    <>
      {hasTradeRequests ? (
        <div className="mt-12">
          <div className="flex items-center px-6">
            <div className="flex-auto">
              <h1 className="text-3xl text-slate-900">Requests</h1>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[40%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                        >
                          Initiator
                        </th>
                        {/* <th
                          scope="col"
                          className="w-[16%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Trading Away
                        </th> */}
                        <th
                          scope="col"
                          className="w-[14%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          <div className="w-full flex justify-center">
                            <div className="w-10 flex justify-center">
                              <SwapHorizIcon style={{ fontSize: "24px" }} />
                            </div>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="w-[40%] pr-6 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Responder
                        </th>
                        {/* <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Trading Away
                        </th> */}
                        <th
                          scope="col"
                          className="w-[6%] relative py-3.5 pl-3 pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {/* {trades.map((trade) => (
                        <TradesRequestsTableRow trade={trade} />
                      ))} */}
                      {tradeRequests.map((request, index) =>
                        request.pending_pickups.map((pickup, pickupIndex) => {
                          const senderTrade = add_shift_attributes(request);
                          const receiverTrade =
                            pickup.shift_pickup &&
                            add_shift_attributes(pickup.shift_pickup);
                          return (
                            pickup.shift_pickup && (
                              <TradesRequestsTableRow
                                key={index}
                                senderShiftId={request.id}
                                senderName={senderTrade.name}
                                senderDate={senderTrade.date}
                                senderShift={senderTrade.shift}
                                senderRole={senderTrade.role}
                                senderStation={senderTrade.station?.name}
                                senderRoleType={senderTrade.roleType}
                                pickupUUID={pickup.uuid}
                                receiverName={
                                  pickup.employee_pickup.first_name +
                                  " " +
                                  pickup.employee_pickup.last_name
                                }
                                receiverDate={receiverTrade.date}
                                receiverShift={receiverTrade.shift}
                                receiverRole={receiverTrade.role}
                                receiverStation={receiverTrade.station?.name}
                                receiverRoleType={receiverTrade.roleType}
                                manager_reject_shift_drop={
                                  props.manager_reject_shift_drop
                                }
                                manager_approve_shift_pickup={
                                  props.manager_approve_shift_pickup
                                }
                                manager_shift_pickup_request_response={
                                  props.manager_shift_pickup_request_response
                                }
                              />
                            )
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          title="Requests"
          description="No trade requests at the moment"
        />
      )}
    </>
  );
}
