import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from "@mui/material/Drawer";
import DrawerHeader from '../requests/shared/helpers/DrawerHeader';
import DrawerFooterContainerLarge from '../requests/shared/helpers/DrawerFooterContainerLarge';
import ButtonOutlined from '../requests/shared/helpers/ButtonOutlined';
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import IntegrationCard from './IntegrationCard';
import IntegrationInput from './IntegrationInput';
import AddIcon from "@mui/icons-material/Add";

function IntegrationsTableRow(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setShowInput(false)
    setNewIntegration({
      provider: '',
      key: '',
      company_key: ''
    })
  }

  const [integrations, setIntegrations] = React.useState(props.location?.integration_data)

  const [newIntegration, setNewIntegration] = React.useState({
    provider: '',
    key: '',
    company_key: ''
  })

  const [showInput, setShowInput] = React.useState(false)

  const handleAddIntegration = () => {
    setShowInput(true)
    setNewIntegration({
      provider: '',
      key: '',
      company_key: ''
    })
  };

  const handleDeleteIntegration = (index) => {
    const updatedIntegrations = [...integrations];
    updatedIntegrations.splice(index, 1);
    setIntegrations(updatedIntegrations);
  };

  function handleSubmit() {
    if (newIntegration.provider === '') {
      props.update_integrations_info(props.location?.id, integrations);
    } else {
      setIntegrations(prevIntegrations => [...prevIntegrations, newIntegration]);
      props.update_integrations_info(props.location?.id, [...integrations, newIntegration]);
    }
    handleClose();
  }

  React.useEffect(() => {
    setIntegrations(props.location?.integration_data)
  }, [props.location?.integration_data]);

  console.log("NEWINTEGRATIONS", newIntegration.provider)

  return (
    <>
      <tr
        key={props.i}
        className="hover:bg-slate-50 cursor-pointer"
        onClick={handleOpen}
      >
        <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
          <div>{props.location.store_name}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
          {props.location?.integration_data?.length > 0 ?
            <div className="w-full flex flex-wrap items-center gap-2">
              {props.location?.integration_data.map((integration, index) => {
                return(
                  <div className="bg-violet-100 text-violet-700 rounded px-3 py-1">
                    {integration.provider}
                  </div>
                )
              })}
            </div> :
            <div className="w-content flex justify-start">
              <div className="bg-slate-100 text-slate-400 rounded px-3 py-1">
                Add
              </div>
            </div>
          }
        </td>
        <td className="relative whitespace-nowrap py-5 pl-12 pr-3 text-right text-sm font-medium">
          <div className="flex items-center justify-end w-fit h-full text-slate-500">
            <ChevronRightIcon />
          </div>
        </td>
      </tr>
      <Drawer
        open={open}
        anchor="right"
        onClose={handleClose}
      >
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader title={props.location.store_name} onClose={handleClose} />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-slate-900 text-md font-semibold">Integrations</p>
                <p className="text-slate-500 text-sm mt-1">Configure the integrations for this location</p>
              </div>
              <div className="mt-5">
                {integrations.map((integration, index) => {
                  return(
                    <div
                      key={index}
                      className="mt-3"
                    >
                      <IntegrationCard
                        integration={integration}
                        onDelete={() => handleDeleteIntegration(index)}
                      />
                    </div>
                  )
                })}
              </div>
              {showInput &&
                <div className="mt-3">
                  <IntegrationInput
                    hideIntegrationInput={() => setShowInput(false)}
                    newIntegration={newIntegration}
                    setNewIntegration={setNewIntegration}
                  />
                </div>
              }
              {!showInput &&
                <div className="mt-3">
                  <div
                    className="w-full bg-violet-100 hover:bg-violet-200 text-violet-700 text-sm flex items-center justify-center space-x-1 py-2.5 rounded-lg cursor-pointer"
                    onClick={handleAddIntegration}
                  >
                    <p>Add integration</p>
                    <div>
                      <AddIcon style={{ fontSize: "20px" }} />
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="h-[300px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Save Changes
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default IntegrationsTableRow
