import * as React from "react";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData"; // new import
import isBetweenPlugin from "dayjs/plugin/isBetween";
import weekday from "dayjs/plugin/weekday"; // import weekday plugin
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

dayjs.extend(isBetweenPlugin);
dayjs.extend(weekday); // use weekday plugin

dayjs.extend(localeData);

// Adjust CustomPickersDay styling
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "isSelected" &&
    prop !== "isHovered" &&
    prop !== "startOfWeekIndex",
})(({ theme, isSelected, isHovered, day, startOfWeekIndex = 1 }) => {
  const isStartOfWeek = day.day() === startOfWeekIndex;
  const isEndOfWeek = day.day() === (startOfWeekIndex + 6) % 7;

  return {
    borderRadius: 0,
    ...(isSelected && {
      backgroundColor: "#6d28d9",
      color: theme.palette.primary.contrastText,
      "&:hover, &:focus": {
        backgroundColor: "#6d28d9",
      },
    }),
    ...(isHovered && {
      backgroundColor: "#c4b5fd",
      "&:hover, &:focus": {
        backgroundColor: "#c4b5fd",
      },
    }),
    ...(isStartOfWeek && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(isEndOfWeek && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  };
});

export default function WeekPicker(props) {
  const [hoveredDay, setHoveredDay] = React.useState(null);

  const [value, setValue] = React.useState(dayjs(props.start_date));

  const businessWeekStartDayIndex = props.businessWeekStartDayIndex;
  // Change the start of the week to Monday
  const localeConfig = dayjs.Ls.en; // or dayjs.Ls['your-locale']
  localeConfig.weekStart = businessWeekStartDayIndex;
  dayjs.locale("en", localeConfig); // setting globally

  const isInSameWeek = (dayA, dayB, startOfWeekIndex = 1) => {
    if (!dayB) {
      return false;
    }

    const getStartOfWeek = (day) => {
      let daysToSubtract;
      if (day.day() === startOfWeekIndex) {
        daysToSubtract = 0; // it's already the start of the week
      } else if (day.day() < startOfWeekIndex) {
        daysToSubtract = day.day() + (7 - startOfWeekIndex);
      } else {
        daysToSubtract = day.day() - startOfWeekIndex;
      }
      return day.subtract(daysToSubtract, "day");
    };

    const startOfWeekA = getStartOfWeek(dayA);
    const startOfWeekB = getStartOfWeek(dayB);

    return startOfWeekA.isSame(startOfWeekB, "day");
  };

  function getMondayString(date) {
    // Get the first day of the week
    const monday = date.startOf("week");

    console.log(monday);
    console.log(monday.toDate());
    // Return the formatted string
    return monday.toDate();
  }

  function Day(props) {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
      <CustomPickersDay
        {...other}
        day={day}
        sx={{ px: 2.5 }}
        disableMargin
        selected={false}
        isSelected={isInSameWeek(day, selectedDay, businessWeekStartDayIndex)}
        isHovered={isInSameWeek(day, hoveredDay, businessWeekStartDayIndex)}
        startOfWeekIndex={businessWeekStartDayIndex}
      />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          props.set_week_start_date(getMondayString(newValue));
        }}
        showDaysOutsideCurrentMonth
        slots={{ day: Day }}
        slotProps={{
          day: (ownerState) => ({
            selectedDay: value,
            hoveredDay,
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null),
          }),
        }}
      />
    </LocalizationProvider>
  );
}
