import React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function ShowUnpublishedShiftsButton(props) {
  return (
    <div>
      <Tooltip title={props.showUnpublishedShifts ? "Stop highlighting unpublished shifts" : "Highlight unpublished shifts"}>
        <IconButton
          onClick={() => props.setShowUnpublishedShifts(prevState => !prevState)}
          style={{ color: props.showUnpublishedShifts ? "#6d28d9" : undefined }}
          className="group-hover:bg-gray-200 transition"
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default ShowUnpublishedShiftsButton
