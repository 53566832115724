import React from "react";

export default function ExpectedPayoutCard(props) {
  let total = 0;
  {
    props.contest_metric_detail.map(
      (metric) =>
        (total +=
          metric.store_aggregate_info.length > 0
            ? metric.store_aggregate_info[0].payout
            : 0)
    );
  }

  function showMetricPlaces(metric) {
    metric.store_aggregate_info.length > 0 ? (
      <div className="flex items-center justify-between">
        <p className="text-sm">
          {metric.name}{" "}
          <span className="text-xs">
            ({metric.store_aggregate_info[0].rank}
            {metric.store_aggregate_info[0].rank === 1
              ? "st"
              : metric.store_aggregate_info[0].rank === 2
              ? "nd"
              : metric.store_aggregate_info[0].rank === 3
              ? "rd"
              : "th"}{" "}
            place
          </span>
          )
        </p>
        <p className="text-base">${metric.store_aggregate_info[0].payout}</p>
      </div>
    ) : (
      <p className="text-base">${0}</p>
    );
  }

  return (
    <div className="bg-white w-96 px-10 py-6 flex flex-col justify-center items-center shadow-xl rounded-xl relative">
      <h3 className="font-bold text-xl">Expected Payout</h3>
      <h5 className="text-xs">(If Contest Were to End Today)</h5>
      <div className="mt-3 w-full">
        <div className="flex items-center justify-between mb-0">
          <p className="font-bold text-lg">Total:</p>
          <p className="text-xl font-bold ml-3">${total}</p>
        </div>
        {props.contest_metric_detail.map((metric) => showMetricPlaces(metric))}
      </div>
    </div>
  );
}
