import React from "react";

export default function GoalTableRow(props) {
  const colors = {
    1: "bg-green-600",
    2: "bg-lime-400",
    3: "bg-yellow-400",
    4: "bg-orange-500",
    5: "bg-red-500",
  };
  function sort_by_date(df) {
    df.sort((a, b) => {
      const dateA = new Date(a.week_start_date);
      const dateB = new Date(b.week_start_date);
      return dateA - dateB;
    });
    return df;
  }

  return (
    <tr className="divide-x divide-gray-200">
      <td
        style={{ backgroundColor: "#1C2541" }}
        className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-white sm:pl-6"
      >
        {props.metric_name}
      </td>
      {sort_by_date(props.metric_weekly_info).map((week, i) => {
        return (
          <td
            key={i}
            className={`whitespace-nowrap p-4 text-center text-black ${
              week.color_num === 1
                ? "bg-green-600"
                : week.color_num === 2
                ? "bg-yellow-400"
                : "bg-red-500"
            }`}
          >
            {props.addUnits(week.value, props.metric_detail.units)}
          </td>
        );
      })}
      {props.emptyColumns}
    </tr>
  );
}
