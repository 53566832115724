import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LoadSpinner from '../../../utilities/LoadSpinner';
import { getRightworkAPI } from '../requests/utilities/requests';
import { API_get_learning_tracks } from '../../../constants';
import { getInitials } from '../requests/utilities/helpers';

function CheckpointReviewModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-2 top-2 text-gray-500 cursor-pointer"
          onClick={() => {
            props.handleClose();
          }}
        >
          <CloseIcon />
        </div>
        <div>
          <div>
            <h3 className="text-2xl text-slate-900">Checkpoints Ready to Review</h3>
          </div>
          <div>
            <p className="text-slate-500 text-sm mt-3">
              Check off these employees' training progress so they can continue
            </p>
          </div>
          <div className="mt-6">
            <div className="w-full flex">
              <div className="w-[21%] text-sm font-bold">Name</div>
              <div className="w-[21%] text-sm font-bold">Track</div>
              <div className="w-[21%] text-sm font-bold">Module</div>
              <div className="w-[21%] text-sm font-bold">Section</div>
              <div className="w-[8%] text-sm font-bold">Type</div>
              <div className="w-[8%] flex justify-end pr-2"></div>
            </div>
            {props.filteredEmployeesBlocked.map((employee, index) => {
              const learningTrackEnrollments = employee?.user?.learning_track_enrollments || [];
              return learningTrackEnrollments.map((enrollment, i) => (
                <div
                  key={`${index}-${i}`}
                  className="py-4 border-b border-slate-200 flex items-center"
                >
                  <div className="w-[21%]">
                    <div className="flex items-center space-x-2">
                      <div className="w-9 h-9 rounded-full bg-slate-100 text-slate-500 flex items-center justify-center">
                        <p className="text-sm">{getInitials(employee.first_name, employee.last_name)}</p>
                      </div>
                      <p className="text-sm">{employee.first_name} {employee.last_name}</p>
                    </div>
                  </div>
                  <div className="w-[21%]">
                    <p className="text-sm">{enrollment.track.name}</p>
                  </div>
                  <div className="w-[21%]">
                    <p className="text-sm">{enrollment.next_module.name}</p>
                  </div>
                  <div className="w-[21%]">
                    <p className="text-sm">{enrollment.next_section.name}</p>
                  </div>
                  <div className="w-[8%]">
                    <p className="text-sm">{enrollment.blocked_by_type === "checkpoint" ? "Checkpoint" : "Failed Quiz"}</p>
                  </div>
                  <div className="w-[8%] flex justify-end">
                    <Tooltip
                      title={`Enter ${enrollment.track.name} for ${employee.first_name}`}
                    >
                      <div
                        className="w-9 h-9 bg-slate-100 hover:bg-slate-200 text-slate-500 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          props.handleClose()
                          props.setShowTopBar(false);
                          props.setCurrentTrackEmployeeName(`${employee.first_name} ${employee.last_name}`);
                          props.setCurrentTrack(enrollment);
                          props.setCurrentTrackUUID(enrollment.track.uuid);
                          props.fetchTrackDetails(true, enrollment.track.uuid, employee.id);
                          props.setShowCompleteTrack(true);
                          props.setSelectedEmployee(employee);
                        }}
                      >
                        <PlayArrowIcon />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ));
            })}
          </div>
          <div className="w-full flex justify-end mt-8">
            <div className="flex space-x-3">
              <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                onClick={() => props.handleClose()}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default CheckpointReviewModal;
