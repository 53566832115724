// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";

// import SortIcon from "@mui/icons-material/Sort";
// import IconButton from "@mui/material/IconButton";
// import MenuItem from "@mui/material/MenuItem";
// import Tooltip from "@mui/material/Tooltip";
// import TextField from "@mui/material/TextField";
// import CloseIcon from "@mui/icons-material/Close";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #E2E8F0",
//   borderRadius: "15px",
//   boxShadow: 24,
//   p: 4,
// };

// function SortButton(props) {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   // const [state, setState] = React.useState({ sort_by: "" });

//   // function handleChange(evt) {
//   //   const value = evt.target.value;
//   //   setState({
//   //     ...state,
//   //     [evt.target.name]: value,
//   //   });
//   // }

//   function handleSort(event) {
//     props.setSorted(event.target.value);
//   }

//   function cancelSort() {
//     props.setSorted("");
//     props.sortTeam("");
//   }

//   function handleQuickSort() {
//     props.setSorted("shift_start");
//     props.sortTeam("shift_start");
//   }

//   return (
//     <div>
//       <div className="flex items-center">
//         <Tooltip title={props.sorted ? "Cancel Sort" : "Sort By"}>
//           <IconButton
//             color={props.sorted ? "primary" : "default"}
//             onClick={
//               props.sorted
//                 ? cancelSort
//                 : props.weeklyView
//                 ? handleOpen
//                 : handleQuickSort
//             }
//           >
//             <SortIcon />
//           </IconButton>
//         </Tooltip>
//         {props.sorted && (
//           <p
//             className="text-xxs -ml-3 mr-2 mt-3 cursor-pointer"
//             style={{ color: "#1976d2" }}
//             onClick={cancelSort}
//           >
//             {props.sorted}
//           </p>
//         )}
//       </div>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <div className="flex flex-col justify-center items-center gap-5">
//             <div
//               className="absolute right-2 top-2 text-gray-500 cursor-pointer"
//               onClick={handleClose}
//             >
//               <CloseIcon />
//             </div>
//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//               style={{ fontSize: "24px" }}
//             >
//               Sort By
//             </Typography>
//             <div className="mt-2 mb-4">
//               <Typography id="modal-modal-description">
//                 <TextField
//                   label="Sort By"
//                   name="sort_by"
//                   select
//                   value={props.sorted}
//                   onChange={handleSort}
//                   style={{ width: "200px" }}
//                 >
//                   {!props.weeklyView && (
//                     <MenuItem value={"shift_start"}>Start Time</MenuItem>
//                   )}
//                   <MenuItem value={"hours"}>Hours</MenuItem>
//                   <MenuItem value={"availability"}>Availability</MenuItem>
//                   <MenuItem value={"role"}>Role</MenuItem>
//                   <MenuItem value={"last"}>Last Name</MenuItem>
//                 </TextField>
//               </Typography>
//             </div>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// export default SortButton;

import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SortIcon from "@mui/icons-material/Sort";
import CheckIcon from "@mui/icons-material/Check";

function SortButton(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSort = (value) => {
    props.setSorted(value);
    toggleDropdown();
  };

  function cancelSort() {
    props.setSorted("");
    props.sortTeam("");
  }

  function handleQuickSort() {
    props.setSorted("shift_start");
    props.sortTeam("shift_start");
    toggleDropdown();
  }

  const sortList = [
    !props.weeklyView && { label: "Start Time", value: "shift_start" },
    { label: "Hours", value: "hours" },
    { label: "Availability", value: "availability" },
    { label: "Department", value: "department"},
    { label: "Role", value: "role" },
    { label: "First Name", value: "first"},
    { label: "Last Name", value: "last" },
  ].filter(Boolean);

  const dropdownRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex items-center">
        <div
          className="relative group"
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          <Tooltip title={`${!isOpen ? "Sort By" : ""}`}>
            <IconButton
              style={{ color: props.sorted ? "#6d28d9" : undefined }}
              className="group-hover:bg-gray-200 transition"
            >
              <SortIcon />
            </IconButton>
          </Tooltip>

          {isOpen && (
            <div className="absolute left-0 w-[275px] mt-2 bg-white border border-slate-200 shadow-xl rounded-lg pt-3 z-30">
              <h3 className="text-slate-500 text-sm pb-3 px-5 font-bold">
                Sort By
              </h3>
              {sortList.map((item, index) => (
                <div
                  className="w-full py-1 px-5 hover:bg-slate-50 flex items-center justify-between cursor-pointer"
                  key={index}
                  onClick={() => handleSort(item.value)}
                >
                  <p
                    className={`${
                      props.sorted === item.value
                        ? "text-violet-700"
                        : "text-slate-500"
                    } text-sm py-3 hover:bg-slate-50`}
                  >
                    {item.label}
                  </p>
                  {props.sorted === item.value && (
                    <div className="text-violet-700">
                      <CheckIcon style={{ fontSize: "18px" }} />
                    </div>
                  )}
                </div>
              ))}
              <div
                className="py-3 border-t border-slate-200 w-full flex justify-center hover:bg-slate-50 cursor-pointer rounded-b-lg"
                onClick={cancelSort}
              >
                <p className="text-slate-500 font-semibold text-xs">Reset</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SortButton;
