import React from 'react'
import RepeatIcon from '@mui/icons-material/Repeat';
import Pill from '../../shared/helpers/Pill';
import { formatDateReadable } from '../../utilities/helpers';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PendingTableEmployeeRowTradeModal from './PendingTableEmployeeRowTradeModal';
import PendingTableEmployeeRowTradeDrawer from './PendingTableEmployeeRowTradeDrawer';

function PendingTableEmployeeRowTrade(props) {

  const tradeReceiver = props.myUserId === props.employee_pickup.id

  let cardStatus = ""
  if (props.rescinded_by === "employee-drop" && tradeReceiver) {
    // cardStatus = "rescinded (them)"
    cardStatus = "rescinded"
  } else if (props.rescinded_by === "employee-drop") {
    cardStatus = "rescinded"
  } else if (props.acting_manager_response === "approved") {
    cardStatus = "approved"
  } else if (props.acting_manager_response === "pending" && props.employee_pickup_response === "denied" && tradeReceiver) {
    // cardStatus = "denied (you)"
    cardStatus = "denied"
  } else if (props.acting_manager_response === "pending" && props.employee_pickup_response === "denied") {
    // cardStatus = "denied (them)"
    cardStatus = "denied"
  } else if (props.acting_manager_response === "pending") {
    cardStatus = "pending"
  } else if (props.acting_manager_response === "denied") {
    cardStatus = "denied"
  } else {
    cardStatus = ""
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <tr className="hover:bg-slate-50 cursor-pointer" onClick={toggleDrawer(true)}>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500 pl-6">
          <div className="text-slate-900 flex items-center space-x-2">
            <div className="text-slate-600 w-9 h-9 bg-slate-100 rounded-lg flex items-center justify-center">
              <SwapHorizIcon style={{ fontSize: "20px" }} />
            </div>
            <p className="text-sm">{tradeReceiver ? "Incoming" : "Outgoing"} Trade Request</p>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500 pl-6">
          <div>
            <p className="text-slate-900 text-sm">{formatDateReadable(props.senderDate)}</p>
            <p className="text-slate-500 text-xs">{props.shift_drop_info.shift}</p>
            <p className="text-slate-500 text-xs">{props.shift_drop_info.station ? props.shift_drop_info.station?.name : props.shift_drop_info.role ? props.shift_drop_info.role : props.shift_drop_info.roleType}</p>
            <p className="text-violet-700 text-xs">
              {!tradeReceiver
                ? "You are trading away"
                : `${props.employee_drop.first_name} ${props.employee_drop.last_name} is trading away`}
            </p>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
          <div className="text-slate-400">
            <RepeatIcon style={{ fontSize: "20px" }} />
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
          <div>
            <p className="text-slate-900 text-sm">{formatDateReadable(props.receiverDate)}</p>
            <p className="text-slate-500 text-xs">{props.shift_pickup_info.shift}</p>
            <p className="text-slate-500 text-xs">{props.shift_pickup_info.station ? props.shift_pickup_info.station?.name : props.shift_pickup_info.role ? props.shift_pickup_info.role : props.shift_pickup_info.roleType}</p>
            <p className="text-violet-700 text-xs">
              {tradeReceiver
                ? "You are trading away"
                : `${props.employee_pickup.first_name} ${props.employee_pickup.last_name} is trading away`}
            </p>
          </div>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
          {/* <div className="flex justify-end">
            <Pill status={cardStatus} />
          </div> */}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
          <div className="flex justify-end">
            <div className="mr-4">
              <Pill status={cardStatus} />
            </div>
            <div>
              {cardStatus !== "pending" ?
                <Tooltip title="Archive">
                  <IconButton
                    className="text-slate-500"
                    sx={{ backgroundColor: "#f8fafc" }}
                    onClick={() => props.archive_shift_change_request(props.uuid)}
                  >
                    <ArchiveOutlinedIcon />
                  </IconButton>
                </Tooltip> :
                <div className="w-10 h-10 rounded-full"></div>
              }
            </div>
            {/* <button
              className="bg-primary hover:bg-blue-700 text-white w-[120px] py-2 rounded-lg"
              onClick={handleOpen}
            >
                {(tradeReceiver && cardStatus === "pending" && (props.employee_pickup_response !== "approved" && props.employee_pickup_response !== "denied")) ? "Respond" : "See Status"}
            </button> */}
          </div>
        </td>
      </tr>
      <PendingTableEmployeeRowTradeModal
        open={open}
        handleClose={handleClose}
        tradeInfo={props}
        tradeReceiver={tradeReceiver}
        cardStatus={cardStatus}
        rescind_trade_shift={props.rescind_trade_shift}
        respond_to_trade_request={props.respond_to_trade_request}
        myId={props.myId}
      />
      <PendingTableEmployeeRowTradeDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        tradeInfo={props}
        tradeReceiver={tradeReceiver}
        cardStatus={cardStatus}
        rescind_trade_shift={props.rescind_trade_shift}
        respond_to_trade_request={props.respond_to_trade_request}
        myId={props.myId}
      />
    </>
  )
}

export default PendingTableEmployeeRowTrade
