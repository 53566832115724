import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import MultipleSelectDropdown from "./MultipleSelectDropdown.js";
import TargetMetricModal from "./TargetMetricModal.js";
import GoalTable from "./GoalTable.js";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import PayoutLogicModal from "./PayoutLogicModal.js";
import NewMetricCard from "./NewMetricCard.js";
import EditMetricCard from "./EditMetricCard.js";

export default function CreateContestDisplay(props) {
  const [contestType, setContestType] = React.useState("");

  const [contest_info, set_contest_info] = React.useState({
    name: "",
    status: "draft",
    contest_type: "",
    description: "",
    participating_stores: [],
    start_date: "",
    end_date: "",
  });

  const [contest_metric_detail, set_contest_metric_detail] = React.useState([]);

  const handleContestInfoChange = (event) => {
    set_contest_info({
      ...contest_info,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (props.contestInfo.contest_info && props.selectedDropdown === "Drafts") {
      let new_contest_info = { ...props.contestInfo.contest_info };
      let store_nums = [];
      new_contest_info.participating_stores.forEach((element) => {
        store_nums.push(element.id);
      });
      new_contest_info["participating_stores"] = store_nums;

      set_contest_info(new_contest_info);
      set_contest_metric_detail(props.contestInfo.contest_metric_detail);
    } else {
      set_contest_info({
        name: "",
        status: "draft",
        contest_type: "",
        description: "",
        participating_stores: [],
        start_date: "",
        end_date: "",
      });
      set_contest_metric_detail([]);
    }
  }, [props.contestInfo]);

  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  function getMondayOfDateWeek(date) {
    const new_date = new Date(date);
    const first = new_date.getDate() - new_date.getDay() + 1;

    const monday = new Date(new_date.setDate(first));
    return monday;
  }

  function publishContest() {
    let publish_contest_info = contest_info;
    publish_contest_info["status"] = "upcoming";
    props.saveAsDraft(publish_contest_info, contest_metric_detail);
  }

  return (
    <div className="px-32 mt-10 pb-20">
      <div className="flex items-center gap-8">
        <button
          disabled={contest_info.name === ""}
          className={
            contest_info.name === ""
              ? "px-8 py-1.5 rounded text-white bg-gray-500"
              : "px-8 py-1.5 rounded text-white bg-primary hover:bg-blue-900"
          }
          onClick={() => props.saveAsDraft(contest_info, contest_metric_detail)}
        >
          Save
        </button>
        <button
          disabled={!(contest_info.id > 0)}
          className={
            !(contest_info.id > 0)
              ? "px-8 py-1.5 rounded text-white bg-gray-500"
              : "px-8 py-1.5 rounded text-white bg-primary hover:bg-blue-900"
          }
          onClick={() =>
            props.deleteContest(contest_info, contest_metric_detail)
          }
        >
          Delete
        </button>
        <button
          disabled={contest_metric_detail.length === 0}
          className={
            contest_metric_detail.length === 0
              ? "px-8 py-1.5 rounded text-white bg-gray-500"
              : "px-8 py-1.5 rounded text-white bg-primary hover:bg-blue-900"
          }
          onClick={() => publishContest()}
        >
          Publish
        </button>
      </div>
      <div className="mt-5 w-[640px]">
        <TextField
          fullWidth
          label="Contest Name"
          id="contestName"
          size="small"
          value={contest_info.name}
          name="name"
          onChange={handleContestInfoChange}
        />
      </div>
      <div className="flex items-center mt-5">
        <div className="flex items-center gap-3">
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="date"
            size="small"
            sx={{ input: { color: "rgba(0, 0, 0, 0.7)" } }}
            value={contest_info.start_date}
            name="start_date"
            onChange={handleContestInfoChange}
          />
          <p>-</p>
          <TextField
            id="outlined-basic"
            variant="outlined"
            type="date"
            size="small"
            sx={{ input: { color: "rgba(0, 0, 0, 0.7)" } }}
            value={contest_info.end_date}
            name="end_date"
            onChange={handleContestInfoChange}
          />
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginLeft: "20px", marginRight: "20px" }}
        />
        <div>
          <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={contest_info.contest_type}
                label="Type"
                name="contest_type"
                onChange={handleContestInfoChange}
              >
                <MenuItem value={"competition"}>Competition</MenuItem>
                <MenuItem value={"standalone"}>Standalone</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginLeft: "20px", marginRight: "20px" }}
        />
        <div>
          <MultipleSelectDropdown
            storeOptions={props.storeOptions}
            storeNames={props.storeNames}
            contest_info={contest_info}
            set_contest_info={set_contest_info}
          />
        </div>
      </div>
      <div className="mt-5">
        <TextField
          id="outlined-textarea"
          label="Contest Description"
          placeholder="Describe the contest..."
          multiline
          rows={2}
          style={{ width: "932px" }}
          value={contest_info.description}
          name="description"
          onChange={handleContestInfoChange}
        />
      </div>
      <div className="flex flex-wrap items-center gap-5 mt-5">
        {contest_info.contest_type &&
          contest_metric_detail.map((metric, i) => (
            <EditMetricCard
              key={i}
              indexOf={i}
              metric={metric}
              contest_info={contest_info}
              contest_metric_detail={contest_metric_detail}
              set_contest_metric_detail={set_contest_metric_detail}
              metricOptions={props.metricOptions}
              addUnits={props.addUnits}
            />
          ))}

        {contest_info.contest_type && (
          <NewMetricCard
            contest_info={contest_info}
            contest_metric_detail={contest_metric_detail}
            set_contest_metric_detail={set_contest_metric_detail}
            metricOptions={props.metricOptions}
            addUnits={props.addUnits}
          />
        )}
      </div>
      {contest_info.start_date !== "" &&
        contest_info.end_date !== "" &&
        contest_metric_detail.length > 0 &&
        contest_info.contest_type && (
          <div className="mt-5">
            <h3 className="font-bold">Contest Table Mockup</h3>
            <GoalTable
              week_start_date={getMondayOfDateWeek(contest_info.start_date)}
              week_end_date={getMondayOfDateWeek(contest_info.end_date)}
              selectedWeekStart={getMondayOfDateWeek(contest_info.start_date)}
              formatDate={props.formatDate}
              contest_metric_detail={contest_metric_detail}
              addDays={addDays}
            />
          </div>
        )}
      <div></div>
    </div>
  );
}
