import React from 'react'

export default function ProgressBar(props) {

  const progress = (props.currentStep / props.totalSteps) * 100;

  return (
    <div className="w-60 h-4 bg-gray-400 rounded-full">
      <div className="h-full bg-blue-500 rounded-full" style={{ width: `${progress}%` }}></div>
    </div>
  )
}
