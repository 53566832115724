import React from 'react'

function ButtonGrayed(props) {
  return (
    <div className="w-full bg-gray-200 rounded-full py-3 flex items-center justify-center text-gray-400 text-lg">
      <p>
        {props.text}
      </p>
    </div>
  )
}

export default ButtonGrayed
