import React from 'react'
import SettingsHeader from '../department_role_station_settings/SettingsHeader'
import TabsTwo from '../requests/shared/helpers/TabsTwo'
import TabsThree from '../requests/shared/helpers/TabsThree'
import LaborDriversSalesTable from './LaborDriversSalesTable'
import LaborDriversAttributesTable from './LaborDriversAttributesTable'
import LaborDriversFlagsTable from './LaborDriversFlagsTable'

function LaborDriversDisplay(props) {

  const [activeTab, setActiveTab] = React.useState('Sales')

  function handleTabButtonPress(target) {
    setActiveTab(target)
  }

  return (
    <div>
      {/* <SettingsHeader title="Labor Drivers" /> */}
      <div className="w-[420px] px-6 mt-6">
        {/* <TabsTwo
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Sales"
          tab2="Attributes"
        /> */}
        <TabsThree
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Sales"
          tab2="Attributes"
          tab3="Flags"
        />
      </div>
      <div className="w-full">
        {
          activeTab === 'Sales' ?
            <div>
              <LaborDriversSalesTable
                post_labor_driver_sales={props.post_labor_driver_sales}
                laborDrivers={props.laborDrivers}
                delete_labor_driver={props.delete_labor_driver}
              />
            </div> :
          activeTab === 'Attributes' ?
            <div>
              <LaborDriversAttributesTable
                post_labor_driver_company_attribute={props.post_labor_driver_company_attribute}
                laborDrivers={props.laborDrivers}
                delete_labor_driver={props.delete_labor_driver}
              />
            </div> :
          activeTab === 'Flags' ?
            <div>
              <LaborDriversFlagsTable
                post_labor_driver_company_attribute={props.post_labor_driver_company_attribute}
                laborDrivers={props.laborDrivers}
                laborProgramFlags={props.laborProgramFlags}
                post_labor_program_flag_company={props.post_labor_program_flag_company}
                delete_labor_program_flag_company={props.delete_labor_program_flag_company}
                delete_labor_driver={props.delete_labor_driver}
              />
            </div> :
          <div></div>
        }
      </div>
    </div>
  )
}

export default LaborDriversDisplay
