import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import DefaultAvailabilityCard from "../../shared/availability/DefaultAvailabilityCard.js";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { formatDateReadable } from "../../utilities/helpers.js";
import { getInitials } from "../../utilities/helpers.js";
import CloseIcon from "@mui/icons-material/Close";
import DefaultAvailabilityRequestCard from "./DefaultAvailabilityRequestCard.js";
import { Drawer } from "@mui/material";
import AvatarSmall from "../../shared/helpers/AvatarSmall.js";
import DrawerHeader from "../../shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../shared/helpers/DrawerFooterContainerLarge";
import ButtonFilled from "../../shared/helpers/ButtonFilled";
import ButtonOutlined from "../../shared/helpers/ButtonOutlined";
import StatusWithText from "../../shared/helpers/StatusWithText.js";

export default function AvailabilityRequestsTableRow(props) {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (response) => {
    let note = "";
    let request_id = props.id;
    props.manager_respond_to_availability_request(request_id, response, note);
    handleClose();
    setDrawerOpen(false);
  };

  return (
    <>
      <tr
        className="hover:bg-slate-50 cursor-pointer"
        onClick={toggleDrawer(true)}
      >
        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm">
          <div className="flex items-center">
            <AvatarSmall initials={getInitials(props.name)} />
            <div className="ml-4">
              <div className="font-medium text-gray-900">{props.name}</div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          <DefaultAvailabilityRequestCard
            day={props.day}
            availability={props.currentAvailability}
            availability2={props.currentAvailability2}
            preferences={props.currentPreferences}
            status="current"
          />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
          <DefaultAvailabilityRequestCard
            day={props.day}
            availability={props.requestedAvailability}
            availability2={props.requestedAvailability2}
            preferences={props.requestedPreferences}
            status="pending"
          />
        </td>
        <td className="whitespace-nowrap pl-3 pr-6 py-4 text-sm text-gray-900">
          <div className="flex items-center justify-end">
            {props.note && (
              <div className="text-slate-500">
                <Tooltip title={props.note}>
                  <CommentIcon />
                </Tooltip>
              </div>
            )}
            <div className="text-gray-900 w-32 text-right">
              {formatDateReadable(props.inEffect)}
            </div>
          </div>
        </td>
        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
          <div className="bg-primary hover:bg-blue-700 text-white px-5 py-2 rounded-lg ml-8">{formatDateReadable(props.inEffect)}</div>
        </td> */}
      </tr>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-2xl font-semibold">Availability Request</h2>
          <div>
            <div className="mt-4">
              <Divider />
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center">
                <AvatarSmall initials={getInitials(props.name)} />
                <div className="ml-3">
                  <p className="font-medium text-gray-900">{props.name}</p>
                </div>
              </div>
              <h4 className="text-xs">
                In Effect: {formatDateReadable(props.inEffect)}
              </h4>
            </div>
            <div className="mt-4">
              <Divider />
            </div>
            <div className="w-full flex items-center justify-between mt-4">
              <h3 className="text-base font-semibold">Current Availability</h3>
              <DefaultAvailabilityRequestCard
                day={props.day}
                availability={props.currentAvailability}
                availability2={props.currentAvailability2}
                preferences={props.currentPreferences}
                status="current"
              />
            </div>
            <div className="mt-4">
              <Divider />
            </div>
            <div className="w-full flex items-center justify-between mt-4">
              <h3 className="text-base font-semibold">
                Requested Availability
              </h3>
              <DefaultAvailabilityRequestCard
                day={props.day}
                availability={props.requestedAvailability}
                availability2={props.requestedAvailability2}
                preferences={props.requestedPreferences}
                status="pending"
              />
            </div>
            <div className="mt-4">
              <Divider />
            </div>
            <div className="w-full mt-6 flex justify-end">
              <div className="flex items-center gap-5">
                <button
                  className="px-8 py-3 rounded-lg border border-primary text-primary hover:bg-gray-100 text-sm"
                  onClick={() => handleSubmit("REJECTED")}
                >
                  Deny
                </button>
                <button
                  className="px-8 py-3 rounded-lg bg-primary hover:bg-blue-700 text-white text-sm"
                  onClick={() => handleSubmit("APPROVED")}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-full bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title="Availability Request"
              onClose={toggleDrawer(false)}
            />
            <div className="px-6">
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Name</h3>
                <p className="mt-1 text-xl text-slate-900">{props.name}</p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Day</h3>
                <p className="mt-1 text-xl text-slate-900">{props.day}</p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Current</h3>
                <div className="w-full bg-slate-100 rounded-2xl p-4 mt-3 relative">
                  <div className="flex flex-row items-center space-x-3">
                    <div className="text-slate-500">
                      <Tooltip title="Availability" placement="left">
                        <ScheduleIcon />
                      </Tooltip>
                    </div>
                    <p className="text-slate-900">
                      {props.currentAvailability}
                    </p>
                  </div>
                  {props.currentAvailability2 &&
                    <div className="flex flex-row items-center space-x-3 mt-2.5">
                      <div className="text-slate-500">
                        <Tooltip title="Availability" placement="left">
                          <ScheduleIcon />
                        </Tooltip>
                      </div>
                      <p className="text-slate-900">
                        {props.currentAvailability2}
                      </p>
                    </div>
                  }
                  <div className="flex flex-row items-center space-x-3 mt-2.5">
                    <div className="text-slate-500">
                      <Tooltip title="Preferences" placement="left">
                        <StarBorderIcon />
                      </Tooltip>
                    </div>
                    <p className="text-slate-900">
                      {props.currentPreferences
                        ? props.currentPreferences
                        : "No Preferences Set"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Requested</h3>
                <div
                  className={`w-full bg-yellow-200 rounded-2xl p-4 mt-3 flex flex-row items-center relative`}
                >
                  <div className="flex-1">
                    <div className="flex flex-row items-center space-x-3">
                      <div className="text-slate-500">
                        <Tooltip title="Availability" placement="left">
                          <ScheduleIcon />
                        </Tooltip>
                      </div>
                      <p className="text-slate-900">
                        {props.requestedAvailability}
                      </p>
                    </div>
                    {props.requestedAvailability2 &&
                      <div className="flex flex-row items-center space-x-3 mt-2.5">
                        <div className="text-slate-500">
                          <Tooltip title="Availability" placement="left">
                            <ScheduleIcon />
                          </Tooltip>
                        </div>
                        <p className="text-slate-900">
                          {props.requestedAvailability2}
                        </p>
                      </div>
                    }
                    <div className="flex flex-row items-center space-x-3 mt-2.5">
                      <div className="text-slate-500">
                        <Tooltip title="Preferences" placement="left">
                          <StarBorderIcon />
                        </Tooltip>
                      </div>
                      <p className="text-slate-900">
                        {props.requestedPreferences
                          ? props.requestedPreferences
                          : "No Preferences Set"}
                      </p>
                    </div>
                    <div className="flex flex-row items-center space-x-3 mt-2.5">
                      <div className="text-slate-500">
                        <Tooltip title="Effective Date" placement="left">
                          <CalendarMonthIcon />
                        </Tooltip>
                      </div>
                      <p className="text-slate-900">
                        {formatDateReadable(props.inEffect)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Note</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {props.note ? props.note : "No note"}
                </p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Status</h3>
                <div className="mt-3">
                  <StatusWithText status="pending" />
                </div>
              </div>
              <div className="h-[200px]"></div>
            </div>
          </div>
          <DrawerFooterContainerLarge>
            <div className="w-full">
              <ButtonFilled
                text="Approve"
                onClick={() => handleSubmit("APPROVED")}
              />
            </div>
            <div className="w-full mt-3">
              <ButtonOutlined
                text="Deny"
                onClick={() => handleSubmit("REJECTED")}
              />
            </div>
          </DrawerFooterContainerLarge>
        </div>
      </Drawer>
    </>
  );
}
