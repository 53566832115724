import React from "react";
import TopBarTrainingOverview from "../home/top_bar_components/TopBarTrainingOverview";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import TabsTwo from "../requests/shared/helpers/TabsTwo";
import AssignTrackToEmployeeModal from "./AssignTrackToEmployeeModal";
import CheckpointReviewModal from "./CheckpointReviewModal";
import { formatDate } from "../requests/utilities/helpers";
import { formatDateReadableWithDayOfWeek } from "../requests/utilities/helpers";
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Tooltip from "@mui/material/Tooltip";
import Rating from "@mui/material/Rating";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CompleteTrack from "../take_training_employee/CompleteTrack";
import WarningIcon from '@mui/icons-material/Warning';
import {
  API_get_learning_track_enrollment_detail_for_employee,
  API_post_learning_section_completed,
  API_post_learning_block_user_passed,
} from "../../../constants";
import LoadSpinner from "../../../utilities/LoadSpinner";

function TrainingStoreDisplay(props) {
  const [openAssignTrackToEmployeeModal, setOpenAssignTrackToEmployeeModal] = React.useState(false);
  const handleOpenAssignTrackToEmployeeModal = () => setOpenAssignTrackToEmployeeModal(true);
  const handleCloseAssignTrackToEmployeeModal = () => setOpenAssignTrackToEmployeeModal(false);

  const [openCheckpointReviewModal, setOpenCheckpointReviewModal] = React.useState(false);
  const handleOpenCheckpointReviewModal = () => setOpenCheckpointReviewModal(true);
  const handleCloseCheckpointReviewModal = () => setOpenCheckpointReviewModal(false);

  const [modalDataTrack, setModalDataTrack] = React.useState([]);

  const [currentTrack, setCurrentTrack] = React.useState(null);
  const [currentTrackUUID, setCurrentTrackUUID] = React.useState(null);
  const [currentTrackEmployeeName, setCurrentTrackEmployeeName] =
    React.useState("");
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);

  console.log("CURRENT TRACK", currentTrack);
  console.log("SELECTED STORE", props.selectedStore.id);

  const [isLoading, setIsLoading] = React.useState(false);

  const [showCompleteTrack, setShowCompleteTrack] = React.useState(false);

  const [searchInput, setSearchInput] = React.useState("");
  const [filteredEmployees, setFilteredEmployees] = React.useState([]);

  React.useEffect(() => {
    const lowercasedInput = searchInput.toLowerCase();
    const filtered = props.storeDetailTrainingDataByEmployee.filter(employee =>
      `${employee.first_name} ${employee.last_name}`.toLowerCase().includes(lowercasedInput)
    );
    setFilteredEmployees(filtered);
  }, [searchInput, props.storeDetailTrainingDataByEmployee]);

  const default_data_section = {
    created_at: null,
    name: "",
    updated_at: null,
    uuid: null,
    as_json: [],
  };
  const [modalDataSection, setModalDataSection] =
    React.useState(default_data_section);

  const fetchTrackDetails = async (
    showLoading,
    learning_track_uuid,
    store_team_member_id
  ) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_track_uuid: learning_track_uuid,
      store_team_member_id: store_team_member_id,
    };
    let api = API_get_learning_track_enrollment_detail_for_employee;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        setModalDataTrack(res.data.redux?.payload.learning_track);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  // ----------- POST APIs ----------- //

  // [POST] api/lms_info/post_learning_section_completed
  // * company_id
  // * learning_section_uuid

  const post_learning_section_completed = async (
    learning_section_uuid,
    store_team_member_id
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      learning_section_uuid: learning_section_uuid,
      store_team_member_id: store_team_member_id,
    };

    try {
      toast.loading("Completing Section...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_learning_section_completed,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Section Completed Successfully", { autoClose: 3000 });

        fetchTrackDetails(false, currentTrackUUID, selectedEmployee.id);
        props.fetchStoreDetailTrainingDataByEmployee(
          false,
          props.selectedStore.id
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [POST] api/lms_info/post_learning_block_user_passed
  // * company_id
  // * learning_quiz_uuid

  const post_learning_block_user_passed = async (
    learning_block_uuid,
    is_passed,
    store_team_member_id
  ) => {
    // Prepare data to send
    const requestData = {
      company_id: props.homeCompany,
      learning_block_uuid: learning_block_uuid,
      is_passed: is_passed,
      store_team_member_id: store_team_member_id,
    };

    try {
      // toast.loading("Checking Quiz Score...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_learning_block_user_passed,
        requestData
      );

      if (res.status === 200) {
        // toast.dismiss();
        // toast.success("Quiz Passed Successfully", { autoClose: 3000 });

        fetchTrackDetails(false, currentTrackUUID, selectedEmployee.id);
        props.fetchStoreDetailTrainingDataByEmployee(
          false,
          props.selectedStore.id
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        // toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };


  console.log("STORE DETAIL TRAINING BY EMPLOYEE", props.storeDetailTrainingDataByEmployee);

  const filteredEmployeesBlocked = props.storeDetailTrainingDataByEmployee?.map(employee => {
    // Filter the learning_track_enrollments for each employee
    const filteredEnrollmentsBlocked = employee.user?.learning_track_enrollments?.filter(enrollment => enrollment.is_blocked);

    // Return a new object with the filtered enrollments
    return {
        ...employee,
        user: {
            ...employee?.user,
            learning_track_enrollments: filteredEnrollmentsBlocked
        }
    };
  })?.filter(employee => employee?.user?.learning_track_enrollments?.length > 0);

  console.log("FILTER EMPLOYEES", filteredEmployeesBlocked);



  return (
    <>
      {!isLoading ? (
        <div>
          {!showCompleteTrack ? (
            <div>
              <div className="mt-12 px-8 flex items-center justify-between">
                <div>
                  <div className="flex items-center space-x-5">
                    <h1 className="text-3xl text-slate-900">
                      {props.selectedStore.store_name}
                    </h1>
                    {filteredEmployeesBlocked?.length > 0 && (
                      <div
                        className="flex item-center space-x-1 text-violet-700 hover:text-violet-800 mt-2 cursor-pointer"
                        onClick={handleOpenCheckpointReviewModal}
                      >
                        <div>
                          <WarningIcon style={{ fontSize: "16px", marginBottom: "7px" }} />
                        </div>
                        <div>
                          <p className="text-sm">
                            {filteredEmployeesBlocked.length} checkpoint{filteredEmployeesBlocked.length > 1 ? 's' : ''} ready for review
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="text-slate-500 mt-2">
                    {props.storeDetailTrainingDataByEmployee?.length} Employees
                  </p>
                  <div className="mt-6">
                    <p className="text-slate-500 text-xs">Search by name</p>
                    <input
                      className={`w-[250px] h-[40px] border border-slate-300 px-3 text-sm rounded-lg outline-none focus:border-2 focus:border-violet-700 mt-1`}
                      type="text"
                      placeholder="🔎  i.e. John Smith"
                      value={searchInput}
                      onChange={(event) =>
                        setSearchInput(event.target.value)
                      }
                    />
                  </div>
                </div>
                {/* <div className="w-96">
                  <TabsTwo
                    activeTab={activeTab}
                    handleTabButtonPress={handleTabButtonPress}
                    tab1="Employees"
                    tab2="Role Proficiences"
                  />
                </div> */}
              </div>
              <div className="mt-8 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-slate-200">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-[20%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                            >
                              Name
                            </th>
                            {/* <th
                              scope="col"
                              className="w-[20%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Hire Date
                            </th> */}
                            <th
                              scope="col"
                              className="w-[24%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Role Proficiency Achieved
                            </th>
                            <th
                              scope="col"
                              className="w-[20%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Last Active
                            </th>
                            <th
                              scope="col"
                              className="flex-1 pl-3 pr-12 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Assigned Tracks
                            </th>
                            {/* <th
                              scope="col"
                              className="w- relative py-3.5 pl-3 pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-200 bg-white">
                          {filteredEmployees.map(
                            (employee, index) => (
                              <tr
                                key={index}
                              >
                                <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                  <div>
                                    {employee.first_name} {employee.last_name}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                  {employee?.store_team_member_roles?.length >
                                  0 ? (
                                    <div className="flex flex-col gap-y-5">
                                      {employee?.store_team_member_roles
                                        ?.sort((a, b) => {
                                          if (
                                            a.scheduled_role_obj
                                              ?.role_type_id ===
                                            b.scheduled_role_obj?.role_type_id
                                          ) {
                                            return a.scheduled_role_obj?.role_name.localeCompare(
                                              b.scheduled_role_obj?.role_name
                                            );
                                          }
                                          return (
                                            a.scheduled_role_obj?.role_type_id -
                                            b.scheduled_role_obj?.role_type_id
                                          );
                                        })
                                        .map((role, index) => (
                                          <div
                                            key={index}
                                            className="gap-x-3 text-slate-500"
                                          >
                                            <p>
                                              {
                                                role?.scheduled_role_obj
                                                  ?.role_name
                                              }
                                            </p>
                                            <div className="mt-0.5">
                                              <Rating
                                                name="read-only"
                                                value={
                                                  role?.training_proficiency_rating
                                                }
                                                precision={0.5}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    <div className="text-sm text-slate-500">
                                      N / A
                                    </div>
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                  {employee?.user?.learning_track_enrollments
                                    ?.length > 0 ? (
                                    <div className="text-slate-500">
                                      {(() => {
                                        const enrollments =
                                          employee.user
                                            .learning_track_enrollments;
                                        const mostRecentEnrollment =
                                          enrollments.reduce(
                                            (latest, current) => {
                                              return new Date(
                                                latest.updated_at
                                              ) > new Date(current.updated_at)
                                                ? latest
                                                : current;
                                            }
                                          );
                                        const mostRecentDate =
                                          formatDateReadableWithDayOfWeek(
                                            formatDate(
                                              new Date(
                                                mostRecentEnrollment.updated_at
                                              )
                                            )
                                          );
                                        return mostRecentDate;
                                      })()}
                                    </div>
                                  ) : (
                                    <div className="text-sm text-slate-500">
                                      N / A
                                    </div>
                                  )}
                                </td>
                                <td className="whitespace-nowrap pl-3 pr-8 py-5 text-sm text-slate-900">
                                  {employee?.user?.learning_track_enrollments
                                    ?.length > 0 ? (
                                    <div>
                                      {employee?.user?.learning_track_enrollments?.map(
                                        (enrollment, index) => {
                                          const trackProgress =
                                            (enrollment.sections_completed_count /
                                              enrollment.total_sections_count) *
                                            100;
                                          return (
                                            <div key={index} className="mb-3.5">
                                              <div className="flex items-center space-x-5">
                                                <div className="flex-1">
                                                  <div className="w-full flex items-center justify-between">
                                                    <p className="text-slate-500">
                                                      {enrollment.track.name}
                                                    </p>
                                                    <p className="text-slate-500 text-xs">
                                                      {trackProgress.toFixed()}%
                                                      completed
                                                    </p>
                                                  </div>
                                                  <div className="w-full h-2 rounded-full bg-slate-200 mt-1 overflow-hidden">
                                                    <div
                                                      className="h-2 bg-emerald-600 rounded-full"
                                                      style={{
                                                        width: `${trackProgress}%`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                                <Tooltip
                                                  title={`Enter ${enrollment.track.name} for ${employee.first_name}`}
                                                >
                                                  <div
                                                    className="w-9 h-9 bg-slate-100 hover:bg-slate-200 text-slate-500 rounded-full flex items-center justify-center cursor-pointer"
                                                    onClick={() => {
                                                      props.setShowTopBar(false);
                                                      setCurrentTrackEmployeeName(`${employee.first_name} ${employee.last_name}`);
                                                      setCurrentTrack(enrollment);
                                                      setCurrentTrackUUID(enrollment.track.uuid);
                                                      fetchTrackDetails(true, enrollment.track.uuid, employee.id);
                                                      setShowCompleteTrack(true);
                                                      setSelectedEmployee(employee);
                                                    }}
                                                  >
                                                    <PlayArrowIcon />
                                                  </div>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div className="text-slate-500 flex items-center gap-x-1.5">
                                      <div>
                                        <InfoIcon
                                          style={{
                                            fontSize: "18px",
                                            marginBottom: "2px",
                                          }}
                                        />
                                      </div>
                                      <p>
                                        No tracks have been assigned to this
                                        employee
                                      </p>
                                    </div>
                                  )}
                                  <div
                                    className="w-fit px-8 h-9 bg-violet-50 hover:bg-violet-100 rounded-lg text-violet-700 flex items-center justify-center space-x-1 mt-6 cursor-pointer"
                                    onClick={() => {
                                      setSelectedEmployee(employee);
                                      handleOpenAssignTrackToEmployeeModal();
                                    }}
                                  >
                                    <div>
                                      <AddIcon
                                        style={{
                                          fontSize: "20px",
                                          marginBottom: "1px",
                                        }}
                                      />
                                    </div>
                                    <p>Assign new track</p>
                                  </div>
                                </td>
                                {/* <td className="relative whitespace-nowrap py-5 pl-6 pr-6 text-right text-sm font-medium w-5">
                                <div className="flex items-center justify-end w-fit h-full text-slate-500">
                                  <ChevronRightIcon />
                                </div>
                              </td> */}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      <div className="border-b border-slate-200"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full min-h-screen">
              <CompleteTrack
                currentTrack={currentTrack}
                modalDataTrack={modalDataTrack}
                setModalDataTrack={setModalDataTrack}
                modalDataSection={modalDataSection}
                setModalDataSection={setModalDataSection}
                homeCompany={props.homeCompany}
                showCompleteTrack={showCompleteTrack}
                setShowCompleteTrack={setShowCompleteTrack}
                post_learning_section_completed={
                  post_learning_section_completed
                }
                post_learning_block_user_passed={
                  post_learning_block_user_passed
                }
                employeeName={currentTrackEmployeeName}
                selectedEmployee={selectedEmployee}
                setShowTopBar={props.setShowTopBar}
                userType="Manager"
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
      <AssignTrackToEmployeeModal
        open={openAssignTrackToEmployeeModal}
        handleClose={handleCloseAssignTrackToEmployeeModal}
        post_learning_track_enrollment={props.post_learning_track_enrollment}
        homeCompany={props.homeCompany}
        selectedEmployee={selectedEmployee}
      />
      <CheckpointReviewModal
        open={openCheckpointReviewModal}
        handleClose={handleCloseCheckpointReviewModal}
        homeCompany={props.homeCompany}
        filteredEmployeesBlocked={filteredEmployeesBlocked}
        setShowTopBar={props.setShowTopBar}
        setCurrentTrackEmployeeName={setCurrentTrackEmployeeName}
        setCurrentTrack={setCurrentTrack}
        setCurrentTrackUUID={setCurrentTrackUUID}
        fetchTrackDetails={fetchTrackDetails}
        setShowCompleteTrack={setShowCompleteTrack}
        setSelectedEmployee={setSelectedEmployee}
      />
    </>
  );
}

export default TrainingStoreDisplay;
