import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import SettingsIcon from "@mui/icons-material/Settings";

export default function TargetMetricCard(props) {
  console.log(props.metric_detail);
  console.log(props.contest_info);
  return (
    <div>
      <div className="flex flex-col justify-center items-center bg-white w-56 pt-5 pb-7 rounded-xl shadow-xl relative">
        {props.week_data.length > 0 && (
          <div
            className={`absolute bottom-1 right-1 ${
              props.week_data[0].color_num === 1
                ? "text-green-600"
                : props.week_data[0].color_num === 2
                ? "text-yellow-400"
                : "text-red-500"
            } `}
          >
            <CircleIcon style={{ fontSize: "18px" }} />
          </div>
        )}

        {/* <div className="absolute bottom-1 left-2">
          <h4 className="font-bold text-gray-400">5x</h4>
        </div> */}
        <h3 className="font-bold">{props.title}</h3>
        <div className="mt-2">
          <p>
            Actual:{" "}
            <span className="text-2xl font-bold ml-3">
              {props.week_data.length > 0
                ? props.addUnits(
                    props.week_data[0].value,
                    props.metric_detail.units
                  )
                : "-"}
            </span>
          </p>
          {props.metric_detail.relative_value_ind === 1 && (
            <div>
              <p className="mt-1">
                Last Year:{" "}
                <span className="text-2xl font-bold ml-3">
                  {props.week_data.length > 0
                    ? props.addUnits(
                        props.week_data[0].relative_value,
                        props.metric_detail.units
                      )
                    : "-"}
                </span>
              </p>
              <p className="mt-1">
                % Change:{" "}
                <span className="text-2xl font-bold ml-3">
                  {props.week_data.length > 0
                    ? props.addUnits(props.week_data[0].final_value, "percent")
                    : "-"}
                </span>
              </p>
            </div>
          )}
          <p className="mt-4 text-xs font-semibold">Payouts</p>
          {props.contest_info.contest_type === "competition" ? (
            <div>
              <div className="text-xxs">
                {props.metric_detail.competition_payout.map((place) => (
                  <p>
                    {place.rank}: ${place.payout}
                  </p>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-xxs">
              {props.metric_detail.standalone_payout.map((payout) => (
                <p>
                  ${payout.payout} if metric{" "}
                  {props.metric_detail.higher_number_better_ind === 1
                    ? ">"
                    : "<"}{" "}
                  {props.addUnits(
                    payout.threshold,
                    props.metric_detail.relative_value_ind === 1
                      ? "percent"
                      : props.metric_detail.units
                  )}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
