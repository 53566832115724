import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from '@mui/icons-material/Send';

function PublishTrackModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div>
              <h3 className="text-2xl text-slate-900">Publish "{props.modalDataTrack.name}"</h3>
            </div>
            {props.modalDataTrack?.roles_assigned_to?.length > 0 ?
              <div>
                <p className="text-slate-500 text-sm mt-4">Employees who can perform the roles listed below will be automatically enrolled. Additionally, you can manually assign an employee to this track in the Training Overview tab.</p>
                <div className="bg-slate-50 rounded-xl px-4 py-2 text-slate-900 mt-5">
                  {props.modalDataTrack.roles_assigned_to?.map((role, index) => (
                    <div className="flex items-center space-x-2 py-2">
                      <div className="flex items-center justify-center w-4 h-4 rounded-full bg-emerald-600 text-white">
                        <CheckIcon style={{ fontSize: "14px" }} />
                      </div>
                      <p className="font-bold text-sm">{role.role_name}</p>
                    </div>
                  ))}
                </div>
                <div className="flex space-x-2 mt-4">
                  <div className="text-orange-500">
                    <InfoIcon style={{ fontSize: "18px" }} />
                  </div>
                  <p className="text-slate-500 text-xs">If you do not want to automatically assign this track to these roles, you can adjust the <span className="font-bold">Auto-assign to roles</span> option in the track's edit tab before publishing.</p>
                </div>
              </div> :
              <div>
                <p className="text-slate-500 text-sm mt-4">If you publish this track without assigning any specific roles, you can manually enroll any employee in the Training Overview tab.</p>
                <div className="flex space-x-2 mt-4 p-4 bg-slate-50 rounded-xl">
                  <div className="text-orange-500">
                    <InfoIcon style={{ fontSize: "18px" }} />
                  </div>
                  <p className="text-slate-500 text-xs">To automatically enroll employees in this track based on specific roles, adjust the <span className="font-bold">Auto-assign to roles</span> option in the track's edit tab before publishing.</p>
                </div>
              </div>
            }
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div className="px-5 py-2 flex items-center justify-center space-x-3 bg-emerald-600 hover:bg-emerald-700 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.post_learning_track_enrollment_by_roles()
                    props.handleClose()
                  }}
                >
                  <p>Publish Track</p>
                  <div>
                    <SendIcon style={{ fontSize: "20px", marginBottom: "2px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default PublishTrackModal
