import React from "react";
import Stack from "@mui/material/Stack";
import DateSelector from "./DateSelector";
import ScheduleTypeFilter from "../../home/top_bar_components/ScheduleTypeFilter";

function DateScheduleTypeGrouping(props) {
  return (
    <div>
      <div className="flex justify-between items-center w-72">
        <DateSelector
          date={props.date}
          increment_day={props.increment_day}
        />
        {/* <ScheduleTypeFilter
          roleType={props.roleType}
          setRoleType={props.setRoleType}
          roleTypeOptions={props.roleTypeOptions}
        /> */}
      </div>
    </div>
  );
}

export default DateScheduleTypeGrouping;
