import React from "react";
import AvailabilityInputsModal from "./AvailabilityInputsModal";
import { get_t_str_shortened } from "../../../../helpers/helpers";

function AvailabilityCard(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.setSaveAvailabilityFinished(false);
  };


  // Determine the availability text based on available hours
  let availability = "";
  if (
    props.availability_info.available_from === 0 &&
    props.availability_info.available_to === 24
  ) {
    availability = "All Day";
  } else if (
    props.availability_info.available_to === 0 &&
    props.availability_info.available_to === 0
  ) {
    availability = "No Availability";
  } else {
    availability = `${get_t_str_shortened(
      props.availability_info.available_from
    )} - ${get_t_str_shortened(props.availability_info.available_to)}`;
  }

  // Calculate available hours and determine the background color
  let availableHours =
    props.availability_info.available_to -
    props.availability_info.available_from;
  let availableColor = "";
  let tempText = "";
  if (availableHours === 0 && props.edit_mode) {
    availableColor = "bg-red-200 hover:bg-red-300 cursor-pointer";
    tempText = "text-red-600"
  } else if (availableHours < 24 && props.edit_mode) {
    availableColor = "bg-amber-200 hover:bg-amber-300 cursor-pointer";
    tempText = "text-amber-600"
  } else if (props.edit_mode) {
    availableColor = "bg-green-200 hover:bg-green-300 cursor-pointer";
    tempText = "text-green-600"
  } else if (availableHours === 0) {
    availableColor = "bg-red-200 hover:bg-red-300 cursor-pointer";
    tempText = "text-red-600"
  } else if (availableHours < 24) {
    availableColor = "bg-amber-200 hover:bg-amber-300 cursor-pointer";
    tempText = "text-amber-600"
  } else {
    availableColor = "bg-green-200 hover:bg-green-300 cursor-pointer";
    tempText = "text-green-600"
  }

  const splitAvailability = props.availability_info.available_to_2

  console.log("AVAILABILITY CARD INFO", props.availability_info)

  return (
    <div className="w-full h-full">
      <div
        onClick={handleOpen}
        className="w-full h-full flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md"
      >
        <div
          className={`w-full h-full flex items-center py-3 px-2 text-slate-900 hover:text-black ${availableColor} relative`}
        >
          {props.availability_info?.is_temporary &&
            <div className="absolute top-0.5 right-1">
              <p className={`text-xxs ${tempText}`}>Temp</p>
            </div>
          }
          {!splitAvailability ?
            <div className="mx-1">
              <p className="text-xs font-bold xl:font-normal">{availability}</p>
              {!props.minimizeMode && availableHours > 0 && props.availability_info.preferred_to > 0 && (
                <p className="text-xxs">
                  {"Pref: " +
                    get_t_str_shortened(props.availability_info.preferred_from) +
                    " - " +
                    get_t_str_shortened(props.availability_info.preferred_to)}
                </p>
              )}
            </div> :
            <div className="mx-1">
              <p className="text-xxs font-bold xl:font-normal">{`${get_t_str_shortened(props.availability_info.available_from)} - ${get_t_str_shortened(props.availability_info.available_to)}`}</p>
              <p className="text-xxs font-bold xl:font-normal">{`${get_t_str_shortened(props.availability_info.available_from_2)} - ${get_t_str_shortened(props.availability_info.available_to_2)}`}</p>
              {!props.minimizeMode && availableHours > 0 && props.availability_info.preferred_to > 0 && (
                <p className="text-xxxs xl:hidden">
                  {"Pref: " +
                    get_t_str_shortened(props.availability_info.preferred_from) +
                    " - " +
                    get_t_str_shortened(props.availability_info.preferred_to)}
                </p>
              )}
            </div>
          }
        </div>
      </div>
      <AvailabilityInputsModal
        open={open}
        handleClose={handleClose}
        availability_info={props.availability_info}
        p={props.p}
        new_availability={false}
        editAvailability={props.editAvailability}
        date_name={props.date_name}
        day_of_week_name={props.day_of_week_name}
        date_str={props.date_str}
        get_t_str={props.get_t_str}
        availableColor={availableColor}
        availableHours={availableHours}
        availability={availability}
        saveAvailabilityFinished={props.saveAvailabilityFinished}
        d={props.d}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        request_time_off={props.request_time_off}
        delete_temporary_availability={props.delete_temporary_availability}
      />
    </div>
  );
}

export default AvailabilityCard;
