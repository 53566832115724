import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  color: "black",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#eeeeee",
    border: "2px solid #1976D2",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 40,
    height: 40,
    color: "black",
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#eeeeee",
    border: "1px solid #1976D2",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-225deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -10%) rotate(-225deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(225deg)",
    },
  },
});

export default function ForecastAdjustmentSlider(props) {
  function num_to_slider(number) {
    return Number((((number + 100) * (100 - 0)) / 200).toFixed(2));
  }

  function slider_to_num(number) {
    return Number((((number - 0) * 200) / (100 - 0) - 100).toFixed(2));
  }

  const [value, setValue] = React.useState(num_to_slider(props.adjValue * 100));

  function valueLabelFormat(value) {
    return slider_to_num(value).toFixed(0) + "%";
  }

  const handleChange = (event, newValue, activeThumb) => {
    setValue(newValue);
    props.setAdjValue(slider_to_num(newValue) / 100);
  };

  return (
    <Box sx={{ width: 300 }}>
      <PrettoSlider
        getAriaLabel={() => "Minimum distance"}
        value={value}
        step={0.5}
        onChange={handleChange}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        track={false}
        valueLabelDisplay="on"
      />
    </Box>
  );
}
