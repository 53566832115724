import React, { useState, useEffect } from "react";
import {
  LineChart,
  Bar,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import CheckIcon from "@mui/icons-material/Check";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PersonIcon from "@mui/icons-material/Person";

const VisualCustomLineChartForChartCard = (props) => {
  const [data, setData] = useState(props.data);
  const [hiddenLines, setHiddenLines] = useState([]);

  useEffect(() => {
    setData(props.data); // Update data state
  }, [props.data]);

  const handleDataChange = (index, field, newValue) => {
    if (newValue >= 0) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index][field] = newValue;
        newData[index].employeesOptimal = Object.keys(newData[index])
          .filter((key) => key.endsWith(` ${props.label1} Labor`))
          .reduce((sum, key) => sum + newData[index][key], 0); // Update total employeesOptimal
        return newData;
      });
    }
  };

  const handleInputChange = (index, field, event) => {
    const newValue = parseInt(event.target.value, 10);
    handleDataChange(index, field, newValue);
  };

  const handleCheckboxChange = (dataKey) => {
    setHiddenLines((prevHiddenLines) => {
      if (prevHiddenLines.includes(dataKey)) {
        return prevHiddenLines.filter((key) => key !== dataKey);
      } else {
        return [...prevHiddenLines, dataKey];
      }
    });
  };

  const getCheckboxColor = (name) => {
    if (name.includes(`${props.label1} Non Flexible`)) {
      return "#cbd5e1"; // Blue color for Forecasted
    } else if (name.includes(props.label2)) {
      return "#FF6F59"; // Light Blue color for Scheduled
    } else {
      return "#bbf7d0"; // Default color
    }
  };

  const roleTypes = props.roleTypes;

  console.log("INDEX", props.index);

  const showCheckBoxOption = (name, label) => {
    return (
      <div className="flex items-center mt-1">
        <input
          type="checkbox"
          checked={!hiddenLines.includes(name)}
          onChange={() => handleCheckboxChange(name)}
          style={{
            appearance: "none",
            WebkitAppearance: "none",
            width: props.isSmall ? "8px" : "12px",
            height: props.isSmall ? "8px" : "12px",
            borderRadius: "50px",
            border: `2px solid ${getCheckboxColor(name)}`,
            outline: "none",
            marginRight: "5px",
            cursor: "pointer",
            backgroundColor: !hiddenLines.includes(name)
              ? getCheckboxColor(name)
              : "transparent",
          }}
        />
        <label htmlFor={name} className={`ml-1 ${props.isSmall && "text-xs"}`}>
          {label}
        </label>
      </div>
    );
  };

  const showDataRow = (name, bold) => {
    return (
      <tr key={name}>
        <td
          className={
            "border px-5 py-4 text-xs whitespace-nowrap bg-gray-100 " + bold
          }
          style={{
            position: "sticky",
            left: 0,
            zIndex: 1,
            minWidth: "150px",
          }}
        >
          {name}
        </td>
        {data.map((entry, index) => (
          <td key={index} className="border">
            <div
              className={
                "h-full w-full flex items-center justify-center text-sm m-auto " +
                bold
              }
            >
              <p>{entry[name] ? entry[name] : 0}</p>
            </div>
          </td>
        ))}
      </tr>
    );
  };

  const showLine = (name) => {
    return (
      !hiddenLines.includes(name) && (
        <Line
          type="stepAfter"
          dataKey={name}
          stroke={getCheckboxColor(name)}
          strokeWidth={1}
          // dot={{ onMouseDown: (event, payload) => event.stopPropagation() }}
          isAnimationActive={true}
          dot={false}
        />
      )
    );
  };

  const showStackedArea = (name) => {
    return (
      !hiddenLines.includes(name) && (
        <Area
          type="stepAfter"
          dataKey={name}
          //stroke={getCheckboxColor(name)}
          // strokeWidth={2}
          // dot={{ onMouseDown: (event, payload) => event.stopPropagation() }}
          // isAnimationActive={true}
          fill={getCheckboxColor(name)}
          stroke={getCheckboxColor(name)}
          stackId="1"
        />
      )
    );
  };

  const showArea = (name) => {
    return (
      !hiddenLines.includes(name) && (
        <Area
          type="stepAfter"
          dataKey={name}
          //stroke={getCheckboxColor(name)}
          //strokeWidth={2}
          //dot={{ onMouseDown: (event, payload) => event.stopPropagation() }}
          isAnimationActive={false}
          fill={getCheckboxColor(name)}
        />
      )
    );
  };

  const CustomTooltip = ({ active, payload, label, coordinate }) => {
    const nonFlexibleTotal = payload?.[0]?.value;
    const flexibleTotal = payload?.[1]?.value;
    const scheduledTotal = payload?.[2]?.value;
    const forecastedTotal = flexibleTotal + nonFlexibleTotal;
    const difference = scheduledTotal - forecastedTotal;

    if (active && payload && payload.length) {
      // Get the position of the tooltip from the coordinate prop
      const tooltipStyle = {
        position: "absolute",
        left: coordinate.x - 100,
        top: coordinate.y - 175,
        transform: "translateX(-50%)",
        pointerEvents: "none",
        zIndex: 9999,
      };

      return (
        <div
          className="bg-white border border-gray-300 w-60 px-6 py-3.5 rounded-xl shadow-xl"
          style={tooltipStyle}
        >
          <div className="w-full flex items-center justify-between mb-3">
            <p className="text-slate-900 text-xs">{`${label}`}</p>
            <p className="text-slate-900 text-xs">Total</p>
          </div>
          {payload.map((entry, index) => {
            const words = entry.name.split(" ");
            const modifiedName = words.slice(0, -1).join(" ");

            return (
              <div
                key={index}
                className={`w-full flex items-center justify-between py-1 ${
                  index !== 0 ? "border-t border-slate-200" : ""
                }`}
              >
                <div className="flex items-center space-x-3">
                  <div
                    className={`w-2 h-2 rounded-full`}
                    style={{ backgroundColor: entry.stroke }}
                  ></div>
                  <div className="text-slate-500 text-xs">{modifiedName}</div>
                </div>
                <div className="text-slate-900 text-xs">{entry.value}</div>
              </div>
            );
          })}
          {difference === 0 ? (
            <div className="mt-1 flex flex-col items-center justify-center text-emerald-600">
              <div>
                <CheckIcon style={{ fontSize: "22px" }} />
              </div>
              <div className="text-xs">Perfect</div>
            </div>
          ) : difference < 0 ? (
            <div className="mt-1 flex flex-col items-center justify-center text-blue-600">
              <div className="flex items-center space-x-1">
                <div className="font-bold text-base">
                  {Math.abs(difference)}
                </div>
                <div>
                  <PersonIcon
                    style={{ fontSize: "18px", marginBottom: "3px" }}
                  />
                </div>
              </div>
              <div className="text-xs">Understaffed</div>
            </div>
          ) : (
            <div className="mt-1 flex flex-col items-center justify-center text-rose-600">
              <div className="flex items-center space-x-1">
                <div className="font-bold text-base">
                  {Math.abs(difference)}
                </div>
                <div>
                  <PersonIcon
                    style={{ fontSize: "18px", marginBottom: "3px" }}
                  />
                </div>
              </div>
              <div className="text-xs">Overstaffed</div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const scheduledHours = (props.agg_column(props.actual_schedule_labor_day, "hours"))
  const forecastedHours = (props.agg_column(props.forecasted_labor_day, "hours"))
  const actualHours = (props.agg_column(props.actual_used_labor_vs_earned_day, "hours"))
  const earnedHours = (props.agg_column(props.calculated_earned_labor_day, "hours"))

  return (
    <div className="relative">
      <div className="w-full flex items-center justify-between">
        <h3 className="text-slate-500 ml-3.5 text-xxs w-32">{props.date_name}</h3>
      </div>
      <div className="absolute -right-0.5 top-0">
        {props.earned_toggle ?
          <p className={`${actualHours <= earnedHours ? "text-emerald-800" : "text-rose-800"} text-xxs`}>{actualHours.toFixed()} / {earnedHours.toFixed()} hrs</p> :
          <p className={`${scheduledHours <= forecastedHours ? "text-emerald-800" : "text-rose-800"} text-xxs`}>{scheduledHours.toFixed()} / {forecastedHours.toFixed()} hrs</p>
        }
      </div>
      <div className="-ml-1.5 -mb-3.5">
        {
          <ResponsiveContainer
            width={
              props.windowWidth > 1400
                ? 155
                : props.windowWidth > 1300
                ? 140
                : props.windowWidth > 1200
                ? 125
                : props.windowWidth > 1100
                ? 110
                : 95
            }
            height={props.height}
            padding={0}
          >
            <ComposedChart data={data}>
              <XAxis
                dataKey="time"
                tick={{ fontSize: 12 }}
                tickLine={false}
                interval="preserveEnd"
                minTickGap={20}
                hide={true}
              />
              <YAxis width={15} tick={{ fontSize: 10 }} tickLine={false} />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

              {showStackedArea(`${props.label1} Non Flexible Total`)}
              {showStackedArea(`${props.label1} Flexible Total`)}
              {showLine(`${props.label2} Labor Total`)}
              <Tooltip content={<CustomTooltip />} />
            </ComposedChart>
          </ResponsiveContainer>
        }
      </div>
    </div>
  );
};

export default VisualCustomLineChartForChartCard;
