import React from "react";
import SettingsHeader from "../department_role_station_settings/SettingsHeader";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LaborModelTestModal from "./LaborModelTestModal";
import { PurpleSwitch } from "../../../helpers/helpers";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonGroup from "@mui/material/ButtonGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createDayArray } from "../../../helpers/helpers";
import {
  generateTimeOptionsDF,
  generateBufferOptionsDF,
} from "../../../helpers/helpers";
import ButtonDelete from "../requests/shared/helpers/ButtonDelete";
import { get_t_str } from "../../../helpers/helpers";
import PublishLaborModelWarningModal from "./PublishLaborModelWarningModal";

function LaborModelInnerDisplay(props) {

  const [openPublishWarningModal, setOpenPublishWarningModal] = React.useState(false);
  const handleOpenPublishWarningModal = () => setOpenPublishWarningModal(true);
  const handleClosePublishWarningModal = () => setOpenPublishWarningModal(false);

  const default_data = {
    constant_time_minutes: null,
    department: {
      company: null,
      role_type: "",
    },
    labor_driver: {
      labor_driver_attribute: null,
      labor_driver_sales: null,
      name: "",
      uuid: null,
    },
    labor_program_flag_company: {
      default: null,
      name: "",
      uuid: null,
    },
    name: "",
    role_ids: [],
    scalable_time_minutes: null,
    time_window: {
      dynamic_end_time: null,
      dynamic_start_time: null,
      fixed_end_offset_qhnum: 0,
      fixed_start_offset_qhnum: 0,
      name: "",
      store_time_window: [],
      uuid: null,
    },
    type: "",
    uuid: null,
    week_days: [true, true, true, true, true, true, true],
  };

  const handleChangeLayer1 = (event) => {
    const { name, value } = event.target;
    setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeLaborDriver = (event) => {
    const selectedLaborDriver = props.laborDrivers?.find(
      (driver) => driver.name === event.target.value
    );
    setModalData((prevData) => ({
      ...prevData,
      labor_driver: {
        ...selectedLaborDriver,
      },
    }));
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartment = props.roleTypes?.find(
      (department) => department.role_type === event.target.value
    );
    setModalData((prevData) => ({
      ...prevData,
      department: {
        ...selectedDepartment,
      },
    }));
  };

  const handleTimeWindowChange = (event) => {
    const selectedTimeWindow = props.companyTimeWindows?.find(
      (timeWindow) => timeWindow.name === event.target.value
    );
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        ...selectedTimeWindow,
      },
    }));
  };

  const handleTimeWindowStartStopChange = (event) => {
    const { name, value } = event.target;
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        ...prevData.time_window,
        [name]: value,
      },
    }));
  };

  const handleOpenCreateTimeWindow = () => {
    setShowCreateTimeWindow(true);
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        dynamic_end_time: "",
        dynamic_start_time: "",
        fixed_end_offset_qhnum: 0,
        fixed_start_offset_qhnum: 0,
        name: "",
        store_time_window: [],
        uuid: null,
      },
    }));
  };

  const handleCreateTimeWindow = (event) => {
    const { name, value } = event.target;
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        ...prevData.time_window,
        [name]: value,
      },
    }));
  };

  const handleCloseCreateTimeWindow = () => {
    setShowCreateTimeWindow(false);
    setSelectedStartTime("");
    setSelectedEndTime("");
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        dynamic_end_time: null,
        dynamic_start_time: null,
        fixed_end_offset_qhnum: 0,
        fixed_start_offset_qhnum: 0,
        name: "",
        store_time_window: [],
        uuid: null,
      },
    }));
  };

  const [laborStandards, setLaborStandards] = React.useState(
    props.laborStandards
  );

  const [laborStandardsSandbox, setLaborStandardsSandbox] = React.useState(
    props.laborStandardsSandbox
  );

  console.log("LABOR STANDARDS SANDBOX", laborStandardsSandbox)

  React.useEffect(() => {
    setLaborStandards(props.laborStandards);
    setLaborStandardsSandbox(props.laborStandardsSandbox)
  }, [props.laborStandards, props.laborStandardsSandbox]);

  // Environment Dropdown

  const [environmentType, setEnvironmentType] = React.useState("published")

  const [isEnvironmentDropdownOpen, setIsEnvironmentDropdownOpen] = React.useState(false);
  const dropdownEnvironmentRef = React.useRef(null);

  const handleEnvironmentDropdownClick = () => {
    setIsEnvironmentDropdownOpen(!isEnvironmentDropdownOpen);
  };

  // Labor Standard Dropdown
  const [isLaborStandardDropdownOpen, setIsLaborStandardDropdownOpen] = React.useState(false);
  const dropdownLaborStandardRef = React.useRef(null);

  const handleLaborStandardButtonClick = () => {
    setIsLaborStandardDropdownOpen(!isLaborStandardDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownEnvironmentRef.current &&
      !dropdownEnvironmentRef.current.contains(event.target) &&
      dropdownLaborStandardRef.current &&
      !dropdownLaborStandardRef.current.contains(event.target)
    ) {
      setIsEnvironmentDropdownOpen(false);
      setIsLaborStandardDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [drawerType, setDrawerType] = React.useState("");

  const [openLaborStandardDrawer, setOpenLaborStandardDrawer] =
    React.useState(false);

  const handleOpenLaborStandardDrawer = (type) => {
    setOpenLaborStandardDrawer(true);
    setDrawerType(type);
    setModalData((prevData) => {
      const newData = {
        ...prevData,
        type: type,
      };
      setScaleWithDriverChecked(newData.labor_driver?.name?.length > 0);
      setLocationSelectionChecked(newData.labor_program_flag_company?.uuid);
      return newData;
    });
  };

  const handleCloseLaborStandardDrawer = () => {
    setOpenLaborStandardDrawer(false);
    handleCloseCreateTimeWindow();
    setScaleWithDriverChecked(false);
    setRoleSelectionChecked(false);
    setDaySelectionChecked(false);
    setLocationSelectionChecked(false);
  };
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  const [openTestModal, setOpenTestModal] = React.useState(false);
  const handleOpenTestModal = () => setOpenTestModal(true);
  const handleCloseTestModal = () => setOpenTestModal(false);

  const [scaleWithDriverChecked, setScaleWithDriverChecked] = React.useState();

  console.log(modalData.labor_driver?.name?.length > 0);
  console.log(scaleWithDriverChecked);

  React.useEffect(() => {
    // Update scaleWithDriverChecked based on modalData
    if (modalData.labor_driver?.name?.length > 0) {
      setScaleWithDriverChecked(true);
    }
  }, [modalData]);

  const handleScaleWithDriverChecked = (event) => {
    const isChecked = event.target.checked;
    setScaleWithDriverChecked(isChecked);
    if (!isChecked) {
      // Clear relevant data when unchecked
      setModalData((prevData) => ({
        ...prevData,
        scalable_time_minutes: null,
        labor_driver: {
          ...prevData.labor_driver,
          labor_driver_attribute: null,
          labor_driver_sales: null,
          name: "",
          uuid: null,
        },
      }));
    }
  };

  const [showCreateTimeWindow, setShowCreateTimeWindow] = React.useState(false);

  const [selectedStartTime, setSelectedStartTime] = React.useState("");
  const [selectedEndTime, setSelectedEndTime] = React.useState("");

  const setSelectedStartTimeAndUpdateDynamicTime = (value) => {
    // Update selected start time
    setSelectedStartTime(value);

    // Determine the dynamic_start_time based on the value
    let dynamicStartTime;
    if (value === "start") {
      dynamicStartTime = "start";
    } else if (value === "close") {
      dynamicStartTime = "close";
    } else {
      dynamicStartTime = "not_dynamic";
    }

    // Update dynamic_start_time in state
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        ...prevData.time_window,
        dynamic_start_time: dynamicStartTime,
      },
    }));
  };

  const setSelectedEndTimeAndUpdateDynamicTime = (value) => {
    // Update selected start time
    setSelectedEndTime(value);

    // Determine the dynamic_start_time based on the value
    let dynamicEndTime;
    if (value === "start") {
      dynamicEndTime = "start";
    } else if (value === "close") {
      dynamicEndTime = "close";
    } else {
      dynamicEndTime = "not_dynamic";
    }

    // Update dynamic_start_time in state
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        ...prevData.time_window,
        dynamic_end_time: dynamicEndTime,
      },
    }));
  };

  console.log("modalData", modalData);

  const [roleSelectionChecked, setRoleSelectionChecked] = React.useState(false);

  const handleRoleSelectionChecked = (event) => {
    const isChecked = event.target.checked;
    setRoleSelectionChecked(isChecked);
  };

  // const [selectedRoles, setSelectedRoles] = React.useState([]);

  // console.log("SELECTED ROLES", selectedRoles)

  const handleRoleSelectionChange = (event) => {
    const {
      target: { value },
    } = event;
    // setSelectedRoles(value);
    setModalData((prevData) => ({
      ...prevData,
      role_ids: value,
    }));
  };

  const filteredRoleNames = props.roleNames?.filter(
    (role) => role.role_type === modalData.department?.role_type
  );

  console.log("ROLENAMES", props.roleNames);

  const [daySelectionChecked, setDaySelectionChecked] = React.useState(false);

  React.useEffect(() => {
    setDaySelectionChecked(modalData.week_days?.some((day) => day === false));
    setRoleSelectionChecked(modalData.role_ids?.length > 0);
  }, [modalData]);

  const handleDaySelectionChecked = (event) => {
    const isChecked = event.target.checked;
    setDaySelectionChecked(isChecked);
    if (!isChecked) {
      setModalData((prevData) => ({
        ...prevData,
        week_days: [true, true, true, true, true, true, true],
      }));
    }
  };

  const [locationSelectionChecked, setLocationSelectionChecked] = React.useState(modalData?.labor_program_flag_company?.uuid !== null);

  console.log("LOCATION SELECTION CHECKED", locationSelectionChecked)
  console.log("TEST TEST", modalData?.labor_program_flag_company?.uuid !== null)

  const handleLocationSelectionChecked = (event) => {
    const isChecked = event.target.checked;
    setLocationSelectionChecked(isChecked);
    if (!isChecked) {
      setModalData((prevData) => ({
        ...prevData,
        labor_program_flag_company: {
          default: null,
          name: "",
          uuid: null,
        },
      }));
    }
  };

  const handleFlagChange = (event) => {
    const selectedFlag = props.laborProgramFlags?.find(
      (flag) => flag.name === event.target.value
    );
    setModalData((prevData) => ({
      ...prevData,
      labor_program_flag_company: {
        ...selectedFlag,
      },
    }));
  };

  console.log("FLAGS", props.laborProgramFlags);

  const day_abbrevs = createDayArray(props.businessWeekStartDayIndex);

  console.log(day_abbrevs);

  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#0971f1",
        darker: "#053e85",
      },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
    },
  });

  function handleAppliedToChange(day_i) {
    let appliedToCopy = [...modalData.week_days];
    appliedToCopy[day_i] = !modalData.week_days[day_i];
    console.log("ABC", appliedToCopy);
    // setAppliedTo(appliedToCopy);
    setModalData((prevData) => ({
      ...prevData,
      week_days: appliedToCopy,
    }));
  }

  function appliedToButtonGroup() {
    return (
      <div className="w-full mt-2 bg-slate-50 rounded-lg flex">
        <ThemeProvider theme={theme}>
          <ButtonGroup
            style={{
              flex: 1,
              border: "1px solid #f1f5f9",
              borderRadius: "7px",
            }}
          >
            {modalData.week_days?.map((d, i) => (
              <Button
                fullWidth
                color="neutral"
                size="small"
                style={{
                  border: d ? "1px solid #6d28d9" : "1px solid #f8fafc",
                  borderRadius: "7px",
                  backgroundColor: d ? "#ede9fe" : "transparent",
                  padding: "6px",
                  margin: "0 0.5px",
                }}
                key={i}
                onClick={() => handleAppliedToChange(i)}
              >
                {day_abbrevs[i]}
              </Button>
            ))}
          </ButtonGroup>
        </ThemeProvider>
      </div>
    );
  }

  function getSelectedDays(daysArray, selectionArray) {
    const selectedDays = [];
    for (let i = 0; i < daysArray.length; i++) {
      if (selectionArray[i]) {
        selectedDays.push(daysArray[i]);
      }
    }
    return selectedDays;
  }

  // labor_standard_uuid,
  //   type,
  //   name,
  //   department_id,
  //   company_time_window_uuid,
  //   company_time_window_name,
  //   dynamic_start_time,
  //   dynamic_end_time,
  //   fixed_start_offset_qhnum,
  //   fixed_end_offset_qhnum,
  //   week_days,
  //   role_ids,
  //   constant_time_minutes,
  //   scalable_time_minutes,
  //   labor_driver_uuid

  function handleSubmit() {
    props.post_labor_standard(
      modalData.uuid,
      modalData.type,
      modalData.name,
      modalData.department?.id,
      modalData.time_window?.uuid,
      modalData.time_window?.name,
      modalData.time_window?.dynamic_start_time,
      modalData.time_window?.dynamic_end_time,
      modalData.time_window?.fixed_start_offset_qhnum,
      modalData.time_window?.fixed_end_offset_qhnum,
      JSON.stringify(modalData.week_days),
      JSON.stringify(modalData.role_ids),
      parseFloat(modalData.constant_time_minutes),
      parseFloat(modalData.scalable_time_minutes),
      modalData.labor_driver?.uuid,
      modalData.labor_program_flag_company?.uuid
    );
    handleCloseLaborStandardDrawer();
  }

  function DeleteTimeWindow() {
    props.delete_time_window(modalData.time_window?.uuid);
    setModalData((prevData) => ({
      ...prevData,
      time_window: {
        dynamic_end_time: null,
        dynamic_start_time: null,
        fixed_end_offset_qhnum: 0,
        fixed_start_offset_qhnum: 0,
        name: "",
        store_time_window: [],
        uuid: null,
      },
    }));
  }

  // console.log("LABOR DRIVERS", props.laborDrivers);

  // console.log("LABOR STANDARDS", laborStandards);

  const laborStandardsToDisplay = environmentType === "sandbox" ? laborStandardsSandbox : laborStandards;

  return (
    <>
      <div>
        {/* <SettingsHeader title="Labor Model" /> */}
        <div className="w-full p-4 flex justify-between">
          <div className="flex items-center space-x-1">
            <div className="relative" ref={dropdownEnvironmentRef}>
              <button
                className="text-violet-700 hover:text-violet-800 hover:bg-violet-100 px-3 py-1.5 rounded-lg flex items-center space-x-2.5"
                onClick={handleEnvironmentDropdownClick}
              >
                <div>
                  <CheckCircleOutlineIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                </div>
                <p className="text-sm">{environmentType === "sandbox" ? "Sandbox" : environmentType === "published" ? "Published" : ""}</p>
                <div>
                  <ExpandMoreIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                </div>
                {isEnvironmentDropdownOpen && (
                  <div className="absolute top-10 left-0 w-44 bg-white border border-slate-200 shadow-lg z-30 rounded-lg overflow-hidden">
                    <div
                      className={`w-full py-3 px-4 hover:bg-slate-50 text-sm flex items-center space-x-3 border-b border-slate-200 ${environmentType === "sandbox" ? "text-slate-900" : "text-slate-500"} cursor-pointer`}
                      onClick={() => {
                        setEnvironmentType("sandbox")
                      }}
                    >
                      <div className="w-4 h-4 flex justify-center items-center">
                        {environmentType === "sandbox" && <CheckIcon style={{ fontSize: "18px", marginBottom: "2px" }} />}
                      </div>
                      <p>Sandbox</p>
                    </div>
                    <div
                      className={`w-full py-3 px-4 hover:bg-slate-50 text-sm flex items-center space-x-3 ${environmentType === "published" ? "text-slate-900" : "text-slate-500"} cursor-pointer`}
                      onClick={() => {
                        setEnvironmentType("published")
                      }}
                    >
                      <div className="w-4 h-4 flex justify-center items-center">
                        {environmentType === "published" && <CheckIcon style={{ fontSize: "18px", marginBottom: "2px" }} />}
                      </div>
                      <p>Published</p>
                    </div>
                  </div>
                )}
              </button>
            </div>
            <Tooltip title={environmentType === "published" ? '"Published" labor standards determine your labor budgets. To make any changes to the labor standards, switch to the "Sandbox" in the dropdown. You can press the orange "Evaluate" button to see the difference between the "Published" labor model and "Sandbox" labor model.' : 'The "Sandbox" is where you can test different labor standard configurations. To see how the "Sandbox" labor model compares to the current "Published" labor model, press the orange "Evaluate" button. All yellow rows are labor standards that have been changed from the "Published" version.'}>
              <div className="text-slate-500">
                <HelpOutlineIcon style={{ fontSize: "22px", marginBottom: "1px" }} />
              </div>
            </Tooltip>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative" ref={dropdownLaborStandardRef}>
              {environmentType === "sandbox" ?
                <Tooltip title={!isLaborStandardDropdownOpen ? "Add labor standard" : ""}>
                  <div
                    className="bg-violet-700 hover:bg-violet-800 text-white h-8 w-8 rounded-full flex items-center justify-center cursor-pointer"
                    onClick={handleLaborStandardButtonClick}
                  >
                    <AddIcon style={{ fontSize: "18px", marginBottom: "1px" }} />
                  </div>
                </Tooltip> :
                <Tooltip title={"Switch to sandbox mode to add labor standards"}>
                  <div
                    className="bg-slate-200 text-slate-400 h-8 w-8 rounded-full flex items-center justify-center"
                  >
                    <AddIcon style={{ fontSize: "18px", marginBottom: "1px" }} />
                  </div>
                </Tooltip>
              }
              {isLaborStandardDropdownOpen && (
                <div className="absolute top-10 right-0 w-44 bg-white border border-slate-200 shadow-lg z-30 rounded-lg overflow-hidden">
                  <div
                    className="w-full py-3 px-4 hover:bg-slate-50 text-sm flex items-center space-x-2 border-b border-slate-200 text-slate-500 cursor-pointer"
                    onClick={() => {
                      setModalData(default_data);
                      setModalEdit(false);
                      handleOpenLaborStandardDrawer("task");
                    }}
                  >
                    <div className="border border-orange-300 bg-orange-200 w-3 h-3 rounded-full"></div>
                    <p>Create new task</p>
                  </div>
                  <div
                    className="w-full py-3 px-4 hover:bg-slate-50 text-sm flex items-center space-x-2 text-slate-500 cursor-pointer"
                    onClick={() => {
                      setModalData(default_data);
                      setModalEdit(false);
                      handleOpenLaborStandardDrawer("routine");
                    }}
                  >
                    <div className="border border-blue-300 bg-blue-200 w-3 h-3 rounded-full"></div>
                    <p>Create new routine</p>
                  </div>
                </div>
              )}
            </div>
            <Tooltip title={`See how the labor model affects different labor metrics`}>
              <div
                className="px-3 h-8 rounded-full flex items-center space-x-0.5 bg-orange-600 hover:bg-orange-700 text-white cursor-pointer"
                onClick={handleOpenTestModal}
              >
                <div>
                  <ScienceOutlinedIcon
                    style={{ fontSize: "18px", marginBottom: "2px" }}
                  />
                </div>
                <p className="pr-1 text-sm">Evaluate</p>
              </div>
            </Tooltip>
            {environmentType === "sandbox" ?
              <Tooltip title={"Publish labor model"}>
                <div
                  className="px-3 h-8 rounded-full flex items-center space-x-1 bg-emerald-600 hover:bg-emerald-700 text-white cursor-pointer"
                  onClick={() => handleOpenPublishWarningModal()}
                >
                  <div>
                    <RocketLaunchOutlinedIcon
                      style={{ fontSize: "18px", marginBottom: "2px" }}
                    />
                  </div>
                  <p className="pr-1 text-sm">Publish</p>
                </div>
              </Tooltip> :
              <Tooltip title={"Switch to sandbox mode to publish labor model"}>
                <div className="px-3 h-8 rounded-full flex items-center space-x-1 bg-slate-200 text-slate-400">
                  <div>
                    <RocketLaunchOutlinedIcon
                      style={{ fontSize: "18px", marginBottom: "2px" }}
                    />
                  </div>
                  <p className="pr-1 text-sm">Publish</p>
                </div>
              </Tooltip>
            }
          </div>
        </div>
        <div className="px-6 w-[70%]">
          {environmentType === "sandbox" &&
            <p className="text-slate-500 text-sm mt-3">To reset the the "Sandbox" back to to the current "Published" version, <span className="text-violet-700 hover:text-violet-800 cursor-pointer" onClick={() => props.revert_labor_standard()}>CLICK HERE</span></p>
          }
        </div>
        <div>
          {environmentType === "sandbox" &&
            <div className="px-6 mt-5">
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-amber-100"></div>
                <p className="text-sm text-slate-500">Labor standard has been changed from published version</p>
              </div>
              <div className="flex items-center space-x-2 mt-1.5">
                <div className="w-3 h-3 rounded-full bg-emerald-100"></div>
                <p className="text-sm text-slate-500">Labor standard is new from published version</p>
              </div>
            </div>
          }
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-[12%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="w-[18%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Labor Calculation
                      </th>
                      {/* <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Driver
                      </th> */}
                      <th
                        scope="col"
                        className="w-[16%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        When
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-6">
                        {/* <span className="sr-only">Edit</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {laborStandardsToDisplay?.map((laborStandard, index) => {
                      const selectedRoleNames = props.roleNames.filter((role) =>
                        laborStandard.role_ids.includes(role.id)
                      );
                      return (
                        <tr
                          key={index}
                          className={`${environmentType === "sandbox" ? laborStandard.is_new ? "bg-emerald-50 hover:bg-emerald-100 cursor-pointer" : laborStandard.has_changed ? "bg-amber-50 hover:bg-amber-100 cursor-pointer" : "hover:bg-slate-50 cursor-pointer" : "bg-slate-50"}`}
                          onClick={environmentType === "sandbox" ? () => {
                            setModalData(laborStandard);
                            setModalEdit(true);
                            handleOpenLaborStandardDrawer(laborStandard.type);
                          } : undefined}
                        >
                          <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                            <div className="w-fit">
                              <div
                                className={`rounded-md px-2.5 py-1 ${
                                  laborStandard.type === "task"
                                    ? "bg-orange-50 border border-orange-200"
                                    : "bg-blue-50 border border-blue-200"
                                } flex items-center justify-center`}
                              >
                                <p
                                  className={`${
                                    laborStandard.type === "task"
                                      ? "text-orange-600"
                                      : "text-blue-600"
                                  }`}
                                >
                                  {laborStandard.type === "task"
                                    ? "Task"
                                    : "Routine"}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-3 py-5 text-sm text-slate-900">
                            <div>
                              <p className="text-slate-900 text-sm">
                                {laborStandard.name}
                              </p>
                              {selectedRoleNames?.length > 0 ? (
                                <div className="mt-0.5">
                                  {selectedRoleNames.map((role, index) => (
                                    <p className="text-slate-500 text-xs">
                                      {role.role_name}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                <p className="text-slate-500 text-xs mt-0.5">
                                  {laborStandard.department?.role_type}
                                </p>
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            <div className="flex space-x-1">
                              {laborStandard.labor_program_flag_company && (
                                <div>
                                  <span className="text-slate-500">if has</span>{" "}
                                  {
                                    laborStandard.labor_program_flag_company
                                      .name
                                  }
                                  <span className="text-slate-500">,</span>
                                </div>
                              )}
                              {laborStandard.constant_time_minutes !== 0 && (
                                <div>
                                  <p className="text-slate-900 text-sm">
                                    {laborStandard.constant_time_minutes}{" "}
                                    <span className="text-slate-500">
                                      min +
                                    </span>
                                  </p>
                                </div>
                              )}
                              <div>
                                {laborStandard.labor_driver?.name ? (
                                  <p className="text-slate-500 text-sm">
                                    every{" "}
                                    <span className="text-slate-900">
                                      {laborStandard.labor_driver?.name}
                                    </span>{" "}
                                    <span className="text-slate-500">
                                      increases labor needed by
                                    </span>{" "}
                                    <span className="text-slate-900">
                                      {laborStandard.scalable_time_minutes}
                                    </span>{" "}
                                    min
                                  </p>
                                ) : (
                                  <p className="text-slate-500 text-sm">
                                    no labor driver
                                  </p>
                                )}
                              </div>
                            </div>
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            <div>
                              {laborStandard.labor_driver?.name ? (
                                <p className="text-slate-500 text-sm">
                                  Every{" "}
                                  <span className="text-slate-900">
                                    {laborStandard.labor_driver?.name}
                                  </span>{" "}
                                  <span className="text-slate-500">
                                    increases labor needed by
                                  </span>{" "}
                                  <span className="text-slate-900">
                                    {laborStandard.scalable_time_minutes}
                                  </span>{" "}
                                  min
                                </p>
                              ) : (
                                <p className="text-slate-500 text-sm">
                                  No labor driver
                                </p>
                              )}
                            </div>
                          </td> */}
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            <div>
                              <p className="text-slate-900 text-sm">
                                {laborStandard.time_window?.name}
                              </p>

                              {laborStandard.week_days?.includes(false) ? (
                                <div className="flex space-x-0.5 mt-0.5">
                                  {getSelectedDays(
                                    day_abbrevs,
                                    laborStandard.week_days
                                  ).map((day, index) => (
                                    <p className="text-slate-500 text-xs mt-0.5">
                                      {index <
                                      getSelectedDays(
                                        day_abbrevs,
                                        laborStandard.week_days
                                      )?.length -
                                        1
                                        ? `${day},`
                                        : `${day}`}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                <p className="text-slate-500 text-xs mt-0.5">
                                  Every day
                                </p>
                              )}
                            </div>
                          </td>
                          <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium w-5">
                            <div className="flex items-center justify-end w-fit h-full text-slate-500">
                              {environmentType === "sandbox" ?
                                <div className="w-8">
                                  <ChevronRightIcon />
                                </div> :
                                <div className="w-8"></div>
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="border-b border-slate-200"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={openLaborStandardDrawer}
        anchor="right"
        onClose={handleCloseLaborStandardDrawer}
      >
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={
                drawerType === "task"
                  ? modalEdit
                    ? "Edit Task"
                    : "Add Task"
                  : modalEdit
                  ? "Edit Routine"
                  : "Add Routine"
              }
              onClose={handleCloseLaborStandardDrawer}
            />
            <div className="w-full px-6">
              <div className="pb-6 border-b border-slate-200">
                <div className="text-slate-500">
                  {drawerType === "task" ? (
                    <p>This task will be applied once per day</p>
                  ) : (
                    <p>This will be applied continuously throughout the day</p>
                  )}
                </div>
                <div className="mt-5">
                  <p className="text-sm text-slate-500">Name</p>
                  <div className="mt-1">
                    <TextField
                      placeholder={
                        drawerType === "task" ? "Task name" : "Routine name"
                      }
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="name"
                      value={modalData.name}
                      onChange={handleChangeLayer1}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <p className="text-sm text-slate-500">Assign to department</p>
                  <div className="mt-1">
                    <Select
                      fullWidth
                      value={modalData.department?.role_type}
                      name="role_type"
                      onChange={handleDepartmentChange}
                      size="small"
                      sx={{
                        borderRadius: "8px",
                        paddingY: "1px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                        height: "45px",
                        backgroundColor: "white",
                      }}
                    >
                      {props.roleTypes?.map((department, index) => (
                        <MenuItem key={index} value={department.role_type}>
                          {department.role_type}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                {modalData.department?.role_type && (
                  <div className="mt-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm text-slate-900">Role selection</p>
                        <p className="text-xs text-slate-500 mt-1">{`Is this ${drawerType} assigned to specific roles?`}</p>
                      </div>
                      <div>
                        <PurpleSwitch
                          checked={roleSelectionChecked}
                          onChange={handleRoleSelectionChecked}
                        />
                      </div>
                    </div>
                    {roleSelectionChecked && (
                      <div className="mt-4">
                        <p className="text-sm text-slate-500">
                          Assign to roles
                        </p>
                        <div className="mt-1">
                          <Select
                            fullWidth
                            multiple
                            value={modalData.role_ids}
                            name="role_names"
                            onChange={handleRoleSelectionChange}
                            size="small"
                            sx={{
                              borderRadius: "8px",
                              paddingY: "1px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                              height: "45px",
                              backgroundColor: "white",
                            }}
                            renderValue={(selected) => {
                              const selectedRoleNames = props.roleNames
                                .filter((role) => selected.includes(role.id))
                                .map((role) => role.role_name);

                              if (selectedRoleNames.length <= 4) {
                                return selectedRoleNames.join(", ");
                              } else {
                                return `${selectedRoleNames[0]}, ${selectedRoleNames[1]}, ${selectedRoleNames[2]}, ${selectedRoleNames[3]} ...`;
                              }
                            }}
                          >
                            {filteredRoleNames.map((role, index) => (
                              <MenuItem key={index} value={role.id}>
                                <Checkbox
                                  checked={
                                    modalData.role_ids?.indexOf(role.id) > -1
                                  }
                                  sx={{
                                    color: "#6d28d9",
                                    "&.Mui-checked": {
                                      color: "#6d28d9",
                                    },
                                  }}
                                />
                                <ListItemText primary={role.role_name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="mt-6 pb-6 border-b border-slate-200">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-slate-900">Location selection</p>
                    <p className="text-xs text-slate-500 mt-1">
                      Is this {drawerType} only performed in certain locations?
                    </p>
                  </div>
                  <div>
                    <PurpleSwitch
                      checked={locationSelectionChecked}
                      onChange={handleLocationSelectionChecked}
                    />
                  </div>
                </div>
                {locationSelectionChecked && (
                  <div className="mt-4">
                    <p className="text-xs text-slate-500">Select flag</p>
                    <div className="mt-1">
                      <Select
                        fullWidth
                        value={modalData.labor_program_flag_company?.name}
                        name="program_flag"
                        onChange={handleFlagChange}
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          paddingY: "1px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                          height: "45px",
                          backgroundColor: "white",
                        }}
                      >
                        {props.laborProgramFlags?.map((flag, index) => (
                          <MenuItem key={index} value={flag.name}>
                            {flag.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-6 pb-6 border-b border-slate-200">
                <div className="flex justify-between">
                  <div>
                    <p className="text-slate-900">Fixed time</p>
                    <p className="text-slate-500 text-xs mt-1">
                      Total fixed minutes to complete this task
                    </p>
                  </div>
                  <div className="w-32">
                    <TextField
                      placeholder="60"
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="constant_time_minutes"
                      // type="number"
                      value={modalData.constant_time_minutes}
                      onChange={handleChangeLayer1}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "8px",
                          paddingY: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                        endAdornment: (
                          <InputAdornment position="end">min</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="w-full bg-slate-50 rounded-xl p-4">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-slate-900 text-sm">Variable time</p>
                        <p className="text-slate-500 text-xs mt-1">
                          This time scales with labor driver
                        </p>
                      </div>
                      <div>
                        <PurpleSwitch
                          checked={scaleWithDriverChecked}
                          onChange={handleScaleWithDriverChecked}
                        />
                      </div>
                    </div>
                    {scaleWithDriverChecked && (
                      <div className="mt-3">
                        <div className="flex space-x-2">
                          <div className="w-[65%]">
                            <div>
                              <p className="text-xs text-slate-500">Driver</p>
                              <div className="mt-1">
                                <Select
                                  fullWidth
                                  value={modalData.labor_driver?.name}
                                  name="name"
                                  onChange={handleChangeLaborDriver}
                                  size="small"
                                  sx={{
                                    borderRadius: "8px",
                                    paddingY: "1px",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6d28d9",
                                      },
                                    height: "38px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  {props.laborDrivers?.map(
                                    (laborDriver, index) => (
                                      <MenuItem
                                        key={index}
                                        value={laborDriver.name}
                                      >
                                        {laborDriver.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </div>
                            </div>
                          </div>
                          <div className="flex-1">
                            <div>
                              <p className="text-xs text-slate-500">
                                Time scaled
                              </p>
                              <div className="mt-1">
                                <TextField
                                  placeholder="60"
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name="scalable_time_minutes"
                                  value={modalData.scalable_time_minutes}
                                  onChange={handleChangeLayer1}
                                  InputProps={{
                                    sx: {
                                      backgroundColor: "white",
                                      borderRadius: "8px",
                                      paddingY: "5px",
                                      height: "38px",
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#6d28d9",
                                        },
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        min
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="w-[10%]">
                            <div className="w-full h-[16px]"></div>
                            <div
                              className="text-slate-500 hover:text-rose-800 h-[38px] flex items-center justify-center cursor-pointer"
                            >
                              <DeleteOutlineIcon />
                            </div>
                          </div> */}
                        </div>
                        {modalData.labor_driver?.name && (
                          <div className="mt-2.5">
                            <div className="w-full rounded-lg border border-emerald-200 text-emerald-800 bg-emerald-50 text-xs py-1.5 px-3">
                              <p className="text-xs text-emerald-800">
                                Every{" "}
                                <span className="font-bold">
                                  {modalData.labor_driver?.name}
                                </span>{" "}
                                increases labor needed by{" "}
                                <span className="font-bold">
                                  {modalData.scalable_time_minutes
                                    ? modalData.scalable_time_minutes
                                    : "x"}
                                </span>{" "}
                                minutes
                              </p>
                            </div>
                          </div>
                        )}
                        {/* <div className="mt-4">
                          <div className="w-full bg-violet-100 hover:bg-violet-200 text-violet-700 text-sm flex items-center justify-center space-x-1 py-2.5 rounded-lg cursor-pointer">
                            <p>Add another driver</p>
                          </div>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div>
                  <p className="text-slate-900 text-sm">
                    When the task is eligible to be done
                  </p>
                  <p className="text-slate-500 text-xs mt-1">
                    Select a time window or{" "}
                    <span
                      className="border-b border-violet-700 hover:border-violet-800 text-violet-700 hover:text-violet-800 cursor-pointer"
                      onClick={handleOpenCreateTimeWindow}
                    >
                      create one
                    </span>
                  </p>
                </div>
                {!showCreateTimeWindow ? (
                  <div>
                    <div className="mt-3">
                      <p className="text-xs text-slate-500">Time window</p>
                      <div className="mt-1">
                        <Select
                          fullWidth
                          value={modalData.time_window?.name}
                          name="name"
                          onChange={handleTimeWindowChange}
                          size="small"
                          sx={{
                            borderRadius: "8px",
                            paddingY: "1px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                            height: "38px",
                            backgroundColor: "white",
                          }}
                        >
                          {props.companyTimeWindows?.map((item, index) => (
                            <MenuItem key={index} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {modalData.time_window?.name && (
                      <div className="mt-3">
                        <div className="w-full bg-slate-50 rounded-xl p-4">
                          <div className="flex justify-between">
                            <p className="text-slate-900 text-sm font-semibold">
                              Selected time window
                            </p>
                            <div
                              className="text-slate-500 hover:text-rose-800 cursor-pointer"
                              onClick={() => DeleteTimeWindow()}
                            >
                              <Tooltip
                                title={`Delete "${modalData.time_window?.name}" time window option. Can only delete if not in use.`}
                              >
                                <DeleteOutlineIcon
                                  style={{ fontSize: "20px" }}
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <div className="mt-0.5">
                            <p className="text-slate-500 text-xs">Name</p>
                            <p className="text-slate-900 mt-0.5 text-sm">
                              {modalData.time_window?.name}
                            </p>
                          </div>
                          {modalData.time_window?.dynamic_start_time ===
                          "not_dynamic" ? (
                            <div className="w-full flex mt-2">
                              <div className="w-1/2">
                                <p className="text-slate-500 text-xs">
                                  Start time
                                </p>
                                <p className="text-slate-900 mt-0.5 text-sm">
                                  {get_t_str(
                                    modalData.time_window
                                      ?.fixed_start_offset_qhnum
                                  )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="w-full flex mt-2">
                              <div className="w-1/2">
                                <p className="text-slate-500 text-xs">
                                  Start time
                                </p>
                                <p className="text-slate-900 mt-0.5 text-sm">
                                  {modalData.time_window?.dynamic_start_time ===
                                  "start"
                                    ? "Open hours"
                                    : modalData.time_window
                                        ?.dynamic_start_time === "close"
                                    ? "Close hours"
                                    : ""}
                                </p>
                              </div>
                              <div className="w-1/2">
                                <p className="text-slate-500 text-xs">
                                  Start time buffer
                                </p>
                                <p className="text-slate-900 mt-0.5 text-sm">
                                  {
                                    modalData.time_window
                                      ?.fixed_start_offset_qhnum
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                          {modalData.time_window?.dynamic_end_time ===
                          "not_dynamic" ? (
                            <div className="w-full flex mt-2">
                              <div className="w-1/2">
                                <p className="text-slate-500 text-xs">
                                  End time
                                </p>
                                <p className="text-slate-900 mt-0.5 text-sm">
                                  {get_t_str(
                                    modalData.time_window
                                      ?.fixed_end_offset_qhnum
                                  )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="w-full flex mt-2">
                              <div className="w-1/2">
                                <p className="text-slate-500 text-xs">
                                  End time
                                </p>
                                <p className="text-slate-900 mt-0.5 text-sm">
                                  {modalData.time_window?.dynamic_end_time ===
                                  "start"
                                    ? "Open hours"
                                    : modalData.time_window
                                        ?.dynamic_end_time === "close"
                                    ? "Close hours"
                                    : ""}
                                </p>
                              </div>
                              <div className="w-1/2">
                                <p className="text-slate-500 text-xs">
                                  End time buffer
                                </p>
                                <p className="text-slate-900 mt-0.5 text-sm">
                                  {
                                    modalData.time_window
                                      ?.fixed_end_offset_qhnum
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                          <div className="mt-2.5">
                            <div className="w-full rounded-lg border border-emerald-200 text-emerald-800 bg-emerald-50 text-xs py-1.5 px-3">
                              <p>
                                Starts at{" "}
                                {modalData.time_window?.dynamic_start_time ===
                                "not_dynamic" ? (
                                  <span className="font-bold">
                                    {get_t_str(
                                      modalData.time_window
                                        ?.fixed_start_offset_qhnum
                                    )}
                                  </span>
                                ) : (
                                  <span className="font-bold">
                                    {modalData.time_window
                                      ?.fixed_start_offset_qhnum !== 0
                                      ? `${Math.abs(
                                          modalData.time_window
                                            ?.fixed_start_offset_qhnum
                                        )} hours ${
                                          modalData.time_window
                                            ?.fixed_start_offset_qhnum > 0
                                            ? "after"
                                            : "before"
                                        }`
                                      : ""}{" "}
                                    {modalData.time_window
                                      ?.dynamic_start_time === "start"
                                      ? "Open hours"
                                      : "Close hours"}
                                  </span>
                                )}{" "}
                                and finishes at{" "}
                                {modalData.time_window?.dynamic_end_time ===
                                "not_dynamic" ? (
                                  <span className="font-bold">
                                    {get_t_str(
                                      modalData.time_window
                                        ?.fixed_end_offset_qhnum
                                    )}
                                  </span>
                                ) : (
                                  <span className="font-bold">
                                    {modalData.time_window
                                      ?.fixed_end_offset_qhnum !== 0
                                      ? `${Math.abs(
                                          modalData.time_window
                                            ?.fixed_end_offset_qhnum
                                        )} hours ${
                                          modalData.time_window
                                            ?.fixed_end_offset_qhnum > 0
                                            ? "after"
                                            : "before"
                                        }`
                                      : ""}{" "}
                                    {modalData.time_window?.dynamic_end_time ===
                                    "start"
                                      ? "Open hours"
                                      : "Close hours"}
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="mt-4">
                    <div className="w-full bg-slate-50 rounded-xl p-4">
                      <div className="flex justify-between">
                        <div>
                          <p className="text-slate-900 text-sm font-semibold">
                            Create new time window
                          </p>
                          <p className="text-slate-500 text-xs mt-1">
                            You can use your standard open and close hours or
                            specifiy a custom time
                          </p>
                        </div>
                        <div
                          className="text-slate-500 hover:text-rose-800 cursor-pointer ml-4 mt-4"
                          onClick={handleCloseCreateTimeWindow}
                        >
                          <Tooltip title="Cancel create new time window">
                            <DeleteOutlineIcon />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="mt-4 pb-4 border-b border-slate-200">
                        <p className="text-xs text-slate-500">Name</p>
                        <div className="mt-1">
                          <TextField
                            placeholder="Time window name"
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="name"
                            value={modalData.time_window?.name}
                            onChange={handleCreateTimeWindow}
                            InputProps={{
                              sx: {
                                backgroundColor: "white",
                                borderRadius: "8px",
                                padding: "5px",
                                height: "38px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6d28d9",
                                  },
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-3 pb-4 border-b border-slate-200">
                        <p className="text-xs text-slate-500">Start time</p>
                        <div className="bg-white border border-[#bdbdbd] rounded-xl overflow-hidden mt-1">
                          <div
                            className="border-b border-[#bdbdbd] hover:bg-violet-50 px-4 h-10 flex flex-row items-center justify-between cursor-pointer"
                            onClick={() =>
                              setSelectedStartTimeAndUpdateDynamicTime("start")
                            }
                          >
                            <p
                              className={`${
                                selectedStartTime === "start"
                                  ? "text-violet-700"
                                  : "text-slate-900"
                              } text-sm`}
                            >
                              Open hours
                            </p>
                            {selectedStartTime === "start" && (
                              <div className="text-violet-700">
                                <CheckIcon color="#6d28d9" />
                              </div>
                            )}
                          </div>
                          <div
                            className="border-b border-[#bdbdbd] hover:bg-violet-50 px-4 h-10 flex flex-row items-center justify-between cursor-pointer"
                            onClick={() =>
                              setSelectedStartTimeAndUpdateDynamicTime("close")
                            }
                          >
                            <p
                              className={`${
                                selectedStartTime === "close"
                                  ? "text-violet-700"
                                  : "text-slate-900"
                              } text-sm`}
                            >
                              Close hours
                            </p>
                            {selectedStartTime === "close" && (
                              <div className="text-violet-700">
                                <CheckIcon color="#6d28d9" />
                              </div>
                            )}
                          </div>
                          <div
                            className="px-4 h-10 hover:bg-violet-50 flex flex-row items-center justify-between cursor-pointer"
                            onClick={() =>
                              setSelectedStartTimeAndUpdateDynamicTime(
                                "not_dynamic"
                              )
                            }
                          >
                            <p
                              className={`${
                                selectedStartTime === "not_dynamic"
                                  ? "text-violet-700"
                                  : "text-slate-900"
                              } text-sm`}
                            >
                              Custom
                            </p>
                            {selectedStartTime === "not_dynamic" && (
                              <div className="text-violet-700">
                                <CheckIcon color="#6d28d9" />
                              </div>
                            )}
                          </div>
                        </div>
                        {selectedStartTime === "start" ||
                        selectedStartTime === "close" ? (
                          <div className="mt-4">
                            <p className="text-xs text-slate-500">Buffer</p>
                            <div className="mt-1">
                              <Select
                                fullWidth
                                value={
                                  modalData.time_window.fixed_start_offset_qhnum
                                }
                                name="fixed_start_offset_qhnum"
                                onChange={handleTimeWindowStartStopChange}
                                size="small"
                                sx={{
                                  borderRadius: "8px",
                                  paddingY: "1px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                  height: "38px",
                                  backgroundColor: "white",
                                }}
                              >
                                {generateBufferOptionsDF().map((v) => (
                                  <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        ) : selectedStartTime === "not_dynamic" ? (
                          <div className="mt-4">
                            <p className="text-xs text-slate-500">Time</p>
                            <div className="mt-1">
                              <Select
                                fullWidth
                                value={
                                  modalData.time_window.fixed_start_offset_qhnum
                                }
                                name="fixed_start_offset_qhnum"
                                onChange={handleTimeWindowStartStopChange}
                                size="small"
                                sx={{
                                  borderRadius: "8px",
                                  paddingY: "1px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                  height: "38px",
                                  backgroundColor: "white",
                                }}
                              >
                                {generateTimeOptionsDF(0).map((v) => (
                                  <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="mt-4">
                        <p className="text-xs text-slate-500">End time</p>
                        <div className="bg-white border border-[#bdbdbd] rounded-xl overflow-hidden mt-1">
                          <div
                            className="border-b border-[#bdbdbd] hover:bg-violet-50 px-4 h-10 flex flex-row items-center justify-between cursor-pointer"
                            onClick={() =>
                              setSelectedEndTimeAndUpdateDynamicTime("start")
                            }
                          >
                            <p
                              className={`${
                                selectedEndTime === "start"
                                  ? "text-violet-700"
                                  : "text-slate-900"
                              } text-sm`}
                            >
                              Open hours
                            </p>
                            {selectedEndTime === "start" && (
                              <div className="text-violet-700">
                                <CheckIcon color="#6d28d9" />
                              </div>
                            )}
                          </div>
                          <div
                            className="border-b border-[#bdbdbd] hover:bg-violet-50 px-4 h-10 flex flex-row items-center justify-between cursor-pointer"
                            onClick={() =>
                              setSelectedEndTimeAndUpdateDynamicTime("close")
                            }
                          >
                            <p
                              className={`${
                                selectedEndTime === "close"
                                  ? "text-violet-700"
                                  : "text-slate-900"
                              } text-sm`}
                            >
                              Close hours
                            </p>
                            {selectedEndTime === "close" && (
                              <div className="text-violet-700">
                                <CheckIcon color="#6d28d9" />
                              </div>
                            )}
                          </div>
                          <div
                            className="px-4 h-10 hover:bg-violet-50 flex flex-row items-center justify-between cursor-pointer"
                            onClick={() =>
                              setSelectedEndTimeAndUpdateDynamicTime(
                                "not_dynamic"
                              )
                            }
                          >
                            <p
                              className={`${
                                selectedEndTime === "not_dynamic"
                                  ? "text-violet-700"
                                  : "text-slate-900"
                              } text-sm`}
                            >
                              Custom
                            </p>
                            {selectedEndTime === "not_dynamic" && (
                              <div className="text-violet-700">
                                <CheckIcon color="#6d28d9" />
                              </div>
                            )}
                          </div>
                        </div>
                        {selectedEndTime === "start" ||
                        selectedEndTime === "close" ? (
                          <div className="mt-4">
                            <p className="text-xs text-slate-500">Buffer</p>
                            <div className="mt-1">
                              <Select
                                fullWidth
                                value={
                                  modalData.time_window.fixed_end_offset_qhnum
                                }
                                name="fixed_end_offset_qhnum"
                                onChange={handleTimeWindowStartStopChange}
                                size="small"
                                sx={{
                                  borderRadius: "8px",
                                  paddingY: "1px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                  height: "38px",
                                  backgroundColor: "white",
                                }}
                              >
                                {generateBufferOptionsDF().map((v) => (
                                  <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        ) : selectedEndTime === "not_dynamic" ? (
                          <div className="mt-4">
                            <p className="text-xs text-slate-500">Time</p>
                            <div className="mt-1">
                              <Select
                                fullWidth
                                value={
                                  modalData.time_window.fixed_end_offset_qhnum
                                }
                                name="fixed_end_offset_qhnum"
                                onChange={handleTimeWindowStartStopChange}
                                size="small"
                                sx={{
                                  borderRadius: "8px",
                                  paddingY: "1px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                  height: "38px",
                                  backgroundColor: "white",
                                }}
                              >
                                {generateTimeOptionsDF(0).map((v) => (
                                  <MenuItem key={v.value} value={v.value}>
                                    {v.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      {modalData.time_window?.dynamic_start_time &&
                        modalData.time_window?.dynamic_end_time && (
                          <div className="mt-3">
                            <div className="w-full rounded-lg border border-emerald-200 text-emerald-800 bg-emerald-50 text-xs py-1.5 px-3">
                              <p>
                                Starts at{" "}
                                {modalData.time_window?.dynamic_start_time ===
                                "not_dynamic" ? (
                                  <span className="font-bold">
                                    {get_t_str(
                                      modalData.time_window
                                        ?.fixed_start_offset_qhnum
                                    )}
                                  </span>
                                ) : (
                                  <span className="font-bold">
                                    {modalData.time_window
                                      ?.fixed_start_offset_qhnum !== 0
                                      ? `${Math.abs(
                                          modalData.time_window
                                            ?.fixed_start_offset_qhnum
                                        )} hours ${
                                          modalData.time_window
                                            ?.fixed_start_offset_qhnum > 0
                                            ? "after"
                                            : "before"
                                        }`
                                      : ""}{" "}
                                    {modalData.time_window
                                      ?.dynamic_start_time === "start"
                                      ? "Open hours"
                                      : "Close hours"}
                                  </span>
                                )}{" "}
                                and finishes at{" "}
                                {modalData.time_window?.dynamic_end_time ===
                                "not_dynamic" ? (
                                  <span className="font-bold">
                                    {get_t_str(
                                      modalData.time_window
                                        ?.fixed_end_offset_qhnum
                                    )}
                                  </span>
                                ) : (
                                  <span className="font-bold">
                                    {modalData.time_window
                                      ?.fixed_end_offset_qhnum !== 0
                                      ? `${Math.abs(
                                          modalData.time_window
                                            ?.fixed_end_offset_qhnum
                                        )} hours ${
                                          modalData.time_window
                                            ?.fixed_end_offset_qhnum > 0
                                            ? "after"
                                            : "before"
                                        }`
                                      : ""}{" "}
                                    {modalData.time_window?.dynamic_end_time ===
                                    "start"
                                      ? "Open hours"
                                      : "Close hours"}
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-6 border-t border-slate-200">
                <div className="mt-6 pb-10">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-slate-900">Day selection</p>
                      <p className="text-xs text-slate-500 mt-1">
                        Is this {drawerType} only performed on certain days of
                        the week?
                      </p>
                    </div>
                    <div>
                      <PurpleSwitch
                        checked={daySelectionChecked}
                        onChange={handleDaySelectionChecked}
                      />
                    </div>
                  </div>
                  {daySelectionChecked && (
                    <div className="mt-4">
                      <div>{appliedToButtonGroup()}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Changes" : "Save Labor Standard"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                {modalEdit ? (
                  <ButtonDelete
                    text="Delete"
                    onClick={() => {
                      props.delete_labor_standard(modalData.uuid);
                      handleCloseLaborStandardDrawer();
                    }}
                  />
                ) : (
                  <ButtonOutlined
                    text="Cancel"
                    onClick={() => handleCloseLaborStandardDrawer()}
                  />
                )}
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
      <LaborModelTestModal
        open={openTestModal}
        handleClose={handleCloseTestModal}
        company_id={props.company_id}
        storeNames={props.storeNames}
        homeStore={props.homeStore}
        roleTypes={props.roleTypes}
      />
      <PublishLaborModelWarningModal
        open={openPublishWarningModal}
        handleClose={handleClosePublishWarningModal}
        publish_labor_standard={props.publish_labor_standard}
      />
    </>
  );
}

export default LaborModelInnerDisplay;
