import React from 'react'

function ButtonFilled(props) {
  return (
    <div
      className="w-full py-3 rounded-full bg-violet-700 hover:bg-violet-800 border border-violet-700 hover:border-violet-800 text-white text-lg flex items-center justify-center cursor-pointer"
      onClick={props.onClick}
    >
      {props.text}
    </div>
  )
}

export default ButtonFilled
