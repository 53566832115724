import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import PayoutLogicModal from "./PayoutLogicModal.js";

export default function TargetMetricModal(props) {
  const box_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const [metric_copy, set_metric_copy] = React.useState(props.metric);

  function handleSelectMetric(event) {
    const info = props.metricOptions.filter(
      (v, i) => v.id === event.target.value
    )[0];
    set_metric_copy({
      ...metric_copy,
      ["id"]: event.target.value,
      ["name"]: info["name"],
      ["relative_value_ind"]: info["relative_value_ind"],
      ["units"]: info["units"],
      ["higher_number_better_ind"]: info["higher_number_better_ind"],
    });
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={box_style}>
        <div className="w-full">
          <h3 className="font-bold">Metric Title</h3>
          <div className="mt-3 px-10">
            <Box sx={{ minWidth: 250 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Metric</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={metric_copy.id}
                  label="Metric"
                  name="id"
                  onChange={handleSelectMetric}
                >
                  {props.metricOptions.map((v, i) => {
                    return (
                      <MenuItem key={v.id} value={v.id}>
                        {v.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className="w-full">
          <div className="mt-5">
            <Divider />
          </div>
          <div>
            <PayoutLogicModal
              contestType={props.contest_info.contest_type}
              metric={metric_copy}
              setMetric={set_metric_copy}
              addUnits={props.addUnits}
            />
          </div>
          <div className="mt-5">
            <Divider />
          </div>
          <div className="mt-5 flex items-center justify-center gap-5">
            <Button
              disabled={
                metric_copy.id === "" ||
                (metric_copy.competition_payout.length === 0) &
                  (metric_copy.standalone_payout.length === 0)
              }
              onClick={() => props.saveMetric(metric_copy)}
              variant="contained"
            >
              Save Metric Incentive
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

// const [multiplier, setMultiplier] = React.useState('');

// const handleMultiplierChange = (event) => {
//   setMultiplier(event.target.value);
// };

// const [numColors, setNumColors] = React.useState(2)

// function incrementNumColors() {
//   setNumColors(prevState => prevState += 1);
// }

// function decrementNumColors() {
//   setNumColors(prevState => prevState -= 1);
// }

// const twoColors = <div className=" ml-5 flex items-center gap-2">
//                     <div className="text-red-500">
//                       <CircleIcon style={{ fontSize: "18px" }} />
//                     </div>
//                     <TextField id="outlined-basic" label="%" variant="outlined" size="small" style={{ width: "55px" }} />
//                     <div className="text-green-600">
//                       <CircleIcon style={{ fontSize: "18px" }} />
//                     </div>
//                     <button onClick={incrementNumColors}>
//                       <IconButton>
//                         <AddIcon style={{ fontSize: "18px" }} />
//                       </IconButton>
//                     </button>
//                   </div>

// const threeColors = <div className="flex items-center gap-2">
//                       <button onClick={decrementNumColors}>
//                         <IconButton>
//                           <RemoveIcon style={{ fontSize: "18px" }} />
//                         </IconButton>
//                       </button>
//                       <div className="text-red-500">
//                         <CircleIcon style={{ fontSize: "18px" }} />
//                       </div>
//                       <TextField id="outlined-basic" label="%" variant="outlined" size="small" style={{ width: "55px" }} />
//                       <div className="text-yellow-400">
//                         <CircleIcon style={{ fontSize: "18px" }} />
//                       </div>
//                       <TextField id="outlined-basic" label="%" variant="outlined" size="small" style={{ width: "55px" }} />
//                       <div className="text-green-600">
//                         <CircleIcon style={{ fontSize: "18px" }} />
//                       </div>
//                       <button onClick={incrementNumColors}>
//                         <IconButton>
//                           <AddIcon style={{ fontSize: "18px" }} />
//                         </IconButton>
//                       </button>
//                     </div>

// const fourColors =  <div className="flex items-center gap-2">
//                       <button onClick={decrementNumColors}>
//                         <IconButton>
//                           <RemoveIcon style={{ fontSize: "18px" }} />
//                         </IconButton>
//                       </button>
//                       <div className="text-red-500">
//                         <CircleIcon style={{ fontSize: "18px" }} />
//                       </div>
//                       <TextField id="outlined-basic" label="%" variant="outlined" size="small" style={{ width: "55px" }} />
//                       <div className="text-orange-500">
//                         <CircleIcon style={{ fontSize: "18px" }} />
//                       </div>
//                       <TextField id="outlined-basic" label="%" variant="outlined" size="small" style={{ width: "55px" }} />
//                       <div className="text-yellow-400">
//                         <CircleIcon style={{ fontSize: "18px" }} />
//                       </div>
//                       <TextField id="outlined-basic" label="%" variant="outlined" size="small" style={{ width: "55px" }} />
//                       <div className="text-green-600">
//                         <CircleIcon style={{ fontSize: "18px" }} />
//                       </div>
//                     </div>

{
  /* <div className="mt-4">
            <div className="flex items-center gap-1">
              <h3 className="font-bold">Thresholds</h3>
              <Tooltip title="Thresholds Tooltip">
                <HelpIcon style={{ fontSize: "20px", color: "gray" }}/>
              </Tooltip>
            </div>
            <div className="w-full flex justify-center mt-3">
              {(numColors === 2 ? twoColors : numColors === 3 ? threeColors : fourColors)}
            </div>
          </div>
          <div className="mt-5">
            <Divider />
          </div>
          <div className="mt-4">
            <div className="flex items-center gap-1">
              <h3 className="font-bold">Weighted Multiplier</h3>
              <Tooltip title="Weighted Multiplier Tooltip">
                <HelpIcon style={{ fontSize: "20px", color: "gray" }}/>
              </Tooltip>
            </div>
            <div className="w-full flex justify-center mt-3">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Multiplier</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={multiplier}
                    label="Multiplier"
                    onChange={handleMultiplierChange}
                  >
                    <MenuItem value={1}>1x</MenuItem>
                    <MenuItem value={2}>2x</MenuItem>
                    <MenuItem value={3}>3x</MenuItem>
                    <MenuItem value={5}>5x</MenuItem>
                    <MenuItem value={10}>10x</MenuItem>
                    <MenuItem value={20}>20x</MenuItem>
                    <MenuItem value={25}>25x</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div> */
}
