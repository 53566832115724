import React from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

function DailyBreakCard() {
  return (
    <div className="w-full h-full bg-slate-900 opacity-40 flex items-center justify-center">
      <div
        className="text-slate-900 w-full"
      >
        <DragIndicatorIcon />
      </div>
    </div>
  )
}

export default DailyBreakCard
