import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import { API_PWD_RESET } from "../constants";

function ForgotPasswordResend({ email, setPasswordResetFlow }) {
  // Function to handle password reset
  const handleResendEmail = async () => {
    try {
      // Replace with your actual API endpoint
      const apiUrl = API_PWD_RESET;

      // Make API call
      await axios.post(apiUrl, { email });

      // If successful, move to the next step
      setPasswordResetFlow(2);
    } catch (error) {
      console.error("Error resending email", error);
      // Handle error accordingly
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="w-96 flex flex-col items-center justify-center">
        <div className="h-24 w-24 bg-violet-50 flex items-center justify-center rounded-full">
          <div className="h-16 w-16 bg-violet-100 flex items-center justify-center rounded-full text-violet-700">
            <EmailIcon style={{ fontSize: 40 }} />
          </div>
        </div>
        <div className="mt-5 flex flex-col justify-center items-center">
          <h3 className="text-4xl text-slate-900">Check Your Email</h3>
          <p className="text-slate-500 mt-8 text-lg">
            We sent a password reset link to:
          </p>
          <p className="text-slate-900 mt-2 text-xl font-bold">
            {email} {/* Display the email passed as a prop */}
          </p>
        </div>
        <div
          className="w-full bg-primary rounded text-white py-4 mt-8 flex justify-center items-center"
          //onClick={() => setPasswordResetFlow(3)}
        >
          Open Email
        </div>
        <div className="mt-7">
          <p
            className="text-slate-500 cursor-pointer"
            onClick={handleResendEmail}
          >
            Didn't receive an email?{" "}
            <span className="text-primary">Click to resend</span>
          </p>
        </div>
        <button
          className="mt-4 py-4 px-6 text-slate-500 hover:text-slate-900"
          onClick={() => setPasswordResetFlow(0)}
        >
          <KeyboardBackspaceIcon /> Back to log in
        </button>
      </div>
    </div>
  );
}

export default ForgotPasswordResend;
