import React, { useState, useEffect } from "react";
import ButtonFilled from "../requests/shared/helpers/ButtonFilled";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import {
  API_get_pay_periods,
  API_get_timecards_for_employee,
} from "../../../constants";
import TimeEntriesHeaderEmployee from "./TimeEntriesHeaderEmployee";
import TimeEntriesHeader from "./TimeEntriesHeader";
import {
  formatDate,
  formatDateReadableWithDayOfWeekNoYear,
  formatDateReadableWithDayOfWeek,
} from "../requests/utilities/helpers";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import LoadSpinner from "../../../utilities/LoadSpinner";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "react-toastify/dist/ReactToastify.css";
import BlinkingDot from "./BlinkingDot";
import InfoIcon from '@mui/icons-material/Info';

function TimeEntriesDisplay(props) {
  const default_data = {
    clocked_in_at: null,
    clocked_out_at: null,
    clocked_in_at_date_time: new Date(),
    clocked_out_at_date_time: new Date(),
    declared_cash_tips: null,
    published_schedule: null,
    status: null,
    store_team_member: {
      first_name: "",
      last_name: "",
      id: null,
    },
    wage_type: null,
    timecard_breaks: [],
    uuid: null,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  const [showSettings, setShowSettings] = React.useState(false);

  const [payPeriods, setPayPeriods] = useState([]);

  const [timeEntries, setTimeEntries] = useState([]);
  const [selectedPayPeriodDates, setSelectedPayPeriodDates] = useState([])

  const [showTimeEntries, setShowTimeEntries] = useState(false)

  const [isLoading, setIsLoading] = React.useState(false);

  console.log("PAY PERIODS", payPeriods)
  console.log("TIME ENTRIES", timeEntries)

  useEffect(() => {
    setShowTimeEntries(false)
    fetchPayPeriods(true)
  }, [props.homeStore]);


  const fetchPayPeriods = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = { store_id: props.homeStore };
    let api = API_get_pay_periods;
    try {
      const res = await getRightworkAPI(api, api_params);
      console.log(res);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.pay_periods
        setPayPeriods(data)
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const fetchTimeCardsForEmployee = async (showLoading, date) => {
    setIsLoading(showLoading);
    let api_params = {
      store_id: props.homeStore,
      date: date
    };
    let api = API_get_timecards_for_employee;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        console.log("TIMECARDS", res.data.redux?.payload?.timecards)
        setTimeEntries(res.data.redux?.payload?.timecards)
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };



  const handleSelectPayPeriod = (beginning_date, end_date) => {
    setShowTimeEntries(true)
    setSelectedPayPeriodDates([beginning_date, end_date])
    fetchTimeCardsForEmployee(true, beginning_date)
  }

  const handleGoBack = () => {
    // Deselect the current period and go back to the pay period list
    setShowTimeEntries(false)
  };

  function getHoursAndBreaks(startTime, endTime, breaksArray) {
    // Convert both to milliseconds
    var startTimeMilliseconds = new Date(startTime).getTime();
    var endTimeMilliseconds = new Date(endTime).getTime();

    let sumBreaksMilliseconds = 0;

    breaksArray?.forEach((breakItem) => {
      var startBreakMilliseconds = new Date(breakItem.clocked_in_at).getTime();
      var endBreakMilliseconds = breakItem.clocked_out_at
        ? new Date(breakItem.clocked_out_at).getTime()
        : breakItem.clocked_in_at
        ? new Date().getTime()
        : 0;
      var breakLengthMilliseconds = Math.abs(
        endBreakMilliseconds - startBreakMilliseconds
      );
      sumBreaksMilliseconds += breakLengthMilliseconds;
    });

    var totalTimeWorkedMilliseconds = Math.abs(
      endTimeMilliseconds - startTimeMilliseconds
    );
    var paidTimeWorkedMilliseconds =
      totalTimeWorkedMilliseconds - sumBreaksMilliseconds;

    // Convert milliseconds to hours
    var paidHours = Math.floor(paidTimeWorkedMilliseconds / (1000 * 60 * 60));

    // Calculate remaining milliseconds after subtracting full hours
    var remainingMilliseconds = paidTimeWorkedMilliseconds % (1000 * 60 * 60);

    // Convert remaining milliseconds to minutes
    var paidMinutes = Math.ceil(remainingMilliseconds / (1000 * 60));

    // Calculate break minutes
    var breakMinutes = Math.floor(sumBreaksMilliseconds / (1000 * 60));

    return {
      paidHours: paidHours,
      paidMinutes: paidMinutes,
      breakMinutes: breakMinutes,
    };
  }

  // Enhancing the status lookup to include both label and styling
  const statusStyles = {
    auto_approved: {
      label: "",
      classes: "bg-emerald-50 border border-emerald-300 text-emerald-800",
    },
    auto_flag: {
      label: "Flagged",
      classes: "bg-fuchsia-50 border border-fuchsia-300 text-fuchsia-800",
    },
    approved: {
      label: "Approved",
      classes: "bg-blue-50 border border-blue-300 text-blue-800",
    },
    denied: {
      label: "Denied",
      classes: "bg-rose-50 border border-rose-300 text-red-800",
    },
    pending: {
      label: "Pending",
      classes: "bg-yellow-50 border border-yellow-300 text-yellow-800",
    },
  };

  // Existing states and hooks...
  const [nameFilter, setNameFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // Assuming you've fetched timeEntries, now let's prepare dynamic filter options
  useEffect(() => {
    // This effect could be used to further manipulate timeEntries data if needed
  }, [timeEntries]);

  // Unique names for the Name filter dropdown
  const uniqueNames = [
    ...new Set(
      timeEntries.map(
        (entry) =>
          `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`
      )
    ),
  ];

  const uniqueStoreTeamMembers = [];
  const uniqueIds = new Set();

  timeEntries.forEach((entry) => {
    const memberId = entry.store_team_member.id;
    if (!uniqueIds.has(memberId)) {
      uniqueIds.add(memberId);
      uniqueStoreTeamMembers.push(entry.store_team_member);
    }
  });


  // Unique dates for the Date filter dropdown
  const uniqueDates = [
    ...new Set(
      timeEntries.map((entry) =>
        formatDate(new Date(entry.clocked_in_at).toLocaleDateString())
      )
    ),
  ];

  // Handlers for filter value changes
  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredTimeEntries = timeEntries.filter((entry) => {
    const fullName = `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`;
    const entryDate = formatDate(
      new Date(entry.clocked_in_at).toLocaleDateString()
    );
    // Apply name filter
    const matchesName = nameFilter ? fullName === nameFilter : true;
    // Apply date filter
    const matchesDate = dateFilter ? entryDate === dateFilter : true;
    // Apply status filter
    const matchesStatus = statusFilter ? entry.status === statusFilter : true;

    return matchesName && matchesDate && matchesStatus;
  });

  console.log("MODAL DATA", modalData);

  const filteredTimeEntriesForApproveAll = filteredTimeEntries.filter(
    (entry) => {
      return entry.status === "pending" || entry.status === "auto_flag";
    }
  );

  let filteredTimeEntriesUUIDs = [];

  filteredTimeEntriesForApproveAll.forEach((timeEntry) =>
    filteredTimeEntriesUUIDs.push(timeEntry.uuid)
  );


  function handleNameChange(event) {
    const selectedStoreTeamMemberId = event.target.value;
    const selectedStoreTeamMember = uniqueStoreTeamMembers.find(
      (member) => member.id === selectedStoreTeamMemberId
    );
    setModalData({
      ...modalData,
      store_team_member: selectedStoreTeamMember,
    });
  }

  function handleDateTimeChange(name, value) {
    setModalData((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  }

  function handleBreakTimeChange(event) {
    // Parse clocked_in_at and clocked_out_at into Date objects
    const clockedInAt = modalData.clocked_in_at_date_time;
    const clockedOutAt = modalData.clocked_out_at_date_time;

    // Calculate midpoint time in milliseconds
    const midpoint = (clockedInAt.getTime() + clockedOutAt.getTime()) / 2;

    // Calculate the time difference from the midpoint
    const minutesFromMidpoint = event.target.value / 2;

    // Calculate updated break times
    const updatedBreak = {
      ...modalData.timecard_breaks[0],
      clocked_in_at: new Date(midpoint - minutesFromMidpoint * 60000).getTime(),
      clocked_out_at: new Date(
        midpoint + minutesFromMidpoint * 60000
      ).getTime(),
      is_paid: false,
      uuid: null,
    };

    // Update modal data with the updated break
    setModalData({
      ...modalData,
      timecard_breaks: [updatedBreak],
    });
  }

  function handleTipChange(event) {
    setModalData({
      ...modalData,
      declared_cash_tips: event.target.value,
    });
  }


  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const csvRows = [];
    // Headers
    const headers = [
      "Name",
      "Date",
      "Timesheet",
      "Paid Hours",
      "Unpaid Breaks",
      "Status",
    ];
    csvRows.push(headers.join(","));

    // Values
    for (const entry of data) {
      const paidHours = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).paidHours;
      const paidMinutes = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).paidMinutes;
      const breakMinutes = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).breakMinutes;
      entry["clocked_in_at_date_time"] = new Date(entry.clocked_in_at);
      entry["clocked_out_at_date_time"] = new Date(
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime()
      );
      entry["status"] = entry.clocked_out_at
        ? entry.status
        : "currently_working";
      entry["sub_status"] =
        !entry.clocked_out_at &&
        (entry.timecard_breaks?.[entry.timecard_breaks?.length - 1]
          ?.clocked_out_at ||
          entry.timecard_breaks?.length === 0)
          ? "Working"
          : !entry.clocked_out_at &&
            !entry.timecard_breaks?.[entry.timecard_breaks?.length - 1]
              ?.clocked_out_at
          ? "On Break"
          : null;

      const values = [
        `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`,
        `${formatDate(new Date(entry.clocked_in_at).toLocaleDateString())}`,
        `${new Date(entry.clocked_in_at).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${new Date(entry.clocked_out_at).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
        `${paidHours}hrs ${paidMinutes}min`,
        `${breakMinutes}min`,
        `${entry.status}`,
      ];
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  // Function to download CSV file
  const downloadCSV = () => {
    const csvData = convertToCSV(timeEntries);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    const datePayPeriod = `${selectedPayPeriodDates[0]}_to_${selectedPayPeriodDates[1]}`;
    const filename = `time_entries_${datePayPeriod}.csv`; // Add datePayPeriod to filename
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  return (
    <>
      {!isLoading ? (
        <div>
          {showTimeEntries ?
            <div>
              <div>
                <div>
                  <TimeEntriesHeaderEmployee
                    title="Back to Pay Periods"
                    storeOptions={props.storeOptions}
                    homeStore={props.homeStore}
                    storeNames={props.storeNames}
                    change_home_store={props.change_home_store}
                    setCurrentTab={props.setCurrentTab}
                    handleGoBack={handleGoBack}
                    showTimeEntries={showTimeEntries}
                    setShowTimeEntries={setShowTimeEntries}
                    setShowSettings={setShowSettings}
                  />
                </div>
                <div className="px-6 mt-8">
                  <div className="flex justify-between">
                    <div>
                      <h1 className="text-3xl text-slate-900">
                        Your Time Entries
                      </h1>
                      <h3 className="mt-1 text-slate-500">
                        {`${formatDateReadableWithDayOfWeek(selectedPayPeriodDates[0])} - ${formatDateReadableWithDayOfWeek(selectedPayPeriodDates[1])}`}
                      </h3>
                    </div>
                    <div className="flex items-center space-x-3">
                      {!timeEntries.some((entry) =>
                        [
                          "pending",
                          "currently_working",
                          "auto_flag",
                        ].includes(entry.status)
                      ) ? (
                        <Tooltip title="Download all time entries to a .csv file">
                          <div
                            className="px-5 py-2 rounded-full bg-orange-600 hover:bg-orange-700 flex items-center space-x-1 text-white cursor-pointer"
                            onClick={downloadCSV}
                          >
                            <div>
                              <DownloadIcon />
                            </div>
                            <p>Download</p>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Can only download when all time entries are approved or denied">
                          <div className="px-5 py-2 rounded-full bg-slate-200 flex items-center space-x-1 text-slate-400">
                            <div>
                              <DownloadIcon />
                            </div>
                            <p>Download</p>
                          </div>
                        </Tooltip>
                      )}
                      {/* <div className="px-5 py-2 rounded-full bg-emerald-600 hover:bg-emerald-700 flex items-center space-x-1 text-white cursor-pointer">
                        <div>
                          <SendIcon
                            style={{ fontSize: "18px", marginBottom: "2px" }}
                          />
                        </div>
                        <p>Publish</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex items-center space-x-5 mt-12">
                    <div className="w-48">
                      <p className="text-sm text-slate-500">
                        Filter by date
                      </p>
                      <div className="mt-1">
                        <Select
                          fullWidth
                          displayEmpty
                          value={dateFilter}
                          onChange={handleDateFilterChange}
                          size="small"
                          sx={{
                            borderRadius: "8px",
                            paddingY: "1px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6d28d9",
                              },
                            height: "40px",
                            backgroundColor: "white",
                          }}
                        >
                          <MenuItem value="">All Dates</MenuItem>
                          {uniqueDates.map((name, index) => (
                            <MenuItem key={index} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="w-48">
                      <p className="text-sm text-slate-500">
                        Filter by status
                      </p>
                      <div className="mt-1">
                        <Select
                          fullWidth
                          displayEmpty
                          value={statusFilter}
                          onChange={handleStatusFilterChange}
                          size="small"
                          sx={{
                            borderRadius: "8px",
                            paddingY: "1px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#6d28d9",
                              },
                            height: "40px",
                            backgroundColor: "white",
                          }}
                        >
                          <MenuItem value="">All Statuses</MenuItem>
                          <MenuItem value="approved">Approved</MenuItem>
                          <MenuItem value="denied">Denied</MenuItem>
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="auto_flag">Flagged</MenuItem>
                          <MenuItem value="currently_working">
                            Currently Working
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-8 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-slate-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="flex-1 py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Timesheet
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Paid Hours
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Unpaid Breaks
                              </th>

                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Cash Tips
                              </th> */}
                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Status
                              </th> */}
                              <th
                                scope="col"
                                className="w-1/12 relative py-3.5 pl-3 pr-6 text-xs text-slate-500 font-medium text-right"
                              >Status</th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-slate-200 bg-white">
                            {filteredTimeEntries.map((entry, i) => {
                              const paidHours = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).paidHours;
                              const paidMinutes = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).paidMinutes;
                              const breakMinutes = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).breakMinutes;
                              entry["clocked_in_at_date_time"] = new Date(
                                entry.clocked_in_at
                              );
                              entry["clocked_out_at_date_time"] = new Date(
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime()
                              );
                              entry["status"] = entry.clocked_out_at
                                ? entry.status
                                : "currently_working";
                              entry["sub_status"] =
                                !entry.clocked_out_at &&
                                (entry.timecard_breaks?.[
                                  entry.timecard_breaks?.length - 1
                                ]?.clocked_out_at ||
                                  entry.timecard_breaks?.length === 0)
                                  ? "Working"
                                  : !entry.clocked_out_at &&
                                    !entry.timecard_breaks?.[
                                      entry.timecard_breaks?.length - 1
                                    ]?.clocked_out_at
                                  ? "On Break"
                                  : null;
                              return (
                                <tr key={i}>
                                  <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                    <div>
                                      {entry.store_team_member.first_name}{" "}
                                      {entry.store_team_member.last_name}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>
                                      {formatDateReadableWithDayOfWeekNoYear(
                                        formatDate(
                                          new Date(
                                            entry.clocked_in_at
                                          ).toLocaleDateString()
                                        )
                                      )}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    {entry.clocked_out_at ? (
                                      <div>
                                        {new Date(
                                          entry.clocked_in_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}{" "}
                                        -{" "}
                                        {new Date(
                                          entry.clocked_out_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </div>
                                    ) : (
                                      <div>
                                        {new Date(
                                          entry.clocked_in_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}{" "}
                                        -
                                      </div>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>{`${paidHours}hrs ${paidMinutes}min`}</div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>{`${breakMinutes}min`}</div>
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>${entry.declared_cash_tips ? entry.declared_cash_tips : "0.00"}</div>
                                  </td> */}
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    {entry.status !==
                                    "currently_working" ? (
                                      <div
                                        className={`inline-block px-3 py-1 rounded-lg text-sm font-medium whitespace-nowrap ${
                                          statusStyles[entry.status]
                                            ?.classes
                                        }`}
                                      >
                                        {statusStyles[entry.status]
                                          ?.label || ""}
                                      </div>
                                    ) : (
                                      <div
                                        className={`w-fit flex items-center space-x-1.5 px-3 py-1 rounded-lg ${
                                          entry.sub_status === "Working"
                                            ? "bg-violet-50 border border-violet-300 text-violet-800"
                                            : "bg-orange-50 border border-orange-300 text-orange-800"
                                        }`}
                                      >
                                        <div>
                                          <BlinkingDot
                                            sub_status={entry.sub_status}
                                          />
                                        </div>
                                        <div>
                                          <p>{entry.sub_status}</p>
                                        </div>
                                      </div>
                                    )}
                                  </td> */}
                                  <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                                    {entry.status !==
                                      "currently_working" ? (
                                      <div
                                        className={`inline-block px-3 py-1 rounded-lg text-sm font-medium whitespace-nowrap ${
                                          statusStyles[entry.status]
                                            ?.classes
                                        }`}
                                      >
                                        {statusStyles[entry.status]
                                          ?.label || ""}
                                      </div>
                                    ) : (
                                      <div
                                        className={`w-fit flex items-center space-x-1.5 px-3 py-1 rounded-lg ${
                                          entry.sub_status === "Working"
                                            ? "bg-violet-50 border border-violet-300 text-violet-800"
                                            : "bg-orange-50 border border-orange-300 text-orange-800"
                                        }`}
                                      >
                                        <div>
                                          <BlinkingDot
                                            sub_status={entry.sub_status}
                                          />
                                        </div>
                                        <div>
                                          <p>{entry.sub_status}</p>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="border-b border-slate-200"></div>
                        {filteredTimeEntries.length === 0 &&
                          <div className="w-full mt-24 flex justify-center">
                            <div className="flex flex-col items-center">
                              <div className="text-slate-900">
                                <InfoIcon />
                              </div>
                              <p className="mt-2 text-slate-900">No time entries match this filter criteria</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> :
            <div>
              <div>
                <TimeEntriesHeaderEmployee
                  title="Pay Periods"
                  storeOptions={props.storeOptions}
                  homeStore={props.homeStore}
                  storeNames={props.storeNames}
                  change_home_store={props.change_home_store}
                  setCurrentTab={props.setCurrentTab}
                  setShowSettings={setShowSettings}
                />
              </div>
              <div className="mt-16 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-slate-200">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-[22%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                            >
                              Pay Period Start
                            </th>
                            <th
                              scope="col"
                              className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Pay Period End
                            </th>
                            {/* <th
                              scope="col"
                              className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Status
                            </th> */}
                            <th
                              scope="col"
                              className="w-1/12 relative py-3.5 pl-3 pr-6"
                            >
                              {/* <span className="sr-only">Edit</span> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-200 bg-white">
                          {payPeriods.map((period, i) => (
                            <tr
                              key={i}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                handleSelectPayPeriod(period.beginning_date, period.end_date)
                              }
                            >
                              <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                <div>
                                  {formatDateReadableWithDayOfWeek(
                                    period.beginning_date
                                  )}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                <div>
                                  {formatDateReadableWithDayOfWeek(
                                    period.end_date
                                  )}
                                </div>
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                In progress
                              </td> */}
                              <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                                <div className="text-slate-500">
                                  <ChevronRightIcon />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="border-b border-slate-200"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default TimeEntriesDisplay;
