import React from "react";
import TargetMetricModal from "./TargetMetricModal";

function EditMetricCard(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [metric, setMetric] = React.useState(props.metric);

  const handleMetricChange = (event) => {
    setMetric({
      ...metric,
      [event.target.name]: event.target.value,
    });
  };

  function saveMetric(new_metric) {
    handleClose();
    setMetric(new_metric);
    let curr_contest_metric_detail = [...props.contest_metric_detail];
    curr_contest_metric_detail[props.indexOf] = new_metric;
    console.log(props.indexOf);
    console.log(curr_contest_metric_detail);
    props.set_contest_metric_detail(curr_contest_metric_detail);
  }

  console.log(props.contest_metric_detail);

  return (
    <div>
      <div
        className="w-56 h-36 bg-white rounded-xl px-8 py-4 text-gray-900 shadow-xl flex flex-col justify-center items-center cursor-pointer"
        onClick={handleOpen}
      >
        <h3 className="text-sm font-bold">{metric.name}</h3>
        <p className="mt-4 text-xs font-semibold">Payouts</p>
        {props.contest_info.contest_type === "competition" ? (
          <div>
            <div className="text-xxs">
              {metric.competition_payout.map((place) => (
                <p>
                  {place.rank}: ${place.payout}
                </p>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-xxs">
            {metric.standalone_payout.map((payout) => (
              <p>
                ${payout.payout} if metric{" "}
                {metric.higher_number_better_ind === 1 ? ">" : "<"}{" "}
                {props.addUnits(
                  payout.threshold,
                  metric.relative_value_ind === 1 ? "percent" : metric.units
                )}
              </p>
            ))}
          </div>
        )}
      </div>
      <TargetMetricModal
        open={open}
        handleClose={handleClose}
        contest_info={props.contest_info}
        contest_metric_detail={props.contest_metric_detail}
        set_contest_metric_detail={props.set_contest_metric_detail}
        metricOptions={props.metricOptions}
        metric={metric}
        handleMetricChange={handleMetricChange}
        saveMetric={saveMetric}
        setMetric={setMetric}
        addUnits={props.addUnits}
      />
    </div>
  );
}

export default EditMetricCard;
