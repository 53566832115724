import React from "react";
import TabsThree from "./shared/helpers/TabsThree";
import AvailabilityRequestsTable from "./manager/availability/AvailabilityRequestsTable";
import AvailabilityApprovedTable from "./manager/availability/AvailabilityApprovedTable";
import AvailabilityMineTable from "./shared/availability/AvailabilityMineTable";
import { mergeAvailabilityRequests } from "./utilities/helpers";
import { mergeAvailabilityHistory } from "./utilities/helpers";
import { mergeAvailability2 } from "./utilities/helpers";
import RequestHeader from "./shared/helpers/RequestHeader";

export default function AvailabilityDisplay(props) {
  const [activeTab, setActiveTab] = React.useState("Requests");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  const availabilityRequestsData = mergeAvailabilityRequests(
    props.availabilityRequests.pending
  );

  const availabilityHistoryData = mergeAvailabilityHistory(
    props.availabilityRequestsResponses.responded
  );

  const myAvailabilityData2 = mergeAvailability2(
    props.availabilityData2.current,
    props.availabilityData2.approved,
    props.availabilityData2.pending
  );

  return (
    <div>
      {/* <RequestHeader title="Availability" /> */}
      <div className="w-1/2 mt-12 px-6">
        <TabsThree
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Requests"
          tab2="Acted On"
          tab3="Mine"
        />
      </div>
      <div className="w-full">
        {activeTab === "Requests" ? (
          <AvailabilityRequestsTable
            availabilityRequestsData={availabilityRequestsData}
            manager_respond_to_availability_request={
              props.manager_respond_to_availability_request
            }
          />
        ) : activeTab === "Acted On" ? (
          <AvailabilityApprovedTable
            availabilityHistoryData={availabilityHistoryData}
            archive_availability_request={props.archive_availability_request}
          />
        ) : activeTab === "Mine" ? (
          <AvailabilityMineTable
            myAvailabilityData={myAvailabilityData2}
            create_availability_request={props.create_availability_request}
            delete_availability_request={props.delete_availability_request}
            rescind_availability_request={props.rescind_availability_request}
            permissionRole={props.permissionRole}
            minEffectiveDate={props.minEffectiveDate}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
