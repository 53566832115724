import React from "react";
import TargetMetricModal from "./TargetMetricModal";
import AddIcon from "@mui/icons-material/Add";

function NewMetricCard(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [metric, setMetric] = React.useState({
    id: "",
    name: "",
    competition_payout: [],
    standalone_payout: [],
    metric_weekly_info: [],
  });

  const handleMetricChange = (event) => {
    setMetric({
      ...metric,
      [event.target.name]: event.target.value,
    });
  };

  function saveMetric(new_metric) {
    handleClose();
    let curr_contest_metric_detail = [...props.contest_metric_detail];
    curr_contest_metric_detail.push(new_metric);
    props.set_contest_metric_detail(curr_contest_metric_detail);
  }

  return (
    <div>
      <div>
        <button
          type="button"
          className="w-56 h-36 rounded-xl border-2 border-dashed border-gray-400 p-12 text-center hover:border-gray-600 shadow flex flex-col justify-center items-center"
          onClick={handleOpen}
        >
          <AddIcon />
          <span className="mt-2 block text-sm font-medium text-gray-900">
            Create a new metric incentive
          </span>
        </button>
      </div>
      <TargetMetricModal
        open={open}
        handleClose={handleClose}
        contest_info={props.contest_info}
        contest_metric_detail={props.contest_metric_detail}
        set_contest_metric_detail={props.contest_metric_detail}
        metricOptions={props.metricOptions}
        metric={metric}
        handleMetricChange={handleMetricChange}
        saveMetric={saveMetric}
        setMetric={setMetric}
        addUnits={props.addUnits}
      />
    </div>
  );
}

export default NewMetricCard;
