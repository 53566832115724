import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import CategoryEntry from './CategoryEntry';
import InputEntry from './InputEntry';


function CategoryCard(props) {
  const [showInput, setShowInput] = React.useState(
    props.category.entries.length === 0
  );
  console.log("fileKey", props.fileKey)

  return (
    <div className="w-full border border-slate-300 rounded-xl p-4 mt-6">
      <p className="text-slate-900 text-2xl">{props.category.name}</p>
      {props.category.entries.map((entry, index) => (
        <div key={index} className="py-5 border-b border-slate-300">
          <CategoryEntry
            entry={entry}
            delete_logbook_category_entry={props.delete_logbook_category_entry}
            categoryType={props.category.type}
            get_file_upload={props.get_file_upload}
          />
        </div>
      ))}
      {showInput ? (
        <div className="mt-5">
          <InputEntry
            fileKey={props.fileKey}
            category={props.category}
            post_logbook_category_entry={props.post_logbook_category_entry}
            setShowInput={setShowInput}
            categoryType={props.category.type}
            homeCompany={props.homeCompany}
            get_file_upload={props.get_file_upload}
            delete_file_upload={props.delete_file_upload}
          />
        </div>
      ) : (
        <div
          className="mt-5 pb-2 flex items-center space-x-2 text-violet-700 hover:text-violet-800 cursor-pointer"
          onClick={() => setShowInput(true)}
        >
          <div>
            <AddIcon style={{ marginBottom: "1px" }} />
          </div>
          <p>Add New Entry</p>
        </div>
      )}
    </div>
  );
}

export default CategoryCard;
