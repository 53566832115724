import React from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EastIcon from "@mui/icons-material/East";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip from "@mui/material/Tooltip";

export default function HomeStoreInfoCard(props) {
  return (
    <div className="w-full bg-white shadow rounded-lg">
      <div>
        {props.homeStoreInfo.length > 0 && (
          <div className="py-4 px-6">
            <div className="flex items-center justify-between">
              <p className="truncate text-base font-bold text-gray-800">
                {
                  props.participating_stores.filter(
                    (v, i) => v.id === props.homeStoreInfo[0].store_id
                  )[0]["store_name"]
                }
              </p>
              <div className="ml-2 flex flex-shrink-0">
                {props.contest_type === "competition" ? (
                  <p
                    className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                      props.homeStoreInfo[0].rank === 1
                        ? "bg-amber-200 text-amber-600"
                        : props.homeStoreInfo[0].rank === 2
                        ? "bg-gray-200 text-gray-800"
                        : props.homeStoreInfo[0].rank === 3
                        ? "bg-orange-200 text-orange-800"
                        : "text-gray-800"
                    }`}
                  >
                    {props.homeStoreInfo[0].rank === 1
                      ? "1st Place"
                      : props.homeStoreInfo[0].rank === 2
                      ? "2nd Place"
                      : props.homeStoreInfo[0].rank === 3
                      ? "3rd Place"
                      : `${props.homeStoreInfo[0].rank}th Place`}
                  </p>
                ) : (
                  <div
                    className={`${
                      props.homeStoreInfo[0].color_num === 1
                        ? "text-green-600"
                        : props.homeStoreInfo[0].color_num === 2
                        ? "text-yellow-400"
                        : "text-red-500"
                    }`}
                  >
                    <CircleIcon style={{ fontSize: "18px" }} />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-2 flex justify-between">
              <Tooltip title="average actual value / average relative value (target)">
                {props.metric_detail.relative_value_ind === 0 ? (
                  <div className="text-sm font-bold flex items-center gap-4">
                    <p>
                      {props.addUnits(
                        props.homeStoreInfo[0].value,
                        props.metric_detail.units
                      )}
                    </p>
                  </div>
                ) : (
                  <div className="text-sm font-bold flex items-center gap-4">
                    <p>
                      {props.addUnits(
                        props.homeStoreInfo[0].value,
                        props.metric_detail.units
                      )}{" "}
                      /{" "}
                      {props.addUnits(
                        props.homeStoreInfo[0].relative_value,
                        props.metric_detail.units
                      )}
                    </p>
                    <EastIcon style={{ fontSize: "14px" }} />
                    <p>
                      {props.addUnits(
                        props.homeStoreInfo[0].final_value,
                        "percent"
                      )}
                    </p>
                  </div>
                )}
              </Tooltip>
              <div className="mt-2 flex items-center gap-1 text-sm text-gray-500 mt-0">
                {props.homeStoreInfo[0].rank < 4 &&
                  props.contest_type === "competition" && (
                    <Tooltip title="Expected Payout (if contest were to end today)">
                      <EmojiEventsIcon fontSize="small" />
                    </Tooltip>
                  )}
                <Tooltip title="Expected Payout (if contest were to end today)">
                  <p>
                    {props.homeStoreInfo[0].payout > 0 &&
                      `$${props.homeStoreInfo[0].payout}`}
                  </p>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
