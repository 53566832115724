import React from "react";
import ShiftCard from "./ShiftCard";
import NewShiftCard from "./NewShiftCard";
import UnavailableCard from "./UnavailableCard";
import UnavailableVacationCard from "./UnavailableVacationCard";
import UnassignedRoles from "./UnassignedRoles";

function PersonDayShiftDisplay(props) {
  const locked_mode =
    !props.edit_mode | props.displayMode | props.locked_person;

  if ((props.person_day_shift.length === 0) & !locked_mode) {
    if (props.person_day_time_off_requests.length > 0) {
      return (
        <UnavailableVacationCard
          weekly_or_daily="weekly"
          p={props.p}
          date_str={props.date_str}
          addShift={props.addShift}
          store_id={props.store_id}
          get_t_str={props.get_t_str}
          date_name={props.date_name}
          availability_info={props.person_day_availability[0]}
          role_names={props.role_names}
          saveShiftFinished={props.saveShiftFinished}
          setSaveShiftFinished={props.setSaveShiftFinished}
          d={props.d}
          copiedShift={props.copiedShift}
          setCopiedShift={props.setCopiedShift}
          addMultipleShifts={props.addMultipleShifts}
          minimizeMode={props.minimizeMode}
          role_types={props.role_types}
          businessWeekStartDayIndex={props.businessWeekStartDayIndex}
          start_qhnum={props.start_qhnum}
          frequentShifts={props.frequentShifts}
          hasNoCapableRoles={props.p.available_role_info.length === 0}
          updatePersonInfo={props.updatePersonInfo}
          breakWaiverEnabled={props.breakWaiverEnabled}
          person_day_time_off_requests={props.person_day_time_off_requests}
        />
      );
    } else if (
      // props.person_day_availability.length === 0 ||
      props.person_day_availability?.[0]?.available_to === 0
    ) {
      return (
        <UnavailableCard
          weekly_or_daily="weekly"
          p={props.p}
          date_str={props.date_str}
          addShift={props.addShift}
          store_id={props.store_id}
          get_t_str={props.get_t_str}
          date_name={props.date_name}
          availability_info={props.person_day_availability[0]}
          role_names={props.role_names}
          saveShiftFinished={props.saveShiftFinished}
          setSaveShiftFinished={props.setSaveShiftFinished}
          d={props.d}
          copiedShift={props.copiedShift}
          setCopiedShift={props.setCopiedShift}
          addMultipleShifts={props.addMultipleShifts}
          minimizeMode={props.minimizeMode}
          role_types={props.role_types}
          businessWeekStartDayIndex={props.businessWeekStartDayIndex}
          start_qhnum={props.start_qhnum}
          frequentShifts={props.frequentShifts}
          hasNoCapableRoles={props.p.available_role_info.length === 0}
          updatePersonInfo={props.updatePersonInfo}
          breakWaiverEnabled={props.breakWaiverEnabled}
        />
      );
      // } else if (props.p.available_role_info.length === 0) {
      //   return <UnassignedRoles />;
    } else {
      return (
        <NewShiftCard
          weekly_or_daily="weekly"
          p={props.p}
          date_str={props.date_str}
          addShift={props.addShift}
          store_id={props.store_id}
          get_t_str={props.get_t_str}
          date_name={props.date_name}
          availability_info={props.person_day_availability[0]}
          role_names={props.role_names}
          saveShiftFinished={props.saveShiftFinished}
          setSaveShiftFinished={props.setSaveShiftFinished}
          d={props.d}
          copiedShift={props.copiedShift}
          setCopiedShift={props.setCopiedShift}
          addMultipleShifts={props.addMultipleShifts}
          minimizeMode={props.minimizeMode}
          role_types={props.role_types}
          businessWeekStartDayIndex={props.businessWeekStartDayIndex}
          start_qhnum={props.start_qhnum}
          frequentShifts={props.frequentShifts}
          edit_mode={props.edit_mode}
          hasNoCapableRoles={props.p.available_role_info.length === 0}
          updatePersonInfo={props.updatePersonInfo}
          breakWaiverEnabled={props.breakWaiverEnabled}
          person_day_time_off_requests={props.person_day_time_off_requests}
          defaultBreakLengthHours={props.defaultBreakLengthHours}
        />
      );
    }
  } else if (props.person_day_shift.length > 0) {
    return (
      <ShiftCard
        weekly_or_daily="weekly"
        shifts_info={props.person_day_shift}
        shift_info={props.person_day_shift[0]}
        lockAllShifts={props.lockAllShifts}
        p={props.p}
        locked_mode={locked_mode || props.lockAllShifts}
        addShift={props.addShift}
        get_t_str={props.get_t_str}
        date_name={props.date_name}
        availability_info={props.person_day_availability[0]}
        role_names={props.role_names}
        saveShiftFinished={props.saveShiftFinished}
        setSaveShiftFinished={props.setSaveShiftFinished}
        d={props.d}
        setCopiedShift={props.setCopiedShift}
        copiedShift={props.copiedShift}
        minimizeMode={props.minimizeMode}
        time_off_conflict={props.person_day_time_off_requests.length > 0}
        role_types={props.role_types}
        addMultipleShifts={props.addMultipleShifts}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        frequentShifts={props.frequentShifts}
        day_in_past={props.day_in_past}
        post_missed_shift={props.post_missed_shift}
        breakWaiverEnabled={props.breakWaiverEnabled}
        showUnpublishedShifts={props.showUnpublishedShifts}
        person_day_time_off_requests={props.person_day_time_off_requests}
        defaultBreakLengthHours={props.defaultBreakLengthHours}
        editShiftsInPastEnabled={props.editShiftsInPastEnabled}
        isCopilotOn={props.isCopilotOn}
      />
    );
  } else {
    return <div></div>;
  }
}

export default PersonDayShiftDisplay;
