import React from 'react'
import TabsThree from '../../shared/helpers/TabsThree'
import TabsTwo from '../../shared/helpers/TabsTwo'
import TimeOffMineTable from '../../shared/availability/TimeOffMineTable'
import PreferencesMineTable from '../../shared/availability/PreferencesMineTable'
import AvailabilityMineTable from '../../shared/availability/AvailabilityMineTable'
import { mergeAvailability2 } from '../../utilities/helpers'
import { mergePreferences2 } from '../../utilities/helpers'

export default function AvailabilityDisplayEmployee(props) {

  const [activeTab, setActiveTab] = React.useState('Availability')

  function handleTabButtonPress(target) {
    setActiveTab(target)
  }

  const myAvailabilityData2 = mergeAvailability2(
    props.availabilityData2.current,
    props.availabilityData2.approved,
    props.availabilityData2.pending
  );

  console.log("MY AVAILABILITY DATA2", myAvailabilityData2)

  // const myPreferencesData2 = mergePreferences2(
  //   props.availabilityData2.current,
  //   props.preferencesData2.current,
  //   props.preferencesData2.approved,
  //   props.preferencesData2.pending
  // );

  const myTimeOff = props.timeOffData.filter(
    (v, i) => v.store_team_member.id === props.myId
  );

  const myTimeOffData = myTimeOff;

  return (
    <div>
      <div className="w-1/2 mt-12 px-6">
        <TabsTwo
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Availability"
          tab2="Time Off"
        />
      </div>
      <div className="w-full mt-2">
        {
          activeTab === 'Availability' ?
            <AvailabilityMineTable
              myAvailabilityData={myAvailabilityData2}
              create_availability_request={props.create_availability_request}
              rescind_availability_request={props.rescind_availability_request}
              permissionRole={props.permissionRole}
              minEffectiveDate={props.minEffectiveDate}
            /> :
          activeTab === 'Time Off' ?
            <TimeOffMineTable
              myTimeOffData={myTimeOffData}
              request_time_off={props.request_time_off}
              rescind_time_off_request={props.rescind_time_off_request}
              archive_time_off_request={props.archive_time_off_request}
              minEffectiveDate={props.minEffectiveDate}
            /> :
          <div></div>
        }
      </div>
    </div>
  )
}
