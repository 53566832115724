import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DailyDatePicker from "./DailyDatePicker";


const date_options = { month: "short", day: "numeric" };

function DateSelector(props) {

  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null); // Create a ref

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]); // Re-run the effect when 'showDailyDatePicker' changes

  return (
    <div ref={datePickerRef} className="relative">
      <div className="flex items-center text-center">
        <div
          className="w-9 h-9 rounded-l-lg border border-slate-200 text-slate-500 flex items-center justify-center hover:bg-slate-50 cursor-pointer"
          onClick={() => props.increment_day(props.date, -1)}
        >
          <ChevronLeftIcon />
        </div>
        <div
          className="w-9 h-9 rounded-r-lg border border-slate-200 text-slate-500 flex items-center justify-center hover:bg-slate-50 cursor-pointer"
          onClick={() => props.increment_day(props.date, 1)}
        >
          <ChevronRightIcon />
        </div>
        <div
          className={`ml-1 px-4 py-4 flex justify-center items-center rounded w-54 h-10 ${showDailyDatePicker ? "text-violet-700" : "text-slate-900 hover:text-slate-900"} bg-white cursor-pointer`}
          onClick={toggleDailyDatePicker}
        >
          <div className="mr-0.5 lg:mr-0 xl:text-sm">
            {props.date.toLocaleDateString("en-us", date_options)}
          </div>
          <div className="text-slate-500">
            {showDailyDatePicker ? <ArrowDropUpIcon style={{ fontSize: "22px" }} /> : <ArrowDropDownIcon style={{ fontSize: "22px" }} />}
          </div>
        </div>
      </div>
      {showDailyDatePicker && (
        <div className="absolute bottom-top left-0 bg-white z-20 flex items-center justify-center pl-4 pr-5 border border-gray-300 rounded shadow-xl overflow-hidden">
          <div className="mt-3">
            <DailyDatePicker
              date={props.date}
              increment_day={props.increment_day}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DateSelector;
