import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function SelectTierScreen(props) {

  const [errors, setErrors] = React.useState({});

  const validateForm = () => {
    const newErrors = {};

    // Validate Selcted Plan
    if (props.onboardingInfo.selectedPlan === '') {
      newErrors.selectedPlan = 'Please selected a plan';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleNextStep = () => {
    const isValid = validateForm();

    if (isValid) {
      // Proceed to the next step
      props.increaseStep();
    }
  };


  return (
    <div>
      <div className="relative">
        <button className="absolute text-gray-100 -left-12 top-1" onClick={props.decreaseStep}>
          <ArrowBackIosIcon style={{ fontSize: "26px" }} />
        </button>
        <h2 className="text-3xl text-gray-100 font-bold">Select a Tier</h2>
        <p className="text-gray-300 mt-5 text-sm">
          RightWork offers a range of comprehensive plans designed to meet the unique needs of your business. Whether you're a single location, a fast-growing chain, or an established brand with 1000s of locations, we have the perfect solution for you.
        </p>
        <p className="text-gray-300 mt-5 text-sm">
          Our plans are flexible and adaptable, allowing you to scale your operations as your business grows. With RightWork, you're never locked into a plan - you can easily upgrade or downgrade at any time to ensure you have the right tools and features to succeed.
        </p>
      </div>
      <div className={`w-full bg-[#252f3f] border-2 ${props.onboardingInfo.selectedPlan === 'Introductory' ? 'border-orange-500' : 'border-[#2f3847]'} rounded-lg p-5 flex items-center justify-center mt-5`} onClick={() => props.setOnboardingInfo({...props.onboardingInfo, selectedPlan: 'Introductory'})}>
        <div className="w-full relative">
          <h2 className="text-gray-100 text-2xl font-bold">Introductory</h2>
          <h3 className="text-base text-gray-100">(Instant Access)</h3>
          <ul className="text-gray-300 text-sm flex flex-col gap-3 mt-3 list-disc ml-8">
            <li>Unlimited users and employees</li>
            <li>Autoscheduling functionality</li>
            <li>60-day refund guarantee</li>
            <li>Mobile app</li>
          </ul>
          <div className="absolute bottom-0 right-0 flex flex-col justify-center items-center text-gray-100">
            <p className="text-3xl">$99</p>
            <p>loc / month</p>
          </div>
        </div>
      </div>
      <div className={`w-full bg-[#252f3f] border-2 ${props.onboardingInfo.selectedPlan === 'Premium' ? 'border-orange-500' : 'border-[#2f3847]'} rounded-lg p-5 flex items-center justify-center mt-5`} onClick={() => props.setOnboardingInfo({...props.onboardingInfo, selectedPlan: 'Premium'})}>
        <div className="w-full relative">
          <h2 className="text-gray-100 text-2xl font-bold">Premium</h2>
          <h3 className="text-base text-gray-100">(Forecasting + Generic Labor Model)</h3>
          <ul className="text-gray-300 text-sm flex flex-col gap-3 mt-3 list-disc ml-8">
            <li>Integration with your point-of-sale system</li>
            <li>Automated precision sales forecasting (product/channel level)</li>
            <li>Generic labor model (can be manually adjusted)</li>
            <li>Custom labor allowance budget</li>
            <li>Unlimited users and employees</li>
            <li>More precise autoscheduling</li>
            <li>60-day refund guarantee</li>
            <li>Mobile app</li>
          </ul>
          <div className="absolute bottom-0 right-0 flex flex-col justify-center items-center text-gray-100">
            <p className="text-3xl">$159</p>
            <p>loc / month</p>
          </div>
        </div>
      </div>
      <div className={`w-full bg-[#252f3f] border-2 ${props.onboardingInfo.selectedPlan === 'Enterprise' ? 'border-orange-500' : 'border-[#2f3847]'} rounded-lg p-5 flex items-center justify-center mt-5`} onClick={() => props.setOnboardingInfo({...props.onboardingInfo, selectedPlan: 'Enterprise'})}>
        <div className="w-full relative">
          <h2 className="text-gray-100 text-2xl font-bold">Enterprise</h2>
          <h3 className="text-base text-gray-100">(Forecasting + Custom Labor Model + Dedicated Team)</h3>
          <ul className="text-gray-300 text-sm flex flex-col gap-3 mt-3 list-disc ml-8">
            <li>Custom labor model (built alongside our data science team)</li>
            <li>White-glove onboarding</li>
            <li>Operations consulting</li>
            <li>Integration with your point-of-sale system</li>
            <li>Automated precision sales forecasting (product/channel level)</li>
            <li>Custom labor allowance budget</li>
            <li>Unlimited users and employees</li>
            <li>The most precise autoscheduling</li>
            <li>Mobile app</li>
          </ul>
          <div className="absolute bottom-0 right-0 flex flex-col justify-center items-center text-gray-100">
            <p className="text-xl">Custom</p>
            <p className="text-xl">pricing</p>
          </div>
        </div>
      </div>
      {errors.selectedPlan && (
        <p className="text-red-500 text-sm mt-1">
          {errors.selectedPlan}
        </p>
      )}
      <div className="w-1/2 mt-8">
        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full" onClick={handleNextStep}>
          Next Step
        </button>
      </div>
    </div>
  )
}
