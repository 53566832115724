import React from 'react'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";


function StatusWithText(props) {
  return (
    <div className="flex flex-row items-center space-x-3">
      <div
        className={`w-9 h-9 rounded-full ${
          props.status === "pending"
            ? "bg-slate-900"
            : props.status === "approved"
            ? "bg-emerald-600"
            : "bg-rose-600"
        } flex items-center justify-center text-white`}
      >
        {props.status === "pending" ? (
          <ImportExportIcon />
        ) : props.status === "approved" ? (
          <CheckIcon />
        ) : (
          <CloseIcon />
        )}
      </div>
      {props.status === "pending" ? (
        <p className="text-slate-900 font-semibold">PENDING</p>
      ) : props.status === "approved" ? (
        <p className="text-emerald-600 font-semibold">APPROVED</p>
      ) : (
        <p className="text-rose-600 font-semibold">DENIED</p>
      )}
    </div>
  )
}

export default StatusWithText
