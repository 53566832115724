import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import LoadSpinner from '../../../utilities/LoadSpinner';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getRightworkAPI } from '../requests/utilities/requests';
import { API_get_learning_quiz_detail } from '../../../constants';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';

function TakeQuizModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    height: "98%",
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    overflowY: "auto",
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [quizDetails, setQuizDetails] = React.useState(null);
  const [step, setStep] = React.useState('welcome');
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [selectedAnswers, setSelectedAnswers] = React.useState([]);
  const [score, setScore] = React.useState(0);
  const [feedback, setFeedback] = React.useState(null);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);




  const fetchQuizDetail = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_quiz_uuid: props.quizSelected?.uuid,
    };
    try {
      const res = await getRightworkAPI(API_get_learning_quiz_detail, api_params);
      if (res.status === 200) {
        setQuizDetails(res.data.redux?.payload?.learning_quiz);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    if (props.quizSelected) {
      fetchQuizDetail(true);
    }
  }, [props.quizSelected]);

  const handleNextQuestion = () => {
    const question = quizDetails.learning_questions[currentQuestionIndex];
    const correctAnswers = question.answers
      .filter(([, is_correct]) => is_correct)
      .map(([answer]) => answer);

    const isCorrect = correctAnswers.every(answer => selectedAnswers.includes(answer)) &&
      selectedAnswers.every(answer => correctAnswers.includes(answer));

    if (isCorrect) {
      setScore(score + 1);
    }

    setFeedback(isCorrect ? 'Correct!' : 'Incorrect!');
    setHasSubmitted(true);
  };

  const handleProceed = () => {
    setFeedback(null);
    setSelectedAnswers([]);
    setHasSubmitted(false); // Reset the submission state for the next question
    if (currentQuestionIndex < quizDetails.learning_questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setStep('completed');
    }
  };

  const handleStartQuiz = () => {
    setStep('question');
  };

  const handleAnswerSelect = (answer) => {
    if (hasSubmitted) return; // Prevent selecting answers after submission
    setSelectedAnswers((prevSelected) =>
      prevSelected.includes(answer)
        ? prevSelected.filter((a) => a !== answer)
        : [...prevSelected, answer]
    );
  };

  const renderWelcome = () => (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="text-slate-900">
        <SchoolOutlinedIcon style={{ fontSize: "80px" }} />
      </div>
      <h3 className="text-3xl text-slate-900 font-semibold mt-8">{quizDetails?.name}</h3>
      {/* <p className="text-slate-500 mt-5 w-80 text-center">
        This quiz will begin when you press the start button. Please give your best effort.
      </p> */}
      <div className="flex items-center gap-x-2 mt-4 text-slate-500">
        <div>
          <ChecklistIcon style={{ fontSize: "22px" }} />
        </div>
        <p className="text-base">
          {quizDetails?.learning_questions?.length} {quizDetails?.learning_questions?.length === 1 ? "question" : "questions"}
        </p>
      </div>
      {quizDetails?.pass_percentage &&
        <div className="flex items-center gap-x-2 mt-3 text-slate-500">
          <div>
            <FlagOutlinedIcon style={{ fontSize: "22px" }} />
          </div>
          <p className="text-base">
            {quizDetails?.pass_percentage ? `${quizDetails?.pass_percentage}% pass percentage` : ""}
          </p>
        </div>
      }
      {quizDetails?.retake_limit &&
        <div className="flex items-center gap-x-2 mt-3 text-slate-500">
          <div>
            <LockOutlinedIcon style={{ fontSize: "22px" }} />
          </div>
          <p className="text-base">
            0 / {quizDetails.retake_limit ? quizDetails?.retake_limit : "UNLIMITED"} attempts
          </p>
        </div>
      }
      <div
        className="px-6 py-2.5 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm cursor-pointer mt-7"
        onClick={handleStartQuiz}
      >
        Get Started
      </div>
    </div>
  );

  const renderQuestion = () => {
    const question = quizDetails.learning_questions[currentQuestionIndex];
    const correctAnswers = question.answers
      .filter(([, is_correct]) => is_correct)
      .map(([answer]) => answer);

    return (
      <div className="w-[50%] sm:w-[85%]">
        <div className="px-3 py-0.5 border border-slate-200 text-slate-500 text-sm rounded-lg w-fit">Multiple Choice</div>
        <h3 className="text-slate-900 text-2xl pt-6 pb-10 border-b border-slate-200">{question.question}</h3>
        <div className="mt-10">
          {question.answers.map(([answer, is_correct], index) => {
            let answerClass = "bg-slate-50 text-slate-600";
            if (hasSubmitted) {
              if (selectedAnswers.includes(answer)) {
                answerClass = is_correct ? "bg-emerald-500 text-white" : "bg-rose-500 text-white";
              } else if (is_correct) {
                answerClass = "bg-emerald-500 text-white";
              }
            }
            return (
              <div
                key={index}
                className={`p-4 rounded-lg flex items-center space-x-5 mt-3 cursor-pointer ${answerClass}`}
                onClick={() => handleAnswerSelect(answer)}
              >
                <div className={`w-5 h-5 rounded ${selectedAnswers.includes(answer) ? 'bg-violet-700' : 'bg-white'} border border-slate-300 flex items-center justify-center`}>
                  <div className="text-white">{selectedAnswers.includes(answer) ? <CheckIcon style={{ fontSize: "18px", marginBottom: "2px" }} /> : ""}</div>
                </div>
                <p>{answer}</p>
              </div>
            );
          })}
        </div>
        <div className="w-full flex items-center justify-between mt-8">
          <div className="flex items-center space-x-3">
            <div
              className={`w-fit px-6 py-3 rounded-lg ${!hasSubmitted ? "bg-violet-700 hover:bg-violet-800 text-white cursor-pointer" : "bg-slate-200 text-slate-400"} text-sm`}
              onClick={handleNextQuestion}
            >
              Submit
            </div>
            {hasSubmitted &&
              <div
                className="w-fit px-6 py-3 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm cursor-pointer flex items-center justify-center gap-x-1.5"
                onClick={() => {
                  if (currentQuestionIndex === quizDetails.learning_questions?.length - 1) {
                    props.post_learning_block_user_passed(quizDetails.uuid, true, props.employeeID)
                  }
                  handleProceed()
                }}
              >
                <p>{currentQuestionIndex === quizDetails.learning_questions?.length - 1 ? "Results" : "Next"}</p>
                <div>
                  {currentQuestionIndex === quizDetails.learning_questions?.length - 1 ? <ChecklistIcon style={{ fontSize: "20px" }} /> : <ChevronRightIcon style={{ fontSize: "20px" }} />}
                </div>
              </div>
            }
          </div>
          <div>
            {feedback &&
              <div className="flex items-center space-x-2">
                <div className={`w-6 h-6 rounded-full flex items-center justify-center text-white ${feedback === 'Correct!' ? 'bg-emerald-500' : 'bg-rose-500'}`}>
                  {feedback === "Correct!" ? <CheckIcon /> : <CloseIcon />}
                </div>
                <p className={`text-lg ${feedback === 'Correct!' ? 'text-emerald-600' : 'text-rose-600'}`}>{feedback}</p>
              </div>}
          </div>
        </div>
      </div>
    );
  };

  const renderCompleted = () => (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h3 className="text-3xl text-slate-900 font-semibold">Quiz Completed!</h3>
      <p className="text-slate-500 mt-5 w-80 text-center">
        Thank you for completing the quiz. Your score is {score} out of {quizDetails.learning_questions.length}.
      </p>
    </div>
  );


  return (
    <Modal
      open={props.open}
      onClose={() => props.handleClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-2 top-2 text-gray-500 cursor-pointer z-40"
          onClick={() => props.handleClose()}
        >
          <CloseIcon />
        </div>
        {!isLoading ? (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="py-5 w-full flex justify-center shadow">
              <p className="text-slate-900 text-base">{quizDetails?.name}</p>
            </div>
            {step !== "welcome" ?
              <div className="w-full bg-slate-100 h-16 flex items-center justify-center shadow-inner">
                <div className="flex items-center space-x-5">
                  {quizDetails?.learning_questions?.map((dot, index) => (
                    <div key={index}>
                      {index === currentQuestionIndex ? (
                        <div className="w-4 h-4 rounded-full border border-violet-700 flex items-center justify-center bg-slate-100">
                          <div className="w-2 h-2 rounded-full bg-violet-700"></div>
                        </div>
                      ) : (
                        <div className="w-2 h-2 rounded-full bg-slate-600"></div>
                      )}
                    </div>
                  ))}
                </div>
              </div> :
              <div className="w-full h-2 bg-slate-100 shadow-inner"></div>
            }
            <div className="flex-1 w-full flex items-center justify-center">
              {step === 'welcome' && renderWelcome()}
              {step === 'question' && renderQuestion()}
              {step === 'completed' && renderCompleted()}
            </div>
          </div>
        ) : (
          <div className="w-full h-[400px]">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <LoadSpinner />
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default TakeQuizModal;
