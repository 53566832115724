import React from 'react'

function DateGrayLine(props) {
  return (
    <div className="w-full px-4 py-4 bg-white border-b border-slate-200">
      <p className="text-slate-600">{props.date}</p>
    </div>
  )
}

export default DateGrayLine
