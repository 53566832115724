import React from "react";
import { AvailabilityGraphD3 } from "./AvailabilityGraphD3";

export function AvailabilityGraph({
  availability,
  forecasted_labor,
  chartid,
  minOpenTime,
  maxCloseTime,
  roleType,
}) {
  const containerRef = React.useRef(null);

  function aggregateAvailability(employeeAvailability) {
    let availabilityByQuarterHour = {};

    employeeAvailability.forEach((employee) => {
      Object.keys(employee).forEach((key) => {
        if (key.includes("_availability")) {
          employee[key].forEach((availabilityEntry) => {
            // First availability range (available_from, available_to)
            for (
              let qh = availabilityEntry.available_from;
              qh < availabilityEntry.available_to;
              qh += 0.25
            ) {
              let qh_key = `${availabilityEntry.date}_${qh.toFixed(2)}`;
              if (!availabilityByQuarterHour[qh_key]) {
                availabilityByQuarterHour[qh_key] = 0;
              }
              availabilityByQuarterHour[qh_key]++;
            }

            // Second availability range (available_from_2, available_to_2) if defined
            if (
              availabilityEntry.available_from_2 !== undefined &&
              availabilityEntry.available_to_2 !== undefined
            ) {
              for (
                let qh = availabilityEntry.available_from_2;
                qh < availabilityEntry.available_to_2;
                qh += 0.25
              ) {
                let qh_key = `${availabilityEntry.date}_${qh.toFixed(2)}`;
                if (!availabilityByQuarterHour[qh_key]) {
                  availabilityByQuarterHour[qh_key] = 0;
                }
                availabilityByQuarterHour[qh_key]++;
              }
            }
          });
        }
      });
    });

    return Object.keys(availabilityByQuarterHour).map((key) => {
      const [date, qh_num] = key.split("_");
      return {
        date,
        qh_num: parseFloat(qh_num),
        people_available: availabilityByQuarterHour[key],
      };
    });
  }

  function aggregateEmployeesNeeded(employeesNeeded) {
    let neededByQuarterHour = {};

    employeesNeeded.forEach((entry) => {
      let qh_key = `${entry.date}_${entry.qh_num}`;
      if (!neededByQuarterHour[qh_key]) {
        neededByQuarterHour[qh_key] = 0;
      }
      neededByQuarterHour[qh_key] += entry.hours * 4;
    });

    return Object.keys(neededByQuarterHour).map((key) => {
      const [date, qh_num] = key.split("_");
      return {
        date,
        qh_num: parseFloat(qh_num),
        labor_needed: neededByQuarterHour[key],
      };
    });
  }

  function getBufferPercentage(people_available, labor_needed) {
    if (people_available === 0) {
      return 0;
    }
    const bufferPercentage =
      ((people_available - labor_needed) / people_available) * 100;
    return bufferPercentage;
  }

  function getHeatMapColor(bufferPercentage, labor_needed) {
    if (labor_needed === 0) {
      return "gray";
    } else if (bufferPercentage <= 20) {
      return "red";
    } else if (bufferPercentage <= 30) {
      return "orange";
    } else if (bufferPercentage <= 40) {
      return "light orange";
    } else if (bufferPercentage <= 50) {
      return "yellow";
    } else if (bufferPercentage <= 60) {
      return "lime";
    } else {
      return "green";
    }
  }

  function joinAggregatedDataSets(
    aggregatedAvailability,
    aggregatedEmployeesNeeded
  ) {
    // Identify all unique dates from both datasets
    const uniqueDates = new Set([
      ...aggregatedAvailability.map((a) => a.date),
      ...aggregatedEmployeesNeeded.map((e) => e.date),
    ]);

    // Generate all qh_nums from 0 to 23.75 in 0.25 increments
    const qhNums = Array.from(
      { length: (maxCloseTime - minOpenTime) * 4 },
      (_, i) => minOpenTime + i * 0.25
    );

    // For each date and each qh_num, find or default the data
    let allData = [];
    uniqueDates.forEach((date) => {
      qhNums.forEach((qh_num) => {
        const qh_numFloat = qh_num; // Convert back to number for matching
        const availability = aggregatedAvailability.find(
          (a) => a.date === date && a.qh_num === qh_numFloat
        ) || { people_available: 0 };
        const need = aggregatedEmployeesNeeded.find(
          (e) => e.date === date && e.qh_num === qh_numFloat
        ) || { labor_needed: 0 };

        allData.push({
          date: date,
          qh_num: qh_numFloat,
          labor_needed: need.labor_needed,
          people_available: availability.people_available,
          buffer: availability.people_available - need.labor_needed,
          bufferPercentage: getBufferPercentage(
            availability.people_available,
            need.labor_needed
          ),
          color: getHeatMapColor(
            getBufferPercentage(
              availability.people_available,
              need.labor_needed
            ),
            need.labor_needed
          ),
        });
      });
    });

    // Sort allData by date and qh_num to ensure order
    allData.sort((a, b) => {
      return a.date?.localeCompare(b.date) || a.qh_num - b.qh_num;
    });

    return allData;
  }

  const aggregatedAvailability = aggregateAvailability(availability);
  const aggregatedEmployeesNeeded = aggregateEmployeesNeeded(forecasted_labor);
  const joinedData = joinAggregatedDataSets(
    aggregatedAvailability,
    aggregatedEmployeesNeeded
  );

  React.useEffect(() => {
    let destroyFn;

    if (containerRef.current) {
      const { destroy } = AvailabilityGraphD3(
        containerRef.current,
        joinedData,
        minOpenTime,
        maxCloseTime,
        chartid
      );
      destroyFn = destroy;
    }

    return destroyFn;
  }, [joinedData]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <section className="Main" style={{ textAlign: "center" }}>
        <div id={chartid} ref={containerRef} />
      </section>
    </div>
  );
}
