import "../../../App.css";
import React, { useState, useEffect } from "react";
import Table from "./settings_table/Table";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import Edit from "./edit_labor_model/Edit.js";
import Visualize from "./Visualize.js";
import Compare from "./Compare.js";
import CheckIcon from "@mui/icons-material/Check";
import ApplyModal from "./ApplyModal.js";
import SaveAsModal from "./SaveAsModal.js";
import TabsThree from "../requests/shared/helpers/TabsThree";
import TopBarDisplayStoreOnly from "../home/top_bar_components/TopBarDisplayStoreOnly";

import axios from "axios";
import {
  API_get_store_variable_flags,
  API_get_store_intraday_timings,
  API_get_store_driver_variables,
  API_get_default_operating_hours,
  API_get_role_types,
  API_get_tasks,
  API_get_plays,
  API_get_intraday_timings,
  API_get_routine_role_assignments,
  API_get_routines,
  API_get_driver_variables,
  API_get_sales_group_calcs,
  API_get_deployment_roles,
  API_put_store_variable_flag,
  API_put_store_intraday_timing,
  API_put_store_driver_variable,
  API_put_default_operating_hour,
  API_put_role_type,
  API_put_task,
  API_put_play,
  API_put_intraday_timing,
  API_put_routine_role_assignment,
  API_put_routine,
  API_put_driver_variable,
  API_put_sales_group_calc,
  API_put_deployment_role,
} from "../../../constants";

import { getDefaultAxiosConfig } from "../../../utilities/requests";
const axiosConfig = getDefaultAxiosConfig();

function SettingsMain(props) {
  const [display, setDisplay] = React.useState("default_operating_hours");
  const [settingsData, setSettingsData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [tableLoading, setTableLoading] = React.useState(true);

  const store_id = props.homeStore;

  function get_foreign_key_options(table, value_col, label_col) {
    let options_dict = {};
    if (table in settingsData) {
      settingsData[table].forEach((element) => {
        options_dict[parseInt(element[value_col])] = element[label_col];
      });
    }
    return options_dict;
  }

  const schema_info = {
    role_types: {
      table: "role_types",
      title: "Role Types",
      get_api: API_get_role_types,
      put_api: API_put_role_type,
      description: "A list of all your current role types.",
      add_row: false,
      order_by: "role_type",
      column_info: [
        {
          header: "Role Type",
          column_name: "role_type",
          column_type: "",
          column_select_options: {},
          width: "3/12",
          header_css: "pl-4",
          disabled: true,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    tasks: {
      table: "tasks",
      title: "Tasks",
      get_api: API_get_tasks,
      put_api: API_put_task,
      description: "A list of all your current tasks.",
      add_row: true,
      order_by: "task_name",
      column_info: [
        {
          header: "Task",
          column_name: "task_name",
          column_type: "",
          column_select_options: {},
          width: "1/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Routine",
          column_name: "routine_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "routines",
            "id",
            "routine_name"
          ),
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Fixed Time",
          column_name: "fixed_time",
          column_type: "",
          column_select_options: {},
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Driver",
          column_name: "driver_variable_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "driver_variables",
            "id",
            "driver_variable_name"
          ),
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Driver Scale",
          column_name: "driver_variable_scale",
          column_type: "",
          column_select_options: {},
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Minutes Per Driver",
          column_name: "minutes_per_driver",
          column_type: "",
          column_select_options: {},
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Variable Flag",
          column_name: "variable_flag",
          column_type: "",
          column_select_options: {},
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Frequency",
          column_name: "frequency",
          column_type: "select",
          column_select_options: {
            Daily: "Daily",
            Weekly: "Weekly",
            Monthly: "Monthly",
            Quarterly: "Quarterly",
          },
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Calculated At Frequency",
          column_name: "calculated_at_frequency",
          column_type: "select",
          column_select_options: {
            QuarterHourly: "QuarterHourly",
            Daily: "Daily",
          },
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    routines: {
      table: "routines",
      title: "Routines",
      get_api: API_get_routines,
      put_api: API_put_routine,
      description: "A list of all your routines.",
      add_row: true,
      order_by: "routine_name",
      column_info: [
        {
          header: "Routine",
          column_name: "routine_name",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Coverage Type",
          column_name: "coverage_type",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Production Type",
          column_name: "production_type",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Intraday Timing",
          column_name: "intraday_timing_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "intraday_timings",
            "id",
            "intraday_timing"
          ),
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Role Type",
          column_name: "role_type_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "role_types",
            "id",
            "role_type"
          ),
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Assigned",
          column_name: "assigned_yes_no",
          column_type: "select",
          column_select_options: { 0: 0, 1: 1 },
          width: "1/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    driver_variables: {
      table: "driver_variables",
      title: "Driver Variables",
      get_api: API_get_driver_variables,
      put_api: API_put_driver_variable,
      description: "A list of all your labor drivers.",
      add_row: true,
      order_by: "driver_variable_name",
      column_info: [
        {
          header: "Driver Variable",
          column_name: "driver_variable_name",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Driver Variable Type",
          column_name: "driver_variable_type",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    sales_group_calcs: {
      table: "sales_group_calcs",
      title: "Sales Group Calcs",
      get_api: API_get_sales_group_calcs,
      put_api: API_put_sales_group_calc,
      description: "A list of all your sales group calculations.",
      add_row: true,
      order_by: "driver_variable",
      column_info: [
        {
          header: "Driver Variable",
          column_name: "driver_variable_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "driver_variables",
            "id",
            "driver_variable_name"
          ),
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Filter Column",
          column_name: "filter_column",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Filter Value",
          column_name: "filter_value",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Aggregate Column",
          column_name: "agg_column",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Aggregate Function",
          column_name: "agg_func",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    intraday_timings: {
      table: "intraday_timings",
      title: "Intraday Timings",
      get_api: API_get_intraday_timings,
      put_api: API_put_intraday_timing,
      description: "A list of all your intraday timings.",
      add_row: true,
      order_by: "intraday_timing",
      column_info: [
        {
          header: "Intraday Timing",
          column_name: "intraday_timing",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    deployment_roles: {
      table: "deployment_roles",
      title: "Deployment Roles",
      get_api: API_get_deployment_roles,
      put_api: API_put_deployment_role,
      description: "A list of all your deployment roles.",
      add_row: true,
      order_by: "deployment_role_name",
      column_info: [
        {
          header: "Deployment Role",
          column_name: "deployment_role_name",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Role Type",
          column_name: "role_type_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "role_types",
            "id",
            "role_type"
          ),
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    routine_role_assignments: {
      table: "routine_role_assignments",
      title: "Routine Role Assignments",
      get_api: API_get_routine_role_assignments,
      put_api: API_put_routine_role_assignment,
      description: "A list of all your routine role assignments.",
      add_row: true,
      order_by: "routine_id",
      column_info: [
        {
          header: "Routine",
          column_name: "routine_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "routines",
            "id",
            "routine_name"
          ),
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Deployment Role",
          column_name: "deployment_role_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "deployment_roles",
            "id",
            "deployment_role_name"
          ),
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Priority",
          column_name: "priority",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    plays: {
      table: "plays",
      title: "Plays",
      get_api: API_get_plays,
      put_api: API_put_play,
      description: "A list of all your plays.",
      add_row: true,
      order_by: "play_num",
      column_info: [
        {
          header: "Play Number",
          column_name: "play_num",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Deployment Role",
          column_name: "deployment_role_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "deployment_roles",
            "id",
            "deployment_role_name"
          ),
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Role Count",
          column_name: "role_cnt",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Role Type",
          column_name: "role_type_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "role_types",
            "id",
            "role_type"
          ),
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    default_operating_hours: {
      table: "default_operating_hours",
      title: "Hours",
      get_api: API_get_default_operating_hours,
      put_api: API_put_default_operating_hour,
      description: "A list of all your current hours.",
      add_row: true,
      order_by: "dow",
      column_info: [
        {
          header: "DayName",
          column_name: "dow",
          column_type: "",
          column_select_options: {},
          width: "4/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Open",
          column_name: "open_time",
          column_type: "",
          column_select_options: {},
          width: "4/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Close",
          column_name: "close_time",
          column_type: "",
          column_select_options: {},
          width: "3/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    store_variable_flags: {
      table: "store_variable_flags",
      title: "Store Variable Flags",
      get_api: API_get_store_variable_flags,
      put_api: API_put_store_variable_flag,
      description: "A list of all your store's variable flags.",
      add_row: true,
      order_by: "variable_flag",
      column_info: [
        {
          header: "Variable Flag",
          column_name: "variable_flag",
          column_type: "",
          column_select_options: {},
          width: "4/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Variable Flag Value",
          column_name: "variable_flag_value",
          column_type: "",
          column_select_options: {},
          width: "4/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    store_intraday_timings: {
      table: "store_intraday_timings",
      title: "Store Intraday Timings",
      get_api: API_get_store_intraday_timings,
      put_api: API_put_store_intraday_timing,
      description: "A list of all your store's intraday timings.",

      add_row: true,
      order_by: "intraday_timing",
      column_info: [
        {
          header: "Intraday Timing",
          column_name: "intraday_timing_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "intraday_timings",
            "id",
            "intraday_timing"
          ),
          width: "2/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Timing Fixed Start",
          column_name: "timing_fixed_start",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Timing Fixed Stop",
          column_name: "timing_fixed_stop",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Timing Dynamic Start",
          column_name: "timing_dynamic_start",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Timing Dynamic Stop",
          column_name: "timing_dynamic_stop",
          column_type: "",
          column_select_options: {},
          width: "2/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
    store_driver_variables: {
      table: "store_driver_variables",
      title: "Store Driver Variables",
      get_api: API_get_store_driver_variables,
      put_api: API_put_store_driver_variable,
      description: "A list of all your store's driver variables.",
      add_row: true,
      order_by: "driver_variable",
      column_info: [
        {
          header: "Driver Variable",
          column_name: "driver_variable_id",
          column_type: "select",
          column_select_options: get_foreign_key_options(
            "driver_variable",
            "id",
            "driver_variable_name"
          ),
          width: "3/12",
          header_css: "pl-4",
          disabled: false,
        },
        {
          header: "Driver Variable Value",
          column_name: "driver_variable_value",
          column_type: "",
          column_select_options: {},
          width: "3/12",
          header_css: "",
          disabled: false,
        },
        {
          header: "Action",
          column_name: "action_button",
          width: "1/12",
          header_css: "pr-4 text-right",
          disabled: true,
        },
      ],
    },
  };

  const table_names = Object.keys(schema_info);
  const default_api_calls = table_names.map(
    (table, index) => schema_info[table]["get_api"]
  );

  async function get_data(api) {
    const res = await axios.get(api + store_id, axiosConfig);
    return res;
  }

  const fetch_data = async (api_calls, set_data_names, load_screen) => {
    try {
      setLoading(load_screen);
      const res = await Promise.all(api_calls.map((api) => get_data(api)));
      var data = { ...settingsData };
      res.map((res, i) => (data[set_data_names[i]] = res.data));
      setSettingsData(data);
      setLoading(false);
      setTableLoading((prevState) => !prevState);
    } catch {
      throw Error("Promise failed");
    }
  };

  console.log(settingsData);

  function edit_settings(put_api, get_api, table, data, edit_type) {
    data["store_id"] = store_id;
    console.log(data);
    axios
      .put(put_api + store_id + "&edit_type=" + edit_type, data, axiosConfig)
      .then(() => {
        fetch_data([get_api], [table], false);
      });
  }

  useEffect(() => {
    if (typeof store_id !== "undefined") {
      fetch_data(default_api_calls, table_names, true);
    }
  }, [store_id]);

  function handleTabClick(table) {
    setDisplay(table);
  }

  function display_tab_button(table) {
    return (
      <button
        className={`flex justify-center items-center text-xs px-5 ${
          display === table && "h-16 border-b-4 border-gray-900"
        }`}
        onClick={() => handleTabClick(table)}
      >
        <h4
          className={
            display === table ? "text-gray-900 font-bold" : "text-gray-500"
          }
        >
          {schema_info[table].title}
        </h4>
      </button>
    );
  }

  const [version, setVersion] = React.useState("");

  const handleChange = (event) => {
    setVersion(event.target.value);
  };

  const [activeTab, setActiveTab] = React.useState("Edit");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  const [openApplyModal, setOpenApplyModal] = React.useState(false);
  const handleOpenApplyModal = () => setOpenApplyModal(true);
  const handleCloseApplyModal = () => setOpenApplyModal(false);

  const [openSaveAsModal, setOpenSaveAsModal] = React.useState(false);
  const handleOpenSaveAsModal = () => setOpenSaveAsModal(true);
  const handleCloseSaveAsModal = () => setOpenSaveAsModal(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="w-full px-10 my-7">
        <TopBarDisplayStoreOnly
          storeOptions={props.storeOptions}
          homeStore={props.homeStore}
          storeNames={props.storeNames}
          change_home_store={props.change_home_store}
        />
      </div>
      <div className="flex flex-col px-28 min-h-screen">
        <h1 className="text-3xl font-bold">Labor Model</h1>
        <div className="flex items-center justify-between gap-8 mt-10">
          <div className="flex items-center gap-12">
            <div>
              <Box sx={{ width: 250 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Version</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={version}
                    label="Version"
                    onChange={handleChange}
                  >
                    <MenuItem value={"Current Version"}>
                      Current Version
                    </MenuItem>
                    <MenuItem value={"Version 2"}>Version 2</MenuItem>
                    <MenuItem value={"Version 3"}>Version 3</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className="flex items-center gap-4">
              <button className="px-8 py-1.5 bg-primary hover:bg-blue-900 rounded text-white">
                Save
              </button>
              <button
                className="px-8 py-1.5 bg-primary hover:bg-blue-900 rounded text-white"
                onClick={handleOpenSaveAsModal}
              >
                Save As
              </button>
              <p className="ml-4 text-sm">0 Unsaved Changes</p>
            </div>
          </div>
          <div>
            <button
              className="flex items-center gap-3 bg-primary hover:bg-blue-900 text-white px-8 py-2 rounded"
              onClick={handleOpenApplyModal}
            >
              <CheckIcon />
              <p>Apply As Current Labor Model</p>
            </button>
          </div>
        </div>
        {/* <div className="mt-12">
          <Divider />
        </div> */}
        <div className="pb-20 mt-12">
          <div className="w-1/2 mt-5">
            <TabsThree
              activeTab={activeTab}
              handleTabButtonPress={handleTabButtonPress}
              tab1="Edit"
              tab2="Visualize"
              tab3="Compare"
            />
          </div>
          {/*        <div className="w-full mt-2">
            {!loading ? (
              activeTab === "Edit" ? (
                <div className="mt-8">
                  <Edit settingsData={settingsData} />
                </div>
              ) : activeTab === "Visualize" ? (
                <div className="mt-12">
                  <Visualize />
                </div>
              ) : activeTab === "Compare" ? (
                <div className="mt-12">
                  <Compare version={version} />
                </div>
              ) : (
                <div></div>
              )
            ) : (
              <div></div>
            )}
            </div> */}
          {
            <div>
              <div className="w-full flex justify-around mt-10 h-16 border-b-2 border-gray-500">
                {table_names.map(function (table, index) {
                  return display_tab_button(table);
                })}
              </div>
              <div>
                {!loading ? (
                  <Table
                    settingsData={settingsData}
                    edit_settings={edit_settings}
                    schema_info={schema_info}
                    display={display}
                    tableLoading={tableLoading}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          }
        </div>
      </div>
      <ApplyModal
        open={openApplyModal}
        handleClose={handleCloseApplyModal}
        style={style}
        version={version}
      />
      <SaveAsModal
        open={openSaveAsModal}
        handleClose={handleCloseSaveAsModal}
        style={style}
      />
    </>
  );
}

export default SettingsMain;
