import React from "react";
import Stack from "@mui/material/Stack";
import WeekSelector from "./WeekSelector";
import ScheduleTypeFilter from "./ScheduleTypeFilter";
import WeeklyDatePicker from "./WeeklyDatePicker";

function WeekScheduleTypeGrouping(props) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <WeekSelector
          start_date={props.start_date}
          end_date={props.end_date}
          increment_week={props.increment_week}
          set_week_start_date={props.set_week_start_date}
          businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        />
        {/* <ScheduleTypeFilter
          roleType={props.roleType}
          setRoleType={props.setRoleType}
          roleTypeOptions={props.roleTypeOptions}
        /> */}
      </div>
    </div>
  );
}

export default WeekScheduleTypeGrouping;
