import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

function NotificationsCard(props) {

  function getTimeStamp(timestamp) {
    // Parse the input timestamp into a Date object
    const date = new Date(timestamp);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - date;

    // Convert milliseconds to minutes
    const minutesAgo = Math.floor(timeDifference / (1000 * 60));

    // Convert minutes to hours
    const hoursAgo = Math.floor(minutesAgo / 60);

    // Convert hours to days
    const daysAgo = Math.floor(hoursAgo / 24);

    if (minutesAgo < 60) {
      return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
    } else if (hoursAgo < 24) {
      return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
    } else if (daysAgo === 1) {
      return 'Yesterday';
    } else {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
      return formattedDate;
    }
  }

  return (
    <div className="w-full py-5 border-b border-gray-300 flex items-center relative">
      <div className="flex-1">
        <div className="flex justify-between">
          <h4 className="font-bold text-slate-900 text-xs">{props.item.notification.title}</h4>
          <p className="text-xs text-slate-900">{getTimeStamp(props.item.notification.created_at)}</p>
        </div>
        <p className="text-gray-500 text-xs mt-1.5 w-3/4">{props.item.notification.body}</p>
      </div>
      <div className="absolute bottom-1 right-0">
        <Tooltip title="Archive">
          <IconButton
            className="text-gray-500 ml-4"
            sx={{ backgroundColor: "#f1f5f9" }}
            onClick={() => props.archive_drawer_notification(props.item.uuid)}
          >
            <ArchiveOutlinedIcon style={{ fontSize: "20px" }}/>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default NotificationsCard
