import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import { formatDateReadable } from "../../utilities/helpers";
import { getDayOfWeek } from "../../utilities/helpers";
import { getInitials } from "../../utilities/helpers";
import CloseIcon from "@mui/icons-material/Close";
import { get_month_and_day } from "../../utilities/helpers";
import { formatDateShift } from "../../utilities/helpers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShiftPickupModal from "./ShiftPickupModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Drawer } from "@mui/material";
import DrawerHeader from "../helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../helpers/DrawerFooterContainerSmall";
import ButtonGrayed from "../helpers/ButtonGrayed";
import ButtonFilled from "../helpers/ButtonFilled";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";

export default function ShiftPoolTableRow(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const shiftInfo = props.shiftInfo;

  let badgeText = "";
  if (shiftInfo?.badge === "is_pending_drop_by_user" && props.shiftPool) {
    badgeText = "Your Shift";
  } else if (shiftInfo?.badge === "is_pending_drop_by_user") {
    badgeText = "Drop Requested";
  } else if (
    shiftInfo?.badge === "is_trade_by_user_pending_employee_response"
  ) {
    badgeText = "Offered Trade";
  } else if (shiftInfo?.badge === "is_trade_by_user_pending_manager_response") {
    badgeText = "Offered Trade";
  } else if (shiftInfo?.badge === "is_trade_to_user_pending_self_response") {
    badgeText = "";
  } else if (shiftInfo?.badge === "is_trade_to_user_pending_manager_response") {
    badgeText = "";
  } else if (
    shiftInfo?.badge === "is_pending_pickup_by_user" &&
    props.shiftPool
  ) {
    badgeText = "Pickup Requested";
  } else {
    badgeText = "";
  }

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handlePickup = () => {
    props.create_pickup_request(props.shiftInfo.id);
    setDrawerOpen(false);
  };

  return (
    <>
      <tr
        className="hover:bg-slate-50 cursor-pointer"
        onClick={toggleDrawer(true)}
      >
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500 pl-6">
          <div className="flex items-center space-x-2">
            <div
              className={`${
                props.shiftInfo.status === "PENDING_DROP"
                  ? "text-rose-600"
                  : "text-emerald-600"
              }`}
            >
              {props.shiftInfo.status === "PENDING_DROP" ? (
                <RemoveCircleIcon style={{ fontSize: "14px" }} />
              ) : (
                <AddCircleIcon style={{ fontSize: "14px" }} />
              )}
            </div>
            <div
              className={`font-medium ${
                props.shiftInfo.status === "PENDING_DROP"
                  ? "text-rose-900"
                  : "text-emerald-800"
              }`}
            >
              {props.shiftInfo.status === "PENDING_DROP"
                ? "Dropped by"
                : "Open shift"}
            </div>
          </div>
          {props.shiftInfo.status === "PENDING_DROP" && (
            <div className="flex items-center space-x-2 -ml-1">
              <div className="w-5 h-5 bg-slate-100 rounded-full flex items-center justify-center">
                <p className="text-slate-500 text-xxxs">
                  {getInitials(props.shiftInfo.name)}
                </p>
              </div>
              <p className="text-slate-500">{props.shiftInfo.name}</p>
            </div>
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900">
            {formatDateShift(props.shiftInfo.date)}
          </div>
          <div className="text-slate-500">{props.shiftInfo.shift}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
          <div className="text-gray-900">
            {props.shiftInfo.station
              ? props.shiftInfo.station?.name
              : props.shiftInfo.role
              ? props.shiftInfo.role
              : props.shiftInfo.roleType}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-slate-500">
          {props.shiftInfo.employee_drop_note && (
            <div>
              <Tooltip title={props.shiftInfo.employee_drop_note}>
                <CommentIcon />
              </Tooltip>
            </div>
          )}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium text-slate-500">
          {badgeText ? (
            <div className="flex justify-end">
              <div className="text-amber-800 bg-amber-50 px-2 py-1 rounded-lg flex items-center justify-center space-x-1">
                {/* {badgeText === "Pickup Requested" &&
                  <Tooltip title={"Cancel Pickup Request"}>
                    <button
                      className="flex items-center justify-center text-red-600"
                      onClick={() => props.rescind_pickup_shift(props.shiftInfo.id)}
                    >
                      <HighlightOffIcon style={{ fontSize: "16px" }}/>
                    </button>
                  </Tooltip>
                } */}
                <WarningAmberIcon style={{ fontSize: "14px" }} />
                <p className="text-amber-800">{badgeText}</p>
              </div>
            </div>
          ) : (
            <div className="flex justify-end">
              <div className="text-slate-900">None</div>
            </div>
          )}
        </td>
      </tr>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <h2 className="text-2xl font-semibold">Selected Shift</h2>
          <div>
            <div className="mt-3">
              <div className="w-full rounded-2xl bg-white border border-slate-200 px-5 py-6 flex flex-row items-center">
                <div className="flex-1">
                  <div>
                    <p className="text-slate-600">{formatDateShift(props.shiftInfo.date)}</p>
                  </div>
                  <div className="flex flex-row items-center mt-1.5">
                    <p className="text-slate-900 text-[28px] font-bold">{props.shiftInfo?.shift_start_formatted[0]}</p>
                    <p className="text-slate-600 text-lg ml-1">{props.shiftInfo?.shift_start_formatted[1]}</p>
                    <div className="ml-3 text-slate-600">
                      <ArrowForwardIcon color="#475569" size={22} />
                    </div>
                    <p className="text-slate-900 text-[28px] font-bold ml-3">{props.shiftInfo?.shift_end_formatted[0]}</p>
                    <p className="text-slate-600 text-lg ml-1">{props.shiftInfo?.shift_end_formatted[1]}</p>
                  </div>
                  <div className="mt-1.5">
                    <p className="text-slate-600">{props.shiftInfo.role} @ {props.shiftInfo.location}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-6 flex justify-end">
              <div className="flex items-center gap-5">
                <button
                  className="px-8 py-3 rounded-lg bg-violet-900 hover:bg-violet-800 text-white text-sm"
                  onClick={() => handleSubmit()}
                >
                  Request Pickup
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal> */}
      <ShiftPickupModal
        open={open}
        handleClose={handleClose}
        shiftInfo={shiftInfo}
        badgeText={badgeText}
        date={formatDateShift(props.shiftInfo.date)}
        startShiftNumber={props.shiftInfo?.shift_start_formatted[0]}
        startShiftLetters={props.shiftInfo?.shift_start_formatted[1]}
        endShiftNumber={props.shiftInfo?.shift_end_formatted[0]}
        endShiftLetters={props.shiftInfo?.shift_end_formatted[1]}
        role={props.shiftInfo.role}
        roleType={props.shiftInfo.roleType}
        location={props.shiftInfo.location}
        create_pickup_request={props.create_pickup_request}
        rescind_pickup_shift={props.rescind_pickup_shift}
      />
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-full relative">
          <DrawerHeader title="Request Pickup" onClose={toggleDrawer(false)} />
          {badgeText && (
            <div className="w-full mt-6 px-6">
              <div className="flex flex-row items-center justify-between bg-amber-50 px-6 py-4 rounded-2xl">
                <div className="flex flex-row items-center space-x-3 text-amber-800">
                  <WarningAmberIcon />
                  <p className="text-amber-800 text-center text-base">
                    {badgeText}
                  </p>
                </div>
                {badgeText === "Pickup Requested" && (
                  <div
                    className="px-4 py-2 bg-yellow-600 hover:bg-yellow-700 rounded-xl cursor-pointer"
                    onClick={() =>
                      props.rescind_pickup_shift(props.shiftInfo.id)
                    }
                  >
                    <p className="text-white">Cancel</p>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="w-full px-6">
            <div className="w-full mt-6 pb-1">
              <p className="text-slate-500 text-base">Date</p>
              <p className="mt-1 text-xl text-slate-900">
                {formatDateReadable(props.shiftInfo.date)}
              </p>
            </div>
            <div className="w-full mt-3 pb-1">
              <p className="text-slate-500 text-base">Time</p>
              <p className="mt-1 text-xl text-slate-900">
                {props.shiftInfo.shift}
              </p>
            </div>
            <div className="w-full mt-3 pb-1">
              <p className="text-slate-500 text-base">Break</p>
              <p className="mt-1 text-xl text-slate-900">
                {props.shiftInfo.break_formatted}
              </p>
            </div>
            <div className="w-full mt-3 pb-1">
              <p className="text-slate-500 text-base">Role</p>
              <p className="mt-1 text-xl text-slate-900">
                {props.shiftInfo.station
                  ? props.shiftInfo.station?.name
                  : props.shiftInfo.role
                  ? props.shiftInfo.role
                  : props.shiftInfo.roleType}
              </p>
            </div>
            <div className="w-full mt-3 pb-1">
              <p className="text-slate-500 text-base">Note</p>
              <p className="mt-1 text-xl text-slate-900">
                {props.shiftInfo.employee_drop_note
                  ? props.shiftInfo.employee_drop_note
                  : "No note"}
              </p>
            </div>
            {props.shiftInfo.status === "PENDING_DROP" &&
            badgeText !== "Your Shift" ? (
              <div className="w-full mt-8 pb-5">
                <div className="flex flex-row items-center space-x-2">
                  <div className="text-rose-600">
                    <RemoveCircleIcon />
                  </div>
                  <p className="text-rose-800 text-xl">Dropped by</p>
                  <div className="pl-3">
                    <p className="text-slate-500 text-xl">
                      {props.shiftInfo.name}
                    </p>
                  </div>
                </div>
              </div>
            ) : props.shiftInfo.status === "AVAILABLE" ? (
              <div className="w-full mt-8 pb-5">
                <div className="flex flex-row items-center space-x-2">
                  <div className="text-emerald-600">
                    <AddCircleIcon />
                  </div>
                  <p className="text-emerald-800 text-xl">Open shift</p>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <DrawerFooterContainerSmall>
            {badgeText ? (
              <ButtonGrayed text="Pickup" />
            ) : (
              <ButtonFilled text="Pickup" onClick={() => handlePickup()} />
            )}
          </DrawerFooterContainerSmall>
        </div>
      </Drawer>
    </>
  );
}
