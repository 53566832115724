import React, { useState, useEffect } from "react";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SaveIcon from "@mui/icons-material/Save";
import FormControl from "@mui/material/FormControl";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CloseIcon from "@mui/icons-material/Close";
import ForecastAdjustmentSlider from "./ForecastAdjustmentSlider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import WeatherEventsCard from "./WeatherEventsCard";
import Edit from "../../settings/edit_labor_model/Edit";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonOutlined from "../../requests/shared/helpers/ButtonOutlined";
import HistoricalSalesContextCard from "./HistoricalSalesContextCard";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Tooltip from "@mui/material/Tooltip";
import { get_t_str } from "../../../../helpers/helpers";

// Modal style object
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

// Icon for forecast adjustment
function adjustment_icon(value) {
  if (value < -0.2) {
    return <SouthEastIcon />;
  }
  if (value < 0) {
    return <TrendingDownIcon />;
  }
  if (value > 0.2) {
    return <NorthEastIcon />;
  }
  if (value > 0) {
    return <TrendingUpIcon />;
  } else {
    return <TrendingFlatIcon />;
  }
}

// Smaller icon for forecast adjustment
function adjustment_icon_small(value) {
  if (value.toFixed(3) === "0.000") {
    return "";
  }
  if (value < -0.2) {
    return <SouthEastIcon style={{ fontSize: "16px" }} />;
  }
  if (value < 0) {
    return <TrendingDownIcon style={{ fontSize: "16px" }} />;
  }
  if (value > 0.2) {
    return <NorthEastIcon style={{ fontSize: "16px" }} />;
  }
  if (value > 0) {
    return <TrendingUpIcon style={{ fontSize: "16px" }} />;
  } else {
    return "";
  }
}

function DateCardSalesInfo(props) {
  const initial_state =
    props.adjustment_chosen.length === 0
      ? 0
      : props.adjustment_chosen[0]["adjustment_percent_value"];

  const initial_reason =
    props.adjustment_chosen.length === 0
      ? ""
      : props.adjustment_chosen[0]["reason"];

  const initial_forecast =
    props.forecast_sales * initial_state + props.forecast_sales;

  // // Shows modal when true
  // const [open, setOpen] = React.useState(false);

  // // Function for opening the modal
  // const handleOpen = () => setOpen(true);

  const [state, setState] = React.useState("");
  const [value, setValue] = React.useState(initial_state);
  const [savingLoading, setSavingLoading] = React.useState(false);

  const [adjustedForecast, setAdjustedForecast] = React.useState(
    (props.forecast_sales * initial_state + props.forecast_sales).toFixed(0)
  );

  // Reason why the manager adjusted the forecasted
  const [reason, setReason] = React.useState(initial_reason);

  function toggleLocked() {
    props.lock_day(props.d);
  }

  useEffect(() => {
    const initial_state = props.adjustment_chosen.length === 0 ? 0 : props.adjustment_chosen[0]["adjustment_percent_value"];
    const initial_reason = props.adjustment_chosen.length === 0 ? "" : props.adjustment_chosen[0]["reason"];
    const initial_forecast = props.forecast_sales * initial_state + props.forecast_sales;

    setValue(initial_state);
    setReason(initial_reason);
    setAdjustedForecast((props.forecast_sales * initial_state + props.forecast_sales).toFixed(0));
  }, [props.adjustment_chosen, props.forecast_sales]);

  function handleClose() {
    const initial_state = props.adjustment_chosen.length === 0 ? 0 : props.adjustment_chosen[0]["adjustment_percent_value"];
    const initial_reason = props.adjustment_chosen.length === 0 ? "" : props.adjustment_chosen[0]["reason"];
    const initial_forecast = props.forecast_sales * initial_state + props.forecast_sales;

    setValue(initial_state);
    setReason(initial_reason);
    setAdjustedForecast(initial_forecast.toFixed(0));
    setShowForecastAdjustmentInputs(false);
    props.setOpenForecastAdjustment(false);
  }

  // Function for submitting the form within the modal
  function submitAndClose() {
    setSavingLoading(true);
    let add_type = "new";
    let data = {
      date: props.date_str,
      adjustments_chosen: "adjust",
      adjustment_percent_value: value,
      sales_value: adjustedForecast,
      reason: value === 0 ? "" : reason,
    };

    if (props.adjustment_chosen.length > 0) {
      add_type = "edit";
      data = props.adjustment_chosen[0];
      data["adjustment_percent_value"] = value;
      data["sales_value"] = adjustedForecast;
      data["reason"] = value === 0 ? "" : reason;
    }

    props.addForecastAdjustment(data, add_type);
  }

  useEffect(() => {
    if (props.addAdjustmentFinished) {
      setSavingLoading(false);
      props.setOpenForecastAdjustment(false);
    }
  }, [props.addAdjustmentFinished]);

  let actualColor = "";
  if (initial_forecast <= props.actual_sales) {
    actualColor = "bg-emerald-50 text-emerald-800";
  } else {
    actualColor = "bg-rose-50 text-rose-800";
  }

  // Calculate the percent difference
  const calculatePercentDifference = (actual, forecasted) => {
    const difference = actual - forecasted;
    const average = (actual + forecasted) / 2;
    const relativeDifference = difference / average;
    const percentDifference = relativeDifference * 100;

    return percentDifference;
  };

  const percentDifference = calculatePercentDifference(
    initial_forecast,
    props.forecast_sales
  );

  const [showForecastAdjustmentInputs, setShowForecastAdjustmentInputs] =
    React.useState(false);

  function toggleShowForecastAdjustmentInputs() {
    setShowForecastAdjustmentInputs((prevState) => !prevState);
  }

  const fullDayNameLookup = {
    "Mon": "Monday",
    "Tue": "Tuesday",
    "Wed": "Wednesday",
    "Thu": "Thursday",
    "Fri": "Friday",
    "Sat": "Saturday",
    "Sun": "Sunday"
  }

  const fullDayString = fullDayNameLookup[props.date_name?.substring(0, 3)]

  const isHoliday = props.historical_sales_context?.[0]?.holidays?.length > 0

  const isClosed = props.specialHours ? (props.specialHours?.open_time === 0 && props.specialHours?.close_time === 0) : (props.defaultHours?.open_time === 0 && props.defaultHours?.close_time === 0)

  const hooString = `${get_t_str(props.hoo?.open_time)} - ${get_t_str(props.hoo?.close_time)}`
  const specialHoursString = `${get_t_str(props.specialHours?.open_time)} - ${get_t_str(props.specialHours?.close_time)}`


  return (
    <div>
      {props.view === "weekly" ? (
        !props.minimizeMode ?
        <div>
          {props.edit_mode || !props.day_in_past ? (
            <div className="w-full">
              <Tooltip title={isHoliday ? `${props.historical_sales_context?.[0]?.holidays?.[0]?.holiday_name}: ${isClosed ? "Closed" : hooString}` : isClosed ? "" : props.specialHours ? `Special Hours: ${specialHoursString}` : ""}>
                <div
                  className={`mt-2 flex items-center hover:text-violet-700 cursor-pointer ${isHoliday ? "bg-amber-50 px-1.5 rounded-lg w-full" : isClosed ? "bg-slate-100 px-1.5 rounded-lg w-full" : props.specialHours ? "bg-blue-50 px-1.5 rounded-lg w-full" : ""}`}
                  onClick={() => props.handleOpenForecastAdjustment()}
                >
                  {/* <IconButton
                    onClick={handleOpen}
                    style={{ color: props.todays_date_yes && "#2563eb" }}
                  > */}
                  {/* {adjustment_icon(value)} */}
                  {/* </IconButton> */}
                  <div>
                    <div className="text-xxs text-slate-500">Forecasted</div>
                    {isClosed ?
                      <div className="text-xs xl:text-xxs py-0.5">
                        Closed
                      </div> :
                      <div className="flex items-center">
                        <div className="text-xs xl:text-xxs py-0.5">
                          {"$" +
                            initial_forecast.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            })}
                        </div>
                        <div className="w-4 h-4 relative">
                          <p className="absolute -bottom-0.5">
                            {adjustment_icon_small(value)}
                          </p>
                        </div>
                        {/* <p className="absolute -right-1 -bottom-0.5">{adjustment_icon_small(value)}</p> */}
                      </div>
                    }
                  </div>
                </div>
              </Tooltip>
            </div>
          ) : (
            <Tooltip title={isHoliday ? `${props.historical_sales_context?.[0]?.holidays?.[0]?.holiday_name}: ${isClosed ? "Closed" : hooString}` : isClosed ? "" : props.specialHours ? `Special Hours: ${specialHoursString}` : ""}>
              <div
                className={`mt-2 flex items-center justify-between hover:text-violet-700 cursor-pointer ${isHoliday ? "bg-amber-50 px-1.5 rounded-lg" : isClosed ? "bg-slate-100 px-1.5 rounded-lg w-full" : props.specialHours ? "bg-blue-50 px-1.5 rounded-lg w-full" : ""}`}
                onClick={() => props.handleOpenForecastAdjustment()}
              >
                {props.windowWidth > 1100 &&
                  <div>
                    <div className="text-xxs text-slate-500">Forecasted</div>
                    {isClosed ?
                      <div className="text-xs xl:text-xxs py-0.5">
                        Closed
                      </div> :
                      <div className="flex items-center">
                        <div className="text-xs xl:text-xxs py-0.5">
                          {"$" +
                            initial_forecast.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            })}
                        </div>
                        <div className="w-4 h-4 relative">
                          <p className="absolute -bottom-0.5">
                            {adjustment_icon_small(value)}
                          </p>
                        </div>
                      </div>
                    }
                  </div>
                }
                <div>
                  <div className={`text-xxs ${props.windowWidth > 1100 ? "text-right" : "text-left"} text-slate-500`}>Actual</div>
                  <div
                    className={`text-xs xl:text-xxs text-center py-0.5 px-1 rounded-md ${actualColor}`}
                  >
                    {"$" +
                      props.actual_sales.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}
                  </div>
                </div>
              </div>
            </Tooltip>
          )}
        </div> :
        <div></div>
      ) : (
        <div className="flex items-center space-x-5">
          <div className="w-48 px-4 py-3 rounded-lg border border-slate-100 relative">
            <div className="text-slate-500 absolute right-1 top-1 cursor-pointer">
              <div onClick={toggleLocked}>
                {props.locked ? (
                  <div className="w-8 h-8 rounded-lg bg-violet-50 flex items-center justify-center text-violet-700">
                    <LockIcon style={{ fontSize: "16px" }} />
                  </div>
                ) : (
                  <IconButton style={{ borderRadius: "8px" }}>
                    <LockOpenIcon style={{ fontSize: "16px" }} />
                  </IconButton>
                )}
              </div>
            </div>
            <h4 className="text-slate-500">
              <WeatherEventsCard
                element="weather"
                daily_weather={props.daily_weather}
                daily_events={props.daily_events}
                date_str={props.date_str}
                date_name={props.date_name}
                saveEvent={props.saveEvent}
                saveEventFinished={props.saveEventFinished}
                setSaveEventFinished={props.setSaveEventFinished}
                setOpenNotes={props.setOpenNotes}
              />
            </h4>
            <p className="text-[14px] text-slate-900 mt-1">{props.date_name}</p>
          </div>
          {props.edit_mode || !props.day_in_past ? (
            <Tooltip title={isHoliday ? `${props.historical_sales_context?.[0]?.holidays?.[0]?.holiday_name}: ${isClosed ? "Closed" : hooString}` : isClosed ? "" : props.specialHours ? `Special Hours: ${specialHoursString}` : ""}>
              <div className={`w-48 px-4 py-3 rounded-xl border border-slate-100 relative text-slate-500 ${isHoliday ? "bg-amber-50" : "bg-white"}`}>
                <div
                  className="absolute right-1 top-1 cursor-pointer"
                  onClick={() => props.handleOpenForecastAdjustment()}
                >
                  <IconButton style={{ borderRadius: "8px" }}>
                    <EditIcon style={{ fontSize: "16px" }} />
                  </IconButton>
                </div>
                <h4 className="text-[12px] text-slate-500">Forecasted Sales</h4>
                <div className="flex items-center space-x-1 text-slate-900">
                  <p>{adjustment_icon_small(value)}</p>
                  <p className="text-[14px] mt-1">
                    {"$" +
                      initial_forecast.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}
                  </p>
                </div>
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={isHoliday ? `${props.historical_sales_context?.[0]?.holidays?.[0]?.holiday_name}: ${isClosed ? "Closed" : hooString}` : isClosed ? "" : props.specialHours ? `Special Hours: ${specialHoursString}` : ""}>
              <div
                className={`w-[250px] px-4 py-3 rounded-xl border border-slate-100 hover:border-slate-900 text-slate-500 flex items-center justify-between cursor-pointer ${isHoliday ? "bg-amber-50" : "bg-white"}`}
                onClick={() => props.handleOpenForecastAdjustment()}
              >
                <div>
                  <h4 className="text-[12px] text-slate-500">Forecasted Sales</h4>
                  <div className="flex items-center space-x-1 text-slate-900">
                    <p>{adjustment_icon_small(value)}</p>
                    <p className="text-[14px] mt-1">
                      {"$" +
                        initial_forecast.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <h4 className="text-[12px] text-slate-500 text-right">
                    Actual Sales
                  </h4>
                  <div className="w-fit justify-end items-end text-right">
                    <p className={`text-[14px] text-slate-900 mt-1 text-right px-1.5 rounded ${actualColor}`}>
                      {"$" +
                        props.actual_sales.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                    </p>
                  </div>
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      )}
      <Drawer
        anchor="right"
        open={props.openForecastAdjustment}
        onClose={handleClose}
      >
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            {isHoliday ?
              <div className="bg-amber-50">
                <DrawerHeader title={`${props.date_name}  --  ${props.historical_sales_context?.[0]?.holidays?.[0]?.holiday_name}`} onClose={handleClose} />
                <div className="px-6 -mt-6">
                  {isClosed ? <p>Closed</p> : <p>{hooString}</p>}
                </div>
              </div> :
              <div>
                <DrawerHeader title={props.date_name} onClose={handleClose} />
                <div className="px-6 -mt-6">
                  {isClosed ? <p>Closed</p> : <p>{hooString}</p>}
                </div>
              </div>
            }
            <p className={`w-full px-6 pt-6 pb-5 text-slate-500 border-b border-slate-200 ${isHoliday ? "bg-amber-50" : "bg-white"}`}>
              {!props.day_in_past ? "You can adjust the forecast for this day" : "Forecasted and actual sales for this day"}
            </p>
            <div className="w-full px-6">
              {!props.day_in_past ?
                <div className="w-full py-5">
                  <div className="w-full flex">
                    <div className="w-1/2">
                      <p className="text-xs text-slate-500">Initial forecast</p>
                      <p className="mt-1 text-slate-900 text-2xl">
                        {props.forecast_sales.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </p>
                    </div>
                    {props.forecast_sales !== initial_forecast && (
                      <div className="w-1/2">
                        <p className="text-xs text-slate-500">Adjusted forecast</p>
                        <p className="mt-1 text-slate-900 text-2xl">
                          {initial_forecast.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </p>
                        <div
                          className={`flex items-center space-x-1 -mt-1.5 ${
                            percentDifference < 0
                              ? "text-rose-800"
                              : "text-emerald-800"
                          }`}
                        >
                          <div className="mb-1">
                            {adjustment_icon_small(percentDifference / 100)}
                          </div>
                          <div>
                            <p className="text-xs">
                              {`${percentDifference.toFixed(0)}%`}{" "}
                              <span className="text-xxs">
                                from initial forecast
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {(!showForecastAdjustmentInputs && !props.day_in_past) && (
                    <div
                      className="mt-3 bg-violet-100 hover:bg-violet-200 text-violet-700 w-48 py-2 rounded-lg flex items-center justify-center cursor-pointer"
                      onClick={toggleShowForecastAdjustmentInputs}
                    >
                      <p className="text-sm">
                        {props.forecast_sales === initial_forecast
                          ? "Adjust initial forecast"
                          : "Change adjusted forecast"}
                      </p>
                    </div>
                  )}
                </div> :
                <div className="w-full pt-5 pb-2">
                  <div className="w-full flex justify-between">
                    <div>
                      <p className="text-xs text-slate-500">Initial Forecast</p>
                      <p className="text-slate-900 text-2xl py-0.5 rounded-md">
                        {props.forecast_sales.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </p>
                    </div>
                    {props.forecast_sales !== initial_forecast && (
                      <div>
                        <p className="text-xs text-slate-500 text-center">Adjusted Forecast</p>
                        <div className="flex items-center space-x-1.5">
                          <p className="text-slate-900 text-2xl py-0.5 rounded-md text-center">
                            {initial_forecast.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </p>
                          {(Math.abs(initial_forecast - props.actual_sales)) <= (Math.abs(props.forecast_sales - props.actual_sales)) ?
                            <div className="text-emerald-700">
                              <ThumbUpIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                            </div> :
                            <div className="text-rose-700">
                              <ThumbDownIcon style={{ fontSize: "18px" , marginBottom: "2px" }}/>
                            </div>
                          }
                        </div>
                        {/* <div
                          className={`flex items-center space-x-1 -mt-1.5 ${
                            percentDifference < 0
                              ? "text-rose-800"
                              : "text-emerald-800"
                          }`}
                        >
                          <div className="mb-1">
                            {adjustment_icon_small(percentDifference / 100)}
                          </div>
                          <div>
                            <p className="text-xs">
                              {`${percentDifference.toFixed(0)}%`}{" "}
                              <span className="text-xxs">
                                from initial forecast
                              </span>
                            </p>
                          </div>
                        </div> */}
                      </div>
                    )}
                    <div>
                      <p className="text-xs text-slate-500 text-right">Actual Sales</p>
                      <p className={`text-2xl py-0.5 px-2 rounded-md text-right ${actualColor}`}>
                        {"$" +
                        props.actual_sales.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              }
              {showForecastAdjustmentInputs && (
                <div className="w-full py-5 border-t border-slate-200">
                  <div>
                    <h3 className="text-slate-500 text-sm">
                      {props.forecast_sales === initial_forecast
                        ? "Set new forecast"
                        : "Set new adjusted forecast"}
                    </h3>
                    <div className="mt-1">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="$10,000"
                        size="small"
                        InputProps={{
                          sx: {
                            backgroundColor: "white",
                            borderRadius: "8px",
                            paddingY: "5px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                          },
                        }}
                        value={"$" + adjustedForecast}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          // Remove non-numeric characters
                          const numericValue = inputValue.replace(
                            /[^0-9.]/g,
                            ""
                          );
                          setAdjustedForecast(numericValue);
                          setValue(
                            (parseFloat(numericValue) - props.forecast_sales) /
                              props.forecast_sales
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <h3 className="text-slate-500 text-sm">Reason*</h3>
                    <div className="mt-1">
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          borderRadius: "12px",
                          backgroundColor: "white",
                        }}
                      >
                        <Select
                          value={reason}
                          onChange={(event) => setReason(event.target.value)}
                          displayEmpty
                          sx={{
                            borderRadius: "8px",
                            paddingY: "5px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                            // fieldset: { borderColor: "#e2e8f0" }
                          }}
                        >
                          <MenuItem value="" disabled>
                            Reason for change
                          </MenuItem>
                          <MenuItem value="weather">Weather</MenuItem>
                          <MenuItem value="events">Local events</MenuItem>
                          <MenuItem value="trust">
                            Don't trust forecast
                          </MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-3 border-t border-slate-200">
                {isHoliday &&
                  <div className="mt-5">
                    <p className="text-sm font-semibold">This {props.historical_sales_context?.[0]?.holidays?.[0]?.holiday_name} last year</p>
                    <div className="mt-3">
                      <HistoricalSalesContextCard
                        date={props.historical_sales_context?.[0]?.holidays?.[0]?.last_year_date}
                        initial_forecast={props.historical_sales_context?.[0]?.holidays?.[0]?.initial_forecasted?.value}
                        adjusted_forecast={props.historical_sales_context?.[0]?.holidays?.[0]?.forecasted_adjustment}
                        actual_sales={props.historical_sales_context?.[0]?.holidays?.[0]?.actual_sales?.value}
                        holiday={true}
                      />
                    </div>
                  </div>
                }
                <div className="mt-5">
                  <p className="text-sm font-semibold">This {fullDayString} last year</p>
                  <div className="mt-3">
                    <HistoricalSalesContextCard
                      date={props.historical_sales_context?.[0]?.one_year_ago?.date}
                      initial_forecast={props.historical_sales_context?.[0]?.one_year_ago?.initial_forecasted?.value}
                      adjusted_forecast={props.historical_sales_context?.[0]?.one_year_ago?.forecasted_adjustment}
                      actual_sales={props.historical_sales_context?.[0]?.one_year_ago?.actual_sales?.value}
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <p className="text-sm font-semibold">Last 4 {fullDayString}s</p>
                  <div className="mt-3">
                    <HistoricalSalesContextCard
                      date={props.historical_sales_context?.[0]?.one_week_ago?.date}
                      initial_forecast={props.historical_sales_context?.[0]?.one_week_ago?.initial_forecasted?.value}
                      adjusted_forecast={props.historical_sales_context?.[0]?.one_week_ago?.forecasted_adjustment}
                      actual_sales={props.historical_sales_context?.[0]?.one_week_ago?.actual_sales?.value}
                    />
                  </div>
                  <div className="mt-3">
                    <HistoricalSalesContextCard
                      date={props.historical_sales_context?.[0]?.two_weeks_ago?.date}
                      initial_forecast={props.historical_sales_context?.[0]?.two_weeks_ago?.initial_forecasted?.value}
                      adjusted_forecast={props.historical_sales_context?.[0]?.two_weeks_ago?.forecasted_adjustment}
                      actual_sales={props.historical_sales_context?.[0]?.two_weeks_ago?.actual_sales?.value}
                    />
                  </div>
                  <div className="mt-3">
                    <HistoricalSalesContextCard
                      date={props.historical_sales_context?.[0]?.three_weeks_ago?.date}
                      initial_forecast={props.historical_sales_context?.[0]?.three_weeks_ago?.initial_forecasted?.value}
                      adjusted_forecast={props.historical_sales_context?.[0]?.three_weeks_ago?.forecasted_adjustment}
                      actual_sales={props.historical_sales_context?.[0]?.three_weeks_ago?.actual_sales?.value}
                    />
                  </div>
                  <div className="mt-3">
                    <HistoricalSalesContextCard
                      date={props.historical_sales_context?.[0]?.four_weeks_ago?.date}
                      initial_forecast={props.historical_sales_context?.[0]?.four_weeks_ago?.initial_forecasted?.value}
                      adjusted_forecast={props.historical_sales_context?.[0]?.four_weeks_ago?.forecasted_adjustment}
                      actual_sales={props.historical_sales_context?.[0]?.four_weeks_ago?.actual_sales?.value}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[210px]"></div>
          </div>
          <DrawerFooterContainerLarge>
            <LoadingButton
              fullWidth
              disabled={
                ((!reason || reason === "") && value !== 0) ||
                value === initial_state
              }
              loading={savingLoading}
              loadingPosition="end"
              endIcon={<SaveIcon />}
              variant="contained"
              onClick={() => submitAndClose()}
              sx={{
                textTransform: "none",
                borderRadius: "50px",
                paddingY: "14px",
                backgroundColor: "#6d28d9",
                "&:hover": {
                  backgroundColor: "#5b21b6",
                },
                fontSize: "16px",
              }}
            >
              Save and Recalculate
            </LoadingButton>
            <div className="w-full mt-3">
              <ButtonOutlined text="Cancel" onClick={handleClose} />
            </div>
          </DrawerFooterContainerLarge>
        </div>
      </Drawer>
    </div>
  );
}

export default DateCardSalesInfo;
