import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

import LoadSpinner from "../../../../../utilities/LoadSpinner";

import CircularProgress from "@mui/material/CircularProgress";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function LoadFromTemplateButton(props) {
  const [state, setState] = React.useState({ template_name: "" });
  const [open, setOpen] = React.useState(false);
  const [buttonPressed, setButtonPressed] = React.useState(false);
  const [secondLoadingMessage, setSecondLoadingMessage] = React.useState(false);
  const [thirdLoadingMessage, setThirdLoadingMessage] = React.useState(false);
  const [fourthLoadingMessage, setFourthLoadingMessage] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleStart = () => {
    props.loadFromTemplate(state.template_name);
    setButtonPressed(true);
    setTimeout(() => {
      setSecondLoadingMessage(true);
    }, 3000);
    setTimeout(() => {
      setThirdLoadingMessage(true);
    }, 6000);
  };

  const completeOnFinish = (loading_finished) => {
    if (loading_finished) {
      setSecondLoadingMessage(true);
      setThirdLoadingMessage(true);
      setFourthLoadingMessage(true);
      setButtonPressed(false);
      handleClose();
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }
  const handleClose = () => {
    setState({ template_name: "" });
    setOpen(false);
    setButtonPressed(false);
    setSecondLoadingMessage(false);
    setThirdLoadingMessage(false);
    setFourthLoadingMessage(false);
    props.setLoadFromTemplateFinished(false);
  };

  const handleDelete = () => {
    setState({ template_name: "" });
    setOpen(false);
    props.deleteScheduleTemplate(state.template_name);
  };

  useEffect(() => {
    completeOnFinish(props.loadFromTemplateFinished);
  }, [props.loadFromTemplateFinished]);

  return (
    <div>
      <div>
        {props.week_already_started || props.template_names.length === 0 ? (
          <Tooltip
            title={
              props.week_already_started
                ? "You cannot load in a template once the week has already started. Go to a future week to use this feature."
                : "You have no templates to load in"
            }
          >
            <div className="text-[#bdbdbd] w-10 h-10 flex items-center justify-center">
              <FolderSpecialIcon
              //color={props.loadedFrom7Shifts ? "success" : "default"}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              //props.loadedFromTemplate
              //</div>  ? "Current Schedule Was Loaded from Template"
              //  : \
              "Load from template"
            }
          >
            <IconButton
              onClick={handleOpen}
              disabled={
                props.week_already_started || props.template_names.length === 0
              }
            >
              <FolderSpecialIcon
              //color={props.loadedFromTemplate ? "success" : "default"}
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {buttonPressed ? (
            // <div className="w-full flex justify-center items-center">
            //   <div className="w-10/12">
            //     <div className="flex flex-col gap-3">
            //       <div className="flex items-center gap-3">
            //         {secondLoadingMessage ? (
            //           <CheckCircleIcon sx={{ color: "#19c22a" }} />
            //         ) : (
            //           <LoadSpinner />
            //         )}
            //         {secondLoadingMessage ? (
            //           <p>Loaded Schedule Successfully</p>
            //         ) : (
            //           <p>Loading Schedule...</p>
            //         )}
            //       </div>
            //       <div
            //         className={`flex items-center gap-3 ${
            //           thirdLoadingMessage && "hidden"
            //         }`}
            //       >
            //         {secondLoadingMessage && <LoadSpinner />}
            //         {secondLoadingMessage && <p>Evaluating Schedule...</p>}
            //       </div>
            //       <div className="flex items-center gap-3">
            //         {thirdLoadingMessage && (
            //           <CheckCircleIcon sx={{ color: "#19c22a" }} />
            //         )}
            //         {thirdLoadingMessage && (
            //           <p>Evaluated Schedule Successfully</p>
            //         )}
            //       </div>
            //       <div
            //         className={`flex items-center gap-3 ${
            //           fourthLoadingMessage && "hidden"
            //         }`}
            //       >
            //         {thirdLoadingMessage && <LoadSpinner />}
            //         {thirdLoadingMessage && <p>Finishing Final Touches...</p>}
            //       </div>
            //       <div className="flex items-center gap-3">
            //         {fourthLoadingMessage && (
            //           <CheckCircleIcon sx={{ color: "#19c22a" }} />
            //         )}
            //         {fourthLoadingMessage && <p>Finished Sucessfully</p>}
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <div className="h-[280px]">
              <div className="pb-8 border-b border-slate-200">
                <h2 className="text-slate-900 text-lg">
                  Loading schedule from {state.template_name}
                </h2>
                <p className="text-slate-500 text-sm mt-2">
                  This can take a couple minutes. Thank you for being patient!
                  Please don't click outside of this modal.
                </p>
              </div>
              <div className="mt-8">
                <div className="flex items-center gap-3">
                  {secondLoadingMessage ? (
                    <CheckCircleIcon
                      sx={{ color: "#059669", fontSize: "26px" }}
                    />
                  ) : (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  {secondLoadingMessage ? (
                    <p className="text-emerald-800">
                      Loaded Schedule Successfully
                    </p>
                  ) : (
                    <p>Loading Schedule...</p>
                  )}
                </div>
                <div
                  className={`flex items-center gap-3 mt-5 ${
                    thirdLoadingMessage && "hidden"
                  }`}
                >
                  {secondLoadingMessage && (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  {secondLoadingMessage && <p>Evaluating Schedule...</p>}
                </div>
                <div className="flex items-center gap-3 mt-5">
                  {thirdLoadingMessage && (
                    <CheckCircleIcon
                      sx={{ color: "#059669", fontSize: "26px" }}
                    />
                  )}
                  {thirdLoadingMessage && (
                    <p className="text-emerald-800">
                      Evaluated Schedule Successfully
                    </p>
                  )}
                </div>
                <div
                  className={`flex items-center gap-3 mt-5 ${
                    fourthLoadingMessage && "hidden"
                  }`}
                >
                  {thirdLoadingMessage && (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  {thirdLoadingMessage && <p>Finishing Final Touches...</p>}
                </div>
                <div className="flex items-center gap-3 mt-5">
                  {fourthLoadingMessage && (
                    <CheckCircleIcon
                      sx={{ color: "#059669", fontSize: "26px" }}
                    />
                  )}
                  {fourthLoadingMessage && (
                    <p className="text-emerald-800">Finished Sucessfully</p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="absolute right-2 top-2 text-gray-500 cursor-pointer"
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
              <h3 className="text-xl text-slate-900">
                Load schedule from template
              </h3>
              <p className="text-m text-slate-500 mt-2">
                Select a template that you have saved to load in.
              </p>
              <div className="mt-7">
                <TextField
                  fullWidth
                  name="template_name"
                  select
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "12px",
                      padding: "5px",
                      height: "50px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                  value={state.template_name}
                  onChange={handleChange}
                >
                  {props.template_names?.map((option) => (
                    <MenuItem
                      key={option.template_name}
                      value={option.template_name}
                    >
                      {option.template_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {state.template_name && (
                <div className="w-full flex items-center mt-5 pb-5 border-b border-slate-200">
                  <div className="w-1/2">
                    <p className="text-slate-500 text-xs">Total Hours</p>
                    <p className="text-slate-900">
                      {
                        props.template_names.filter(
                          (i) => i.template_name == state.template_name
                        )[0]["hours"]
                      }
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-slate-500 text-xs">Saved On Date</p>
                    <p className="text-slate-900">
                      {
                        props.template_names.filter(
                          (i) => i.template_name == state.template_name
                        )[0]["saved_on_date"]
                      }
                    </p>
                  </div>
                </div>
              )}
              {state.template_name ? (
                <div className="w-full flex items-center space-x-4 mt-6">
                  <div
                    className="w-32 py-3 rounded-full text-white text-sm bg-rose-600 hover:bg-rose-700 flex items-center justify-center cursor-pointer"
                    onClick={() => handleDelete()}
                  >
                    <p>Delete</p>
                  </div>
                  <div className="flex-1">
                    <Button
                      fullWidth
                      variant="contained"
                      loading={buttonPressed}
                      loadingPosition="end"
                      endIcon={<FolderSpecialIcon />}
                      onClick={() => handleStart()}
                      sx={{
                        textTransform: "none",
                        borderRadius: "50px",
                        paddingY: "10px",
                        backgroundColor: "#6d28d9",
                        "&:hover": {
                          backgroundColor: "#5b21b6",
                        },
                      }}
                    >
                      Load
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="w-full flex items-center space-x-4 mt-6">
                  <div className="w-32 py-3 rounded-full text-slate-400 text-sm bg-slate-200 flex items-center justify-center">
                    <p>Delete</p>
                  </div>
                  <div className="flex-1">
                    <div className="w-full py-3 rounded-full text-slate-400 text-sm bg-slate-200 flex items-center justify-center gap-x-2">
                      Load <FolderSpecialIcon />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default LoadFromTemplateButton;
