import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { get_t_str } from "../../../../helpers/helpers";
import CheckIcon from "@mui/icons-material/Check";
import ModalImage from "react-modal-image";
import TaskStatusBadge from "./TaskStatusBadge";

function TaskNameCardModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const assignedName =
    props.task.plan_task_assignments?.[0]?.store_team_member?.full_name;

  console.log("MANAGER TASKS", props.task)

  const handleOpenPdfInNewTab = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="w-full flex items-end justify-between">
              <div>
                <div>
                  <p className="text-xs text-slate-500">Name</p>
                  <p className="text-base text-slate-900">{props.task.name}</p>
                </div>
                {props.task.description &&
                  <div className="mt-2">
                    <p className="text-xs text-slate-500">Description</p>
                    <p className="text-base text-slate-900">
                      {props.task.description}
                    </p>
                  </div>
                }
                <div className="mt-2">
                  <p className="text-xs text-slate-500">Complete Between</p>
                  {(props.task.lower_bound_qhnum && props.task.upper_bound_qhnum) ?
                    <p className="text-base text-slate-900">
                      {get_t_str(props.task.lower_bound_qhnum)} -{" "}
                      {get_t_str(props.task.upper_bound_qhnum)}
                    </p> :
                    <p className="text-base text-slate-900">
                      Anytime
                    </p>
                  }
                </div>
                {props.task?.file_uploads?.length > 0 &&
                  <div className="mt-2">
                    <p className="text-xs text-slate-500">Resources</p>
                    {props.task?.file_uploads?.map((file, index) => {
                      const fileType = file.name.split('.').pop();
                      return (
                        <div key={index} className="py-1">
                          {fileType === "pdf" ?
                            <div onClick={() => {
                              handleOpenPdfInNewTab(file.presigned_url)
                            }}>
                              <p className="text-sm text-violet-700 hover:text-violet-800 cursor-pointer">{file.name}</p>
                            </div> :
                            <div onClick={() => {
                              props.setSelectedImage(file.presigned_url)
                              props.setShowLightbox(true)
                            }}>
                              <p className="text-sm text-violet-700 hover:text-violet-800 cursor-pointer">{file.name}</p>
                            </div>
                          }
                        </div>
                      );
                    })}
                  </div>
                }
                {props.task?.plan_task_assignments?.length > 0 &&
                  <div className="mt-2">
                    <p className="text-xs text-slate-500">Status</p>
                    <div className="mt-1.5">
                      <TaskStatusBadge
                        status={props.task?.plan_task_assignments?.[0]?.status}
                        submitted_at={props.task?.plan_task_assignments?.[0]?.submitted_at_formatted}
                        size="large"
                      />
                    </div>
                  </div>
                }
              </div>
              {props.task?.plan_task_assignments?.[0]?.store_team_member ? (
                <div
                  className={`${props.cardColor} h-fit text-white px-3 py-2 rounded-lg`}
                >
                  {assignedName}
                </div>
              ) : (
                <div
                  className={`${props.cardColor} bg-white border border-slate-300 h-fit text-slate-500 px-3 py-2 rounded-lg`}
                >
                  Unassigned
                </div>
              )}
            </div>
            {props.task?.subtasks?.length > 0 &&
              <div>
                {props.task?.plan_task_assignments?.[0]?.store_team_member ?
                  <div className="mt-4 bg-slate-50 rounded-lg px-5 py-2">
                    {props.task?.plan_task_assignments?.[0]?.plan_subtask_assignments?.map((subtask, index) => (
                      <div key={index} className="py-4">
                        <div className="flex gap-x-3">
                          <div className="text-sm leading-none w-5">{index + 1}.</div>
                          {subtask.plan_subtask.type === "text" ? (
                            <div className="w-full">
                              <div className="flex gap-x-3">
                                <div>
                                  <p className="text-sm text-slate-900 leading-none">
                                    {subtask.plan_subtask.name}
                                  </p>
                                  <p className="text-xxs text-slate-500 mt-1">
                                    {subtask.plan_subtask.description}
                                  </p>
                                </div>
                              </div>
                              <div className="border border-slate-300 mt-2 min-h-[40px] p-2.5 rounded-lg text-xs bg-white flex items-center">
                                <p>{subtask.value_text}</p>
                              </div>
                            </div>
                          ) : subtask.plan_subtask.type === "checkbox" ? (
                            <div className="flex gap-x-3">
                              <div className={`border border-slate-300 w-6 h-6 rounded ${subtask.value_boolean ? "bg-violet-700" : "bg-white"} flex items-center justify-center text-white`}>
                                {subtask.value_boolean ? <CheckIcon style={{ fontSize: "18px" }} /> : ""}
                              </div>
                              <div>
                                <p className="text-sm text-slate-900 leading-none">
                                  {subtask.plan_subtask.name}
                                </p>
                                <p className="text-xxs text-slate-500 mt-1">
                                  {subtask.plan_subtask.description}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {(subtask?.file_upload === null && subtask.plan_subtask.is_image_required) ?
                          <div className="flex gap-x-3 mt-1">
                            <div className="w-5"></div>
                            <p className="text-xxs text-slate-500">Image required*</p>
                          </div> :
                          <div className="flex gap-x-3 mt-2">
                            <div className="w-5"></div>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                props.setSelectedImage(subtask?.file_upload?.presigned_url)
                                props.setShowLightbox(true)
                              }}
                            >
                              <img className="rounded-lg max-h-[150px] max-w-[250px]" src={subtask?.file_upload?.presigned_url} />
                            </div>
                          </div>
                        }
                      </div>
                    ))}
                  </div> :
                  <div className="mt-4 bg-slate-50 rounded-lg px-5 py-2">
                    {props.task?.subtasks?.map((subtask, index) => {
                      return (
                        <div key={index} className="flex gap-x-3 py-4">
                          <div className="text-sm leading-none w-5">{index + 1}.</div>
                          {subtask.type === "text" ? (
                            <div className="w-full">
                              <div className="flex gap-x-3">
                                <div>
                                  <p className="text-sm text-slate-900 leading-none">
                                    {subtask.name}
                                  </p>
                                  <p className="text-xxs text-slate-500 mt-1">
                                    {subtask.description}
                                  </p>
                                </div>
                              </div>
                              <div className="border border-slate-300 mt-2 h-[40px] p-2.5 rounded-lg text-xxs bg-white">
                              </div>
                            </div>
                          ) : subtask.type === "checkbox" ? (
                            <div className="flex gap-x-3">
                              <div className="border border-slate-300 w-6 h-6 rounded bg-white"></div>
                              <div>
                                <p className="text-sm text-slate-900 leading-none">
                                  {subtask.name}
                                </p>
                                <p className="text-xxs text-slate-500 mt-1">
                                  {subtask.description}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                }
              </div>
            }
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div
                  className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                {(props.task?.plan_task_assignments?.[0]?.store_team_member && props.task?.plan_task_assignments?.[0]?.status !== "completed" && props.task?.plan_task_assignments?.[0]?.status !== "completed_late") &&  (
                  <div
                    className="px-8 py-2 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      props.unassign_plan_task(
                        props.task.plan_task_assignments?.[0]?.uuid
                      );
                      props.handleClose();
                    }}
                  >
                    Unassign
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TaskNameCardModal;
