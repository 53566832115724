import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MenuItem from "@mui/material/MenuItem";
import ModuleInputsModal from "./ModuleInputsModal";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DeleteWarningModal from "./DeleteWarningModal";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TrackInputsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [openModuleInputsModal, setOpenModuleInputsModal] =
    React.useState(false);
  const handleOpenModuleInputsModal = () => setOpenModuleInputsModal(true);
  const handleCloseModuleInputsModal = () => setOpenModuleInputsModal(false);

  const [openDeleteWarningModal, setOpenDeleteWarningModal] =
    React.useState(false);
  const handleOpenDeleteWarningModal = () => setOpenDeleteWarningModal(true);
  const handleCloseDeleteWarningModal = () => setOpenDeleteWarningModal(false);

  const [selectedModule, setSelectedModule] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [selectedModules, setSelectedModules] = React.useState([]);

  React.useEffect(() => {
    if (props.modalDataTrack?.roles_assigned_to) {
      setSelectedRoles(
        props.modalDataTrack.roles_assigned_to.map((role) => role.id)
      );
    }
  }, [props.modalDataTrack.roles_assigned_to]);

  React.useEffect(() => {
    if (props.open) {
      const initialSelectedModules = props.modalDataTrack.modules.map(
        (module) => module.name
      );
      setSelectedModules(initialSelectedModules);
    }
  }, [props.open]);

  function handleChange(event) {
    const { name, value } = event.target;
    props.setModalDataTrack((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  }

  function addSelectedModuleToModalData(moduleNames) {
    const newModules = moduleNames
      .map((moduleName) =>
        props.modulesData.find((module) => module.name === moduleName)
      )
      .filter(Boolean);

    const uniqueModules = [
      ...new Map(newModules.map((item) => [item.name, item])).values(),
    ];

    props.setModalDataTrack((prevModalData) => ({
      ...prevModalData,
      modules: uniqueModules,
    }));
  }

  function removeModuleFromModalData(index) {
    const updatedModules = props.modalDataTrack.modules.filter(
      (_, i) => i !== index
    );
    props.setModalDataTrack((prevModalData) => ({
      ...prevModalData,
      modules: updatedModules,
    }));

    // Update selectedModules state to reflect the change
    const updatedSelectedModules = updatedModules.map((module) => module.name);
    setSelectedModules(updatedSelectedModules);
  }

  // function handleSelectRolesChange(event) {
  //   const { value } = event.target;
  //   const selectedRoleObjects = value.map((id) =>
  //     props.roleNames.find((role) => role.id === id)
  //   );
  //   setSelectedRoles(value);
  //   props.setModalDataTrack((prevModalData) => ({
  //     ...prevModalData,
  //     roles_assigned_to: selectedRoleObjects,
  //   }));
  // }

  const handleSelectRolesChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      if (selectedRoles.length === (props.roleNames?.length || 0)) {
        setSelectedRoles([]);
        props.setModalDataTrack((prevModalData) => ({
          ...prevModalData,
          roles_assigned_to: [],
        }));
      } else {
        const allRoles = props.roleNames?.map((role) => role.id) || [];
        setSelectedRoles(allRoles);
        const selectedRoleObjects = props.roleNames || [];
        props.setModalDataTrack((prevModalData) => ({
          ...prevModalData,
          roles_assigned_to: selectedRoleObjects,
        }));
      }
      return;
    }

    setSelectedRoles(value);
    const selectedRoleObjects = value.map((id) => {
      const role = props.roleNames.find((role) => role.id === id);
      if (!role) {
        console.error(`Role with id ${id} not found in props.roleNames`);
      }
      return role;
    }).filter(Boolean); // filter out undefined values
    props.setModalDataTrack((prevModalData) => ({
      ...prevModalData,
      roles_assigned_to: selectedRoleObjects,
    }));
  };


  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const reorderedModules = Array.from(props.modalDataTrack.modules);
    const [movedModule] = reorderedModules.splice(result.source.index, 1);
    reorderedModules.splice(result.destination.index, 0, movedModule);

    props.setModalDataTrack((prevModalData) => ({
      ...prevModalData,
      modules: reorderedModules,
    }));
  }

  React.useEffect(() => {
    if (props.type === "Preview" && props.open) {
      props.removeAllBlocks();
    }
  }, [props.modalDataTrack]);

  return (
    <>
      <Modal
        open={props.open}
        // onClose={() => {
        //   setSelectedModule("");
        //   setInputValue(""); // Clear the input value
        //   if (props.type === "Preview") {
        //     props.removeAllBlocks();
        //     props.setModalDataTrack(props.selectedTrack);
        //   }
        //   props.handleClose();
        // }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => {
              setSelectedModule("");
              setInputValue(""); // Clear the input value
              if (props.type === "Preview") {
                props.removeAllBlocks();
                props.setModalDataTrack(props.selectedTrack);
              }
              props.handleClose();
            }}
          >
            <CloseIcon />
          </div>
          <div>
            <div>
              <h3 className="text-2xl text-slate-900">
                {props.modalEdit ? "Edit Track" : "Create Track"}
              </h3>
            </div>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Track Name</p>
              <div className="mt-1.5">
                <TextField
                  placeholder="What do you want to name this track? (i.e. Server Track)"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={props.modalDataTrack?.name}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Auto-assign to roles</p>
              <div className="mt-1.5">
                <Select
                  fullWidth
                  value={selectedRoles}
                  name="roles_assigned_to"
                  multiple
                  onChange={handleSelectRolesChange}
                  size="small"
                  sx={{
                    borderRadius: "8px",
                    paddingY: "5px",
                    height: "45px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                  renderValue={(selected) => {
                    const selectedRoleNames = props.roleNames
                      .filter((role) => selected.includes(role.id))
                      .map((role) => role.role_name);

                    if (selectedRoleNames.length <= 8) {
                      return selectedRoleNames.join(", ");
                    } else {
                      return `${selectedRoleNames[0]}, ${selectedRoleNames[1]}, ...`;
                    }
                  }}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={
                        props.roleNames?.length > 0 &&
                        selectedRoles.length === props.roleNames?.length
                      }
                      indeterminate={
                        selectedRoles.length > 0 &&
                        selectedRoles.length < props.roleNames.length
                      }
                      sx={{
                        color: "#6d28d9",
                        "&.Mui-checked": {
                          color: "#6d28d9",
                        },
                        "&.MuiCheckbox-indeterminate": {
                          color: "#6d28d9",
                        },
                      }}
                    />
                    <ListItemText
                      primary={
                        selectedRoles.length === props.roleNames?.length
                          ? "Deselect All"
                          : "Select All"
                      }
                      primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                  </MenuItem>
                  {props.roleNames?.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      <Checkbox
                        checked={selectedRoles.indexOf(role.id) > -1}
                        sx={{
                          color: "#6d28d9",
                          "&.Mui-checked": {
                            color: "#6d28d9",
                          },
                        }}
                      />
                      <ListItemText primary={role.role_name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Modules</p>
              <div
                className={`w-full ${
                  props.modalDataTrack?.modules?.length > 0
                    ? "pb-5 mt-1.5"
                    : "pb-5 mt-1.5"
                }`}
              >
                <div className="flex-1">
                  <div className="w-full">
                    <Autocomplete
                      multiple
                      fullWidth
                      value={selectedModules}
                      onChange={(event, newValue) => {
                        if (newValue.includes("+ CREATE NEW MODULE")) {
                          props.setModalDataModule(props.default_data_module);
                          handleOpenModuleInputsModal();
                        } else {
                          setSelectedModules(newValue);
                          addSelectedModuleToModalData(newValue);
                        }
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      disableClearable
                      disableCloseOnSelect
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      }}
                      options={[
                        "+ CREATE NEW MODULE",
                        ...(props.modulesData?.map((module) => module.name) ||
                          []),
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select modules to add to track"
                          size="small"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px !important",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                              backgroundColor: "white",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) =>
                        option === "+ CREATE NEW MODULE" ? (
                          <li
                            {...props}
                            style={{
                              backgroundColor: "#f5f3ff",
                              color: "#6d28d9",
                              fontWeight: "bold",
                            }}
                          >
                            {option}
                          </li>
                        ) : (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              sx={{
                                color: "#6d28d9",
                                "&.Mui-checked": {
                                  color: "#6d28d9",
                                },
                              }}
                            />
                            {option}
                          </li>
                        )
                      }
                      renderTags={() => null} // Hide the selected values in the input box
                    />
                  </div>
                </div>
              </div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="modules">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="bg-slate-50"
                    >
                      {props.modalDataTrack?.modules?.map((module, index) => (
                        <Draggable
                          key={module.name}
                          draggableId={module.name}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                left: "auto !important",
                                top: "auto !important",
                              }}
                              className={`py-3 border-b border-slate-200 flex ${
                                snapshot.isDragging
                                  ? "bg-slate-200"
                                  : "bg-white"
                              }`}
                            >
                              <div
                                className={`w-[8%] text-slate-500 cursor-pointer`}
                                {...provided.dragHandleProps}
                              >
                                <DragIndicatorIcon />
                              </div>
                              <p className="w-[12%]">{index + 1}</p>
                              <p className="flex-1">{module.name}</p>
                              <div
                                className="text-slate-500 cursor-pointer"
                                onClick={() => removeModuleFromModalData(index)}
                              >
                                <CloseIcon style={{ fontSize: "22px" }} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="w-full mt-6 flex justify-end">
              <div className="flex items-center space-x-2">
                {props.modalEdit && props.type !== "Preview" ? (
                  <div
                    className="px-10 py-2.5 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      setSelectedModule("");
                      handleOpenDeleteWarningModal();
                    }}
                  >
                    Delete Track
                  </div>
                ) : (
                  <div
                    className="px-10 py-2.5 border border-violet-700 bg-white hover:bg-slate-50 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      setSelectedModule("");
                      setInputValue("");
                      props.handleClose();
                      if (props.type === "Preview") {
                        props.removeAllBlocks();
                        props.setModalDataTrack(props.selectedTrack);
                      }
                    }}
                  >
                    Cancel
                  </div>
                )}
                <div
                  className="px-10 py-2.5 bg-violet-700 hover:bg-violet-800 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.post_learning_track();
                    setSelectedModule("");
                    setInputValue(""); // Clear the input value
                    if (props.type === "Preview") {
                      props.removeAllBlocks();
                      props.setSelectedTrack(props.modalDataTrack);
                    }
                    props.handleClose();
                  }}
                >
                  Save Track
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ModuleInputsModal
        open={openModuleInputsModal}
        handleClose={handleCloseModuleInputsModal}
        homeCompany={props.homeCompany}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        sectionsData={props.sectionsData}
        post_learning_module={props.post_learning_module}
        post_learning_section={props.post_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
      />
      <DeleteWarningModal
        open={openDeleteWarningModal}
        handleClose={handleCloseDeleteWarningModal}
        type="track"
        delete_function={props.delete_learning_track}
        handleCloseInputsModal={props.handleClose}
      />
    </>
  );
}

export default TrackInputsModal;
