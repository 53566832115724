import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ButtonFilled from "../requests/shared/helpers/ButtonFilled";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";

function RoleSettingsDrawer(props) {
  const [state, setState] = React.useState(props.modalData);

  useEffect(() => {
    setState(props.modalData);
  }, [props.modalData]);

  function handleChange(evt) {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  function handleColorSelection(color) {
    setState({
      ...state,
      color: color,
    });
  }

  function handleExcludeFromLaborChecked(evt) {
    setState({
      ...state,
      exclude_from_labor: evt.target.checked,
    });
  }

  function handleSubmit() {
    console.log("SUBMITTING: ", state);
    let edit_type = "edit";
    if (!props.modalEdit) {
      edit_type = "create";
    }
    props.edit_role(state, state.color, edit_type);
  }

  useEffect(() => {
    if (!props.isSavingData) {
      props.onClose();
    }
  }, [props.isSavingData]);

  console.log("STATE", state);

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader
            title={`${props.modalEdit ? "Edit" : "Add"} role`}
            onClose={props.onClose}
          />
          <div className="w-full px-6">
            <div className="mt-7">
              <p className="text-sm text-slate-500">Location</p>
              <div className="mt-1">
                <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                  <p>{props.store_name}</p>
                </div>
              </div>
            </div>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Name</p>
              {props.permissionRole !== "company_manager" ?
                <div className="mt-1">
                  <TextField
                    placeholder="Server"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="role_name"
                    value={state.role_name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div> :
                <div className="mt-1">
                  <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                    <p>{state.role_name}</p>
                  </div>
                </div>
              }
            </div>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Department</p>
              {props.permissionRole !== "company_manager" ?
                <div className="mt-1">
                  <FormControl fullWidth size="small">
                    <Select
                      labelId="role-department"
                      id="role-department"
                      name="role_type_id"
                      value={state.role_type_id}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        // Find the department with the matching id and return its role_type
                        const selectedDept = props.departments.find(
                          (dept) => dept.id === selected
                        );
                        return selectedDept ? selectedDept.role_type : "";
                      }}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      }}
                    >
                      {props.departments.map((v, i) => (
                        <MenuItem key={v.id} value={v.id}>
                          {v.role_type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> :
                <div className="mt-1">
                  <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                    <p>{props.departments.find((dept) => dept.id === state.role_type_id)?.role_type}</p>
                  </div>
                </div>
              }
            </div>
            <div className="mt-7">
              {props.permissionRole !== "company_manager" ?
                <div className="flex items-center">
                  <Checkbox
                    name="exclude_from_labor"
                    checked={state.exclude_from_labor}
                    onChange={handleExcludeFromLaborChecked}
                    sx={{
                      color: "#6d28d9",
                      "&.Mui-checked": {
                        color: "#6d28d9",
                      },
                    }}
                  />
                  <p className="text-sm text-slate-500 ml-1">Exclude role from labor calculations?</p>
                </div> :
                <div className="flex items-center">
                  <Checkbox
                    name="exclude_from_labor"
                    checked={state.exclude_from_labor}
                    disabled
                  />
                  <p className="text-sm text-slate-500 ml-1">Exclude role from labor calculations?</p>
                </div>
              }
            </div>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Color</p>
              <div className="mt-1 w-full">
                <div className="w-full flex space-x-2">
                  {["bg-violet-600", "bg-sky-700", "bg-cyan-600", "bg-emerald-600", "bg-lime-600", "bg-amber-500", "bg-orange-600", "bg-red-600", "bg-pink-600"].map((color) => (
                    <div
                      key={color}
                      className={`w-10 h-10 rounded-md flex items-center justify-center ${state.color === color ? 'border border-slate-300' : ''} cursor-pointer`}
                      onClick={() => handleColorSelection(color)}
                    >
                      <div className={`w-6 h-6 rounded-full flex items-center justify-center ${color}`}>
                        {state.color === color &&
                          <div className="text-white">
                            <CheckIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <DrawerFooterContainerLarge>
            <div className="w-full">
              <LoadingButton
                fullWidth
                loading={props.isSavingData}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="contained"
                onClick={() => handleSubmit()}
                sx={{
                  textTransform: "none",
                  borderRadius: "50px",
                  paddingY: "14px",
                  backgroundColor: "#6d28d9",
                  "&:hover": {
                    backgroundColor: "#5b21b6",
                  },
                  fontSize: "16px",
                }}
              >
                Save
              </LoadingButton>
            </div>
            <div className="w-full mt-3">
              <ButtonOutlined text="Cancel" onClick={props.onClose} />
            </div>
          </DrawerFooterContainerLarge>
          <div className="h-[200px]"></div>
        </div>
      </div>
    </Drawer>
  );
}

export default RoleSettingsDrawer;
