import React from "react";
import Pill from "../../shared/helpers/Pill";
import { add_shift_attributes, getInitials } from "../../utilities/helpers";
import { formatDateReadable } from "../../utilities/helpers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import EmptyState from "../../shared/helpers/EmptyState";
import AvatarSmall from "../../shared/helpers/AvatarSmall";

export default function ShiftPoolApprovedTable(props) {
  let shiftPoolHistory = props.requestResponsesData
    .filter((v, i) => v["shift_pickup"] === null)
    .map((v, i) => ({
      ...v,
      shift_drop: add_shift_attributes(v.shift_drop),
    }))
    .sort((a, b) =>
      b.acting_manager_response_at?.localeCompare(a.acting_manager_response_at)
    );

  // props.archive_shift_pickup_request

  return (
    <div className="mt-12">
      {shiftPoolHistory.length > 0 ? (
        <div>
          <div className="flex items-center px-6">
            <div className="flex-auto">
              <h1 className="text-3xl text-slate-900">Acted On</h1>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[20%] py-3.5 pl-6 pr-3 text-left text-sm font-medium text-slate-500"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                        >
                          Shift To Pickup
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm font-medium text-slate-500"
                        >
                          Role
                        </th>
                        {/* <th scope="col" className="w-[20%] px-3 py-3.5 text-left text-sm font-medium text-slate-500">
                          Status
                        </th> */}
                        <th
                          scope="col"
                          className="w-1/12 relative py-3.5 pl-3 pr-20 text-right font-medium text-sm text-slate-500"
                        >
                          <span className="">Status</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {shiftPoolHistory.map((shift, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-3 pl-6 pr-3 text-sm">
                            <div className="flex items-center">
                              <div className="h-8 w-8 flex-shrink-0">
                                <AvatarSmall
                                  initials={getInitials(
                                    shift.employee_pickup.first_name,
                                    shift.employee_pickup.last_name
                                  )}
                                />
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {shift.employee_pickup.first_name}{" "}
                                  {shift.employee_pickup.last_name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {formatDateReadable(shift.shift_drop.date)}
                            </div>
                            <div className="text-gray-500">
                              {shift.shift_drop.shift}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {shift.shift_drop.station
                                ? shift.shift_drop.station?.name
                                : shift.shift_drop.role
                                ? shift.shift_drop.role
                                : shift.shift_drop.roleType}
                            </div>
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                            <div className="flex items-center justify-start">
                              {person.requests}
                            </div>
                          </td> */}
                          <td className="relative whitespace-nowrap py-3 pl-3 pr-6 text-right text-sm font-medium">
                            <div className="flex items-center justify-end">
                              <div className="mr-3">
                                <Pill status={shift.acting_manager_response} />
                              </div>
                              <Tooltip title="Archive">
                                <IconButton
                                  className="text-gray-500 ml-4"
                                  sx={{ backgroundColor: "#f8fafc" }}
                                  onClick={() =>
                                    props.archive_shift_pickup_request(
                                      shift.uuid
                                    )
                                  }
                                >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          title="Acted On"
          description="No shift pool actions at the moment"
        />
      )}
    </div>
  );
}
