import React from 'react'
import { getInitials } from '../requests/utilities/helpers'
import { getCardColor2 } from '../../../helpers/helpers';
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FiberPinIcon from "@mui/icons-material/FiberPin";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import UserProfileAttributesTab from './UserProfileAttributesTab';
import EmployeeProfileCertificationsTab from '../requests/manager/employees/EmployeeProfileCertificationsTab';
import EmployeeProfileStatisticsTab from '../requests/manager/employees/EmployeeProfileStatisticsTab';
import Drawer from "@mui/material/Drawer";
import DrawerHeader from '../requests/shared/helpers/DrawerHeader';
import DrawerFooterContainerLarge from '../requests/shared/helpers/DrawerFooterContainerLarge';
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ButtonOutlined from '../requests/shared/helpers/ButtonOutlined';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { formatDateReadable } from '../requests/utilities/helpers';

function UserProfileForEmployee(props) {

  const default_data = {
    first_name: "",
    last_name: "",
    email: "",
    phone: null,
    clock_pin: null,
    stm_id: null,
    date_of_birth: null,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalData, setModalData] = React.useState(default_data);

  const [activeTab, setActiveTab] = React.useState("Attributes");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const avatarCardColor = props.selectedTeamMemberData && props.roleNames ? getCardColor2(props.selectedTeamMemberData?.primary_role_id, props.roleNames, false) : "bg-slate-500";

  console.log(avatarCardColor)

  const avatarCardColor2 = avatarCardColor.trim();

  let backgroundGradientColor = "";

  if (avatarCardColor2 === "bg-violet-600") {
    backgroundGradientColor = "via-violet-200";
  } else if (avatarCardColor2 === "bg-sky-700") {
    backgroundGradientColor = "via-sky-200";
  } else if (avatarCardColor2 === "bg-cyan-600") {
    backgroundGradientColor = "via-cyan-200";
  } else if (avatarCardColor2 === "bg-emerald-600") {
    backgroundGradientColor = "via-emerald-200";
  } else if (avatarCardColor2 === "bg-lime-600") {
    backgroundGradientColor = "via-lime-200";
  } else if (avatarCardColor2 === "bg-amber-500") {
    backgroundGradientColor = "via-amber-200";
  } else if (avatarCardColor2 === "bg-orange-600") {
    backgroundGradientColor = "via-orange-200";
  } else if (avatarCardColor2 === "bg-red-600") {
    backgroundGradientColor = "via-red-200";
  } else if (avatarCardColor2 === "bg-pink-600") {
    backgroundGradientColor = "via-pink-200";
  } else {
    backgroundGradientColor = "via-slate-200";
  }

  console.log("SELECTED TEAM MEMBER DATA", props.selectedTeamMemberData)

  let permissionInfo = {
    label: "",
    style: "",
  };
  if (props.selectedTeamMemberData?.permission_type === "company_admin") {
    permissionInfo = {
      label: "Admin",
      style: "border border-violet-600 bg-violet-50 text-violet-600",
    };
  } else if (props.selectedTeamMemberData?.permission_type === "company_manager") {
    permissionInfo = {
      label: "Manager",
      style: "border border-orange-600 bg-orange-50 text-orange-600",
    };
  } else if (props.selectedTeamMemberData?.permission_type === "company_timeclock") {
    permissionInfo = {
      label: "Time Clock",
      style: "border border-yellow-600 bg-yellow-50 text-yellow-700",
    };
  } else {
    permissionInfo = {
      label: "Employee",
      style: "border border-blue-600 bg-blue-50 text-blue-600",
    };
  }

  const handleDateChange = (newValue) => {
    const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : "";
    setModalData((prevData) => ({
      ...prevData,
      date_of_birth: formattedDate,
    }));
  };

  function handleChange(evt) {
    const { name, value } = evt.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  }

  function handleSubmit() {
    props.post_store_team_member(
      modalData.first_name,
      modalData.last_name,
      modalData.email,
      modalData.phone,
      modalData.permission_type,
      modalData.stm_id,
      modalData.clock_pin,
      modalData.date_of_birth
    );
    handleClose();
  }

  console.log("MODAL DATA", modalData)

  // Code to show date picker
  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]);


  return (
    <>
      <div>
        <div
          className={`w-full h-24 bg-gradient-to-r from-slate-50 ${backgroundGradientColor} to-slate-50 flex justify-center relative`}
        >
          <div
            className={`w-20 h-20 rounded-full flex items-center justify-center ${avatarCardColor} text-white text-2xl absolute -bottom-10`}
          >
            <p>
              {props.selectedTeamMemberData &&
                getInitials(
                  props.selectedTeamMemberData?.first_name,
                  props.selectedTeamMemberData?.last_name
                )}
            </p>
          </div>
        </div>
        <div className="mt-14 flex flex-col justify-center items-center">
          <div className="relative">
            <p className="text-3xl text-slate-900">
              {props.selectedTeamMemberData?.first_name}{" "}
              {props.selectedTeamMemberData?.last_name}
            </p>
            <div className="absolute -right-10 bottom-0.5">
              <div
                className="w-8 h-8 rounded-full bg-slate-100 flex items-center justify-center text-slate-500"
                onClick={() => {
                  handleOpen();
                  setModalData({
                    first_name: props.selectedTeamMemberData?.first_name,
                    last_name: props.selectedTeamMemberData?.last_name,
                    email: props.selectedTeamMemberData?.email,
                    phone: props.selectedTeamMemberData?.phone_number,
                    clock_pin: props.selectedTeamMemberData?.clock_pin,
                    permission_type: props.selectedTeamMemberData?.permission_type,
                    stm_id: props.selectedTeamMemberData?.id,
                    date_of_birth: props.selectedTeamMemberData?.date_of_birth
                  })
                }}
              >
                <Tooltip title="Edit employee info">
                  <IconButton>
                    <EditIcon style={{ fontSize: "16px" }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-1.5 text-slate-500 mt-3">
            <div>
              <EmailIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
            </div>
            <p>
              {props.selectedTeamMemberData?.email
                ? props.selectedTeamMemberData?.email
                : "None"}
            </p>
          </div>
          <div className="flex items-center space-x-1.5 text-slate-500 mt-1">
            <div>
              <PhoneIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
            </div>
            <p>
              {props.selectedTeamMemberData?.phone_number
                ? props.selectedTeamMemberData?.phone_number
                : "None"}
            </p>
          </div>
          {props.timeEntriesEnabled && (
            <div className="flex items-center space-x-1.5 text-slate-500 mt-1">
              <div>
                <FiberPinIcon
                  style={{ fontSize: "18px", marginBottom: "2px" }}
                />
              </div>
              <p>
                {props.selectedTeamMemberData?.clock_pin
                  ? props.selectedTeamMemberData?.clock_pin
                  : "None"}
              </p>
            </div>
          )}
          <div className="flex items-center space-x-1 mt-1.5">
            <div
              className={`${permissionInfo.style} px-3 py-1 rounded-lg flex items-center justify-center`}
            >
              <p className="text-sm">{permissionInfo.label}</p>
            </div>
            {props.selectedTeamMemberData?.is_minor &&
              <div
                className={`bg-slate-100 border border-slate-400 text-slate-600 px-3 h-8 rounded-lg flex items-center justify-center`}
              >
                <p className="text-sm">Minor</p>
              </div>
            }
          </div>
        </div>
        <div className="w-full flex justify-center border-y border-slate-200 mt-8">
          <div className="flex items-center space-x-20">
            <div
              className={`${
                activeTab === "Attributes"
                  ? "text-slate-900 border-b-2 border-slate-900 font-bold"
                  : "text-slate-500"
              } py-4 cursor-pointer`}
              onClick={() => handleTabClick("Attributes")}
            >
              <p>Attributes</p>
            </div>
            <div
              className={`${
                activeTab === "Certifications"
                  ? "text-slate-900 border-b-2 border-slate-900 font-bold"
                  : "text-slate-500"
              } py-4 cursor-pointer`}
              onClick={() => handleTabClick("Certifications")}
            >
              <p>Documents</p>
            </div>
            {/* <div className={`${activeTab === "Training" ? "text-slate-900 border-b-2 border-slate-900 font-bold" : "text-slate-500"} py-4 cursor-pointer`} onClick={() => handleTabClick("Training")}>
              <p>Training</p>
            </div> */}
            <div className={`${activeTab === "Statistics" ? "text-slate-900 border-b-2 border-slate-900 font-bold" : "text-slate-500"} py-4 cursor-pointer`} onClick={() => handleTabClick("Statistics")}>
              <p>Statistics</p>
            </div>
          </div>
        </div>
        <div className="w-full min-h-[600px] pt-10 pb-24 bg-slate-50">
            {activeTab === "Attributes" ? (
              <UserProfileAttributesTab
                selectedTeamMemberData={props.selectedTeamMemberData}
                roleNames={props.roleNames}
                homeStore={props.homeStore}
              />
            ) : activeTab === "Certifications" ? (
              <div>
                <EmployeeProfileCertificationsTab
                  certificateData={props.selectedTeamMemberData?.user?.certificates}
                  post_certificate={props.post_certificate}
                  delete_certificate={props.delete_certificate}
                  stm_id={props.selectedTeamMemberData?.id}
                />
              </div>
            ) : activeTab === "Training" ? (
              <div>Training</div>
            ) : activeTab === "Statistics" ? (
              <div>
                <EmployeeProfileStatisticsTab
                  selectedTeamMemberData={props.selectedTeamMemberData}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
      </div>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <div className="w-[450px] h-full bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={"Edit Profile Info"}
              onClose={handleClose}
            />
            <div className="px-6">
              <div className="mt-4">
                <p className="text-sm text-slate-500">First Name</p>
                <div className="mt-1">
                  <TextField
                    placeholder="John"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="first_name"
                    value={modalData?.first_name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-slate-500">Last Name</p>
                <div className="mt-1">
                  <TextField
                    placeholder="Smith"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="last_name"
                    value={modalData?.last_name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-slate-500">Email</p>
                <div className="mt-1">
                  <TextField
                    placeholder="email@email.com"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={modalData?.email}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-slate-500">Phone</p>
                <div className="mt-1">
                  <TextField
                    placeholder="(555) 555-5555"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="phone"
                    value={modalData?.phone}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              {props.timeEntriesEnabled &&
                <div className="mt-4">
                  <p className="text-sm text-slate-500">Pin</p>
                  <div className="mt-1">
                    <TextField
                      placeholder="1234"
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="clock_pin"
                      value={modalData?.clock_pin}
                      onChange={handleChange}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              }
              <div className="mt-4">
                <p className="text-sm text-slate-500">Date of Birth</p>
                <div className={`${!showDailyDatePicker ? "border border-white" : ""} flex-1 mt-1`}>
                  <div
                    className={`flex-1 bg-white rounded-lg text-sm ${showDailyDatePicker ? "border-2 border-violet-700" : "border border-[#bdbdbd] hover:border-slate-900"} cursor-pointer relative`}
                    ref={datePickerRef}
                  >
                    <div
                      className="w-full h-full px-3.5 py-2.5"
                      onClick={toggleDailyDatePicker}
                    >
                      <div
                        className="w-full h-full flex items-center justify-between"
                      >
                        <p className="text-[16px]">{modalData.date_of_birth ? formatDateReadable(modalData.date_of_birth) : "Click to select"}</p>
                        <div className="text-slate-500">
                          <CalendarTodayIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                        </div>
                      </div>
                    </div>
                    {showDailyDatePicker && (
                      <div className="absolute bottom-12 left-0 bg-white z-10 flex items-center justify-center pl-4 pr-5 border border-[#bdbdbd] rounded-lg shadow-xl overflow-hidden">
                        <div className="mt-3">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                              <DemoItem>
                                <DateCalendar
                                  value={modalData?.date_of_birth ? dayjs(modalData?.date_of_birth) : null}
                                  onChange={handleDateChange}
                                  slotProps={{
                                    day: {
                                      sx: {
                                        "&.MuiPickersDay-root.Mui-selected": {
                                          backgroundColor: "#6d28d9",
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoItem>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[150px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Save Profile
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default UserProfileForEmployee
