import React from 'react'
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";

function NoShiftBar(props) {
  return (
    <div className="w-full bg-amber-100 px-6 py-5 rounded-2xl flex flex-row items-center space-x-4 text-amber-800">
      <WbSunnyOutlinedIcon />
      <p className="text-amber-800 text-base">
        {props.text}
      </p>
    </div>
  )
}

export default NoShiftBar
