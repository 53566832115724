import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import "react-toastify/dist/ReactToastify.css";

function DeleteWarningModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <div className="text-rose-800">
                <WarningIcon style={{ marginBottom: "1px" }} />
              </div>
              <h3 className="text-2xl text-slate-900">Are you sure?</h3>
            </div>
            <div>
              <p className="text-slate-500 text-sm mt-4">Are you sure you want to delete this {props.type}?</p>
              <p className="text-slate-500 text-sm mt-4">Deleting this {props.type} will delete it from all corresponding {props.type === "track" ? "enrollments" : props.type === "module" ? "tracks" : props.type === "section" ? "modules" : props.type === "quiz" ? "sections" : ""} that it belongs to.</p>
              <p className="text-slate-500 text-sm mt-4">Only perform this action if you are sure that this {props.type} should no longer be used.</p>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                {props.type === "section" ?
                  <div className="px-8 py-2 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      props.removeAllBlocks()
                      props.delete_function()
                      props.handleClose()
                      props.handleCloseInputsModal()
                    }}
                  >
                    Delete Anyway
                  </div> :
                  <div className="px-8 py-2 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.delete_function()
                    props.handleClose()
                    props.handleCloseInputsModal()
                  }}
                >
                  Delete Anyway
                </div>
              }
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default DeleteWarningModal
