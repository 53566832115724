import React, { useState, useEffect } from "react";
import ContestDropdown from "./ContestDropdown.js";
import Divider from "@mui/material/Divider";
import TargetMetricCard from "./TargetMetricCard.js";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GoalTable from "./GoalTable.js";
import ContestLeaderboard from "./ContestLeaderboard.js";
import ContestInformation from "./ContestInformation.js";
import ExpectedPayoutCard from "./ExpectedPayoutCard.js";

export default function ContestDisplay(props) {
  const [selectedWeekStart, setSelectedWeekStart] = React.useState(null);
  const [selectedWeekEnd, setSelectedWeekEnd] = React.useState(null);

  useEffect(() => {
    if (props.contestInfo.contest_info) {
      const week_start_date = getMondayOfDateWeek(
        props.contestInfo.contest_info.start_date
      );
      const week_end_date = addDays(week_start_date, 6);
      setSelectedWeekStart(week_start_date);
      setSelectedWeekEnd(week_end_date);
    }
  }, [props.contestInfo]);

  const [displayLeaderboard, setDisplayLeaderboard] = React.useState(true);
  const [displayWeeklyView, setDisplayWeeklyView] = React.useState(true);

  function handleLeadboardTabClick() {
    setDisplayLeaderboard(true);
    setDisplayWeeklyView(false);
  }

  function handleWeeklyViewTabClick() {
    setDisplayLeaderboard(false);
    setDisplayWeeklyView(true);
  }

  function handleAllTabClick() {
    setDisplayLeaderboard(true);
    setDisplayWeeklyView(true);
  }

  function getMondayOfDateWeek(date) {
    const new_date = new Date(date);
    const first = new_date.getDate() - new_date.getDay() + 1;

    const monday = new Date(new_date.setDate(first));
    return monday;
  }

  function increment_week(start_date, add_week) {
    const new_start_date = addDays(start_date, add_week * 7);
    setSelectedWeekStart(new_start_date);
    setSelectedWeekEnd(addDays(new_start_date, 6));
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  const date_options = { month: "short", day: "numeric", year: "numeric" };
  console.log(props.selectedDropdown);
  return (
    <div className="px-32">
      <div className="flex items-center gap-5 mt-10">
        <div className="flex flex-col items-center">
          <div className="w-7 h-7 rounded-full border border-gray-700 flex justify-center items-center text-gray-700">
            <h5>
              {props.contests.current ? props.contests.current.length : 0}
            </h5>
          </div>
          <div className="mt-2">
            <ContestDropdown
              type="Ongoing"
              currentContestId={props.currentContestId}
              handleChange={props.handleChangeContest}
              options={props.contests.current}
              selectedDropdown={props.selectedDropdown}
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-7 h-7 rounded-full border border-gray-700 flex justify-center items-center text-gray-700">
            <h5>
              {props.contests.upcoming ? props.contests.upcoming.length : 0}
            </h5>
          </div>
          <div className="mt-2">
            <ContestDropdown
              type="Upcoming"
              currentContestId={props.currentContestId}
              handleChange={props.handleChangeContest}
              options={props.contests.upcoming}
              selectedDropdown={props.selectedDropdown}
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-7 h-7 rounded-full border border-gray-700 flex justify-center items-center text-gray-700">
            <h5>
              {props.contests.previous ? props.contests.previous.length : 0}
            </h5>
          </div>
          <div className="mt-2">
            <ContestDropdown
              type="Previous"
              currentContestId={props.currentContestId}
              handleChange={props.handleChangeContest}
              options={props.contests.previous}
              selectedDropdown={props.selectedDropdown}
            />
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Divider />
      </div>
      {props.contestInfo.contest_info && selectedWeekStart && (
        <div>
          <div className="mt-8 flex gap-10">
            <div className="w-[52%]">
              <ContestInformation
                contestTitle={props.contestInfo.contest_info.name}
                startDate={props.contestInfo.contest_info.start_date}
                endDate={props.contestInfo.contest_info.end_date}
                contestType={props.contestInfo.contest_info.contest_type}
                participatingStores={
                  props.contestInfo.contest_info.participating_stores
                }
                description={props.contestInfo.contest_info.description}
              />
            </div>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "20px", marginRight: "20px" }}
            />
            <div className="flex items-center justify-center">
              <ExpectedPayoutCard
                contest_metric_detail={props.contestInfo.contest_metric_detail}
              />
            </div>
          </div>
          <div className="mt-8">
            <Divider />
          </div>
          <div className="w-full flex justify-start items-center text-sm pl-8">
            <button
              className={`${
                displayWeeklyView && displayLeaderboard
                  ? "bg-primary text-white"
                  : "border-b border-x border-gray-400 hover:bg-gray-300"
              } rounded-lg rounded-t-none px-8 py-2`}
              onClick={handleAllTabClick}
            >
              All
            </button>
            <button
              className={`${
                displayWeeklyView && !displayLeaderboard
                  ? "bg-primary text-white"
                  : "border-b border-x border-gray-400 hover:bg-gray-300"
              } rounded-lg rounded-t-none px-8 py-2`}
              onClick={handleWeeklyViewTabClick}
            >
              Weekly View
            </button>
            <button
              className={`${
                displayLeaderboard && !displayWeeklyView
                  ? "bg-primary text-white"
                  : "border-b border-x border-gray-400 hover:bg-gray-300"
              } rounded-lg rounded-t-none px-8 py-2`}
              onClick={handleLeadboardTabClick}
            >
              {props.contestInfo.contest_info.contest_type === "competition"
                ? "Leaderboard"
                : "Summary View"}
            </button>
          </div>
          {displayWeeklyView && (
            <div className="mt-8">
              <div className="w-full flex items-center">
                <IconButton
                  disabled={
                    formatDate(
                      getMondayOfDateWeek(
                        props.contestInfo.contest_info.start_date
                      )
                    ) === formatDate(selectedWeekStart)
                  }
                  onClick={() => increment_week(selectedWeekStart, -1)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <div className="w-72 flex justify-center items-center">
                  <h2 className="text-lg font-bold py-1">
                    {selectedWeekStart.toLocaleDateString(
                      "en-us",
                      date_options
                    )}{" "}
                    -{" "}
                    {selectedWeekEnd.toLocaleDateString("en-us", date_options)}
                  </h2>
                </div>
                <IconButton
                  disabled={
                    formatDate(
                      getMondayOfDateWeek(
                        props.contestInfo.contest_info.end_date
                      )
                    ) === formatDate(selectedWeekStart)
                  }
                  onClick={() => increment_week(selectedWeekStart, 1)}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>
              <div className="text-gray-800 mt-2 flex flex-wrap items-center gap-10">
                {props.contestInfo.contest_metric_detail.map(
                  (object, index) => (
                    <TargetMetricCard
                      title={object.name}
                      week_data={object.metric_weekly_info.filter(
                        (v, i) =>
                          v.week_start_date === formatDate(selectedWeekStart)
                      )}
                      metric_detail={object}
                      contest_info={props.contestInfo.contest_info}
                      addUnits={props.addUnits}
                    />
                  )
                )}
              </div>
              <div className="mt-10">
                <GoalTable
                  week_start_date={getMondayOfDateWeek(
                    props.contestInfo.contest_info.start_date
                  )}
                  week_end_date={getMondayOfDateWeek(
                    props.contestInfo.contest_info.end_date
                  )}
                  selectedWeekStart={selectedWeekStart}
                  formatDate={formatDate}
                  contest_metric_detail={
                    props.contestInfo.contest_metric_detail
                  }
                  addDays={addDays}
                  addUnits={props.addUnits}
                />
              </div>
            </div>
          )}
          {displayWeeklyView && displayLeaderboard && (
            <div className="mt-12">
              <Divider />
            </div>
          )}
          {displayLeaderboard && (
            <div className="flex justify-between flex-wrap mt-4">
              {props.contestInfo.contest_metric_detail.map((metric, index) => (
                <ContestLeaderboard
                  index={index}
                  metricName={metric.name}
                  metric_detail={metric}
                  homeStoreInfo={metric.store_aggregate_info}
                  otherStoresInfo={metric.leaderboard_info}
                  contest_type={props.contestInfo.contest_info.contest_type}
                  participating_stores={
                    props.contestInfo.contest_info.participating_stores
                  }
                  addUnits={props.addUnits}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <div className="h-20"></div>
    </div>
  );
}
