import React from "react";
import { CoverageGraphD3 } from "./CoverageGraphD3";

export function CoverageGraph({ data, chartid, boxHeight, hoo }) {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    let destroyFn;

    if (containerRef.current) {
      const { destroy } = CoverageGraphD3(
        containerRef.current,
        data,
        chartid,
        boxHeight,
        hoo
      );
      destroyFn = destroy;
    }

    return destroyFn;
  }, [data]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <section className="Main" style={{ textAlign: "center" }}>
        <div id={chartid} ref={containerRef} />
      </section>
    </div>
  );
}
