import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Skeleton from '@mui/material/Skeleton';

function SkeletonDisplay() {
  return(
    <div>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="flex flex-col h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} width={50} />
            <div className="flex items-center gap-2">
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={55} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={28} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={55} />
            </div>
            <Skeleton variant="text" sx={{ fontSize: '1.4rem' }} width={150} />
          </div>
          <Skeleton variant="rounded" width={150} height={30} />
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
          <div className="flex items-center gap-2" style={{ height: "50px" }}>
            <Skeleton variant="circular" width={40} height={40} />
            <div>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
            </div>
          </div>
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem', marginLeft: "10px" }} width={95} />
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={50} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={40} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
          </div>
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem', marginLeft: "10px" }} width={95} />
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={50} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={40} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
          </div>
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem', marginLeft: "10px" }} width={95} />
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={50} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={40} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
          </div>
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem', marginLeft: "10px" }} width={95} />
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={50} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={40} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
          </div>
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem', marginLeft: "10px" }} width={95} />
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={50} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={40} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
          </div>
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem', marginLeft: "10px" }} width={95} />
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={50} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={40} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
          </div>
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          divider={<Divider variant="inset" />}
        >
          <div className="h-[90px]">
            <Skeleton variant="text" sx={{ fontSize: '1.4rem', marginLeft: "10px" }} width={95} />
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={50} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
            <div className="flex items-center justify-between ml-2 mr-2">
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={40} />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }} width={30} />
            </div>
          </div>
          <Skeleton variant="rounded" width={120} height={30} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
          <Skeleton variant="rounded" width={120} height={50} />
        </Stack>
      </Stack>
    </div>
  )
}

export default SkeletonDisplay
