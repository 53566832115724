import React from 'react'

export default function TabsTwo(props) {
  return (
    <div className={`w-full bg-slate-50 flex flex-row p-1 ${props.type === "TopBar" ? "rounded-lg" : "rounded-2xl"}`}>
      <button
        className={`w-1/2 flex justify-center items-center ${props.type === "TopBar" ? "py-1.5 rounded-md" : "py-3 rounded-xl"} ${props.activeTab === props.tab1 ? "bg-white shadow" : ""}`}
        onClick={() => props.handleTabButtonPress(props.tab1)}
      >
        <p className={`text-sm ${props.activeTab === props.tab1 ? "text-violet-700 font-semibold" : "text-slate-500"}`}>{props.tab1}</p>
      </button>
      <button
        className={`w-1/2 flex justify-center items-center ${props.type === "TopBar" ? "py-1.5 rounded-md" : "py-3 rounded-xl"} ${props.activeTab === props.tab2 ? "bg-white shadow" : ""}`}
        onClick={() => props.handleTabButtonPress(props.tab2)}
      >
        <p className={`text-sm ${props.activeTab === props.tab2 ? "text-violet-700 font-semibold" : "text-slate-500"}`}>{props.tab2}</p>
      </button>
    </div>
  )
}
