import React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import { formatDateReadable } from "../../utilities/helpers";
import { getInitials } from "../../utilities/helpers";
import CloseIcon from "@mui/icons-material/Close";
import ShiftPoolBidCard from "./ShiftPoolBidCard";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InfoIcon from "@mui/icons-material/Info";
import { Drawer } from "@mui/material";
import ButtonOutlined from "../../shared/helpers/ButtonOutlined";
import ButtonFilled from "../../shared/helpers/ButtonFilled";
import ButtonGrayed from "../../shared/helpers/ButtonGrayed";
import DrawerHeader from "../../shared/helpers/DrawerHeader";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";

export default function ShiftPoolRequestsTableRow(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedBid, setSelectedBid] = React.useState(null);

  const handleBidSelect = (bidName) => {
    setSelectedBid(bidName);
  };

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  function handleDeny() {
    props.manager_reject_shift_drop(props.id);
    handleClose();
    setDrawerOpen(false);
  }

  function handleApprove() {
    props.manager_approve_shift_pickup(selectedBid, "approved");
    handleClose();
    setDrawerOpen(false);
  }

  return (
    <>
      <tr
        className="hover:bg-slate-50 cursor-pointer"
        onClick={toggleDrawer(true)}
      >
        <td className="whitespace-nowrap py-3 pl-6 pr-3 text-sm">
          <div>
            {/* <div className="h-10 w-10 flex-shrink-0">
              <div className={`h-10 w-10 rounded-full bg-primary text-white flex items-center justify-center ${props.status === "AVAILABLE" ? "text-xs" : ""}`}>{props.status === "PENDING_DROP" ? getInitials(props.name) : "Open"}</div>
            </div> */}
            <div className="flex items-center space-x-2">
              <div
                className={`${
                  props.status === "PENDING_DROP"
                    ? "text-rose-600"
                    : "text-emerald-600"
                }`}
              >
                {props.status === "PENDING_DROP" ? (
                  <RemoveCircleIcon style={{ fontSize: "14px" }} />
                ) : (
                  <AddCircleIcon style={{ fontSize: "14px" }} />
                )}
              </div>
              <div
                className={`font-medium ${
                  props.status === "PENDING_DROP"
                    ? "text-rose-900"
                    : "text-emerald-800"
                }`}
              >
                {props.status === "PENDING_DROP" ? "Dropped by" : "Open shift"}
              </div>
            </div>
            {props.status === "PENDING_DROP" && (
              <div className="flex items-center space-x-2 -ml-1">
                <div className="w-5 h-5 bg-slate-100 rounded-full flex items-center justify-center">
                  <p className="text-slate-500 text-xxxs">
                    {getInitials(props.name)}
                  </p>
                </div>
                <p className="text-slate-500">{props.name}</p>
              </div>
            )}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-slate-500">
          <div className="text-slate-900">{formatDateReadable(props.date)}</div>
          <div className="text-slate-500">{props.shift}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-slate-500">
          <div className="text-slate-900">
            {props.station
              ? props.station
              : props.role
              ? props.role
              : props.roleType}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-3 text-sm text-slate-500">
          {props.note &&
            <div>
              <Tooltip title={props.note}>
                <CommentIcon />
              </Tooltip>
            </div>
          }
        </td>
        <td className="whitespace-nowrap pl-3 pr-6 py-3 text-sm text-slate-500">
          {props.bids.length > 0 ? (
            <div className="flex items-center justify-end">
              <AvatarGroup max={4}>
                {props.bids.map((bid) => (
                  <Avatar
                    sx={{
                      width: 34,
                      height: 34,
                      fontSize: 14,
                      backgroundColor: "#f8fafc",
                      color: "#64748b",
                    }}
                  >
                    {bid.employee_pickup.first_name[0]}
                    {bid.employee_pickup.last_name[0]}
                  </Avatar>
                ))}
              </AvatarGroup>
            </div>
          ) : (
            <div className="text-slate-900 text-right">No Bids Yet</div>
          )}
        </td>
        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
          {props.bids.length > 0 ?
            <div className="bg-primary hover:bg-blue-700 text-white px-5 py-2 rounded-lg flex items-center justify-center cursor-pointer" onClick={handleOpen}>Approve / Deny</div> :
            <div className="bg-slate-200 text-slate-400 px-5 py-2 rounded-lg flex items-center justify-center">Approve / Deny</div>
          }
        </td> */}
      </tr>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-2xl font-semibold">Shift Pool Request</h2>
          <div>
            <h3 className="text-base font-semibold mt-5">Shift</h3>
            <div className="mt-2">
              <Divider />
            </div>
            <div className="flex items-center mt-3">
              <div className="h-14 w-14 flex-shrink-0">
                <div
                  className={`h-14 w-14 rounded-full bg-primary text-white flex items-center justify-center ${
                    props.status === "AVAILABLE" ? "text-sm" : ""
                  }`}
                >
                  {props.status === "PENDING_DROP"
                    ? getInitials(props.name)
                    : "Open"}
                </div>
              </div>
              <div className="ml-4">
                <p className="font-medium text-slate-900">
                  {props.status === "PENDING_DROP" ? props.name : "Open Shift"}
                </p>
                <p className="text-sm text-slate-500">
                  {formatDateReadable(props.date)}{" "}
                  <span className="ml-1">({props.shift})</span>
                </p>
                <div className="mt-1 flex items-center gap-1">
                  <CircleIcon style={{ color: "orange", fontSize: "10px" }} />
                  <p className="text-xs font-semibold text-slate-900">
                    {props.station
                      ? props.station
                      : props.role
                      ? props.role
                      : props.roleType}
                  </p>
                </div>
              </div>
            </div>
            <h3 className="text-base font-semibold mt-8">Requests</h3>
            <div className="mt-2">
              <Divider />
            </div>
            {props.bids.length > 0 ? (
              <div>
                {props.bids.map((bid, i) => (
                  <ShiftPoolBidCard
                    key={i}
                    isSelected={selectedBid === bid.uuid}
                    info={bid}
                    hours={props.hours}
                    handleBidSelect={() => handleBidSelect(bid.uuid)}
                  />
                ))}
              </div>
            ) : (
              <div className="mt-3">
                <p>No bids yets</p>
              </div>
            )}
          </div>
          <div className="w-full mt-6 flex justify-end">
            <div className="flex items-center gap-5">
              {props.status === "PENDING_DROP" && (
                <div
                  className="px-8 py-3 rounded-lg border border-primary text-primary hover:bg-slate-100 text-sm flex items-center justify-center cursor-pointer"
                  onClick={() => handleDeny()}
                >
                  Deny Drop
                </div>
              )}
              {selectedBid ? (
                <div
                  className="px-8 py-3 rounded-lg bg-primary hover:bg-blue-700 text-white text-sm flex items-center justify-center cursor-pointer"
                  onClick={() => handleApprove()}
                >
                  Assign to Selected
                </div>
              ) : (
                <div
                  className="px-8 py-3 rounded-lg bg-gray-200 text-gray-400 text-sm flex items-center justify-center"
                  onClick={() => handleApprove()}
                >
                  Assign to Selected
                </div>
              )}
            </div>
          </div>
          <div
            className="absolute right-4 top-4 text-slate-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-full bg-slate-50 relative">
          <DrawerHeader
            title="Shift Pickup Request"
            onClose={toggleDrawer(false)}
          />
          <div className="mt-8 px-6">
            {props.status === "PENDING_DROP" ? (
              <div className="w-full bg-rose-50 rounded-xl border border-rose-100 p-3 flex flex-row items-center space-x-1.5">
                <div className="text-rose-600">
                  <RemoveCircleIcon />
                </div>
                <p className="text-rose-800 text-lg">Dropped by</p>
                <div className="flex flex-row items-center space-x-1.5 pl-2">
                  <p className="text-slate-500 text-lg">{props.name}</p>
                </div>
              </div>
            ) : (
              <div className="w-full bg-emerald-50 rounded-xl border border-emerald-100 p-3 flex flex-row items-center space-x-1.5">
                <div className="text-emerald-600">
                  <AddCircleIcon />
                </div>
                <p className="text-emerald-800 text-lg">Open shift</p>
              </div>
            )}
            <div className="w-full flex items-center mt-4">
              <div className="w-1/2">
                <div>
                  <p className="text-slate-500 text-base">Date</p>
                  <p className="text-slate-900 text-lg">
                    {formatDateReadable(props.date)}
                  </p>
                </div>
                <div className="mt-2">
                  <p className="text-slate-500 text-base">Break</p>
                  <p className="text-slate-900 text-lg">
                    {props.breakFormatted}
                  </p>
                </div>
              </div>
              <div className="w-1/2">
                <div>
                  <p className="text-slate-500 text-base">Time</p>
                  <p className="text-slate-900 text-lg">{props.shift}</p>
                </div>
                <div className="mt-2">
                  <p className="text-slate-500 text-base">Role</p>
                  <p className="text-slate-900 text-lg">
                    {props.station
                      ? props.station
                      : props.role
                      ? props.role
                      : props.roleType}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full mt-2">
              <div>
                <p className="text-slate-500 text-base">Note</p>
                <p className="text-slate-900 text-lg">
                  {props.note ? props.note : "No note"}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8 bg-white max-h-[400px] overflow-auto">
            <div className="px-6 pt-3">
              <p className="text-slate-500">
                {props.bids.length} {props.bids.length === 1 ? "bid" : "bids"}
              </p>
            </div>
            {props.bids.length > 0 ? (
              <div>
                {props.bids.map((bid, i) => (
                  <ShiftPoolBidCard
                    key={i}
                    isSelected={selectedBid === bid.uuid}
                    info={bid}
                    hours={props.hours}
                    handleBidSelect={() => handleBidSelect(bid.uuid)}
                  />
                ))}
              </div>
            ) : (
              <div className="w-full py-12 flex flex-col items-center justify-center">
                <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
                  <InfoIcon />
                </div>
                <p className="mt-2 text-sm">No bids yets</p>
              </div>
            )}
          </div>
          <div className="w-full absolute bottom-0 bg-white border-t border-slate-300 px-6">
            <div className="pb-4">
              {props.status === "PENDING_DROP" && (
                <div className="mt-4">
                  <ButtonOutlined
                    text="Deny Drop"
                    onClick={() => handleDeny()}
                  />
                </div>
              )}
              {selectedBid ? (
                <div className="w-full mt-4">
                  <ButtonFilled
                    text="Assign to selected"
                    onClick={() => handleApprove()}
                  />
                </div>
              ) : (
                <div className="w-full mt-4">
                  <ButtonGrayed text="Assign to selected" />
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
