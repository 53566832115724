
/*
 * Returns axios config object with authentication Token.
 */
export function getDefaultAxiosConfig() {
  return {
    headers:{
      Authorization: `Token ${localStorage.token}`,
    }
  };
}
