import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

export default function DailyDatePicker(props) {
  const [value, setValue] = React.useState(dayjs(props.date));

  function handleChange(newValue) {
    // Set the time portion to the same time for both props.date and newValue
    const propsDateWithTime = dayjs(props.date).startOf('day');
    const newValueWithTime = newValue.startOf('day');

    // Calculate the difference in days between props.date and the selected date
    const differenceInDays = newValueWithTime.diff(propsDateWithTime, 'day');

    // Update the state with the selected date
    setValue(newValue);

    // Call the increment_day function with the calculated difference
    props.increment_day(props.date, differenceInDays);
  }




  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateCalendar', 'DateCalendar']}>
        <DemoItem>
          <DateCalendar
            value={value}
            onChange={(newValue) => handleChange(newValue)}
            slotProps={{
              day: {
                sx: {
                  "&.MuiPickersDay-root.Mui-selected": {
                    backgroundColor: "#6d28d9",
                  },
                },
              },
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
