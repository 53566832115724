import React from "react";
import TimeOffRequestsTableRow from "./TimeOffRequestsTableRow";
import TimeOffApprovedCalendarModal from "./TimeOffApprovedCalendarModal";
import InfoIcon from '@mui/icons-material/Info';


export default function TimeOffRequestsTable(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const hasTimeOffRequests = props.timeOffRequests.length > 0;

  return (
    <>
      <div className="mt-12">
        <div className="flex items-center px-6">
          <div className="flex items-center gap-x-4">
            <h1 className="text-3xl text-slate-900">Requests</h1>
            <div
              className="px-5 py-2 bg-violet-50 hover:bg-violet-100 text-violet-700 text-xs rounded-lg h-fit cursor-pointer"
              onClick={handleOpen}
            >
              <p>Time Off Calendar</p>
            </div>
          </div>
        </div>
        <div className="mt-12 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-[20%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                      >
                        Employee
                      </th>
                      {/* <th scope="col" className="w-[16%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium">
                        Date Submitted
                      </th> */}
                      <th
                        scope="col"
                        className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                      >
                        Time Off Date(s)
                      </th>
                      <th
                        scope="col"
                        className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="w-1/12 relative py-3.5 pl-3 pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {props.timeOffRequests.map((request, index) => (
                      <TimeOffRequestsTableRow
                        key={index}
                        firstName={request.store_team_member.first_name}
                        lastName={request.store_team_member.last_name}
                        title={request.title}
                        note={request.note}
                        startDate={request.date_lower_bound}
                        endDate={request.date_upper_bound}
                        uuid={request.uuid}
                        respond_to_time_off_request={
                          props.respond_to_time_off_request
                        }
                      />
                    ))}
                  </tbody>
                </table>
                <div className="border-b border-slate-200"></div>
                {!hasTimeOffRequests &&
                  <div className="w-full mt-24 flex items-center justify-center">
                    <div className="w-full flex flex-col items-center justify-center">
                      <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
                        <InfoIcon />
                      </div>
                      <p className="mt-2 text-sm">You have no time off requests</p>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <TimeOffApprovedCalendarModal
        open={open}
        handleClose={handleClose}
        timeOffFutureApproved={props.timeOffFutureApproved}
      />
    </>
  );
}
