import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function LockAllButton(props) {
  return (
    <Tooltip
      title={props.lockAllShifts ? "Unlock Shifts" : "Lock All Current Shifts"}
    >
      <IconButton onClick={() => props.setLockAllShifts()}>
        <LockIcon color={props.lockAllShifts ? "success" : "default"} />
      </IconButton>
    </Tooltip>
  );
}

export default LockAllButton;
