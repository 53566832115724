import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function StoreName(props) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleChange = (event) => {
    props.change_home_store(event.target.value);
    setOpen(false);
  };

  const get_store_name = (storeId) => {
    const store_info = props.storeNames.find((s) => s.id === storeId);
    return store_info ? store_info["store_name"] : null;
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
  };

  const filteredStoreOptions = props.storeOptions.filter((s) =>
    get_store_name(s.store).toLowerCase().includes(searchTerm.toLowerCase())
  );

  React.useEffect(() => {
    setSearchTerm("")
  }, [open])

  return (
    <Box>
      <FormControl fullWidth size="small">
        {props.storeOptions.length > 1 ?
          <div
            ref={anchorRef}
            className="flex items-center gap-x-0.5 cursor-pointer"
            onClick={handleOpen}
          >
            <p className="text-slate-900">{get_store_name(props.homeStore)}</p>
            <div className="text-slate-500">
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>
          </div> :
          <div>
            <p className="text-slate-900">{get_store_name(props.homeStore)}</p>
          </div>
        }
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <Box p={2} width="300px">
            <TextField
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              size="small"
              autoFocus
              InputProps={{
                sx: {
                  backgroundColor: "white",
                  borderRadius: "8px",
                  padding: "5px",
                  height: "42px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6d28d9",
                  },
                },
              }}
            />
            <List>
              {filteredStoreOptions.map((s) => (
                <MenuItem
                  key={s.store}
                  value={s.store}
                  onClick={() => handleChange({ target: { value: s.store } })}
                  selected={props.homeStore === s.store}
                >
                  {get_store_name(s.store)}
                </MenuItem>
              ))}
            </List>
          </Box>
        </Popover>
      </FormControl>
    </Box>
  );
}
