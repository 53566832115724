import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import KeyIcon from "@mui/icons-material/Key";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";
import { API_PWD_CHANGE } from "../../../../constants";
import { getDefaultAxiosConfig } from "../../../../utilities/requests";

const axiosConfig = getDefaultAxiosConfig();

function ChangePasswordModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 6,
  };

  const [passwordChangeSubmitted, setPasswordChangeSubmitted] =
    React.useState(false);
  const [oldPassword, setOldPassword] = React.useState();
  const [newPassword, setNewPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleChangePassword = async () => {
    try {
      // Verify that newPassword and confirmPassword are the same
      if (newPassword !== confirmPassword) {
        alert("Passwords do not match");
        // Handle error accordingly
        return;
      }
      console.log({
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: confirmPassword,
      });
      // Call the API to set the new password
      await axios.post(
        API_PWD_CHANGE,
        {
          old_password: oldPassword,
          new_password1: newPassword,
          new_password2: confirmPassword,
        },
        axiosConfig
      );

      setPasswordChangeSubmitted(true);
    } catch (error) {
      alert("Error setting new password", error);
      // Handle error accordingly
    }
  };

  function handleClose() {
    props.handleClose();
    setPasswordChangeSubmitted(false);
  }

  return (
    <Modal
      open={props.open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </div>
          {!passwordChangeSubmitted ? (
            <div className="w-full flex flex-col items-center justify-center">
              <div className="h-24 w-24 bg-violet-50 flex items-center justify-center rounded-full">
                <div className="h-16 w-16 bg-violet-100 flex items-center justify-center rounded-full text-primary">
                  <KeyIcon style={{ fontSize: 40, color: "#6d28d9" }} />
                </div>
              </div>
              <div className="mt-5 flex flex-col justify-center items-center">
                <h3 className="text-3xl text-slate-900 text-center">
                  Set New Password
                </h3>
                <p className="text-slate-500 mt-4 text-sm text-center">
                  Your new password must be different than previously used
                  passwords
                </p>
              </div>
              <div className="w-full mt-8">
                <p className="text-sm text-slate-500">Old password</p>
                <div className="mt-1">
                  <TextField
                    id="old-password"
                    // label="Old Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px !important",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                        backgroundColor: "white"
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-full mt-8">
                <p className="text-sm text-slate-500">New password</p>
                <div className="mt-1">
                  <TextField
                    id="new-password"
                    // label="New Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px !important",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                        backgroundColor: "white"
                      },
                    }}
                  />
                </div>
                <p className="text-slate-500 mt-0.5 text-xxs text-end">
                  Must be a minimum of 8 letters
                </p>
              </div>
              <div className="w-full">
                <p className="text-sm text-slate-500">Confirm new password</p>
                <div className="mt-1">
                  <TextField
                    id="confirm-password"
                    // label="Confirm New Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px !important",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                        backgroundColor: "white"
                      },
                    }}
                  />
                </div>
              </div>
              <button
                className="w-full bg-violet-700 hover:bg-violet-800 rounded-full text-white py-4 mt-9"
                onClick={handleChangePassword}
              >
                Reset Password
              </button>
            </div>
          ) : (
            <div className="full flex flex-col items-center justify-center">
              <div className="h-24 w-24 bg-green-50 flex items-center justify-center rounded-full">
                <div className="h-16 w-16 bg-green-100 flex items-center justify-center rounded-full text-green-700">
                  <CheckCircleOutlineIcon style={{ fontSize: 40 }} />
                </div>
              </div>
              <div className="mt-5 flex flex-col justify-center items-center">
                <h3 className="text-3xl text-slate-900">Password Reset</h3>
                <p className="text-slate-500 mt-8 text-base text-center">
                  Your password has been successfully reset. You can now log in
                  with your new password.
                </p>
              </div>
              <button
                className="w-full bg-primary hover:bg-blue-900 rounded text-white py-4 mt-8"
                onClick={() => handleClose()}
              >
                Continue
              </button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default ChangePasswordModal;
