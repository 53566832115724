import * as d3 from "d3";
import { get_t_str } from "../../../../../helpers/helpers";
import { formatDateReadableWithDayOfWeekNoYear } from "../../../requests/utilities/helpers";

export function ScheduleCoverageGraphD3(
  container,
  data,
  minOpenTime,
  maxCloseTime,
  chartid
) {
  const boxwidth = container.getBoundingClientRect().width;
  const boxheight = 600;

  var margin = { top: 20, right: 30, bottom: 24, left: 50 },
    width = boxwidth - margin.left - margin.right,
    height = boxheight - margin.top - margin.bottom;

  d3.select("#" + chartid + " svg").remove();

  var svg = d3
    .select("#" + chartid)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  // Build X scales and axis:
  var x = d3
    .scaleBand()
    .range([0, width])
    .domain(data.map((d) => d.date))
    .padding(0.01);

  var xAxis = d3
    .axisBottom(x)
    .tickFormat(formatDateReadableWithDayOfWeekNoYear)
    .tickSizeOuter(0);

  svg
    .append("g")
    .attr("transform", "translate(0," + height + ")")
    .style("font-size", "14px")
    .style("color", "#64748b")
    .call(xAxis);

  const filteredData = data;

  var y = d3
    .scaleBand()
    .range([0, height])
    .domain(filteredData.map((d) => d.qh_num).sort((a, b) => a - b))
    .padding(0.01);

  var yAxis = d3
    .axisLeft(y)
    .tickValues(
      y.domain().filter(function (d, i) {
        return !(i % 4);
      })
    )
    .tickFormat(get_t_str)
    .tickSizeOuter(0);

  var yAxisLabel = svg
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(0,0)")
    .style("font-size", 10)
    .style("color", "#64748b")
    .call(yAxis);

  var myColor = d3
    .scaleOrdinal()
    .domain([
      "gray",
      "blue6",
      "blue5",
      "blue4",
      "blue3",
      "blue2",
      "blue1",
      "neutral",
      "red1",
      "red2",
      "red3",
      "red4",
      "red5",
      "red6",
    ])
    .range([
      "#94a3b8",
      "#1d4ed8",
      "#2563eb",
      "#3b82f6",
      "#60a5fa",
      "#93c5fd",
      "#bfdbfe",
      "#e2e8f0",
      "#fecdd3",
      "#fda4af",
      "#fb7185",
      "#f43f5e",
      "#e11d48",
      "#be123c",
    ]);

  // create tooltip
  const tooltip = d3
    .select("#" + chartid)
    .append("div")
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-width", "1px")
    .style("border-radius", "8px")
    .style("padding", "20px")
    .style("text-align", "left")
    .style("position", "absolute")
    .style("z-index", 1000);

  // Function to update tooltip position based on available space
  const updateTooltipPosition = (event, tooltip) => {
    const tooltipWidth = tooltip.node().offsetWidth;
    const tooltipHeight = tooltip.node().offsetHeight;
    const pageX = event.pageX;
    const pageY = event.pageY;
    const offsetLeft = 10;
    const offsetTop = 10;

    let tooltipX = pageX + offsetLeft;
    let tooltipY = pageY + offsetTop;

    // Adjust if tooltip goes beyond right edge
    if (tooltipX + tooltipWidth > window.innerWidth) {
      tooltipX = pageX - tooltipWidth - offsetLeft;
    }

    // Adjust if tooltip goes beyond bottom edge
    if (tooltipY + tooltipHeight > window.innerHeight - 40) {
      tooltipY = pageY - tooltipHeight - offsetTop;
    }

    // Adjust if tooltip goes beyond left edge
    if (tooltipX < 0) {
      tooltipX = 0;
    }

    // Adjust if tooltip goes beyond top edge
    if (tooltipY < 0) {
      tooltipY = 0;
    }

    tooltip.style("left", tooltipX + "px").style("top", tooltipY + "px");
  };

  // Three functions that change the tooltip when user hover / move / leave a cell
  const mouseover = function (event, d) {
    tooltip
      .html(
        formatDateReadableWithDayOfWeekNoYear(d.date) +
          ", " +
          get_t_str(d.qh_num) +
          "<br>" +
          '<hr style="margin-top: 8px; margin-bottom: 8px;">' +
          '<span style="font-weight: bold">' +
          d.forecasted_labor_sum +
          "</span>" +
          '<span style="color: #64748b"> employees needed </span>' +
          "<br>" +
          '<span style="font-weight: bold">' +
          d.scheduled_labor_sum +
          "</span>" +
          '<span style="color: #64748b"> employees scheduled </span>' +
          "<br>" +
          '<hr style="margin-top: 8px; margin-bottom: 8px;">' +
          '<span style="font-weight: bold">' +
          d.buffer_sum +
          "</span>" +
          '<span style="color: #64748b"> difference </span>' +
          "<br>"
      )
      .style("opacity", 1)
      .style("z-index", 1000);

    d3.select(this)
      .style("stroke", "black")
      .style("stroke-width", "1px")
      .style("opacity", 1);
  };

  const mousemove = function (event, d) {
    updateTooltipPosition(event, tooltip);
  };

  const mouseleave = function (event, d) {
    tooltip.style("opacity", 0).style("z-index", 0);
    d3.select(this).style("stroke", "none").style("opacity", 1);
  };

  // add the squares
  svg
    .selectAll()
    .data(data, function (d) {
      return d.date + ":" + d.qh_num;
    })
    .enter()
    .append("rect")
    .attr("x", function (d) {
      return x(d.date);
    })
    .attr("y", function (d) {
      return y(d.qh_num);
    })
    .attr("width", x.bandwidth())
    .attr("height", y.bandwidth())
    .style("fill", function (d) {
      return myColor(d.color);
    })
    .on("mouseover", mouseover)
    .on("mousemove", mousemove)
    .on("mouseleave", mouseleave);

  return svg;
}
