import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import WeekSelector from "../home/top_bar_components/WeekSelector";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { formatDate } from "../requests/utilities/helpers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import BarChartIcon from "@mui/icons-material/BarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import TableChartIcon from "@mui/icons-material/TableChart";
import TocIcon from "@mui/icons-material/Toc";
import {
  LineChart,
  BarChart,
  Bar,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";

import LoadSpinner from "../../../utilities/LoadSpinner";
import { getRightworkAPI } from "../requests/utilities/requests";
import { API_get_labor_test_results } from "../../../constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LaborModelTestModal(props) {
  const [selectedStores, setSelectedStores] = React.useState([props.homeStore]);
  const [selectedMetric, setSelectedMetric] = React.useState("hours");
  const [data, setData] = React.useState(null);
  const [laborStandardContributionData, setLaborStandardContributionData] = React.useState(null)
  const [calculationStatus, setCalculationStatus] = React.useState("idle");
  const [selectedVisualization, setSelectedVisualization] =
    React.useState("table");
  const [runType, setRunType] = React.useState("forecasted_sandbox_vs_published")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    minHeight: 450,
    width: "98%",
    height: "96%",
    overflow: calculationStatus === "idle" ? "hidden" : "auto",
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
  };

  // Handle store selection changes
  const handleStoreSelectionChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      // If "Select All" is selected and all stores are already selected, deselect all options
      if (selectedStores.length === props.storeNames.length) {
        setSelectedStores([]);
      } else {
        // Otherwise, toggle all checkboxes
        setSelectedStores(props.storeNames.map((store) => store.id));
      }
      return;
    }
    setSelectedStores(value);
  };

  const handleSelectMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  // Handle run type change
  const handleRunTypeChange = (event) => {
    const value = event.target.value;
    if (value === "forecasted_sandbox_vs_published") {
      setRunType("forecasted_sandbox_vs_published");
    } else if (value === "earned_sandbox_vs_actual") {
      setRunType("earned_sandbox_vs_actual");
    }
    setCalculationStatus("idle")
  };

  // Handler for the Calculate button
  const handleCalculateClick = async () => {
    setCalculationStatus("loading");
    // Prepare the payload
    const payload = {
      company_id: props.company_id,
      store_ids: selectedStores.join(","),
      date_start: formatDate(startDate),
      date_end: formatDate(endDate),
      run_type: runType
    };

    try {
      // Replace `API_get_labor_test_results` with your actual endpoint constant
      const response = await getRightworkAPI(
        API_get_labor_test_results,
        payload
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data.test_results;
        setData(data);
        const labor_standard_contribution_data = response.data.labor_standard_contribution
        setLaborStandardContributionData(labor_standard_contribution_data)
        setCalculationStatus("completed");
        // Process your data here (e.g., update state to display results)
      } else {
        throw new Error("Failed to fetch test results");
      }
    } catch (error) {
      console.error("Error fetching test results:", error);
      setCalculationStatus("idle");
      // Optionally use toast to show error message
      toast.error("Error fetching test results");
    }
  };

  console.log("DATA", data);
  console.log("LABAR STANDARD CONTRIBUTION DATA", laborStandardContributionData)


  const getBusinessWeekStartDayIndex = (storeNames, store_id) => {
    const day_of_week_names = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    try {
      return day_of_week_names.indexOf(
        storeNames.filter((v, i) => v.id === store_id)[0][
          "business_week_start_day"
        ]
      );
    } catch (error) {
      return 1;
    }
  };

  const businessWeekStartDayIndex = getBusinessWeekStartDayIndex(
    props.storeNames,
    props.homeStore
  );

  function getStartOfCurrentWeek(businessWeekStartDayIndex) {
    const today = new Date();
    const first = today.getDate() - today.getDay() + businessWeekStartDayIndex;

    const monday = new Date(today.setDate(first));
    return monday;
  }

  const [week_start_date, set_week_start_date] = React.useState(
    getStartOfCurrentWeek(businessWeekStartDayIndex)
  );

  function addDays(theDate, days) {
    var newDate = new Date(theDate);
    newDate.setDate(theDate.getDate() + days);
    return newDate;
  }

  const [startDate, setStartDate] = React.useState(week_start_date);
  const [endDate, setEndDate] = React.useState(addDays(week_start_date, 6));

  function increment_week(start_date, add_week) {
    const new_start_date = addDays(start_date, add_week * 7);
    set_week_start_date(new_start_date);
  }

  // Reset calculation status upon any input change
  React.useEffect(() => {
    setCalculationStatus("idle");
  }, [selectedStores, week_start_date, startDate, endDate]);

  //Department selector logic
  const departments = props.roleTypes
    ? props.roleTypes.map((v, i) => v.role_type)
    : [];
  console.log(departments);
  const [selectedDepartments, setSelectedDepartments] =
    React.useState(departments);

  const selectedFields = ["new_cost", "new_hours", "old_cost", "old_hours"];

  function calculateSum(data, selectedFields, selectedRoles) {
    const sums = {};

    selectedFields.forEach((field) => {
      sums[field] = 0;
    });

    data.forEach((obj) => {
      if (
        selectedRoles.includes(obj.role_type) ||
        selectedRoles.includes("All")
      ) {
        selectedFields.forEach((field) => {
          sums[field] += obj[field];
        });
      }
    });

    return sums;
  }

  // Department selector styling
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };

  const handleDepartmentSelectionChange = (event) => {
    const {
      target: { value },
    } = event;
    const value_arr = typeof value === "string" ? value.split(",") : value;
    if (value_arr.length > 0) {
      setSelectedDepartments(value_arr);
    }
  };

  const numberOfLocationsInTest = data ? Object.keys(data).length : 0;

  // Initialize an array to store the sums
  let locationSumsByDayOfWeekArray = [];

  // Iterate over each index position
  for (let i = 0; i < 7; i++) {
    // Initialize sum for current index position
    let sales = 0;
    let new_hours = 0;
    let new_cost = 0;
    let old_hours = 0;
    let old_cost = 0;

    if (data) {
      Object.values(data).forEach((day) => {
        const laborResultsByDepartment = calculateSum(
          day[i].labor_results,
          selectedFields,
          selectedDepartments
        );
        sales += parseFloat(day[i].sales);
        new_hours += parseFloat(laborResultsByDepartment.new_hours);
        new_cost += parseFloat(laborResultsByDepartment.new_cost);
        old_hours += parseFloat(laborResultsByDepartment.old_hours);
        old_cost += parseFloat(laborResultsByDepartment.old_cost);
      });
    }

    // Push the sum to the sumsArray
    locationSumsByDayOfWeekArray.push({
      sales: sales,
      new_hours: new_hours,
      new_cost: new_cost,
      old_hours: old_hours,
      old_cost: old_cost,
    });
  }

  // Display the resulting array
  console.log(
    "LOCATION SUMS BY DAY OF WEEK ARRAY",
    locationSumsByDayOfWeekArray
  );

  function sumWeekData(weekData) {
    // Initialize variables to store cumulative sums
    let totalNewCost = 0;
    let totalNewHours = 0;
    let totalOldCost = 0;
    let totalOldHours = 0;
    let totalSales = 0;

    // Iterate through each day's data in the array
    weekData.forEach((day) => {
      // Add each day's values to the cumulative sums
      totalNewCost += day.new_cost;
      totalNewHours += day.new_hours;
      totalOldCost += day.old_cost;
      totalOldHours += day.old_hours;
      totalSales += day.sales;
    });

    // Create and return a new object with summed values
    return {
      new_cost: totalNewCost,
      new_hours: totalNewHours,
      old_cost: totalOldCost,
      old_hours: totalOldHours,
      sales: totalSales,
    };
  }

  const xAxisLabels =
    businessWeekStartDayIndex === 1
      ? [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ]
      : [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];

  const averageHoursArray = [];
  const averageSPLHArray = [];
  const averageLaborPercentArray = [];

  xAxisLabels.forEach((day, index) => {
    const averageHoursObj = {
      name: day,
      new_hours: Number(
        (
          locationSumsByDayOfWeekArray[index].new_hours /
          numberOfLocationsInTest
        ).toFixed(2)
      ),
      old_hours: Number(
        (
          locationSumsByDayOfWeekArray[index].old_hours /
          numberOfLocationsInTest
        ).toFixed(2)
      ),
    };
    averageHoursArray.push(averageHoursObj);

    const averageSPLHObj = {
      name: day,
      new_SPLH: Number(
        (
          locationSumsByDayOfWeekArray[index].sales /
          locationSumsByDayOfWeekArray[index].new_hours
        ).toFixed(2)
      ),
      old_SPLH: Number(
        (
          locationSumsByDayOfWeekArray[index].sales /
          locationSumsByDayOfWeekArray[index].old_hours
        ).toFixed(2)
      ),
    };
    averageSPLHArray.push(averageSPLHObj);

    const averageLaborPercentObj = {
      name: day,
      new_labor_percent: Number(
        (
          (locationSumsByDayOfWeekArray[index].new_cost /
            locationSumsByDayOfWeekArray[index].sales) *
          100
        ).toFixed(2)
      ),
      old_labor_percent: Number(
        (
          (locationSumsByDayOfWeekArray[index].old_cost /
            locationSumsByDayOfWeekArray[index].sales) *
          100
        ).toFixed(2)
      ),
    };
    averageLaborPercentArray.push(averageLaborPercentObj);
  });

  console.log("AVERAGE LABOR PERCENT ARRAY", locationSumsByDayOfWeekArray);
  console.log("AVERAGE HOURS ARRAY", averageHoursArray);
  console.log("STORE NAMES", props.storeNames);

  // Finds the store name if one store is selected
  let selectedStoreId = selectedStores[0];
  let store = props.storeNames.find((store) => store.id === selectedStoreId);
  let storeName = store ? store.store_name : null;

  // Labor standards contribution data
  const transformedLaborStandardsContributionData = laborStandardContributionData
  ? Object.keys(laborStandardContributionData)
      .map((key) => ({
        name: key,
        hours: parseFloat(laborStandardContributionData[key].hours).toFixed(2),
        role_type: laborStandardContributionData[key].role_type,
        type: laborStandardContributionData[key].type
      }))
      .sort((a, b) => b.hours - a.hours)
  : [];


  const laborStandardsDataByDepartment = transformedLaborStandardsContributionData?.filter((item) =>
    selectedDepartments?.includes(item.role_type)
  );

  const laborStandardsMaxHours = Math.round(Math.max(...laborStandardsDataByDepartment.map(d => d.hours)) * 1.05);



  // Recharts custom components
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <div className="w-full flex items-center justify-center space-x-5 mt-3">
        {payload.map((entry, index) => (
          <div key={index} style={{ color: entry.color }} className="flex items-center space-x-1">
            <div style={{ backgroundColor: entry.color }} className="w-2 h-2 rounded-full"></div>
            <p>
              {
                entry.value === 'old_hours' ? runType === "forecasted_sandbox_vs_published" ? "Published Hours" : "Actual Hours" :
                entry.value === 'new_hours' ? 'Sandbox Hours' :
                entry.value === 'hours' ? 'Sandbox Hours' :
                entry.value === 'old_SPLH' ? runType === "forecasted_sandbox_vs_published" ? "Published SPLH" : "Actual SPLH" :
                entry.value === 'new_SPLH' ? 'Sandbox SPLH' :
                entry.value === 'SPLH' ? 'Sandbox SPLH' :
                entry.value === 'old_labor_percent' ? runType === "forecasted_sandbox_vs_published" ? "Published Labor Percent" : "Actual Labor Percent" :
                entry.value === 'new_labor_percent' ? 'Sandbox Labor Percent' :
                entry.value === 'labor_percent' ? 'Sandbox Labor Percent' :
                ''
              }
            </p>
          </div>
        ))}
      </div>
    );
  };

  const CustomTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      return (
        <div className="p-4 bg-white border border-slate-300 rounded-lg">
          <p>{label}</p>
          {payload.map((item, index) => (
            <p key={index} style={{ color: item.color }} className="mt-2">
              {
                item.dataKey === 'old_hours' ? runType === "forecasted_sandbox_vs_published" ? "Published Hours: " : "Actual Hours: " :
                item.dataKey === 'new_hours' ? 'Sandbox Hours: ' :
                item.dataKey === 'hours' ? 'Sandbox Hours: ' :
                item.dataKey === 'old_SPLH' ? runType === "forecasted_sandbox_vs_published" ? "Published SPLH: " : "Actual SPLH: " :
                item.dataKey === 'new_SPLH' ? 'Sandbox SPLH: ' :
                item.dataKey === 'SPLH' ? 'Sandbox SPLH: ' :
                item.dataKey === 'old_labor_percent' ? runType === "forecasted_sandbox_vs_published" ? "Published Labor Percent: " : "Actual Labor Percent: " :
                item.dataKey === 'new_labor_percent' ? 'Sandbox Labor Percent: ' :
                item.dataKey === 'labor_percent' ? 'Sandbox Labor Percent: ' :
                ''
              }
              {(item.dataKey === 'old_SPLH' || item.dataKey === 'new_SPLH' || item.dataKey === 'SPLH') &&
                "$"
              }
              {item.value}
              {(item.dataKey === 'old_labor_percent' || item.dataKey === 'new_labor_percent' || item.dataKey === 'labor_percent') &&
                "%"
              }
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const formatValue = (value, includeSymbol = '', decimals = 2) => {
    if (value === 0 || isNaN(value) || value === undefined || value === null) {
      return '-';
    }
    return `${includeSymbol === "$" ? "$" : ""}${Number(value).toFixed(decimals)}${includeSymbol === "%" ? "%" : ""}`;
  };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={props.handleClose}
          >
            <CancelIcon />
          </div>
          <div className="pb-6 border-b border-slate-200 px-6 py-6">
            <h1 className="text-slate-900 text-2xl">Labor model test run</h1>
            <div className="w-full flex items-center space-x-5 mt-5">
              <div>
                {/* <WeekSelector
                  start_date={week_start_date}
                  end_date={addDays(week_start_date, 6)}
                  increment_week={increment_week}
                  set_week_start_date={set_week_start_date}
                  businessWeekStartDayIndex={businessWeekStartDayIndex}
                /> */}
                <div className="flex items-center">
                  <div className="w-40">
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          Text="Start Date"
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          slotProps={{
                            day: {
                              sx: {
                                "&.MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#6d28d9",
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "40px",
                              backgroundColor: "white",
                              borderRadius: "12px",
                              fontSize: "14px",
                              "& fieldset": {
                                borderRadius: "12px",
                                height: "45px",
                                paddingX: "25px",
                                borderColor: "#e2e8f0"
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                          className="w-full text-center"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="ml-1 text-slate-500">
                    <ArrowForwardIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                  </div>
                  <div className="w-40 ml-1">
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          Text="End Date"
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          slotProps={{
                            day: {
                              sx: {
                                "&.MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#6d28d9",
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "40px",
                              backgroundColor: "white",
                              borderRadius: "12px",
                              fontSize: "14px",
                              "& fieldset": {
                                borderRadius: "12px",
                                height: "45px",
                                paddingX: "25px",
                                borderColor: "#e2e8f0"
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                          className="w-full text-center"
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[420px]">
                <Select
                  label="Locations"
                  fullWidth
                  multiple
                  value={selectedStores}
                  onChange={handleStoreSelectionChange}
                  input={<OutlinedInput />}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "12px",
                    paddingX: "15px",
                    height: "40px",
                    fontSize: "14px",
                    // width: "600px",
                    fieldset: { borderColor: "#e2e8f0" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                  renderValue={(selected) => {
                    const selectedStoresNames = props.storeNames
                      .filter((store) => selected.includes(store.id))
                      .map((store) => store.store_name);

                    if (selectedStoresNames.length <= 7) {
                      return selectedStoresNames.join(", ");
                    } else {
                      return `${selectedStoresNames[0]}, ${selectedStoresNames[1]}, ${selectedStoresNames[3]}, ${selectedStoresNames[4]}, ${selectedStoresNames[5]}, ${selectedStoresNames[6]}, ${selectedStoresNames[7]}  ...`;
                    }
                  }}
                  className="text-left w-full"
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={
                        props.storeNames.length > 0 &&
                        selectedStores.length === props.storeNames.length
                      }
                      indeterminate={
                        selectedStores.length > 0 &&
                        selectedStores.length < props.storeNames.length
                      }
                      sx={{
                        color: "#6d28d9",
                        "&.Mui-checked": {
                          color: "#6d28d9", // Purple color when checked
                        },
                        "&.MuiCheckbox-indeterminate": {
                          color: "#6d28d9", // Purple color when indeterminate
                        },
                      }}
                    />
                    <ListItemText
                      primary={
                        selectedStores.length === props.storeNames.length
                          ? "Deselect All"
                          : "Select All"
                      }
                      primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                    />
                  </MenuItem>
                  {props.storeNames.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      <Checkbox
                        checked={selectedStores.indexOf(value.id) > -1}
                        sx={{
                          color: "#6d28d9",
                          "&.Mui-checked": {
                            color: "#6d28d9",
                          },
                        }}
                      />
                      <ListItemText primary={value.store_name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="flex-1">
                <Select
                  value={runType}
                  onChange={handleRunTypeChange}
                  fullWidth
                  size="small"
                  sx={{
                    borderRadius: "12px",
                    paddingY: "5px",
                    height: "40px",
                    fontSize: "14px",
                    fieldset: { borderColor: "#e2e8f0" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                >
                  <MenuItem value="forecasted_sandbox_vs_published">Sandbox vs Published Forecasted Labor</MenuItem>
                  <MenuItem value="earned_sandbox_vs_actual">Sandbox Earned Labor vs Actual Used Labor</MenuItem>
                </Select>
              </div>
              <div>
                <LoadingButton
                  variant="contained"
                  loading={calculationStatus === "loading"}
                  loadingPosition="end"
                  endIcon={
                    calculationStatus === "completed" ? (
                      <CheckIcon />
                    ) : (
                      <AutoGraphIcon />
                    )
                  }
                  onClick={() => handleCalculateClick()}
                  disabled={
                    selectedStores.length === 0 ||
                    calculationStatus === "loading"
                  }
                  sx={{
                    textTransform: "none",
                    borderRadius: "50px",
                    height: "40px",
                    backgroundColor:
                      calculationStatus === "completed" ? "green" : "#6d28d9", // conditional styling
                    "&:hover": {
                      backgroundColor:
                        calculationStatus === "completed"
                          ? "darkgreen"
                          : "#5b21b6", // change color on hover if completed
                    },
                  }}
                >
                  {calculationStatus === "loading"
                    ? "Calculating..."
                    : calculationStatus === "completed"
                    ? "Labor Calculated"
                    : "Calculate Labor"}
                </LoadingButton>
              </div>
              {calculationStatus === "completed" && (
                <div
                  className="cursor-pointer"
                  onClick={() => setCalculationStatus("idle")}
                >
                  <p className="text-sm text-violet-700">Reset</p>
                </div>
              )}
            </div>
          </div>
          {calculationStatus === "idle" || calculationStatus === "loading" ? (
            <div className="w-full min-h-[82%] bg-slate-50 px-6 flex flex-grow items-center justify-center">
              <h3 className="text-center text-xl text-slate-500 pb-12">
                Select valid parameters and then press Calculate Labor to see
                results.
              </h3>
            </div>
          ) : (
            <div className="w-full min-h-[82%] bg-slate-50 px-6 pb-12">
              <div className="w-full pt-8 flex justify-between">
                <div className="flex items-center space-x-8">
                  <div className="flex border border-slate-200 rounded-xl overflow-hidden">
                    <div
                      className={`flex items-center space-x-1 px-4 py-2 text-sm font-medium ${
                        selectedVisualization === "table"
                          ? "bg-slate-100 text-violet-700"
                          : "bg-white text-slate-900"
                      } border-r border-slate-200 hover:bg-slate-100 cursor-pointer`}
                      onClick={() => setSelectedVisualization("table")}
                    >
                      <div>
                        <TocIcon style={{ fontSize: "20px" }} />
                      </div>
                      <p>Table</p>
                    </div>
                    <div
                      className={`flex items-center space-x-1 px-4 py-2 text-sm font-medium ${
                        selectedVisualization === "bar_chart"
                          ? "bg-slate-100 text-violet-700"
                          : "bg-white text-slate-900"
                      } border-r border-slate-200 hover:bg-slate-100 cursor-pointer`}
                      onClick={() => setSelectedVisualization("bar_chart")}
                    >
                      <div>
                        <BarChartIcon style={{ fontSize: "20px" }} />
                      </div>
                      <p>Bar</p>
                    </div>
                    <div
                      className={`flex items-center space-x-1 px-4 py-2 text-sm font-medium ${
                        selectedVisualization === "line_chart"
                          ? "bg-slate-100 text-violet-700"
                          : "bg-white text-slate-900"
                      } hover:bg-slate-100 cursor-pointer`}
                      onClick={() => setSelectedVisualization("line_chart")}
                    >
                      <div>
                        <StackedLineChartIcon style={{ fontSize: "20px" }} />
                      </div>
                      <p>Line</p>
                    </div>
                  </div>
                  <div
                    className={`flex items-center space-x-1 px-4 py-2 text-sm font-medium bg-white rounded-xl border border-slate-200 ${
                      selectedVisualization === "labor_standards"
                        ? "bg-slate-100 text-violet-700"
                        : "bg-white text-slate-900"
                    } hover:bg-slate-100 cursor-pointer`}
                    onClick={() => setSelectedVisualization("labor_standards")}
                  >
                    <div>
                      <AlignHorizontalLeftIcon style={{ fontSize: "20px" }} />
                    </div>
                    <p>Show Labor Standard Contribution</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="w-60">
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedMetric}
                        onChange={handleSelectMetricChange}
                        sx={{
                          borderRadius: "10px",
                          fieldset: { borderColor: "#e2e8f0" },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        }}
                      >
                        <MenuItem value={"hours"}>Hours</MenuItem>
                        <MenuItem value={"splh"}>SPLH</MenuItem>
                        <MenuItem value={"labor_percent"}>
                          Labor Percent
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="w-60">
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                      }}
                    >
                      <Select
                        fullWidth
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedDepartments}
                        onChange={handleDepartmentSelectionChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        sx={{
                          borderRadius: "10px",
                          fieldset: { borderColor: "#e2e8f0" },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        }}
                      >
                        {departments.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            // classes={{ root: classes.menuItem }}
                          >
                            <Checkbox
                              checked={selectedDepartments.indexOf(name) > -1}
                              style={{ color: "#6d28d9" }}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {selectedVisualization === "table" ? (
                <div className="mt-8 w-full bg-white border border-slate-200 rounded-lg overflow-hidden">
                  <div className="flex items-center border-b border-slate-200 py-2">
                    <div className="w-[14%]"></div>
                    <div className="flex-1 pr-10 text-white">
                      <p className="text-sm">Sandbox {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                      <p className="text-sm mt-2">{runType === "forecasted_sandbox_vs_published" ? "Published" : "Actual"} {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                    </div>
                    {xAxisLabels.map((day, index) => (
                      <div
                        key={index}
                        className="flex-1"
                      >
                        <p className="text-slate-500 text-sm">{day}</p>
                      </div>
                    ))}
                    <div className="flex-1">
                      <p className="text-slate-900 font-bold text-sm">Total</p>
                    </div>
                  </div>
                  {data && calculationStatus === "completed" && (
                    <div>
                      {Object.entries(data).map(([location, values]) => {
                        let sumNewHours = 0;
                        let sumOldHours = 0;
                        let sumSales = 0;
                        let sumNewCost = 0;
                        let sumOldCost = 0;
                        let totalHoursChange = 0;
                        let totalSPLHChange = 0;
                        let totalLaborPercentChange = 0;
                        return (
                          <div
                            key={location}
                            className="w-full flex border-b border-slate-200 py-3.5"
                          >
                            <div className="w-[14%] text-slate-900 px-6">
                              <p className="text-slate-900 text-sm">{location}</p>
                            </div>
                            <div className="w-[86%] flex">
                              <div className="flex-1 pr-10">
                                <p className="text-sm text-slate-500">{runType === "forecasted_sandbox_vs_published" ? "Published" : "Actual"} {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                                <p className="text-sm text-violet-700 mt-2">Sandbox {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                              </div>
                              {values?.map((dayValue, index) => {
                                const laborResultsByDepartment = calculateSum(
                                  dayValue.labor_results,
                                  selectedFields,
                                  selectedDepartments
                                );
                                const hoursChange = (
                                  laborResultsByDepartment.new_hours -
                                  laborResultsByDepartment.old_hours
                                ).toFixed(2);
                                const SPLHChange = (
                                  dayValue.sales /
                                    laborResultsByDepartment.new_hours -
                                  dayValue.sales /
                                    laborResultsByDepartment.old_hours
                                ).toFixed(2);
                                const laborPercentChange = (
                                  (laborResultsByDepartment.new_cost /
                                    dayValue.sales) *
                                    100 -
                                  (laborResultsByDepartment.old_cost /
                                    dayValue.sales) *
                                    100
                                ).toFixed(2);
                                console.log(
                                  "LABOR RESULTS BY DEPARTMENT",
                                  laborResultsByDepartment
                                );
                                console.log("DAY VALUES", dayValue);
                                sumNewHours +=
                                  laborResultsByDepartment.new_hours;
                                sumOldHours +=
                                  laborResultsByDepartment.old_hours;
                                sumSales += dayValue.sales;
                                sumNewCost += laborResultsByDepartment.new_cost;
                                sumOldCost += laborResultsByDepartment.old_cost;
                                totalHoursChange = (
                                  sumNewHours - sumOldHours
                                ).toFixed(2);
                                totalSPLHChange = (
                                  sumSales / sumNewHours -
                                  sumSales / sumOldHours
                                ).toFixed(2);
                                totalLaborPercentChange = (
                                  (sumNewCost / sumSales) * 100 -
                                  (sumOldCost / sumSales) * 100
                                ).toFixed(2);

                                return (
                                  <div key={index} className="flex-1">
                                    {selectedMetric === "hours" ? (
                                      <div>
                                        <p className="text-sm">
                                          {formatValue(laborResultsByDepartment.old_hours)}
                                        </p>
                                        <p className="text-sm text-violet-700 mt-2">
                                          {formatValue(laborResultsByDepartment.new_hours)}
                                        </p>
                                        <div className="w-fit mt-2">
                                          <div
                                            className={`${
                                              hoursChange > 0
                                                ? "bg-rose-100 border border-rose-200 text-rose-800"
                                                : hoursChange < 0
                                                ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                                : "bg-slate-100 border border-slate-200 text-slate-600"
                                            } flex items-center justify-center rounded px-1`}
                                          >
                                            <div>
                                              {hoursChange < 0 ? (
                                                <RemoveIcon
                                                  style={{
                                                    fontSize: "10px",
                                                    marginBottom: "2px",
                                                  }}
                                                />
                                              ) : hoursChange > 0 ? (
                                                <AddIcon
                                                  style={{
                                                    fontSize: "10px",
                                                    marginBottom: "2px",
                                                  }}
                                                /> ) : (<div></div>
                                              )}
                                            </div>
                                            <p className="text-xs">
                                              {formatValue(Math.abs(hoursChange))}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : selectedMetric === "splh" ? (
                                      <div>
                                        <p className="text-sm">
                                          {formatValue((dayValue.sales / laborResultsByDepartment.old_hours), "$")}
                                        </p>
                                        <p className="text-sm text-violet-700 mt-2">
                                          {formatValue((dayValue.sales / laborResultsByDepartment.new_hours), "$")}
                                        </p>
                                        <div className="w-fit mt-2">
                                          <div
                                            className={`${
                                              SPLHChange < 0
                                                ? "bg-rose-100 border border-rose-200 text-rose-800"
                                                : SPLHChange > 0
                                                ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                                : "bg-slate-100 border border-slate-200 text-slate-600"
                                            } flex items-center justify-center rounded px-1`}
                                          >
                                            <div>
                                              {SPLHChange < 0 ? (
                                                <RemoveIcon
                                                  style={{
                                                    fontSize: "10px",
                                                    marginBottom: "2px",
                                                  }}
                                                />
                                              ) : SPLHChange > 0 ? (
                                                <AddIcon
                                                  style={{
                                                    fontSize: "10px",
                                                    marginBottom: "2px",
                                                  }}
                                                /> ) : ( <div></div>
                                              )}
                                            </div>
                                            <p className="text-xs">
                                              {formatValue((Math.abs(SPLHChange)), "$")}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : selectedMetric === "labor_percent" ? (
                                      <div>
                                        <p className="text-sm">
                                          {formatValue(((laborResultsByDepartment.old_cost / dayValue.sales) * 100), "%")}
                                        </p>
                                        <p className="text-sm text-violet-700 mt-2">
                                          {formatValue(((laborResultsByDepartment.new_cost / dayValue.sales) * 100), "%")}
                                        </p>
                                        <div className="w-fit mt-2">
                                          <div
                                            className={`${
                                              laborPercentChange > 0
                                                ? "bg-rose-100 border border-rose-200 text-rose-800"
                                                : laborPercentChange < 0
                                                ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                                : "bg-slate-100 border border-slate-200 text-slate-600"
                                            } flex items-center justify-center rounded px-1`}
                                          >
                                            <div>
                                              {laborPercentChange < 0 ? (
                                                <RemoveIcon
                                                  style={{
                                                    fontSize: "10px",
                                                    marginBottom: "2px",
                                                  }}
                                                />
                                              ) : laborPercentChange > 0 ? (
                                                <AddIcon
                                                  style={{
                                                    fontSize: "10px",
                                                    marginBottom: "2px",
                                                  }}
                                                /> ) : ( <div></div>
                                              )}
                                            </div>
                                            <p className="text-xs">
                                              {formatValue(Math.abs(laborPercentChange))}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <p></p>
                                    )}
                                  </div>
                                );
                              })}
                              <div className="flex-1">
                              <p className="text-sm font-bold">
                                  {selectedMetric === "hours"
                                    ? formatValue(sumOldHours)
                                    : selectedMetric === "splh"
                                    ? `${formatValue((sumSales / sumOldHours), "$")}`
                                    : selectedMetric === "labor_percent"
                                    ? `${formatValue(((sumOldCost / sumSales) * 100), "%")}`
                                    : 0}
                                </p>
                                <p className="text-sm font-bold text-violet-700 mt-2">
                                  {selectedMetric === "hours"
                                    ? formatValue(sumNewHours)
                                    : selectedMetric === "splh"
                                    ? `${formatValue((sumSales / sumNewHours), "$")}`
                                    : selectedMetric === "labor_percent"
                                    ? `${formatValue(((sumNewCost / sumSales) * 100), "%")}`
                                    : 0}
                                </p>
                                {selectedMetric === "hours" ? (
                                  <div className="w-fit mt-2">
                                    <div
                                      className={`${
                                        totalHoursChange > 0
                                          ? "bg-rose-100 border border-rose-200 text-rose-800"
                                          : totalHoursChange < 0
                                          ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                          : "bg-slate-100 border border-slate-200 text-slate-600"
                                      } flex items-center justify-center rounded px-1`}
                                    >
                                      <div>
                                        {totalHoursChange < 0 ? (
                                          <RemoveIcon
                                            style={{
                                              fontSize: "10px",
                                              marginBottom: "2px",
                                            }}
                                          />
                                        ) : totalHoursChange > 0 ? (
                                          <AddIcon
                                            style={{
                                              fontSize: "10px",
                                              marginBottom: "2px",
                                            }}
                                          /> ) : ( <div></div>
                                        )}
                                      </div>
                                      <p className="text-xs">
                                        {formatValue(Math.abs(totalHoursChange))}
                                      </p>
                                    </div>
                                  </div>
                                ) : selectedMetric === "splh" ? (
                                  <div className="w-fit mt-2">
                                    <div
                                      className={`${
                                        totalSPLHChange < 0
                                          ? "bg-rose-100 border border-rose-200 text-rose-800"
                                          : totalSPLHChange > 0
                                          ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                          : "bg-slate-100 border border-slate-200 text-slate-600"
                                      } flex items-center justify-center rounded px-1`}
                                    >
                                      <div>
                                        {totalSPLHChange < 0 ? (
                                          <RemoveIcon
                                            style={{
                                              fontSize: "10px",
                                              marginBottom: "2px",
                                            }}
                                          />
                                        ) : totalSPLHChange > 0 ? (
                                          <AddIcon
                                            style={{
                                              fontSize: "10px",
                                              marginBottom: "2px",
                                            }}
                                          /> ) : ( <div></div>
                                        )}
                                      </div>
                                      <p className="text-xs">
                                        {formatValue((Math.abs(totalSPLHChange)), "$")}
                                      </p>
                                    </div>
                                  </div>
                                ) : selectedMetric === "labor_percent" ? (
                                  <div className="w-fit mt-2">
                                    <div
                                      className={`${
                                        totalLaborPercentChange > 0
                                          ? "bg-rose-100 border border-rose-200 text-rose-800"
                                          : totalLaborPercentChange < 0
                                          ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                          : "bg-slate-100 border border-slate-200 text-slate-600"
                                      } flex items-center justify-center rounded px-1`}
                                    >
                                      <div>
                                        {totalLaborPercentChange < 0 ? (
                                          <RemoveIcon
                                            style={{
                                              fontSize: "10px",
                                              marginBottom: "2px",
                                            }}
                                          />
                                        ) : totalLaborPercentChange > 0 ? (
                                          <AddIcon
                                            style={{
                                              fontSize: "10px",
                                              marginBottom: "2px",
                                            }}
                                          /> ) : ( <div></div>
                                        )}
                                      </div>
                                      <p className="text-xs">
                                        {formatValue(Math.abs(totalLaborPercentChange))}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedMetric === "hours" ? (
                    <div className="w-full flex bg-slate-100 py-3.5">
                      <div className="w-[14%] text-slate-900 px-6">
                        <p className="text-slate-900 text-sm font-bold">Average</p>
                      </div>
                      <div className="flex-1 pr-10">
                        <p className="text-sm text-slate-500">{runType === "forecasted_sandbox_vs_published" ? "Published" : "Actual"} {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                        <p className="text-sm text-violet-700 mt-2">Sandbox {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                      </div>
                      {locationSumsByDayOfWeekArray.map((day, index) => {
                        const hoursChange = (
                          (day.new_hours - day.old_hours) /
                          numberOfLocationsInTest
                        ).toFixed(2);
                        return (
                          <div
                            key={index}
                            className="flex-1"
                          >
                            <p className="text-slate-900 text-sm font-bold">
                              {formatValue(
                                (day.old_hours / numberOfLocationsInTest)
                              )}
                            </p>
                            <p className="text-violet-700 text-sm font-bold mt-2">
                              {formatValue(
                                (day.new_hours / numberOfLocationsInTest)
                              )}
                            </p>
                            <div className="w-fit mt-2">
                              <div
                                className={`${
                                  hoursChange > 0
                                    ? "bg-rose-100 border border-rose-200 text-rose-800"
                                    : hoursChange < 0
                                    ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                    : "bg-slate-100 border border-slate-200 text-slate-600"
                                } flex items-center justify-center rounded px-1`}
                              >
                                <div>
                                  {hoursChange < 0 ? (
                                    <RemoveIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  ) : hoursChange > 0 ? (
                                    <AddIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    /> ) : ( <div></div>
                                  )}
                                </div>
                                <p className="text-xs">
                                  {formatValue(Math.abs(hoursChange))}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex-1">
                        <p className="text-slate-900 text-sm font-bold">
                          {formatValue((
                            sumWeekData(locationSumsByDayOfWeekArray)
                              .old_hours / numberOfLocationsInTest
                          ))}
                        </p>
                        <p className="text-violet-700 text-sm font-bold mt-2">
                          {formatValue((
                            sumWeekData(locationSumsByDayOfWeekArray)
                              .new_hours / numberOfLocationsInTest
                          ))}
                        </p>
                        <div className="w-fit mt-2">
                          {/* Define hoursChange variable */}
                          {(() => {
                            const hoursChange = (
                              (sumWeekData(locationSumsByDayOfWeekArray)
                                .new_hours -
                                sumWeekData(locationSumsByDayOfWeekArray)
                                  .old_hours) /
                              numberOfLocationsInTest
                            ).toFixed(2);

                            // Determine the CSS classes based on hoursChange value
                            let cssClass;
                            if (hoursChange > 0) {
                              cssClass =
                                "bg-rose-100 border border-rose-200 text-rose-800";
                            } else if (hoursChange < 0) {
                              cssClass =
                                "bg-emerald-100 border border-emerald-200 text-emerald-800";
                            } else {
                              cssClass =
                                "bg-slate-100 border border-slate-200 text-slate-600";
                            }

                            // Render the styled div based on hoursChange
                            return (
                              <div
                                className={`flex items-center justify-center rounded px-1 ${cssClass}`}
                              >
                                <div>
                                  {hoursChange < 0 ? (
                                    <RemoveIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  ) : hoursChange > 0 ? (
                                    <AddIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    /> ) : ( <div></div>
                                  )}
                                </div>
                                <p className="text-xs">
                                  {formatValue(Math.abs(hoursChange))}
                                </p>
                              </div>
                            );
                          })()}
                        </div>
                      </div>
                    </div>
                  ) : selectedMetric === "splh" ? (
                    <div className="w-full flex bg-slate-100 py-3.5">
                      <div className="w-[14%] text-slate-900 px-6">
                        <p className="text-slate-900 text-sm font-bold">Average</p>
                      </div>
                      <div className="flex-1 pr-10">
                        <p className="text-sm text-slate-500">{runType === "forecasted_sandbox_vs_published" ? "Published" : "Actual"} {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                        <p className="text-sm text-violet-700 mt-2">Sandbox {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                      </div>
                      {locationSumsByDayOfWeekArray.map((day, index) => {
                        const SPLHChange = (
                          day.sales / day.new_hours -
                          day.sales / day.old_hours
                        ).toFixed(2);
                        return (
                          <div
                            key={index}
                            className="flex-1"
                          >
                            <p className="text-slate-900 text-sm font-bold">
                              {formatValue((day.sales / day.old_hours), "$")}
                            </p>
                            <p className="text-violet-700 text-sm font-bold mt-2">
                              {formatValue((day.sales / day.new_hours), "$")}
                            </p>
                            <div className="w-fit mt-2">
                              <div
                                className={`${
                                  SPLHChange < 0
                                    ? "bg-rose-100 border border-rose-200 text-rose-800"
                                    : SPLHChange > 0
                                    ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                    : "bg-slate-100 border border-slate-200 text-slate-600"
                                } flex items-center justify-center rounded px-1`}
                              >
                                <div>
                                  {SPLHChange < 0 ? (
                                    <RemoveIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  ) : SPLHChange > 0 ? (
                                    <AddIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    /> ) : ( <div></div>
                                  )}
                                </div>
                                <p className="text-xs">
                                  {formatValue((Math.abs(SPLHChange)), "$")}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex-1">
                        <p className="text-slate-900 text-sm font-bold">
                          {formatValue((
                            sumWeekData(locationSumsByDayOfWeekArray).sales /
                            sumWeekData(locationSumsByDayOfWeekArray).old_hours
                          ), "$")}
                        </p>
                        <p className="text-violet-700 text-sm font-bold mt-2">
                          {formatValue((
                            sumWeekData(locationSumsByDayOfWeekArray).sales /
                            sumWeekData(locationSumsByDayOfWeekArray).new_hours
                          ), "$")}
                        </p>
                        <div className="w-fit mt-2">
                          {/* Define SPLHChange variable */}
                          {(() => {
                            const SPLHChange = (
                              sumWeekData(locationSumsByDayOfWeekArray).sales /
                                sumWeekData(locationSumsByDayOfWeekArray)
                                  .new_hours -
                              sumWeekData(locationSumsByDayOfWeekArray).sales /
                                sumWeekData(locationSumsByDayOfWeekArray)
                                  .old_hours
                            ).toFixed(2);

                            // Determine the CSS classes based on SPLHChange value
                            let cssClass;
                            if (SPLHChange < 0) {
                              cssClass =
                                "bg-rose-100 border border-rose-200 text-rose-800";
                            } else if (SPLHChange > 0) {
                              cssClass =
                                "bg-emerald-100 border border-emerald-200 text-emerald-800";
                            } else {
                              cssClass =
                                "bg-slate-100 border border-slate-200 text-slate-600";
                            }

                            // Render the styled div based on SPLHChange
                            return (
                              <div
                                className={`flex items-center justify-center rounded px-1 ${cssClass}`}
                              >
                                <div>
                                  {SPLHChange < 0 ? (
                                    <RemoveIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  ) : SPLHChange > 0 ? (
                                    <AddIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    /> ) : ( <div></div>
                                  )}
                                </div>
                                <p className="text-xs">
                                  {formatValue((Math.abs(SPLHChange)), "$")}
                                </p>
                              </div>
                            );
                          })()}
                        </div>
                      </div>
                    </div>
                  ) : selectedMetric === "labor_percent" ? (
                    <div className="w-full flex bg-slate-100 py-3.5">
                      <div className="w-[14%] text-slate-900 px-6">
                        <p className="text-slate-900 text-sm font-bold">Average</p>
                      </div>
                      <div className="flex-1 pr-10">
                        <p className="text-sm text-slate-500">{runType === "forecasted_sandbox_vs_published" ? "Published" : "Actual"} {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                        <p className="text-sm text-violet-700 mt-2">Sandbox {selectedMetric === "hours" ? "Hours" : selectedMetric === "splh" ? "SPLH" : selectedMetric === "labor_percent" ? "Labor %" : ""}</p>
                      </div>
                      {locationSumsByDayOfWeekArray.map((day, index) => {
                        const laborPercentChange = (
                          (day.new_cost / day.sales) * 100 -
                          (day.old_cost / day.sales) * 100
                        ).toFixed(2);
                        return (
                          <div
                            key={index}
                            className="flex-1"
                          >
                            <p className="text-slate-900 text-sm font-bold">
                              {formatValue(((day.old_cost / day.sales) * 100), "%")}
                            </p>
                            <p className="text-violet-700 text-sm font-bold mt-2">
                              {formatValue(((day.new_cost / day.sales) * 100), "%")}
                            </p>
                            <div className="w-fit mt-2">
                              <div
                                className={`${
                                  laborPercentChange > 0
                                    ? "bg-rose-100 border border-rose-200 text-rose-800"
                                    : laborPercentChange < 0
                                    ? "bg-emerald-100 border border-emerald-200 text-emerald-800"
                                    : "bg-slate-100 border border-slate-200 text-slate-600"
                                } flex items-center justify-center rounded px-1`}
                              >
                                <div>
                                  {laborPercentChange < 0 ? (
                                    <RemoveIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  ) : laborPercentChange > 0 ? (
                                    <AddIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    /> ) : ( <div></div>
                                  )}
                                </div>
                                <p className="text-xs">
                                  {formatValue(Math.abs(laborPercentChange))}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex-1">
                        <p className="text-slate-900 text-sm font-bold">
                          {formatValue((
                            (sumWeekData(locationSumsByDayOfWeekArray)
                              .old_cost /
                              sumWeekData(locationSumsByDayOfWeekArray).sales) *
                            100
                          ), "%")}
                        </p>
                        <p className="text-violet-700 text-sm font-bold mt-2">
                          {formatValue((
                            (sumWeekData(locationSumsByDayOfWeekArray)
                              .new_cost /
                              sumWeekData(locationSumsByDayOfWeekArray).sales) *
                            100
                          ), "%")}
                        </p>
                        <div className="w-fit mt-2">
                          {/* Define laborPercentChange variable */}
                          {(() => {
                            const laborPercentChange = (
                              (sumWeekData(locationSumsByDayOfWeekArray)
                                .new_cost /
                                sumWeekData(locationSumsByDayOfWeekArray)
                                  .sales) *
                                100 -
                              (sumWeekData(locationSumsByDayOfWeekArray)
                                .old_cost /
                                sumWeekData(locationSumsByDayOfWeekArray)
                                  .sales) *
                                100
                            ).toFixed(2);

                            // Determine the CSS classes based on laborPercentChange value
                            let cssClass;
                            if (laborPercentChange > 0) {
                              cssClass =
                                "bg-rose-100 border border-rose-200 text-rose-800";
                            } else if (laborPercentChange < 0) {
                              cssClass =
                                "bg-emerald-100 border border-emerald-200 text-emerald-800";
                            } else {
                              cssClass =
                                "bg-slate-100 border border-slate-200 text-slate-600";
                            }

                            // Render the styled div based on laborPercentChange
                            return (
                              <div
                                className={`flex items-center justify-center rounded px-1 ${cssClass}`}
                              >
                                <div>
                                  {laborPercentChange < 0 ? (
                                    <RemoveIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  ) : laborPercentChange > 0 ? (
                                    <AddIcon
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "2px",
                                      }}
                                    /> ) : ( <div></div>
                                  )}
                                </div>
                                <p className="text-xs">
                                  {formatValue(Math.abs(laborPercentChange))}
                                </p>
                              </div>
                            );
                          })()}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : selectedVisualization === "bar_chart" ? (
                selectedMetric === "hours" ? (
                  <div className="w-full flex flex-col items-center pr-20 pt-10">
                    {selectedStores.length === 1 ? (
                      <h3 className="text-lg ml-10">
                        Labor Hours Recommended For {storeName}
                      </h3>
                    ) : (
                      <h3 className="text-lg ml-10">
                        Average Labor Hours Recommended Across{" "}
                        {selectedStores.length} Locations
                      </h3>
                    )}
                    <div className="mt-5">
                      <BarChart
                        width={1000}
                        height={400}
                        data={averageHoursArray}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={<CustomLegend />} />
                        <Bar dataKey="old_hours" fill="#64748b" />
                        <Bar dataKey="new_hours" fill="#6d28d9" />
                      </BarChart>
                    </div>
                  </div>
                ) : selectedMetric === "splh" ? (
                  <div className="w-full flex flex-col items-center pr-20 pt-10">
                    {selectedStores.length === 1 ? (
                      <h3 className="text-lg ml-10">
                        Sales Per Labor Hour Recommended For {storeName}
                      </h3>
                    ) : (
                      <h3 className="text-lg ml-10">
                        Average Sales Per Labor Hour Recommended Across{" "}
                        {selectedStores.length} Locations
                      </h3>
                    )}
                    <div className="mt-5">
                      <BarChart
                        width={1000}
                        height={400}
                        data={averageSPLHArray}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={<CustomLegend />} />
                        <Bar dataKey="old_SPLH" fill="#64748b" />
                        <Bar dataKey="new_SPLH" fill="#6d28d9" />
                      </BarChart>
                    </div>
                  </div>
                ) : selectedMetric === "labor_percent" ? (
                  <div className="w-full flex flex-col items-center pr-20 pt-10">
                    {selectedStores.length === 1 ? (
                      <h3 className="text-lg ml-10">
                        Labor Percent Recommended For {storeName}
                      </h3>
                    ) : (
                      <h3 className="text-lg ml-10">
                        Average Labor Percent Recommended Across{" "}
                        {selectedStores.length} Locations
                      </h3>
                    )}
                    <div className="mt-5">
                      <BarChart
                        width={1000}
                        height={400}
                        data={averageLaborPercentArray}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={<CustomLegend />} />
                        <Bar dataKey="old_labor_percent" fill="#64748b" />
                        <Bar dataKey="new_labor_percent" fill="#6d28d9" />
                      </BarChart>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )
              ) : selectedVisualization === "line_chart" ? (
                selectedMetric === "hours" ? (
                  <div className="w-full flex flex-col items-center pr-20 pt-10">
                    {selectedStores.length === 1 ? (
                      <h3 className="text-lg ml-10">
                        Labor Hours Recommended For {storeName}
                      </h3>
                    ) : (
                      <h3 className="text-lg ml-10">
                        Average Labor Hours Recommended Across{" "}
                        {selectedStores.length} locations
                      </h3>
                    )}
                    <div className="mt-5">
                      <LineChart
                        width={1000}
                        height={400}
                        data={averageHoursArray}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={<CustomLegend />} />
                        <Line
                          type="monotone"
                          dataKey="new_hours"
                          stroke="#6d28d9"
                        />
                        <Line
                          type="monotone"
                          dataKey="old_hours"
                          stroke="#64748b"
                        />
                      </LineChart>
                    </div>
                  </div>
                ) : selectedMetric === "splh" ? (
                  <div className="w-full flex flex-col items-center pr-20 pt-10">
                    {selectedStores.length === 1 ? (
                      <h3 className="text-lg ml-10">
                        Sales Per Labor Hour Recommended For {storeName}
                      </h3>
                    ) : (
                      <h3 className="text-lg ml-10">
                        Average Sales Per Labor Hour Recommended Across{" "}
                        {selectedStores.length} Locations
                      </h3>
                    )}
                    <div className="mt-5">
                      <LineChart
                        width={1000}
                        height={400}
                        data={averageSPLHArray}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={<CustomLegend />} />
                        <Line
                          type="monotone"
                          dataKey="new_SPLH"
                          stroke="#6d28d9"
                        />
                        <Line
                          type="monotone"
                          dataKey="old_SPLH"
                          stroke="#64748b"
                        />
                      </LineChart>
                    </div>
                  </div>
                ) : selectedMetric === "labor_percent" ? (
                  <div className="w-full flex flex-col items-center pr-20 pt-10">
                    {selectedStores.length === 1 ? (
                      <h3 className="text-lg ml-10">
                        Labor Percent Recommended For {storeName}
                      </h3>
                    ) : (
                      <h3 className="text-lg ml-10">
                        Average Labor Percent Recommended Across{" "}
                        {selectedStores.length} Locations
                      </h3>
                    )}
                    <div className="mt-5">
                      <LineChart
                        width={1000}
                        height={400}
                        data={averageLaborPercentArray}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend content={<CustomLegend />} />
                        <Line
                          type="monotone"
                          dataKey="new_labor_percent"
                          stroke="#6d28d9"
                        />
                        <Line
                          type="monotone"
                          dataKey="old_labor_percent"
                          stroke="#64748b"
                        />
                      </LineChart>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )
              ) : selectedVisualization === "labor_standards" ? (
                selectedMetric === "hours" ? (
                <div className="w-full flex flex-col items-center pr-10 pt-10">
                  <h3 className="text-lg ml-10">
                    Sandbox Labor Standards Contribution to Labor Hours
                  </h3>
                  <div className="mt-5">
                    <BarChart
                      width={1250}
                      height={450}
                      data={laborStandardsDataByDepartment}
                      layout="vertical"
                      // margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" domain={[0, laborStandardsMaxHours]} />
                      <YAxis type="category" dataKey="name" width={250} interval={0} tick={{ fontSize: 12 }} />
                      <Tooltip content={<CustomTooltip />} />
                      <Legend content={<CustomLegend />} />
                      <Bar dataKey="hours" fill="#6d28d9" />
                    </BarChart>
                  </div>
                </div>
                ) : selectedMetric === "splh" ? (
                  <div className="w-full h-[420px] flex flex-col items-center justify-center pt-10">
                    <p className="text-xl text-slate-500">
                      Labor standards contribution to SPLH is not a relevant metric
                    </p>
                    <p className="text-xl text-slate-500 mt-5">
                      Please select the metric "Hours" to see contributions
                    </p>
                  </div>
                ) : selectedMetric === "labor_percent" ? (
                  <div className="w-full h-[420px] flex flex-col items-center justify-center pt-10">
                    <p className="text-xl text-slate-500">
                      Labor standards contribution to labor percent is not a relevant metric
                    </p>
                    <p className="text-xl text-slate-500 mt-5">
                      Please select the metric "Hours" to see contributions
                    </p>
                  </div>
                ) : (
                  <div></div>
                )
              ) : (
                <div></div>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default LaborModelTestModal;
