import React, { useState } from "react";
import MetricWeightSlider from "./MetricWeightSlider";

export default function AlgorithmSettingsDisplayConfigurations(props) {
  const { autoScheduleSettings } = props;
  let metricNames = ["Business", "Customer", "Employee"];
  const [weights, setWeights] = useState([
    autoScheduleSettings.business,
    autoScheduleSettings.customer,
    autoScheduleSettings.employee,
  ]);

  React.useEffect(() => {
    setWeights([
      autoScheduleSettings.business,
      autoScheduleSettings.customer,
      autoScheduleSettings.employee,
    ]);
  }, [autoScheduleSettings]);

  let descriptions = [];
  if (weights[0] > 55) {
    descriptions.push("This schedule has a strong emphasis on adhering to your labor budget.");
  } else if (weights[0] > 45) {
    descriptions.push("This schedule has an emphasis on adhering to your labor budget.");
  }
  if (weights[1] > 55) {
    descriptions.push("This schedule has a strong emphasis on service level and short wait times.");
  } else if (weights[1] > 45) {
    descriptions.push("This schedule has an emphasis on service level and short wait times.");
  }
  if (weights[2] > 55) {
    descriptions.push("This schedule has a strong emphasis on employee preferences and consistent shifts.");
  } else if (weights[2] > 45) {
    descriptions.push("This schedule has an emphasis on employee preferences and consistent shifts.");
  }
  if (descriptions.length === 0) {
    descriptions.push("This schedule takes a balanced approach to adherence to labor budget, employee preferences, and service level.");
  }


  function handleWeightChange(metricIndex, newWeight) {
    let totalWeight = weights.reduce((acc, val) => acc + val, 0);
    let newWeights = [...weights];
    let difference = newWeight - newWeights[metricIndex];
    let adjustment = difference / (metricNames.length - 1);
    newWeights[metricIndex] = newWeight;
    metricNames.forEach((name, index) => {
      if (index !== metricIndex) {
        let adjustedValue = newWeights[index] - adjustment;
        if (adjustedValue < 0) {
          adjustment += newWeights[index] / (metricNames.length - 1);
          adjustedValue = 0;
        }
        newWeights[index] = adjustedValue;
      }
    });
    // Round to avoid floating point errors and ensure the sum is exactly 100
    newWeights.forEach((weight, index) => {
      newWeights[index] = Math.round(weight);
    });
    const newTotalWeight = newWeights.reduce((acc, val) => acc + val, 0);
    // Adjust for rounding errors
    const roundingError = 100 - newTotalWeight;
    if (roundingError !== 0) {
      const indexToAdjust =
        roundingError > 0
          ? newWeights.indexOf(Math.min(...newWeights))
          : newWeights.indexOf(Math.max(...newWeights));
      newWeights[indexToAdjust] += roundingError;
    }
    // Ensure scores always add up to 100 and never go below 0
    let newBusinessScore = Math.max(newWeights[0], 0);
    let newCustomerScore = Math.max(newWeights[1], 0);
    let newEmployeeScore = Math.max(newWeights[2], 0);
    const totalScore = newBusinessScore + newCustomerScore + newEmployeeScore;
    if (totalScore !== 100) {
      const adjustment = (100 - totalScore) / 3;
      newBusinessScore += adjustment;
      newCustomerScore += adjustment;
      newEmployeeScore += adjustment;
    }
    setWeights(newWeights);
  }


  const metricWeightSliders = metricNames.map((metricName, index) => {
    return (
      <MetricWeightSlider
        key={index}
        metricName={metricName}
        initialWeight={weights[index]}
        onWeightChange={(newWeight) => handleWeightChange(index, newWeight)}
      />
    );
  });


  return (
    <div className="metric-weight-selector">
      {metricWeightSliders}
      <div className="mt-10 flex items-center gap-24">
        <div className="w-32 h-32 rounded-lg border border-gray-500 flex flex-col items-center justify-center shadow">
          <p className="text-gray-600">Business</p>
          <p className="text-gray-900 font-bold text-2xl">{weights[0]}</p>
        </div>
        <div className="w-32 h-32 rounded-lg border border-gray-500 flex flex-col items-center justify-center shadow">
          <p className="text-gray-600">Customer</p>
          <p className="text-gray-900 font-bold text-2xl">{weights[1]}</p>
        </div>
        <div className="w-32 h-32 rounded-lg border border-gray-500 flex flex-col items-center justify-center shadow">
          <p className="text-gray-600">Employee</p>
          <p className="text-gray-900 font-bold text-2xl">{weights[2]}</p>
        </div>
      </div>
      <div className="mt-10">
        <div>
          {descriptions.map((description, i) => <p key={i} className="text-lg">{description}</p>)}
        </div>
      </div>
      <div className="mt-10">
        <button
          className="bg-primary hover:bg-blue-700 text-white px-8 py-3 rounded-lg"
          onClick={() => props.saveAutoScheduleSettings(weights)}
        >
          Save Settings
        </button>
      </div>
    </div>
  );
}
