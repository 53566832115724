import * as d3 from "d3";
import {
  getColorFromRoleType,
  colorLookup,
  convertBarColor,
  convertBorderColor,
} from "../../../../../helpers/helpers";

export function RosterGraphD3(container, d, chartid, get_t_str, role_types) {
  const boxwidth = container.getBoundingClientRect().width;
  const boxheight = 450;

  var margin = { top: 20, right: 40, bottom: 20, left: 150 },
    width = boxwidth - margin.left - margin.right,
    height = boxheight - margin.top - margin.bottom;

  // append the svg object to the body of the page
  d3.select("#" + chartid + " svg").remove();

  d.sort(function (a, b) {
    return (
      d3.descending(a.shift_start, b.shift_start) ||
      d3.descending(a.shift_length, b.shift_length)
    );
  });

  var svg = d3
    .select("#" + chartid)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

  // Have a look to the data
  var subgroups = [
    "pre_shift",
    "pre_break",
    "break",
    "post_break",
    "post_shift",
  ];

  // Function format name

  function formatName(name) {
    // Convert the name to lowercase and split it into words
    const words = name.toLowerCase().split(' ');

    // Capitalize the first letter of each word
    const formattedName = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space between them
    return formattedName.join(' ');
  }

  //stack the data --> stack per subgroup
  var stackedData = d3.stack().keys(subgroups)(d);

  // Calculate the minimum and maximum shift start and end times
  const minStartTime = d3.min(d, (item) => item.shift_start);
  const maxEndTime = d3.max(d, (item) => item.shift_end);

  // Add a buffer of 1 hour to the domain
  const xDomainStart = minStartTime - 1;
  const xDomainEnd = maxEndTime + 1;

  const tickValues = d3.range(xDomainStart, xDomainEnd);

  // Create the x-axis scale with the updated domain
  var x = d3.scaleLinear().domain([xDomainStart, xDomainEnd]).range([0, width]);

  // Update the x-axis with the new scale and tick values
  var xAxis = d3
    .axisBottom(x)
    .tickValues(tickValues) // Use the updated domain values as tick values
    .tickFormat((d) => get_t_str(d))
    .tickSizeOuter(0);


  // Remove the existing x-axis label
  svg.select(".x-axis").remove();

  function addVerticalDottedLines(svg, x, height) {
    const ticks = d3.range(x.domain()[0], x.domain()[1]);

    svg
      .selectAll(".vertical-line")
      .data(ticks)
      .enter()
      .append("line")
      .attr("class", "vertical-line")
      .attr("x1", (d) => x(d))
      .attr("y1", 0)
      .attr("x2", (d) => x(d))
      .attr("y2", height)
      .style("stroke", "lightgray")
      .style("stroke-dasharray", "2,2");
  }

  // Add the updated x-axis and label
  var xAxisLabel = svg
    .append("g")
    .attr("class", "axis x-axis")
    .attr("transform", "translate(0," + height + ")")
    .style("font-size", 10)
    .call(xAxis);

  xAxisLabel.select(".domain").style("stroke", "lightgray");
  xAxisLabel.selectAll(".tick line").style("stroke", "lightgray");
  xAxisLabel.selectAll("text").style("fill", "#64748b");

  // Add vertical dotted lines
  addVerticalDottedLines(svg, x, height);

  // Add Y axis for names
  var y = d3
    .scaleBand()
    .domain(d.map((d) => formatName(d.name)))
    .range([height, 0])
    .padding([0.1]);

  var yAxis = svg.append("g").call(d3.axisLeft(y).tickSizeOuter(0));

  yAxis.selectAll("text").style("fill", "#64748b").style("font-size", 14);

  yAxis.select(".domain").style("stroke", "lightgray");
  yAxis.selectAll(".tick line").style("stroke", "lightgray");


  // Old version to get colors

  // function get_bar_color(subgroup, role_type) {
  //   const hexcolor = convertBarColor(
  //     getColorFromRoleType(role_type, role_types, true)
  //   );
  //   return d3
  //     .scaleOrdinal()
  //     .domain(subgroups)
  //     .range(["none", hexcolor, "#eeeee", hexcolor, "none"])(subgroup);
  // }


  // function get_border_color(subgroup, role_type) {
  //   const borderColor = convertBorderColor(
  //     getColorFromRoleType(role_type, role_types, true)
  //   );
  //   return d3
  //     .scaleOrdinal()
  //     .domain(subgroups)
  //     .range(["none", borderColor, "#000", borderColor, "none"])(subgroup);
  // }


  // var barOpacity = d3.scaleOrdinal().domain(subgroups).range([0, 1, 1, 1, 0]);

  const getColorLookup = {
    "bg-violet-600": "#7c3aed",
    "bg-sky-700": "#0369a1",
    "bg-cyan-600": "#0891b2",
    "bg-emerald-600": "#059669",
    "bg-lime-600": "#65a30d",
    "bg-amber-500": "#f59e0b",
    "bg-orange-600": "#ea580c",
    "bg-red-600": "#dc2626",
    "bg-pink-600": "#db2777",
  };

  function getBarColor(subgroup, color_string) {
    const color = getColorLookup[color_string]
    return d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(["none", color, "eeeee", color, "none"])(subgroup);
  }

  // create tooltip
  const tooltip = d3
    .select("#" + chartid)
    .append("div")
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-color", "lightgray")
    .style("border-width", "1px")
    .style("border-radius", "5px")
    .style("padding", "15px")
    .style("position", "absolute")
    .style("pointer-events", "none")
    .style("text-align", "left");

  // Three function that change the tooltip when user hover / move / leave a cell
  const mouseover = function (event, d) {
    tooltip.html(function (data) {
      // remove the Break portion of the tooltip when there are no breaks
      if (d.data.break == 0) {
        return (
          "<b>" +
          formatName(d.data.name) +
          " (" +
          d.data.role_type +
          ")" +
          "</b><br>" +
          "Shift: " +
          get_t_str(d.data.shift_start) +
          " - " +
          get_t_str(d.data.shift_end) +
          "<br>" +
          "Hours: " +
          d.data.hours +
          " hrs"
        );
      } else {
        return (
          "<b>" +
          formatName(d.data.name) +
          " (" +
          d.data.role_type +
          ")" +
          "</b><br>" +
          "Shift: " +
          get_t_str(d.data.shift_start) +
          " - " +
          get_t_str(d.data.shift_end) +
          "<br>" +
          "Hours: " +
          d.data.hours +
          " hrs" +
          "<br>" +
          "Break Start: " +
          get_t_str(d.data.break_start) +
          "<br>" +
          "Break Length: " +
          d.data.break_length +
          " hrs"
        );
      }
    });
    //.style("opacity", 1);
  };
  const mousemove = function (event, d) {
    tooltip
      .style("transform", "translateY(-55%)")
      // .style("left", 250 + "px")
      // .style("top", 250 + "px");
      .style("left", event.x - 10 + "px")
      .style("top", event.y - 80 + "px");
  };
  const mouseleave = function (event, d) {
    tooltip.style("opacity", 0);
  };

  // Show the bars
  var bars = svg
    .append("g")
    .selectAll("g")
    // Enter in the stack data = loop key per key = group per group
    .data(stackedData)
    .enter()
    .append("g");

  bars.each(function (e) {
    d3.select(this)
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("y", (d) => y(formatName(d.data.name)))
      .attr("fill", (d) => getBarColor(e.key, d.data.color))
      // .attr("stroke", (d) => get_border_color(e.key, d.data.role_type))
      .attr("stroke-width", 2)
      .attr("x", (d) => x(d.data.shift_start))
      .attr("width", (d) => x(d.data.shift_end) - x(d.data.shift_start))
      .attr("height", y.bandwidth())
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .transition()
      .duration(1000)
      .delay((d, i) => i * 10);
  });

  svg
    .append("g")
    .selectAll("g")
    .data([stackedData[1]])
    .enter()
    .append("g")
    .each(function (e) {
      d3.select(this)
        .selectAll("g")
        .data((d) => d)
        .enter()
        .append("g")
        .each(function () {
          d3.select(this)
            .append("text")
            .attr("x", (d) => x(d.data.shift_start) + 10)
            .attr("y", (d) => y(formatName(d.data.name)) + y.bandwidth() / 2 + 10 / 2)
            .text(
              (d) =>
                ` ${get_t_str(d.data.shift_start)} - ${get_t_str(
                  d.data.shift_end
                )}`
            )
            .style("fill", "white")
            .style("font-size", 14)
            .style("pointer-events", "none");
        });
    });

  return svg;
}
