import React from 'react'
import { Drawer } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import TradeStatusBar from './TradeStatusBar';
import EmployeeShiftCardUpdatedStatic from '../../shared/shiftswaps/EmployeeShiftCardUpdatedStatic';
import { formatDateShift } from '../../utilities/helpers';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DrawerHeader from '../../shared/helpers/DrawerHeader';
import DrawerFooterContainerSmall from '../../shared/helpers/DrawerFooterContainerSmall';
import ButtonGrayed from '../../shared/helpers/ButtonGrayed';
import ButtonFilled from '../../shared/helpers/ButtonFilled';
import ButtonOutlined from '../../shared/helpers/ButtonOutlined';

function PendingTableEmployeeRowTradeDrawer(props) {

  function handleTradeCancel() {
    props.rescind_trade_shift(props.tradeInfo.id);
  }

  let completion = ""
  if (props.tradeInfo.rescinded_by === "employee-drop" && props.tradeInfo.employee_pickup_response === "approved") {
    completion = "trade_rescinded_by_sender_after_approved"
  } else if (props.tradeInfo.rescinded_by === "employee-drop") {
    completion = "trade_rescinded_by_sender_before_approved"
  } else if (props.tradeInfo.employee_pickup_response === "approved" && props.tradeInfo.acting_manager_response === "pending") {
    completion = "trade_approved_by_employee"
  } else if (props.tradeInfo.employee_pickup_response === "denied" && props.tradeInfo.acting_manager_response === "pending") {
    completion = "trade_denied_by_employee"
  } else if (props.tradeInfo.employee_pickup_response === "approved" && props.tradeInfo.acting_manager_response === "approved") {
    completion = "trade_approved_by_manager"
  } else if (props.tradeInfo.employee_pickup_response === "approved" && props.tradeInfo.acting_manager_response === "denied") {
    completion = "trade_denied_by_manager"
  } else {
    completion = "trade_requested_by_sender"
  }

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader title={props.tradeReceiver ? "Incoming Trade Request" : "Outgoing Trade Request"} onClose={props.onClose} />
          <div className="w-full px-6">
            <div className="w-full">
              <div className="w-full mt-4">
                <TradeStatusBar
                  completion={completion}
                  tradeReceiver={props.tradeReceiver}
                  receiverName={props.tradeInfo.employee_pickup.first_name + " " + props.tradeInfo.employee_pickup.last_name}
                  senderName={props.tradeInfo.employee_drop.first_name + " " + props.tradeInfo.employee_drop.last_name}
                  renderAnimations={props.renderAnimations}
                />
              </div>
            </div>
            <div className="w-full mt-12 relative flex flex-col items-center justify-center">
              <div className="w-full">
                <p className="text-xs text-slate-500">{props.tradeReceiver ? `${props.tradeInfo.employee_drop.first_name + " " + props.tradeInfo.employee_drop.last_name} is trading away` : "You are trading away"}</p>
                <div className="mt-0.5">
                  <EmployeeShiftCardUpdatedStatic
                    date={formatDateShift(props.tradeInfo.senderDate)}
                    shiftStartNumber={props.tradeInfo.shift_drop_info.shift_start_formatted[0]}
                    shiftStartLetters={props.tradeInfo.shift_drop_info.shift_start_formatted[1]}
                    shiftEndNumber={props.tradeInfo.shift_drop_info.shift_end_formatted[0]}
                    shiftEndLetters={props.tradeInfo.shift_drop_info.shift_end_formatted[1]}
                    break={props.tradeInfo.shift_drop_info.break_formatted}
                    station={props.tradeInfo.shift_drop_info.station?.name}
                    role={props.tradeInfo.shift_drop_info.role}
                    roleType={props.tradeInfo.shift_drop_info.roleType}
                    location={props.tradeInfo.shift_drop_info.location}
                  />
                </div>
              </div>
              <div className="w-full flex justify-center absolute left-28">
                <div className="w-14 h-14 bg-violet-100 flex items-center justify-center rounded-full border border-violet-200 text-violet-700">
                  <SwapVertIcon style={{ fontSize: "32px" }}/>
                </div>
              </div>
              <div className="w-full mt-1">
                <div className="mt-0.5">
                  <EmployeeShiftCardUpdatedStatic
                    date={formatDateShift(props.tradeInfo.receiverDate)}
                    shiftStartNumber={props.tradeInfo.shift_pickup_info.shift_start_formatted[0]}
                    shiftStartLetters={props.tradeInfo.shift_pickup_info.shift_start_formatted[1]}
                    shiftEndNumber={props.tradeInfo.shift_pickup_info.shift_end_formatted[0]}
                    shiftEndLetters={props.tradeInfo.shift_pickup_info.shift_end_formatted[1]}
                    break={props.tradeInfo.shift_pickup_info.break_formatted}
                    station={props.tradeInfo.shift_pickup_info.station?.name}
                    role={props.tradeInfo.shift_pickup_info.role}
                    roleType={props.tradeInfo.shift_pickup_info.roleType}
                    location={props.tradeInfo.shift_pickup_info.location}
                  />
                </div>
                <p className="text-xs text-slate-500 mt-0.5">{props.tradeReceiver ? "You are trading away" : `${props.tradeInfo.employee_pickup.first_name + " " + props.tradeInfo.employee_pickup.last_name} is trading away`}</p>
              </div>
            </div>
          </div>
          <DrawerFooterContainerSmall>
            {props.tradeReceiver ?
              <div className="w-full">
                {(props.tradeInfo.acting_manager_response === "pending" && completion !== "trade_approved_by_employee" && completion !== "trade_rescinded_by_sender_before_approved") ? (
                  <div className="w-full flex items-center">
                    <div className="w-1/2 pr-2">
                      <ButtonOutlined text="Deny Trade" onClick={() => props.respond_to_trade_request(props.tradeInfo.shift_drop.id, props.tradeInfo.uuid, "denied")} />
                    </div>
                    <div className="w-1/2 pl-2">
                      <ButtonFilled text="Approve Trade" onClick={() => props.respond_to_trade_request(props.tradeInfo.shift_drop.id, props.tradeInfo.uuid, "approved")} />
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex items-center">
                    <div className="w-1/2 pr-2">
                      <ButtonGrayed text="Deny Trade" />
                    </div>
                    <div className="w-1/2 pl-2">
                      <ButtonGrayed text="Accept Trade" />
                    </div>
                  </div>
                )
              }
              </div> :
              <div className="w-full">
                {props.tradeInfo.acting_manager_response === "pending" ?
                  <ButtonFilled text="Cancel Request" onClick={() => handleTradeCancel()} /> :
                  <ButtonGrayed text="Cancel Request" />
                }
              </div>
            }
          </DrawerFooterContainerSmall>
          <div className="h-[200px]"></div>
        </div>
      </div>
    </Drawer>
  )
}

export default PendingTableEmployeeRowTradeDrawer
