import React from 'react'
import ScheduleIcon from "@mui/icons-material/Schedule";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { mergeAvailability2 } from '../../utilities/helpers';
import EmployeeProfileAvailabilityCard from './EmployeeProfileAvailabilityCard';

function EmployeeProfileAvailabilityTab(props) {

  const availabilityData2 = props.selectedTeamMemberAvailabilityData ? mergeAvailability2(
    props.selectedTeamMemberAvailabilityData?.current,
    props.selectedTeamMemberAvailabilityData?.approved,
    props.selectedTeamMemberAvailabilityData?.pending
  ) : []


  return (
    <div className="w-full flex flex-col items-center">
      <div>
        <h3 className="text-slate-900 text-2xl font-bold">Default Availability</h3>
      </div>
      <div className="mt-5 flex space-x-5">
        <div className="bg-white p-5 rounded-xl w-[410px]">
          <div className="flex justify-between">
            <p className="text-slate-500 text-xs leading-5 w-[70%] -mt-0.5">
              This is {props.selectedTeamMemberData?.first_name}'s default availability. To see temporary availability or time off, go to the availability section of the scheduling display.
            </p>
            <div className="text-slate-500 text-xxs">
              <div className="flex items-center space-x-1.5">
                <div>
                  <ScheduleIcon style={{ fontSize: "16px" }} />
                </div>
                <p>Availability</p>
              </div>
              <div className="flex items-center space-x-1.5 mt-1">
                <div>
                  <StarBorderIcon style={{ fontSize: "16px" }} />
                </div>
                <p>Preferences</p>
              </div>
              <div className="flex items-center space-x-1.5 mt-1">
                <div>
                  <CalendarMonthIcon style={{ fontSize: "16px" }} />
                </div>
                <p>In Effect Date</p>
              </div>
            </div>
          </div>
          <div className="mt-3">
            {availabilityData2?.map((dow, index) => {
              return(
                <div key={index}>
                  <EmployeeProfileAvailabilityCard
                    dow={dow}
                    selectedTeamMemberData={props.selectedTeamMemberData}
                    create_availability_request={props.create_availability_request}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeProfileAvailabilityTab
