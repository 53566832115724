import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TradeTargetSelectModal from "./TradeTargetSelectModal";
import ShiftDropModal from "./ShiftDropModal";
import ShiftDropDrawer from "./ShiftDropDrawer";
import { getCardColor2 } from "../../../../../helpers/helpers";

function EmployeeShiftCardUpdated(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  function handleAddToPoolPress(note) {
    props.drop_shift(props.shift.id, null, note);
    handleClose();
  }

  const shiftInfo = props.shift;

  let badgeText = "";
  if (shiftInfo?.badge === "is_pending_drop_by_user") {
    badgeText = "Drop Requested";
  } else if (
    shiftInfo?.badge === "is_trade_by_user_pending_employee_response"
  ) {
    badgeText = "Offered Trade";
  } else if (shiftInfo?.badge === "is_trade_by_user_pending_manager_response") {
    badgeText = "Offered Trade";
  } else if (shiftInfo?.badge === "is_trade_to_user_pending_self_response") {
    badgeText = "";
  } else if (shiftInfo?.badge === "is_trade_to_user_pending_manager_response") {
    badgeText = "";
  } else if (shiftInfo?.badge === "is_pending_pickup_by_user") {
    badgeText = "";
  } else {
    badgeText = "";
  }

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const shiftCardColor = getCardColor2(
    props.roleId?.id,
    props.roleNames,
    false
  );

  return (
    <>
      <div
        className="w-full rounded-2xl bg-white border border-slate-200 hover:border-slate-500 px-5 py-5 flex flex-row items-center cursor-pointer relative"
        onClick={toggleDrawer(true)}
      >
        <div className="flex-1">
          <div>
            <p className="text-slate-600">{props.dateFormatted}</p>
          </div>
          <div className="flex flex-row items-center mt-1.5">
            <p className="text-slate-900 text-[22px] font-bold">
              {props.shiftStartNumber}
            </p>
            <p className="text-slate-600 text-base ml-1">
              {props.shiftStartLetters}
            </p>
            <div className="ml-3 text-slate-600">
              <ArrowForwardIcon color="#475569" size={22} />
            </div>
            <p className="text-slate-900 text-[22px] font-bold ml-3">
              {props.shiftEndNumber}
            </p>
            <p className="text-slate-600 text-base ml-1">
              {props.shiftEndLetters}
            </p>
          </div>
          <div className="mt-1.5">
            <p className="text-slate-600">
              {props.station
                ? props.station
                : props.role
                ? props.role
                : props.roleType}{" "}
              @ {props.location}
            </p>
          </div>
        </div>
        <div className="text-slate-600">
          <ChevronRightIcon color="#475569" size={22} />
        </div>
        {badgeText && (
          <div className="bg-amber-50 px-2 py-1 rounded-lg absolute bottom-2 right-2">
            <p className="text-amber-800 text-xxs">{badgeText}</p>
          </div>
        )}
      </div>
      <ShiftDropModal
        open={open}
        onClose={handleClose}
        shiftInfo={shiftInfo}
        badgeText={badgeText}
        date={props.date}
        shiftStartNumber={props.shiftStartNumber}
        shiftStartLetters={props.shiftStartLetters}
        shiftEndNumber={props.shiftEndNumber}
        shiftEndLetters={props.shiftEndLetters}
        role={props.role}
        location={props.location}
        handleAddToPoolPress={handleAddToPoolPress}
        drop_shift={props.drop_shift}
        trade_shift={props.trade_shift}
        notMyUpcomingShifts={props.notMyUpcomingShifts}
        rescind_drop_shift={props.rescind_drop_shift}
        rescind_trade_shift={props.rescind_trade_shift}
      />
      <ShiftDropDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        shiftInfo={shiftInfo}
        badgeText={badgeText}
        date={props.date}
        firstName={props.firstName}
        lastName={props.lastName}
        isDateInPast={props.isDateInPast}
        shiftStartNumber={props.shiftStartNumber}
        shiftStartLetters={props.shiftStartLetters}
        shiftEndNumber={props.shiftEndNumber}
        shiftEndLetters={props.shiftEndLetters}
        role={props.role}
        station={props.station}
        roleType={props.roleType}
        break={props.break}
        location={props.location}
        handleAddToPoolPress={handleAddToPoolPress}
        drop_shift={props.drop_shift}
        trade_shift={props.trade_shift}
        rescind_drop_shift={props.rescind_drop_shift}
        rescind_trade_shift={props.rescind_trade_shift}
        notMyUpcomingShifts={props.notMyUpcomingShifts}
        shiftCardColor={shiftCardColor}
        roleNames={props.roleNames}
        note={props.note}
      />
    </>
  );
}

export default EmployeeShiftCardUpdated;
