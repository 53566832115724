import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160,
    },
  },
};

function VizualizeAvailOrSchedDepartmentFilter(props) {

  const names = props.roleTypeOptions;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const value_arr = typeof value === "string" ? value.split(",") : value;
    if (value_arr.length > 0) {
      props.setRoleType(value_arr);
    }
  };

  return (
    <div className="mr-1">
      {names.length > 0 && (
        <FormControl size="small">
          <Select
            fullWidth
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={props.roleType}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{
                textAlign: "center",
                width: "300px"
            }}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#6d28d9",
              },
              borderRadius: "8px",
              boxShadow: "none",
              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
          >
            {names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                // classes={{ root: classes.menuItem }}
              >
                <Checkbox
                  checked={props.roleType.indexOf(name) > -1}
                  style={{ color: "#6d28d9" }}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export default VizualizeAvailOrSchedDepartmentFilter
