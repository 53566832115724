import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import { PurpleSwitch } from "../../../helpers/helpers";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SaveIcon from "@mui/icons-material/Save";
import { generateTimeOptions } from "../../../helpers/helpers";
import SpecialHoursOfOperationsInput from "./SpecialHoursOfOperationsInput";
import { get_t_str } from "../../../helpers/helpers";
import { text_to_num } from "../../../helpers/helpers";
import {
  formatDate,
  formatDateReadableWithDayOfWeek,
} from "../requests/utilities/helpers";

function HoursOfOperationTableRow(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [hoursOfOperationData, setHoursOfOperationsData] = React.useState(
    props.location
  );

  const currentDate = new Date();

  const handleAddSpecialHours = () => {
    const newSpecialHours = {
      store: hoursOfOperationData.storeId,
      date: formatDate(currentDate),
      open_time: hoursOfOperationData.standardOperatingHours[0].open_time,
      close_time: hoursOfOperationData.standardOperatingHours[0].close_time,
    };
    const updatedSpecialHours = [
      ...hoursOfOperationData.specialHours,
      newSpecialHours,
    ];
    setHoursOfOperationsData({
      ...hoursOfOperationData,
      specialHours: updatedSpecialHours,
    });
  };

  const handleDeleteSpecialHours = (index) => {
    const updatedSpecialHours = [...hoursOfOperationData.specialHours];
    updatedSpecialHours.splice(index, 1);
    setHoursOfOperationsData({
      ...hoursOfOperationData,
      specialHours: updatedSpecialHours,
    });
  };

  const dayOfWeekNameLookup = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun",
  };

  const dayOfWeekFullNameLookup = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };

  function condenseOperatingHours(hoursArr) {
    const orderedDict = new Map();
    for (const obj of hoursArr) {
      const key =
        obj.open_time === 0 && obj.close_time === 0
          ? `Closed`
          : `${get_t_str(obj.open_time)} - ${get_t_str(obj.close_time)}`;
      if (orderedDict.has(key)) {
        orderedDict.get(key);
        orderedDict.get(key).push(obj.dow);
      } else {
        orderedDict.set(key, [obj.dow]);
      }
    }
    return orderedDict;
  }

  const condensedOperatingHours = condenseOperatingHours(
    props.location.standardOperatingHours
  );

  function findGroups(array) {
    let groups = [];
    let start = array[0];
    let end = array[0];

    for (let i = 1; i < array.length; i++) {
      if (array[i] === end + 1) {
        end = array[i];
      } else {
        groups.push(
          start === end
            ? `${dayOfWeekNameLookup[start]}, `
            : `${dayOfWeekNameLookup[start]} - ${dayOfWeekNameLookup[end]}, `
        );
        start = end = array[i];
      }
    }

    groups.push(
      start === end
        ? `${dayOfWeekNameLookup[start]}, `
        : `${dayOfWeekNameLookup[start]} - ${dayOfWeekNameLookup[end]}, `
    );

    return groups;
  }

  const handleChange = (evt, index, field) => {
    const value = evt.target.value;
    const updatedStandardOperatingHours = [
      ...hoursOfOperationData.standardOperatingHours,
    ];
    const updatedDay = {
      ...updatedStandardOperatingHours[index],
      [field]: text_to_num(value),
    };
    updatedStandardOperatingHours[index] = updatedDay;
    setHoursOfOperationsData({
      ...hoursOfOperationData,
      standardOperatingHours: updatedStandardOperatingHours,
    });
  };

  // Function to check if the store has operating hours for a specific day
  const hasOperatingHoursForDay = (day) =>
    day.open_time !== 0 || day.close_time !== 0;

  // Function to get the checked state for a specific day
  const getCheckedStateForDay = (day) => {
    return hasOperatingHoursForDay(day);
  };

  // Function to handle the toggle of PurpleSwitch
  const handlePurpleSwitchToggle = (dayIndex) => {
    const updatedStandardOperatingHours = [
      ...hoursOfOperationData.standardOperatingHours,
    ];
    const currentDay = updatedStandardOperatingHours[dayIndex];

    // If PurpleSwitch is checked, set open_time and close_time to 0
    if (hasOperatingHoursForDay(currentDay)) {
      updatedStandardOperatingHours[dayIndex] = {
        ...currentDay,
        open_time: 0,
        close_time: 0,
      };
    } else {
      updatedStandardOperatingHours[dayIndex] = {
        ...currentDay,
        open_time: props.location.standardOperatingHours[0].open_time
          ? props.location.standardOperatingHours[0].open_time
          : 9,
        close_time: props.location.standardOperatingHours[0].close_time
          ? props.location.standardOperatingHours[0].close_time
          : 17,
      };
    }

    setHoursOfOperationsData({
      ...hoursOfOperationData,
      standardOperatingHours: updatedStandardOperatingHours,
    });
  };

  function handleSubmit() {
    props.update_hours_for_all_stores(hoursOfOperationData);
    handleClose();
  }

  const maxStartQhnum = props.storeNames.reduce((max, obj) => {
    return obj.start_qhnum > max ? obj.start_qhnum : max;
  }, -Infinity);

  return (
    <>
      <tr
        key={props.i}
        className="hover:bg-slate-50 cursor-pointer"
        onClick={handleOpen}
      >
        <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
          <div>{props.location.locationName}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
          <div className="w-full">
            {Array.from(condensedOperatingHours.entries()).map(
              ([key, value]) => (
                <div key={key} className="flex items-center space-x-2">
                  <p className="text-slate-900">{findGroups(value)}</p>
                  <p className="text-violet-700">{key}</p>
                </div>
              )
            )}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
          {props.location.specialHours.length > 0 ? (
            <div className="w-full">
              {props.location.specialHours.map((day, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <p>{formatDateReadableWithDayOfWeek(day.date)}</p>
                  <p className="text-violet-700">
                    {day.open_time !== 0 && day.close_time !== 0
                      ? `${get_t_str(day.open_time)} - ${get_t_str(
                          day.close_time
                        )}`
                      : "Closed"}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-slate-500">No special hours upcoming</div>
          )}
        </td>
        <td className="relative whitespace-nowrap py-5 pl-12 pr-3 text-right text-sm font-medium">
          <div className="flex items-center justify-end w-fit h-full text-slate-500">
            <ChevronRightIcon />
          </div>
        </td>
      </tr>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={props.location.locationName}
              onClose={handleClose}
            />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-slate-900 text-sm font-semibold">
                  Set standard hours
                </p>
                <p className="text-slate-500 text-xs mt-1">
                  Configure the standard hours of operation for this location
                </p>
              </div>
              {/* <div className="mt-5">
                <Divider />
              </div> */}
              <div className="mt-6">
                {hoursOfOperationData.standardOperatingHours.map(
                  (day, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-center justify-between mt-4"
                      >
                        <div className="flex items-center">
                          <div>
                            <PurpleSwitch
                              size="small"
                              checked={getCheckedStateForDay(day)}
                              onChange={() => handlePurpleSwitchToggle(index)}
                            />
                          </div>
                          <p className="text-slate-900 text-xs ml-1">
                            {dayOfWeekFullNameLookup[day.dow]}
                          </p>
                        </div>
                        {day.open_time === 0 && day.close_time === 0 ? (
                          <div className="h-[34px] flex items-cente">
                            <p className="text-slate-900">Closed</p>
                          </div>
                        ) : (
                          <div className="flex items-center gap-x-1">
                            <div className="w-[100px]">
                              <Select
                                fullWidth
                                value={get_t_str(day.open_time)}
                                name="open_time"
                                onChange={(evt) =>
                                  handleChange(evt, index, "open_time")
                                }
                                size="small"
                                sx={{
                                  borderRadius: "8px",
                                  paddingY: "0px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                  fontSize: "12px",
                                }}
                              >
                                {generateTimeOptions(maxStartQhnum).map(
                                  (option, index) => (
                                    <MenuItem key={index} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </div>
                            <div>
                              <div className="text-slate-500 flex items-center justify-center">
                                <ArrowForwardIcon
                                  style={{ fontSize: "16px" }}
                                />
                              </div>
                            </div>
                            <div className="w-[100px]">
                              <Select
                                fullWidth
                                value={get_t_str(day.close_time)}
                                name="close_time"
                                onChange={(evt) =>
                                  handleChange(evt, index, "close_time")
                                }
                                size="small"
                                sx={{
                                  borderRadius: "8px",
                                  paddingY: "0px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                  fontSize: "12px",
                                }}
                              >
                                {generateTimeOptions(maxStartQhnum).map(
                                  (option, index) => (
                                    <MenuItem key={index} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              <div className="mt-6">
                <Divider />
              </div>
              <div className="mt-5">
                <p className="text-slate-900 text-sm font-semibold">
                  Set special hours
                </p>
                <p className="text-slate-500 text-xs mt-1">
                  Add special hours of operation for this location (i.e.
                  holidays)
                </p>
              </div>
              {/* <div className="mt-5">
                <Divider />
              </div> */}
              <div className="mt-5">
                {hoursOfOperationData.specialHours.map((day, index) => {
                  return (
                    <div key={index} className="mt-3">
                      <SpecialHoursOfOperationsInput
                        index={index}
                        day={day}
                        hoursOfOperationData={hoursOfOperationData}
                        setHoursOfOperationsData={setHoursOfOperationsData}
                        maxStartQhnum={maxStartQhnum}
                        onDelete={() => handleDeleteSpecialHours(index)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="mt-3">
                <div
                  className="w-full bg-violet-100 hover:bg-violet-200 text-violet-700 text-sm flex items-center justify-center space-x-1 py-2.5 rounded-lg cursor-pointer"
                  onClick={handleAddSpecialHours}
                >
                  <p>Add special hours</p>
                  <div>
                    <AddIcon style={{ fontSize: "20px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[300px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Save Changes
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default HoursOfOperationTableRow;
