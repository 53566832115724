import React from "react"
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function MinimizeButton(props) {
  return(
    <Tooltip title="Minimize Schedule">
      <IconButton
        style={{ color: props.minimizeMode ? "#6d28d9" : undefined }}
        className="group-hover:bg-gray-200 transition"
        onClick={props.toggleMinimizeMode}
      >
        <UnfoldLessIcon />
      </IconButton>
    </Tooltip>
  )
}

export default MinimizeButton
