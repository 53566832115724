import React from 'react'
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getInitials } from '../../utilities/helpers';
import { getCardColor2 } from '../../../../../helpers/helpers';

function ScheduleSelectedAlsoWorkingShift(props) {

  const shiftCardColor = getCardColor2(
    props.roleId?.id,
    props.roleNames,
    false
  )

  return (
    <div className="w-full bg-white border-b border-slate-200 py-3 flex flex-row">
      <div className={`w-12 h-12 ${shiftCardColor} text-white flex items-center justify-center rounded-full ${props.name === "undefined undefined" ? "text-sm" : "text-base"}`}>
        <p>{props.name === "undefined undefined" ? "Open" : getInitials(props.name)}</p>
      </div>
      <div className="flex-1 ml-5">
        <div>
          <p className="text-slate-900">
            {props.name === "undefined undefined" ? "Open Shift" : props.name}
          </p>
        </div>
        <div className="flex flex-row items-center mt-0.5">
          <p className="text-slate-500 text-sm">
            {props.shiftStartNumber}
          </p>
          <p className="text-slate-500 text-sm ml-1">
            {props.shiftStartLetters}
          </p>
          <div className="ml-3 text-slate-500">
            <ArrowForwardIcon style={{ fontSize: "20px" }} />
          </div>
          <p className="text-slate-500 text-sm ml-3">
            {props.shiftEndNumber}
          </p>
          <p className="text-slate-500 text-sm ml-1">
            {props.shiftEndLetters}
          </p>
        </div>
        <div>
          <p className="text-slate-500 text-sm">{props.station ? props.station : props.role ? props.role : props.roleType}</p>
        </div>
      </div>
    </div>
  )
}

export default ScheduleSelectedAlsoWorkingShift
