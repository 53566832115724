import React, { useState, useEffect, useRef } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WeeklyDatePicker from "./WeeklyDatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const date_options = { month: "short", day: "numeric" };

function WeekSelector(props) {
  const [showWeeklyDatePicker, setShowWeeklyDatePicker] = React.useState(false);
  const datePickerRef = useRef(null); // Create a ref

  function toggleWeeklyDatePicker() {
    setShowWeeklyDatePicker((prevState) => !prevState);
  }

  useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowWeeklyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showWeeklyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showWeeklyDatePicker]); // Re-run the effect when 'showWeeklyDatePicker' changes

  return (
    <div className="relative" ref={datePickerRef}>
      <div className="flex items-center text-center">
        <div
          className="w-9 h-9 rounded-l-lg border border-slate-200 text-slate-500 flex items-center justify-center hover:bg-slate-50 cursor-pointer"
          onClick={() => props.increment_week(props.start_date, -1)}
        >
          <ChevronLeftIcon />
        </div>
        <div
          className="w-9 h-9 rounded-r-lg border border-slate-200 text-slate-500 flex items-center justify-center hover:bg-slate-50 cursor-pointer"
          onClick={() => props.increment_week(props.start_date, 1)}
        >
          <ChevronRightIcon />
        </div>
        <div
          className={`ml-1 px-4 py-4 flex justify-center items-center rounded w-54 h-10 ${
            showWeeklyDatePicker
              ? "text-violet-700"
              : "text-slate-900 hover:text-slate-900"
          } bg-white cursor-pointer`}
          onClick={toggleWeeklyDatePicker}
        >
          {/* <div className="text-gray-700 lg:hidden">
            <CalendarMonthIcon />
          </div> */}
          <div className="mr-0.5 lg:mr-0 xl:text-sm">
            {props.start_date.toLocaleDateString("en-us", date_options) +
              " - " +
              props.end_date.toLocaleDateString("en-us", date_options)}{" "}
          </div>
          <div className="text-slate-500">
            {showWeeklyDatePicker ? (
              <ArrowDropUpIcon style={{ fontSize: "22px" }} />
            ) : (
              <ArrowDropDownIcon style={{ fontSize: "22px" }} />
            )}
          </div>
        </div>
      </div>
      {showWeeklyDatePicker && (
        <div className="absolute -bottom-88 left-10 bg-white z-20 flex items-center justify-center pl-4 pr-5 border border-gray-300 rounded shadow-xl overflow-hidden">
          <div className="mt-6">
            <WeeklyDatePicker
              set_week_start_date={props.set_week_start_date}
              start_date={props.start_date}
              businessWeekStartDayIndex={props.businessWeekStartDayIndex}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WeekSelector;
