import React from "react";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

export default function ContestInformation(props) {
  console.log(props.participatingStores);
  const storeNames = props.participatingStores
    .map((store) => store.store_name)
    .join(", ");

  return (
    <div>
      <div>
        <h3 className="text-2xl font-bold">{props.contestTitle}</h3>
      </div>
      <div className="flex items-center mt-4">
        <div className="min-w-fit">
          <h5 className="font-bold text-sm">Date Range</h5>
          <p>
            {props.startDate} - {props.endDate}
          </p>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginLeft: "20px", marginRight: "20px" }}
        />
        <div>
          <h5 className="font-bold text-sm">Type</h5>
          <p>{props.contestType}</p>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginLeft: "20px", marginRight: "20px" }}
        />
        <Tooltip title={storeNames}>
          <div>
            <h5 className="font-bold text-sm">Participating Stores</h5>
            <p>
              {props.participatingStores
                .slice(0, 3)
                .map((store) => store.store_name)
                .join(", ")}
              {props.participatingStores.length > 3 && <span>...</span>}
            </p>
          </div>
        </Tooltip>
      </div>
      <div className="mt-4">
        <h5 className="font-bold text-sm">Description</h5>
        <p>{props.description}</p>
      </div>
    </div>
  );
}
