import React, { useState } from "react";
import Divider from "@mui/material/Divider";

export default function IntroScreen(props) {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    // First Name validation
    if (!props.onboardingInfo.firstName || props.onboardingInfo.firstName.length < 2) {
      newErrors.firstName =
        "First Name is required and must be at least 2 characters long.";
    }

    // Last Name validation
    if (!props.onboardingInfo.lastName || props.onboardingInfo.lastName.length < 2) {
      newErrors.lastName =
        "Last Name is required and must be at least 2 characters long.";
    }

    // Contact Email validation
    if (!props.onboardingInfo.email) {
      newErrors.email = "Contact Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(props.onboardingInfo.email)) {
      newErrors.email = "Contact Email must be a valid email address.";
    }

    // Job Title validation
    if (!props.onboardingInfo.jobTitle || props.onboardingInfo.jobTitle.length < 2) {
      newErrors.jobTitle =
        "Job Title is required and must be at least 2 characters long.";
    }

    // Industry validation
    if (!props.onboardingInfo.industry) {
      newErrors.industry = "Please select an industry.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form before proceeding
    if (validateForm()) {
      props.increaseStep();
    }
  };

  return (
    <div>
      <h2 className="text-3xl text-gray-100 font-bold">
        Welcome to RightWork!
      </h2>
      <p className="text-gray-300 mt-5">
        Let's get you started. In just a few quick steps, you will be automating
        scheduling, saving money, and delighting your employees.
      </p>
      <div className="mt-10">
        <Divider style={{ backgroundColor: "gray" }} />
      </div>
      <div className="mt-8">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="w-1/2">
                <label className="block mb-1 text-gray-300">First Name *</label>
                <input
                  type="text"
                  className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                    errors.firstName ? "border-red-500" : "border-[#2f3847]"
                  }`}
                  value={props.onboardingInfo.firstName}
                  onChange={(e) =>
                    props.setOnboardingInfo({
                      ...props.onboardingInfo,
                      firstName: e.target.value,
                    })
                  }
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div className="w-1/2">
                <label className="block mb-1 text-gray-300">Last Name *</label>
                <input
                  type="text"
                  className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                    errors.lastName ? "border-red-500" : "border-[#2f3847]"
                  }`}
                  value={props.onboardingInfo.lastName}
                  onChange={(e) =>
                    props.setOnboardingInfo({
                      ...props.onboardingInfo,
                      lastName: e.target.value,
                    })
                  }
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div className="mt-4">
              <label className="block mb-1 text-gray-300">Contact Email *</label>
              <input
                type="email"
                className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                  errors.email ? "border-red-500" : "border-[#2f3847]"
                }`}
                value={props.onboardingInfo.email}
                onChange={(e) =>
                  props.setOnboardingInfo({
                    ...props.onboardingInfo,
                    email: e.target.value,
                  })
                }
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>
            <div className="mt-4">
              <label className="block mb-1 text-gray-300">Job Title *</label>
              <input
                type="text"
                className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                  errors.jobTitle ? "border-red-500" : "border-[#2f3847]"
                }`}
                value={props.onboardingInfo.jobTitle}
                onChange={(e) =>
                  props.setOnboardingInfo({
                    ...props.onboardingInfo,
                    jobTitle: e.target.value,
                  })
                }
              />
              {errors.jobTitle && (
                <p className="text-red-500 text-sm mt-1">{errors.jobTitle}</p>
              )}
            </div>
            <div className="mt-4">
              <label className="block mb-1 text-gray-300">Industry *</label>
              <select
                className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                  errors.industry ? "border-red-500" : "border-[#2f3847]"
                }`}
                value={props.onboardingInfo.industry}
                onChange={(e) =>
                  props.setOnboardingInfo({
                    ...props.onboardingInfo,
                    industry: e.target.value,
                  })
                }
              >
                <option value="">Select industry</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Retail">Retail</option>
                <option value="Hotel">Hotel</option>
                <option value="Construction">Construction</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Other">Other</option>
              </select>
              {errors.industry && (
                <p className="text-red-500 text-sm mt-1">{errors.industry}</p>
              )}
            </div>
            <div className="w-1/2 mt-8">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full"
                type="submit"
              >
                Next Step
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
