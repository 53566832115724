import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';


function PublishLaborModelWarningModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <div className="text-rose-800">
                <WarningIcon style={{ marginBottom: "1px" }} />
              </div>
              <h3 className="text-2xl text-slate-900">Are you sure?</h3>
            </div>
            <div>
              <p className="text-slate-500 text-sm mt-4">Are you sure you want to publish this labor model?</p>
              <p className="text-slate-500 text-sm mt-4">Publishing this labor model will adjust your labor budgets going forward using the current labor standards in the sandbox</p>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div className="px-8 py-2 bg-emerald-200 hover:bg-emerald-300 text-emerald-800 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.publish_labor_standard()
                    props.handleClose()
                  }}
                >
                  Publish New Labor Model
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default PublishLaborModelWarningModal
