import React from "react";
import Account from "../home/top_bar_components/Account";
import StoreName from "../home/top_bar_components/StoreName";
import AlarmIcon from "@mui/icons-material/Alarm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Notifications from "../home/top_bar_components/Notifications";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Tooltip from "@mui/material/Tooltip";

function TimeClockHeader(props) {


  const storeNames = props.storeNames?.filter(store => store.time_entries_enabled === true);

  const storeOptions = props.storeOptions?.filter(option => {
    return storeNames.some(store => store.id === option.store);
  });

  return (
    <div className="py-5 px-6 border-b border-slate-200 flex items-center justify-between">
      <div className="flex items-center space-x-8">
        {props.showSettings ? (
          <div
            className="flex items-center space-x-2 cursor-pointer text-slate-900 hover:text-slate-700"
            onClick={() => props.setShowSettings((prevState) => !prevState)}
          >
            <div className="w-9 h-9 rounded-full flex items-center justify-center transition duration-150">
              <ArrowBackIcon />
            </div>
            <h2 className="text-xl">{props.title}</h2>
          </div>
        ) : props.showTimeEntries ? (
          <div
            className="flex items-center space-x-2 cursor-pointer text-slate-900 hover:text-slate-700"
            onClick={() => props.handleGoBack()}
          >
            <div className="w-9 h-9 rounded-full flex items-center justify-center transition duration-150">
              <ArrowBackIcon />
            </div>
            <h2 className="text-xl">{props.title}</h2>
          </div>
        ) : (
          <div>
            <h2 className="text-2xl text-slate-900">{props.title}</h2>
          </div>
        )}
        <div className="flex space-x-2">
          <div className="w-48">
            <div
              className="w-full py-2 px-5 rounded-full bg-violet-700 hover:bg-violet-800 border border-violet-700 hover:border-violet-800 text-white text-base flex items-center justify-center space-x-2.5 cursor-pointer"
              onClick={() => props.setCurrentTab("timeClock")}
            >
              <p>Launch Clock</p>
              <div>
                <AlarmIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
              </div>
            </div>
          </div>
          {/* {!props.showSettings &&
            <Tooltip title="Pay period, time entry, and time clock settings">
              <div
                className="w-10 h-10 bg-slate-100 hover:bg-slate-200 flex items-center justify-center rounded-full text-slate-900 cursor-pointer"
                onClick={() => props.setShowSettings((prevState) => (!prevState))}
              >
                <SettingsOutlinedIcon />
              </div>
            </Tooltip>
          } */}
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-6">
          <StoreName
            storeOptions={storeOptions}
            homeStore={props.homeStore}
            storeNames={storeNames}
            change_home_store={props.change_home_store}
          />
        </div>
        <div className="mr-8">
          <Notifications homeStore={props.homeStore} />
        </div>
        <div className="cursor-pointer">
          <Account />
        </div>
      </div>
    </div>
  );
}

export default TimeClockHeader;
