import React from 'react'

function ButtonFilledCancel(props) {
  return (
    <div
      className="w-full py-3 rounded-full bg-rose-600 hover:bg-rose-700 text-white text-lg flex items-center justify-center cursor-pointer"
      onClick={props.onClick}
    >
      {props.text}
    </div>
  )
}

export default ButtonFilledCancel
