import React from 'react'
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Select from "@mui/material/Select";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from '../../shared/helpers/DrawerHeader';
import DrawerFooterContainerLarge from '../../shared/helpers/DrawerFooterContainerLarge';
import ButtonOutlined from '../../shared/helpers/ButtonOutlined';
import EmployeeDeleteWarningModal from './EmployeeDeleteWarningModal';

function EmployeeManageAccessTab(props) {
  const [selectedStmId, setSelectedStmId] = React.useState(null);
  const [selectedStoreId, setSelectedStoreId] = React.useState(null)
  const [selectedStoreName, setSelectedStoreName] = React.useState(null);

  const default_data = {
    email: "",
    first_name: "",
    id: null,
    last_name: "",
    permission_type: "",
    phone_number: null,
    store: null,
  };

  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [openEmployeeDeleteWarningModal, setOpenEmployeeDeleteWarningModal] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setModalData(default_data);
    setOpen(false);
  };

  const handleOpenEmployeeDeleteWarningModal = () => {
    setOpenEmployeeDeleteWarningModal(true);
  };

  const handleCloseEmployeeDeleteWarningModal = () => {
    setOpenEmployeeDeleteWarningModal(false);
  };

  // New handle functions for Select components
  const handleStoreChange = (event) => {
    const selectedStore = props.storeList.find(store => store.id === event.target.value);
    setModalData(prevData => ({
      ...prevData,
      store: selectedStore || null
    }));
  };

  const handlePermissionChange = (event) => {
    setModalData(prevData => ({
      ...prevData,
      permission_type: event.target.value
    }));
  };

  // Validation function
  const checkIfValid = () => {
    return modalData.store?.id && modalData.permission_type;
  };

  const permissionTypeLookup = {
    "company_admin": "Admin",
    "company_manager": "Manager",
    "company_timeclock": "Time Clock",
    "company_employee": "Employee"
  };

  console.log("STORE MODAL DATA", modalData)

  console.log("STM", props.selectedTeamMemberData)

   // Get array of store IDs where employee is already a member
   const existingStoreIds = React.useMemo(() => {
    return props.relatedTeamMembersData?.map(member => member.store?.id) || [];
  }, [props.relatedTeamMembersData]);

  // Filter store list to exclude stores where employee is already a member
  const availableStores = React.useMemo(() => {
    return props.storeList?.filter(store => !existingStoreIds.includes(store.id)) || [];
  }, [props.storeList, existingStoreIds]);

  // When editing, include the current store in the available stores
  const storeOptions = React.useMemo(() => {
    if (modalEdit && modalData?.store) {
      return [...availableStores, modalData.store];
    }
    return availableStores;
  }, [availableStores, modalEdit, modalData?.store]);

  return (
    <>
      <div className="w-full flex flex-col items-center">
        <div>
          <div className="flex items-center space-x-5">
            <h3 className="text-slate-900 text-2xl font-bold">Manage Access</h3>
            <div
              className="w-8 h-8 rounded-full bg-slate-100 flex items-center justify-center text-slate-500"
              onClick={() => {
                setModalEdit(false);
                handleOpen();
              }}
            >
              <Tooltip title={`Add ${props.selectedTeamMemberData?.first_name} to a store`}>
                <IconButton>
                  <AddIcon style={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-fit mt-5">
          <div className="bg-white p-5 rounded-xl w-fit">
            <div>
              <div className="flex items-center">
                <p className="text-slate-500 text-sm w-48">Stores</p>
                <p className="text-slate-500 text-sm w-44">Permission</p>
                <p className="text-slate-500 text-sm text-right flex-1">Action</p>
              </div>
              <div>
                {props.relatedTeamMembersData?.map((member, index) => (
                  <div key={index} className="flex items-center mt-4">
                    <p className="w-48">{member?.store?.store_name}</p>
                    <p className="w-44">{permissionTypeLookup[member?.permission_type]}</p>
                    <div className="flex items-center space-x-2 flex-1">
                      <div
                        className="w-8 h-8 rounded-full bg-slate-50 flex items-center justify-center text-slate-500"
                        onClick={() => {
                          setModalData(member);
                          setModalEdit(true);
                          handleOpen();
                        }}
                      >
                        <Tooltip title="Edit store access">
                          <IconButton>
                            <EditIcon style={{ fontSize: "16px" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <Tooltip title={`Delete ${props.selectedTeamMemberData?.first_name} from ${member?.store?.store_name}`}>
                        <div
                          className="w-8 h-8 rounded-full bg-slate-100 hover:bg-rose-800 flex items-center justify-center text-rose-800 hover:text-white cursor-pointer"
                          onClick={() => {
                            setSelectedStmId(member?.id);
                            setSelectedStoreId(member?.store?.id)
                            setSelectedStoreName(member?.store?.store_name);
                            handleOpenEmployeeDeleteWarningModal();
                          }}
                        >
                          <DeleteIcon style={{ fontSize: "16px" }} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={modalEdit ? "Edit Store Access" : "Add Store Access"}
              onClose={handleClose}
            />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-sm text-slate-500">Store*</p>
                <div className="mt-1">
                  <Select
                    fullWidth
                    displayEmpty
                    value={modalData?.store?.id || ""}
                    onChange={handleStoreChange}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "1px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      height: "45px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem disabled value="">
                      Select store
                    </MenuItem>
                    {storeOptions?.map((store, index) => (
                      <MenuItem key={index} value={store.id}>
                        {store.store_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="mt-7">
                <p className="text-sm text-slate-500">Permission Type*</p>
                <div className="mt-1">
                  <Select
                    fullWidth
                    displayEmpty
                    value={modalData?.permission_type || ""}
                    onChange={handlePermissionChange}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "1px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      height: "45px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem disabled value="">
                      Select Permission
                    </MenuItem>
                    <MenuItem value="company_admin">Admin</MenuItem>
                    <MenuItem value="company_manager">Manager</MenuItem>
                    <MenuItem value="company_employee">Employee</MenuItem>
                    {props.timeEntriesEnabled && (
                      <MenuItem value="company_timeclock">Time Clock</MenuItem>
                    )}
                  </Select>
                </div>
              </div>
            </div>
            <div className="h-[400px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  disabled={!checkIfValid()}
                  onClick={() => {
                    if (modalEdit) {
                      props.post_store_team_member_only_permission_type_and_store_id(
                        modalData?.id,
                        modalData?.store?.id,
                        modalData?.permission_type
                      );
                    } else {
                      props.clone_or_reactivate_store_team_member(
                        props.selectedTeamMemberData?.id,
                        modalData?.store?.id,
                        modalData?.permission_type
                      );
                    }
                    handleClose();
                  }}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Changes" : "Add Access"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={handleClose} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
      <EmployeeDeleteWarningModal
        open={openEmployeeDeleteWarningModal}
        handleClose={handleCloseEmployeeDeleteWarningModal}
        firstName={props.selectedTeamMemberData?.first_name}
        lastName={props.selectedTeamMemberData?.last_name}
        homeStore={props.homeStore}
        selectedStoreId={selectedStoreId}
        selectedStoreName={selectedStoreName}
        storeNames={props.storeNames}
        stm_id={props.selectedTeamMemberData?.id}
        selectedStmId={selectedStmId}
        archive_store_team_member={props.archive_store_team_member}
        setShowUserProfile={props.setShowUserProfile}
      />
    </>
  );
}

export default EmployeeManageAccessTab;
