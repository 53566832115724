import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

function CopilotButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [dots, setDots] = useState("");

  // Animate the dots
  useEffect(() => {
    if (props.isCopilotOn) {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length === 3 ? "" : prev + "."));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [props.isCopilotOn]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const modifiedCopilotShifts = props.copilotShifts?.map((shift) => ({
    ...shift,
    action: "add",
  }));

  const modifiedCopilotDeleteShifts = props.copilotDeleteShifts?.map((shift) => ({
    ...shift,
    action: "delete",
  }));

  const pulseKeyframes = `
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

  // Add the keyframes to the document's style
  if (typeof document !== "undefined") {
    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(pulseKeyframes, styleSheet.cssRules.length);
  }

  return (
    <div>
      <div className="relative">
        {props.week_ended || !props.autosched_available ? (
          <Tooltip
            title={
              props.week_ended
                ? "Copilot not available for past weeks"
                : "Copilot not available"
            }
          >
            <div className="text-[#bdbdbd] w-10 h-10 flex items-center justify-center">
              <AutoAwesomeIcon />
            </div>
          </Tooltip>
        ) : (
          <div className="relative group" ref={dropdownRef}>
            <div className="flex items-center space-x-1">
              <Tooltip
                title={`${
                  !isOpen
                    ? props.isCopilotOn
                      ? "Copilot Enabled"
                      : "Open Copilot"
                    : ""
                }`}
              >
                <IconButton
                  onClick={toggleDropdown}
                  style={{
                    color: props.isCopilotOn ? "#6d28d9" : undefined,
                    animation:
                      props.isCopilotOn && props.isCopilotLoading
                        ? "pulse 2s infinite"
                        : "none",
                  }}
                  className="hover:bg-gray-200 transition"
                  disabled={props.week_ended || !props.autosched_available}
                >
                  <AutoAwesomeIcon />
                </IconButton>
              </Tooltip>
              <div
                className="w-[170px] h-6 flex items-center"
                onClick={toggleDropdown}
              >
                {props.isCopilotOn && (
                  <div>
                    {props.isCopilotLoading ? (
                      <p className="text-xs text-violet-700 cursor-pointer">
                        Updating recommendations{dots}
                      </p>
                    ) : (
                      <div>
                        {props.copilotShifts?.length > 0 &&
                          <p className="text-xs text-violet-700 cursor-pointer">
                            +{props.copilotShifts?.length} shifts recommended
                          </p>
                        }
                        {props.copilotDeleteShifts?.length > 0 &&
                          <p className="text-xs text-violet-700 cursor-pointer">
                            -{props.copilotDeleteShifts?.length} shifts recommended
                          </p>
                        }
                        {(props.copilotShifts?.length === 0 && props.copilotDeleteShifts?.length === 0) &&
                          <p className="text-xs text-violet-700 cursor-pointer">
                            No recommendations
                          </p>
                        }
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {isOpen && (
              <div className="absolute left-0 w-[275px] mt-2 bg-white border border-slate-200 shadow-xl rounded-lg pt-5 z-40">
                <div
                  className="absolute right-2 top-2 text-gray-500 cursor-pointer"
                  onClick={toggleDropdown}
                >
                  <CloseIcon style={{ fontSize: "18px" }} />
                </div>
                {props.isCopilotOn ? (
                  <div>
                    <div className="px-5">
                      <h3 className="text-slate-900 text-sm font-bold">
                        Showing Recommendations{dots}
                      </h3>
                    </div>
                    <div className="px-5 text-xs text-slate-500 mt-2">
                      <p>
                        To accept a new shift, press and hold on the
                        shift, to remove a shift, click the X
                      </p>
                    </div>
                    <div className="w-full flex justify-center text-sm font-bold text-slate-500 mt-2">
                      <p>OR</p>
                    </div>
                    <div className="px-5 pb-5">
                      <div
                        className="bg-emerald-600 hover:bg-emerald-700 py-2 rounded-lg flex items-center justify-center mt-3 cursor-pointer"
                        onClick={() => {
                          props.setIsAddingAllCopilotShiftsLoading(true);
                          props.addMultipleShifts([...modifiedCopilotShifts, ...modifiedCopilotDeleteShifts]);
                        }}
                      >
                        {props.isAddingAllCopilotShiftsLoading ? (
                          <div className="text-white font-semibold text-xs flex items-center space-x-2">
                            <p>Accepting Recommendations</p>
                            <div className="mt-1">
                              <CircularProgress
                                size={12}
                                sx={{
                                  color: "white",
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <p className="text-white font-semibold text-xs">
                            Accept All Recommendations
                          </p>
                        )}
                      </div>
                      <div
                        className="bg-rose-600 hover:bg-rose-700 py-2 rounded-lg flex items-center justify-center mt-3 cursor-pointer"
                        onClick={() => props.setIsCopilotOn(false)}
                      >
                        <p className="text-white font-semibold text-xs">
                          Hide Recommendations
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="px-5">
                      <div className="px-3 py-1 bg-slate-900 text-white text-xxs rounded-full w-fit">
                        NEW
                      </div>
                    </div>
                    <h3 className="text-slate-900 text-sm px-5 font-bold mt-3">
                      AI Copilot
                    </h3>
                    <div className="px-5 text-xs text-slate-500 mt-2">
                      <p>
                        The copilot recommends shifts that fill your demand as
                        efficiently as possible
                      </p>
                    </div>
                    <div className="px-5 text-xs text-slate-500 mt-2">
                      <p>
                        Accounts for availability, overtime, compliance, and
                        target hours
                      </p>
                    </div>
                    <div className="py-3 px-5 border-t border-slate-200 w-full rounded-b-lg mt-4">
                      <div className="w-full">
                        <div
                          className="bg-violet-700 hover:bg-violet-800 py-2 rounded-lg flex items-center justify-center cursor-pointer"
                          onClick={() => props.setIsCopilotOn(true)}
                        >
                          <p className="text-white font-semibold text-xs">
                            Show Recommendations
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CopilotButton;
