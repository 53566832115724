import React from 'react'

function ButtonDelete(props) {
  return (
    <div
    className="w-full py-3 rounded-full bg-rose-100 hover:bg-rose-200 text-rose-800 text-lg flex items-center justify-center cursor-pointer"
      onClick={props.onClick}
    >
      {props.text}
    </div>
  )
}

export default ButtonDelete
