import React from 'react'
import CloseIcon from "@mui/icons-material/Close";

function DrawerHeader(props) {
  return (
    <div className="flex items-center justify-between px-6 py-8">
      <h2 className="text-slate-900 text-2xl">{props.title}</h2>
      <div className="text-slate-500 cursor-pointer" onClick={props.onClose}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default DrawerHeader
