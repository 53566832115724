import React from 'react'
import StoreName from './StoreName';
import Notifications from './Notifications';
import Account from './Account';
import DateSelector from '../../plan/daily_top_bar_components/DateSelector';

function TopBarViewLogs(props) {

  return (
    <div className="w-full py-5 px-6 border-b border-slate-200">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl text-slate-900">View Logbook</h2>
        <div className="flex items-center">
          <div className="mr-8">
            <DateSelector
              date={props.date}
              increment_day={props.increment_day}
            />
          </div>
          <div className="mr-6">
            <StoreName
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div>
          <div className="mr-8">
            <Notifications
              homeStore={props.homeStore}
            />
            </div>
          <div className="h-10 border-gray-500 flex items-center justify-center cursor-pointer">
            <Account />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBarViewLogs
