import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";
import Pill from "../../shared/helpers/Pill";
import { formatDateReadable, formatDateShift } from "../../utilities/helpers";
import EmployeeShiftCardUpdatedStatic from "../../shared/shiftswaps/EmployeeShiftCardUpdatedStatic";
import ShiftStatusBar from "./ShiftStatusBar";

function PendingTableEmployeeRowShiftModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  function handlePickupCancel() {
    props.rescind_pickup_shift(props.shiftInfo.id);
    props.toggleModal();
  }

  function handleDropCancel() {
    props.rescind_drop_shift(props.shiftInfo.id);
    // props.toggleModal();
  }

  // props.shiftInfo.pending_pickup_count === null

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-4 top-4 text-gray-500 cursor-pointer"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center">
          <h1 className="text-xl font-bold w-full">
            Selected {props.shiftInfo.requestType}
          </h1>
          <div className="w-full mt-6">
            <EmployeeShiftCardUpdatedStatic
              date={formatDateShift(props.shiftInfo.date)}
              shiftStartNumber={props.shiftInfo.shift_start_formatted[0]}
              shiftStartLetters={props.shiftInfo.shift_start_formatted[1]}
              shiftEndNumber={props.shiftInfo.shift_end_formatted[0]}
              shiftEndLetters={props.shiftInfo.shift_end_formatted[1]}
              break={props.shiftInfo.break_formatted}
              role={props.shiftInfo.role}
              roleType={props.shiftInfo.roleType}
              location={props.shiftInfo.location}
              status={props.cardStatus}
            />
          </div>
          {props.shiftInfo.requestType === "Add to Pool Request" ? (
            <div className="w-full mt-4">
              <h2 className="text-slate-900 text-base">Status</h2>
              <ShiftStatusBar
                cardStatus={props.cardStatus}
                pendingPickupCount={props.shiftInfo.pending_pickup_count}
              />
            </div>
          ) : (
            <div className="w-full mt-3 pb-3 flex flex-row items-center justify-end">
              <Pill status={props.cardStatus} />
            </div>
          )}
          {props.shiftInfo.requestType === "Add to Pool Request" ? (
            <div className="w-full">
              {props.cardStatus === "pending" &&
              props.shiftInfo.requestType === "Add to Pool Request" ? (
                <div className="w-full">
                  <button
                    onClick={() => handleDropCancel()}
                    className="w-full border-2 border-blue-900 hover:bg-gray-100 rounded-full py-4 flex items-center justify-center mt-5"
                  >
                    <p className="text-blue-900 font-semibold text-base">
                      Cancel Request
                    </p>
                  </button>
                </div>
              ) : (
                <div className="w-full bg-gray-200 rounded-full py-4 flex items-center justify-center mt-5">
                  <p className="text-gray-400 font-semibold text-base">
                    Cancel Request
                  </p>
                </div>
              )}
            </div>
          ) : props.shiftInfo.requestType === "Pickup Request" ? (
            <div className="w-full">
              {props.cardStatus === "pending" ? (
                <button
                  onClick={() => handlePickupCancel()}
                  className="w-full border-2 border-blue-900 hover:bg-gray-100 rounded-full py-4 flex items-center justify-center mt-5"
                >
                  <p className="text-blue-900 font-semibold text-base">
                    Cancel Request
                  </p>
                </button>
              ) : (
                <div className="w-full bg-gray-200 rounded-full py-4 flex items-center justify-center mt-5">
                  <p className="text-gray-400 font-semibold text-base">
                    Cancel Request
                  </p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </Box>
    </Modal>
  );
}

export default PendingTableEmployeeRowShiftModal;
