import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import Rating from "@mui/material/Rating";
import { getCardColor2 } from "../../../../../helpers/helpers";
import PersonCardModal from "../../../home/schedule_components/PersonCardModal";
import PersonAvatar from "../../../home/schedule_components/PersonAvatar";

function EmployeeProfileAttributesTab(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setMainRoleId(props.selectedTeamMemberData?.primary_role_id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [mainRoleId, setMainRoleId] = React.useState(
    props.selectedTeamMemberData?.primary_role_id
  );

  const handleMainRoleSelection = (event) => {
    setMainRoleId(event.target.value);
  };

  const name =
    props.selectedTeamMemberData?.first_name +
    " " +
    props.selectedTeamMemberData?.last_name;

  const returnAvatar = () => {
    return (
      <PersonAvatar
        name={name}
        bgColor={getCardColor2(mainRoleId, props.roleNames, false)}
      />
    );
  };

  const p = {
    ...props.selectedTeamMemberData,
    available_role_info: props.selectedTeamMemberData?.roles_and_role_types,
  };

  return (
    <>
      <div className="w-full flex flex-col items-center">
        <div>
          <div className="flex items-center space-x-5">
            <h3 className="text-slate-900 text-2xl font-bold">Attributes</h3>
            <div
              className="w-8 h-8 rounded-full bg-slate-100 flex items-center justify-center text-slate-500"
              onClick={handleOpen}
            >
              <Tooltip title="Edit employee attributes">
                <IconButton>
                  <EditIcon style={{ fontSize: "16px" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-fit">
          <div className="mt-5 flex space-x-5">
            <div>
              <div className="bg-white p-5 rounded-xl w-fit">
                <div>
                  <div className="flex items-center">
                    <p className="text-slate-500 text-sm w-48">Capable Roles</p>
                    <p className="text-slate-500 text-sm w-44">Skill Level</p>
                    <p className="text-slate-500 text-sm text-right">
                      Main Role
                    </p>
                  </div>
                  {props.selectedTeamMemberData.roles_and_role_types?.length >
                  0 ? (
                    <div className="mt-3">
                      {props.selectedTeamMemberData?.roles_and_role_types
                        ?.sort((a, b) => {
                          if (
                            a.scheduled_role_obj?.role_type_id ===
                            b.scheduled_role_obj?.role_type_id
                          ) {
                            return a.scheduled_role_obj?.role_name.localeCompare(
                              b.scheduled_role_obj?.role_name
                            );
                          }
                          return (
                            a.scheduled_role_obj?.role_type_id -
                            b.scheduled_role_obj?.role_type_id
                          );
                        })
                        .map((role, index) => (
                          <div key={index} className="flex items-center mt-1">
                            <div className="flex items-center space-x-2 w-48">
                              <div
                                className={`w-3 h-3 rounded-full ${getCardColor2(
                                  role.scheduled_role,
                                  props.roleNames,
                                  false
                                )}`}
                              ></div>
                              <p className="text-slate-900">
                                {role?.scheduled_role_obj?.role_name}
                              </p>
                            </div>
                            <div className="w-44 mt-2">
                              <Rating
                                name="read-only"
                                value={role?.proficiency_rating}
                                readOnly
                              />
                            </div>
                            {role?.main_role_ind === 1 ? (
                              <div className="flex-1 flex justify-end">
                                <div className="w-6 h-6 rounded-full bg-emerald-600 flex items-center justify-center text-white">
                                  <CheckIcon style={{ fontSize: "16px" }} />
                                </div>
                              </div>
                            ) : (
                              <div className="flex-1 flex justify-end">
                                <div className="w-6 h-6 rounded-full border border-slate-200 bg-slate-200"></div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="mt-3">
                      <div className="flex items-center mt-2">
                        <p className="w-48">None</p>
                        <p className="w-44">N/A</p>
                        <p>N/A</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white p-5 rounded-xl">
                <div className="flex items-center space-x-20">
                  <div className="flex flex-col items-center">
                    <p className="text-slate-500 text-sm">Target Hours</p>
                    {props.selectedTeamMemberData.target_weekly_hours ? (
                      <p className="text-5xl text-slate-900 mt-3">
                        {props.selectedTeamMemberData.target_weekly_hours}
                      </p>
                    ) : (
                      <p className="text-xl text-slate-900 mt-3">None</p>
                    )}
                  </div>
                  {/* <div>
                    <p className="text-slate-500 text-sm text-right">Min Target Hours</p>
                    <p className="text-xl text-slate-900 mt-2 text-right">{props.selectedTeamMemberData.min_weekly_hours ? props.selectedTeamMemberData.min_weekly_hours : "None"}</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PersonCardModal
        handleClose={handleClose}
        open={open}
        p={p}
        role_names={props.roleNames}
        updatePersonInfo={props.updatePersonInfo}
        handleMainRoleSelection={handleMainRoleSelection}
        mainRoleId={mainRoleId}
        setMainRoleId={setMainRoleId}
        returnAvatar={returnAvatar}
      />
    </>
  );
}

export default EmployeeProfileAttributesTab;
