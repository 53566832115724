import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ShiftDropModal from "../../shared/shiftswaps/ShiftDropModal";
import ShiftDropDrawer from "../../shared/shiftswaps/ShiftDropDrawer";
import { getCardColor2 } from "../../../../../helpers/helpers";

function ScheduleSelectedShift(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleAddToPoolPress(note) {
    props.drop_shift(props.shift.id, null, note);
  }

  const shiftInfo = props.shift;

  let badgeText = "";
  if (shiftInfo?.badge === "is_pending_drop_by_user") {
    badgeText = "Drop Requested";
  } else if (
    shiftInfo?.badge === "is_trade_by_user_pending_employee_response"
  ) {
    badgeText = "Offered Trade";
  } else if (shiftInfo?.badge === "is_trade_by_user_pending_manager_response") {
    badgeText = "Offered Trade";
  } else if (shiftInfo?.badge === "is_trade_to_user_pending_self_response") {
    badgeText = "";
  } else if (shiftInfo?.badge === "is_trade_to_user_pending_manager_response") {
    badgeText = "";
  } else if (shiftInfo?.badge === "is_pending_pickup_by_user") {
    badgeText = "";
  } else {
    badgeText = "";
  }

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const shiftCardColor = getCardColor2(
    props.roleId?.id,
    props.roleNames,
    false
  );

  return (
    <>
      <div
        className="w-full rounded-2xl bg-violet-100 hover:bg-violet-200 cursor-pointer px-5 py-5 relative mt-5"
        onClick={toggleDrawer(true)}
      >
        <p className="text-slate-500">Your Shift</p>
        <div className="flex flex-row items-center mt-1">
          <p className="text-slate-900 text-[28px] font-bold">
            {props.shiftStartNumber}
          </p>
          <p className="text-slate-600 text-lg ml-1">
            {props.shiftStartLetters}
          </p>
          <div className="ml-3 text-slate-600">
            <ArrowForwardIcon color="#475569" size={22} />
          </div>
          <p className="text-slate-900 text-[28px] font-bold ml-3">
            {props.shiftEndNumber}
          </p>
          <p className="text-slate-600 text-lg ml-1">{props.shiftEndLetters}</p>
        </div>
        <div className="mt-1">
          <p className="text-slate-600">
            {props.station
              ? props.station
              : props.role
              ? props.role
              : props.roleType}{" "}
            @ {props.location}
          </p>
        </div>
        {badgeText && (
          <div className="bg-amber-50 px-2 py-1 rounded-lg absolute bottom-2 right-2">
            <p className="text-amber-800 text-xs">{badgeText}</p>
          </div>
        )}
      </div>
      <ShiftDropModal
        open={open}
        onClose={handleClose}
        shiftInfo={shiftInfo}
        badgeText={badgeText}
        date={props.date}
        isDateInPast={props.isDateInPast}
        shiftStartNumber={props.shiftStartNumber}
        shiftStartLetters={props.shiftStartLetters}
        shiftEndNumber={props.shiftEndNumber}
        shiftEndLetters={props.shiftEndLetters}
        role={props.role}
        location={props.location}
        handleAddToPoolPress={handleAddToPoolPress}
        drop_shift={props.drop_shift}
        trade_shift={props.trade_shift}
        rescind_drop_shift={props.rescind_drop_shift}
        rescind_trade_shift={props.rescind_trade_shift}
        notMyUpcomingShifts={props.notMyUpcomingShifts}
      />
      <ShiftDropDrawer
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        setDrawerOpen={setDrawerOpen}
        shiftInfo={shiftInfo}
        badgeText={badgeText}
        date={props.date}
        firstName={props.firstName}
        lastName={props.lastName}
        isDateInPast={props.isDateInPast}
        shiftStartNumber={props.shiftStartNumber}
        shiftStartLetters={props.shiftStartLetters}
        shiftEndNumber={props.shiftEndNumber}
        shiftEndLetters={props.shiftEndLetters}
        role={props.role}
        station={props.station}
        roleType={props.roleType}
        break={props.break}
        location={props.location}
        handleAddToPoolPress={handleAddToPoolPress}
        drop_shift={props.drop_shift}
        trade_shift={props.trade_shift}
        rescind_drop_shift={props.rescind_drop_shift}
        rescind_trade_shift={props.rescind_trade_shift}
        notMyUpcomingShifts={props.notMyUpcomingShifts}
        shiftCardColor={shiftCardColor}
        roleNames={props.roleNames}
        note={props.note}
      />
    </>
  );
}

export default ScheduleSelectedShift;
