import React, { useState, useEffect } from "react";
import EventIcon from "@mui/icons-material/Event";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from "@mui/icons-material/Add";
import { get_t_str, text_to_num } from "../../../../helpers/helpers";
import DrawerHeader from "../../requests/shared/helpers/DrawerHeader";
import { formatDateReadable } from "../../requests/utilities/helpers";
import { generateTimeOptions } from "../../../../helpers/helpers";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function WeatherEventsCard(props) {
  // Shows modal when set to true
  const [open, setOpen] = React.useState(false);

  const [eventTitle, setEventTitle] = React.useState();
  const [eventDescription, setEventDescription] = React.useState();
  const [eventDate, setEventDate] = React.useState(props.date_str);
  const [eventStartTime, setEventStartTime] = React.useState();
  const [eventEndTime, setEventEndTime] = React.useState();

  const [buttonPressed, setButtonPressed] = React.useState(false);
  const [isEventsDisplayed, setIsEventsDisplayed] = React.useState(true);


  function toggleEventsDisplayed() {
    setIsEventsDisplayed((prevState) => !prevState);
  }

  const [showNoteInputs, setShowNoteInputs] = React.useState(false)

  function toggleShowNoteInputs() {
    setShowNoteInputs(prevState => !prevState);
  }

  // Modal style object
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  let weatherEmoji = "";
  let degrees = "";

  if (props.daily_weather?.length > 0) {
    let text = props.daily_weather?.[0].text;
    degrees = props.daily_weather?.[0].maxtemp;

    if (text === "Sunny") {
      weatherEmoji = "☀️";
    } else if (text === "Partly cloudy") {
      weatherEmoji = "🌤";
    } else if (text === "Cloudy") {
      weatherEmoji = "☁️";
    } else if (text === "Rain") {
      weatherEmoji = "🌧";
    } else if (text === "Snow") {
      weatherEmoji = "❄️";
    } else if (text === "Thunderstorms") {
      weatherEmoji = "🌩";
    } else {
      weatherEmoji = "";
    }
  }

  function get_weather_path(icon_string) {
    return process.env.PUBLIC_URL + "/images/weather/" + icon_string.slice(-7);
  }

  // Function for opening the modal
  const handleOpen = () => {
    setOpen(true);
  };

  const handleStart = () => {
    const data = {
      title: eventTitle,
      description: eventDescription,
      date: eventDate,
      start_time: eventStartTime ? text_to_num(eventStartTime) : eventStartTime,
      end_time: eventEndTime ? text_to_num(eventEndTime) : eventEndTime,
    };
    props.saveEvent(data);
    setButtonPressed(true);
  };

  // Function for closing the modal
  const handleClose = () => {
    setButtonPressed(false);
    props.setOpenNotes(false);
    setEventTitle();
    setEventDescription();
    setEventStartTime();
    setEventEndTime();
    props.setSaveEventFinished(false);
    setIsEventsDisplayed(true);
    setShowNoteInputs(false)
  };

  useEffect(() => {
    setEventDate(props.date_str);
  }, [props.date_str]);

  useEffect(() => {
    if (props.saveEventFinished) {
      setButtonPressed(false)
      setEventTitle();
      setEventDescription();
      setEventStartTime();
      setEventEndTime();
      props.setSaveEventFinished(false);
      setIsEventsDisplayed(true);
      setShowNoteInputs(false)
    }
  }, [props.saveEventFinished]);

  const checkIfValid = () => {
    if (eventTitle) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {props.element === "weather" ? (
        (!props.minimizeMode || props.view === "daily") &&
          <div>
            {props.daily_weather?.length > 0 ? (
              <div className="flex items-center -ml-0.5">
                <img
                  src={get_weather_path(props.daily_weather?.[0].icon)}
                  className="d-inline-block align-top"
                  alt={props.daily_weather?.[0].text}
                  style={{ height: "18px" }}
                />
                <span className="text-[12px] ml-1 text-slate-500">
                  {degrees}°F
                </span>
              </div>
            ) : (
              <div></div>
            )}
          </div>
      ) : props.element === "notes" ? (
        <div>
          <div className="text-slate-500">
            {props.daily_events?.length > 0 ? (
              <div className="-mr-2">
                <Tooltip
                  title={`You have ${props.daily_events?.length} ${props.daily_events?.length === 1 ? "note" : "notes"} today. Click to see`}
                >
                  <IconButton
                    className="text-slate-500 relative"
                    style={{ borderRadius: "10px" }}
                    onClick={() => props.handleOpenNotes()}
                  >
                    <div className="w-2.5 h-2.5 bg-white rounded-full flex items-center justify-center absolute top-1 right-1">
                      <div className="w-2 h-2 bg-rose-600 rounded-full flex items-center justify-center"></div>
                    </div>
                    <NoteAltOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div className="-mr-2">
                <Tooltip title="Add Note">
                  <IconButton
                    className="text-slate-500"
                    style={{ borderRadius: "10px" }}
                    onClick={() => props.handleOpenNotes()}
                  >
                    <NoteAltOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-between">
          {props.daily_weather?.length > 0 ? (
            <div className="flex items-center">
              <img
                src={get_weather_path(props.daily_weather?.[0].icon)}
                className="d-inline-block align-top"
                alt={props.daily_weather[0].text}
                style={{ height: "24px" }}
              />
              <span className="text-xs ml-1 text-gray-700">{degrees}°F</span>
            </div>
          ) : (
            <div></div>
          )}
          <div className="text-slate-500">
            {props.daily_events?.length > 0 ? (
              <div className="-mr-2">
                <Tooltip
                  title={
                    <div className="p-3 ">
                      <div className="font-bold text-lg">Notes</div>
                      <ul className="tooltip-list mt-2">
                        {props.daily_events.map((e, i) => (
                          <li key={i}>{"- " + e.title}</li>
                        ))}
                      </ul>
                    </div>
                  }
                  arrow
                >
                  <IconButton onClick={() => props.handleOpenNotes()}>
                    <EventIcon color="primary" style={{ fontSize: "18px" }} />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div className="-mr-2">
                <Tooltip title="Add Note">
                  <IconButton onClick={() => props.handleOpenNotes()}>
                    <CalendarTodayIcon
                      color="default"
                      style={{ fontSize: "17px" }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
      <Drawer
        anchor="right"
        open={props.openNotes}
        onClose={handleClose}
      >
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader title={"Notes on " + formatDateReadable(eventDate)} onClose={handleClose} />
            <div className="w-full px-6">
              {props.daily_events?.length === 0 ?
                <div>
                  <p className="text-slate-900">No note added</p>
                  <p className="text-slate-500 text-sm mt-1">Click the button below to add a note</p>
                </div> :
                <div>
                  {props.daily_events.map((event, i) => (
                    <div className="flex pb-5 border-b border-slate-200 mt-5">
                      <div className="flex-1">
                        {event.start_time &&
                          <p className="text-slate-500 text-xs">{get_t_str(event.start_time)} {event.end_time && `- ${get_t_str(event.end_time)}`}</p>
                        }
                        <h4 className="text-slate-900 text-base">{event.title}</h4>
                        <p className="text-slate-500 text-sm mt-0.5">{event.description}</p>
                        {/* <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">
                          {event.title}{" "}
                          {event.start_time && event.end_time && (
                            <span className="font-base">
                              ({event.start_time} - {event.end_time})
                            </span>
                          )}
                        </h3>
                        <p className="mt-1.5 truncate text-sm text-gray-500">
                          {event.description}
                        </p> */}
                      </div>
                      <div
                        className="text-slate-500 cursor-pointer w-8 h-8 bg-slate-50 hover:bg-slate-100 flex items-center justify-center rounded-md"
                        onClick={() => props.deleteEvent(event.id)}
                      >
                        <div>
                          <DeleteOutlinedIcon style={{ fontSize: "20px" }} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
              {!showNoteInputs ?
                <div
                  className="w-full bg-violet-100 hover:bg-violet-200 text-violet-700 text-sm flex items-center justify-center space-x-1 py-2.5 mt-5 rounded-lg cursor-pointer"
                  onClick={toggleShowNoteInputs}
                >
                  <p>Add note</p>
                  <div>
                    <AddIcon style={{ fontSize: "20px" }} />
                  </div>
                </div> :
                <div className="w-full mt-5">
                  <div>
                    <div>
                      <p className="text-sm text-slate-500">Title*</p>
                      <div className="mt-1">
                        <TextField
                          placeholder="e.g. Private party"
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={eventTitle}
                          onChange={(e) => setEventTitle(e.target.value)}
                          InputProps={{
                            sx: {
                              backgroundColor: 'white',
                              borderRadius: "8px",
                              padding: "5px",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="text-sm text-slate-500">Description</p>
                      <div className="mt-1">
                        <TextField
                          placeholder="Add a description (optional)"
                          multiline
                          rows={3}
                          className="w-full"
                          value={eventDescription}
                          onChange={(e) => setEventDescription(e.target.value)}
                          InputProps={{
                            sx: {
                              borderRadius: "8px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-5">
                      {/* <div className="w-[35%]">
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="date"
                          value={eventDate}
                          onChange={(e) => setEventDate(e.target.value)}
                        />
                      </div> */}
                      <div className="w-[42%]">
                        <p className="text-sm text-slate-500">Start time</p>
                        <div className="mt-1">
                          {/* <TextField
                            placeholder="8:00pm"
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={eventStartTime}
                            onChange={(e) => setEventStartTime(e.target.value)}
                            InputProps={{
                              sx: {
                                backgroundColor: 'white',
                                borderRadius: "8px",
                                padding: "5px",
                                height: "50px"
                              },
                            }}
                          /> */}
                          <FormControl fullWidth size="small">
                            <Select
                              value={eventStartTime}
                              name="start_time"
                              onChange={(e) => setEventStartTime(e.target.value)}
                              sx= {{
                                backgroundColor: 'white',
                                borderRadius: "8px",
                                padding: "5px",
                                height: "45px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6d28d9",
                                },
                              }}
                            >
                              {generateTimeOptions(0).map((time) => (
                                <MenuItem key={time} value={time}>
                                  {time}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="w-[16%] text-slate-500 flex items-center justify-center mt-6">
                        <ArrowForwardIcon />
                      </div>
                      <div className="w-[42%]">
                        <p className="text-sm text-slate-500">End time</p>
                        <div className="mt-1">
                          {/* <TextField
                            placeholder="10:00pm"
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={eventEndTime}
                            onChange={(e) => setEventEndTime(e.target.value)}
                            InputProps={{
                              sx: {
                                backgroundColor: 'white',
                                borderRadius: "8px",
                                padding: "5px",
                                height: "50px"
                              },
                            }}
                          /> */}
                          <FormControl fullWidth size="small">
                            <Select
                              value={eventEndTime}
                              name="end_time"
                              onChange={(e) => setEventEndTime(e.target.value)}
                              sx= {{
                                backgroundColor: 'white',
                                borderRadius: "8px",
                                padding: "5px",
                                height: "45px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6d28d9",
                                },
                              }}
                            >
                              {generateTimeOptions(0).map((time) => (
                                <MenuItem key={time} value={time}>
                                  {time}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mt-5">
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        loading={buttonPressed}
                        disabled={!checkIfValid()}
                        loadingPosition="end"
                        endIcon={<SaveIcon />}
                        onClick={() => handleStart()}
                        sx={{
                          width: "100%",
                          textTransform: "none",
                          borderRadius: "8px",
                          color: "#6d28d9",
                          backgroundColor: "#ede9fe",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: "#ddd6fe",
                            boxShadow: "none"
                          },
                          fontSize: "16px",
                        }}
                      >
                        Save Note
                      </LoadingButton>
                    </div>
                  </div>

                </div>
              }
            </div>
            <div className="h-[80px]"></div>
          </div>
        </div>
        {/* <Box sx={style}> */}
          {/* <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div> */}
          {/* {isEventsDisplayed && props.daily_events?.length > 0 ? (
            <div>
              <h2 className="text-xl font-bold">Notes on {eventDate}</h2>
              <ul role="list" className="divide-y divide-gray-100 mt-3">
                {props.daily_events.map((event, i) => (
                  <li key={i} className="py-4">
                    <div className="flex items-center justify-between">
                      <div className="w-[50%]">
                        <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">
                          {event.title}{" "}
                          {event.start_time && event.end_time && (
                            <span className="font-base">
                              ({event.start_time} - {event.end_time})
                            </span>
                          )}
                        </h3>
                        <p className="mt-1.5 truncate text-sm text-gray-500">
                          {event.description}
                        </p>
                      </div>
                      <button
                        className="text-gray-500"
                        onClick={() => props.deleteEvent(event.id)}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="w-full flex justify-end mt-5">
                <Button
                  variant="contained"
                  onClick={toggleEventsDisplayed}
                  endIcon={<AddIcon />}
                  sx={{ textTransform: "none", borderRadius: "8px" }}
                >
                  Add Note
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <h2 className="text-xl font-bold">Add Note</h2>
              <div className="mt-4">
                <TextField
                  label="Note Title"
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                />
              </div>
              <div className="mt-5">
                <TextField
                  multiline
                  rows={2}
                  label="Description (optional)"
                  className="w-full"
                  value={eventDescription}
                  onChange={(e) => setEventDescription(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between mt-5">
                <div className="w-[35%]">
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    type="date"
                    value={eventDate}
                    onChange={(e) => setEventDate(e.target.value)}
                  />
                </div>
                <div className="w-[28%]">
                  <TextField
                    label="Start Time"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={eventStartTime}
                    onChange={(e) => setEventStartTime(e.target.value)}
                  />
                </div>
                <div>to</div>
                <div className="w-[28%]">
                  <TextField
                    label="End Time"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={eventEndTime}
                    onChange={(e) => setEventEndTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end mt-5">
                <div className="flex items-center gap-3">
                  <LoadingButton
                    variant="contained"
                    loading={buttonPressed}
                    disabled={!checkIfValid()}
                    loadingPosition="end"
                    endIcon={<SaveIcon />}
                    onClick={() => handleStart()}
                    sx={{ textTransform: "none", borderRadius: "8px" }}
                  >
                    Add Note
                  </LoadingButton>
                </div>
              </div>
            </div>
          )} */}
        {/* </Box> */}
      </Drawer>
    </>
  );
}
