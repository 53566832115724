import React from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function IntegrationCard(props) {

  const handleDeleteClick = () => {
    props.onDelete();
  };

  console.log(props?.integration?.key)

  return (
    <div
      className="w-full bg-slate-50 rounded-xl p-4"
    >
      <div className="flex items-center justify-between">
        <p className="text-slate-900 text-base">{props.integration.provider}</p>
        <div
          className="text-rose-800 cursor-pointer"
          onClick={handleDeleteClick}
        >
          <DeleteOutlineOutlinedIcon style={{ fontSize: "22px" }} />
        </div>
      </div>
      <div className="w-[90%]">
        <div className="w-full border border-[#bdbdbd] flex items-center h-8 rounded-lg overflow-hidden mt-2">
          <div className="bg-slate-100 px-2 w-[100px] h-full flex items-center">
            <p className="text-slate-500 text-sm">Key ID:</p>
          </div>
          <div className="flex-1 bg-white px-2 h-full flex items-center whitespace-nowrap overflow-x-scroll">
            <p className="text-slate-500 text-sm">{props.integration.key}</p>
          </div>
        </div>
        {props.integration.provider === "7 shifts" &&
          <div className="w-full border border-[#bdbdbd] flex items-center h-8 rounded-lg overflow-hidden mt-2">
            <div className="bg-slate-100 px-2 w-[100px] h-full flex items-center">
              <p className="text-slate-500 text-sm">Company ID:</p>
            </div>
            <div className="flex-1 bg-white px-2 h-full flex items-center">
              <p className="text-slate-500 text-sm">{props.integration.company_key}</p>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default IntegrationCard
