import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { formatDate } from '../requests/utilities/helpers';
import { formatDateReadableWithDayOfWeekNoYear } from '../requests/utilities/helpers';

function TimeClockShiftSummaryModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };


  const [shiftDate, setShiftDate] = React.useState(props.shiftDate)

  const [clockInTime, setClockInTime] = React.useState(props.clockInTime)

  const [clockInTimeInt, setClockInTimeInt] = React.useState(props.clockInTimeInt)

  const [shiftTimeline, setShiftTimeline] = React.useState(props.shiftTimeline);

  const [breaksArray, setBreaksArray] = React.useState(props.breaksArray)

  React.useEffect(() => {
    // Check if props.shiftTimeline.length is greater than 0 before updating the state
    if (props.shiftTimeline.length > 0) {
      setShiftTimeline(props.shiftTimeline);
    }
    if (props.showClockOutForSummaryModal) {
      setShiftTimeline([...shiftTimeline, {title: "Clock Out", type: "clock_out", time: (new Date()).toLocaleTimeString(), timeInt: Date.now()}])
      // props.setClockOutTimeForSummaryModal((new Date()).toLocaleTimeString())
    }
  }, [props.shiftTimeline, props.showClockOutForSummaryModal]);

  console.log("TEST", props.showClockOutForSummaryModal)


  React.useEffect(() => {
    if (props.clockInTime) {
      setClockInTime(new Date(props.clockInTime).toLocaleTimeString())
    }
  }, [props.clockInTime]);

  React.useEffect(() => {
    if (props.clockInTimeInt) {
      setClockInTimeInt((props.clockInTimeInt))
    }
  }, [props.clockInTimeInt]);

  React.useEffect(() => {
    if (props.breaksArray?.length > 0) {
      setBreaksArray(props.breaksArray)
    }
  }, [props.breaksArray]);

  React.useEffect(() => {
    if (props.shiftDate) {
      setShiftDate(props.shiftDate)
    }
  }, [props.shiftDate]);

  console.log("SHIFT TIMELINE2", shiftTimeline)



  function calculateTimeDifferences(shiftTimeline) {
    const result = [];

    for (let i = 0; i < shiftTimeline.length - 1; i++) {
        const currentEntry = shiftTimeline[i];
        const nextEntry = shiftTimeline[i + 1];

        let currentTime, nextTime, timeDiff;

        if (currentEntry.type === "break_end" && (nextEntry.type === "break_start" || nextEntry.type === "clock_out")) {
            currentTime = currentEntry.timeInt;  // Assuming currentEntry.time is already a timestamp
            nextTime = nextEntry.timeInt;        // Assuming nextEntry.time is already a timestamp
            timeDiff = Math.abs(nextTime - currentTime) / (1000 * 60); // in minutes
            result.push({ title: "Worked", time: formatTimeDifference(timeDiff) });
        } else if (currentEntry.type !== "break_end" && currentEntry.type !== "clock_out") {
            currentTime = currentEntry.timeInt;  // Assuming currentEntry.time is already a timestamp
            nextTime = nextEntry.timeInt;        // Assuming nextEntry.time is already a timestamp
            timeDiff = Math.abs(nextTime - currentTime) / (1000 * 60); // in minutes

            let title;
            switch (currentEntry.type) {
                case "clock_in":
                    title = "Worked";
                    break;
                case "break_start":
                    title = "Took Break";
                    break;
                default:
                    // Handle unexpected entry types
                    console.error("Unexpected entry type:", currentEntry.type);
                    break;
            }

            result.push({ title, time: formatTimeDifference(timeDiff) });
        }
    }

    return result;
  }


  function formatTimeDifference(minutes) {
    if (minutes >= 60) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = Math.round(minutes % 60); // Round the minutes
        if (remainingMinutes === 0) {
            return `${hours}hr`;
        } else {
            return `${hours}hr ${remainingMinutes.toFixed(1)}min`;
        }
    } else {
        return `${minutes.toFixed(1)}min`;
    }
}


  const shiftTimelineSimple = calculateTimeDifferences(shiftTimeline);




  function getHoursAndBreaks(startTime, endTime, breaksArray) {
    // Convert both to milliseconds
    var startTimeMilliseconds = new Date(startTime).getTime();
    var endTimeMilliseconds = new Date(endTime).getTime();

    let sumBreaksMilliseconds = 0;

    breaksArray?.forEach((breakItem) => {
      var startBreakMilliseconds = new Date(breakItem.clocked_in_at).getTime();
      var endBreakMilliseconds = breakItem.clocked_out_at
        ? new Date(breakItem.clocked_out_at).getTime()
        : breakItem.clocked_in_at
        ? new Date().getTime()
        : 0;
      var breakLengthMilliseconds = Math.abs(
        endBreakMilliseconds - startBreakMilliseconds
      );
      sumBreaksMilliseconds += breakLengthMilliseconds;
    });

    var totalTimeWorkedMilliseconds = Math.abs(
      endTimeMilliseconds - startTimeMilliseconds
    );
    var paidTimeWorkedMilliseconds =
      totalTimeWorkedMilliseconds - sumBreaksMilliseconds;

    // Convert milliseconds to hours
    var paidHours = Math.floor(paidTimeWorkedMilliseconds / (1000 * 60 * 60));

    // Calculate remaining milliseconds after subtracting full hours
    var remainingMilliseconds = paidTimeWorkedMilliseconds % (1000 * 60 * 60);

    // Convert remaining milliseconds to minutes
    var paidMinutes = Math.ceil(remainingMilliseconds / (1000 * 60));

    // Calculate break minutes
    var breakMinutes = Math.floor(sumBreaksMilliseconds / (1000 * 60));

    return {
      paidHours: paidHours,
      paidMinutes: paidMinutes,
      breakMinutes: breakMinutes,
    };
  }

  const hoursAndBreaks = getHoursAndBreaks(clockInTimeInt ? clockInTimeInt : Date.now(), props.clockOutTimeForSummaryModal ? props.clockOutTimeForSummaryModal : Date.now(), breaksArray ? breaksArray : [])

  console.log("BREAKSARRAY2", breaksArray)

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <h3 className="text-2xl text-slate-900">Shift Summary</h3>
            <p className="text-lg text-slate-500">{formatDateReadableWithDayOfWeekNoYear(formatDate(new Date(shiftDate)))}</p>
          </div>
          <div className="mt-3 flex items-center justify-between">
            <div className="mt-5">
              <p className="text-slate-500">Total paid time</p>
              <p className="text-slate-900 font-bold text-xl mt-1">{hoursAndBreaks.paidHours}hrs {hoursAndBreaks.paidMinutes}min</p>
            </div>
            <div className="mt-5">
              <p className="text-slate-500 text-right">Total break time</p>
              <p className="text-slate-900 font-bold text-xl text-right mt-1">{hoursAndBreaks.breakMinutes}min</p>
            </div>
          </div>
          <div className="mt-8">
            <p className="text-slate-500">Timeline</p>
            <div className="max-h-[550px] overflow-auto mt-3">
              {clockInTime &&
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-slate-900 text-sm font-bold">Clocked In</p>
                    {/* <p className="text-xs text-slate-500">On time</p> */}
                  </div>
                  <div className="flex-1 h-3.5 border-b border-dashed border-slate-200 mx-1">
                  </div>
                  <p className="text-slate-900 text-sm">
                    {clockInTime}
                  </p>
                </div>
              }
              {shiftTimelineSimple.map((item, index) => (
                <div key={index}>
                  <div className="w-1 h-3 border-l border-slate-200 my-1"></div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className={`${item.title === "Worked" ? "text-violet-700" : "text-orange-600"} text-sm font-bold`}>{item.title}</p>
                      {/* <p className="text-xs text-slate-500">On time</p> */}
                    </div>
                    <div className="flex-1 h-3.5 border-b border-dashed border-slate-200 mx-1">
                    </div>
                    <p className="text-slate-900 text-sm">
                      {item.time}
                    </p>
                  </div>
                </div>
              ))}
              {props.clockOutTimeForSummaryModal && props.showClockOutForSummaryModal &&
                <div>
                  <div className="w-1 h-3 border-l border-slate-200 my-1"></div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-slate-900 text-sm font-bold">Clocked Out</p>
                      {/* <p className="text-xs text-slate-500">On time</p> */}
                    </div>
                    <div className="flex-1 h-3.5 border-b border-dashed border-slate-200 mx-1">
                    </div>
                    <p className="text-slate-900 text-sm">
                      {new Date(props.clockOutTimeForSummaryModal).toLocaleTimeString()}
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default TimeClockShiftSummaryModal
