import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import TextField from "@mui/material/TextField";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from '@mui/icons-material/Info';

function LocationDetailsAttributesTable(props) {
  const [modalData, setModalData] = React.useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [laborDriverStoreAttributes, setLaborDriverStoreAttributes] =
    React.useState(props.laborDriverStoreAttributes);

  const [selectedAttributeIndex, setSelectedAttributeIndex] = React.useState(0);
  const [selectedAttribute, setSelectedAttribute] = React.useState(
    laborDriverStoreAttributes[selectedAttributeIndex]
  );

  React.useEffect(() => {
    setLaborDriverStoreAttributes(props.laborDriverStoreAttributes);
    setSelectedAttribute(
      props.laborDriverStoreAttributes[selectedAttributeIndex]
    );
  }, [props.laborDriverStoreAttributes, selectedAttributeIndex]);

  const handleAttributeChange = (event) => {
    const selectedAttributeName = event.target.value;
    const selectedAttributeIndex = props.laborDriverStoreAttributes?.findIndex(
      (attr) => attr?.name === selectedAttributeName
    );
    const selectedAttribute =
      props.laborDriverStoreAttributes?.[selectedAttributeIndex];
    setSelectedAttributeIndex(selectedAttributeIndex);
    setSelectedAttribute(selectedAttribute);
  };

  const handleValueChange = (event) => {
    const { name, value } = event.target;
    setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log("MODALDATA", modalData);

  function handleSubmit() {
    props.post_labor_driver_store_attribute(
      selectedAttribute.uuid,
      modalData?.value,
      modalData?.store?.id
    );
    handleClose();
  }

  return (
    <>
      <div className="mt-10">
        <div className="w-full px-6 flex justify-between">
          <div>
            <h1 className="text-3xl text-slate-900">Attributes</h1>
          </div>
          {props.laborDriverStoreAttributes?.length > 0 &&
            <div className="min-w-[200px] relative">
              <div>
                <FormControl fullWidth size="small">
                  <Select
                    value={selectedAttribute?.name}
                    onChange={handleAttributeChange}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    }}
                  >
                    {props.laborDriverStoreAttributes.map((v, i) => (
                      <MenuItem key={i} value={v?.name}>
                        {v?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="absolute -bottom-5 right-0">
                <p className="text-xs text-slate-500">
                  {laborDriverStoreAttributes?.length} total{" "}
                  {laborDriverStoreAttributes?.length === 1
                    ? "attribute"
                    : "attributes"}
                </p>
              </div>
            </div>
          }
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-[30%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                      >
                        Value
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-6">
                        {/* <span className="sr-only">Edit</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {selectedAttribute?.labor_driver_attributes_all_stores?.map(
                      (store, index) => (
                        <tr
                          key={index}
                          className="hover:bg-slate-50 cursor-pointer"
                          onClick={() => {
                            setModalData(store);
                            handleOpen();
                          }}
                        >
                          <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                            <div>
                              <p className="text-slate-900">
                                {store.store.store_name}
                              </p>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            <div>
                              <p className="text-slate-900">{store.value}</p>
                            </div>
                          </td>
                          <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                            <div className="flex items-center justify-end w-2 space-x-3 text-slate-500">
                              <ChevronRightIcon />
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <div className="border-b border-slate-200"></div>
                {props.laborDriverStoreAttributes?.length === 0 &&
                  <div className="w-full mt-32">
                    <div className="w-full flex flex-col items-center justify-center">
                      <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
                        <InfoIcon />
                      </div>
                      <p className="mt-2 text-sm w-72 text-center">No attribute labor drivers have been created. To create one, go to Labor Drivers, select the Attributes tab, and then click the + button.</p>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title="Save Store Attribute Value"
              onClose={handleClose}
            />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-sm text-slate-500">Location</p>
                <div className="mt-1">
                  <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                    <p>{modalData?.store?.store_name}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Attribute</p>
                <div className="mt-1">
                  <div className="bg-slate-200 text-slate-400 rounded-[8px] h-[45px] px-4 flex items-center">
                    <p>{selectedAttribute?.name}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Value</p>
                <div className="mt-1">
                  <TextField
                    placeholder="1"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="value"
                    value={modalData?.value}
                    onChange={handleValueChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Save Changes
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default LocationDetailsAttributesTable;
