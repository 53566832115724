import React from "react";
import TabsTwo from "./shared/helpers/TabsTwo";
import TradesRequestsTable from "./manager/trades/TradesRequestsTable";
import TradesApprovedTable from "./manager/trades/TradesApprovedTable";
import RequestHeader from "./shared/helpers/RequestHeader";

export default function TradesDisplay(props) {
  const [activeTab, setActiveTab] = React.useState("Requests");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  return (
    <div>
      {/* <RequestHeader title="Trades" /> */}
      <div className="w-1/2 mt-12 px-6">
        <TabsTwo
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Requests"
          tab2="Acted On"
        />
      </div>
      <div className="w-full">
        {activeTab === "Requests" ? (
          <TradesRequestsTable
            shiftPoolData={props.shiftPoolData}
            manager_reject_shift_drop={props.manager_reject_shift_drop}
            manager_approve_shift_pickup={props.manager_approve_shift_pickup}
            manager_shift_pickup_request_response={
              props.manager_shift_pickup_request_response
            }
          />
        ) : activeTab === "Acted On" ? (
          <TradesApprovedTable
            requestResponsesData={props.requestResponsesData}
            archive_trade_request={props.archive_trade_request}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
