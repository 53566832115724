import React from 'react'
import { Drawer } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import NotificationsCard from './NotificationsCard';
import IconButton from "@mui/material/IconButton";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InfoIcon from '@mui/icons-material/Info';
import NotificationSettingsModal from './NotificationSettingsModal';
import DrawerFooterContainerSmall from './shared/helpers/DrawerFooterContainerSmall';
import ButtonFilled from './shared/helpers/ButtonFilled';

function NotificationsDrawer(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto px-6">
          <div className="flex items-center justify-between pt-8 pb-4">
            <div className="flex items-center space-x-5">
              <h2 className="text-slate-900 text-2xl">Notifications</h2>
              <IconButton
                className="text-slate-900"
                sx={{ backgroundColor: "#f1f5f9" }}
                onClick={handleOpen}
              >
                <SettingsOutlinedIcon style={{ color: "#0f172a", fontSize: "20px" }} />
              </IconButton>
            </div>
            <div className="text-slate-500 cursor-pointer" onClick={props.onClose}>
              <CloseIcon />
            </div>
          </div>
          {props.notifications?.length > 0 ?
            <div>
              {props.notifications?.map((item, index) => (
                <NotificationsCard
                  key={index}
                  item={item}
                  archive_drawer_notification={props.archive_drawer_notification}
                />
              ))}
            </div> :
            <div className="w-full border-slate-200 mt-44 flex items-center justify-center">
              <div className="w-full flex flex-col items-center justify-center">
                <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
                  <InfoIcon />
                </div>
                <p className="mt-2 text-sm">No notifications at the moment</p>
              </div>
            </div>
          }
          <div className="h-[200px]"></div>
        </div>
        <DrawerFooterContainerSmall>
          <ButtonFilled text="Clear all" onClick={() => props.archive_all_drawer_notifications()} />
        </DrawerFooterContainerSmall>
      </div>
      <NotificationSettingsModal
        open={open}
        handleClose={handleClose}
        notificationSettings={props.notificationSettings}
        update_notification_settings={props.update_notification_settings}
        permissionRole={props.permissionRole}
      />
    </Drawer>
  )
}

export default NotificationsDrawer
