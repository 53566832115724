import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function ContestDropdown(props) {
  return (
    <Box sx={{ minWidth: 250 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">{props.type}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name={props.type}
          disabled={!props.options}
          value={
            props.selectedDropdown === props.type ? props.currentContestId : ""
          }
          label={props.type}
          onChange={props.handleChange}
        >
          {props.options &&
            props.options.map((v, i) => {
              return (
                <MenuItem key={v.id} value={v.id}>
                  {v.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
}
