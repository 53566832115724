import React from "react";
import IntroScreen from "./IntroScreen";
import BusinessInfoScreen from "./BusinessInfoScreen";
import PilotInfoScreen from "./PilotInfoScreen";
import GoalsInfoScreen from "./GoalsInfoScreen";
import SelectTierScreen from "./SelectTierScreen";
import ChecklistScreen from "./ChecklistScreen";
import CongratulationsScreen from "./CongratulationsScreen";
import ProgressBar from "./ProgressBar";

export default function OnboardingDisplay() {
  const [onboardingSteps, setOnboardingSteps] = React.useState(1);

  function increaseStep() {
    setOnboardingSteps((prevState) => (prevState += 1));
  }

  function decreaseStep() {
    setOnboardingSteps((prevState) => (prevState -= 1));
  }

  const [onboardingInfo, setOnboardingInfo] = React.useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    industry: "",
    businessName: "",
    numOfLocations: "",
    departmentNames: "",
    posSystem: "",
    employeeInfo: "",
    employeeWages: "",
    currentRoles: "",
    nonLaborAllowanceRoles: "",
    isUsingScheduling: "",
    schedulingSystem: "",
    replaceOrComplement: "",
    numOfPilots: "",
    pilotLocationName: "",
    operatingHours: "",
    pilotAccess: "",
    selectedPlan: "",
  });

  return (
    <div className="w-full min-h-screen bg-[#171e2e] flex items-center justify-center p-20">
      <img
        src={process.env.PUBLIC_URL + "/images/logo_gray_new.png"}
        alt="RightWork Logo"
        className="absolute left-10 top-5 w-44"
      />
      <div className="absolute right-10 top-7">
        <h3 className="text-gray-300 text-sm font-bold mb-2">
          Progress {onboardingSteps} / 7
        </h3>
        <ProgressBar currentStep={onboardingSteps} totalSteps={7} />
      </div>
      <div className="w-5/12">
        {onboardingSteps === 1 ? (
          <IntroScreen
            increaseStep={increaseStep}
            onboardingInfo={onboardingInfo}
            setOnboardingInfo={setOnboardingInfo}
          />
        ) : onboardingSteps === 2 ? (
          <BusinessInfoScreen
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            onboardingInfo={onboardingInfo}
            setOnboardingInfo={setOnboardingInfo}
          />
        ) : onboardingSteps === 3 ? (
          <PilotInfoScreen
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            onboardingInfo={onboardingInfo}
            setOnboardingInfo={setOnboardingInfo}
          />
        ) : onboardingSteps === 4 ? (
          <GoalsInfoScreen
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            onboardingInfo={onboardingInfo}
            setOnboardingInfo={setOnboardingInfo}
          />
        ) : onboardingSteps === 5 ? (
          <SelectTierScreen
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            onboardingInfo={onboardingInfo}
            setOnboardingInfo={setOnboardingInfo}
          />
        ) : onboardingSteps === 6 ? (
          <ChecklistScreen
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            onboardingInfo={onboardingInfo}
            setOnboardingInfo={setOnboardingInfo}
          />
        ) : onboardingSteps === 7 ? (
          <CongratulationsScreen onboardingInfo={onboardingInfo} />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
