import React from 'react'

export default function TabsThree(props) {
  return (
    <div className="w-full bg-slate-50 flex rounded-2xl p-1">
      <button
        className={`w-1/3 flex justify-center items-center py-3 rounded-xl ${props.activeTab === props.tab1 ? "bg-white shadow" : ""}`}
        onClick={() => props.handleTabButtonPress(props.tab1)}
      >
        <p className={`text-sm ${props.activeTab === props.tab1 ? "text-violet-700 font-semibold" : "text-slate-500"}`}>{props.tab1}</p>
      </button>
      <button
        className={`w-1/3 flex justify-center items-center py-3 rounded-xl ${props.activeTab === props.tab2 ? "bg-white shadow" : ""}`}
        onClick={() => props.handleTabButtonPress(props.tab2)}
      >
        <p className={`text-sm ${props.activeTab === props.tab2 ? "text-violet-700 font-semibold" : "text-slate-500"}`}>{props.tab2}</p>
      </button>
      <button
        className={`w-1/3 flex justify-center items-center py-3 rounded-xl ${props.activeTab === props.tab3 ? "bg-white shadow" : ""}`}
        onClick={() => props.handleTabButtonPress(props.tab3)}
      >
        <p className={`text-sm ${props.activeTab === props.tab3 ? "text-violet-700 font-semibold" : "text-slate-500"}`}>{props.tab3}</p>
      </button>
    </div>
  )
}
