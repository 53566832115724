import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";

function EditChannelNameModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const handleChannelNameChange = (event) => {
    props.setChannelName(event.target.value);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <h3 className="text-2xl">Edit Channel Name</h3>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Channel Name</p>
              <div className="mt-1.5">
                <TextField
                  placeholder="channel-name (no spaces)"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={props.channelName}
                  onChange={handleChannelNameChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div
                  className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div
                  className="px-8 py-2 bg-violet-50 hover:bg-violet-100 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.handleEditChannel(props.channelID, props.channelName)
                    props.handleClose()
                  }}
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditChannelNameModal;
