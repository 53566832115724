import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function BusinessInfoScreen(props) {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    // Validate Company Name
    if (props.onboardingInfo.businessName.trim() === "") {
      newErrors.businessName = "Company Name is required";
    }

    // Validate Number of Locations
    if (props.onboardingInfo.numOfLocations.trim() === "") {
      newErrors.numOfLocations = "Number of Locations is required";
    }

    // Validate Department Names
    if (props.onboardingInfo.departmentNames.trim() === "") {
      newErrors.departmentNames = "Department Names are required";
    }

    // Validate POS System
    if (props.onboardingInfo.posSystem === "") {
      newErrors.posSystem = "POS System is required";
    }

    // // Validate Employee Info
    // if (props.onboardingInfo.posSystem && !props.onboardingInfo.employeeInfo) {
    //   newErrors.employeeInfo = 'Please select an option for employee information in POS System';
    // }

    // // Validate Employee Wages
    // if (props.onboardingInfo.posSystem && !props.onboardingInfo.employeeWages) {
    //   newErrors.employeeWages = 'Please select an option for employee wages in POS System';
    // }

    // // Validate Current Roles
    // if (props.onboardingInfo.posSystem && !props.onboardingInfo.currentRoles) {
    //   newErrors.currentRoles = 'Please select an option for current roles in POS System';
    // }

    // // Validate Non-Labor Allowance Roles
    // if (props.onboardingInfo.posSystem && !props.onboardingInfo.nonLaborAllowanceRoles) {
    //   newErrors.nonLaborAllowanceRoles = 'Please select an option for non-labor allowance roles in POS System';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleNextStep = () => {
    const isValid = validateForm();

    if (isValid) {
      // Proceed to the next step
      props.increaseStep();
    }
  };

  return (
    <div>
      <div className="relative">
        <button
          className="absolute text-gray-100 -left-12 top-1"
          onClick={props.decreaseStep}
        >
          <ArrowBackIosIcon style={{ fontSize: "26px" }} />
        </button>
        <h2 className="text-3xl text-gray-100 font-bold">
          Tell us about your {props.onboardingInfo.industry} company!
        </h2>
      </div>
      <div className="mt-5">
        <Divider style={{ backgroundColor: "gray" }} />
      </div>
      <div className="mt-8">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <div className="w-1/2">
              <label className="block mb-1 text-gray-300">Company Name *</label>
              <input
                type="text"
                className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                  errors.businessName ? "border-red-500" : "border-[#2f3847]"
                }`}
                value={props.onboardingInfo.businessName}
                onChange={(e) =>
                  props.setOnboardingInfo({
                    ...props.onboardingInfo,
                    businessName: e.target.value,
                  })
                }
              />
              {errors.businessName && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.businessName}
                </p>
              )}
            </div>
            <div className="w-1/2">
              <label className="block mb-1 text-gray-300">
                Number of Locations *
              </label>
              <input
                type="text"
                className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                  errors.numOfLocations ? "border-red-500" : "border-[#2f3847]"
                }`}
                value={props.onboardingInfo.numOfLocations}
                onChange={(e) =>
                  props.setOnboardingInfo({
                    ...props.onboardingInfo,
                    numOfLocations: e.target.value,
                  })
                }
              />
              {errors.numOfLocations && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.numOfLocations}
                </p>
              )}
            </div>
          </div>
          <div className="mt-4">
            <label className="block mb-1 text-gray-300">
              What are your department names? * (i.e. a restaurant usually has
              back-of-house (BOH) and front-of-house (FOH) and a hotel usually
              has front desk and housekeeping). Please separate them with a
              comma.
            </label>
            <input
              type="text"
              className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                errors.departmentNames ? "border-red-500" : "border-[#2f3847]"
              }`}
              value={props.onboardingInfo.departmentNames}
              onChange={(e) =>
                props.setOnboardingInfo({
                  ...props.onboardingInfo,
                  departmentNames: e.target.value,
                })
              }
              placeholder="BOH, FOH"
            />
            {errors.departmentNames && (
              <p className="text-red-500 text-sm mt-1">
                {errors.departmentNames}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block mb-1 text-gray-300">
              What POS system are you using? *
            </label>
            <select
              className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                errors.posSystem ? "border-red-500" : "border-[#2f3847]"
              }`}
              value={props.onboardingInfo.posSystem}
              onChange={(e) =>
                props.setOnboardingInfo({
                  ...props.onboardingInfo,
                  posSystem: e.target.value,
                })
              }
            >
              <option value="">Select a POS system</option>
              <option value="Toast">Toast</option>
              <option value="Revel Systems">Revel Systems</option>
              <option value="Square">Square</option>
              <option value="Lightspeed">Lightspeed</option>
              <option value="Other">Other</option>
            </select>
            {errors.posSystem && (
              <p className="text-red-500 text-sm mt-1">{errors.posSystem}</p>
            )}
          </div>
          {/* {props.onboardingInfo.posSystem &&
            <div className="mt-6">
              <div className="flex flex-col">
                <div className="flex items-center mb-1">
                  <label className="text-gray-300">
                    Are your employees and their contact info listed in your {props.onboardingInfo.posSystem} POS? *
                  </label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="yes"
                    checked={props.onboardingInfo.employeeInfo === 'yes'}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, employeeInfo: e.target.value})}
                  />
                  <label className="text-gray-300">Yes</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="no"
                    checked={props.onboardingInfo.employeeInfo === 'no'}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, employeeInfo: e.target.value})}
                  />
                  <label className="text-gray-300">No</label>
                </div>
                {errors.employeeInfo && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.employeeInfo}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <div className="flex items-center mb-1">
                  <label className="text-gray-300">Are your employees' wages listed in your {props.onboardingInfo.posSystem} POS? *</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="yes"
                    checked={props.onboardingInfo.employeeWages === 'yes'}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, employeeWages: e.target.value})}
                  />
                  <label className="text-gray-300">Yes</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="no"
                    checked={props.onboardingInfo.employeeWages === 'no'}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, employeeWages: e.target.value})}
                  />
                  <label className="text-gray-300">No</label>
                </div>
                {errors.employeeWages && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.employeeWages}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <div className="flex items-center mb-1">
                  <label className="text-gray-300">Are all your current roles listed in your {props.onboardingInfo.posSystem} POS? *</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="yes"
                    checked={props.onboardingInfo.currentRoles === "yes"}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, currentRoles: e.target.value})}
                  />
                  <label className="text-gray-300">Yes</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="no"
                    checked={props.onboardingInfo.currentRoles === "no"}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, currentRoles: e.target.value})}
                  />
                  <label className="text-gray-300">No</label>
                </div>
                {errors.currentRoles && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.currentRoles}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <div className="flex items-center mb-1">
                  <label className="text-gray-300">Do you have any roles that should NOT count towards your labor allowance? * (i.e. a salaried manager)</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="yes"
                    checked={props.onboardingInfo.nonLaborAllowanceRoles === "yes"}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, nonLaborAllowanceRoles: e.target.value})}
                  />
                  <label className="text-gray-300">Yes</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="radio"
                    className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                    value="no"
                    checked={props.onboardingInfo.nonLaborAllowanceRoles === "no"}
                    onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, nonLaborAllowanceRoles: e.target.value})}
                  />
                  <label className="text-gray-300">No</label>
                </div>
                {errors.nonLaborAllowanceRoles && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.nonLaborAllowanceRoles}
                  </p>
                )}
              </div>
            </div>
          } */}
          <div className="mt-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                checked={props.onboardingInfo.isUsingScheduling}
                onChange={(e) =>
                  props.setOnboardingInfo({
                    ...props.onboardingInfo,
                    isUsingScheduling: e.target.checked,
                  })
                }
              />
              <label className="text-gray-300">
                I am currently using a scheduling software
              </label>
            </div>
            {props.onboardingInfo.isUsingScheduling && (
              <div className="mt-8">
                <label className="block mb-1 text-gray-300">
                  What is your current scheduling system?
                </label>
                <select
                  className="border-2 border-[#2f3847] rounded-md p-2 w-full bg-[#252f3f] text-gray-200"
                  value={props.onboardingInfo.schedulingSystem}
                  onChange={(e) =>
                    props.setOnboardingInfo({
                      ...props.onboardingInfo,
                      schedulingSystem: e.target.value,
                    })
                  }
                >
                  <option value="">Select a scheduling system</option>
                  <option value="7shifts">7shifts</option>
                  <option value="HotSchedules">HotSchedules</option>
                  <option value="Other">Other</option>
                </select>
                {props.onboardingInfo.schedulingSystem && (
                  <div className="mt-8">
                    <div className="flex items-center">
                      <label className="block mb-1 text-gray-300">
                        Would you like to replace your current scheduling system
                        or complement it?
                      </label>
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        type="radio"
                        className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                        value="replace"
                        checked={
                          props.onboardingInfo.replaceOrComplement === "replace"
                        }
                        onChange={(e) =>
                          props.setOnboardingInfo({
                            ...props.onboardingInfo,
                            replaceOrComplement: e.target.value,
                          })
                        }
                      />
                      <label className="text-gray-300">
                        Replace {props.onboardingInfo.schedulingSystem} with
                        RightWork
                      </label>
                    </div>
                    <div className="flex items-center mt-4">
                      <input
                        type="radio"
                        className="form-radio h-5 w-5 text-gray-300 rounded border-2 border-gray-300 mr-2"
                        value="complement"
                        checked={
                          props.onboardingInfo.replaceOrComplement ===
                          "complement"
                        }
                        onChange={(e) =>
                          props.setOnboardingInfo({
                            ...props.onboardingInfo,
                            replaceOrComplement: e.target.value,
                          })
                        }
                      />
                      <label className="text-gray-300">
                        Complement {props.onboardingInfo.schedulingSystem} with
                        RightWork
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2 mt-8">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full"
            onClick={handleNextStep}
          >
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
}
