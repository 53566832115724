import React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";
import Pill from "../helpers/Pill";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import { formatDateReadable } from "../../utilities/helpers";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../../utilities/helpers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { Drawer } from "@mui/material";
import DrawerHeader from "../helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../helpers/DrawerFooterContainerSmall";
import ButtonFilled from "../helpers/ButtonFilled";
import { PurpleSwitch } from "../../../../../helpers/helpers";
import DatePickerDropdown from "../helpers/DatePickerDropdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};


export default function TimeOffMineTable(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dateStart, setDateStart] = React.useState(formatDate(props.minEffectiveDate));
  const [dateEnd, setDateEnd] = React.useState(formatDate(props.minEffectiveDate));
  const [isDateSelected, setIsDateSelected] = React.useState(false);
  const [requestTitle, setRequestTitle] = React.useState("");
  const [requestDescription, setRequestDescription] = React.useState("");
  const [requestDate, setRequestDate] = React.useState("");
  const [showEndDate, setShowEndDate] = React.useState(false);

  const [validationError, setValidationError] = React.useState("");

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSubmit = () => {
    /*
    store_id = request.data.get('store_id')
    title = request.data.get('title')
    note = request.data.get('note')
    date_lower_bound = request.data.get('date_lower_bound')
    date_upper_bound = request.data.get('date_upper_bound')
    */

    if (requestTitle.trim() === "") {
      setValidationError("Must include title to submit time off request.");
      return;
    }

    let data = {
      title: requestTitle,
      note: requestDescription,
      date_lower_bound: dateStart,
    };
    if (showEndDate) {
      data["date_upper_bound"] = dateEnd;
    }
    props.request_time_off(data);
    handleClose();
    setDrawerOpen(false);
  };

  const hasTimeOffRequests = props.myTimeOffData.length > 0;

  // For the start date datepicker
  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  const timeOffRequests = props.myTimeOffData;

  // Helper function to categorize time off requests into "Upcoming" and "History"
  const categorizeTimeOffRequests = (requests) => {
    const currentDate = new Date();
    return requests.reduce(
      (acc, request) => {
        const startDate = new Date(request.date_lower_bound);
        if (startDate > currentDate) {
          acc.upcoming.push(request);
        } else {
          acc.history.push(request);
        }
        return acc;
      },
      { upcoming: [], history: [] }
    );
  };

  const [categorizedTimeOffRequests, setCategorizedTimeOffRequests] = React.useState(
    categorizeTimeOffRequests(timeOffRequests)
  );

  React.useEffect(() => {
    setCategorizedTimeOffRequests(categorizeTimeOffRequests(timeOffRequests));
  }, [props.myTimeOffData]);






  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]); // Re-run the effect when 'showDailyDatePicker' changes


  // For the end date datepicker
  const [showDailyDatePicker2, setShowDailyDatePicker2] = React.useState(false);
  const datePickerRef2 = React.useRef(null);

  function toggleDailyDatePicker2() {
    setShowDailyDatePicker2((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside2(event) {
      if (
        datePickerRef2.current &&
        !datePickerRef2.current.contains(event.target)
      ) {
        setShowDailyDatePicker2(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker2) {
      document.addEventListener("mousedown", handleClickOutside2);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, [showDailyDatePicker2]); // Re-run the effect when 'showDailyDatePicker' changes


  return (
    <div className="mt-12">
      {hasTimeOffRequests ? (
        <div>
          <div>
            <div className="flex items-center">
              <div className="w-full flex items-center justify-between px-6">
                <h1 className="text-3xl text-slate-900">Time Off</h1>
                <button
                  className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
                  onClick={toggleDrawer(true)}
                >
                  <AddIcon style={{ fontSize: "22px" }} />
                </button>
              </div>
            </div>
            <div className="mt-10 px-6">
              <h3 className="text-lg text-violet-700">My Upcoming</h3>
            </div>
            <div className="mt-4 flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-slate-200">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="w-[20%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                          >
                            Time Off Requested
                          </th>
                          {/* <th scope="col" className="w-[14%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium">
                          Type
                        </th> */}
                          <th
                            scope="col"
                            className="w-[10%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                          >
                            Note
                          </th>
                          <th
                            scope="col"
                            className="w-1/12 relative py-3.5 pl-3 pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-slate-200 bg-white">
                        {categorizedTimeOffRequests.upcoming.map((request, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm">
                              <div className="font-medium text-slate-900">
                                {request.title}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900-500">
                              <div className="flex items-center space-x-1">
                                <p>
                                  {formatDateReadable(request.date_lower_bound)}
                                </p>
                                {request.date_upper_bound && (
                                  <div className="flex items-center space-x-1">
                                    <div className="text-slate-900">
                                      <ArrowForwardIcon
                                        style={{ fontSize: "16px" }}
                                      />
                                    </div>
                                    <p>
                                      {formatDateReadable(
                                        request.date_upper_bound
                                      )}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </td>
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                            <div className="text-slate-900">{request.type}</div>
                            <div className="text-slate-500">({request.hours})</div>
                          </td> */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
                              {request.note ? (
                                <span className="text-slate-500 mr-8">
                                  <Tooltip title={request.note}>
                                    <CommentIcon />
                                  </Tooltip>
                                </span>
                              ) : (
                                <span className="text-slate-500 mr-8">
                                  No note
                                </span>
                              )}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
                              <div className="w-full flex items-center justify-end gap-x-3">
                                <div>
                                  <Pill status={request.status.toLowerCase()} />
                                </div>
                                {request.status.toLowerCase() !== "denied" &&
                                request.status.toLowerCase() !== "rejected" &&
                                request.status.toLowerCase() !== "rescinded" &&
                                request.status.toLowerCase() !== "cancelled" ? (
                                  <Tooltip title="Cancel Time Off">
                                    <IconButton
                                      className="text-slate-500 ml-4"
                                      sx={{ backgroundColor: "#f8fafc" }}
                                      onClick={() =>
                                        props.rescind_time_off_request(
                                          request.uuid
                                        )
                                      }
                                    >
                                      <CloseIcon style={{ fontSize: "20px" }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <div className="w-10 h-10"></div>
                                )}
                                <Tooltip title="Archive">
                                  <IconButton
                                    className="text-slate-500 ml-4"
                                    sx={{ backgroundColor: "#f8fafc" }}
                                    onClick={() =>
                                      props.archive_time_off_request(request.uuid)
                                    }
                                  >
                                    <ArchiveOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="border-b border-slate-200"></div>
                    {categorizedTimeOffRequests.upcoming?.length === 0 &&
                      <div className="w-full flex flex-col items-center justify-center mt-10">
                        <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
                          <InfoIcon />
                        </div>
                        <p className="mt-2 text-sm">You have no upcoming time off requests</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mt-16 px-6">
              <h3 className="text-lg text-violet-700">My History</h3>
            </div>
            <div className="mt-4 flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-slate-200">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="w-[20%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                          >
                            Time Off Requested
                          </th>
                          {/* <th scope="col" className="w-[14%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium">
                          Type
                        </th> */}
                          <th
                            scope="col"
                            className="w-[10%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                          >
                            Note
                          </th>
                          <th
                            scope="col"
                            className="w-1/12 relative py-3.5 pl-3 pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-slate-200 bg-white">
                        {categorizedTimeOffRequests.history.map((request, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm">
                              <div className="font-medium text-slate-900">
                                {request.title}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900-500">
                              <div className="flex items-center space-x-1">
                                <p>
                                  {formatDateReadable(request.date_lower_bound)}
                                </p>
                                {request.date_upper_bound && (
                                  <div className="flex items-center space-x-1">
                                    <div className="text-slate-900">
                                      <ArrowForwardIcon
                                        style={{ fontSize: "16px" }}
                                      />
                                    </div>
                                    <p>
                                      {formatDateReadable(
                                        request.date_upper_bound
                                      )}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </td>
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                            <div className="text-slate-900">{request.type}</div>
                            <div className="text-slate-500">({request.hours})</div>
                          </td> */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
                              {request.note ? (
                                <span className="text-slate-500 mr-8">
                                  <Tooltip title={request.note}>
                                    <CommentIcon />
                                  </Tooltip>
                                </span>
                              ) : (
                                <span className="text-slate-500 mr-8">
                                  No note
                                </span>
                              )}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
                              <div className="w-full flex items-center justify-end gap-x-3">
                                <div>
                                  <Pill status={request.status.toLowerCase()} />
                                </div>
                                {request.status.toLowerCase() !== "denied" &&
                                request.status.toLowerCase() !== "rejected" &&
                                request.status.toLowerCase() !== "rescinded" &&
                                request.status.toLowerCase() !== "cancelled" ? (
                                  <Tooltip title="Cancel Time Off">
                                    <IconButton
                                      className="text-slate-500 ml-4"
                                      sx={{ backgroundColor: "#f8fafc" }}
                                      onClick={() =>
                                        props.rescind_time_off_request(
                                          request.uuid
                                        )
                                      }
                                    >
                                      <CloseIcon style={{ fontSize: "20px" }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <div className="w-10 h-10"></div>
                                )}
                                <Tooltip title="Archive">
                                  <IconButton
                                    className="text-slate-500 ml-4"
                                    sx={{ backgroundColor: "#f8fafc" }}
                                    onClick={() =>
                                      props.archive_time_off_request(request.uuid)
                                    }
                                  >
                                    <ArchiveOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="border-b border-slate-200"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex items-center">
            <div className="w-full flex items-center justify-between px-6">
              <h1 className="text-3xl text-slate-900">My Time Off</h1>
              <button
                className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
                onClick={toggleDrawer(true)}
              >
                <AddIcon style={{ fontSize: "22px" }} />
              </button>
            </div>
          </div>
          <div className="w-full h-72 border-t border-slate-200 mt-12 flex items-center justify-center">
            <div className="w-full flex flex-col items-center justify-center">
              <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
                <InfoIcon />
              </div>
              <p className="mt-2 text-sm">
                You have no time off requests at the moment
              </p>
            </div>
          </div>
        </div>
      )}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-2xl font-semibold">Request Time Off</h2>
          <div>
            <div className="mt-6">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Title"
                variant="outlined"
                size="small"
                value={requestTitle}
                onChange={(event) => setRequestTitle(event.target.value)}
              />
            </div>
            <div className="mt-3">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Note"
                variant="outlined"
                size="small"
                value={requestDescription}
                onChange={(event) => setRequestDescription(event.target.value)}
              />
            </div>
            <div className="mt-3 flex flex-row items-center space-x-5">
              <div>
                <p className="text-xs font-bold mb-0.5">
                  {showEndDate ? "Start Date" : "Date"}
                </p>
                <TextField
                  id="outlined-basic"
                  type="date"
                  size="small"
                  className="w-60 border border-slate-400 p-4 text-slate-700 rounded-lg"
                  value={dateStart}
                  onChange={(event) => setDateStart(event.target.value)}
                />
              </div>
              {showEndDate && (
                <div className="flex items-center gap-5">
                  <p className="mt-4">to</p>
                  <div>
                    <p className="text-xs font-bold mb-0.5">End Date</p>
                    <TextField
                      id="outlined-basic"
                      type="date"
                      size="small"
                      className="w-60 border border-slate-400 p-4 text-slate-700 rounded-lg"
                      value={dateEnd}
                      onChange={(event) => setDateEnd(event.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-2 flex items-center">
              <Switch
                checked={showEndDate}
                onChange={() => setShowEndDate(!showEndDate)}
              />
              <p className="text-slate-500">Request more than one day off</p>
            </div>
            <div className="w-full mt-6 flex justify-end">
              <div className="flex items-center gap-5">
                <button
                  className="px-8 py-3 rounded-lg border border-primary text-primary hover:bg-slate-100 text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="px-8 py-3 rounded-lg bg-primary hover:bg-blue-700 text-white text-sm"
                  onClick={() => handleSubmit()}
                >
                  Request
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-4 top-4 text-slate-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </Box>
      </Modal> */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-screen bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title="Request Time Off"
              onClose={toggleDrawer(false)}
            />
            <div className="px-6">
              <div className="mt-6">
                <h3 className="text-slate-500 text-sm">Title</h3>
                <div className="mt-1">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="e.g. Vacation request"
                    size="small"
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "12px",
                        paddingY: "5px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                    value={requestTitle}
                    onChange={(event) => setRequestTitle(event.target.value)}
                  />
                </div>
              </div>
              <div className="mt-5">
                <h3 className="text-slate-500 text-sm">Note</h3>
                <div className="mt-1">
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder="Leave a note (optional)"
                    size="small"
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "12px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                    value={requestDescription}
                    onChange={(event) =>
                      setRequestDescription(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="mt-5">
                <h3 className="text-slate-500 text-sm">
                  {showEndDate ? "Start Date" : "Date"}
                </h3>
                <div className="mt-1">
                  <DatePickerDropdown
                    showDailyDatePicker={showDailyDatePicker}
                    toggleDailyDatePicker={toggleDailyDatePicker}
                    datePickerRef={datePickerRef}
                    setDate={setDateStart}
                    minEffectiveDate={props.minEffectiveDate}
                  />
                </div>
              </div>
              {showEndDate && (
                <div className="mt-5">
                  <p className="text-slate-500 text-sm">End Date</p>
                  <div className="mt-1">
                    <DatePickerDropdown
                      showDailyDatePicker={showDailyDatePicker2}
                      toggleDailyDatePicker={toggleDailyDatePicker2}
                      datePickerRef={datePickerRef2}
                      setDate={setDateEnd}
                      minEffectiveDate={dateStart}
                    />
                  </div>
                </div>
              )}
              <div className="mt-5 flex items-center">
                <PurpleSwitch
                  checked={showEndDate}
                  onChange={() => setShowEndDate(!showEndDate)}
                />
                <p className="text-slate-500 text-sm">
                  Request more than one day off
                </p>
              </div>
              <div className="mt-5 text-rose-600">{validationError}</div>
            </div>
            <div className="h-[150px]"></div>
          </div>
          <DrawerFooterContainerSmall>
            <ButtonFilled text="Request" onClick={() => handleSubmit()} />
          </DrawerFooterContainerSmall>
        </div>
      </Drawer>
    </div>
  );
}
