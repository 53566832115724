import * as React from "react";
import AvailabilityInputsModal from "./AvailabilityInputsModal";

function AddAvailabilityCard(props) {
  const [open, setOpen] = React.useState(false);

  // Event handler for opening the modal
  const handleOpen = () => setOpen(true);

  // Event handler for closing the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveAvailabilityFinished(false);
  };

  // Object representing availability information
  const availability_info = {
    store_team_member: props.p.id,
    dow: props.date_dow,
    date: props.date_str,
    available_from: 0,
    available_to: 0,
    preferred_from: 0,
    preferred_to: 0,
    default: false,
  };

  return (
    <div className="w-full h-full">
      <a
        onClick={handleOpen}
        className="hover:border-violet-700 hover:border-solid hover:bg-white hover:text-violet-700 group w-full h-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium cursor-pointer"
      >
        <svg
          className="group-hover:text-violet-700 mb-1 text-slate-400"
          width="20"
          height="20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
        </svg>
        <p className={`text-xxs ${props.minimizeMode && "mb-[3px]"}`}>
          Add availability
        </p>
      </a>
      <AvailabilityInputsModal
        open={open}
        handleClose={handleClose}
        availability_info={availability_info}
        p={props.p}
        new_availability={true}
        editAvailability={props.editAvailability}
        date_name={props.date_name}
        day_of_week_name={props.day_of_week_name}
        date_str={props.date_str}
        get_t_str={props.get_t_str}
        saveAvailabilityFinished={props.saveAvailabilityFinished}
        d={props.d}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        request_time_off={props.request_time_off}
      />
    </div>
  );
}

export default AddAvailabilityCard;
