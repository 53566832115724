import React from "react";
import SettingsHeader from "../department_role_station_settings/SettingsHeader";
import TabsTwo from "../requests/shared/helpers/TabsTwo";
import TabsThree from "../requests/shared/helpers/TabsThree";
import LocationDetailsProficiencyTable from "./LocationDetailsProficiencyTable";
import LocationDetailsAttributesTable from "./LocationDetailsAttributesTable";
import LocationDetailsFlagsTable from "./LocationDetailsFlagsTable";

function LocationDetailsDisplay(props) {
  const [activeTab, setActiveTab] = React.useState("Attributes");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  return (
    <div>
      {/* <SettingsHeader title="Location Details" /> */}
      <div className="w-[420px] px-6 mt-6">
        <TabsThree
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Attributes"
          tab2="Flags"
          tab3="Proficiency"
        />
      </div>
      <div className="w-full">
        {activeTab === "Proficiency" ? (
          <div>
            <LocationDetailsProficiencyTable
              storesInfo={props.storesInfo}
              post_store_proficiency={props.post_store_proficiency}
            />
          </div>
        ) : activeTab === "Attributes" ? (
          <div>
            <LocationDetailsAttributesTable
              laborDriverStoreAttributes={props.laborDriverStoreAttributes}
              post_labor_driver_store_attribute={
                props.post_labor_driver_store_attribute
              }
            />
          </div>
        ) : activeTab === "Flags" ? (
          <div>
            <LocationDetailsFlagsTable
              laborDriverStoreAttributes={props.laborDriverStoreAttributes}
              post_labor_driver_store_attribute={
                props.post_labor_driver_store_attribute
              }
              laborProgramFlagsStoreOverrides={props.laborProgramFlagsStoreOverrides}
              post_labor_program_flag_store={props.post_labor_program_flag_store}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default LocationDetailsDisplay;
