import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SaveAsTemplateButton from "./SaveAsTemplateButton";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import PublishScheduleButton from "../PublishScheduleButton";
import DeleteShiftsButton from "./DeleteShiftsButton";
import ShowUnpublishedShiftsButton from "../ShowUnpublishedShiftsButton";

function SaveScheduleButtons(props) {
  return (
    <div>
      <Stack direction="row" spacing={1} alignItems="center">
        <DeleteShiftsButton
          deleteAllShifts={props.deleteAllShifts}
          week_already_started={props.week_already_started}
        />
        <SaveAsTemplateButton
          saveScheduleAsTemplate={props.saveScheduleAsTemplate}
          template_names={props.template_names}
          formatDate={props.formatDate}
          scheduled_hours={props.scheduled_hours}
        />
        <ShowUnpublishedShiftsButton
          showUnpublishedShifts={props.showUnpublishedShifts}
          setShowUnpublishedShifts={props.setShowUnpublishedShifts}
        />
        <PublishScheduleButton
          publishShifts={props.publishShifts}
          roleType={props.roleType}
          roleTypeOptions={props.roleTypeOptions}
          publishShiftsFinished={props.publishShiftsFinished}
          setPublishShiftsFinished={props.setPublishShiftsFinished}
          pushedChanges={props.pushedChanges}
          has7ShiftsIntegration={props.has7ShiftsIntegration}
          countOfWarnings={props.countOfWarnings}
          countOfUnpublished={props.countOfUnpublished}
          countOfPublished={props.countOfPublished}
        />
      </Stack>
    </div>
  );
}

export default SaveScheduleButtons;
