import React from 'react'
import Drawer from "@mui/material/Drawer";
import DrawerHeader from '../../requests/shared/helpers/DrawerHeader';
import TextField from "@mui/material/TextField";
import ButtonOutlined from '../../requests/shared/helpers/ButtonOutlined';
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DrawerFooterContainerLarge from '../../requests/shared/helpers/DrawerFooterContainerLarge';

function ProfileDrawer(props) {

  const default_data = {
    first_name: "",
    last_name: "",
    email: "",
    phone: null,
    clock_pin: null,
    permission_type: "",
    stm_id: null,
    is_hidden: false,
  };

  const [modalData, setModalData] = React.useState(default_data);

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-white relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader
            title="Profile Settings"
            onClose={props.onClose}
          />
          <div className="px-6">
            <div className="mt-4">
              <p className="text-sm text-slate-500">First Name</p>
              <div className="mt-1">
                <TextField
                  placeholder="John"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="first_name"
                  // value={modalData.first_name}
                  // onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <p className="text-sm text-slate-500">Last Name</p>
              <div className="mt-1">
                <TextField
                  placeholder="Smith"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="last_name"
                  // value={modalData.last_name}
                  // onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <p className="text-sm text-slate-500">Email</p>
              <div className="mt-1">
                <TextField
                  placeholder="email@email.com"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="email"
                  // value={modalData.email}
                  // onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <p className="text-sm text-slate-500">Phone</p>
              <div className="mt-1">
                <TextField
                  placeholder="(555) 555-5555"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="phone"
                  // value={modalData.phone}
                  // onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            {/* {props.timeEntriesEnabled &&
              <div className="mt-4">
                <p className="text-sm text-slate-500">Pin</p>
                <div className="mt-1">
                  <TextField
                    placeholder="1234"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="clock_pin"
                    value={modalData.clock_pin}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            } */}
          </div>
          <div className="h-[150px]"></div>
        </div>
        <DrawerFooterContainerLarge>
          <div className="w-full">
            <LoadingButton
              fullWidth
              // loading={props.isSavingData}
              loadingPosition="end"
              endIcon={<SaveIcon />}
              variant="contained"
              // onClick={() => handleSubmit()}
              sx={{
                textTransform: "none",
                borderRadius: "50px",
                paddingY: "14px",
                backgroundColor: "#6d28d9",
                "&:hover": {
                  backgroundColor: "#5b21b6",
                },
                fontSize: "16px",
              }}
            >
              Save
            </LoadingButton>
          </div>
          <div className="w-full mt-3">
            <ButtonOutlined text="Cancel" onClick={props.onClose} />
          </div>
        </DrawerFooterContainerLarge>
      </div>
    </Drawer>
  )
}

export default ProfileDrawer
