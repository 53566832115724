import React from "react";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SaveIcon from "@mui/icons-material/Save";

import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

function SaveAsTemplateButton(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setState({ ...state, template_name: "" });
  };

  const [state, setState] = React.useState({ template_name: "" });

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function submitAndClose() {
    // Create a list of template names from the dictionary
    const templateNamesList = props.template_names.map(
      (item) => item.template_name
    );

    // Check if the template name already exists in the list
    if (templateNamesList.includes(state.template_name)) {
      // Display a confirmation dialog
      const isConfirmed = window.confirm(
        "This template name already exists. Do you want to overwrite it?"
      );

      // If the user clicks "OK", save the template and close; otherwise, do nothing
      if (isConfirmed) {
        props.saveScheduleAsTemplate(state.template_name);
        handleClose();
      }
    } else {
      // If the template name does not exist, save and close directly
      props.saveScheduleAsTemplate(state.template_name);
      handleClose();
    }
  }

  function agg_column(df, column) {
    return df.reduce(function (a, b) {
      return a + b[column];
    }, 0);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const currentDate = new Date();
  const currentDateFormatted = props.formatDate(currentDate);

  return (
    <div>
      <Tooltip title="Save As Template">
        <IconButton onClick={handleOpen}>
          <SaveIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div
              className="absolute right-2 top-2 text-gray-500 cursor-pointer"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
            <h3 className="text-xl">Save schedule as template</h3>
            {props.scheduled_hours && (
              <div className="w-full flex items-center mt-8 pb-5 border-b border-slate-200">
                <div className="w-1/2">
                  <p className="text-slate-500 text-xs">Total Hours</p>
                  <p className="text-slate-900">
                    {agg_column(props.scheduled_hours, "hours")}
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="text-slate-500 text-xs">Saved On Date</p>
                  <p className="text-slate-900">{currentDateFormatted}</p>
                </div>
              </div>
            )}
            <div className="w-full mt-5">
              <h3 className="text-slate-500 text-sm">Template Name</h3>
              <div className="mt-3">
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Type a template name"
                  name="template_name"
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "12px",
                      padding: "5px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                  value={state.template_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full flex items-center space-x-4 mt-6">
              <div
                className="px-5 py-2.5 rounded-full border border-slate-200 text-slate-400 hover:bg-slate-50 flex items-center justify-center cursor-pointer"
                onClick={handleClose}
              >
                <p>Cancel</p>
              </div>
              <div className="flex-1">
                {state.template_name.length > 0 ? (
                  <div
                    className="w-full py-2.5 rounded-full bg-violet-700 hover:bg-violet-800 text-white flex items-center justify-center cursor-pointer"
                    onClick={() => submitAndClose()}
                  >
                    <p>Save Template</p>
                  </div>
                ) : (
                  <div className="w-full py-2.5 rounded-full bg-slate-200 text-slate-400 flex items-center justify-center">
                    <p>Save Template</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SaveAsTemplateButton;
