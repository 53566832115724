import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function IntegrationInput(props) {


  // Function to handle input changes and update newIntegration state
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setNewIntegration(prevState => ({
      ...prevState,
      [name]: value
    }));

  };

  return (
    <div className="w-full bg-slate-50 rounded-xl p-4">
      <div className="flex items-center justify-between">
        <p className="text-slate-500 text-sm">Select integration</p>
        <div className="text-rose-800 cursor-pointer" onClick={props.hideIntegrationInput}>
          <DeleteOutlineOutlinedIcon style={{ fontSize: "22px" }} />
        </div>
      </div>
      <div className="mt-3 w-[90%]">
        <Select
          fullWidth
          name="provider"
          placeholder="Select integration"
          onChange={handleInputChange}
          size="small"
          sx={{
            borderRadius: "8px",
            paddingY: "1px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#6d28d9",
            },
            fontSize: "14px",
            backgroundColor: "white"
          }}
        >
          <MenuItem value="Toast">Toast</MenuItem>
          <MenuItem value="7 shifts">7Shifts</MenuItem>
        </Select>
      </div>
      <div className="w-[90%] border border-[#bdbdbd] hover:border-slate-900 flex items-center h-8 rounded-lg overflow-hidden mt-2 focus:border-violet-500">
        <div className="bg-slate-100 px-2 w-[100px] h-full flex items-center">
          <p className="text-slate-500 text-sm">Key ID:</p>
        </div>
        <div className="flex-1 bg-white px-2 h-full flex items-center">
          <input
            type="text"
            className="text-slate-900 text-sm w-full h-full px-2 outline-none"
            placeholder="Enter key id"
            name="key"
            onChange={handleInputChange}
          />
        </div>
      </div>
      {props.newIntegration?.provider === "7 shifts" &&
        <div className="w-[90%] border border-[#bdbdbd] hover:border-slate-900 flex items-center h-8 rounded-lg overflow-hidden mt-2">
          <div className="bg-slate-100 px-2 w-[100px] h-full flex items-center">
            <p className="text-slate-500 text-sm">Company ID:</p>
          </div>
          <div className="flex-1 bg-white px-2 h-full flex items-center">
            <input
              type="text"
              className="text-slate-900 text-sm w-full h-full px-2 outline-none"
              placeholder="Enter company id"
              name="company_key"
              onChange={handleInputChange}
            />
          </div>
        </div>
      }
    </div>
  );
}

export default IntegrationInput;
