import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TradeTargetCard from "./TradeTargetCard";
import { formatDateReadable } from "../../utilities/helpers";
import DateGrayLine from "../helpers/DateGrayLine";
import ButtonFilled from "../helpers/ButtonFilled";
import ButtonGrayed from "../helpers/ButtonGrayed";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 460,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  py: 4,
};

function ShiftDropAddNoteModal(props) {

  const [shiftDropNote, setShiftDropNote] = React.useState("")

  function handleChange(event) {
    setShiftDropNote(event.target.value)
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={props.handleClose}
          >
            <CloseIcon />
          </div>
          <div className="w-full mt-2 px-4">
            <h2 className="text-xl text-slate-900">Would you like to add a note?</h2>
            <p className="text-sm text-slate-500 mt-2">This will inform your teammates why you are dropping the shift.</p>
          </div>
          <div className="w-full h-[170px] px-4 mt-8">
            <div>
              <p className="text-sm text-slate-500">Add note (optional)</p>
              <div className="mt-1.5">
                <TextField
                  placeholder="Going camping over the weekend"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="note"
                  value={shiftDropNote}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[90px] absolute bottom-0 border-t border-slate-300 flex items-center justify-center bg-white rounded-b-xl">
            <div className="px-4 w-full">
              <ButtonFilled
                text="Add to pool"
                onClick={() => {
                    props.handleAddToPoolPress(shiftDropNote)
                    props.handleClose()
                  }
                }
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ShiftDropAddNoteModal;
