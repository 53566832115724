import React, { useState, useEffect } from 'react';
import { defaultProps } from "@blocknote/core";
import { createReactBlockSpec } from "@blocknote/react";
import { Menu } from "@mantine/core";
import { QuizOutlined as QuizOutlinedIcon } from '@mui/icons-material';
import "./styles.css";
import AddQuizToSectionModal from './AddQuizToSectionModal';
import TakeQuizModal from './TakeQuizModal';
import CompleteQuizModal from './CompleteQuizModal';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Check as CheckIcon } from "@mui/icons-material";

export const Quiz = (props) => {

  // const [hasDataLoaded, setHasDataLoaded] = React.useState(false)

  // React.useEffect(() => {
  //   if (props.block.id) {
  //     setHasDataLoaded(true)
  //   }
  // }, [props.block.id])


  const { block, editor } = props;
  const { quizSelected } = block.props;

  const [openSelectQuizModal, setOpenSelectQuizModal] = React.useState(false);
  const handleOpenSelectQuizModal = () => setOpenSelectQuizModal(true);
  const handleCloseSelectQuizModal = () => setOpenSelectQuizModal(false);

  const [openTakeQuizModal, setOpenTakeQuizModal] = React.useState(false);
  const handleOpenTakeQuizModal = () => setOpenTakeQuizModal(true);
  const handleCloseTakeQuizModal = () => setOpenTakeQuizModal(false);

  const handleQuizSelect = (selectedQuiz) => {
    editor.updateBlock(block, {
      ...block,
      props: {
        ...block.props,
        quizSelected: selectedQuiz,
      },
    });
  };

  const [isChecked, setIsChecked] = React.useState(quizSelected?.user_has_passed);


  // React.useEffect(() => {
  //   if (hasDataLoaded && props.quizzesData && block.id && editor) {
  //     const newQuizSelected = props.quizzesData?.find(quiz => quiz?.uuid === quizSelected?.uuid);
  //     if (newQuizSelected && hasDataLoaded) {
  //       handleQuizSelect(newQuizSelected)
  //     }
  //   }
  // }, [props.quizzesData, hasDataLoaded]);


  return (
    <>
      {!quizSelected ?
        <div
          className={"quiz bg-gray-200 hover:bg-gray-300 cursor-pointer relative"}
          onClick={handleOpenSelectQuizModal}
        >
          <div className="text-gray-600" contentEditable={false}>
            <QuizOutlinedIcon
              className={"quiz-icon-wrapper"}
              style={{ fontSize: 24 }}
            />
          </div>
          <div className="flex-1 text-gray-600 flex items-center text-sm" ref={props.contentRef} contentEditable={false}>
            Add quiz
          </div>
        </div> :
        (quizSelected.quiz_needs_manager_check === true && props.type !== "Preview") ?
          <div
            className={"white rounded-lg relative px-6 py-3 w-full border border-slate-200 flex items-center justify-between"}
            // onClick={handleOpenTakeQuizModal}
            // ref={props.contentRef}
            contentEditable={false}
          >
            <div className="flex-1">
              <h2 className="text-2xl text-slate-900 font-semibold">{quizSelected?.name}</h2>
              <p
                className="text-rose-800 w-3/4"
                style={{ marginTop: 2, fontSize: 12 }}
              >
                You have exhausted all your attempts and did not meet the passing percentage. To proceed, have your manager check off the box.
              </p>
              <div>
                <div className="flex items-center gap-x-2 mt-1.5">
                  <div>
                    <ChecklistIcon style={{ fontSize: 18 }} />
                  </div>
                  <p
                    className="text-slate-900 text-sm"
                    style={{ fontSize: 14 }}
                  >
                    {quizSelected?.learning_questions_count} {quizSelected?.learning_questions_count === 1 ? "question" : "questions"}
                  </p>
                </div>
                {quizSelected.pass_percentage > 0 &&
                  <div className="flex items-center gap-x-2 mt-1.5">
                    <div>
                      <FlagOutlinedIcon style={{ fontSize: 18 }} />
                    </div>
                    <p
                      className="text-slate-900 text-sm"
                      style={{ fontSize: 14 }}
                    >
                      {quizSelected?.pass_percentage}% pass percentage
                    </p>
                  </div>
                }
                <div className="flex items-center gap-x-2 mt-1.5">
                  <div>
                    <LockOutlinedIcon style={{ fontSize: 18 }} />
                  </div>
                  <p
                    className="text-slate-900 text-sm"
                    style={{ fontSize: 14 }}
                  >
                    {(quizSelected.retake_limit === null || quizSelected.retake_limit === 0) ?
                      `${quizSelected?.pass_attempt_count ? quizSelected?.pass_attempt_count : 0} / ∞ Attempts` :
                      `${quizSelected?.pass_attempt_count ? quizSelected?.pass_attempt_count : 0} / ${quizSelected?.retake_limit} attempts`
                    }
                  </p>
                </div>
              </div>
            </div>
            {!isChecked ?
              <div
                className={`w-10 h-10 border border-slate-200 rounded bg-white ${props.userType !== "Employee" ? "hover:bg-slate-50 cursor-pointer" : ""}`}
                onClick={() => {
                  if (props.userType !== "Employee") {
                    props.post_learning_block_user_passed(quizSelected.uuid, true, props.employeeID);
                    setIsChecked(true);
                  }
                }}
              ></div> :
              <div
                className="w-10 h-10 border border-white rounded flex items-center justify-center bg-emerald-600 text-white"
              >
                <CheckIcon style={{ fontSize: "26px" }} />
              </div>
            }
            <div className="absolute bottom-2 right-2 px-3 py-0.5 bg-rose-600 rounded-full text-white text-sm">Failed</div>
          </div> :
          <div
            className={"white rounded-lg cursor-pointer relative px-6 py-3 w-full border border-slate-200 hover:border-slate-900"}
            onClick={handleOpenTakeQuizModal}
            // ref={props.contentRef}
            contentEditable={false}
          >
            <h2 className="text-2xl text-slate-900 font-semibold">{quizSelected?.name}</h2>
            <p
              className="text-slate-500"
              style={{ marginTop: 2, fontSize: 12 }}
            >
              Click anywhere in this card to open quiz
            </p>
            <div>
              <div className="flex items-center gap-x-2 mt-1.5">
                <div>
                  <ChecklistIcon style={{ fontSize: 18 }} />
                </div>
                <p
                  className="text-slate-900 text-sm"
                  style={{ fontSize: 14 }}
                >
                  {quizSelected?.learning_questions_count} {quizSelected?.learning_questions_count === 1 ? "question" : "questions"}
                </p>
              </div>
              {quizSelected.pass_percentage > 0 &&
                <div className="flex items-center gap-x-2 mt-1.5">
                  <div>
                    <FlagOutlinedIcon style={{ fontSize: 18 }} />
                  </div>
                  <p
                    className="text-slate-900 text-sm"
                    style={{ fontSize: 14 }}
                  >
                    {quizSelected?.pass_percentage}% pass percentage
                  </p>
                </div>
              }
              <div className="flex items-center gap-x-2 mt-1.5">
                <div>
                  <LockOutlinedIcon style={{ fontSize: 18 }} />
                </div>
                <p
                  className="text-slate-900 text-sm"
                  style={{ fontSize: 14 }}
                >
                  {(quizSelected.retake_limit === null || quizSelected.retake_limit === 0) ?
                    `${quizSelected?.pass_attempt_count ? quizSelected?.pass_attempt_count : 0} / ∞ Attempts` :
                    `${quizSelected?.pass_attempt_count ? quizSelected?.pass_attempt_count : 0} / ${quizSelected?.retake_limit} attempts`
                  }
                </p>
              </div>
            </div>
            {quizSelected.user_has_passed && <div className="absolute bottom-2 right-2 px-3 py-0.5 bg-emerald-600 rounded-full text-white text-sm">Passed</div>}
          </div>
      }
      <AddQuizToSectionModal
        open={openSelectQuizModal}
        handleClose={handleCloseSelectQuizModal}
        quizSelected={quizSelected}
        handleQuizSelect={handleQuizSelect}
        quizzesData={props.quizzesData}
        homeCompany={props.homeCompany}
      />
      {/* <TakeQuizModal
        open={openTakeQuizModal}
        handleClose={handleCloseTakeQuizModal}
        quizSelected={quizSelected}
        homeCompany={props.homeCompany}
        post_learning_block_user_passed={props.post_learning_block_user_passed}
        employeeID={props.employeeID}
      /> */}
      <CompleteQuizModal
        open={openTakeQuizModal}
        handleClose={handleCloseTakeQuizModal}
        quizSelected={quizSelected}
        homeCompany={props.homeCompany}
        post_learning_block_user_passed={props.post_learning_block_user_passed}
        employeeID={props.employeeID}
        type={props.type}
        setRefreshSection={props.setRefreshSection}
      />
    </>
  );
};
