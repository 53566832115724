import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddTaskIcon from "@mui/icons-material/AddTask";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeploymentGraph } from "./visual_components/DeploymentGraph";
import { CoverageGraph } from "./visual_components/CoverageGraph";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import DailyPlan from "./visual_components/Deployment/DailyPlan";
import VisualCustomLineChart from "./VisualCustomLineChart";
import { generateLaborSummary, get_t_str } from "../../../../helpers/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -45%)",
  minWidth: 600,
  minHeight: 400,
  width: "95%",
  height: "80%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 5,
};

function VisualChartModal(props) {
  let data;
  let label1;
  let label2;
  if (props.earned_toggle) {
    label1 = "Earned";
    label2 = "Used";
    data = generateLaborSummary(
      props.calculated_earned_labor_day,
      props.actual_used_labor_vs_earned_day,
      get_t_str,
      label1,
      label2
    );
  } else {
    label1 = "Forecasted";
    label2 = "Scheduled";
    data = generateLaborSummary(
      props.forecasted_labor_day,
      props.actual_schedule_labor_day,
      get_t_str,
      label1,
      label2
    );
  }
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={props.handleClose}
          >
            <CancelIcon />
          </div>
          <VisualCustomLineChart
            data={data.summary}
            roleTypes={data.roleTypes}
            date_name={props.date_name}
            height={450}
            isTable={false}
            label1={label1}
            label2={label2}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default VisualChartModal;
