import React from "react";
import EmployeesTable from "../requests/manager/employees/EmployeesTable";
import {
  API_get_list_users,
  API_get_store_team_member,
  API_get_roles,
  API_store_team_member_set_hide,
  API_store_team_member_set_order,
  API_post_store_team_member,
  API_archive_store_team_member,
  API_update_team_member_info,
  API_post_certificate,
  API_delete_certificate,
  API_get_logbook_employee_entries,
  API_post_logbook_employee_entry,
  API_delete_logbook_employee_entry,
  API_get_file_upload,
  API_get_stores_company_manager,
  API_clone_or_reactivate_store_team_member,
  API_post_store_team_member_only_permission_type_and_store_id,
  API_create_availability_request
} from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import { formatDate } from "../requests/utilities/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../utilities/LoadSpinner";
import Account from "../home/top_bar_components/Account";
import StoreName from "../home/top_bar_components/StoreName";
import TopBarDisplayTitle from "../home/top_bar_components/TopBarDisplayTitle";
import TopBarDisplayUserProfile from "../home/top_bar_components/TopBarDisplayUserProfile";
import { getDefaultAxiosConfig } from "../requests/utilities/requests";
import axios from "axios";
import { Lightbox } from "react-modal-image";

function EmployeesInformationDisplay(props) {
  const [data, setData] = React.useState([]);
  const [selectedTeamMemberData, setSelectedTeamMemberData] = React.useState();
  const [selectedTeamMemberAvailabilityData, setSelectedTeamMemberAvailabilityData] = React.useState();
  const [relatedTeamMembersData, setRelatedTeamMembersData] = React.useState();
  const [selectedTeamMemberId, setSelectedTeamMemberId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [showLighbox, setShowLightbox] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(null)

  console.log("SELECTEDTEAMMEMBERID", selectedTeamMemberId);

  const default_api_calls = {
    users: API_get_list_users,
    roleNames: API_get_roles,
    storeList: API_get_stores_company_manager
  };

  // Get the current date
  let currentDate = new Date();
  // Get the start date of the previous month
  let lowerBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  // Get the end date of the next month
  let upperBoundDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    0
  );

  let lowerBoundDateStr = formatDate(lowerBoundDate);
  let upperBoundDateStr = formatDate(upperBoundDate);

  let api_params = {
    store_id: props.homeStore,
    company_id: props.homeCompany,
    lower_bound_date: lowerBoundDateStr,
    upper_bound_date: upperBoundDateStr,
    specific_date: formatDate(currentDate),
    date_start: formatDate(currentDate),
    date_end: formatDate(currentDate),
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setIsLoading(false);
      setData(tempData);
    } catch (error) {
      console.log(error);
      //throw Error("Promise failed");
    }
  };

  const fetchStoreTeamMember = async (showLoading, stm_id) => {
    setIsLoading(showLoading);
    let api_params = {
      store_id: props.homeStore,
      stm_id: stm_id,
    };
    let api = API_get_store_team_member;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        console.log("STORE TEAM MEMBER", res.data.redux?.payload?.employee);
        setSelectedTeamMemberData(res.data.redux?.payload?.employee);
        setSelectedTeamMemberAvailabilityData(res.data.redux?.payload?.employee_availability);
        setRelatedTeamMembersData(res.data.redux?.payload?.related_store_team_members)
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };


  // ----------- POST APIs ----------- //
  const hide_store_team_member = async (store_team_member_id, hidden) => {
    // Prepare data to send
    const requestData = {
      store_team_member_id: store_team_member_id,
      hidden: hidden,
    };

    try {
      toast.loading("Updating Employee...", { autoClose: false });
      const res = await postRightworkAPI(
        API_store_team_member_set_hide,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Employee Updated Successfully!", { autoClose: 3000 });
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
        // Further handle success case as per your requirement
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  const store_team_member_set_order = async (store_team_member_settings) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      store_team_member_settings: JSON.stringify(store_team_member_settings),
    };

    try {
      toast.loading("Setting Employees Order...", { autoClose: false });
      const res = await postRightworkAPI(
        API_store_team_member_set_order,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Order Set Successfully!", { autoClose: 3000 });
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
        // Further handle success case as per your requirement
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Request Failed", { autoClose: 3000 });
      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_store_team_member = async (
    first_name,
    last_name,
    email,
    phone_number,
    permission_type,
    stm_id,
    clock_pin,
    date_of_birth
  ) => {
    // Prepare data to send
    const requestData = {
      stm_id: stm_id,
      store_id: props.homeStore,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
      permission_type: permission_type,
      clock_pin: clock_pin,
      date_of_birth: date_of_birth,
    };

    console.log("Company ID", requestData);

    try {
      toast.loading("Updating Employee...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_store_team_member,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Employee Updated!", { autoClose: 3000 });
        if (selectedTeamMemberId) {
          fetchStoreTeamMember(false, selectedTeamMemberId);
        }
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const archive_store_team_member = async (store_id, stm_id) => {
    // Prepare data to send
    const requestData = {
      store_id: store_id,
      stm_id: stm_id,
      company_id: props.homeCompany
    };

    try {
      toast.loading("Deleting Access...", { autoClose: false });

      const res = await postRightworkAPI(
        API_archive_store_team_member,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Access deleted!", { autoClose: 3000 });

        fetchStoreTeamMember(false, selectedTeamMemberId);
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const updatePersonInfo = async (data) => {
    try {
      toast.loading("Updating Employee Attributes...", { autoClose: false });

      const axiosConfig = await getDefaultAxiosConfig();
      const res = await axios.put(
        API_update_team_member_info + props.homeStore,
        data,
        axiosConfig
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Employee Attributes Updated!", { autoClose: 3000 });

        fetchStoreTeamMember(false, selectedTeamMemberId);
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_certificate = async (
    store_team_member_id,
    certificate_uuid,
    type,
    expiration_date,
    image
  ) => {
    const formData = new FormData();

    formData.append("store_id", props.homeStore);
    formData.append("store_team_member_id", store_team_member_id);
    formData.append("certificate_uuid", certificate_uuid);
    formData.append("type", type);
    formData.append("expiration_date", expiration_date);
    formData.append("image", image);

    try {
      toast.loading("Updating Certificate...", { autoClose: false });

      const axiosConfig = await getDefaultAxiosConfig();

      const res = await axios.post(API_post_certificate, formData, {
        headers: {
          ...axiosConfig.headers, // Merge default axios headers with multipart/form-data headers
          "Content-Type": "multipart/form-data", // Set content type for file upload
        },
      });

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Certificate Updated!", { autoClose: 3000 });

        fetchStoreTeamMember(false, selectedTeamMemberId);
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const delete_certificate = async (store_team_member_id, certificate_uuid) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      store_team_member_id: store_team_member_id,
      certificate_uuid: certificate_uuid,
    };

    try {
      toast.loading("Deleting Certificate...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_certificate, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Certificate Deleted!", { autoClose: 3000 });

        fetchStoreTeamMember(false, selectedTeamMemberId);
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // [GET] api/file_upload_info/get_file_upload
  // * file_upload_uuid
  const get_file_upload = async (file_upload_uuid) => {
    let api_params = {
      file_upload_uuid: file_upload_uuid,
    };
    try {
      const res = await getRightworkAPI(API_get_file_upload, api_params);
      if (res.status === 200) {
        setSelectedImage(res.data.redux?.payload?.presigned_url);
        setShowLightbox(true)
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  // [POST] api/logbook_info/post_logbook_employee_entry
  // * store_team_member_id (auth)
  // * value_text
  // * file_upload_uuids (optional)
  //     JSON.stringify(["08h0-2fuinf2-2fjin-2ff2f"])
  const post_logbook_employee_entry = async (store_team_member_id, value_text, file_upload_uuids) => {
    const requestData = {
      store_team_member_id: store_team_member_id,
      value_text: value_text,
      file_upload_uuids: JSON.stringify(file_upload_uuids),
    };

    try {
      toast.loading("Saving Note...", { autoClose: false });

      const res = await postRightworkAPI(API_post_logbook_employee_entry, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Note Saved Successfully", { autoClose: 3000 });
        fetchStoreTeamMember(false, selectedTeamMemberId);
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();
      // handlePostError(error);
    }
  };

  // [POST] api/logbook_info/delete_logbook_employee_entry
  // * store_team_member_id (auth)
  // * logbook_employee_entry_uuid
  const delete_logbook_employee_entry = async (store_team_member_id, logbook_employee_entry_uuid) => {
    const requestData = {
      store_team_member_id: store_team_member_id,
      logbook_employee_entry_uuid: logbook_employee_entry_uuid,
    };

    try {
      toast.loading("Deleting Note...", { autoClose: false });

      const res = await postRightworkAPI(API_delete_logbook_employee_entry, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Note Deleted Successfully", { autoClose: 3000 });
        fetchStoreTeamMember(false, selectedTeamMemberId);
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();
      // handlePostError(error);
    }
  };


  // POST /api/user_info/clone_or_reactivate_store_team_member
  // Args:
  //     stm_id  (int):  ID of STM to be "cloned"
  //     store_id (int):  STM will be "cloned" to this store
  //     permission_type (str): The permission type that the new STM will have
  const clone_or_reactivate_store_team_member = async (
    stm_id,
    store_id,
    permission_type,
  ) => {
    // Prepare data to send
    const requestData = {
      stm_id: stm_id,
      store_id: store_id,
      permission_type: permission_type,
      company_id: props.homeCompany
    };

    try {
      toast.loading("Adding Access...", { autoClose: false });

      const res = await postRightworkAPI(
        API_clone_or_reactivate_store_team_member,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Access Added!", { autoClose: 3000 });
        if (selectedTeamMemberId) {
          fetchStoreTeamMember(false, selectedTeamMemberId);
        }
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // POST /api/user_info/post_store_team_member_only_permission_type_and_store_id
  //   Args:
  //       stm_id  (int):  ID of STM to be updated
  //       store_id (int):  New store_id for the STM
  //       permission_type (str): New permission type for the STM
  const post_store_team_member_only_permission_type_and_store_id = async (
    stm_id,
    store_id,
    permission_type,
  ) => {
    // Prepare data to send
    const requestData = {
      stm_id: stm_id,
      store_id: store_id,
      permission_type: permission_type,
      company_id: props.homeCompany
    };


    try {
      toast.loading("Updating Access...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_store_team_member_only_permission_type_and_store_id,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Access Updated!", { autoClose: 3000 });
        if (selectedTeamMemberId) {
          fetchStoreTeamMember(false, selectedTeamMemberId);
        }
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const create_availability_request = async (data) => {
    /*
    store_id = request.data.get('store_id')
    dow = request.data.get('dow')
    available_from = request.data.get('available_from')
    available_to = request.data.get('available_to')
    note = request.data.get('note')
    user_id = request.user.id
    */

    // Prepare data to send
    let requestData = data;
    requestData["store_id"] = props.homeStore;

    try {
      toast.loading("Creating Availability...", { autoClose: false });
      const res = await postRightworkAPI(
        API_create_availability_request,
        requestData
      );

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Availability Created Successfully!", { autoClose: 3000 });

        fetchStoreTeamMember(false, selectedTeamMemberId);
        fetch_data(
          {
            users: API_get_list_users,
          },
          api_params
        );
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
    setShowUserProfile(false);
  }, [props.homeStore]);

  const userData = data.users?.redux?.payload?.employees;

  const storeList = data.storeList?.redux?.payload?.stores;

  // State for user profile

  const [showUserProfile, setShowUserProfile] = React.useState(false);

  const handleGoBack = () => {
    setShowUserProfile(false);
  };



  console.log("SELECTED EMPLOYEE", selectedTeamMemberData);
  console.log("EMPLOYEE AVAIL", selectedTeamMemberAvailabilityData)
  console.log("STORE LIST", storeList)

  return (
    <>
      {showLighbox &&
        <div className="absolute w-screen h-screen z-40">
          <Lightbox
            small={selectedImage}
            medium={selectedImage}
            // large={selectedImage}
            hideDownload={true}
            hideZoom={true}
            onClose={() => setShowLightbox(false)}
          />
        </div>
      }
      {!isLoading ? (
        <div>
          {showUserProfile ? (
            <div>
              <TopBarDisplayUserProfile
                title="Back to employees"
                storeOptions={props.storeOptions}
                homeStore={props.homeStore}
                storeNames={props.storeNames}
                change_home_store={props.change_home_store}
                handleGoBack={handleGoBack}
              />
            </div>
          ) : (
            <div>
              <TopBarDisplayTitle
                title="Employees"
                storeOptions={props.storeOptions}
                homeStore={props.homeStore}
                storeNames={props.storeNames}
                change_home_store={props.change_home_store}
              />
            </div>
          )}
          <div>
            <EmployeesTable
              employees={userData}
              hide_store_team_member={hide_store_team_member}
              store_team_member_set_order={store_team_member_set_order}
              post_store_team_member={post_store_team_member}
              archive_store_team_member={archive_store_team_member}
              roleNames={data.roleNames}
              timeEntriesEnabled={props.timeEntriesEnabled}
              showUserProfile={showUserProfile}
              setShowUserProfile={setShowUserProfile}
              fetchStoreTeamMember={fetchStoreTeamMember}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              updatePersonInfo={updatePersonInfo}
              post_certificate={post_certificate}
              delete_certificate={delete_certificate}
              selectedTeamMemberData={selectedTeamMemberData}
              selectedTeamMemberAvailabilityData={selectedTeamMemberAvailabilityData}
              relatedTeamMembersData={relatedTeamMembersData}
              setSelectedTeamMemberId={setSelectedTeamMemberId}
              post_logbook_employee_entry={post_logbook_employee_entry}
              delete_logbook_employee_entry={delete_logbook_employee_entry}
              get_file_upload={get_file_upload}
              homeCompany={props.homeCompany}
              storeList={storeList}
              clone_or_reactivate_store_team_member={clone_or_reactivate_store_team_member}
              post_store_team_member_only_permission_type_and_store_id={post_store_team_member_only_permission_type_and_store_id}
              create_availability_request={create_availability_request}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  );
}

export default EmployeesInformationDisplay;
