import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ImportButton from "./ImportButton.js";
import DownloadButton from "./DownloadButton.js";
import Papa from "papaparse";
import TableRow from "./TableRow.js";
import TableRowNew from "./TableRowNew.js";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const performSort = (df, propertyName, asc) => {
  df.sort((a, b) => {
    if (asc) {
      if (a[propertyName] < b[propertyName]) {
        return -1;
      }
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    } else {
      if (a[propertyName] > b[propertyName]) {
        return -1;
      }
      if (a[propertyName] < b[propertyName]) {
        return 1;
      }
      return 0;
    }
  });
  return df;
};

export default function Table(props) {
  const [formVisible, setFormVisible] = React.useState(false);
  const [importVisible, setImportVisible] = React.useState(false);
  const [sortColumn, setSortColumn] = React.useState(
    props.schema_info[props.display]["order_by"]
  );
  const [sortAscending, setSortAscending] = React.useState(true);
  const [data, setData] = React.useState(
    performSort(
      props.settingsData[props.display],
      props.schema_info[props.display]["order_by"],
      true
    )
  );

  useEffect(() => {
    let sort_column = props.schema_info[props.display]["order_by"];
    setSortColumn(sort_column);
    setData(performSort(props.settingsData[props.display], sort_column, true));
    setFormVisible(false);
    setImportVisible(false);
    setSortAscending(true);
  }, [props.display, props.tableLoading]);

  const table_info = props.schema_info[props.display];
  const table = table_info["table"];
  const title = table_info["title"];
  const download_name = title + ".csv";
  const description = table_info["description"];
  const column_info = table_info["column_info"];
  const get_api = table_info["get_api"];
  const put_api = table_info["put_api"];
  const add_row = table_info["add_row"];
  const tableLoading = props.tableLoading;
  const edit_settings = props.edit_settings;

  function handleImportClick() {
    setImportVisible((prevState) => !prevState);
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const data = reader.result;
      const parsedData = Papa.parse(data, {
        header: true,
        dynamicTyping: true,
        // transformHeader: header => header.toLowerCase().replace(/\W/g, '')
      });
      props.setTasks(parsedData.data);
    };
    reader.onerror = (error) => {
      console.log(error);
    };
  };

  const sortBy = (data, propertyName) => {
    let asc = sortAscending;
    if (propertyName !== sortColumn) {
      setSortColumn(propertyName);
      asc = true;
    } else {
      asc = !asc;
    }

    const df = performSort(data, propertyName, asc);
    setSortAscending(asc);
    setData([...df]);
  };

  const downloadCSV = () => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", download_name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  function handleAddRow() {
    setFormVisible((prevState) => !prevState);
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  return (
    <div>
      <div className="w-full h-12 mt-10 flex justify-between items-center">
        <div onClick={handleImportClick}>
          <ImportButton />
        </div>
        <div className="flex">
          <div onClick={() => downloadCSV(data)}>
            <DownloadButton />
          </div>
          {add_row && (
            <button
              className="border border-gray-500 px-8 py-2 ml-4 rounded flex items-center gap-2 hover:border-2 hover:border-blue-900"
              onClick={() => handleAddRow()}
            >
              {formVisible ? (
                <RemoveIcon fontSize="small" />
              ) : (
                <AddIcon fontSize="small" />
              )}
              {formVisible ? "hide new row" : "add row"}
            </button>
          )}
        </div>
      </div>
      <div className="w-full">
        {importVisible && (
          <div className="mt-2">
            <label
              for="file-upload"
              className="bg-blue-900 text-white rounded px-4 py-2 text-sm"
            >
              Choose File
            </label>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleFileSelect}
            />
          </div>
        )}
        <div className="sm:flex sm:items-center mt-10">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
            <p className="mt-2 text-sm text-gray-700">{description}</p>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <table className="min-w-full divide-y">
                <thead>
                  <tr style={{ backgroundColor: "#1C2541" }}>
                    {column_info.map((c, i) => {
                      return (
                        <th
                          scope="col"
                          className={
                            "text-left text-xs font-semibold text-white py-3.5 w-" +
                            c.width +
                            " " +
                            c.header_css
                          }
                        >
                          <button onClick={() => sortBy(data, c.column_name)}>
                            {c.header}
                          </button>
                          {sortColumn === c.column_name ? (
                            sortAscending ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )
                          ) : (
                            ""
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                {data.map((row, index) => {
                  return (
                    <tbody className="divide-y divide-gray-300">
                      <TableRow
                        key={index}
                        row={row}
                        index={index}
                        column_info={column_info}
                        table={table}
                        edit_settings={edit_settings}
                        put_api={put_api}
                        get_api={get_api}
                      />
                    </tbody>
                  );
                })}
                {formVisible && (
                  <TableRowNew
                    column_info={column_info}
                    table={table}
                    edit_settings={edit_settings}
                    put_api={put_api}
                    get_api={get_api}
                    setFormVisible={setFormVisible}
                  />
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
