import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import SectionInputsModal from "./SectionInputsModal";
import SectionInputsModal2 from "./SectionInputsModal2";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DeleteWarningModal from "./DeleteWarningModal";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ModuleInputsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [openSectionInputsModal, setOpenSectionInputsModal] =
    React.useState(false);
  const handleOpenSectionInputsModal = () => setOpenSectionInputsModal(true);
  const handleCloseSectionInputsModal = () => setOpenSectionInputsModal(false);

  const [openDeleteWarningModal, setOpenDeleteWarningModal] =
    React.useState(false);
  const handleOpenDeleteWarningModal = () => setOpenDeleteWarningModal(true);
  const handleCloseDeleteWarningModal = () => setOpenDeleteWarningModal(false);

  function handleColorSelection(color) {
    props.setModalDataModule({
      ...props.modalDataModule,
      color: color,
    });
  }

  function handleChange(event) {
    const { name, value } = event.target;
    props.setModalDataModule((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  }

  const [selectedSections, setSelectedSections] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    if (props.open) {
      const initialSelectedSections = props.modalDataModule.sections.map(
        (section) => section.name
      );
      setSelectedSections(initialSelectedSections);
    }
  }, [props.open]);

  function addSelectedSectionToModalData(sectionNames) {
    const newSections = sectionNames
      .map((sectionName) =>
        props.sectionsData.find((section) => section.name === sectionName)
      )
      .filter(Boolean);

    const uniqueSections = [
      ...new Map(newSections.map((item) => [item.name, item])).values(),
    ];

    props.setModalDataModule((prevModalData) => ({
      ...prevModalData,
      sections: uniqueSections,
    }));
  }

  function removeSectionFromModalData(index) {
    const updatedSections = props.modalDataModule.sections.filter(
      (_, i) => i !== index
    );
    props.setModalDataModule((prevModalData) => ({
      ...prevModalData,
      sections: updatedSections,
    }));

    // Update selectedSections state to reflect the change
    const updatedSelectedSections = updatedSections.map(
      (section) => section.name
    );
    setSelectedSections(updatedSelectedSections);
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const reorderedSections = Array.from(props.modalDataModule.sections);
    const [movedSection] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, movedSection);

    props.setModalDataModule((prevModalData) => ({
      ...prevModalData,
      sections: reorderedSections,
    }));
  }

  React.useEffect(() => {
    if (props.type === "Preview" && props.open) {
      props.removeAllBlocks();
    }
  }, [props.modalDataModule]);

  console.log("MODAL DATA MODULE MODAL", props.modalDataModule);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          setSelectedSections([]);
          setInputValue("");
          if (props.type === "Preview") {
            props.removeAllBlocks();
            props.setModalDataModule(props.selectedModule);
          }
          // props.handleClose();
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => {
              setSelectedSections([]);
              setInputValue("");
              if (props.type === "Preview") {
                props.removeAllBlocks();
                props.setModalDataModule(props.selectedModule);
              }
              props.handleClose();
            }}
          >
            <CloseIcon />
          </div>
          <div>
            <div>
              <h3 className="text-2xl text-slate-900">
                {props.modalEdit ? "Edit Module" : "Create Module"}
              </h3>
            </div>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Module Name</p>
              <div className="mt-1.5">
                <TextField
                  placeholder="What do you want to name this module? (i.e. Server Level 1)"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={props.modalDataModule?.name}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Module Color</p>
              <div className="mt-1 w-full">
                <div className="w-full flex space-x-2">
                  {[
                    "bg-slate-400",
                    "bg-violet-600",
                    "bg-sky-700",
                    "bg-cyan-600",
                    "bg-emerald-600",
                    "bg-lime-600",
                    "bg-amber-500",
                    "bg-orange-600",
                    "bg-red-600",
                    "bg-pink-600",
                  ].map((color) => (
                    <div
                      key={color}
                      className={`w-10 h-10 rounded-md flex items-center justify-center ${
                        props.modalDataModule?.color === color
                          ? "border border-slate-300"
                          : ""
                      } cursor-pointer`}
                      onClick={() => handleColorSelection(color)}
                    >
                      <div
                        className={`w-6 h-6 rounded-full flex items-center justify-center ${color}`}
                      >
                        {props.modalDataModule?.color === color && (
                          <div className="text-white">
                            <CheckIcon
                              style={{ fontSize: "18px", marginBottom: "2px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Sections</p>
              <div
                className={`w-full ${
                  props.modalDataModule?.sections?.length > 0
                    ? "mt-1.5 pb-5"
                    : "mt-1.5 pb-5"
                }`}
              >
                <div className="flex-1">
                  <div className="w-full">
                    <Autocomplete
                      multiple
                      fullWidth
                      value={selectedSections}
                      onChange={(event, newValue) => {
                        if (newValue.includes("+ CREATE NEW SECTION")) {
                          props.setModalDataSection(props.default_data_section);
                          handleOpenSectionInputsModal();
                        } else {
                          setSelectedSections(newValue);
                          addSelectedSectionToModalData(newValue);
                        }
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      disableClearable
                      disableCloseOnSelect
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      }}
                      options={[
                        "+ CREATE NEW SECTION",
                        ...(props.sectionsData?.map(
                          (section) => section.name
                        ) || []),
                      ]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="sections"
                          variant="outlined"
                          size="small"
                          placeholder="Select sections to add to module"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "8px !important",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#6d28d9",
                                },
                              backgroundColor: "white",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option, { selected }) =>
                        option === "+ CREATE NEW SECTION" ? (
                          <li
                            {...props}
                            style={{
                              backgroundColor: "#f5f3ff",
                              color: "#6d28d9",
                              fontWeight: "bold",
                            }}
                          >
                            {option}
                          </li>
                        ) : (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              sx={{
                                color: "#6d28d9",
                                "&.Mui-checked": {
                                  color: "#6d28d9",
                                },
                              }}
                            />
                            {option}
                          </li>
                        )
                      }
                      renderTags={() => null} // Hide the selected values in the input box
                    />
                  </div>
                </div>
              </div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="sections">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="bg-slate-50"
                    >
                      {props.modalDataModule?.sections?.map(
                        (section, index) => (
                          <Draggable
                            key={section.name}
                            draggableId={section.name}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  left: "auto !important",
                                  top: "auto !important",
                                }}
                                className={`py-3 border-b border-slate-200 flex ${
                                  snapshot.isDragging
                                    ? "bg-slate-200"
                                    : "bg-white"
                                }`}
                              >
                                <div
                                  className={`w-[8%] text-slate-500 cursor-pointer`}
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorIcon />
                                </div>
                                <p className="w-[12%]">{index + 1}</p>
                                <p className="flex-1">{section.name}</p>
                                <div
                                  className="text-slate-500 cursor-pointer"
                                  onClick={() =>
                                    removeSectionFromModalData(index)
                                  }
                                >
                                  <CloseIcon style={{ fontSize: "22px" }} />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="w-full mt-6 flex justify-end">
              <div className="flex items-center space-x-2">
                {props.modalEdit && props.type !== "Preview" ? (
                  <div
                    className="px-10 py-2.5 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      setSelectedSections([]);
                      handleOpenDeleteWarningModal();
                    }}
                  >
                    Delete Module
                  </div>
                ) : (
                  <div
                    className="px-10 py-2.5 border border-violet-700 bg-white hover:bg-slate-50 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      setSelectedSections([]);
                      if (props.type === "Preview") {
                        props.removeAllBlocks();
                        props.setModalDataModule(props.selectedModule);
                      }
                      props.handleClose();
                    }}
                  >
                    Cancel
                  </div>
                )}
                <div
                  className="px-10 py-2.5 bg-violet-700 hover:bg-violet-800 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.post_learning_module();
                    setSelectedSections([]);
                    if (props.type === "Preview") {
                      props.removeAllBlocks();
                      props.setSelectedModule(props.modalDataModule);
                    }
                    props.handleClose();
                  }}
                >
                  Save Module
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <SectionInputsModal2
        open={openSectionInputsModal}
        handleClose={handleCloseSectionInputsModal}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        default_data_section={props.default_data_section}
        sectionsData={props.sectionsData}
        post_learning_section={props.post_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
        homeCompany={props.homeCompany}
      />
      <DeleteWarningModal
        open={openDeleteWarningModal}
        handleClose={handleCloseDeleteWarningModal}
        type="module"
        delete_function={props.delete_learning_module}
        handleCloseInputsModal={props.handleClose}
      />
    </>
  );
}

export default ModuleInputsModal;
