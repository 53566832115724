import React from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import SaveAsIcon from '@mui/icons-material/SaveAs';

export default function SaveAsModal(props) {
  return(
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={props.style}>
          <div className="w-full flex flex-col justify-center items-center px-4">
            <h2 className="text-2xl text-center font-bold">Save As</h2>
            <p className="text-base text-center mt-5">What would you like to name the new labor model?</p>
            <div className="mt-4">
              <TextField id="outlined-basic" label="Labor Model Name" variant="outlined" size="small" style={{ width: "300px" }}/>
            </div>
            <div className="mt-7">
              <Button variant="contained" className="flex items-center gap-2"><SaveAsIcon />Save As</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
