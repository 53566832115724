import React, { useState, useEffect } from "react";
import ScheduledNameCard2 from "./ScheduleNameCard2";
import TaskNameCard from "./TaskNameCard";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { formatDate } from "../../requests/utilities/helpers";

function DailyPlanInfo2(props) {
  const [unassignedTasks, setUnassignedTasks] = useState([]);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [tasksCompleted, setTasksCompleted] = useState(0);
  const [tasksInProgress, setTasksInProgress] = useState(0);
  const [tasksOverdue, setTasksOverdue] = useState(0);

  useEffect(() => {
    const unassigned = props.dailyTasks?.filter((task) => (task.plan_task_assignments?.length === 0 || task.plan_task_assignments?.[0]?.store_team_member === null)) || [];
    const assigned = props.dailyTasks?.filter((task) => (task.plan_task_assignments?.length > 0 && task.plan_task_assignments?.[0]?.store_team_member !== null)) || [];

    setUnassignedTasks(unassigned);
    setAssignedTasks(assigned);

    setTasksCompleted((assigned.filter(task => task.plan_task_assignments?.[0]?.status === "completed").length) + (assigned.filter(task => task.plan_task_assignments?.[0]?.status === "completed_late").length));
    setTasksInProgress(assigned.filter(task => task.plan_task_assignments?.[0]?.status === "in_progress").length);
    setTasksOverdue(assigned.filter(task => task.plan_task_assignments?.[0]?.status === "overdue").length);
  }, [props.dailyTasks, props.date]);

  const employeesWorking = props.combinedData;

  const groupedEmployeesWorkingByRoleType = employeesWorking.reduce((acc, item) => {
    if (item.publishedschedule !== null) {
      const roleType = item.role_type;
      if (!acc[roleType]) {
        acc[roleType] = [];
      }
      acc[roleType].push(item);
    }
    return acc;
  }, {});

  const groupedUnassignedTasks = unassignedTasks.reduce((acc, task) => {
    const category = task.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(task);
    return acc;
  }, {});

  const isTaskEligible = (task, employee) => {
    // If either time bound is null, task is always eligible
    if (task.lower_bound_qhnum === null || task.upper_bound_qhnum === null) {
      return true;
    }

    const taskStart = new Date(task.lower_bound_qhnum).getTime();
    const taskEnd = new Date(task.upper_bound_qhnum).getTime();
    const shiftStart = new Date(employee.shift_start).getTime();
    const shiftEnd = new Date(employee.shift_end).getTime();

    return (taskStart >= shiftStart && taskStart < shiftEnd) ||
           (taskEnd > shiftStart && taskEnd <= shiftEnd) ||
           (taskStart <= shiftStart && taskEnd >= shiftEnd);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    const task = unassignedTasks.find((task) => task.uuid === draggableId);

    if (task && destination.droppableId.startsWith("employee-")) {
      const employeeId = destination.droppableId.split('-')[1];
      const employee = employeesWorking.find(emp => emp.id.toString() === employeeId);

      if (employee && isTaskEligible(task, employee)) {
        const updatedTask = {
          ...task,
          plan_task_assignments: [{
            store_team_member: {
              id: employee.store_team_member
            }
          }]
        };

        setUnassignedTasks(prevUnassignedTasks =>
          prevUnassignedTasks.filter(t => t.uuid !== task.uuid)
        );
        setAssignedTasks(prevAssignedTasks => [...prevAssignedTasks, updatedTask]);

        props.assign_plan_task(task.uuid, formatDate(props.date), employee.store_team_member, employee.publishedschedule.id)
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex">
        <div className="w-[22%] mr-20">
          <div className="mb-10">
            {Object.entries(groupedUnassignedTasks).map(([category, tasks]) => (
              <div key={category} className="mb-6">
                <div className="w-full flex items-center justify-between">
                <h3 className="mb-1 text-slate-500">{category}</h3>
                <p className="text-sm text-slate-500">{tasks.length}</p>
              </div>
                <Droppable droppableId={`category-${category}`}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {tasks.map((task, index) => (
                        <Draggable key={task.uuid} draggableId={task.uuid} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="mt-2"
                            >
                              <TaskNameCard task={task} isAssigned={false} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </div>
        <div className="w-[78%] mt-2">
          <div className="flex items-center gap-x-5 mb-10">
            <div className="p-4 border border-slate-200 rounded-lg min-w-[180px]">
              <p className="text-sm text-slate-500">Tasks Completed</p>
              <p className="text-2xl text-slate-900 mt-2">{tasksCompleted} / {assignedTasks.length}</p>
            </div>
            <div className="p-4 border border-slate-200 rounded-lg min-w-[180px]">
              <p className="text-sm text-slate-500">In Progress</p>
              <p className="text-2xl text-slate-900 mt-2">{tasksInProgress}</p>
            </div>
            <div className="p-4 border border-slate-200 rounded-lg min-w-[180px]">
              <p className="text-sm text-slate-500">Overdue</p>
              <p className="text-2xl text-slate-900 mt-2">{tasksOverdue}</p>
            </div>
          </div>
          {Object.keys(groupedEmployeesWorkingByRoleType).map((roleType, index) => (
            <div className="mt-8" key={index}>
              <h2 className="text-2xl text-slate-500">{roleType}</h2>
              <div className="mt-2 flex flex-wrap gap-2">
                {groupedEmployeesWorkingByRoleType[roleType].map((employee) => (
                  <Droppable key={employee.id} droppableId={`employee-${employee.id}`}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps} className="mt-2">
                        <ScheduledNameCard2
                          employee={employee}
                          assignedTasks={assignedTasks.filter((task) => task?.plan_task_assignments?.[0]?.store_team_member?.id === employee.store_team_member)}
                          snapshot={snapshot}
                          roleNames={props.roleNames}
                          isTaskEligible={isTaskEligible}
                          unassignedTasks={unassignedTasks}
                          unassign_plan_task={props.unassign_plan_task}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </DragDropContext>
  );
}

export default DailyPlanInfo2;
