import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";

function ShiftPickupModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const handlePickup = () => {
    props.create_pickup_request(props.shiftInfo.id);
    props.handleClose();
  };

  function handleRescindPickupShift() {
    // Need to get uuid
    props.rescind_pickup_shift(props.shiftInfo.id);
    props.handleClose();
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-4 top-4 text-gray-500 cursor-pointer"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </div>
        <h2 className="text-2xl">Selected Shift</h2>
        <div>
          <div className="mt-3">
            <div className="w-full rounded-2xl bg-white border border-slate-200 px-5 py-6 flex flex-row items-center relative">
              <div className="flex-1">
                <div>
                  <p className="text-slate-600">{props.date}</p>
                </div>
                <div className="flex flex-row items-center mt-1.5">
                  <p className="text-slate-900 text-[28px] font-bold">
                    {props.startShiftNumber}
                  </p>
                  <p className="text-slate-600 text-lg ml-1">
                    {props.startShiftLetters}
                  </p>
                  <div className="ml-3 text-slate-600">
                    <ArrowForwardIcon color="#475569" size={22} />
                  </div>
                  <p className="text-slate-900 text-[28px] font-bold ml-3">
                    {props.endShiftNumber}
                  </p>
                  <p className="text-slate-600 text-lg ml-1">
                    {props.endShiftLetters}
                  </p>
                </div>
                <div className="mt-1.5">
                  <p className="text-slate-600">
                    {props.role} @ {props.location}
                  </p>
                </div>
              </div>
              {props.badgeText && (
                <div className="bg-amber-50 px-2 py-1 rounded-lg absolute bottom-2 right-2 flex items-center space-x-1">
                  {props.badgeText === "Pickup Requested" && (
                    <Tooltip title={"Cancel Pickup Request"}>
                      <button
                        className="flex items-center justify-center text-red-600"
                        onClick={() => handleRescindPickupShift()}
                      >
                        <HighlightOffIcon style={{ fontSize: "16px" }} />
                      </button>
                    </Tooltip>
                  )}
                  <p className="text-amber-800 text-sm">{props.badgeText}</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-full mt-6 flex justify-end">
            <div className="flex items-center gap-5">
              {props.badgeText !== "Your Shift" &&
              props.badgeText !== "Pickup Requested" ? (
                <button
                  className="px-8 py-3 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm"
                  onClick={() => handlePickup()}
                >
                  Request Pickup
                </button>
              ) : (
                <div className="px-8 py-3 rounded-lg bg-gray-200 text-gray-400 text-sm">
                  Request Pickup
                </div>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ShiftPickupModal;
