import React from "react";
import Pill from "../../shared/helpers/Pill";
import { formatDateReadable } from "../../utilities/helpers";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PendingTableEmployeeRowShiftModal from "./PendingTableEmployeeRowShiftModal";
import PendingTableEmployeeRowShiftDropDrawer from "./PendingTableEmployeeRowShiftDropDrawer";
import PendingTableEmployeeRowShiftPickupDrawer from "./PendingTableEmployeeRowShiftPickupDrawer";

function PendingTableEmployeeRowShift(props) {
  let cardStatus = "";
  if (
    props.rescinded_by === "employee-drop" &&
    props.requestType === "Pickup Request"
  ) {
    cardStatus = "rescinded (them)";
  } else if (
    props.rescinded_by === "employee-drop" &&
    props.requestType === "Add to Pool Request"
  ) {
    cardStatus = "rescinded";
  } else if (props.rescinded_by === "employee-pickup") {
    cardStatus = "rescinded";
  } else if (props.acting_manager_response === "approved") {
    cardStatus = "approved";
  } else if (props.acting_manager_response === "pending") {
    cardStatus = "pending";
  } else if (props.acting_manager_response === "denied") {
    cardStatus = "denied";
  } else {
    cardStatus = "";
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDrawerOpenDrop, setDrawerOpenDrop] = React.useState(false);

  const toggleDrawerDrop = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpenDrop(open);
  };

  const [isDrawerOpenPickup, setDrawerOpenPickup] = React.useState(false);

  const toggleDrawerPickup = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpenPickup(open);
  };

  return (
    <>
      <tr
        className="hover:bg-slate-50 cursor-pointer"
        onClick={
          props.requestType === "Add to Pool Request"
            ? toggleDrawerDrop(true)
            : toggleDrawerPickup(true)
        }
      >
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500 pl-6">
          <div className="flex items-center space-x-2">
            {props.requestType === "Add to Pool Request" ? (
              <div className="text-slate-600 w-9 h-9 bg-slate-100 rounded-lg flex items-center justify-center">
                <CloudDownloadOutlinedIcon style={{ fontSize: "20px" }} />
              </div>
            ) : (
              <div className="text-slate-600 w-9 h-9 bg-slate-100 rounded-lg flex items-center justify-center">
                <PersonAddAltIcon style={{ fontSize: "20px" }} />
              </div>
            )}
            <div className="text-slate-900 text-sm">{props.requestType}</div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500 pl-6">
          <div className="text-slate-900">{formatDateReadable(props.date)}</div>
          <div className="text-slate-500 text-xs">{props.shift}</div>
          <div className="text-slate-500 text-xs">
            {props.station
              ? props.station?.name
              : props.role
              ? props.role
              : props.roleType}
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
          {/* <div>
            <ArrowRightAltIcon />
          </div> */}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
          {/* <div>
            <p className="text-slate-900 text-sm">{props.shift}</p>
            <p className="text-xs text-slate-500">{props.break_formatted}</p>
            <p className="text-xs text-slate-500">{props.role} | {props.roleType}</p>
          </div> */}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
          {/* <div className="flex justify-end">
            <Pill status={cardStatus} />
          </div> */}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium">
          <div className="flex items-center justify-end">
            <div className="mr-4">
              <Pill status={cardStatus} />
            </div>
            <div>
              {cardStatus !== "pending" ? (
                <Tooltip title="Archive">
                  <IconButton
                    className="text-slate-500"
                    sx={{ backgroundColor: "#f8fafc" }}
                    onClick={() =>
                      props.archive_shift_change_request(props.uuid)
                    }
                  >
                    <ArchiveOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <div className="w-10 h-10"></div>
              )}
            </div>
          </div>
        </td>
      </tr>
      <PendingTableEmployeeRowShiftModal
        open={open}
        handleClose={handleClose}
        shiftInfo={props}
        cardStatus={cardStatus}
        rescind_drop_shift={props.rescind_drop_shift}
        rescind_pickup_shift={props.rescind_pickup_shift}
      />
      <PendingTableEmployeeRowShiftDropDrawer
        open={isDrawerOpenDrop}
        onClose={toggleDrawerDrop(false)}
        shiftInfo={props}
        cardStatus={cardStatus}
        rescind_drop_shift={props.rescind_drop_shift}
      />
      <PendingTableEmployeeRowShiftPickupDrawer
        open={isDrawerOpenPickup}
        onClose={toggleDrawerPickup(false)}
        shiftInfo={props}
        cardStatus={cardStatus}
        rescind_pickup_shift={props.rescind_pickup_shift}
      />
    </>
  );
}

export default PendingTableEmployeeRowShift;
