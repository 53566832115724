import React from 'react';

const DrawerFooterContainerSmall = ({ children }) => {
  return (
    <div className="w-full absolute bottom-0 h-[90px] bg-white border-t border-slate-300 px-6 flex flex-col items-center justify-center z-30">
      {children}
    </div>
  );
};

export default DrawerFooterContainerSmall;
