import React from 'react'
import ManageLocationsDisplay from './ManageLocationsDisplay';
import IntegrationsDisplay from './IntegrationsDisplay';
import HoursOfOperationDisplay from './HoursOfOperationDisplay';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CableIcon from '@mui/icons-material/Cable';
import { getRightworkAPI } from '../requests/utilities/requests';
import { postRightworkAPI } from '../requests/utilities/requests';
import {
  API_get_hours_for_all_stores,
  API_update_hours_for_all_stores,
  API_get_manager_stores_rightwork_data,
  API_update_manager_store_rightwork_data,
  API_get_manager_stores_integration_data,
  API_post_manager_store_integration_data
} from '../../../constants';
import { formatDate } from '../requests/utilities/helpers';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadSpinner from "../../../utilities/LoadSpinner";
import TopBarDisplayTitleNoLocationSelect from '../home/top_bar_components/TopBarDisplayTitleNoLocationSelect';

function LocationsIntegrationsHoursSettingsDisplay(props) {

  const [data, setData] = React.useState([]);
  const [display, setDisplay] = React.useState("Locations");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSavingData, setIsSavingData] = React.useState(false);

  const default_api_calls = {
    hoursOfOperation: API_get_hours_for_all_stores,
    locationsData: API_get_manager_stores_rightwork_data,
    integrationsData: API_get_manager_stores_integration_data
  };

  // Get the current date
  let currentDate = new Date();

  // // Get the start date of the previous month
  // let lowerBoundDate = new Date(
  //   currentDate.getFullYear(),
  //   currentDate.getMonth() - 1,
  //   1
  // );
  // // Get the end date of the next month
  // let upperBoundDate = new Date(
  //   currentDate.getFullYear(),
  //   currentDate.getMonth() + 2,
  //   0
  // );

  // let lowerBoundDateStr = formatDate(lowerBoundDate);
  // let upperBoundDateStr = formatDate(upperBoundDate);

  let api_params = {
    date_start: formatDate(currentDate),
  };

  const fetch_data = async (api_calls, api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = Object.keys(api_calls);
    let api_list = Object.values(api_calls);
    try {
      console.log("CALLING APIS");
      const res = await Promise.all(
        api_list.map((api) => getRightworkAPI(api, api_params))
      );
      var tempData = { ...data };
      res.map((res, i) => (tempData[api_set_data_name[i]] = res.data));
      setData(tempData);
      setIsLoading(false);
      setIsSavingData(false);
    } catch (error) {
      console.log(error);
      //throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    fetch_data(default_api_calls, api_params, true);
  }, []);

  // ----------- POST APIs ----------- //

  const update_hours_for_all_stores = async (update_hours_payload) => {

    // Prepare data to send
    const requestData = {
      update_hours_payload: JSON.stringify([update_hours_payload])
    };

    try {
      toast.loading("Updating Hours of Operation...", { autoClose: false });

      const res = await postRightworkAPI(API_update_hours_for_all_stores, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Hours of Operation Updated!", { autoClose: 3000 });

        fetch_data(
          {
            hoursOfOperation: API_get_hours_for_all_stores,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };


  const update_store_info = async (store_data) => {

    // Prepare data to send
    const requestData = {
      store_id: store_data.id,
      latitude: store_data.latitude,
      longitude: store_data.longitude,
      onboarding_status: store_data.onboarding_status,
      mobile_app_enabled: store_data.mobile_app_enabled
    };

    try {
      toast.loading("Updating Store Info...", { autoClose: false });

      const res = await postRightworkAPI(API_update_manager_store_rightwork_data, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Store Info Updated!", { autoClose: 3000 });

        fetch_data(
          {
            locationsData: API_get_manager_stores_rightwork_data,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };


  const update_integrations_info = async (store_id, integration_data) => {

    // Prepare data to send
    const requestData = {
      store_id: store_id,
      integration_data: JSON.stringify(integration_data)
    };

    try {
      toast.loading("Updating Integration Info...", { autoClose: false });

      const res = await postRightworkAPI(API_post_manager_store_integration_data, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Integration Info Updated!", { autoClose: 3000 });

        fetch_data(
          {
            integrationsData: API_get_manager_stores_integration_data,
          },
          api_params
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };




  return (
    <>
      {!isLoading ? (
        <div className="w-full min-h-screen flex">
          <div className="w-1/5">
            <div className="h-full flex flex-col gap-2 px-6 py-5 bg-slate-50 border-r border-slate-200">
              <button
                className={`mt-5 w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
                  display === "Locations" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Locations")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Locations"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <LocationOnIcon />
                  </div>
                  <span>Manage Locations</span>
                </div>
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Hours" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Hours")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Hours"
                      ? "text-violet-700"
                      : "text-slate-500"
                  }`}
                >
                  <div>
                    <ScheduleIcon />
                  </div>
                  <span>Hours of Operations</span>
                </div>
              </button>
              <button
                className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                  display === "Integrations" ? "bg-white" : ""
                }`}
                onClick={() => setDisplay("Integrations")}
              >
                <div
                  className={`flex flex-1 items-center space-x-4 ${
                    display === "Integrations" ? "text-violet-700" : "text-slate-500"
                  }`}
                >
                  <div>
                    <CableIcon />
                  </div>
                  <span>Integrations</span>
                </div>
              </button>
            </div>
          </div>
          <div className="w-4/5">
            <div>
              <TopBarDisplayTitleNoLocationSelect
                title={display}
              />
            </div>
            {display === "Locations" ? (
              <ManageLocationsDisplay
                locationsData={data?.locationsData?.redux?.payload?.stores}
                update_store_info={update_store_info}
              />
            ) : display === "Integrations" ? (
              <IntegrationsDisplay
                integrationsData={data?.integrationsData?.redux?.payload?.stores}
                update_integrations_info={update_integrations_info}
              />
            ) : display === "Hours" ? (
              <HoursOfOperationDisplay
                hoursOfOperation={data?.hoursOfOperation?.hours}
                update_hours_for_all_stores={update_hours_for_all_stores}
                storeNames={props.storeNames}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </>
  )
}

export default LocationsIntegrationsHoursSettingsDisplay
