import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import LoadSpinner from '../../../utilities/LoadSpinner';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { API_get_learning_section_detail } from '../../../constants';
import { getRightworkAPI } from '../requests/utilities/requests';
import {
  BlockNoteSchema,
  defaultBlockSpecs,
} from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import {
  useCreateBlockNote,
  createReactBlockSpec
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { Quiz } from './Quiz';
import { Alert } from './Alert';
import { Checkpoint } from './Checkpoint';
import SectionInputsModal2 from './SectionInputsModal2';
import ModuleInputsModal from './ModuleInputsModal';
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";

function ModulePreviewModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "95%",
    maxHeight: 800,
    bgcolor: "background.paper",
    // border: "2px solid #E2E8F0",
    // borderRadius: "15px",
    boxShadow: 24,
    // overflowY: "auto",
  };

  const [showMenu, setShowMenu] = React.useState(true)

  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);

  const [selectedSectionUUID, setSelectedSectionUUID] = React.useState()

  console.log("PREVIEW SECTION UUID", selectedSectionUUID)

  const [showSectionsDropdown, setShowSectionsDropdown] = React.useState(true)

  function toggleShowSectionsDropdown() {
    setShowSectionsDropdown(prevState => !prevState)
  }


  // [GET] api/lms_info/get_learning_section_detail
  // * company_id
  // * learning_section_uuid
  const fetchSectionDetail = async (showLoading, learning_section_uuid) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_section_uuid: learning_section_uuid,
    };
    let api = API_get_learning_section_detail;
    try {
      const res = await getRightworkAPI(api, api_params);

      // WORKAROUND: Blocknote and signed URLs
      let mapSignedUrlToFileUuid = localStorage.getItem("mapSignedUrlToFileUuid") || "{}";
      mapSignedUrlToFileUuid = JSON.parse(mapSignedUrlToFileUuid);
      const rawBlocksAsJson = res.data.redux?.payload?.learning_section.as_json;
      rawBlocksAsJson.forEach(((block, index) => {
        const validTypes = ["image", "video", "audio", "file"];
        if (!validTypes.includes(block.type)) {
          return;
        }
        mapSignedUrlToFileUuid[block.props.url] = block.props.file_uuid;
      }));
      localStorage.setItem("mapSignedUrlToFileUuid", JSON.stringify(mapSignedUrlToFileUuid));

      if (res.status === 200) {
        props.setModalDataSection(res.data.redux?.payload?.learning_section);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const [openModuleInputsModal, setOpenModuleInputsModal] = React.useState(false);
  const handleOpenModuleInputsModal = () => setOpenModuleInputsModal(true);
  const handleCloseModuleInputsModal = () => {
    setOpenModuleInputsModal(false);
  }

  const [openSectionInputsModal, setOpenSectionInputsModal] = React.useState(false);
  const handleOpenSectionInputsModal = () => setOpenSectionInputsModal(true);
  const handleCloseSectionInputsModal = () => {
    setOpenSectionInputsModal(false);
  }

  React.useEffect(() => {
    if (props.open && props.modalDataModule?.sections?.length > 0) {
      fetchSectionDetail(true, props.modalDataModule?.sections?.[0]?.uuid)
      setSelectedSectionIndex(0)
      setSelectedSectionUUID(props.modalDataModule?.sections?.[0]?.uuid)
    }
  }, [props.open, openModuleInputsModal])


  const quizzesData = props.quizzesData
  const homeCompany = props.homeCompany

  const schema = BlockNoteSchema.create({
    blockSpecs: {
      ...defaultBlockSpecs,
      quiz: createReactBlockSpec({
        type: "quiz",
        propSchema: {
          quizSelected: { type: 'object', default: null },
          quizzesData: { type: 'array', required: true },
          homeCompany: { type: 'string', required: true },
        },
        content: "inline",
      }, {
        render: (props) => <Quiz {...props} quizzesData={quizzesData} homeCompany={homeCompany} type="Preview" />,
      }),
      checkpoint: createReactBlockSpec({
        type: "checkpoint",
        propSchema: {
          checkpointSelected: { type: 'object', default: null },
          homeCompany: { type: 'string', required: true },
        },
        content: "inline",
      }, {
        render: (props) => <Checkpoint {...props} />,
      }),
      alert: Alert,
    },
  });

  const editor = useCreateBlockNote({
    schema,
  });

  const removeAllBlocks = () => {
    if (editor) {
      const allBlockIDsToRemove = editor.document.map(block => block.id);
      editor.removeBlocks(allBlockIDsToRemove);
    }
  };

  const loadAllBlocks = () => {
    if (editor) {
      const allBlocksToLoad = props.modalDataSection.as_json;
      editor.insertBlocks(allBlocksToLoad, editor.document[0]?.id || null, "before");
    }
  }

  React.useEffect(() => {
    if (!isLoading && props.modalDataSection.as_json.length > 0) {
      loadAllBlocks()
    }
  }, [isLoading])

  // React.useEffect(() => {
  //   setSelectedSectionIndex(0)
  // }, [props.open])

  console.log("MODAL DATA MODULE PREVIEW", props.modalDataModule)

  function refresh_section() {
    fetchSectionDetail(true, selectedSectionUUID)
  }




  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose()
          removeAllBlocks()
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div className="w-full h-full flex">
            {showMenu &&
              <div className="w-[25%] min-w-[225px] bg-white h-full overflow-auto">
                <div className="px-4 pt-4 pb-6 border-b border-slate-200">
                  <div
                    className="flex items-center space-x-1.5 text-slate-500 hover:text-slate-600 cursor-pointer"
                    onClick={() => setShowMenu(false)}
                  >
                    <div>
                      <MenuIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
                    </div>
                    <p className="text-slate-500 text-xs">Hide</p>
                  </div>
                  <h2 className="mt-8 text-2xl">Module Preview</h2>
                  <div className="mt-3 flex items-center space-x-2">
                    <div className={`w-3 h-3 rounded bg-violet-700`}></div>
                    <p className="text-slate-900 text-xs">1 Module</p>
                  </div>
                  <div className="mt-6 flex items-center justify-between">
                    <p className="text-slate-500 text-xxs">0% completed</p>
                  </div>
                  <div className="mt-1 w-full h-1.5 rounded bg-slate-200">
                  </div>
                </div>
                <div
                  className="pl-2 pr-3 py-4 border-b border-slate-200 hover:bg-slate-100 flex items-center justify-between cursor-pointer relative"
                  onClick={() => {
                    toggleShowSectionsDropdown()
                    // props.setSelectedModuleIndex(props.index)
                    // props.setSelectedSectionIndex(null)
                  }}
                >
                  {showSectionsDropdown &&
                    <div className="h-full w-1 bg-violet-700 absolute left-0"></div>
                  }
                  <div className="flex-1 flex items-center space-x-1.5">
                    <div className="text-xxs text-slate-500">
                      {showSectionsDropdown ? <ExpandMoreIcon style={{ fontSize: "20px" }} /> : <ChevronRightIcon style={{ fontSize: "20px" }} />}
                    </div>
                    <div>
                      <p className="text-slate-900 text-xs">{props.modalDataModule?.name}</p>
                      <p className="text-slate-500 text-xxs">{props.modalDataModule?.sections?.length} {props.modalDataModule?.sections?.length === 1 ? "section" : "sections"}</p>
                    </div>
                  </div>
                  <div className="w-6 h-6 rounded-full bg-slate-300 flex items-center justify-center ml-3 relative">
                    <div className={`w-5 h-5 rounded-full bg-white flex items-center justify-center`}></div>
                  </div>
                </div>
                {showSectionsDropdown &&
                  <div>
                    {props.modalDataModule?.sections?.map((section, index) => (
                      <div
                        key={index}
                        className={`pl-6 pr-4 py-4 border-b border-slate-200 bg-slate-50 hover:bg-slate-100 flex items-center justify-between cursor-pointer relative ${selectedSectionIndex === index ? "bg-slate-200" : ""}`}
                        onClick={() => {
                          if (!isLoading) {
                              setSelectedSectionIndex(index);
                              removeAllBlocks();
                              fetchSectionDetail(true, section.uuid);
                              setSelectedSectionUUID(section.uuid)
                          }
                        }}
                      >
                        <div className="flex items-center space-x-2">
                          <p className="text-xxs text-slate-500">{index + 1}.</p>
                          <p className="text-slate-900 text-xs">{section.name}</p>
                        </div>
                        {selectedSectionIndex === index ?
                          <div className="w-4 h-4 rounded-full border bg-slate-200 border-violet-700 flex items-center justify-center">
                            <div className="w-2 h-2 rounded-full bg-violet-700"></div>
                          </div> :
                          <div className="w-4 h-4 rounded-full border bg-white border-slate-300"></div>
                        }
                      </div>
                    ))}
                  </div>
                }
              </div>
            }
            {showMenu &&
              <div className="w-2 bg-slate-100 shadow-inner"></div>
            }
            <div className="flex-1 h-full">
              <div className="w-full h-full flex flex-col">
                <div>
                  <div className="w-full py-5 bg-white flex justify-center shadow relative">
                    <p className="text-slate-500 text-sm">Section {selectedSectionIndex + 1} of {props.modalDataModule?.sections?.length}</p>
                    {!showMenu &&
                      <div className="absolute left-2 top-2">
                        <div
                          className="flex items-center space-x-1 text-slate-500 hover:text-slate-600 cursor-pointer"
                          onClick={() => setShowMenu(true)}
                        >
                          <div>
                            <MenuIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div
                    className="absolute right-2 top-2 text-gray-500 cursor-pointer z-40"
                    onClick={() => {
                      props.handleClose()
                      removeAllBlocks()
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <div className="h-2 bg-slate-100 shadow-inner"></div>
                </div>
                <div className="w-full flex-1 relative pt-8 overflow-auto bg-white shadow">
                  {!isLoading ?
                    <div>
                      <BlockNoteView
                        editor={editor}
                        editable={false}
                        theme="light"
                      />
                    </div> :
                    <div className="w-full flex-1">
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <LoadSpinner />
                      </div>
                    </div>
                  }
                </div>
                <div className="w-full h-[10%] flex flex-col">
                  <div className="w-full h-2 bg-slate-100 shadow-inner"></div>
                  <div className="w-full bg-white flex items-center justify-between px-6 flex-1 shadow">
                    <div className="flex items-center gap-x-3">
                      <div
                        className="flex items-center gap-x-3 px-3 py-1 rounded bg-violet-50 hover:bg-violet-100 text-violet-700 cursor-pointer"
                        onClick={() => {
                          removeAllBlocks()
                          handleOpenModuleInputsModal()
                        }}
                      >
                        <div>
                          <ViewModuleIcon />
                        </div>
                        <p>Edit Module</p>
                      </div>
                      <div
                        className="flex items-center gap-x-3 px-3 py-1 rounded bg-violet-50 hover:bg-violet-100 text-violet-700 cursor-pointer"
                        onClick={() => {
                          removeAllBlocks()
                          fetchSectionDetail(true, selectedSectionUUID)
                          handleOpenSectionInputsModal()
                        }}
                      >
                        <div>
                          <ViewHeadlineIcon />
                        </div>
                        <p>Edit Section</p>
                      </div>
                    </div>
                    <div>
                      {/* <div className="py-2 px-4 bg-emerald-600 hover:bg-emerald-700 rounded-lg text-sm text-white cursor-pointer">Mark as completed</div> */}
                      {props.modalDataModule?.sections?.length !== (selectedSectionIndex + 1) ?
                        <div className="flex items-center space-x-4">
                          {selectedSectionIndex > 0 &&
                            <div
                              className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                              onClick={() => {
                                removeAllBlocks()
                                fetchSectionDetail(true, props.modalDataModule?.sections?.[selectedSectionIndex - 1]?.uuid)
                                setSelectedSectionUUID(props.modalDataModule?.sections?.[selectedSectionIndex - 1]?.uuid)
                                setSelectedSectionIndex(selectedSectionIndex - 1)
                              }}
                            >
                              <div>
                                <ChevronLeftIcon />
                              </div>
                              <p>Last section</p>
                            </div>
                          }
                          <div
                            className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                            onClick={() => {
                              removeAllBlocks()
                              fetchSectionDetail(true, props.modalDataModule?.sections?.[selectedSectionIndex + 1]?.uuid)
                              setSelectedSectionUUID(props.modalDataModule?.sections?.[selectedSectionIndex + 1]?.uuid)
                              setSelectedSectionIndex(selectedSectionIndex + 1)
                            }}
                          >
                            <p>Next section</p>
                            <div>
                              <ChevronRightIcon />
                            </div>
                          </div>
                        </div> :
                        <div className="flex items-center space-x-4">
                          {selectedSectionIndex > 0 &&
                            <div
                              className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                              onClick={() => {
                                removeAllBlocks()
                                fetchSectionDetail(true, props.modalDataModule?.sections?.[selectedSectionIndex - 1]?.uuid)
                                setSelectedSectionUUID(props.modalDataModule?.sections?.[selectedSectionIndex - 1]?.uuid)
                                setSelectedSectionIndex(selectedSectionIndex - 1)
                              }}
                            >
                              <div>
                                <ChevronLeftIcon />
                              </div>
                              <p>Last section</p>
                            </div>
                          }
                          <div
                            className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                            onClick={() => {
                              removeAllBlocks()
                              props.handleClose()
                            }}
                          >
                            <p>Finish Module</p>
                            <div>
                              <ChevronRightIcon />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ModuleInputsModal
        open={openModuleInputsModal}
        handleClose={handleCloseModuleInputsModal}
        modalEdit={true}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        homeCompany={props.homeCompany}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        sectionsData={props.sectionsData}
        post_learning_module={props.post_learning_module}
        delete_learning_module={props.delete_learning_module}
        post_learning_section={props.post_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
        type="Preview"
        removeAllBlocks={removeAllBlocks}
        selectedModule={props.selectedModule}
        setSelectedModule={props.setSelectedModule}
      />
      <SectionInputsModal2
        open={openSectionInputsModal}
        handleClose={handleCloseSectionInputsModal}
        modalEdit={true}
        isLoading={isLoading}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        post_learning_section={props.post_learning_section}
        homeCompany={props.homeCompany}
        delete_learning_section={props.delete_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
        type="Preview"
        removeAllBlocks={removeAllBlocks}
        refresh_section={refresh_section}
      />
    </>
  );
}

export default ModulePreviewModal;
