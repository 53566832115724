import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "react-toastify/dist/ReactToastify.css";

function TimeOffApprovedCalendarModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    height: "98%",
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );
  const [days, setDays] = React.useState([]);

  const changeMonth = (direction) => {
    setDays([]); // Reset days to avoid glitches during transition
    setTimeout(() => {
      if (direction === "next") {
        if (currentMonth === 11) {
          setCurrentMonth(0);
          setCurrentYear(currentYear + 1);
        } else {
          setCurrentMonth(currentMonth + 1);
        }
      } else if (direction === "prev") {
        if (currentMonth === 0) {
          setCurrentMonth(11);
          setCurrentYear(currentYear - 1);
        } else {
          setCurrentMonth(currentMonth - 1);
        }
      }
    }, 0);
  };

  React.useEffect(() => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const prevMonthDays = new Date(currentYear, currentMonth, 0).getDate();
    const daysArray = [];

    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      daysArray.push({ date: prevMonthDays - i, isCurrentMonth: false });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push({ date: i, isCurrentMonth: true });
    }

    while (daysArray.length % 7 !== 0) {
      const nextMonthDay = daysArray.length - daysInMonth - firstDayOfMonth + 1;
      daysArray.push({ date: nextMonthDay, isCurrentMonth: false });
    }

    setDays(daysArray);
  }, [currentMonth, currentYear]);

  const today = new Date();
  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  console.log("timeOffFutureApproved", props.timeOffFutureApproved);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="w-full flex items-center justify-between">
              <h3 className="text-xl">Time Off Calendar</h3>
              <div className="w-72 flex gap-x-12">
                <div className="flex items-center gap-x-2">
                  <div className="w-3 h-3 rounded-full bg-emerald-500"></div>
                  <p className="text-emerald-700">Approved</p>
                </div>
                <div className="flex items-center gap-x-2">
                  <div className="w-3 h-3 rounded-full bg-yellow-300"></div>
                  <p className="text-yellow-600">Pending</p>
                </div>
              </div>
            </div>
            <div className="w-full flex px-12 mt-6">
              <div className="w-full pb-8">
                <div className="w-full flex justify-center items-center">
                  <div className="flex items-center text-slate-900 w-[50%] margin-auto">
                    <button
                      type="button"
                      className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-500 hover:text-slate-600"
                      onClick={() => changeMonth("prev")}
                    >
                      <span className="sr-only">Previous month</span>
                      <ChevronLeftIcon />
                    </button>
                    <div className="flex-auto font-semibold text-lg text-center">
                      {months[currentMonth]} {currentYear}
                    </div>
                    <button
                      type="button"
                      className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-500 hover:text-slate-600"
                      onClick={() => changeMonth("next")}
                    >
                      <span className="sr-only">Next month</span>
                      <ChevronRightIcon />
                    </button>
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-slate-500">
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                    (dayOfWeek) => (
                      <div key={dayOfWeek}>{dayOfWeek}</div>
                    )
                  )}
                </div>
                <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-slate-200 text-sm shadow ring-1 ring-slate-200 overflow-hidden">
                  {days.map((day, dayIdx) => {
                    const isToday =
                      day.date === todayDate &&
                      currentMonth === todayMonth &&
                      currentYear === todayYear &&
                      day.isCurrentMonth;
                    const currentDate = new Date(
                      currentYear,
                      currentMonth,
                      day.date
                    );
                    const isPastDate = currentDate < today;
                    return (
                      <div
                        key={dayIdx}
                        className={classNames(
                          "min-h-[100px] relative pb-2",
                          day.isCurrentMonth ? "bg-white" : "bg-slate-50"
                        )}
                      >
                        {day.isCurrentMonth && (
                          <div>
                            <div className="absolute left-0.5 top-0.5">
                              <div
                                className={classNames(
                                  "mx-auto flex h-[28px] w-[28px] items-center justify-center rounded-full",
                                  day.isCurrentMonth
                                    ? "text-slate-900"
                                    : "text-slate-200",
                                  isToday ? "bg-violet-700 text-white" : ""
                                )}
                              >
                                {day.date}
                              </div>
                            </div>
                            <div className="mt-8 px-1">
                              {props.timeOffFutureApproved.map((timeOff) => {
                                const startDate = new Date(
                                  timeOff.date_lower_bound + "T00:00:00"
                                );
                                const endDate = timeOff.date_upper_bound
                                  ? new Date(
                                      timeOff.date_upper_bound + "T23:59:59"
                                    )
                                  : startDate;
                                const currentDate = new Date(
                                  currentYear,
                                  currentMonth,
                                  day.date
                                );

                                const isInRange = (date) => {
                                  const start = new Date(
                                    startDate.getFullYear(),
                                    startDate.getMonth(),
                                    startDate.getDate()
                                  );
                                  const end = new Date(
                                    endDate.getFullYear(),
                                    endDate.getMonth(),
                                    endDate.getDate()
                                  );
                                  const current = new Date(
                                    date.getFullYear(),
                                    date.getMonth(),
                                    date.getDate()
                                  );

                                  return current >= start && current <= end;
                                };

                                if (isInRange(currentDate)) {
                                  return (
                                    <div
                                      key={timeOff.uuid}
                                      className={`${
                                        isPastDate && !isToday
                                          ? timeOff.status === "PENDING" ? "hidden" : "bg-slate-200 text-slate-400"
                                          : timeOff.status === "PENDING" ? "bg-yellow-200 text-yellow-700" : "bg-emerald-100 text-emerald-700"
                                      } mt-1 rounded px-2 py-1`}
                                    >
                                      <p className="text-xxs font-bold">
                                        {timeOff.store_team_member.first_name}{" "}
                                        {timeOff.store_team_member.last_name}
                                      </p>
                                      <p className="text-xxs">
                                        {timeOff.title}
                                      </p>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TimeOffApprovedCalendarModal;
