import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getRightworkAPI } from '../requests/utilities/requests';
import { API_get_learning_quiz_summaries } from '../../../constants';
import LoadSpinner from '../../../utilities/LoadSpinner';

function AddQuizToSectionModal(props) {

  const [selectedQuiz, setSelectedQuiz] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const handleDropdownQuizSelect = (quiz) => {
    setSelectedQuiz(quiz);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [quizzesData, setQuizzesData] = React.useState(null);

  const fetchQuizzesData = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
    };
    try {
      const res = await getRightworkAPI(API_get_learning_quiz_summaries, api_params);
      if (res.status === 200) {
        setQuizzesData(res.data.redux?.payload?.learning_quizzes);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  React.useEffect(() => {
    if (props.open) {
      fetchQuizzesData(true);
    }
  }, [props.open]);


  return (
    <Modal
      open={props.open}
      onClose={() => props.handleClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-2 top-2 text-gray-500 cursor-pointer"
          onClick={() => props.handleClose()}
        >
          <CloseIcon />
        </div>
        {!isLoading ?
          <div>
            <div>
              <h3 className="text-2xl text-slate-900">Add Quiz to Section</h3>
            </div>
            <div>
              <p className="text-slate-500 text-sm mt-4">Select from the quizzes you have created to add to this section.</p>
            </div>
            <div className="mt-3">
              <div className="flex-1">
                <div className="w-full">
                  <Select
                    fullWidth
                    value={selectedQuiz}
                    displayEmpty
                    onChange={(e) => handleDropdownQuizSelect(e.target.value)}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      Select a quiz
                    </MenuItem>
                    {quizzesData?.map((quiz, index) => (
                      <MenuItem key={index} value={quiz}>
                        {quiz.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div className="px-8 py-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.handleQuizSelect(selectedQuiz);
                    props.handleClose();
                  }}
                >
                  Add Quiz
                </div>
              </div>
            </div>
          </div> :
          <div className="w-full h-[400px]">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <LoadSpinner />
            </div>
          </div>
        }
      </Box>
    </Modal>
  );
}

export default AddQuizToSectionModal;
