import GoalTableRow from "./GoalTableRow.js";

export default function GoalTable(props) {
  const weeks_long = Math.ceil(
    (props.week_end_date.getTime() - props.week_start_date.getTime()) /
      (1000 * 60 * 60 * 24) /
      7
  );

  const date_options = { month: "short", day: "numeric" };

  function getEmptyColumns(contest_metric_detail) {
    let emptyColumns = [];
    for (
      let i = 0;
      i <=
      (contest_metric_detail
        ? weeks_long - contest_metric_detail.metric_weekly_info.length
        : 1);
      i++
    ) {
      emptyColumns.push(
        <td className="whitespace-nowrap p-4 text-sm text-center text-black bg-gray-400"></td>
      );
    }
    return emptyColumns;
  }

  let columnTitles = [];
  for (let i = 0; i <= weeks_long; i++) {
    columnTitles.push(
      <th
        scope="col"
        className={`bg-gray-600 px-4 py-3.5 text-center text-white text-xs`}
        style={{
          border:
            props.formatDate(props.selectedWeekStart) ===
            props.formatDate(props.addDays(props.week_start_date, i * 7))
              ? "3px solid black"
              : "1px solid #e5e7eb",
        }}
      >
        Week of{" "}
        {props
          .addDays(props.week_start_date, i * 7)
          .toLocaleDateString("en-us", date_options)}
      </th>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="bg-gray-600 py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-white sm:pl-6"
                    ></th>
                    {columnTitles}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {props.contest_metric_detail.map((object, index) => (
                    <GoalTableRow
                      index={index}
                      metric_name={object.name}
                      metric_detail={object}
                      metric_weekly_info={object.metric_weekly_info}
                      formatDate={props.formatDate}
                      week_start_date={props.week_start_date}
                      addDays={props.addDays}
                      emptyColumns={getEmptyColumns(object)}
                      addUnits={props.addUnits}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
