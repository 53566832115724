import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import { getInitials } from "../../utilities/helpers";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getCardColor2 } from "../../../../../helpers/helpers";

function TradeTargetCard(props) {
  const shiftCardColor = getCardColor2(props.roleId, props.roleNames, false);

  return (
    <div
      className="w-full flex py-4 px-4 border-b border-slate-200 bg-white hover:bg-slate-50 cursor-pointer"
      onClick={props.handleTradePress}
    >
      <div
        className={`w-12 h-12 ${shiftCardColor} rounded-full mt-1 flex items-center justify-center`}
      >
        <p className="text-white">{getInitials(props.name)}</p>
      </div>
      <div className="flex items-center flex-1">
        <div className="ml-4 flex-1">
          <p className="text-slate-900 text-base">{props.name}</p>
          <div className="flex items-center text-slate-600 text-sm">
            <p>{props.shiftStartNumber}</p>
            <p>{props.shiftStartLetters}</p>
            <div className="ml-2">
              <ArrowForwardIcon color="#475569" style={{ fontSize: "18px" }} />
            </div>
            <p className="ml-2">{props.shiftEndNumber}</p>
            <p>{props.shiftEndLetters}</p>
          </div>
          <div className="text-slate-600 text-sm">
            <p>
              {props.station
                ? props.station?.name
                : props.role
                ? props.role
                : props.roleType}
            </p>
          </div>
        </div>
        {props.selected ? (
          <div className="w-7 h-7 bg-emerald-600 border border-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "20px" }} />
          </div>
        ) : (
          <div className="w-7 h-7 bg-slate-50 border border-slate-500 rounded-full"></div>
        )}
      </div>
    </div>
  );
}

export default TradeTargetCard;
