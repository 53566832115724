import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadSpinner() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress
        size="1.5rem"
        sx={{
          color: "#6d28d9"
        }}
      />
    </Box>
  );
}
