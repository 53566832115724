import React from "react";
import SettingsHeader from "./SettingsHeader";
import AddIcon from "@mui/icons-material/Add";
import RoleSettingsDrawer from "./RoleSettingsDrawer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteWarningModal from "./DeleteWarningModal";

function RoleSettingsDisplay(props) {
  const default_data = {
    role_name: null,
    role_type: null,
    role_type_id: null,
    station_options: [],
    exclude_from_labor: false,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  const [openDeleteWarningModal, setOpenDeleteWarningModal] =
    React.useState(false);
  const handleOpenDeleteWarningModal = () => setOpenDeleteWarningModal(true);
  const handleCloseDeleteWarningModal = () => setOpenDeleteWarningModal(false);

  const [selectedRole, setSelectedRole] = React.useState(null);

  return (
    <div>
      {/* <SettingsHeader title="Role Settings" /> */}
      <div className="w-full p-6 flex justify-end">
        <Tooltip title="Add role">
          <button
            className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
            onClick={() => {
              setModalData(default_data);
              setModalEdit(false);
              handleOpen();
            }}
          >
            <AddIcon style={{ fontSize: "22px" }} />
          </button>
        </Tooltip>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-[18%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="w-[18%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Department
                    </th>
                    <th
                      scope="col"
                      className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Stations
                    </th>
                    <th
                      scope="col"
                      className="w-[14%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Excluded from labor
                    </th>
                    <th
                      scope="col"
                      className="w-1/12 relative py-3.5 pl-3 pr-6"
                    >
                      {/* <span className="sr-only">Edit</span> */}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200 bg-white">
                  {Array.isArray(props.roles) &&
                    props.roles
                      .sort((a, b) => {
                        if (a.role_type_id === b.role_type_id) {
                          return a.role_name.localeCompare(b.role_name);
                        }
                        return a.role_type_id > b.role_type_id;
                      })
                      .map((role, i) => (
                        <tr key={i}>
                          <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                            <div className="w-fit">
                              <div
                                className={`rounded px-3 p-1 ${
                                  role.color ? "text-white" : ""
                                } ${role.color}`}
                              >
                                {role.role_name}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            <div>{role.role_type}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            <div className="w-full flex flex-wrap items-center gap-2">
                              {role.station_options?.map((station, j) => (
                                <div
                                  key={j}
                                  className="bg-violet-100 text-violet-700 rounded px-3 py-1"
                                >
                                  {station.station_name}
                                </div>
                              ))}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                            {role.exclude_from_labor && (
                              <div className="px-2 py-1 rounded w-fit bg-orange-100 border border-orange-200 text-orange-700">
                                Yes
                              </div>
                            )}
                          </td>
                          <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                            <div className="flex items-center justify-end w-32 space-x-3">
                              <Tooltip title="Edit">
                                <IconButton
                                  className="text-slate-500 ml-4"
                                  sx={{ backgroundColor: "#f8fafc" }}
                                  onClick={() => {
                                    setModalData(role);
                                    setModalEdit(true);
                                    handleOpen();
                                  }}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                              {props.permissionRole !== "company_manager" && (
                                <Tooltip title="Delete">
                                  <IconButton
                                    className="text-slate-500"
                                    sx={{ backgroundColor: "#f8fafc" }}
                                    onClick={() => {
                                      setSelectedRole(role);
                                      handleOpenDeleteWarningModal();
                                    }}
                                  >
                                    <DeleteOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <div className="border-b border-slate-200"></div>
            </div>
          </div>
        </div>
      </div>
      <RoleSettingsDrawer
        open={open}
        onClose={handleClose}
        modalData={modalData}
        modalEdit={modalEdit}
        departments={props.departments}
        edit_role={props.edit_role}
        isSavingData={props.isSavingData}
        store_name={props.store_name}
        permissionRole={props.permissionRole}
      />
      <DeleteWarningModal
        type="role"
        open={openDeleteWarningModal}
        handleClose={handleCloseDeleteWarningModal}
        delete_function_role={props.edit_role}
        selectedItem={selectedRole}
      />
    </div>
  );
}

export default RoleSettingsDisplay;
