import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const ShiftStatusBar = (props) => {
  const bids = props.pendingPickupCount ? props.pendingPickupCount : 0;

  return (
    <div>
      {props.cardStatus === "rescinded" || props.cardStatus === "rescinded (them)" ? (
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-rose-600 rounded-full flex items-center justify-center text-white">
            <ClearIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div>
      ) : props.cardStatus === "pending" && bids > 0 ? (
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div>
      ) : props.cardStatus === "pending" ? (
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div>
      ) : props.cardStatus === "approved" ? (
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
        </div>
      ) : props.cardStatus === "denied" && bids > 0 ? (
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-rose-600"></div>
          <div className="w-8 h-8 bg-rose-600 rounded-full flex items-center justify-center text-white">
            <ClearIcon style={{ fontSize: "18px" }} />
          </div>
        </div>
      ) : props.cardStatus === "denied" ? (
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-rose-600"></div>
          <div className="w-8 h-8 rounded-full bg-rose-600 flex items-center justify-center text-white">
            <ClearIcon style={{ fontSize: "18px" }} />
          </div>
          <div className="flex-1 h-1 bg-rose-600"></div>
          <div className="w-8 h-8 bg-rose-600 rounded-full flex items-center justify-center text-white">
            <ClearIcon style={{ fontSize: "18px" }} />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="w-full flex flex-row items-center mt-1">
        <div className="w-1/3">
          <p className="text-slate-500 text-sm">Your Drop</p>
          <p className="text-slate-500 text-sm">Request</p>
        </div>
        <div className="w-1/3 flex flex-col items-center justify-center">
          <p className="text-slate-500 text-sm">{bids}</p>
          <p className="text-slate-500 text-sm">{bids === 1 ? "Bid" : "Bids"}</p>
        </div>
        <div className="w-1/3 flex flex-col items-end justify-center">
          <p className="text-slate-500 text-sm">Manager</p>
          <p className="text-slate-500 text-sm">Response</p>
        </div>
      </div>
    </div>
  );
}

export default ShiftStatusBar;
