import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import LoadSpinner from '../../../utilities/LoadSpinner';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import { API_get_learning_section_detail } from '../../../constants';
import { getRightworkAPI } from '../requests/utilities/requests';
import {
  BlockNoteSchema,
  defaultBlockSpecs,
} from "@blocknote/core";
import "@blocknote/core/fonts/inter.css";
import {
  useCreateBlockNote,
  createReactBlockSpec
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { Quiz } from './Quiz';
import { Alert } from './Alert';
import { Checkpoint } from './Checkpoint';
import TrackSidebarModuleDropdown from './TrackSidebarModuleDropdown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TrackInputsModal from './TrackInputsModal';
import ModuleInputsModal from './ModuleInputsModal';
import SectionInputsModal2 from './SectionInputsModal2';
import TimelineIcon from "@mui/icons-material/Timeline";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";

function TrackPreviewModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "95%",
    maxHeight: 800,
    bgcolor: "background.paper",
    // border: "2px solid #E2E8F0",
    // borderRadius: "15px",
    boxShadow: 24,
    // overflowY: "auto",
  };

  const [showMenu, setShowMenu] = React.useState(true)


  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedModuleIndex, setSelectedModuleIndex] = React.useState(0);

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);

  const [selectedSectionUUID, setSelectedSectionUUID] = React.useState();


  const modules = props.modalDataTrack?.modules

  const lastSectionInModule = (modules?.[selectedModuleIndex]?.sections?.length === selectedSectionIndex + 1)
  const lastSectionInTrack = ((modules?.length === selectedModuleIndex + 1) && (modules?.[selectedModuleIndex]?.sections?.length === selectedSectionIndex + 1))

  const firstSectionInTrack = ((selectedModuleIndex === 0) && (selectedSectionIndex === null || selectedSectionIndex === 0))
  const firstSectionInModule = ((selectedModuleIndex !== 0) && (selectedSectionIndex === null || selectedSectionIndex === 0))

  console.log("TRACK PREVIEW SELECTED SECTION UUID", selectedSectionUUID)

  const [selectedModule, setSelectedModule] = React.useState(modules?.[0])

  React.useEffect(() => {
    setSelectedModule(modules?.[selectedModuleIndex])
  }, [selectedModuleIndex, selectedSectionIndex])

  console.log("SELECTED MODULE", selectedModule)




  // [GET] api/lms_info/get_learning_section_detail
  // * company_id
  // * learning_section_uuid
  const fetchSectionDetail = async (showLoading, learning_section_uuid) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_section_uuid: learning_section_uuid,
    };
    let api = API_get_learning_section_detail;
    try {
      const res = await getRightworkAPI(api, api_params);

      // WORKAROUND: Blocknote and signed URLs
      let mapSignedUrlToFileUuid = localStorage.getItem("mapSignedUrlToFileUuid") || "{}";
      mapSignedUrlToFileUuid = JSON.parse(mapSignedUrlToFileUuid);
      const rawBlocksAsJson = res.data.redux?.payload?.learning_section.as_json;
      rawBlocksAsJson.forEach(((block, index) => {
        const validTypes = ["image", "video", "audio", "file"];
        if (!validTypes.includes(block.type)) {
          return;
        }
        mapSignedUrlToFileUuid[block.props.url] = block.props.file_uuid;
      }));
      localStorage.setItem("mapSignedUrlToFileUuid", JSON.stringify(mapSignedUrlToFileUuid));

      if (res.status === 200) {
        props.setModalDataSection(res.data.redux?.payload?.learning_section);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };


  const [openTrackInputsModal, setOpenTrackInputsModal] = React.useState(false);
  const handleOpenTrackInputsModal = () => setOpenTrackInputsModal(true);
  const handleCloseTrackInputsModal = () => {
    setOpenTrackInputsModal(false);
  }

  const [openModuleInputsModal, setOpenModuleInputsModal] = React.useState(false);
  const handleOpenModuleInputsModal = () => setOpenModuleInputsModal(true);
  const handleCloseModuleInputsModal = () => {
    setOpenModuleInputsModal(false);
  }

  const [openSectionInputsModal, setOpenSectionInputsModal] = React.useState(false);
  const handleOpenSectionInputsModal = () => setOpenSectionInputsModal(true);
  const handleCloseSectionInputsModal = () => {
    setOpenSectionInputsModal(false);
  }

  React.useEffect(() => {
    if (modules?.[0]?.sections?.[0]?.uuid && props.open) {
      fetchSectionDetail(true, modules?.[0]?.sections?.[0]?.uuid)
      setSelectedModuleIndex(0)
      setSelectedSectionIndex(0)
      setSelectedSectionUUID(modules?.[0]?.sections?.[0]?.uuid)
    }
  }, [props.open, openTrackInputsModal, openModuleInputsModal])

  const quizzesData = props.quizzesData
  const homeCompany = props.homeCompany

  const schema = BlockNoteSchema.create({
    blockSpecs: {
      ...defaultBlockSpecs,
      quiz: createReactBlockSpec({
        type: "quiz",
        propSchema: {
          quizSelected: { type: 'object', default: null },
          quizzesData: { type: 'array', required: true },
          homeCompany: { type: 'string', required: true },
        },
        content: "inline",
      }, {
        render: (props) => <Quiz {...props} quizzesData={quizzesData} homeCompany={homeCompany} type="Preview" />,
      }),
      checkpoint: createReactBlockSpec({
        type: "checkpoint",
        propSchema: {
          checkpointSelected: { type: 'object', default: null },
          homeCompany: { type: 'string', required: true },
        },
        content: "inline",
      }, {
        render: (props) => <Checkpoint {...props} />,
      }),
      alert: Alert,
    },
  });

  const editor = useCreateBlockNote({
    schema,
  });

  const removeAllBlocks = () => {
    if (editor) {
      const allBlockIDsToRemove = editor.document.map(block => block.id);
      editor.removeBlocks(allBlockIDsToRemove);
    }
  };

  const loadAllBlocks = () => {
    if (editor) {
      const allBlocksToLoad = props.modalDataSection?.as_json;
      editor.insertBlocks(allBlocksToLoad, editor.document[0]?.id || null, "before");
    }
  }

  React.useEffect(() => {
    if (!isLoading && props.modalDataSection?.as_json.length > 0) {
      loadAllBlocks()
    }
  }, [isLoading])

  React.useEffect(() => {
    setSelectedModuleIndex(0)
  }, [props.open])

  function refresh_section() {
    fetchSectionDetail(true, selectedSectionUUID)
  }

  console.log("TRACK PREVIEW MODAL DATA SECTION", props.modalDataSection)


  // Need to do
  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          removeAllBlocks()
          setSelectedModuleIndex(0)
          setSelectedSectionIndex(0)
          props.handleClose()
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div className="w-full h-full flex">
            {showMenu &&
              <div className="w-[25%] min-w-[225px] bg-white h-full overflow-auto">
                <div className="px-4 pt-4 pb-6 border-b border-slate-200">
                  <div
                    className="flex items-center space-x-1.5 text-slate-500 hover:text-slate-600 cursor-pointer"
                    onClick={() => setShowMenu(false)}
                  >
                    <div>
                      <MenuIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
                    </div>
                    <p className="text-slate-500 text-xs">Hide</p>
                  </div>
                  <h2 className="mt-8 text-2xl">{props.modalDataTrack?.name}</h2>
                  <div className="mt-3 flex items-center space-x-2">
                    <div className={`w-3 h-3 rounded bg-violet-700`}></div>
                    <p className="text-slate-900 text-xs">{modules?.length} {modules?.length === 1 ? "module" : "modules"}</p>
                  </div>
                  <div className="mt-6 flex items-center justify-between">
                    <p className="text-slate-500 text-xxs">0% completed</p>
                    {/* <p className="text-slate-500 text-xxs">0 / {props.modalDataTrack?.modules?.length}</p> */}
                  </div>
                  <div className="mt-1 w-full h-1.5 rounded bg-slate-200">
                  </div>
                </div>
                <div>
                  {modules?.map((module, index) => {
                    return(
                      <div key={index}>
                        <TrackSidebarModuleDropdown
                          module={module}
                          index={index}
                          selectedModuleIndex={selectedModuleIndex}
                          setSelectedModuleIndex={setSelectedModuleIndex}
                          fetchSectionDetail={fetchSectionDetail}
                          removeAllBlocks={removeAllBlocks}
                          selectedSectionIndex={selectedSectionIndex}
                          setSelectedSectionIndex={setSelectedSectionIndex}
                          setSelectedSectionUUID={setSelectedSectionUUID}
                          isLoading={isLoading}
                          type="Preview"
                          userType="Manager"
                        />
                      </div>
                    )}
                  )}
                </div>
              </div>
            }
            {showMenu &&
              <div className="w-2 bg-slate-100 shadow-inner"></div>
            }
            <div className="flex-1 h-full">
              <div className="w-full h-full flex flex-col">
                <div>
                  <div className="w-full py-5 bg-white flex justify-center shadow relative">
                    <p className="text-slate-500 text-sm">Module {selectedModuleIndex + 1} {selectedSectionIndex !== null && `- Section ${selectedSectionIndex + 1}`}</p>
                    {!showMenu &&
                      <div className="absolute left-2 top-2">
                        <div
                          className="flex items-center space-x-1 text-slate-500 hover:text-slate-600 cursor-pointer"
                          onClick={() => setShowMenu(true)}
                        >
                          <div>
                            <MenuIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div
                    className="absolute right-2 top-2 text-gray-500 cursor-pointer z-40"
                    onClick={() => {
                      removeAllBlocks()
                      setSelectedModuleIndex(0)
                      setSelectedSectionIndex(0)
                      props.handleClose()
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <div className="h-2 bg-slate-100 shadow-inner"></div>
                </div>
                <div className="w-full flex-1 relative pt-8 overflow-auto bg-white shadow">
                  {!isLoading ?
                    <div>
                      <BlockNoteView
                        editor={editor}
                        editable={false}
                        theme="light"
                      />
                    </div> :
                    <div className="w-full flex-1">
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <LoadSpinner />
                      </div>
                    </div>
                  }
                </div>
                <div className="w-full h-[10%] flex flex-col">
                  <div className="w-full h-2 bg-slate-100 shadow-inner"></div>
                  <div className="w-full bg-white flex justify-between px-6 flex-1 shadow">
                    <div className="flex items-center space-x-4">
                      <div
                        className="flex items-center gap-x-3 px-3 py-1 rounded bg-violet-50 hover:bg-violet-100 text-violet-700 cursor-pointer"
                        onClick={() => {
                          removeAllBlocks()
                          handleOpenTrackInputsModal()
                        }}
                      >
                        <div>
                          <TimelineIcon />
                        </div>
                        <p>
                          Edit Track
                        </p>
                      </div>
                      {modules.length > 0 &&
                        <div
                          className="flex items-center gap-x-3 px-3 py-1 rounded bg-violet-50 hover:bg-violet-100 text-violet-700 cursor-pointer"
                          onClick={() => {
                            removeAllBlocks()
                            props.setModalDataModule(modules?.[selectedModuleIndex])
                            handleOpenModuleInputsModal()
                          }}
                        >
                          <div>
                            <ViewModuleIcon />
                          </div>
                          <p>Edit Module</p>
                        </div>
                      }
                      {modules.length > 0 &&
                        <div
                          className="flex items-center gap-x-3 px-3 py-1 rounded bg-violet-50 hover:bg-violet-100 text-violet-700 cursor-pointer"
                          onClick={() => {
                            removeAllBlocks()
                            fetchSectionDetail(true, selectedSectionUUID)
                            handleOpenSectionInputsModal()
                          }}
                        >
                          <div>
                            <ViewHeadlineIcon />
                          </div>
                        <p>Edit Section</p>
                        </div>
                      }
                    </div>
                    <div className="flex items-center space-x-4">
                      {firstSectionInTrack ?
                        <div></div> :
                      firstSectionInModule ?
                        <div
                          className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                          onClick={() => {
                            removeAllBlocks()
                            fetchSectionDetail(true, modules?.[selectedModuleIndex - 1]?.sections?.[(modules?.[selectedModuleIndex - 1].sections?.length) - 1]?.uuid)
                            setSelectedSectionIndex((modules?.[selectedModuleIndex - 1].sections?.length) - 1)
                            setSelectedModuleIndex(selectedModuleIndex - 1)
                          }}
                        >
                          <div>
                            <ChevronLeftIcon />
                          </div>
                          <p>Last section</p>
                        </div> :
                        <div
                          className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                          onClick={() => {
                            removeAllBlocks()
                            fetchSectionDetail(true, modules?.[selectedModuleIndex]?.sections?.[selectedSectionIndex - 1]?.uuid)
                            setSelectedSectionIndex(selectedSectionIndex - 1)
                          }}
                        >
                          <div>
                            <ChevronLeftIcon />
                          </div>
                          <p>Last section</p>
                        </div>
                      }
                      {lastSectionInTrack ?
                        <div
                          className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                          onClick={() => {
                            removeAllBlocks()
                            props.handleClose()
                          }}
                        >
                          <p>Finish Track</p>
                          <div>
                            <ChevronRightIcon />
                          </div>
                        </div> :
                      lastSectionInModule ?
                        <div
                          className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                          onClick={() => {
                            removeAllBlocks()
                            fetchSectionDetail(true, modules?.[selectedModuleIndex + 1]?.sections?.[0]?.uuid)
                            setSelectedModuleIndex(selectedModuleIndex + 1)
                            setSelectedSectionIndex(0)
                          }}
                        >
                          <p>Next section</p>
                          <div>
                            <ChevronRightIcon />
                          </div>
                        </div> :
                        <div
                          className="py-2 px-4 bg-violet-700 hover:bg-violet-800 rounded-lg text-sm text-white flex items-center justify-center gap-x-1.5 cursor-pointer"
                          onClick={() => {
                            removeAllBlocks()
                            fetchSectionDetail(true, modules?.[selectedModuleIndex]?.sections?.[selectedSectionIndex + 1]?.uuid)
                            setSelectedSectionIndex(selectedSectionIndex + 1)
                          }}
                        >
                          <p>Next section</p>
                          <div>
                            <ChevronRightIcon />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <TrackInputsModal
        open={openTrackInputsModal}
        handleClose={handleCloseTrackInputsModal}
        modalEdit={true}
        modalDataTrack={props.modalDataTrack}
        setModalDataTrack={props.setModalDataTrack}
        homeCompany={props.homeCompany}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        modulesData={props.modulesData}
        sectionsData={props.sectionsData}
        roleNames={props.roleNames}
        post_learning_track={props.post_learning_track}
        delete_learning_track={props.delete_learning_track}
        post_learning_module={props.post_learning_module}
        post_learning_section={props.post_learning_section}
        quizzesData={props.quizzesData}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        type="Preview"
        selectedTrack={props.selectedTrack}
        setSelectedTrack={props.setSelectedTrack}
        removeAllBlocks={removeAllBlocks}
      />
      <ModuleInputsModal
        open={openModuleInputsModal}
        handleClose={handleCloseModuleInputsModal}
        modalEdit={true}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        homeCompany={props.homeCompany}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        sectionsData={props.sectionsData}
        post_learning_module={props.post_learning_module}
        delete_learning_module={props.delete_learning_module}
        post_learning_section={props.post_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
        type="Preview"
        removeAllBlocks={removeAllBlocks}
        selectedModule={selectedModule}
        setSelectedModule={setSelectedModule}
      />
      <SectionInputsModal2
        open={openSectionInputsModal}
        handleClose={handleCloseSectionInputsModal}
        modalEdit={true}
        isLoading={isLoading}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        post_learning_section={props.post_learning_section}
        homeCompany={props.homeCompany}
        delete_learning_section={props.delete_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        quizzesData={props.quizzesData}
        type="Preview"
        removeAllBlocks={removeAllBlocks}
        refresh_section={refresh_section}
      />
    </>
  );
}

export default TrackPreviewModal;
