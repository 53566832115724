import React from 'react'
import VisualCustomLineChartForChartCard from './VisualCustomLineChartForChartCard';
import { generateLaborSummary } from '../../../../helpers/helpers';
import { get_t_str } from '../../../../helpers/helpers';

function DailyChartCard(props) {
  let data;
  let label1;
  let label2;
  if (props.earned_toggle) {
    label1 = "Earned";
    label2 = "Used";
    data = generateLaborSummary(
      props.calculated_earned_labor_day,
      props.actual_used_labor_vs_earned_day,
      get_t_str,
      label1,
      label2
    );
  } else {
    label1 = "Forecasted";
    label2 = "Scheduled";
    data = generateLaborSummary(
      props.forecasted_labor_day,
      props.actual_schedule_labor_day,
      get_t_str,
      label1,
      label2
    );
  }
  return (
    <VisualCustomLineChartForChartCard
      data={data.summary}
      roleTypes={data.roleTypes}
      date_name={props.date_name}
      height={110}
      isTable={false}
      label1={label1}
      label2={label2}
      index={props.index}
      windowWidth={props.windowWidth}
      forecasted_labor_day={props.forecasted_labor_day}
      actual_schedule_labor_day={props.actual_schedule_labor_day}
      calculated_earned_labor_day={props.calculated_earned_labor_day}
      actual_used_labor_vs_earned_day={props.actual_used_labor_vs_earned_day}
      agg_column={props.agg_column}
      earned_toggle={props.earned_toggle}
    />
  )
}

export default DailyChartCard
