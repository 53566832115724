import React from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';

export default function ApplyModal(props) {
  return(
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={props.style}>
          <div className="w-full flex flex-col justify-center items-center px-4">
            <h2 className="text-2xl text-center">Apply <span className="font-bold">{props.version}</span> As Current Labor Model</h2>
            <p className="text-base text-center mt-5">Applying <span className="font-bold">{props.version}</span> as the current labor model will implement all the routines, tasks, and task information in generating the demand forecast and auto schedule. It will go into effect the next time the forecasts runs.</p>
            <div className="mt-7">
              <Button variant="contained" className="flex items-center gap-2"><CheckIcon />Apply</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
