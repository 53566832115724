import React from 'react';

const DrawerFooterContainerLarge = ({ children }) => {
  return (
    <div className="w-full absolute bottom-0 h-[150px] bg-white border-t border-slate-300 px-6 flex flex-col items-center justify-center">
      {children}
    </div>
  );
};

export default DrawerFooterContainerLarge;
