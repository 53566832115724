import React from "react";
import { RosterGraphD3 } from "./RosterGraphD3";

export function RosterGraph({ data, chartid, get_t_str, role_types }) {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    let destroyFn;

    if (containerRef.current) {
      const { destroy } = RosterGraphD3(
        containerRef.current,
        data,
        chartid,
        get_t_str,
        role_types
      );
      destroyFn = destroy;
    }

    return destroyFn;
  }, [data]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <section className="Main" style={{ textAlign: "center" }}>
        <div id={chartid} ref={containerRef} />
      </section>
    </div>
  );
}
