import React from "react";
import { Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";
import { formatDateReadable, getInitials } from "../../utilities/helpers";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import TradeTargetSelectModal from "./TradeTargetSelectModal";
import ShiftDropAddNoteModal from "./ShiftDropAddNoteModal";
import DrawerHeader from "../helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../helpers/DrawerFooterContainerLarge";
import ButtonFilled from "../helpers/ButtonFilled";
import ButtonGrayed from "../helpers/ButtonGrayed";

function ShiftDropDrawer(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleRescindDropShift() {
    props.rescind_drop_shift(props.shiftInfo.id);
  }

  function handleRescindTradeShift() {
    props.rescind_trade_shift(props.shiftInfo.id);
  }

  const [openAddDropNoteModal, setOpenAddDropNoteModal] = React.useState(false);
  const handleOpenAddDropNoteModal = () => setOpenAddDropNoteModal(true);
  const handleCloseAddDropNoteModal = () => setOpenAddDropNoteModal(false);

  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader title="Selected Shift" onClose={props.onClose} />
          <div>
            <div className="w-full flex justify-center mt-3">
              <div
                className={`w-16 h-16 ${
                  props.shiftCardColor ? props.shiftCardColor : "bg-violet-700"
                } text-white flex items-center justify-center rounded-full text-xl`}
              >
                {getInitials(props.firstName, props.lastName)}
              </div>
            </div>
            <div className="text-slate-900 text-xl w-full flex justify-center mt-2">
              <p>
                {props.firstName} {props.lastName}
              </p>
            </div>
            <div className="w-full mt-8 px-6">
              {props.badgeText && (
                <div className="flex flex-row items-center justify-between bg-amber-100 px-6 py-3 rounded-2xl">
                  <div className="flex flex-row items-center space-x-3 text-amber-800">
                    <WarningAmberIcon />
                    <p className="text-amber-800 text-center text-base">
                      {props.badgeText}
                    </p>
                  </div>
                  {props.badgeText !== "Your Shift" && (
                    <div
                      className="px-4 py-2 bg-yellow-600 hover:bg-yellow-700 rounded-xl cursor-pointer"
                      onClick={
                        props.badgeText === "Drop Requested" ||
                        props.badgeText === "Add to Pool Requested"
                          ? () => handleRescindDropShift()
                          : () => handleRescindTradeShift()
                      }
                    >
                      <p className="text-white">Cancel</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="px-6">
              <div className="w-full mt-6 pb-1">
                <h3 className="text-slate-500 text-base">Date</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {formatDateReadable(props.date)}
                </p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Time</h3>
                <div className="flex flex-row items-center mt-1.5 text-slate-900">
                  <p className="text-xl">{props.shiftStartNumber}</p>
                  <p className="text-xl ml-1">{props.shiftStartLetters}</p>
                  <div className="ml-3 text-slate-600">
                    <ArrowForwardIcon color="#475569" size={22} />
                  </div>
                  <p className="text-xl ml-3">{props.shiftEndNumber}</p>
                  <p className="text-xl ml-1">{props.shiftEndLetters}</p>
                </div>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Break</h3>
                <p className="mt-1 text-xl text-slate-900">{props.break}</p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Role</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {props.station
                    ? props.station
                    : props.role
                    ? props.role
                    : props.roleType}
                </p>
              </div>
              {props.note && (
                <div className="w-full mt-3 pb-1">
                  <h3 className="text-slate-500 text-base">Note</h3>
                  <p className="mt-1 text-xl text-slate-900">{props.note}</p>
                </div>
              )}
            </div>
            {/* <div className="mt-3">
              <div className="w-full rounded-2xl bg-white border border-slate-200 px-5 py-6 flex flex-row items-center relative">
                <div className="flex-1">
                  <div>
                    <p className="text-slate-600">{props.date}</p>
                  </div>
                  <div className="flex flex-row items-center mt-1.5">
                    <p className="text-slate-900 text-[28px] font-bold">{props.shiftStartNumber}</p>
                    <p className="text-slate-600 text-lg ml-1">{props.shiftStartLetters}</p>
                    <div className="ml-3 text-slate-600">
                      <ArrowForwardIcon color="#475569" size={22} />
                    </div>
                    <p className="text-slate-900 text-[28px] font-bold ml-3">{props.shiftEndNumber}</p>
                    <p className="text-slate-600 text-lg ml-1">{props.shiftEndLetters}</p>
                  </div>
                  <div className="mt-1.5">
                    <p className="text-slate-600">{props.role} @ {props.location}</p>
                  </div>
                </div>
                {props.badgeText && (
                  <div className="bg-amber-50 px-2 py-1 rounded-lg absolute bottom-2 right-2 flex items-center space-x-1">
                    {(props.badgeText === "Drop Requested" || props.badgeText === "Offered Trade") &&
                      <Tooltip title={props.badgeText === "Drop Requested" ? "Cancel Drop Request" : "Cancel Trade Offer"}>
                        <button
                          className="flex items-center justify-center text-red-600"
                          onClick={props.badgeText === "Drop Requested" ?
                            () => handleRescindDropShift() :
                            () => handleRescindTradeShift()
                          }
                        >
                          <HighlightOffIcon style={{ fontSize: "16px" }}/>
                        </button>
                      </Tooltip>
                    }
                    <p className="text-amber-800 text-sm">{props.badgeText}</p>
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <DrawerFooterContainerLarge>
            {!props.badgeText && !props.isDateInPast ? (
              <div className="w-full">
                <div className="w-full">
                  <ButtonFilled text="Trade" onClick={handleOpen} />
                </div>
                <div className="w-full mt-3">
                  <ButtonFilled
                    text="Add to Pool"
                    // onClick={props.handleAddToPoolPress}
                    onClick={handleOpenAddDropNoteModal}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full">
                  <ButtonGrayed text="Trade" />
                </div>
                <div className="w-full mt-3">
                  <ButtonGrayed text="Add to Pool" />
                </div>
              </div>
            )}
          </DrawerFooterContainerLarge>
          <div className="h-[210px]"></div>
        </div>
      </div>
      <ShiftDropAddNoteModal
        open={openAddDropNoteModal}
        handleClose={handleCloseAddDropNoteModal}
        handleAddToPoolPress={props.handleAddToPoolPress}
      />
      <TradeTargetSelectModal
        open={open}
        handleClose={handleClose}
        drop_shift={props.drop_shift}
        trade_shift={props.trade_shift}
        shiftInfo={props.shiftInfo}
        notMyUpcomingShifts={props.notMyUpcomingShifts}
        roleNames={props.roleNames}
      />
    </Drawer>
  );
}

export default ShiftDropDrawer;
