import React from 'react';

const TaskStatusBadge = ( props ) => {
  const statusConfig = {
    completed_late: {
      className: "bg-orange-100 border border-orange-300 text-orange-700",
      label: "Submitted Late"
    },
    completed: {
      className: "bg-emerald-100 border border-emerald-300 text-emerald-700",
      label: "Submitted"
    },
    in_progress: {
      className: "bg-yellow-100 border border-yellow-300 text-yellow-700",
      label: "In progress"
    },
    overdue: {
      className: "bg-rose-100 border border-rose-300 text-rose-700",
      label: "Overdue"
    },
    not_started: {
      className: "bg-slate-100 border border-slate-300 text-slate-700",
      label: "Not Started"
    }
  };

   const defaultConfig = {
    className: "bg-slate-100 border border-slate-300 text-slate-700",
    label: "Not Started"
  };

  const config = statusConfig[props.status] || defaultConfig;

  return (
    <span className={`py-1 rounded-full ${props.size === "large" ? "px-3 text-sm" : "px-2 text-xxs"} w-fit ${config.className}`}>
      {config.label} {(props.status === "completed" || props.status === "completed_late") && <span> @ {props.submitted_at}</span>}
    </span>
  );
};

export default TaskStatusBadge;
