import React from 'react'
import ShiftCard from '../ShiftCard'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ResizableDailyBreakCard from './ResizeableDailyBreakCard';

function ResizableDailyShiftCard(props) {
  const [currentShiftInfo, setCurrentShiftInfo] = React.useState(props.shift_info);
  const [appliedTo, setAppliedTo] = React.useState([false, false, false, false, false, false, false]);
  const [dragging, setDragging] = React.useState(null);
  const [dragStart, setDragStart] = React.useState(null);
  const [showBreak, setShowBreak] = React.useState(true)

  React.useEffect(() => {
    setCurrentShiftInfo(props.shift_info)
  }, [props.shift_info])

  React.useEffect(() => {
    let appliedToCopy = [false, false, false, false, false, false, false];
    appliedToCopy[props.d] = true;
    setAppliedTo(appliedToCopy);
  }, []);

  const handleMouseDown = (e, type) => {
    e.stopPropagation();
    setDragging(type);
    setDragStart(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const sensitivity = 0.012;
      const rawShiftAmount = (e.clientX - dragStart) * sensitivity;
      const shiftAmount = rawShiftAmount;

      setCurrentShiftInfo((prevShiftInfo) => {
        const newShiftInfo = { ...prevShiftInfo };
        if (dragging === 'start') {
          newShiftInfo.shift_start += shiftAmount;
          newShiftInfo.shift_length -= shiftAmount;
        } else if (dragging === 'end') {
          newShiftInfo.shift_end += shiftAmount;
          newShiftInfo.shift_length += shiftAmount;
        } else if (dragging === 'whole') {
          newShiftInfo.shift_start += shiftAmount;
          newShiftInfo.break_start += shiftAmount;
          newShiftInfo.shift_end += shiftAmount;
        }

        newShiftInfo.hours = newShiftInfo.shift_length - newShiftInfo.break_length;

        return newShiftInfo;
      });

      setDragStart(e.clientX);
    }
  };

  const handleMouseUp = () => {
    if (dragging) {
      setDragging(null);
      setDragStart(null);

      setCurrentShiftInfo((prevShiftInfo) => {
        const roundToQuarter = (value) => {
          return Math.round(value * 4) / 4;
        };

        const newShiftInfo = { ...prevShiftInfo };
        newShiftInfo.shift_start = roundToQuarter(newShiftInfo.shift_start);
        newShiftInfo.shift_end = roundToQuarter(newShiftInfo.shift_end);
        newShiftInfo.shift_length = newShiftInfo.shift_end - newShiftInfo.shift_start;
        if ((newShiftInfo.break_start < newShiftInfo.shift_start) || ((newShiftInfo.break_start + newShiftInfo.break_length) > newShiftInfo.shift_end)) {
          newShiftInfo.break_start = (((newShiftInfo.shift_end + newShiftInfo.shift_start) / 2) * 4) / 4 - (newShiftInfo.break_length / 2)
        }
        newShiftInfo.hours = newShiftInfo.shift_length - newShiftInfo.break_length;
        newShiftInfo.break_start = roundToQuarter(newShiftInfo.break_start)

        props.addShift(newShiftInfo, 'edit', appliedTo, true);

        return newShiftInfo;
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, dragStart]);


  return (
    <>
      <div
        style={{
          height: 32,
          width: currentShiftInfo?.shift_length * props.hour_increment + "%",
          marginLeft: (currentShiftInfo?.shift_start - props.previousEndTime) * props.hour_increment + "%",
          zIndex: 9,
          position: "relative",
          cursor: 'default'
        }}
      >
        {!props.day_in_past && !props.multipleShifts && !currentShiftInfo?.is_copilot_suggestion && (
          <>
            <div
              onMouseDown={(e) => handleMouseDown(e, 'start')}
              style={{
                position: 'absolute',
                left: -5,
                top: 0,
                bottom: 0,
                width: 10,
                cursor: 'ew-resize',
                zIndex: 8
              }}
            />
            <div
              onMouseDown={(e) => handleMouseDown(e, 'end')}
              style={{
                position: 'absolute',
                right: -5,
                top: 0,
                bottom: 0,
                width: 10,
                cursor: 'ew-resize',
                zIndex: 8
              }}
            />
            {showBreak &&
              <div
                className="text-white"
                style={{
                  position: 'absolute',
                  right: 20,
                  top: 2,
                  bottom: 0,
                  width: 20,
                  height: 20,
                  zIndex: 9999,
                  cursor: 'move'
                }}
                onMouseDown={(e) => handleMouseDown(e, 'whole')}
              >
                <DragIndicatorIcon />
              </div>
            }
          </>
        )}
        <ShiftCard
          {...props}
          shift_info={currentShiftInfo}
          setShowBreak={setShowBreak}
          isCopilotOn={props.isCopilotOn}
        />
      </div>
      {currentShiftInfo.break_length > 0 && showBreak &&
        <ResizableDailyBreakCard
          shift_info={currentShiftInfo}
          day_in_past={props.day_in_past}
          multipleShifts={props.multipleShifts}
          hour_increment={props.hour_increment}
          previousEndTime={props.previousEndTime}
          d={props.d}
          addShift={props.addShift}
        />
      }
    </>
  )
}

export default ResizableDailyShiftCard
