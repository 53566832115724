import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ShiftsTableRowEmployee from "../shiftswaps/ShiftsTableRowEmployee";
import { add_shift_attributes, formatDate } from "../../utilities/helpers";
import Divider from "@mui/material/Divider";
import { formatDateReadable } from "../../utilities/helpers";
import { formatDateShift } from "../../utilities/helpers";
import ScheduleSelectedShift from "./ScheduleSelectedShift";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/Info";
import ScheduleSelectedAlsoWorkingShift from "./ScheduleSelectedAlsoWorkingShift";
import RequestHeader from "../../shared/helpers/RequestHeader";
import NoShiftBar from "../../shared/helpers/NoShiftBar";

export default function ScheduleDisplayEmployee(props) {
  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );
  const [selectedShift, setSelectedShift] = React.useState([]);
  const [selectedAlsoWorking, setSelectedAlsoWorking] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(
    formatDate(new Date())
  );

  const changeMonth = (direction) => {
    if (direction === "next") {
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
    } else if (direction === "prev") {
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
    }
  };

  const scheduleData2 = props.scheduleData?.map((v, i) =>
    add_shift_attributes(v)
  );

  const myShifts = scheduleData2?.filter(
    (v, i) => v.store_team_member?.id === props.myId
  );

  const alsoWorking = scheduleData2?.filter(
    (v, i) => v.store_team_member?.id !== props.myId
  );

  function isMyShift(date, myShifts) {
    return myShifts?.some((shift) => shift.date === date);
  }

  const [days, setDays] = React.useState([]);

  React.useEffect(() => {
    function isDateInCurrentMonth(date) {
      return (
        date.getMonth() === currentMonth && date.getFullYear() === currentYear
      );
    }

    const today = new Date(); // Get the current date

    // Calculate the first day of the current month
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay() + 1;

    // Calculate the number of days in the previous month to display
    const daysInPrevMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    // Calculate the total number of days to display (including days from the previous month)
    const totalDays = 35;

    // Transform the received data and add the currentMonth field
    const transformedData = [];
    const currentDate = new Date(
      currentYear,
      currentMonth,
      1 - daysInPrevMonth
    ); // Start with the first day to display
    let initialSelectedShift = {};
    let initialSelectedAlsoWorking = [];

    for (let i = 0; i < totalDays; i++) {
      const date = currentDate.toISOString().split("T")[0];
      const isInCurrentMonth = isDateInCurrentMonth(currentDate);

      // Check if the current date matches the loop date
      const isSelected = formatDate(currentDate) === selectedDate;

      console.log(formatDate(new Date()));
      console.log(formatDate(currentDate));

      // Find the corresponding shift data in the receivedData, if it exists
      const shiftData = myShifts?.filter((shift) => shift.date === date);

      const alsoWorkingData =
        alsoWorking?.filter((day) => day.date === date) || [];

      // Calculate if the current date is today's date
      const isToday =
        currentDate.getFullYear() === today.getFullYear() &&
        currentDate.getMonth() === today.getMonth() &&
        currentDate.getDate() === today.getDate();

      // if (isSelected) {
      //   // Set initialSelectedShift and initialSelectedAlsoWorking for the current day
      //   initialSelectedShift = shiftData ? [ ...shiftData ] : {};
      //   initialSelectedAlsoWorking = [...alsoWorkingData];
      // }

      transformedData.push({
        date,
        shift: shiftData ? [...shiftData] : null,
        alsoWorking: alsoWorkingData ? [...alsoWorkingData] : [],
        isCurrentMonth: isInCurrentMonth,
        isSelected, // Set isSelected based on the current date
        isToday,
      });

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Set the initial values for selectedShift and selectedAlsoWorking
    // setSelectedShift(initialSelectedShift);
    // setSelectedAlsoWorking(initialSelectedAlsoWorking);

    // Set the state with the transformed data
    setDays(transformedData);
  }, [currentMonth, currentYear, props.scheduleData]);

  React.useEffect(() => {
    const shiftData = myShifts?.filter((shift) => shift.date === selectedDate);
    const alsoWorkingData =
      alsoWorking?.filter((day) => day.date === selectedDate) || [];
    setSelectedShift(shiftData ? shiftData : []);
    setSelectedAlsoWorking(alsoWorkingData ? alsoWorkingData : []);
  }, [props.scheduleData]);

  console.log(selectedShift);

  const selectDate = (dayIdx, shiftInfo, alsoWorking) => {
    const newDays = days?.map((day, index) => {
      if (index === dayIdx) {
        return { ...day, isSelected: true };
      } else {
        return { ...day, isSelected: false };
      }
    });
    setDays(newDays);

    if (shiftInfo) {
      setSelectedShift([...shiftInfo]);
    } else {
      // If no shift is associated with the selected date, reset selectedShift
      setSelectedShift([]);
    }

    if (alsoWorking) {
      setSelectedAlsoWorking([...alsoWorking]);
    } else {
      // If there are no other workings on the selected date, reset selectedAlsoWorking
      setSelectedAlsoWorking([]);
    }

    // Update selectedDate with the selected date
    setSelectedDate(days[dayIdx].date);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let notMyUpcomingShifts = props.notMyUpcomingShifts?.map((v, i) =>
    add_shift_attributes(v)
  );

  function isDateInPast(dateStr) {
    const currentDate = new Date();
    // Compare the date objects and return the result as a boolean
    return dateStr < formatDate(currentDate);
  }

  console.log("DAYS", days);

  return (
    <div>
      {/* <RequestHeader title="Schedule" /> */}
      <div>
        <div className="w-full flex mt-12 px-12">
          <div className="text-center w-[62%] border-r border-slate-200 pr-12 pb-8">
            <div className="w-full flex justify-center items-center">
              <div className="flex items-center text-slate-900 w-[50%] margin-auto">
                <button
                  type="button"
                  className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-500 hover:text-slate-600"
                  onClick={() => changeMonth("prev")}
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon />
                </button>
                <div className="flex-auto font-semibold text-lg">
                  {months[currentMonth]} {currentYear}
                </div>
                <button
                  type="button"
                  className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-500 hover:text-slate-600"
                  onClick={() => changeMonth("next")}
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon />
                </button>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-slate-500">
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                (dayOfWeek) => (
                  <div key={dayOfWeek}>{dayOfWeek}</div>
                )
              )}
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-slate-200 text-sm shadow ring-1 ring-slate-200">
              {days?.map((day, dayIdx) => (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    "py-10 hover:bg-slate-100 focus:z-10 relative",
                    day.isCurrentMonth ? "bg-white" : "bg-slate-50",
                    (day.isSelected || day.isToday) && "font-semibold",
                    day.isSelected && "text-white",
                    !day.isSelected &&
                      day.isCurrentMonth &&
                      !day.isToday &&
                      "text-slate-900",
                    !day.isSelected &&
                      !day.isCurrentMonth &&
                      !day.isToday &&
                      "text-slate-200",
                    day.isToday && !day.isSelected && "text-violet-700",
                    dayIdx === 0 && "rounded-tl-lg",
                    dayIdx === 6 && "rounded-tr-lg",
                    dayIdx === days.length - 7 && "rounded-bl-lg",
                    dayIdx === days.length - 1 && "rounded-br-lg"
                  )}
                  onClick={() => selectDate(dayIdx, day.shift, day.alsoWorking)}
                >
                  <div className="flex flex-col justify-center items-center">
                    <div
                      dateTime={day.date}
                      className={classNames(
                        "mx-auto flex h-[62px] w-[62px] items-center justify-center rounded-full absolute",
                        day.isSelected && day.isToday && "bg-violet-700",
                        day.isSelected && !day.isToday && "bg-violet-700"
                      )}
                    >
                      <p>{day.date.split("-").pop().replace(/^0/, "")}</p>
                    </div>
                    {isMyShift(day.date, myShifts) && (
                      <div
                        className={`w-2 h-2 rounded-full ${
                          isDateInPast(day.date)
                            ? day.isSelected
                              ? "bg-yellow-50"
                              : "bg-yellow-600 opacity-20"
                            : day.isSelected
                            ? "bg-yellow-50"
                            : "bg-yellow-600"
                        } absolute bottom-4`}
                      ></div>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div className="flex-1 pl-12">
            <div>
              <h3 className="text-slate-500 text-lg">
                {formatDateShift(selectedDate)}
              </h3>
            </div>
            <div className="mt-3">
              {selectedShift?.length > 0 ? (
                <div>
                  {selectedShift?.map((shift, index) => (
                    <ScheduleSelectedShift
                      firstName={shift?.store_team_member?.first_name}
                      lastName={shift?.store_team_member?.last_name}
                      date={shift?.date}
                      dateFormatted={formatDateShift(shift?.date)}
                      isDateInPast={isDateInPast(shift?.date)}
                      shiftStartNumber={shift?.shift_start_formatted?.[0]}
                      shiftStartLetters={shift?.shift_start_formatted?.[1]}
                      shiftEndNumber={shift?.shift_end_formatted?.[0]}
                      shiftEndLetters={shift?.shift_end_formatted?.[1]}
                      role={shift?.role}
                      station={shift?.station?.name}
                      roleType={shift?.roleType}
                      roleId={shift?.role_id}
                      location={shift?.location}
                      shift={shift}
                      break={shift?.break_formatted}
                      notMyUpcomingShifts={notMyUpcomingShifts}
                      drop_shift={props.drop_shift}
                      trade_shift={props.trade_shift}
                      rescind_drop_shift={props.rescind_drop_shift}
                      rescind_trade_shift={props.rescind_trade_shift}
                      roleNames={props.roleNames}
                      note={shift?.note}
                    />
                  ))}
                </div>
              ) : (
                <NoShiftBar text="You have no shift scheduled!" />
              )}
            </div>
            <div className="mt-3">
              <div className="py-3 border-b border-slate-200 flex items-center justify-between">
                <h5 className="text-sm text-slate-500">Also Working</h5>
                <div className="flex items-center space-x-2">
                  <div className="text-slate-900">
                    <PersonIcon style={{ fontSize: "22px" }} />
                  </div>
                  <p className="text-slate-500">
                    {selectedAlsoWorking?.length}
                  </p>
                </div>
              </div>
              {selectedAlsoWorking && selectedAlsoWorking?.length > 0 ? (
                selectedAlsoWorking?.map((shift, index) => (
                  <div key={index}>
                    <ScheduleSelectedAlsoWorkingShift
                      name={`${shift?.store_team_member?.first_name} ${shift?.store_team_member?.last_name}`}
                      shiftStartNumber={shift?.shift_start_formatted?.[0]}
                      shiftStartLetters={shift?.shift_start_formatted?.[1]}
                      shiftEndNumber={shift?.shift_end_formatted?.[0]}
                      shiftEndLetters={shift?.shift_end_formatted?.[1]}
                      role={shift?.role}
                      station={shift?.station?.name}
                      roleType={shift?.role_type}
                      roleId={shift?.role_id}
                      roleNames={props.roleNames}
                    />
                  </div>
                ))
              ) : (
                <div className="mt-3 text-slate-500 text-sm flex items-center space-x-2">
                  <div className="w-8 h-8 text-slate-900 bg-slate-50 flex items-center justify-center rounded-lg">
                    <InfoIcon style={{ fontSize: "20px" }} />
                  </div>
                  <p>No one else is scheduled today</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="mt-10">
          <h3 className="text-slate-900 text-xl font-bold">{formatDateReadable(selectedDate)}</h3>
          {selectedShift.shift_start ?
            <div>
              <p>{selectedShift.shift_start} - {selectedShift.shift_end}</p>
            </div> :
            <div className="mt-3">No Shift Today</div>
          }
          {selectedAlsoWorking.length > 0 &&
            <div>
              {selectedAlsoWorking.map((employee, index) => (
                <div key={index}>
                  {employee.shift_start} - {employee.shift_end}
                </div>
              ))}
            </div>
          }
        </div> */}
      </div>
    </div>
  );
}
