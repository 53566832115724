import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PersonCardModal from './PersonCardModal';
import { getCardColor2 } from '../../../../helpers/helpers';
import PersonAvatar from './PersonAvatar';

function NoCapableRolesWarningModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setMainRoleId(props.p?.primary_role_id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [mainRoleId, setMainRoleId] = React.useState(
    props.p?.primary_role_id
  );

  const handleMainRoleSelection = (event) => {
    setMainRoleId(event.target.value);
  };

  const name =
    props.p?.first_name +
    " " +
    props.p?.last_name;

  const returnAvatar = () => {
    return (
      <PersonAvatar
        name={name}
        bgColor={getCardColor2(mainRoleId, props.role_names, false)}
      />
    );
  };

  const p = {
    ...props.p,
    available_role_info: props.p?.roles_and_role_types,
  };


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <div className="text-rose-800">
                <WarningIcon style={{ marginBottom: "1px" }} />
              </div>
              <h3 className="text-2xl text-slate-900">No Capable Roles Warning</h3>
            </div>
            <div>
              <p className="text-slate-500 text-sm mt-4">{props.p.first_name} {props.p.last_name} has not been assigned any capable roles yet.</p>
              <p className="text-slate-500 text-sm mt-4">To assign capable roles to {props.p.first_name}, your can either click on their avatar in the schedule display or click the button below.</p>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div className="px-8 py-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.handleClose()
                    handleOpen()
                  }}
                >
                  Open {props.p.first_name}'s Profile
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <PersonCardModal
        handleClose={handleClose}
        open={open}
        p={p}
        role_names={props.role_names}
        updatePersonInfo={props.updatePersonInfo}
        handleMainRoleSelection={handleMainRoleSelection}
        mainRoleId={mainRoleId}
        setMainRoleId={setMainRoleId}
        returnAvatar={returnAvatar}
      />
    </>
  )
}

export default NoCapableRolesWarningModal
