import React from "react";
import Stack from "@mui/material/Stack";
import LoadFromScheduleButton from "./LoadFromScheduleButton";
import LoadFromTemplateButton from "./LoadFromTemplateButton";
import Tooltip from "@mui/material/Tooltip";

function LoadScheduleButtons(props) {
  return (
    <div>
      <Stack direction="row" spacing={1}>
        <LoadFromScheduleButton
          week_already_started={props.week_already_started}
          loadFrom7Shift={props.loadFrom7Shift}
          week_start_date={props.week_start_date}
          load7ShiftsScheduleFinished={props.load7ShiftsScheduleFinished}
          setLoad7ShiftsScheduleFinished={props.setLoad7ShiftsScheduleFinished}
          loadedFrom7Shifts={props.loadedFrom7Shifts}
          has7ShiftsIntegration={props.has7ShiftsIntegration}
        />
        <LoadFromTemplateButton
          week_already_started={props.week_already_started}
          loadFromTemplate={props.loadFromTemplate}
          deleteScheduleTemplate={props.deleteScheduleTemplate}
          template_names={props.template_names}
          loadFromTemplateFinished={props.loadFromTemplateFinished}
          setLoadFromTemplateFinished={props.setLoadFromTemplateFinished}
          loadedFromTemplate={props.loadedFromTemplate}
        />
      </Stack>
    </div>
  );
}

export default LoadScheduleButtons;
