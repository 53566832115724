import React from 'react'
import StoreName from './StoreName'
import Account from './Account'
import Notifications from './Notifications'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function TopBarDisplayUserProfile(props) {
  return (
    <div className="py-5 px-6 border-b border-slate-200 flex items-center justify-between">
      <div
        className="flex items-center space-x-2 text-slate-900 hover:text-slate-700 cursor-pointer"
        onClick={() => props.handleGoBack()}
      >
        <div className="w-9 h-9 rounded-full flex items-center justify-center transition duration-150">
          <ArrowBackIcon />
        </div>
        <h2 className="text-xl">{props.title}</h2>
      </div>
      <div className="flex items-center">
        <div className="mr-6">
          <StoreName
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
          />
        </div>
        <div className="mr-8">
          <Notifications
            homeStore={props.homeStore}
          />
        </div>
        <div className="cursor-pointer">
          <Account />
        </div>
      </div>
    </div>
  )
}

export default TopBarDisplayUserProfile
