import React from "react";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import Tooltip from "@mui/material/Tooltip";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

function DeleteShiftsButton(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function submitAndClose() {
    console.log("Clear Unlocked Shifts");
    props.deleteAllShifts();
    handleClose();
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Tooltip title="Clear Schedule">
        <IconButton onClick={handleOpen}>
          <DeleteSweepIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            {/* <div
              className="absolute right-2 top-2 text-gray-500 cursor-pointer"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ fontSize: "24px" }}
            >
              Delete All Unlocked Shifts
            </Typography>
            <div className="mt-3">
              <Button
                variant="contained"
                endIcon={<DeleteSweepIcon />}
                onClick={() => submitAndClose()}
                sx={{ textTransform: "none", borderRadius: "8px" }}
              >
                {"Delete Shifts"}
              </Button>
            </div> */}
            <div
              className="absolute right-2 top-2 text-gray-500 cursor-pointer"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
            <h3 className="text-xl">Delete all unlocked shifts</h3>
            <div className="w-full flex items-center space-x-4 mt-8">
              <div
                className="px-5 py-2.5 rounded-full border border-slate-200 text-slate-400 hover:bg-slate-50 flex items-center justify-center cursor-pointer"
                onClick={handleClose}
              >
                <p>Cancel</p>
              </div>
              <div className="flex-1">
                <div
                  className="w-full py-2.5 rounded-full bg-rose-600 hover:bg-rose-700 text-white flex items-center justify-center cursor-pointer"
                  onClick={() => submitAndClose()}
                >
                  <p>Delete Shifts</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DeleteShiftsButton;
