import React from "react";
import StoreName from "./StoreName";
import Account from "./Account";
import Notifications from "./Notifications";

function TopBarDisplayStoreOnly(props) {
  return (
    <div className="w-full">
      <div className="flex items-center justify-end">
        <div className="mr-6">
          <StoreName
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
          />
        </div>
        <div className="mr-8">
          <Notifications
            homeStore={props.homeStore}
          />
        </div>
        <div className="h-10 border-l-2 border-gray-400 flex items-center justify-center cursor-pointer">
          <Account />
        </div>
      </div>
    </div>
  );
}

export default TopBarDisplayStoreOnly;
