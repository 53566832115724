import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';


export default function DefaultAvailabilityRequestCard(props) {


  return(
    <div className="flex flex-col">
      <div className={`rounded-xl flex flex-col justify-center px-4 py-2.5 shadow relative ${props.status === "current" ? "bg-slate-100" : props.status === "pending" ? "bg-yellow-200" : props.status === "approved" ? "bg-emerald-200" : "bg-rose-200"}`}>
        <h2 className="text-sm w-32 mt-0.5">{props.day}</h2>
        <div className="mt-1">
          <h3 className="text-xs">{props.availability ? props.availability : "No Availability Set"}</h3>
          {props.availability2 && <h3 className="text-xs mt-1">{props.availability2}</h3>}
          <h4 className="text-xxs text-slate-500">Pref: {props.preferences ? props.preferences : "No Preferences Set"}</h4>
        </div>
        <div className="absolute top-2 right-2">
          {
            props.status === "current" ?
              <div></div> :
            props.status === "pending" ?
              <div className="text-yellow-600 text-xxs">pending</div> :
            props.status === "approved" ?
              <Tooltip title="Approved">
                <div className="w-5 h-5 rounded-full flex items-center justify-center bg-emerald-600 text-white">
                  <CheckIcon style={{ fontSize: "15px" }}/>
                </div>
              </Tooltip> :
            props.status === "rejected" ?
              <Tooltip title="Rejected">
                <div className="w-5 h-5 rounded-full flex items-center justify-center bg-rose-600 text-white">
                  <ClearIcon style={{ fontSize: "15px" }}/>
                </div>
              </Tooltip> :
              <div></div>
          }
        </div>
      </div>
    </div>
  )
}
