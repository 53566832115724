import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TradeTargetSelectModal from "./TradeTargetSelectModal";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";

function ShiftDropModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleRescindDropShift() {
    props.rescind_drop_shift(props.shiftInfo.id);
    props.onClose();
  }

  function handleRescindTradeShift() {
    props.rescind_trade_shift(props.shiftInfo.id);
    props.onClose();
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={props.onClose}
          >
            <CloseIcon />
          </div>
          <h2 className="text-2xl">Selected Shift</h2>
          <div>
            <div className="mt-3">
              <div className="w-full rounded-2xl bg-white border border-slate-200 px-5 py-6 flex flex-row items-center relative">
                <div className="flex-1">
                  <div>
                    <p className="text-slate-600">{props.date}</p>
                  </div>
                  <div className="flex flex-row items-center mt-1.5">
                    <p className="text-slate-900 text-[28px] font-bold">
                      {props.shiftStartNumber}
                    </p>
                    <p className="text-slate-600 text-lg ml-1">
                      {props.shiftStartLetters}
                    </p>
                    <div className="ml-3 text-slate-600">
                      <ArrowForwardIcon color="#475569" size={22} />
                    </div>
                    <p className="text-slate-900 text-[28px] font-bold ml-3">
                      {props.shiftEndNumber}
                    </p>
                    <p className="text-slate-600 text-lg ml-1">
                      {props.shiftEndLetters}
                    </p>
                  </div>
                  <div className="mt-1.5">
                    <p className="text-slate-600">
                      {props.role} @ {props.location}
                    </p>
                  </div>
                </div>
                {props.badgeText && (
                  <div className="bg-amber-50 px-2 py-1 rounded-lg absolute bottom-2 right-2 flex items-center space-x-1">
                    {(props.badgeText === "Drop Requested" ||
                      props.badgeText === "Offered Trade") && (
                      <Tooltip
                        title={
                          props.badgeText === "Drop Requested"
                            ? "Cancel Drop Request"
                            : "Cancel Trade Offer"
                        }
                      >
                        <button
                          className="flex items-center justify-center text-red-600"
                          onClick={
                            props.badgeText === "Drop Requested"
                              ? () => handleRescindDropShift()
                              : () => handleRescindTradeShift()
                          }
                        >
                          <HighlightOffIcon style={{ fontSize: "16px" }} />
                        </button>
                      </Tooltip>
                    )}
                    <p className="text-amber-800 text-sm">{props.badgeText}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full mt-6 flex justify-end">
              {!props.badgeText && !props.isDateInPast ? (
                <div className="flex items-center gap-5">
                  <button
                    className="px-8 py-3 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm"
                    onClick={handleOpen}
                  >
                    Trade
                  </button>
                  <button
                    className="px-8 py-3 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm"
                    onClick={props.handleAddToPoolPress}
                  >
                    Add to Pool
                  </button>
                </div>
              ) : (
                <div className="flex items-center gap-5">
                  <div className="px-8 py-3 rounded-lg bg-gray-200 text-gray-400 text-sm">
                    Trade
                  </div>
                  <div className="px-8 py-3 rounded-lg bg-gray-200 text-gray-400 text-sm">
                    Add to Pool
                  </div>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      <TradeTargetSelectModal
        open={open}
        handleClose={handleClose}
        drop_shift={props.drop_shift}
        trade_shift={props.trade_shift}
        shiftInfo={props.shiftInfo}
        notMyUpcomingShifts={props.notMyUpcomingShifts}
      />
    </>
  );
}

export default ShiftDropModal;
