import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import RemoveIcon from "@mui/icons-material/Remove";
import CircleIcon from "@mui/icons-material/Circle";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

export default function PayoutLogicModal(props) {
  // const [places, setPlaces] = React.useState([
  //   {prizeAmount: "5,000", rank: "1"},
  //   {prizeAmount: "3,000", rank: "2"},
  //   {prizeAmount: "2,500", rank: "3"}
  // ]);

  const [addPlace, setAddPlace] = React.useState(false);
  const [prizeValueInput, setPrizeValueInput] = React.useState();

  function handleAddPlaceClick() {
    setAddPlace((prevState) => !prevState);
  }

  function savePlace() {
    const newPlaces = [
      ...props.metric.competition_payout,
      {
        payout: prizeValueInput,
        rank: props.metric.competition_payout.length + 1,
        color_num: 1,
      },
    ];
    props.setMetric({
      ...props.metric,
      ["competition_payout"]: newPlaces,
    });
    setAddPlace(false);
  }

  function deletePlace() {
    const newPlaces = props.metric.competition_payout.slice(0, -1);
    props.setMetric({
      ...props.metric,
      ["competition_payout"]: newPlaces,
    });
  }

  // const [payouts, setPayouts] = React.useState([
  //   {amount: "1,000", threshold: 100},
  //   {amount: "750", threshold: 90},
  //   {amount: "500", threshold: 80}
  // ])

  const [addPayout, setAddPayout] = React.useState(false);
  const [payoutAmountInput, setPayoutAmountInput] = React.useState();
  const [payoutThresholdInput, setPayoutThresholdInput] = React.useState();

  function handleAddPayoutClick() {
    setAddPayout((prevState) => !prevState);
  }

  function savePayout() {
    const newPayouts = [
      ...props.metric.standalone_payout,
      {
        payout: payoutAmountInput,
        threshold: payoutThresholdInput,
        color_num: 1,
      },
    ];
    props.setMetric({
      ...props.metric,
      ["standalone_payout"]: newPayouts,
    });
    setAddPayout(false);
  }

  function deletePayout() {
    const newPayouts = props.metric.standalone_payout.slice(0, -1);
    props.setMetric({
      ...props.metric,
      ["standalone_payout"]: newPayouts,
    });
  }

  const payout_length = props.metric.competition_payout.length;
  const threshold_length = props.metric.standalone_payout.length;
  return (
    <div>
      <div className="w-full">
        <div className="mt-4">
          <div className="flex items-center gap-1">
            <h3 className="font-bold">Payout Terms</h3>
            <Tooltip
              title={
                props.contestType === "competition"
                  ? "Select the payout amounts based on each location's rank of the metric specified at the end of the contest"
                  : "Select the payout amounts and the corresponding thresholds the location must exceed in order to earn each amount"
              }
            >
              <HelpIcon style={{ fontSize: "20px", color: "gray" }} />
            </Tooltip>
          </div>
        </div>
        {props.contestType === "competition" ? (
          <div className="flex flex-col justify-center items-center">
            <div className="mt-3">
              {props.metric.competition_payout.map((place, index) => (
                <div
                  key={index}
                  className={`flex items-center gap-8 relative ${
                    payout_length < 5 ? "mb-3" : "mb-1"
                  }`}
                >
                  {index === payout_length - 1 && (
                    <button
                      className="absolute top-2 -left-8 text-gray-500"
                      onClick={deletePlace}
                    >
                      <ClearIcon style={{ fontSize: "20px" }} />
                    </button>
                  )}
                  <div
                    className={`w-28 ${
                      payout_length < 5 ? "h-[40px]" : "h-[32px] text-sm"
                    } border border-gray-300 rounded flex justify-center items-center`}
                  >
                    <p className="text-gray-500">${place.payout}</p>
                  </div>
                  <div className="text-gray-500">
                    <EastIcon />
                  </div>
                  <p className="text-gray-500">
                    {place.rank}
                    {place.rank === 1
                      ? "st"
                      : place.rank === 2
                      ? "nd"
                      : place.rank === 3
                      ? "rd"
                      : "th"}{" "}
                    Place
                  </p>
                </div>
              ))}
              <div>
                {addPlace ? (
                  <div className="flex items-center gap-8 mt-3 text-gray-900 relative">
                    <div className="w-28">
                      <TextField
                        id="outlined-basic"
                        label="Payout $"
                        variant="outlined"
                        size="small"
                        value={prizeValueInput}
                        sx={{ fieldset: { border: "1px solid #4b5563" } }}
                        onChange={(event) => {
                          setPrizeValueInput(event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <EastIcon />
                    </div>
                    <p>{payout_length + 1} Place</p>
                    {prizeValueInput && (
                      <button
                        className="absolute top-1 -right-10 rounded text-sm text-gray-500 px-3 py-1"
                        onClick={() => savePlace()}
                      >
                        <SaveIcon />
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center">
                    <button
                      className={`flex items-center gap-1 text-gray-900 text-base px-3 border border-dashed hover:border-solid border-gray-900 hover:bg-gray-300 w-full ${
                        payout_length < 5 ? "h-[40px]" : "h-[32px] text-sm"
                      } rounded flex justify-center items-center`}
                      onClick={handleAddPlaceClick}
                    >
                      <AddIcon style={{ fontSize: "16px" }} />
                      <p>Add new</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col justify-center items-center">
              <div>
                {props.metric.standalone_payout.map((threshold, index) => {
                  let color;
                  if (threshold_length === 1) {
                    color = "text-green-600";
                  } else if (threshold_length === 2) {
                    color = index === 0 ? "text-green-600" : "text-yellow-400";
                  } else if (threshold_length === 3) {
                    color =
                      index === 0
                        ? "text-green-600"
                        : index === 1
                        ? "text-yellow-400"
                        : "text-orange-500";
                  } else if (threshold_length === 4) {
                    color =
                      index === 0
                        ? "text-green-600"
                        : index === 1
                        ? "text-lime-400"
                        : index === 2
                        ? "text-yellow-400"
                        : "text-orange-500";
                  }

                  return (
                    <div className="flex items-center gap-3 mt-2">
                      <div
                        className={`w-28 h-[40px] border border-gray-300 rounded flex justify-center items-center relative`}
                      >
                        {index === threshold_length - 1 && (
                          <button
                            className="absolute top-2 -left-7 text-gray-500"
                            onClick={deletePayout}
                          >
                            <ClearIcon style={{ fontSize: "20px" }} />
                          </button>
                        )}
                        <p className="text-gray-500">${threshold.payout}</p>
                      </div>
                      <div className="relative text-gray-500">
                        <p className="text-sm">
                          If metric is{" "}
                          <span className="text-xl">
                            {props.metric.higher_number_better_ind === 1
                              ? ">"
                              : "<"}
                          </span>
                        </p>
                        {index < threshold_length - 1 && (
                          <p className="absolute left-7 top-8 font-semibold text-xs">
                            OR
                          </p>
                        )}
                      </div>
                      <div
                        className={`w-28 h-[40px] border border-gray-300 rounded flex justify-center items-center relative`}
                      >
                        {threshold_length < 5 && (
                          <div
                            className={`${color} absolute bottom-[18px] left-[3px]`}
                          >
                            <CircleIcon style={{ fontSize: "12px" }} />
                          </div>
                        )}
                        <p className="text-gray-500">
                          {props.addUnits(
                            threshold.threshold,
                            props.metric.relative_value_ind === 1
                              ? "percent"
                              : props.metric.units
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
                <div className="mt-2">
                  {addPayout ? (
                    <div>
                      <div className="flex items-center gap-3 text-gray-900 relative">
                        <div className="w-28">
                          <TextField
                            id="outlined-basic"
                            label="Payout $"
                            variant="outlined"
                            size="small"
                            value={payoutAmountInput}
                            sx={{ fieldset: { border: "1px solid #4b5563" } }}
                            onChange={(event) => {
                              setPayoutAmountInput(event.target.value);
                            }}
                          />
                        </div>
                        <div className="relative">
                          <p className="text-sm">
                            If metric is{" "}
                            <span className="text-xl">
                              {props.metric.higher_number_better_ind === 1
                                ? ">"
                                : "<"}
                            </span>
                          </p>
                          <p className="absolute left-7 bottom-7 font-semibold text-xs">
                            OR
                          </p>
                        </div>
                        <div className="w-28">
                          <TextField
                            id="outlined-basic"
                            label={
                              "Threshold " +
                              (props.metric &&
                                ((props.metric.relative_value_ind === 1) |
                                (props.metric.units === "percent")
                                  ? "%"
                                  : "$"))
                            }
                            variant="outlined"
                            size="small"
                            value={payoutThresholdInput}
                            sx={{ fieldset: { border: "1px solid #4b5563" } }}
                            onChange={(event) => {
                              setPayoutThresholdInput(event.target.value);
                            }}
                          />
                        </div>
                        {payoutAmountInput && payoutThresholdInput && (
                          <button
                            className="absolute top-1 -right-10 rounded text-sm text-gray-500 px-3 py-1"
                            onClick={savePayout}
                          >
                            <SaveIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex items-center mt-2">
                        <button
                          className="flex items-center gap-1 text-gray-900 border border-dashed hover:border-solid border-gray-900 hover:bg-gray-300 text-sm w-full h-[40px] rounded flex justify-center items-center"
                          onClick={handleAddPayoutClick}
                        >
                          <AddIcon style={{ fontSize: "16px" }} />
                          <p>Add new</p>
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="flex items-center gap-3 mt-2">
                    <div
                      className={`w-28 h-[40px] border border-gray-300 rounded flex justify-center items-center`}
                    >
                      <p className="text-gray-500">$0</p>
                    </div>
                    <div className="flex items-center gap-1 text-gray-500">
                      <p className="text-sm">Otherwise</p>
                      <div className="text-red-500">
                        <CircleIcon style={{ fontSize: "16px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
