import React from 'react'
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function TimeClockShiftCard(props) {
  return (
    <div className="w-[275px] border-2 border-slate-200 rounded-xl px-5 py-3.5">
      <div className="flex flex-row items-center">
        <p className="text-slate-900 text-[26px]">
          {props.shiftStart}
        </p>
        <div className="ml-1.5 text-slate-500">
          <ArrowForwardIcon color="#475569" size={22} />
        </div>
        <p className="text-slate-900 text-[26px] ml-1.5">
          {props.shiftEnd}
        </p>
      </div>
      <div>
        <p className="text-slate-500 text-[18px]">
          {props.station ? props.station : props.role ? props.role : props.role_type}
        </p>
        <p className="text-slate-500">
          {props.breakLength === 0
            ? "No Break"
            : props.breakLength === 0.5
            ? "30 min break"
            : props.breakLength === 1
            ? "1 hr break"
            : ""}
        </p>
      </div>
    </div>
  )
}

export default TimeClockShiftCard
