import React from 'react'
import Divider from '@mui/material/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RoomServiceIcon from '@mui/icons-material/RoomService';

export default function GoalsInfoScreen(props) {

  const [automate, setAutomate] = React.useState(false)
  const [sales, setSales] = React.useState(false)
  const [costs, setCosts] = React.useState(false)
  const [evaluate, setEvaluate] = React.useState(false)
  const [service, setService] = React.useState(false)
  const [pinpoint, setPinpoint] = React.useState(false)
  const [incentivize, setIncentivize] = React.useState(false)
  const [employee, setEmployee] = React.useState(false)
  const [labor, setLabor] = React.useState(false)

  return (
    <div>
      <div className="relative">
        <button className="absolute text-gray-100 -left-12 top-1" onClick={props.decreaseStep}>
          <ArrowBackIosIcon style={{ fontSize:"26px" }}/>
        </button>
        <h2 className="text-3xl text-gray-100 font-bold">Let's talk about your goals!</h2>
      </div>
      <div className="mt-5">
        <Divider style={{ backgroundColor: "gray" }}/>
      </div>
      <div className="mt-4">
        <div className="flex flex-col gap-4">
          <div className="mt-4">
            <p className="block mb-1 text-gray-300">What are you most excited to do in RightWork? You can pick multiple.</p>
            <p className="text-xs text-gray-300 mb-4">(This is just to get you started. You can do all of these regardless of what you pick)</p>
            <div className="flex items-center justify-between flex-wrap gap-y-7 mt-3 text-gray-300 text-center text-xs">
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${automate ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setAutomate(prevState => !prevState)}>
                <AutoFixHighIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Automate and standarize your scheduling process</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${sales ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setSales(prevState => !prevState)}>
                <ShowChartIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">More accurately forecast sales (product/channel)</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${costs ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setCosts(prevState => !prevState)}>
                <AttachMoneyIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Decrease your labor costs with more efficient staffing</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${evaluate ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setEvaluate(prevState => !prevState)}>
                <ImageSearchIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Evaluate your schedules for how they meet demand</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${service ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setService(prevState => !prevState)}>
                <RoomServiceIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Increase service level and reduce wait times</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${pinpoint ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setPinpoint(prevState => !prevState)}>
                <MyLocationIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Pinpoint which stores are underperforming and why</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${incentivize ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setIncentivize(prevState => !prevState)}>
                <TrackChangesIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Incentivize your store managers to hit their metrics</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${employee ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setEmployee(prevState => !prevState)}>
                <SentimentVerySatisfiedIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Increase your employee retention rate</p>
              </button>
              <button className={`w-40 h-40 bg-[#252f3f] border-2 ${labor ? "border-orange-500" : "border-[#2f3847]"} rounded-lg p-4 flex flex-col items-center justify-center`} onClick={() => setLabor(prevState => !prevState)}>
                <BarChartIcon style={{ fontSize:"36px" }} />
                <p className="mt-5">Easily forecast and budget labor across the day</p>
              </button>
            </div>
          </div>
          <div className="w-1/2 mt-8">
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full" onClick={props.increaseStep}>
              Next Step
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
