export function formatDate(dateObject) {
  var d = new Date(dateObject),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatDateShift(dateString) {
  return dateObjToShortStr(dateStrToObj(dateString));
}

export function dateObjToShortStr(dateObject) {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };
  return dateObject.toLocaleDateString("en-US", options);
}

export function dateStrToObj(dateString) {
  // Split the date string into its components
  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
  const day = parseInt(parts[2], 10);

  // Construct the Date object in local time
  const dateObject = new Date(year, month, day);
  return dateObject;
}

export const formatDateReadableWithDayOfWeek = (dateString) => {
  const dateObj = dateStrToObj(dateString);

  const dayOfWeekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = dayOfWeekNames[dateObj.getDay()];
  const day = dateObj.getDate();
  const month = monthNames[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  return `${dayOfWeek}, ${month} ${day}, ${year}`;
};

export const formatDateReadableWithDayOfWeekNoYear = (dateString) => {
  const dateObj = dateStrToObj(dateString);

  const dayOfWeekNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = dayOfWeekNames[dateObj.getDay()];
  const day = dateObj.getDate();
  const month = monthNames[dateObj.getMonth()];

  return `${dayOfWeek}, ${month} ${day}`;
};

export function format_time_num(value) {
  let hh_num_temp = value % 24;
  if (value == 24) {
    return "12:00am";
  }
  const hh_num = Math.round(hh_num_temp * 4) / 4;
  var hour_num = Math.trunc(hh_num);
  const daypart = hour_num >= 12 ? "pm" : "am";
  hour_num = hour_num > 12 ? (hour_num -= 12) : hour_num;
  const hh_str = hh_num % 1 === 0 ? ":00" : ":" + (hh_num % 1) * 60;

  let next_day = "";
  if (value > 24) {
    next_day = " (ND)";
  }

  return hour_num + hh_str + daypart + next_day;
}

export function format_shift_output(qh_num) {
  let inputNumber = qh_num % 24;
  if (inputNumber >= 0 && inputNumber <= 24) {
    let timeString, amPm;

    if (inputNumber < 12) {
      timeString = `${Math.floor(inputNumber)}:${Math.round(
        (inputNumber % 1) * 60
      )
        .toString()
        .padStart(2, "0")}`;
      amPm = "AM";
    } else if (inputNumber === 12) {
      timeString = "12:00";
      amPm = "PM";
    } else {
      const pmHour =
        Math.floor(inputNumber) === 12 ? 12 : Math.floor(inputNumber) - 12;
      timeString = `${pmHour}:${Math.round((inputNumber % 1) * 60)
        .toString()
        .padStart(2, "0")}`;
      amPm = "PM";
    }

    return [timeString, amPm];
  } else {
    return null;
  }
}

export function format_break(hour) {
  if (hour >= 0) {
    // Multiply the hour by 60 to convert it to minutes.
    return hour * 60;
  } else {
    return null; // Handle invalid input (negative numbers) as needed.
  }
}

export function add_shift_attributes(shift_info) {
  let new_shift_info = { ...shift_info };
  if (Object.keys(new_shift_info).includes("shift_start")) {
    new_shift_info["shift"] =
      format_time_num(new_shift_info["shift_start"]) +
      " - " +
      format_time_num(new_shift_info["shift_end"]);

    new_shift_info["shift_start_formatted"] = format_shift_output(
      new_shift_info["shift_start"]
    );
    new_shift_info["shift_end_formatted"] = format_shift_output(
      new_shift_info["shift_end"]
    );
    new_shift_info["break"] =
      new_shift_info["break_length"] > 0
        ? format_time_num(new_shift_info["break_start"]) +
          " (" +
          new_shift_info["break_length"] +
          " hrs)"
        : "None";

    new_shift_info["break_formatted"] =
      new_shift_info["break_length"] > 0
        ? format_break(new_shift_info["break_length"]) + " min break"
        : // "@ " +
          // format_time_num(new_shift_info["break_start"])
          "No Break";

    new_shift_info["name"] =
      new_shift_info.store_team_member?.first_name +
      " " +
      new_shift_info.store_team_member?.last_name;
    new_shift_info["role"] = new_shift_info.role_id?.role_name
      ? new_shift_info.role_id?.role_name
      : "General";
    new_shift_info["location"] = new_shift_info.store?.store_name;
    new_shift_info["roleType"] = new_shift_info["role_type"];
    new_shift_info["month"] = get_month_and_day(new_shift_info["date"]).month;
    new_shift_info["day"] = get_month_and_day(new_shift_info["date"]).day;
    return new_shift_info;
  } else {
    return {};
  }
}

const tradeInfo = {
  senderName: "",
  senderRole: "",
  senderDate: "",

  receiverName: "",
  receiverRole: "",
  receiverDate: "",
  status: "",
};

export function add_pending_requests_info(requests) {
  let request_info = { ...requests };
  if (
    requests["requestType"] == "tradeSender" ||
    requests["requestType"] == "tradeReceiver"
  ) {
    request_info["shift_pickup_info"] = add_shift_attributes(
      requests.shift_pickup
    );
    request_info["shift_drop_info"] = add_shift_attributes(requests.shift_drop);

    request_info["senderName"] = request_info["shift_drop_info"]["name"];
    request_info["senderRole"] = request_info["shift_drop_info"]["role"];
    request_info["senderDate"] = request_info["shift_drop_info"]["date"];

    request_info["receiverName"] = request_info["shift_pickup_info"]["name"];
    request_info["receiverRole"] = request_info["shift_pickup_info"]["role"];
    request_info["receiverDate"] = request_info["shift_pickup_info"]["date"];

    request_info["id"] = requests.shift_drop?.id;
  } else if (requests["requestType"] === "Pickup Request") {
    request_info = add_shift_attributes(requests.shift_drop);
    request_info["id"] = requests.shift_drop?.id;
  } else {
    request_info = add_shift_attributes(requests.shift_drop);

    request_info["id"] = requests.shift_drop?.id;
  }
  request_info["uuid"] = requests["uuid"];
  request_info["requestType"] = requests["requestType"];
  request_info["rescinded_by"] = requests["rescinded_by"];
  request_info["pending_pickup_count"] = requests["pending_pickup_count"];
  request_info["acting_manager_response"] = requests["acting_manager_response"]
    ? requests["acting_manager_response"]
    : "pending";

  return request_info;
}

function get_availability_text_from_values(available_from, available_to) {
  if (available_from === 0 && available_to === 0) {
    return "Unavailable";
  } else if (available_from === 0 && available_to === 24) {
    return "All Day";
  } else {
    return (
      format_time_num(available_from) + " - " + format_time_num(available_to)
    );
  }
}

export function mergeAvailability2(current, approved, pending) {
  const finalArray = [];
  const day_names = [
    "Mondays",
    "Tuesdays",
    "Wednesdays",
    "Thursdays",
    "Fridays",
    "Saturdays",
    "Sundays",
  ];

  for (let dow = 1; dow <= 7; dow++) {
    const currentObj = current.find((item) => item.dow === dow);
    const pendingObjs = pending.filter((item) => item.dow === dow);
    const approvedObjs = approved.filter((item) => item.dow === dow);

    const requestedArray = [];

    pendingObjs.forEach((pendingObj) => {
      requestedArray.push({
        availability: get_availability_text_from_values(
          pendingObj.available_from,
          pendingObj.available_to
        ),
        availability2: pendingObj && pendingObj.available_to_2
          ? get_availability_text_from_values(
              pendingObj.available_from_2,
              pendingObj.available_to_2
            )
          : null,
        preferences: get_preferences_text_from_values(
          pendingObj.preferred_from,
          pendingObj.preferred_to
        ),
        inEffect: pendingObj.activated_at,
        status: "pending",
        id: pendingObj.id,
        note: pendingObj.note,
      });
    });

    approvedObjs.forEach((approvedObj) => {
      requestedArray.push({
        availability: get_availability_text_from_values(
          approvedObj.available_from,
          approvedObj.available_to
        ),
        availability2: approvedObj && approvedObj.available_to_2
          ? get_availability_text_from_values(
              approvedObj.available_from_2,
              approvedObj.available_to_2
            )
          : null,
        preferences: get_preferences_text_from_values(
          approvedObj.preferred_from,
          approvedObj.preferred_to
        ),
        inEffect: approvedObj.activated_at,
        status: "approved",
        id: approvedObj.id,
        note: approvedObj.note,
      });
    });

    const dayObject = {
      day: day_names[dow - 1],
      availability: currentObj
        ? get_availability_text_from_values(
            currentObj.available_from,
            currentObj.available_to
          )
        : null,
      availability2: currentObj && currentObj.available_to_2
        ? get_availability_text_from_values(
            currentObj.available_from_2,
            currentObj.available_to_2
          )
        : null,
      preferences:
        currentObj && currentObj.preferred_to > 0
          ? get_preferences_text_from_values(
              currentObj.preferred_from,
              currentObj.preferred_to
            )
          : null,
      requested: requestedArray.length > 0 ? requestedArray : null,
    };

    finalArray.push(dayObject);
  }

  return finalArray;
}

export function mergeAvailabilityRequests(availability_requests) {
  let converted_requests = availability_requests?.map((obj) => {
    // Process the data
    const availability = get_availability_text_from_values(
      obj.available_from,
      obj.available_to
    );

    const availability2 = obj.available_to_2 ?
      get_availability_text_from_values(
        obj.available_from_2,
        obj.available_to_2
      ) : null;

    const preferences =
      obj.preferred_to > 0
        ? get_preferences_text_from_values(obj.preferred_from, obj.preferred_to)
        : null;

    // Map the day based on the 'dow' value
    const daysOfWeek = [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
      "Saturdays",
      "Sundays",
    ];
    const day = daysOfWeek[obj.dow - 1];

    // Replace 'store_team_member' with actual name (for example)
    const name = obj.store_team_member;

    // Create the new object with processed data
    const processedObj = {
      availability:
        obj.previous_availability.length > 0
          ? get_availability_text_from_values(
              obj.previous_availability[0].available_from,
              obj.previous_availability[0].available_to
            )
          : "No Availability Set",
      availability2:
        obj.previous_availability.length > 0 && obj.previous_availability[0].available_to_2
          ? get_availability_text_from_values(
              obj.previous_availability[0].available_from_2,
              obj.previous_availability[0].available_to_2
            )
          : null,
      preferences:
        obj.previous_availability.length > 0 &&
        obj.previous_availability[0].preferred_to > 0
          ? get_preferences_text_from_values(
              obj.previous_availability[0].preferred_from,
              obj.previous_availability[0].preferred_to
            )
          : null,
      day: day,
      name: name.first_name + " " + name.last_name,
      requested: {
        availability: availability,
        availability2: availability2,
        preferences: preferences,
        inEffect: obj.activated_at,
        status: obj.status,
        id: obj.id,
        note: obj.note,
      },
    };

    return processedObj;
  });

  return converted_requests;
}

export function mergeAvailabilityHistory(availability_history) {
  let converted_history = availability_history?.map((obj) => {
    // Process the data
    const availability = get_availability_text_from_values(
      obj.available_from,
      obj.available_to
    );

    const availability2 = obj.available_to_2 ?
      get_availability_text_from_values(
        obj.available_from_2,
        obj.available_to_2
      ) : null;

    const preferences =
      obj.preferred_to > 0
        ? get_preferences_text_from_values(obj.preferred_from, obj.preferred_to)
        : null;

    // Map the day based on the 'dow' value
    const daysOfWeek = [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
      "Saturdays",
      "Sundays",
    ];
    const day = daysOfWeek[obj.dow - 1];

    // Replace 'store_team_member' with actual name (for example)
    const name = obj.store_team_member;

    // Create the new object with processed data
    const processedObj = {
      availability:
        obj.previous_availability.length > 0
          ? get_availability_text_from_values(
              obj.previous_availability[0].available_from,
              obj.previous_availability[0].available_to
            )
          : "No Availability Set",
      availability2:
        obj.previous_availability.length > 0 && obj.previous_availability[0].available_to_2
          ? get_availability_text_from_values(
              obj.previous_availability[0].available_from_2,
              obj.previous_availability[0].available_to_2
            )
          : null,
      preferences:
        obj.previous_availability.length > 0 &&
        obj.previous_availability[0].preferred_to > 0
          ? get_preferences_text_from_values(
              obj.previous_availability[0].preferred_from,
              obj.previous_availability[0].preferred_to
            )
          : null,
      day: day,
      name: name.first_name + " " + name.last_name,
      requested: {
        availability: availability,
        availability2: availability2,
        preferences: preferences,
        inEffect: obj.activated_at,
        status: obj.status.toLowerCase(),
        id: obj.id,
        note: obj.note,
      },
    };

    return processedObj;
  });

  return converted_history;
}

function get_preferences_text_from_values(preferred_from, preferred_to) {
  if (preferred_from === 0 && preferred_to === 0) {
    return null;
  } else {
    return (
      format_time_num(preferred_from) + " - " + format_time_num(preferred_to)
    );
  }
}

export function mergePreferences2(availability, current, approved, pending) {
  const finalArray = [];
  const day_names = [
    "Mondays",
    "Tuesdays",
    "Wednesdays",
    "Thursdays",
    "Fridays",
    "Saturdays",
    "Sundays",
  ];

  for (let dow = 1; dow <= 7; dow++) {
    const currentAvailability = availability.find((item) => item.dow === dow);
    const currentObj = current.find((item) => item.dow === dow);
    const pendingObjs = pending.filter((item) => item.dow === dow);
    const approvedObjs = approved.filter((item) => item.dow === dow);

    const requestedArray = [];

    pendingObjs.forEach((pendingObj) => {
      requestedArray.push({
        preferences: get_preferences_text_from_values(
          pendingObj.preferred_from,
          pendingObj.preferred_to
        ),
        inEffect: pendingObj.activated_at,
        status: "pending",
        id: pendingObj.id,
      });
    });

    approvedObjs.forEach((approvedObj) => {
      requestedArray.push({
        preferences: get_preferences_text_from_values(
          approvedObj.preferred_from,
          approvedObj.preferred_to
        ),
        inEffect: approvedObj.activated_at,
        status: "approved",
        id: approvedObj.id,
      });
    });

    const dayObject = {
      day: day_names[dow - 1],
      availability: currentAvailability
        ? get_availability_text_from_values(
            currentAvailability.available_from,
            currentAvailability.available_to
          )
        : "None",
      preferences: currentObj
        ? get_preferences_text_from_values(
            currentObj.preferred_from,
            currentObj.preferred_to
          )
        : null,
      requested: requestedArray.length > 0 ? requestedArray : null,
    };

    finalArray.push(dayObject);
  }

  return finalArray;
}

export function mergePreferencesRequests(preferences_requests) {
  let converted_requests = preferences_requests?.map((obj) => {
    // Process the data
    const preferences = get_preferences_text_from_values(
      obj.preferred_from,
      obj.preferred_to
    );

    // Map the day based on the 'dow' value
    const daysOfWeek = [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
      "Saturdays",
      "Sundays",
    ];
    const day = daysOfWeek[obj.dow - 1];

    // Replace 'store_team_member' with actual name (for example)
    const name = obj.store_team_member;

    // Create the new object with processed data
    const processedObj = {
      preferences:
        obj.previous_availability.length > 0
          ? get_preferences_text_from_values(
              obj.previous_availability[0].preferred_from,
              obj.previous_availability[0].preferred_to
            )
          : "No Preferences Set",
      day: day,
      name: name.first_name + " " + name.last_name,
      requested: {
        preferences: preferences,
        inEffect: obj.activated_at, // Provide the appropriate value here
        status: obj.status.toLowerCase(), // Copy the status from the original object
        id: obj.id,
      },
    };

    return processedObj;
  });

  return converted_requests;
}

export function mergePreferencesHistory(preferences_history) {
  let converted_history = preferences_history?.map((obj) => {
    // Process the data
    const preferences = get_preferences_text_from_values(
      obj.preferred_from,
      obj.preferred_to
    );

    // Map the day based on the 'dow' value
    const daysOfWeek = [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
      "Saturdays",
      "Sundays",
    ];
    const day = daysOfWeek[obj.dow - 1];

    // Replace 'store_team_member' with actual name (for example)
    const name = obj.store_team_member;

    // Create the new object with processed data
    const processedObj = {
      preferences:
        obj.previous_availability.length > 0
          ? get_preferences_text_from_values(
              obj.previous_availability[0].preferred_from,
              obj.previous_availability[0].preferred_to
            )
          : "No Preferences Set",
      day: day,
      name: name.first_name + " " + name.last_name,
      requested: {
        preferences: preferences,
        inEffect: obj.activated_at,
        status: obj.status.toLowerCase(),
        id: obj.id,
      },
    };

    return processedObj;
  });

  return converted_history;
}

export function get_month_and_day(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObj = dateStrToObj(dateString);
  const monthAbbreviation = months[dateObj.getMonth()];
  const day = dateObj.getDate();

  return {
    month: monthAbbreviation,
    day: day,
  };
}

export const formatDateReadable = (dateString) => {
  const dateObj = dateStrToObj(dateString);

  // Set the timezone to UTC
  //date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = dateObj.getDate(); // Use getUTCDate() instead of getDate()
  const month = monthNames[dateObj.getMonth()]; // Use getUTCMonth() instead of getMonth()
  const year = dateObj.getFullYear(); // Use getUTCFullYear() instead of getFullYear()

  return `${month} ${day}, ${year}`;
};

export const text_to_num = (text) => {
  if (text === "12:00am") {
    return 24;
  }
  let arr = text.split(":");
  let adj = 0;
  let hours = parseInt(text);
  if (text.toLowerCase().includes("p") & (hours !== 12)) {
    adj += 12;
  }
  if (text.toLowerCase().includes("a") & (hours === 12)) {
    adj -= 12;
  }
  if (text.toLowerCase().includes("n")) {
    adj += 24;
  }
  let num = 0;
  if ((text.length === 0) | isNaN(hours)) {
    num = 0;
  } else if (arr.length > 1) {
    let minutes =
      (arr[1].length > 0) & !isNaN(parseInt(arr[1]))
        ? parseInt(arr[1]) / 60
        : 0;
    num = adj + hours + minutes;
  } else {
    num = parseInt(text) + adj;
  }
  return Math.max(Math.round(num * 4) / 4, 0);
};

export function getInitials(name1, name2) {
  const names = name2 === undefined ? name1.split(" ") : [name1, name2];
  const initial1 = names[0] ? names[0][0].toUpperCase() : "";
  const initial2 = names[1] ? names[1][0].toUpperCase() : "";
  return initial1 + initial2;
}

export function getDayOfWeek(dateString) {
  try {
    const dateObj = dateStrToObj(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[dateObj.getDay()];
    return dayOfWeek;
  } catch (error) {
    return "Invalid date format";
  }
}

// Function to get the max date and add one day to it, then compare with today's date and return the max
export function getMaxDatePlusOne(objects) {
  if (!objects?.length) {
    return new Date(); // or handle the case when the array is empty
  }

  // Extract dates and convert to Date objects
  const dates = objects?.map((obj) => new Date(obj.date));

  // Find the maximum date
  const maxDate = new Date(Math.max.apply(null, dates));

  // Add one day to the maximum date
  const nextDate = new Date(maxDate);
  nextDate.setDate(maxDate.getDate() + 1);

  // Get today's date
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to midnight for accurate comparison

  // Return the maximum between today's date and the calculated next date
  return nextDate > today ? nextDate : today;
}
