import * as d3 from "d3";

export function CoverageGraphD3(container, df, chartid, boxHeight, hoo) {
  const boxwidth = container.getBoundingClientRect().width;
  const boxheight = 600;

  let d = df.filter((v, i) => (v.qh_num >= hoo[0]) & (v.qh_num <= hoo[1]));

  var margin = { top: 20, right: 30, bottom: 24, left: 50 },
    width = boxwidth - margin.left - margin.right,
    height = boxheight - margin.top - margin.bottom

  // append the svg object to the body of the page
  d3.select("#" + chartid + " svg").remove();

  var svg = d3
    .select("#" + chartid)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  // Have a look to the data
  console.log(d);

  // Add X axis
  //create X-axis domain and range
  var xScale = d3
    .scaleBand()
    .domain(d.map((d) => d.time))
    .range([0, width])
    .padding([0.1]);

  //add X-axis, set ticks to only show every 4 values
  var xAxis = d3.axisBottom(xScale).tickValues(
    xScale.domain().filter(function (d, i) {
      return !(i % 4);
    })
  );

  //add X-axis label
  svg
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(0," + height + ")")
    .style("font-size", 8)
    .call(xAxis);

  //var max_workload = d3.max(d, function (dp) {
  //  return +d3.max([dp.labor * 15, dp.actual_labor * 15, dp.workload]);
  //});
  var max_workload = 300;
  //create Y-axis domain and range
  var yScale = d3.scaleLinear().domain([0, max_workload]).range([height, 0]);

  //add Y-axis
  var yAxis = d3.axisLeft(yScale);

  svg
    .append("g")
    .attr("class", "yaxis")
    .call(yAxis)
    .append("text")
    .attr("fill", "black") //set the fill here
    .attr("text-anchor", "middle")
    .attr("transform", "translate(-35," + height / 2 + ")rotate(-90)")
    .text("Labor Minutes")
    .style("font-size", 14);

  // create tooltip
  const tooltip = d3
    .select("#" + chartid)
    .append("div")
    .style("opacity", 0)
    .attr("class", "tooltip")
    .style("background-color", "white")
    .style("border", "solid")
    .style("border-width", "1px")
    .style("border-radius", "5px")
    .style("padding", "15px")
    .style("position", "absolute")
    .style("max-width", "250px")
    .style("text-align", "left")
    .style("pointer-events", "none");

  // Three function that change the tooltip when user hover / move / leave a cell
  const mouseover = function (event, d) {
    //const dayTime = d.data.time;
    tooltip
      .html(
        "<b>" +
          d.time +
          "</b><br>" +
          d.labor +
          " Recommended<br> " +
          d.actual_labor +
          " Scheduled<hr style='margin:5px 5px;'/> " +
          Math.round(d.workload) +
          " minutes of workload<br> " +
          (d.actual_labor > 0
            ? Math.round((d.workload / 15 / d.actual_labor) * 100)
            : 0) +
          "% employee utilization"
      )
      .style("opacity", 0.9);
    const [x, y] = d3.pointer(event);

    tooltip.style("left", x + "px").style("top", y - 200 + "px");
  };

  const mouseleave = function (event, d) {
    tooltip.style("opacity", 0);
  };
  // Bars
  var bars = svg
    .selectAll("mybar")
    .data(d)
    .enter()
    .append("rect")
    .attr("x", function (d) {
      return xScale(d.time);
    })
    .attr("y", function (d) {
      return yScale(0);
    })
    .attr("width", xScale.bandwidth())
    .attr("height", 0)
    .attr("fill", "#6194C2");

  bars.on("mouseover", mouseover).on("mouseleave", mouseleave);

  bars
    //.transition()
    //.duration(800)
    .attr("y", (d) => yScale(d.workload))
    .attr("height", function (d) {
      return height - yScale(d.workload);
    });
  //.delay((d, i) => i * 10);
  /*
  var opt_line = d3
    .line()
    .x(function (d) {
      return xScale(d.time);
    })
    .y(function (d) {
      return yScale(d.labor * 15);
    });

  var opt_line2 = svg
    .append("path")
    .attr("fill", "none")
    .attr("stroke", "black")
    .attr("stroke-width", 3)
    .attr("d", opt_line(d));

  var totalLength = opt_line2.node().getTotalLength();

  opt_line2
    .attr("stroke-dasharray", totalLength)
    .attr("stroke-dashoffset", totalLength)
    //.transition()
    //.duration(3000)
    .attr("stroke-dashoffset", 0);

  var rec_line = d3
    .line()
    .x(function (d) {
      return xScale(d.time);
    })
    .y(function (d) {
      return yScale(d.actual_labor * 15);
    });


  var rec_line2 = svg
    .append("path")
    .attr("fill", "none")
    .attr("stroke", "OrangeRed")
    .attr("stroke-width", 1)
    .attr("d", rec_line(d));

  var totalLength = rec_line2.node().getTotalLength();

  rec_line2
    .attr("stroke-dasharray", totalLength)
    .attr("stroke-dashoffset", totalLength)
    //.transition()
    //.duration(3000)
    .attr("stroke-dashoffset", 0);
  //.delay(800);
*/
  var legend_values = ["Forecasted Workload"];
  var color_values = ["#6194C2"];
  var ySize = [10];
  var xSize = 10;
  var yLocation = 10;
  var xLocation = 10;
  var offset = 20;
  var yOffset = [0];

  svg
    .selectAll("mydots")
    .data(legend_values)
    .enter()
    .append("rect")
    .attr("x", 10)
    .attr("y", function (d, i) {
      return yLocation + yOffset[i];
    })
    .attr("width", xSize)
    .attr("height", function (d, i) {
      return ySize[i];
    })
    .style("fill", function (d, i) {
      return color_values[i];
    });

  // Add one dot in the legend for each name.
  svg
    .selectAll("mylabels")
    .data(legend_values)
    .enter()
    .append("text")
    .attr("x", 25)
    .attr("y", function (d, i) {
      return yLocation + i * offset + 10;
    })
    .style("fill", "black")
    .text(function (d) {
      return d;
    })
    .style("font-size", 12);
  return svg;
}
