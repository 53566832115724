import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { API_get_chat_users } from '../../../constants';
import { getRightworkAPI } from '../requests/utilities/requests';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import EditChannelNameModal from './EditChannelNameModal';
import AddChannelMembersModal from './AddChannelMembersModal';
import RemoveChannelMemberModal from './RemoveChannelMemberModal';
import DeleteChannelModal from './DeleteChannelModal';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

function ChannelDetailsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [showEditChannelName, setShowEditChannelName] = React.useState(false);
  const [showAddMembers, setShowAddMembers] = React.useState(false);
  const [showRemoveMembers, setShowRemoveMembers] = React.useState(false);
  const [showDeleteChannel, setShowDeleteChannel] = React.useState(false);

  const [selectedUserForRemoval, setSelectedUserForRemoval] = React.useState(null)

  let members = props.currentChannelMembers

  // Sort members by online status
  members?.sort((a, b) => b.user.online - a.user.online);


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex items-center space-x-3">
              <h3 className="text-xl font-bold">Channel Details</h3>
              {props.currentChannelMuted &&
                <Tooltip title="This channel is muted">
                  <div className="text-slate-500">
                    <VolumeOffIcon />
                  </div>
                </Tooltip>
              }
            </div>
            <div className="border border-slate-200 rounded-xl px-6 py-4 mt-5">
              <div className="flex items-center gap-x-3">
                <h3 className="text-sm font-bold">Channel Name</h3>
                {props.userCanEditChannel &&
                  <Tooltip title="Edit channel name">
                    <div
                      className="bg-violet-50 hover:bg-violet-100 text-violet-700 rounded w-6 h-6 flex items-center justify-center cursor-pointer"
                      onClick={() => setShowEditChannelName(true)}
                    >
                      <EditIcon style={{ fontSize: "16px" }} />
                    </div>
                  </Tooltip>
                }
              </div>
              <p className="mt-1">#{props.currentChannelName}</p>
            </div>
            <div className="border border-slate-200 rounded-xl px-6 pt-4 pb-2 mt-5">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-3">
                  <h3 className="text-sm font-bold">Members</h3>
                  {props.userCanEditChannel &&
                    <Tooltip title="Add members to channel">
                      <div
                        className="bg-violet-50 hover:bg-violet-100 text-violet-700 rounded w-6 h-6 flex items-center justify-center cursor-pointer"
                        onClick={() => setShowAddMembers(true)}
                      >
                        <PersonAddAlt1Icon style={{ fontSize: "16px" }} />
                      </div>
                    </Tooltip>
                  }
                </div>
                <div className="flex items-center gap-x-1">
                  <PersonIcon style={{ fontSize: "20px" }} />
                  <p>{members?.length}</p>
                </div>
              </div>
              <div className="w-full py-2 border-b border-slate-200 flex items-center justify-between mt-2">
                <p className="text-sm text-slate-500">Name</p>
                {!props.userCanEditChannel && <p className="text-sm text-slate-500">Status</p>}
              </div>
              {members?.map((member, index) => (
                <div
                  key={index}
                  className={`w-full py-2 ${index !== members?.length - 1 ? 'border-b border-slate-200' : ''} flex items-center justify-between`}
                >
                  <div className="w-full flex items-center">
                    <div className={`flex items-center gap-x-2 ${props.userCanEditChannel ? "mr-2" : "flex-1"}`}>
                      <p className={`text-sm`}>{member?.user?.name}</p>
                      {(member.role === "owner" && !props.userCanEditChannel) && <div className="px-2 py-0.5 text-sm rounded bg-slate-100 text-slate-600">Owner</div>}
                    </div>
                    {member?.user?.online ? (
                      <div className="flex items-center gap-x-1">
                        <div className="w-3 h-3 bg-emerald-600 rounded-full"></div>
                      </div>
                    ) : (
                      <div className="flex items-center gap-x-1">
                        <div className="w-3 h-3 bg-rose-700 rounded-full"></div>
                      </div>
                    )}
                  </div>
                  {props.userCanEditChannel &&
                    <div>
                      {member.role === "owner" ?
                        <div className="px-2 py-0.5 text-sm rounded bg-slate-100 text-slate-600">Owner</div> :
                        <Tooltip title={`Remove ${member?.user?.name} from channel`}>
                          <div
                            className="bg-rose-50 hover:bg-rose-100 text-rose-700 rounded w-6 h-6 flex items-center justify-center cursor-pointer"
                            onClick={() => {
                              setSelectedUserForRemoval(member)
                              setShowRemoveMembers(true)
                            }}
                          >
                            <PersonRemoveAlt1Icon style={{ fontSize: "16px" }} />
                          </div>
                        </Tooltip>
                      }
                    </div>
                  }
                </div>
              ))}
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex items-center gap-x-3">
                {props.currentChannelMuted ?
                  <div className="px-8 py-2 bg-orange-200 hover:bg-orange-300 text-orange-800 rounded-full text-sm w-fit cursor-pointer"
                      onClick={() => {
                        props.handleUnmuteChannel(props.channelID)
                        props.setCurrentChannelMuted(false)
                      }}
                    >
                      Unmute Channel
                  </div> :
                  <div className="px-8 py-2 bg-violet-100 hover:bg-violet-200 text-violet-700 rounded-full text-sm w-fit cursor-pointer"
                      onClick={() => {
                        props.handleMuteChannel(props.channelID)
                        props.setCurrentChannelMuted(true)
                      }}
                    >
                      Mute Channel
                  </div>
                }
                {props.userCanEditChannel &&
                  <div className="px-8 py-2 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-sm w-fit cursor-pointer"
                    onClick={() => {
                      setShowDeleteChannel(true)
                    }}
                  >
                    Delete Channel
                  </div>
                }
                <div
                  className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-sm w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <EditChannelNameModal
        open={showEditChannelName}
        handleClose={() => setShowEditChannelName(false)}
        channelName={props.channelName}
        setChannelName={props.setChannelName}
        channelID={props.channelID}
        handleEditChannel={props.handleEditChannel}
      />
      <AddChannelMembersModal
        open={showAddMembers}
        handleClose={() => setShowAddMembers(false)}
        currentChannelName={props.currentChannelName}
        currentChannelMembers={props.currentChannelMembers}
        channelID={props.channelID}
        chatUsers={props.chatUsers}
        handleAddMembersToChannel={props.handleAddMembersToChannel}
      />
      <RemoveChannelMemberModal
        open={showRemoveMembers}
        handleClose={() => setShowRemoveMembers(false)}
        channelName={props.channelName}
        currentChannelName={props.currentChannelName}
        channelID={props.channelID}
        selectedUserForRemoval={selectedUserForRemoval}
        handleRemoveMemberFromChannel={props.handleRemoveMemberFromChannel}
      />
      <DeleteChannelModal
        open={showDeleteChannel}
        handleClose={() => setShowDeleteChannel(false)}
        currentChannelName={props.currentChannelName}
        channelID={props.channelID}
        handleDeleteChannel={props.handleDeleteChannel}
      />
    </>
  );
}

export default ChannelDetailsModal;
