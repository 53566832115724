import React from 'react'
import EmployeeProfileNoteEntry from './EmployeeProfileNoteEntry';
import EmployeeProfileNoteInputEntry from './EmployeeProfileNoteInputEntry';
import AddIcon from '@mui/icons-material/Add';


function EmployeeProfileNotesTab(props) {


  console.log("NOTES", props.selectedTeamMemberData)

  const [showInput, setShowInput] = React.useState(props.selectedTeamMemberData?.logbook_employee_entries?.length === 0);

  React.useEffect(() => {
    setShowInput(props.selectedTeamMemberData?.logbook_employee_entries?.length === 0)
  }, [props.selectedTeamMemberData])

  return (
    <div className="w-full flex flex-col items-center">
      <div>
        <h3 className="text-slate-900 text-2xl font-bold">Notes</h3>
      </div>
      <div className="mt-5">
        <div className={`bg-white ${showInput ? "pt-1" : ""} pb-5 px-5 rounded-xl w-[550px]`}>
          {props.selectedTeamMemberData?.logbook_employee_entries?.map((entry, index) => (
            <div key={index} className="py-5 border-b border-slate-300">
              <EmployeeProfileNoteEntry
                entry={entry}
                delete_logbook_employee_entry={props.delete_logbook_employee_entry}
                stm_id={props.stm_id}
                showInput={showInput}
                setShowInput={setShowInput}
                get_file_upload={props.get_file_upload}
              />
            </div>
          ))}
          {showInput ? (
            <div className="mt-5">
              <EmployeeProfileNoteInputEntry
                post_logbook_employee_entry={props.post_logbook_employee_entry}
                stm_id={props.stm_id}
                showInput={showInput}
                setShowInput={setShowInput}
                homeCompany={props.homeCompany}
              />
            </div>
          ) : (
            <div
              className="mt-5 pb-2 flex items-center space-x-2 text-violet-700 hover:text-violet-800 cursor-pointer"
              onClick={() => setShowInput(true)}
            >
              <div>
                <AddIcon style={{ marginBottom: "1px" }} />
              </div>
              <p>Add New Entry</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EmployeeProfileNotesTab
