import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import DefaultAvailabilityCard from "../../shared/availability/DefaultAvailabilityCard.js";
import CommentIcon from "@mui/icons-material/Comment";
import Pill from "../../shared/helpers/Pill.js";
import { formatDateReadable } from "../../utilities/helpers.js";
import { getInitials } from "../../utilities/helpers.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DefaultAvailabilityRequestCard from "./DefaultAvailabilityRequestCard.js";
import EmptyState from "../../shared/helpers/EmptyState.js";
import AvatarSmall from "../../shared/helpers/AvatarSmall.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

export default function AvailabilityApprovedTable(props) {
  // props.archive_availability_request

  return (
    <div className="mt-12">
      {props.availabilityHistoryData.length > 0 ? (
        <div>
          <div className="flex items-center px-6">
            <div className="flex-auto">
              <h1 className="text-3xl text-slate-900">Acted On</h1>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto min-w-full">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[25%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                        >
                          Employee
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Existing Availability
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Requested Availability
                        </th>
                        <th
                          scope="col"
                          className="w-[25%] pl-3 pr-16 py-3.5 text-sm text-slate-500 font-medium text-right"
                        >
                          Effective Date
                        </th>
                        {/* <th scope="col" className="w-[18%] relative py-3.5 pl-3 pr-6">
                          <span className="sr-only">Action</span>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {props.availabilityHistoryData.map((availability, i) => (
                        <tr key={i}>
                          <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm">
                            <div className="flex items-center">
                              <AvatarSmall
                                initials={getInitials(availability.name)}
                              />
                              <div className="ml-4">
                                <div className="font-medium text-slate-900">
                                  {availability.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
                            <DefaultAvailabilityRequestCard
                              day={availability.day}
                              availability={availability.availability}
                              availability2={availability.availability2}
                              preferences={availability.preferences}
                              status="current"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
                            <DefaultAvailabilityRequestCard
                              day={availability.day}
                              availability={availability.requested.availability}
                              availability2={availability.requested.availability2}
                              preferences={availability.requested.preferences}
                              status={availability.requested.status.toLowerCase()}
                            />
                          </td>
                          <td className="whitespace-nowrap pl-3 pr-3 py-4 text-sm text-slate-500 text-right">
                            <div className="flex items-center justify-end space-x-3">
                              {availability.requested.note && (
                                <div>
                                  <Tooltip title={availability.requested.note}>
                                    <CommentIcon />
                                  </Tooltip>
                                </div>
                              )}
                              <div className="text-slate-900 w-32">
                                {availability.requested.inEffect
                                  ? formatDateReadable(
                                      availability.requested.inEffect
                                    )
                                  : "None"}
                              </div>
                              <Tooltip title="Archive">
                                <IconButton
                                  className="text-slate-500 ml-4"
                                  sx={{ backgroundColor: "#f8fafc" }}
                                  onClick={() =>
                                    props.archive_availability_request(
                                      availability.requested.id
                                    )
                                  }
                                >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                          {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-3 text-right text-sm font-medium">
                            <div className="flex justify-end items-center gap-10">
                              <div className="flex items-center justify-end">
                                <div>
                                  <Pill status={availability.requested.status.toLowerCase()} />
                                </div>
                                <Tooltip title="Archive">
                                  <IconButton
                                    className="text-slate-500 ml-4"
                                    onClick={() => props.archive_availability_request(availability.requested.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState
          title="Acted On"
          description="No acted on availability at the moment"
        />
      )}
    </div>
  );
}
