import React from 'react'

function ButtonOutlined(props) {
  return (
    <div
      className="w-full py-3 rounded-full bg-white hover:bg-slate-50 border border-violet-700 text-violet-700 text-lg flex items-center justify-center cursor-pointer"
      onClick={props.onClick}
    >
      {props.text}
    </div>
  )
}

export default ButtonOutlined
