import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import TrackInputsModal from './TrackInputsModal';
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PublishTrackModal from './PublishTrackModal';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import TrackPreviewModal from './TrackPreviewModal';
import SendIcon from '@mui/icons-material/Send';
import ViewListIcon from '@mui/icons-material/ViewList';


function TracksDisplay(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalEdit, setModalEdit] = React.useState(false);


  const backgroundColorLookup = {
    "bg-slate-400": "bg-slate-100",
    "bg-violet-600": "bg-slate-100",
    "bg-sky-700": "bg-slate-100",
    "bg-cyan-600": "bg-slate-100",
    "bg-emerald-600": "bg-slate-100",
    "bg-lime-600": "bg-slate-100",
    "bg-amber-500": "bg-slate-100",
    "bg-orange-600": "bg-slate-100",
    "bg-red-600": "bg-slate-100",
    "bg-pink-600": "bg-slate-100"
  }

  const [selectedTrack, setSelectedTrack] = React.useState(null)
  const [selectedTrackIndex, setSelectedTrackIndex] = React.useState(null)

  const [openPublishTrackModal, setOpenPublishTrackModal] = React.useState(false);
  const handleOpenPublishTrackModal = () => setOpenPublishTrackModal(true);
  const handleClosePublishTrackModal = () => setOpenPublishTrackModal(false);

  const [openTrackPreviewModule, setOpenTrackPreviewModule] = React.useState(false);
  const handleOpenTrackPreviewModule = () => setOpenTrackPreviewModule(true);
  const handleCloseTrackPreviewModule = () => setOpenTrackPreviewModule(false);

  const [searchInput, setSearchInput] = React.useState("");

  const filteredTracks = props.tracksData?.filter(track => {
    const searchParts = searchInput?.toLowerCase()?.split(" ");
    return searchParts?.every(part =>
      track?.name?.toLowerCase()?.includes(part)
    );
  });

  React.useEffect(() => {
    if (selectedTrackIndex !== null) {
      const newTracksData = [...props.tracksData];
      newTracksData[selectedTrackIndex] = { ...newTracksData[selectedTrackIndex], ...props.tracksData };
      props.setModalDataTrack(newTracksData[selectedTrackIndex]);
      setSelectedTrack(newTracksData[selectedTrackIndex])
    }
  }, [props.tracksData, props.modulesData, props.sectionsData]);

  return (
    <>
      <div>
        <div className="w-full px-6 mt-10 flex items-center justify-between">
          <h2 className="text-4xl text-slate-900">Tracks</h2>
          <Tooltip title="Create new track">
            <button
              className="bg-violet-700 hover:bg-violet-800 text-white h-12 w-12 rounded-full"
              onClick={() => {
                setModalEdit(false)
                props.setModalDataTrack(props.default_data_track)
                handleOpen()
              }}
            >
              <AddIcon style={{ fontSize: "26px" }} />
            </button>
          </Tooltip>
        </div>
        <div className="w-full px-6 mt-8">
          <p className="text-sm text-slate-500">Search by track</p>
          <input
            className={`w-[250px] h-[40px] border border-slate-300 px-3 text-sm rounded-lg outline-none focus:border-2 focus:border-violet-700 mt-1`}
            type="text"
            placeholder="🔎  i.e. Bartender Track"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
        </div>
        <div className="px-6 mt-10 pb-10">
          {filteredTracks?.map((track, index) => {
            return(
              <div
                key={index}
                className="w-full border border-slate-200 rounded-xl p-6 relative mt-4"
              >
                <div className="w-full flex items-center justify-between">
                  <div className="flex space-x-8 flex-1">
                    <h4 className="text-slate-900 text-2xl">{track.name}</h4>
                    <div className="flex gap-2 flex-1 flex-wrap">
                      {track.roles_assigned_to?.map((role, index) => (
                        <Tooltip title="Auto-assigned">
                          <div
                            key={index}
                            className="bg-slate-100 text-slate-600 rounded px-3 flex items-center justify-center text-sm"
                          >
                            {role.role_name}
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center space-x-3 ml-8">
                    <div
                      className="w-10 h-10 rounded-full bg-slate-100 flex items-center justify-center text-slate-500"
                      onClick={() => {
                        setModalEdit(true)
                        props.setModalDataTrack(track)
                        setSelectedTrack(track)
                        handleOpen()
                      }}
                    >
                      <Tooltip title="Edit track">
                        <IconButton>
                          <EditIcon style={{ fontSize: "22px" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div
                      className="w-10 h-10 rounded-full bg-slate-100 flex items-center justify-center text-slate-500"
                      onClick={() => {
                        props.setModalDataTrack(track)
                        setSelectedTrack(track)
                        setSelectedTrackIndex(index)
                        handleOpenTrackPreviewModule()
                      }}
                    >
                      <Tooltip title="Preview track">
                        <IconButton>
                          <SlideshowIcon style={{ fontSize: "22px" }} />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {/* <div
                      className="h-10 px-4 flex items-center justify-center space-x-3 rounded-full bg-emerald-600 hover:bg-emerald-700 text-white cursor-pointer"
                      onClick={() => {
                        props.setModalDataTrack(track)
                        handleOpenPublishTrackModal()
                      }}
                    >
                      <p>Publish Track</p>
                      <div>
                        <SendIcon style={{ fontSize: "20px", marginBottom: "2px" }} />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="mt-8 flex flex-wrap items-center gap-x-1 gap-y-5">
                  {track.modules?.map((module, index) => {
                    return(
                      <div
                        key={index}
                        className="flex items-center gap-x-1"
                      >
                        <div className={`min-w-[200px] h-[94px] ${backgroundColorLookup[module.color]} rounded-xl p-3 relative`}>
                          <h4 className={`text-white text-sm px-2 py-0.5 rounded ${module.color} w-fit`}>{module.name}</h4>
                          <div className="flex items-center space-x-1 mt-2 text-slate-500">
                            <div>
                              <ViewListIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                            </div>
                            <p className="text-slate-500 text-xs">{module.sections?.length} {module.sections?.length === 1 ? "section" : "sections"}</p>
                          </div>
                          <div className={`w-fit px-2.5 py-0.5 text-slate-600 absolute bottom-2 right-2 rounded-full bg-slate-200`}>
                            <p className="text-xs">Module {index + 1}</p>
                          </div>
                        </div>
                        {index !== track.modules?.length - 1 &&
                          <div>
                            <ArrowForwardIcon style={{ fontSize: "20px" }} />
                          </div>
                        }
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <TrackInputsModal
        open={open}
        handleClose={handleClose}
        modalEdit={modalEdit}
        modalDataTrack={props.modalDataTrack}
        setModalDataTrack={props.setModalDataTrack}
        homeCompany={props.homeCompany}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        modulesData={props.modulesData}
        sectionsData={props.sectionsData}
        roleNames={props.roleNames}
        post_learning_track={props.post_learning_track}
        delete_learning_track={props.delete_learning_track}
        post_learning_module={props.post_learning_module}
        post_learning_section={props.post_learning_section}
        quizzesData={props.quizzesData}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
      />
      <TrackPreviewModal
        open={openTrackPreviewModule}
        handleClose={handleCloseTrackPreviewModule}
        modalDataTrack={props.modalDataTrack}
        setModalDataTrack={props.setModalDataTrack}
        homeCompany={props.homeCompany}
        modalDataModule={props.modalDataModule}
        setModalDataModule={props.setModalDataModule}
        modalDataSection={props.modalDataSection}
        setModalDataSection={props.setModalDataSection}
        quizzesData={props.quizzesData}
        default_data_module={props.default_data_module}
        default_data_section={props.default_data_section}
        modulesData={props.modulesData}
        sectionsData={props.sectionsData}
        roleNames={props.roleNames}
        post_learning_track={props.post_learning_track}
        delete_learning_track={props.delete_learning_track}
        post_learning_module={props.post_learning_module}
        post_learning_section={props.post_learning_section}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        default_data_quiz={props.default_data_quiz}
        post_learning_quiz={props.post_learning_quiz}
        type="Preview"
        selectedTrack={selectedTrack}
        setSelectedTrack={setSelectedTrack}
      />
      {/* <PublishTrackModal
        open={openPublishTrackModal}
        handleClose={handleClosePublishTrackModal}
        modalDataTrack={props.modalDataTrack}
        homeCompany={props.homeCompany}
        post_learning_track_enrollment_by_roles={props.post_learning_track_enrollment_by_roles}
      /> */}
    </>
  )
}

export default TracksDisplay
