import React from 'react'
import { Drawer } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ShiftStatusBar from './ShiftStatusBar';
import { formatDateReadable } from '../../utilities/helpers';
import DrawerHeader from '../../shared/helpers/DrawerHeader';
import DrawerFooterContainerSmall from '../../shared/helpers/DrawerFooterContainerSmall';
import ButtonFilled from '../../shared/helpers/ButtonFilled';
import ButtonGrayed from '../../shared/helpers/ButtonGrayed';
import StatusWithText from '../../shared/helpers/StatusWithText';

function PendingTableEmployeeRowShiftPickupDrawer(props) {

  function handlePickupCancel() {
    props.rescind_pickup_shift(props.shiftInfo.id);
    props.onClose();
  }


  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader title="Pickup Request" onClose={props.onClose} />
          <div className="w-full">
            <div className="w-full h-full flex flex-col items-center px-6">
              <div className="w-full mt-6 pb-1">
                <h3 className="text-slate-500 text-base">Date</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {formatDateReadable(props.shiftInfo.date)}
                </p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Time</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {props.shiftInfo.shift}
                </p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Break</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {props.shiftInfo.break_formatted}
                </p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Role</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {props.shiftInfo.station ? props.shiftInfo.station?.name : props.shiftInfo.role ? props.shiftInfo.role : props.shiftInfo.roleType}
                </p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Location</h3>
                <p className="mt-1 text-xl text-slate-900">
                  {props.shiftInfo.location}
                </p>
              </div>
              <div className="w-full mt-3 pb-1">
                <h3 className="text-slate-500 text-base">Status</h3>
                <div className="mt-3">
                  <StatusWithText status={props.cardStatus} />
                </div>
              </div>
            </div>
          </div>
          <DrawerFooterContainerSmall>
            <div className="w-full">
              {props.cardStatus === "pending" ? (
                <ButtonFilled text="Cancel Request" onClick={() => handlePickupCancel()} />
              ) : (
                <ButtonGrayed text="Cancel Request" />
              )}
            </div>
          </DrawerFooterContainerSmall>
          <div className="h-[200px]"></div>
        </div>
      </div>
    </Drawer>
  )
}

export default PendingTableEmployeeRowShiftPickupDrawer
