import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function PublishScheduleButton(props) {
  const [scheduleNeedsReview, setScheduleNeedsReview] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.setPublishShiftsFinished(false);
    setButtonPressed(false);
  };
  const [buttonPressed, setButtonPressed] = React.useState(false);

  const handleStart = () => {
    if (props.roleType.length === props.roleTypeOptions.length) {
      props.publishShifts();
    } else {
      props.publishShifts(props.roleType.join(","));
    }
    setButtonPressed(true);
  };

  // console.log("ROLE TYPE", props.roleType.join(","))
  // console.log("ROLE TYPE OPTIONS", props.roleTypeOptions.length)

  useEffect(() => {
    if (props.publishShiftsFinished) {
      handleClose();
    }
  }, [props.publishShiftsFinished]);

  const tooltipTitle = props.has7ShiftsIntegration
    ? "Send to 7Shifts"
    : "Publish Schedule";

  const modalDescription = props.has7ShiftsIntegration
    ? "Replace existing shifts in 7shifts with the current schedule."
    : "Notifies your team of their new shifts.";

  const countOfWarnings =
    props.countOfWarnings.countOfConflicts +
    props.countOfWarnings.countOfOvertime;

  function createWarningsText(numScheduleWarnings, countConflicts) {
    let scheduleWarningText = "";
    if (numScheduleWarnings > 0) {
      scheduleWarningText += `${numScheduleWarnings} ${
        numScheduleWarnings === 1 ? "person is" : "people are"
      } in overtime`;
    }

    let availabilityWarningText = "";
    if (countConflicts > 0) {
      availabilityWarningText += `${countConflicts} ${
        countConflicts === 1 ? "person has" : "shifts with"
      } an availability conflict`;
    }
    return [scheduleWarningText, availabilityWarningText];
  }

  const warningsText = createWarningsText(
    props.countOfWarnings.countOfOvertime,
    props.countOfWarnings.countOfConflicts
  );

  // This text will be displayed on the button
  let publishButtonText = "Publish Schedule";
  if (props.countOfPublished > 0) {
    publishButtonText = `Publish Changes`;
  }

  // You can also disable the button if there are no changes to publish
  const isButtonDisabled = props.countOfUnpublished === 0;

  return (
    <div>
      {/* Conditional Tooltip based on whether there are changes */}
      <Tooltip
        title={
          props.countOfUnpublished > 0
            ? "You have changes to publish"
            : "No changes to publish"
        }
      >
        <span>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleOpen}
            disabled={isButtonDisabled} // Disable the button if no unpublished changes
            sx={{
              backgroundColor: "#6d28d9",
              "&:hover": {
                backgroundColor: "#5b21b6",
              },
              textTransform: "none",
              borderRadius: "8px",
            }}
          >
            <span className="lg:hidden">{publishButtonText}</span>
            <span className="hidden lg:block text-sm">Publish</span>
          </Button>
        </span>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {scheduleNeedsReview ? (
          <Box sx={style}>
            <div
              className="absolute right-2 top-2 text-gray-500 cursor-pointer"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
            <div className="flex items-center space-x-1">
              <div className="text-rose-700">
                <DangerousIcon
                  style={{ fontSize: "22px", marginBottom: "1px" }}
                />
              </div>
              <h3 className="text-xl text-slate-900">
                Schedule Review Required
              </h3>
            </div>
            <p className="text-sm text-slate-500 mt-2">
              This schedule has a grade that is{" "}
              <span className="font-bold">below an A</span> or has a forecast
              adjustment that is{" "}
              <span className="font-bold">greater than 10%</span>. Please
              contact a supervisor to have them publish this schedule or make
              the necessary changes to publish yourself.
            </p>
            {countOfWarnings > 0 && (
              <div className="mt-5">
                <p className="text-slate-500 text-xs">Other schedule issues</p>
                <div className="w-full p-4 bg-rose-100 border border-rose-700 flex items-center space-x-5 rounded-xl mt-1">
                  <div className="text-rose-700">
                    <WarningIcon style={{ fontSize: "44px" }} />
                  </div>
                  <div>
                    <p className="text-rose-700 text-xs">{`This schedule has ${countOfWarnings} ${
                      countOfWarnings === 1 ? "issue" : "issues"
                    }. Are you sure you want to publish this schedule?`}</p>
                    <div className="mt-1">
                      <ul>
                        {warningsText.map((text, index) => (
                          <li className="text-rose-700 text-xs">
                            {text.length > 0 && "•"} {text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="w-full flex items-center space-x-4 mt-6">
              <div
                className="px-5 py-2.5 rounded-full border border-slate-200 text-slate-400 hover:bg-slate-50 flex items-center justify-center cursor-pointer"
                onClick={handleClose}
              >
                <p>Cancel</p>
              </div>
              <div className="flex-1">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={buttonPressed}
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                  onClick={() => handleStart()}
                  sx={{
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "10px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                  }}
                >
                  Supervisor Publish
                </LoadingButton>
              </div>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div>
              <div
                className="absolute right-2 top-2 text-gray-500 cursor-pointer"
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
              <h3 className="text-xl text-slate-900">
                {props.countOfPublished > 0
                  ? `Publish ${props.countOfUnpublished} ${
                      props.countOfUnpublished === 1 ? "Change" : "Changes"
                    }`
                  : `Publish Schedule`}
              </h3>
              <p className="text-md text-slate-500 mt-2">
                {`You will be publishing ${
                  props.countOfPublished > 0 ? "these changes" : "this schedule"
                } to ${
                  props.roleType.length === props.roleTypeOptions.length
                    ? "all departments"
                    : props.roleType
                        .map((department, index) =>
                          index > 0
                            ? ` and your ${department} department`
                            : ` your ${department} department`
                        )
                        .join("")
                }.`}
              </p>
              <div className="w-full bg-slate-50 py-2 px-5 rounded-lg flex items-center space-x-2 mt-5">
                <div className="text-emerald-600">
                  <CheckCircleIcon
                    style={{ fontSize: "18px", marginBottom: "2px" }}
                  />
                </div>
                <p className="text-slate-900 text-xs font-semibold">
                  {modalDescription}
                </p>
              </div>
              {countOfWarnings > 0 && (
                <div className="w-full p-4 bg-rose-100 border border-rose-600 flex items-center space-x-5 rounded-xl mt-5">
                  <div className="text-rose-600">
                    <WarningIcon style={{ fontSize: "44px" }} />
                  </div>
                  <div>
                    <p className="text-rose-600 text-xs">{`This schedule has ${countOfWarnings} ${
                      countOfWarnings === 1 ? "issue" : "issues"
                    }. Are you sure you want to publish this schedule?`}</p>
                    <div className="mt-1">
                      <ul>
                        {warningsText.map((text, index) => (
                          <li className="text-rose-600 text-xs">
                            {text.length > 0 && "•"} {text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <div className="w-full flex items-center space-x-4 mt-6">
                <div
                  className="px-5 py-2.5 rounded-full border border-slate-200 text-slate-400 hover:bg-slate-50 flex items-center justify-center cursor-pointer"
                  onClick={handleClose}
                >
                  <p>Cancel</p>
                </div>
                <div className="flex-1">
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    loading={buttonPressed}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    onClick={() => handleStart()}
                    sx={{
                      textTransform: "none",
                      borderRadius: "50px",
                      paddingY: "10px",
                      backgroundColor: "#6d28d9",
                      "&:hover": {
                        backgroundColor: "#5b21b6",
                      },
                    }}
                  >
                    {props.countOfPublished > 0
                      ? countOfWarnings > 0
                        ? "Publish Anyway"
                        : "Publish Changes"
                      : countOfWarnings > 0
                      ? "Publish Anyway"
                      : "Publish Schedule"}
                  </LoadingButton>
                </div>
              </div>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
}

export default PublishScheduleButton;
