import React, { useState, useEffect } from "react";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import TimeClockExitAppWarningModal from "./TimeClockExitAppModal";

function PinEntry(props) {
  // State to track the entered PIN
  const [enteredPin, setEnteredPin] = useState("");
  // Other existing state variables...

  const handlePinChange = (digit) => {
    const pinValue = enteredPin + digit;
    setEnteredPin(pinValue.slice(0, 4).replace(/[^0-9]/g, ""));
  };

  const handleBackspace = () => {
    setEnteredPin(enteredPin.slice(0, -1));
  };

  const handlePinSubmit = () => {
    props.get_employee_data(enteredPin);
  };

  const renderPinDots = () => {
    const filledDots = enteredPin.length;
    const emptyDots = 4 - filledDots;
    const dots = [];

    for (let i = 0; i < filledDots; i++) {
      dots.push(
        <div
          key={i}
          className="bg-white border-2 border-violet-700 w-16 h-16 rounded-lg flex items-center justify-center"
        >
          <div className="w-5 h-5 rounded-full bg-violet-700"></div>
        </div>
      );
    }

    for (let i = 0; i < emptyDots; i++) {
      dots.push(
        <div
          key={filledDots + i}
          className="bg-white border-2 border-slate-200 w-16 h-16 rounded-lg"
        ></div>
      );
    }

    return dots;
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {setOpen(true)};
  const handleClose = () => {setOpen(false)};

  return (
    <>
      <div>
        <div className="w-content absolute top-6 left-6">
          <img
            src="https://rightworkpublic.s3.us-west-1.amazonaws.com/logo_name.png"
            alt="Rightwork Logo"
            width={250}
          />
        </div>
        <div className="absolute top-3 right-8 flex items-center space-x-4">
          <div
            className="px-4 py-3.5 rounded-full cursor-pointer"
            onClick={handleOpen}
          >
            <p className="text-violet-700 hover:text-violet-900 text-lg font-semibold">
              Exit App
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center h-screen bg-slate-100 p-4">
          <div className="flex flex-col items-center">
            <h3 className="text-slate-900 text-4xl">{props.homeStoreName}</h3>
            <h3 className="text-lg text-slate-500 mt-2">Enter your pin to log in</h3>
          </div>
          <div className="flex space-x-3 mt-8">{renderPinDots()}</div>
          <div className="bg-white border border-slate-200 rounded-xl overflow-hidden mt-10 w-[38%] min-w-[400px]">
            <div className="w-full flex items-center border-b border-slate-200">
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200 hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(1)}
              >
                <p className="text-3xl font-bold">1</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200  hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(2)}
              >
                <p className="text-3xl font-bold">2</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(3)}
              >
                <p className="text-3xl font-bold">3</p>
              </div>
            </div>
            <div className="w-full flex items-center border-b border-slate-200">
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200  hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(4)}
              >
                <p className="text-3xl font-bold">4</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200  hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(5)}
              >
                <p className="text-3xl font-bold">5</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(6)}
              >
                <p className="text-3xl font-bold">6</p>
              </div>
            </div>
            <div className="w-full flex items-center border-b border-slate-200">
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200  hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(7)}
              >
                <p className="text-3xl font-bold">7</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200  hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(8)}
              >
                <p className="text-3xl font-bold">8</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(9)}
              >
                <p className="text-3xl font-bold">9</p>
              </div>
            </div>
            <div className="w-full flex items-center">
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200  hover:bg-slate-50 cursor-pointer"
                onClick={() => setEnteredPin("")}
              >
                <p className="text-2xl font-bold">CLEAR</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center border-r border-slate-200  hover:bg-slate-50 cursor-pointer"
                onClick={() => handlePinChange(0)}
              >
                <p className="text-3xl font-bold">0</p>
              </div>
              <div
                className="flex-1 h-24 flex items-center justify-center  hover:bg-slate-50 cursor-pointer"
                onClick={handleBackspace}
              >
                <div>
                  <BackspaceIcon style={{ fontSize: "36px" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              className="bg-violet-700 hover:bg-violet-800 text-white font-bold py-6 px-36 rounded-full text-2xl"
              onClick={handlePinSubmit}
            >
              Enter
            </button>
          </div>
        </div>
      </div>
      <TimeClockExitAppWarningModal
        open={open}
        handleClose={handleClose}
        logout={props.logout}
        pinEntry={true}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    username: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinEntry);
