import React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import CommentIcon from "@mui/icons-material/Comment";
import Tooltip from "@mui/material/Tooltip";
import Pill from "../../shared/helpers/Pill";
import { formatDateReadable } from "../../utilities/helpers";
import { getInitials } from "../../utilities/helpers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import AvatarSmall from "../../shared/helpers/AvatarSmall";
import TimeOffApprovedCalendarModal from "./TimeOffApprovedCalendarModal";
import InfoIcon from '@mui/icons-material/Info';


export default function TimeOffApprovedTable(props) {
  // props.archive_time_off_request

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="mt-12">
        <div>
          <div className="flex items-center px-6">
            <div className="flex gap-x-4">
              <h1 className="text-3xl text-slate-900">Acted On</h1>
              <div
                className="px-5 py-2 bg-violet-50 hover:bg-violet-100 text-violet-700 text-xs rounded-lg h-fit cursor-pointer"
                onClick={handleOpen}
              >
                <p>Time Off Calendar</p>
              </div>
            </div>
          </div>
          <div className="mt-12 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[20%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                        >
                          Employee
                        </th>
                        {/* <th scope="col" className="w-[16%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium">
                          Date Submitted
                        </th> */}
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Time Off Date(s)
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="w-1/12 relative py-3.5 pl-3 pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {props.timeOffHistory.map((timeOff, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm">
                            <div className="flex items-center">
                              <AvatarSmall
                                initials={getInitials(
                                  timeOff.store_team_member.first_name,
                                  timeOff.store_team_member.last_name
                                )}
                              />
                              <div className="ml-4">
                                <div className="font-medium text-slate-900">
                                  {timeOff.store_team_member.first_name}{" "}
                                  {timeOff.store_team_member.last_name}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                            <div className="text-slate-900">{timeOff.dateSubmitted}</div>
                          </td> */}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
                            <div className="flex items-center space-x-1">
                              <p>
                                {formatDateReadable(timeOff.date_lower_bound)}
                              </p>
                              {timeOff.date_upper_bound && (
                                <div className="flex items-center space-x-1">
                                  <div className="text-slate-900">
                                    <ArrowForwardIcon
                                      style={{ fontSize: "16px" }}
                                    />
                                  </div>
                                  <p>
                                    {formatDateReadable(
                                      timeOff.date_upper_bound
                                    )}
                                  </p>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
                            <div className="text-slate-900">
                              {timeOff.title}
                            </div>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                            {timeOff.note ? (
                              <span className="text-slate-500 mr-8">
                                <Tooltip title={timeOff.note}>
                                  <CommentIcon />
                                </Tooltip>
                              </span>
                            ) : (
                              <span className="text-slate-500 mr-8">
                                {/* <Tooltip title={timeOff.note}>
                                  <ModeCommentOutlinedIcon />
                                </Tooltip> */}
                              </span>
                            )}
                            <div className="flex items-center justify-end w-32">
                              <div className="mr-2">
                                <Pill status={timeOff.status.toLowerCase()} />
                              </div>
                              <Tooltip title="Archive">
                                <IconButton
                                  className="text-slate-500 ml-4"
                                  sx={{ backgroundColor: "#f8fafc" }}
                                  onClick={() =>
                                    props.archive_time_off_request(timeOff.uuid)
                                  }
                                >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                  {props.timeOffHistory.length === 0 &&
                    <div className="w-full mt-24 flex items-center justify-center">
                      <div className="w-full flex flex-col items-center justify-center">
                        <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
                          <InfoIcon />
                        </div>
                        <p className="mt-2 text-sm">No acted on time off at the moment</p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TimeOffApprovedCalendarModal
        open={open}
        handleClose={handleClose}
        timeOffFutureApproved={props.timeOffFutureApproved}
      />
    </>
  );
}
