import React from 'react';

const BlinkingDot = (props) => {

  let color = ""

  if (props.sub_status === "Working") {
    color = "bg-violet-700"
  } else {
    color = "bg-orange-500"
  }

  return (
    <div>
      {/* Dot element with inline blinking animation */}
      <div
        className={`w-2 h-2 ${color} rounded-full`}
        style={{ animation: 'blink 2s infinite' }}
      />

      {/* Inline style for keyframes animation */}
      <style>
        {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default BlinkingDot;
