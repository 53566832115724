import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import Divider from '@mui/material/Divider';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { getInitials } from '../../utilities/helpers';

function ShiftPoolBidCard(props) {

  const weekHours = props.info.employee_pickup_hours ? props.info.employee_pickup_hours : 0
  return (
    <div onClick={props.handleBidSelect} className="cursor-pointer">
      <div className="flex items-center relative px-6 py-3 border-b border-slate-200 hover:bg-slate-50">
        <div className="flex items-center flex-1">
          <div className="h-12 w-12 flex-shrink-0">
            <div className="h-12 w-12 rounded-full bg-slate-50 text-slate-500 flex items-center justify-center">{getInitials(props.info.employee_pickup.first_name, props.info.employee_pickup.last_name)}</div>
          </div>
          <div className="ml-4 flex-1">
            <p className="font-medium text-gray-900">{props.info.employee_pickup.first_name} {props.info.employee_pickup.last_name}</p>
            <div className="flex flex-row items-center mt-1.5">
              <div className="w-1/2">
                <p className="text-slate-500 text-xs">Current</p>
                <p className="text-slate-900 text-sm">{weekHours} hrs</p>
              </div>
              <div className="w-1/2">
                <p className="text-slate-500 text-xs">After</p>
                <p className="text-violet-700 text-sm">{weekHours + props.hours} hrs</p>
              </div>
            </div>
            {/* <p className="text-sm text-gray-600">Week Hours: {weekHours}hrs <ArrowRightAltIcon style={{ color: "gray" }} /> {weekHours + props.hours}hrs</p>
            {(weekHours + props.hours) <= 40 ?
              <div className="flex items-center gap-1 mt-1">
                <CheckIcon style={{ fontSize: "12px" }} />
                <p className="text-xs">No issues with this shift pickup</p>
              </div> :
              <div className="flex items-center gap-1 mt-1">
                <WarningIcon style={{ fontSize: "12px" }} />
                <p className="text-xs">{props.info.employee_pickup.first_name} will go into overtime if shift approved</p>
              </div>
            } */}
          </div>
        </div>
        {props.isSelected ?
          <div className="w-6 h-6 rounded-full bg-emerald-600 flex items-center justify-center text-white">
            <CheckIcon color="white" style={{ fontSize: "20px" }}/>
          </div> :
          <div className="w-6 h-6 rounded-full border border-slate-300 bg-slate-50"></div>
        }
      </div>
    </div>
  )
}

export default ShiftPoolBidCard
