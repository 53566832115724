import React from "react";
import TextField from "@mui/material/TextField";
import KeyIcon from "@mui/icons-material/Key";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";
import Button from "@mui/material/Button";
import { API_PWD_RESET } from "../constants";

function ForgotPasswordReset({ email, setEmail, setPasswordResetFlow }) {
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Function to handle password reset
  const handlePasswordReset = async () => {
    try {
      // Replace with your actual API endpoint
      const apiUrl = API_PWD_RESET;

      // Make API call
      await axios.post(apiUrl, { email: email });
      console.log(email);

      // If successful, move to the next step
      setPasswordResetFlow(2);
    } catch (error) {
      console.error("Error resetting password", error);
      // Handle error accordingly
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="w-96 flex flex-col items-center justify-center">
        <div className="h-24 w-24 bg-violet-50 flex items-center justify-center rounded-full">
          <div className="h-16 w-16 bg-violet-100 flex items-center justify-center rounded-full text-violet-700">
            <KeyIcon style={{ fontSize: 40 }} />
          </div>
        </div>
        <div className="mt-5 flex flex-col justify-center items-center">
          <h3 className="text-4xl text-slate-900">Forgot Password?</h3>
          <p className="text-slate-500 mt-4 text-base">
            No worries, we'll send you reset instructions
          </p>
        </div>
        <div className="w-full mt-8">
          <p className="text-slate-500 text-sm">Email Address</p>
          <div className="mt-1.5">
            <TextField
              id="outlined-basic"
              // label="Email"
              placeholder="email@email.com"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px !important",
                  height: "55px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6d28d9",
                  },
                  backgroundColor: "white"
                },
              }}
            />
          </div>
        </div>
        {/* <button
          className="w-full bg-primary hover:bg-blue-900 rounded text-white py-4 mt-6"
          onClick={handlePasswordReset}
        >
          Reset Password
        </button> */}
        <div className="w-full mt-6">
          <Button
            onClick={handlePasswordReset}
            fullWidth
            variant="contained"
            //color="primary"
            sx={{
              textTransform: "none",
              borderRadius: "50px",
              paddingY: "14px",
              backgroundColor: "#6d28d9",
              "&:hover": {
                backgroundColor: "#5b21b6",
              },
              fontSize: "16px",
            }}
          >
            <span style={{ color: "white" }}>Reset Password</span>
          </Button>
        </div>
        <button
          className="mt-4 py-4 px-6 text-slate-500 hover:text-slate-900"
          onClick={() => setPasswordResetFlow(0)}
        >
          <KeyboardBackspaceIcon /> Back to log in
        </button>
      </div>
    </div>
  );
}

export default ForgotPasswordReset;
