import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";
import { formatDateShift } from "../../utilities/helpers";
import EmployeeShiftCardUpdatedStatic from "../../shared/shiftswaps/EmployeeShiftCardUpdatedStatic";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import TradeStatusBar from "./TradeStatusBar";

function PendingTableEmployeeRowTradeModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  function handleTradeCancel() {
    props.rescind_trade_shift(props.tradeInfo.id);
  }

  let completion = "";
  if (
    props.tradeInfo.rescinded_by === "employee-drop" &&
    props.tradeInfo.employee_pickup_response === "approved"
  ) {
    completion = "trade_rescinded_by_sender_after_approved";
  } else if (props.tradeInfo.rescinded_by === "employee-drop") {
    completion = "trade_rescinded_by_sender_before_approved";
  } else if (
    props.tradeInfo.employee_pickup_response === "approved" &&
    props.tradeInfo.acting_manager_response === "pending"
  ) {
    completion = "trade_approved_by_employee";
  } else if (
    props.tradeInfo.employee_pickup_response === "denied" &&
    props.tradeInfo.acting_manager_response === "pending"
  ) {
    completion = "trade_denied_by_employee";
  } else if (
    props.tradeInfo.employee_pickup_response === "approved" &&
    props.tradeInfo.acting_manager_response === "approved"
  ) {
    completion = "trade_approved_by_manager";
  } else if (
    props.tradeInfo.employee_pickup_response === "approved" &&
    props.tradeInfo.acting_manager_response === "denied"
  ) {
    completion = "trade_denied_by_manager";
  } else {
    completion = "trade_requested_by_sender";
  }

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-4 top-4 text-gray-500 cursor-pointer"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </div>
        <div className="w-full h-full flex flex-col items-center justify-center">
          <h1 className="text-xl font-bold w-full">Selected Trade Request</h1>
          <div className="w-full mt-6">
            <p className="text-sm text-slate-900">
              {props.tradeReceiver
                ? `${
                    props.tradeInfo.employee_drop.first_name +
                    " " +
                    props.tradeInfo.employee_drop.last_name
                  } is trading away`
                : "You are trading away"}
            </p>
            <div className="mt-1">
              <EmployeeShiftCardUpdatedStatic
                date={formatDateShift(props.tradeInfo.senderDate)}
                shiftStartNumber={
                  props.tradeInfo.shift_drop_info.shift_start_formatted[0]
                }
                shiftStartLetters={
                  props.tradeInfo.shift_drop_info.shift_start_formatted[1]
                }
                shiftEndNumber={
                  props.tradeInfo.shift_drop_info.shift_end_formatted[0]
                }
                shiftEndLetters={
                  props.tradeInfo.shift_drop_info.shift_end_formatted[1]
                }
                break={props.tradeInfo.shift_drop_info.break_formatted}
                role={props.tradeInfo.shift_drop_info.role}
                roleType={props.tradeInfo.shift_drop_info.roleType}
                location={props.tradeInfo.shift_drop_info.location}
              />
            </div>
          </div>
          <div className="text-slate-500 mt-5">
            <SwapVertIcon style={{ fontSize: "28px" }} />
          </div>
          <div className="w-full mt-2">
            <p className="text-sm text-slate-900">
              {props.tradeReceiver
                ? "You are trading away"
                : `${
                    props.tradeInfo.employee_pickup.first_name +
                    " " +
                    props.tradeInfo.employee_pickup.last_name
                  } is trading away`}
            </p>
            <div className="mt-1">
              <EmployeeShiftCardUpdatedStatic
                date={formatDateShift(props.tradeInfo.receiverDate)}
                shiftStartNumber={
                  props.tradeInfo.shift_pickup_info.shift_start_formatted[0]
                }
                shiftStartLetters={
                  props.tradeInfo.shift_pickup_info.shift_start_formatted[1]
                }
                shiftEndNumber={
                  props.tradeInfo.shift_pickup_info.shift_end_formatted[0]
                }
                shiftEndLetters={
                  props.tradeInfo.shift_pickup_info.shift_end_formatted[1]
                }
                break={props.tradeInfo.shift_pickup_info.break_formatted}
                role={props.tradeInfo.shift_pickup_info.role}
                roleType={props.tradeInfo.shift_pickup_info.roleType}
                location={props.tradeInfo.shift_pickup_info.location}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="w-full mt-4">
              <p className="text-slate-900 text-base">Status</p>
              <TradeStatusBar
                completion={completion}
                tradeReceiver={props.tradeReceiver}
                receiverName={
                  props.tradeInfo.employee_pickup.first_name +
                  " " +
                  props.tradeInfo.employee_pickup.last_name
                }
                senderName={
                  props.tradeInfo.employee_drop.first_name +
                  " " +
                  props.tradeInfo.employee_drop.last_name
                }
                renderAnimations={props.renderAnimations}
              />
            </div>
          </div>
          {props.tradeReceiver ? (
            <div className="w-full mt-5">
              {props.tradeInfo.acting_manager_response === "pending" &&
              completion !== "trade_approved_by_employee" ? (
                <div className="w-full flex items-center space-x-3">
                  <button
                    className="w-full bg-blue-900 rounded-full py-4 flex items-center justify-center mt-5"
                    onClick={() =>
                      props.respond_to_trade_request(
                        props.tradeInfo.shift_drop.id,
                        props.tradeInfo.uuid,
                        "approved"
                      )
                    }
                  >
                    <span className="text-white font-semibold text-base">
                      Accept Trade
                    </span>
                  </button>
                  <button
                    className="w-full border-2 border-blue-900 rounded-full py-4 flex items-center justify-center mt-3"
                    onClick={() =>
                      props.respond_to_trade_request(
                        props.tradeInfo.shift_drop.id,
                        props.tradeInfo.uuid,
                        "denied"
                      )
                    }
                  >
                    <span className="text-blue-900 font-semibold text-base">
                      Deny Trade
                    </span>
                  </button>
                </div>
              ) : (
                <div className="w-full flex items-center space-x-3">
                  <div className="w-full bg-gray-200 rounded-full py-4 flex items-center justify-center mt-5">
                    <span className="text-gray-400 font-semibold text-base">
                      Accept Trade
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full py-4 flex items-center justify-center mt-3">
                    <span className="text-gray-400 font-semibold text-base">
                      Deny Trade
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full mt-5">
              {props.tradeInfo.acting_manager_response === "pending" ? (
                <button
                  onClick={() => handleTradeCancel()}
                  className="w-full border-2 border-blue-900 hover:bg-gray-100 rounded-full py-4 flex items-center justify-center"
                >
                  <p className="text-blue-900 font-semibold text-base">
                    Cancel Request
                  </p>
                </button>
              ) : (
                <div className="bg-gray-200 rounded-full py-4 flex items-center justify-center">
                  <p className="text-gray-400 font-semibold text-base">
                    Cancel Request
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default PendingTableEmployeeRowTradeModal;
