import React from "react";
import { PurpleSwitch } from "../../../../helpers/helpers";

// This is actually the toggle between schedule display and availability display
function EditTeamButton(props) {
  return (
    <div className={`w-full h-full bg-white -ml-10 pl-10 ${props.minimizeMode ? "-mt-1.5" : ""}`}>
      {!props.minimizeMode && <h2 className="font-bold">Mode</h2>}
      <div className="flex items-center">
        <h3 className="text-xxs">{props.windowWidth > 1100 ? "Schedule" : "Schedule"}</h3>
        <PurpleSwitch
          checked={props.editTeam}
          onClick={props.toggleEditTeam}
        />
        <h3 className="text-xxs">{props.windowWidth > 1100 ? "Availability" : "Avail"}</h3>
      </div>
    </div>
  );
}

export default EditTeamButton;
