import React from 'react'

function AvatarSmall(props) {
  return (
    <div className={`h-8 w-8 rounded-full ${props.color ? `${props.color} text-white` : "bg-slate-50 text-slate-500"} text-xs flex items-center justify-center`}>
      {props.initials}
    </div>
  )
}

export default AvatarSmall
