import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import { formatDateReadable } from '../../requests/utilities/helpers';
import { PurpleSwitch } from '../../../../helpers/helpers';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function ShiftRecapModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 440,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [hasEnteredMissedShift, setHasEnteredMissedShift] = React.useState(props.shift_info?.publishedschedule?.missed_shift_reason)

  React.useEffect(() => {
    setHasEnteredMissedShift(props.shift_info?.publishedschedule?.missed_shift_reason)
  },[props.shift_info?.publishedschedule?.missed_shift_reason])

  const [missedShiftChecked, setMissedShiftChecked] = React.useState(false)

  const handleMissedShiftChecked = (event) => {
    setMissedShiftChecked(event.target.checked);
    setMissedShiftReason("");
  }
  const [missedShiftReason, setMissedShiftReason] = React.useState("");

  const handleMissedShiftReasonSelect = (event) => {
    setMissedShiftReason(event.target.value);
  };

  const handleSubmit = () => {
    props.post_missed_shift(props.shift_info.id, missedShiftReason)
    props.handleClose()
  }

  const handleRevert = () => {
    props.post_missed_shift(props.shift_info.id, null)
    props.handleClose()
  }

  const missedShiftReasonLookup = {
    "called_off_sick": "Called Off Sick",
    "called_off_other": "Called Off Other",
    "no_show": "No-Call No-Show"
  }


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div>
              <h3 className="text-2xl text-slate-900">Shift Recap</h3>
            </div>
            {props.shift_info.publishedschedule ?
              <div>
                {!hasEnteredMissedShift ?
                  <div>
                    <div>
                      <p className="text-slate-500 text-sm mt-6">Did {props.p.first_name} {props.p.last_name} miss this shift on {formatDateReadable(props.shift_info.date)}?</p>
                      <div className="flex items-center space-x-2 mt-3">
                        <p>No</p>
                        <div>
                          <PurpleSwitch
                            checked={missedShiftChecked}
                            onChange={handleMissedShiftChecked}
                          />
                        </div>
                        <p>Yes</p>
                      </div>
                      {missedShiftChecked &&
                        <div className="mt-3">
                          <p className="text-slate-500 text-sm mt-4">Why did {props.p.first_name} miss this shift?</p>
                          <div className="mt-1">
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={missedShiftReason}
                                onChange={handleMissedShiftReasonSelect}
                              >
                                <FormControlLabel value="called_off_sick" control={
                                  <Radio
                                    sx={{
                                      color: "#6d28d9",
                                      '&.Mui-checked': {
                                        color: "#6d28d9",
                                      },
                                    }}
                                  />
                                } label="Called out sick" />
                                <FormControlLabel value="called_off_other" control={
                                  <Radio
                                    sx={{
                                      color: "#6d28d9",
                                      '&.Mui-checked': {
                                        color: "#6d28d9",
                                      },
                                    }}
                                  />
                                } label="Called out for other reason" />
                                <FormControlLabel value="no_show" control={
                                  <Radio
                                    sx={{
                                      color: "#6d28d9",
                                      '&.Mui-checked': {
                                        color: "#6d28d9",
                                      },
                                    }}
                                  />
                                } label="No-call no-show" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      }
                    </div>
                    {(missedShiftChecked && missedShiftReason) ?
                      <div className="w-full flex justify-end mt-8">
                        <div className="flex space-x-3">
                          <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                            onClick={() => props.handleClose()}
                          >
                            Cancel
                          </div>
                          <div className="px-8 py-2 bg-violet-700 hover:bg-violet-800 text-white rounded-full text-base w-fit cursor-pointer"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </div>
                        </div>
                      </div> :
                      <div className="w-full flex justify-end mt-8">
                        <div className="flex space-x-3">
                          <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                            onClick={() => props.handleClose()}
                          >
                            Cancel
                          </div>
                          <div className="px-8 py-2 bg-slate-200 text-slate-400 rounded-full text-base w-fit"
                            // onClick={() => props.logout()}
                          >
                            Submit
                          </div>
                        </div>
                      </div>
                    }
                  </div> :
                  <div>
                    <p className="text-slate-500 text-sm mt-6">You submitted that <span className="font-bold">{props.p.first_name} {props.p.last_name}</span> missed this shift on {formatDateReadable(props.shift_info.date)}.</p>
                    <p className="text-slate-500 text-sm mt-6">Reason: <span className="font-bold">{missedShiftReasonLookup[props.shift_info?.publishedschedule?.missed_shift_reason]}</span></p>
                    <p className="text-slate-500 text-sm mt-6">If {props.p.first_name} did not actually miss this shift, you can click the "Revert back" button to inform Rightwork that this shift was not missed. </p>
                    <div className="w-full flex justify-end mt-8">
                      <div className="flex space-x-3">
                        <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                          onClick={() => props.handleClose()}
                        >
                          Cancel
                        </div>
                        <div className="px-8 py-2 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                          onClick={() => handleRevert()}
                        >
                          Revert Back
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div> :
              <div>
                <div>
                  <p className="text-slate-500 text-sm mt-6">This {formatDateReadable(props.shift_info.date)} shift was never published for {props.p.first_name} {props.p.last_name}.</p>
                  <p className="text-slate-500 text-sm mt-6">You may not mark an unpublished shift as missed.</p>
                </div>
                <div className="w-full flex justify-end mt-8">
                  <div className="flex space-x-3">
                    <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                      onClick={() => props.handleClose()}
                    >
                      Cancel
                    </div>
                    <div className="px-8 py-2 bg-slate-200 text-slate-400 rounded-full text-base w-fit"
                      // onClick={() => props.logout()}
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ShiftRecapModal
