import React from 'react'
import ShiftCard from '../ShiftCard'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DailyBreakCard from './DailyBreakCard';

function ResizableDailyBreakCard(props) {
  const [currentShiftInfo, setCurrentShiftInfo] = React.useState(props.shift_info);
  const [appliedTo, setAppliedTo] = React.useState([false, false, false, false, false, false, false]);
  const [dragging, setDragging] = React.useState(null);
  const [dragStart, setDragStart] = React.useState(null);

  React.useEffect(() => {
    setCurrentShiftInfo(props.shift_info)
  }, [props.shift_info])

  React.useEffect(() => {
    let appliedToCopy = [false, false, false, false, false, false, false];
    appliedToCopy[props.d] = true;
    setAppliedTo(appliedToCopy);
  }, []);

  const handleMouseDown = (e, type) => {
    e.stopPropagation();
    setDragging(type);
    setDragStart(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const sensitivity = 0.012;
      const rawShiftAmount = (e.clientX - dragStart) * sensitivity;
      const shiftAmount = rawShiftAmount;

      setCurrentShiftInfo((prevShiftInfo) => {
        const newShiftInfo = { ...prevShiftInfo };
        if (dragging === 'start') {
          newShiftInfo.break_start += shiftAmount;
          newShiftInfo.break_length -= shiftAmount;
        } else if (dragging === 'end') {
          newShiftInfo.break_end += shiftAmount;
          newShiftInfo.break_length += shiftAmount;
        } else if (dragging === 'whole') {
          newShiftInfo.break_start += shiftAmount;
          // newShiftInfo.shift_end += shiftAmount;
        }

        newShiftInfo.hours = newShiftInfo.shift_length - newShiftInfo.break_length;

        return newShiftInfo;
      });

      setDragStart(e.clientX);
    }
  };

  const handleMouseUp = () => {
    if (dragging) {
      setDragging(null);
      setDragStart(null);

      setCurrentShiftInfo((prevShiftInfo) => {
        const roundToQuarter = (value) => {
          return Math.round(value * 4) / 4;
        };

        const newShiftInfo = { ...prevShiftInfo };

        if (newShiftInfo.break_start < newShiftInfo.shift_start) {
          newShiftInfo.break_start = newShiftInfo.shift_start
        }

        if ((newShiftInfo.break_start > newShiftInfo.shift_end) || ((newShiftInfo.break_start + newShiftInfo.break_length) > newShiftInfo.shift_end)) {
          newShiftInfo.break_start = newShiftInfo.shift_end - newShiftInfo.break_length
        }
        // newShiftInfo.shift_end = roundToQuarter(newShiftInfo.shift_end);
        newShiftInfo.break_start = roundToQuarter(newShiftInfo.break_start);
        newShiftInfo.break_length = roundToQuarter(newShiftInfo.break_length);
        // newShiftInfo.break_start = (((newShiftInfo.shift_end + newShiftInfo.shift_start) / 2) * 4) / 4 - (newShiftInfo.break_length / 2)
        newShiftInfo.hours = newShiftInfo.shift_length - newShiftInfo.break_length;

        props.addShift(newShiftInfo, 'edit', appliedTo, true);

        return newShiftInfo;
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, dragStart]);


  return (
    <div
      style={{
        height: 32,
        width: currentShiftInfo?.break_length * props.hour_increment + "%",
        marginLeft: (currentShiftInfo?.break_start - props.previousEndTime) * props.hour_increment + 15 + "%",
        position: "absolute",
        cursor: 'default',
        zIndex: 10,
      }}
    >
      {!props.day_in_past && !props.multipleShifts && !currentShiftInfo?.is_copilot_suggestion && (
        <>
          <div
            onMouseDown={(e) => handleMouseDown(e, 'start')}
            style={{
              position: 'absolute',
              left: -5,
              top: 0,
              bottom: 0,
              width: 10,
              cursor: 'ew-resize',
              zIndex: 9999
            }}
          />
          <div
            onMouseDown={(e) => handleMouseDown(e, 'end')}
            style={{
              position: 'absolute',
              right: -5,
              top: 0,
              bottom: 0,
              width: 10,
              cursor: 'ew-resize',
              zIndex: 9999
            }}
          />
        </>
      )}
      {!props.multipleShifts && (
        (!props.day_in_past && !currentShiftInfo?.is_copilot_suggestion) ?
        <div
          className="w-full h-full"
          onMouseDown={(e) => handleMouseDown(e, 'whole')}
          style={{
            cursor: 'move'
          }}
        >
          <DailyBreakCard />
        </div> :
        <div
          className="w-full h-full"
        >
          <DailyBreakCard />
        </div>
      )}
    </div>
  )
}

export default ResizableDailyBreakCard
