import React from "react"
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function DisplayButton(props) {
  return(
    <Tooltip title="Display Mode">
      <IconButton color={props.displayMode ? "primary" : "default"} onClick={props.toggleDisplayMode}>
        <VisibilityIcon />
      </IconButton>
    </Tooltip>
  )
}

export default DisplayButton
