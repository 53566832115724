// import * as React from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";

// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import IconButton from "@mui/material/IconButton";
// import MenuItem from "@mui/material/MenuItem";
// import Tooltip from "@mui/material/Tooltip";
// import TextField from "@mui/material/TextField";
// import CloseIcon from "@mui/icons-material/Close";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #E2E8F0",
//   borderRadius: "15px",
//   boxShadow: 24,
//   p: 4,
// };

// function FilterButton(props) {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [filterValue, setFilterValue] = React.useState("");

//   // const [state, setState] = React.useState({ filter_by: "" });

//   // function handleChange(evt) {
//   //   const value = evt.target.value;
//   //   setState({
//   //     ...state,
//   //     [evt.target.name]: value,
//   //   });
//   // }

//   function handleFilter(event) {
//     setFilterValue(event.target.value);
//   }

//   function cancelFilter() {
//     setFilterValue("");
//     props.filterTeam("");
//   }

//   function submitAndClose() {
//     props.filterTeam(filterValue);
//     handleClose();
//   }

//   function handleQuickFilter() {
//     setFilterValue("has_shift");
//     props.filterTeam("has_shift");
//   }

//   return (
//     <div>
//       <div className="flex items-center">
//         <Tooltip title={props.filtered ? "Cancel Filter" : "Filter By"}>
//           <IconButton
//             color={props.filtered ? "primary" : "default"}
//             onClick={
//               props.filtered
//                 ? cancelFilter
//                 : props.weeklyView
//                 ? handleOpen
//                 : handleQuickFilter
//             }
//           >
//             <FilterAltIcon />
//           </IconButton>
//         </Tooltip>
//         {props.filtered && (
//           <p
//             className="text-xxs -ml-3 mr-2 mt-3 cursor-pointer"
//             style={{ color: "#1976d2" }}
//             onClick={cancelFilter}
//           >
//             {filterValue}
//           </p>
//         )}
//       </div>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <div className="flex flex-col justify-center items-center gap-5">
//             <div
//               className="absolute right-2 top-2 text-gray-500 cursor-pointer"
//               onClick={handleClose}
//             >
//               <CloseIcon />
//             </div>
//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//               style={{ fontSize: "24px" }}
//             >
//               Filter By
//             </Typography>
//             <div className="mt-2">
//               <Typography id="modal-modal-description">
//                 <TextField
//                   label="Filter By"
//                   name="filter_by"
//                   select
//                   value={filterValue}
//                   onChange={handleFilter}
//                   style={{ width: "200px" }}
//                 >
//                   <MenuItem value={"Scheduled Only"}>Scheduled Only</MenuItem>
//                   <MenuItem value={"Has Availability"}>
//                     Has Availability
//                   </MenuItem>
//                 </TextField>
//               </Typography>
//             </div>
//             <div className="mt-3">
//               <Button
//                 variant="contained"
//                 endIcon={<FilterAltIcon />}
//                 onClick={() => submitAndClose()}
//                 sx={{ textTransform: "none", borderRadius: "8px" }}
//               >
//                 {"Filter"}
//               </Button>
//             </div>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// export default FilterButton;

import * as React from "react";
import { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";

function FilterButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState([
    "Scheduled Only",
    "Has Availability",
  ]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilter = (event) => {
    setFilterValue(event.target.value);
  };

  const cancelFilter = () => {
    setFilterValue("");
    props.filterTeam("");
  };

  const submitAndClose = () => {
    props.filterTeam(filterValue);
    toggleDropdown();
  };

  const handleQuickFilter = () => {
    setFilterValue("has_shift");
    props.filterTeam("has_shift");
    toggleDropdown();
  };

  const dropdownRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div className="relative">
      <div className="flex items-center">
        <div
          className="relative group"
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          <Tooltip title={`${!isOpen ? "Filter By" : ""}`}>
            <IconButton
              style={{ color: props.filtered ? "#6d28d9" : undefined }}
              className="group-hover:bg-gray-200 transition"
            >
              <FilterAltIcon />
            </IconButton>
          </Tooltip>

          {isOpen && (
            <div className="absolute left-0 w-[275px] mt-2 bg-white border border-slate-200 shadow-xl rounded-lg pt-3 z-30">
              <h3 className="text-slate-500 text-sm pb-3 px-5 font-bold">
                Filter By
              </h3>
              {filterList.map((item, index) => (
                <div
                  className="w-full py-1 px-5 hover:bg-slate-50 flex items-center justify-between cursor-pointer"
                  key={index}
                  onClick={() => {
                    setFilterValue(item);
                    props.filterTeam(item);
                    toggleDropdown();
                  }}
                >
                  <p
                    className={`${
                      filterValue === item
                        ? "text-violet-700"
                        : "text-slate-500"
                    } text-sm py-3 hover:bg-slate-50`}
                  >
                    {item}
                  </p>
                  {filterValue === item && (
                    <div className="text-violet-700">
                      <CheckIcon style={{ fontSize: "18px" }} />
                    </div>
                  )}
                </div>
              ))}
              <div
                className="py-3 border-t border-slate-200 w-full flex justify-center hover:bg-slate-50 cursor-pointer rounded-b-lg"
                onClick={cancelFilter}
              >
                <p className="text-slate-500 font-semibold text-xs">Reset</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FilterButton;
