import React from "react";
import { formatDateReadableWithDayOfWeek } from "../../requests/utilities/helpers";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

function HistoricalSalesContextCard(props) {
  let actualColor = "";
  if (props.adjusted_forecast) {
    if (props.adjusted_forecast <= props.actual_sales) {
      actualColor = "bg-emerald-50 text-emerald-800";
    } else {
      actualColor = "bg-rose-50 text-rose-800";
    }
  } else {
    if (props.initial_forecast <= props.actual_sales) {
      actualColor = "bg-emerald-50 text-emerald-800";
    } else {
      actualColor = "bg-rose-50 text-rose-800";
    }
  }

  console.log("INITIAL FORECAST", props.initial_forecast);

  return (
    <div>
      <p className="text-xs text-slate-500">
        {props.date ? formatDateReadableWithDayOfWeek(props.date) : ""}
      </p>
      <div className="mt-1">
        <div className={`border border-slate-200 p-3 rounded-lg flex items-center justify-between ${props.holiday ? "bg-amber-50" : "bg-white"}`}>
          <div>
            <p className="text-xs text-slate-500">Forecasted</p>
            {props.initial_forecast ? (
              <p className="text-sm text-slate-900 py-0.5">
                {Number(props.initial_forecast).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            ) : (
              <p className="text-sm text-slate-900 py-0.5">No data</p>
            )}
          </div>
          {props.adjusted_forecast && (
            <div>
              <p className="text-xs text-slate-500 text-center">Adjusted</p>
              <div className="flex items-center space-x-1">
                <p className="text-sm text-slate-900 text-center py-0.5">
                  {Number(props.adjusted_forecast).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </p>
                {Math.abs(props.actual_sales - props.adjusted_forecast) <=
                Math.abs(props.actual_sales - props.initial_forecast) ? (
                  <div className="text-emerald-700">
                    <ThumbUpIcon
                      style={{ fontSize: "14px", marginBottom: "2px" }}
                    />
                  </div>
                ) : (
                  <div className="text-rose-700">
                    <ThumbDownIcon
                      style={{ fontSize: "14px", marginBottom: "2px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <div>
            <p className="text-xs text-slate-500 text-end">Actual</p>
            {props.actual_sales ? (
              <p
                className={`text-sm text-slate-900 text-end py-0.5 px-1.5 rounded ${actualColor}`}
              >
                {Number(props.actual_sales).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            ) : (
              <p className="text-sm text-slate-900 py-0.5 text-end">No data</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoricalSalesContextCard;
