import React from 'react'
import TimeOffCardDeleteModal from './TimeOffCardDeleteModal';

function TimeOffCard(props) {
  // Set the maximum length for the title
  const maxLength = 54;
  // Truncate the title if it exceeds the maximum length
  const truncatedTitle = props.title.length > maxLength ? props.title.substring(0, maxLength) + '...' : props.title;

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  console.log("TIME OFF INFO", props.timeOffInfo)

  return (
    <>
      <div
        className="w-full h-full py-3 px-3 text-slate-900 hover:text-black bg-red-200 hover:bg-red-300 rounded-lg shadow-md flex flex-col justify-center cursor-pointer"
        onClick={handleOpenDeleteModal}
      >
        <p className="text-xs font-bold xl:font-normal">Time Off</p>
        {!props.minimizeMode && <p className="text-xxs">{truncatedTitle}</p>}
      </div>
      <TimeOffCardDeleteModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        timeOffInfo={props.timeOffInfo}
        p={props.p}
        delete_time_off_request={props.delete_time_off_request}
      />
    </>
  )
}

export default TimeOffCard
