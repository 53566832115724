import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddTaskIcon from "@mui/icons-material/AddTask";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { RosterGraph } from "./visual_components/RosterGraph";
import { DeploymentGraph } from "./visual_components/DeploymentGraph";
import { CoverageGraph } from "./visual_components/CoverageGraph";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import DailyPlan from "./visual_components/Deployment/DailyPlan";

// Modal style object
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  minHeight: 400,
  width: "80%",
  height: 600,
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function VisualButtonModal(props) {
  function process_schedule_data() {
    const schedule_data = props.actual_schedule.map((x) => {
      x.pre_shift = x.shift_start - 0;
      if (x.break_length > 0) {
        x.pre_break = x.break_start - x.shift_start;
        x.break = x.break_length;
        x.post_break = x.shift_end - (x.break_start + x.break_length);
      } else {
        x.pre_break = x.shift_length;
        x.break = 0;
        x.post_break = 0;
      }
      x.post_shift = 24 - x.shift_end;
      const person_info = props.store_team_member_weekly_info.filter(
        (p) => p.scheduling_app_user_id === x.person
      );
      x.name =
        person_info.length > 0
          ? person_info[0].first_name + " " + person_info[0].last_name[0] + "."
          : "";

      return x;
    });

    return schedule_data.sort(
      (a, b) => a.shift_start > b.shift_start || a.shift_length > b.shift_length
    );
  }
  //    rcmnd_deploy_prep1 = rcmnd_deploy[['store_num','date','role_type','qh_num','role_list']].explode('role_list')
  const res = props.combine_data(
    props.forecasted_labor_day,
    props.forecasted_workload_day,
    props.recommended_schedule_labor_day,
    props.actual_schedule_labor_day
  );
  const schedule_data = process_schedule_data();

  console.log(res.data);
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={props.handleClose}
          >
            <CancelIcon />
          </div>
          <div>
            <div className="flex items-center gap-8">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ fontSize: "24px" }}
              >
                {props.title}
              </Typography>
              <div className="text-gray-500">
                <Tooltip title={props.description}>
                  <HelpIcon />
                </Tooltip>
              </div>
            </div>
            <div className="mt-2">
              <Typography id="modal-modal-description">
                {props.date_name}
              </Typography>
            </div>
            <div style={{ width: "100%", height: "400px", marginTop: "10px" }}>
              {props.title === "Daily Plan" ? (
                <DailyPlan
                  data={res.data}
                  schedule={schedule_data}
                  chartid="dailyplan"
                  roles={res.roles}
                  get_t_str={props.get_t_str}
                />
              ) : (
                <div />
              )}
              {props.title === "Daily Roster" ? (
                <RosterGraph
                  data={schedule_data}
                  chartid="rostergraph"
                  get_t_str={props.get_t_str}
                  role_types={props.role_types}
                />
              ) : (
                <div />
              )}
              {props.title === "Deployment Guide" ? (
                <DeploymentGraph
                  data={res.data}
                  roles={res.roles}
                  chartid="deploymentgraph"
                  hoo={[7.5, 24]}
                  boxHeight={450}
                />
              ) : (
                <div />
              )}
              {props.title === "Workload Graph" ? (
                <CoverageGraph
                  data={res.data}
                  chartid="coveragegraph"
                  hoo={[7.5, 24]}
                  boxHeight={450}
                />
              ) : (
                <div />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default VisualButtonModal;
