import axios from "axios";
import {
  API_post_file_upload_with_eagerly_generated_presigned_url,
  API_get_file_upload,
} from "../../../../constants";

/*
 * Returns axios config object with authentication Token.
 */
export async function getDefaultAxiosConfig() {
  try {
    const token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
  } catch (error) {
    // Handle error while retrieving token
    console.error("Error retrieving authentication token:", error);
    return {
      headers: {},
    };
  }
}

export async function getRightworkAPI(url, data_obj) {
  const params = new URLSearchParams(data_obj).toString();
  console.log(url + "?" + params);

  try {
    const axiosConfig = await getDefaultAxiosConfig();
    const res = await axios.get(url + "?" + params, axiosConfig);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function postRightworkAPI(url, data_obj) {
  const axiosConfig = await getDefaultAxiosConfig();
  const res = await axios.post(url, data_obj, axiosConfig);
  return res;
}


export async function uploadFile(company_id, file, type) {
  const formData = new FormData();
  formData.append("company_id", company_id);
  formData.append("file_name", file.name);
  formData.append("type", type);

  // [1] Get presigned URL
  const axiosConfig = await getDefaultAxiosConfig();
  const res = await axios.post(API_post_file_upload_with_eagerly_generated_presigned_url, formData, {
    headers: {
      ...axiosConfig.headers,
      "Content-Type": "multipart/form-data",
    },
  });
  const fileUUID = res?.data?.redux?.payload?.uuid;
  const presignedUrl = res?.data?.redux?.payload?.presigned_url;

  // [2] Upload file
  await axios.put(presignedUrl, file, {
    headers: {
      'Content-Type': file.type,
    }
  });

  // [3] Get file URL
  let api_params = {
    file_upload_uuid: fileUUID,
  };
  const resFile = await getRightworkAPI(API_get_file_upload, api_params);
  const resFilePayload = resFile?.data?.redux?.payload;

  // [4] Weeee
  return resFilePayload;
};