import React, { useState } from "react";
import { useEffect } from "react";
import Divider from '@mui/material/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const PilotInfoScreen = (props) => {

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    // Validate Number of Pilots
    if (props.onboardingInfo.numOfPilots === '') {
      newErrors.numOfPilots = 'Number of Pilots is required';
    }

    // Validate Pilot Name
    if (props.onboardingInfo.pilotLocationName.trim() === '') {
      newErrors.pilotLocationName = 'Pilot Location Name is required';
    }

    // Validate Operating Hours
    if (props.onboardingInfo.operatingHours.trim() === '') {
      newErrors.operatingHours = 'Operating Hours is required';
    }

    // Validate Pilot Access
    if (props.onboardingInfo.pilotAccess === '') {
      newErrors.pilotAccess = 'Pilot access is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleNextStep = () => {
    const isValid = validateForm();

    if (isValid) {
      // Proceed to the next step
      props.increaseStep();
    }
  };


  const [hasDifferentOperatingHours, setHasDifferentOperatingHours] = useState(false);
  const [operatingHoursPerDay, setOperatingHoursPerDay] = useState({});

  useEffect(() => {
    // Reset operating hours when checkbox is unchecked
    if (!hasDifferentOperatingHours) {
      setOperatingHoursPerDay({});
    }
  }, [hasDifferentOperatingHours]);

  const handleOperatingHoursChange = (e, day) => {
    const value = e.target.value;
    setOperatingHoursPerDay((prevOperatingHours) => ({
      ...prevOperatingHours,
      [day]: value,
    }));
  };

  const renderLocationForms = () => {
    const forms = [];
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    for (let i = 1; i <= props.onboardingInfo.numOfPilots; i++) {
      forms.push(
        <div key={i} className="mt-8">
          <div className="flex flex-col gap-4">
            <div>
              <Divider style={{ backgroundColor: "gray" }}/>
            </div>
            <div>
              <div>
                <label className="block mb-1 text-gray-300">Location {i} Name</label>
                <input
                  type="text"
                  className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                    errors.pilotLocationName ? "border-red-500" : "border-[#2f3847]"
                  }`}
                  value={props.onboardingInfo.pilotLocationName}
                  onChange={(e) => props.setOnboardingInfo({ ...props.onboardingInfo, pilotLocationName: e.target.value })}
                />
                {errors.pilotLocationName && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.pilotLocationName}
                </p>
              )}
              </div>
              <div className="mt-4">
                <label className="block mb-1 text-gray-300">Operating Hours</label>
                {!hasDifferentOperatingHours &&
                  <input
                    type="text"
                    className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                      errors.operatingHours ? "border-red-500" : "border-[#2f3847]"
                    }`}
                    value={props.onboardingInfo.operatingHours}
                    onChange={(e) => props.setOnboardingInfo({ ...props.onboardingInfo, operatingHours: e.target.value })}
                  />
                }
                {!hasDifferentOperatingHours && errors.operatingHours && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.operatingHours}
                  </p>
                )}
              </div>
              {hasDifferentOperatingHours &&
              daysOfWeek.map((day) => (
                <div key={day} className="mt-4 ml-10 flex items-center">
                  <label className="block mb-1 text-gray-300 w-32">{day}:</label>
                  <input
                    type="text"
                    className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
                      errors.operatingHours ? "border-red-500" : "border-[#2f3847]"
                    }`}
                    value={props.onboardingInfo.operatingHours}
                    onChange={(e) => props.setOnboardingInfo({ ...props.onboardingInfo, operatingHours: e.target.value })}
                  />
                </div>
              ))}
              {hasDifferentOperatingHours && errors.operatingHours && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.operatingHours}
                  </p>
                )}
              <div className="mt-1.5">
                <input
                  type="checkbox"
                  checked={hasDifferentOperatingHours}
                  onChange={(e) => setHasDifferentOperatingHours(e.target.checked)}
                  className="mr-2"
                />
                <label className="text-gray-300">My company has different operating hours on different days</label>
              </div>
            </div>
            <div>
              <label className="block mb-1 text-gray-300">Who should have access to this location? Please include names, emails, and access level (executive, manager, employee). Please separate people with commas. You can always add more later.</label>
              <textarea
                className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 h-36 ${
                  errors.pilotAccess ? "border-red-500" : "border-[#2f3847]"
                }`}
                placeholder="Jimothy Halpert - jimothy.halpert@dundermifflin.com - employee, David Wallace - david.wallace@dundermifflin.com - executive, etc."
                value={props.onboardingInfo.pilotAccess}
                onChange={(e) => props.setOnboardingInfo({ ...props.onboardingInfo, pilotAccess: e.target.value })}
              />
              {errors.pilotAccess && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.pilotAccess}
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
    return forms;
  };

  return (
    <div>
      <div className="relative">
        <button className="absolute text-gray-100 -left-12 top-1" onClick={props.decreaseStep}>
          <ArrowBackIosIcon style={{ fontSize:"26px" }}/>
        </button>
        <h2 className="text-3xl text-gray-100 font-bold">How many locations would you like to pilot?</h2>
      </div>
      <div className="mt-5">
        <Divider style={{ backgroundColor: "gray" }} />
      </div>
      <div>
        <div className="mt-8">
          <label className="block mb-1 text-gray-300">Number of Locations</label>
          <select
            className={`border-2 rounded-md p-2 w-full bg-[#252f3f] text-gray-200 ${
              errors.numOfPilots ? "border-red-500" : "border-[#2f3847]"
            }`}
            value={props.onboardingInfo.numOfPilots}
            onChange={(e) => props.setOnboardingInfo({...props.onboardingInfo, numOfPilots: e.target.value})}
          >
            <option value="">Select a number</option>
            {[...Array(10)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          {errors.numOfPilots && (
            <p className="text-red-500 text-sm mt-1">
              {errors.numOfPilots}
            </p>
          )}
        </div>
        {props.onboardingInfo.numOfPilots && renderLocationForms()}
        <div className="w-1/2 mt-8">
          <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-full" onClick={handleNextStep}>
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default PilotInfoScreen;
