import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import LoadSpinner from "../../../../../utilities/LoadSpinner";
import CircularProgress from "@mui/material/CircularProgress";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import AutoScheduleComparison from "./AutoScheduleComparison";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import TuneIcon from "@mui/icons-material/Tune";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  maxHeight: "98%",
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 5,
  overflow: "hidden",
};

function calcLetter(score) {
  if (score >= 90) {
    return "A";
  } else if (score >= 80) {
    return "B";
  } else if (score >= 70) {
    return "C";
  } else if (score >= 60) {
    return "D";
  } else if (score === 0) {
    return "-";
  } else {
    return "F";
  }
}

function AutoScheduleFunction(props) {
  const [open, setOpen] = React.useState(false);
  const [buttonPressed, setButtonPressed] = React.useState(false);
  const [secondLoadingMessage, setSecondLoadingMessage] = React.useState(false);
  const [thirdLoadingMessage, setThirdLoadingMessage] = React.useState(false);
  const [fourthLoadingMessage, setFourthLoadingMessage] = React.useState(false);
  const [fifthLoadingMessage, setFifthLoadingMessage] = React.useState(false);

  const [scheduleComparison, setScheduleComparison] = React.useState(false);
  const [acceptedLoading, setAcceptedLoading] = React.useState(false);

  const compareOnFinish = (loading_finished) => {
    if (loading_finished) {
      setSecondLoadingMessage(false);
      setThirdLoadingMessage(false);
      setFourthLoadingMessage(false);
      setFifthLoadingMessage(false);
      setScheduleComparison(true);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    setSecondLoadingMessage(false);
    setThirdLoadingMessage(false);
    setFourthLoadingMessage(false);
    setFifthLoadingMessage(false);
    setScheduleComparison(false);
    setButtonPressed(false);
    setAcceptedLoading(false);
    props.setAutoScheduleFinished(false);
    props.setAcceptAutoScheduleFinished(false);
    props.setAutoScheduleError(false);
  }, [open])

  const handleStart = (runType) => {
    props.autoSchedule(runType);
    setButtonPressed(true);
    setTimeout(() => {
      setSecondLoadingMessage(true);
    }, 5000);
    setTimeout(() => {
      setThirdLoadingMessage(true);
    }, 10000);
    setTimeout(() => {
      setFourthLoadingMessage(true);
    }, 15000);
  };

  const handleAutoFill = () => {
    props.lockAllShifts();
    props.setLockAllShifts();
    handleStart("autoschedule");
  };

  const handleClose = () => {
    setOpen(false);
    setSecondLoadingMessage(false);
    setThirdLoadingMessage(false);
    setFourthLoadingMessage(false);
    setFifthLoadingMessage(false);
    setScheduleComparison(false);
    setButtonPressed(false);
    setAcceptedLoading(false);
    props.setAutoScheduleFinished(false);
    props.setAcceptAutoScheduleFinished(false);
    props.setAutoScheduleError(false);
  };

  function cancelAndClose() {
    handleClose();
  }
  function saveAndClose(days) {
    props.acceptNewSchedule(days);
    setAcceptedLoading(true);
  }

  useEffect(() => {
    if (props.acceptAutoScheduleFinished) {
      handleClose();
    }
  }, [props.acceptAutoScheduleFinished]);

  useEffect(() => {
    compareOnFinish(props.autoScheduleFinished);
  }, [props.autoScheduleFinished]);

  useEffect(() => {
    if (props.autoScheduleError) {
      handleClose();
    }
  }, [props.autoScheduleError]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:
      buttonPressed && !props.autoScheduleFinished
        ? "30%"
        : scheduleComparison
        ? "98%"
        : "65%",
    height: props.autoScheduleFinished ? "98%" : "content",
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    paddingX: 4,
    paddingY: 3,
    overflow: "scroll",
  };

  return (
    <div>
      {props.week_ended || !props.autosched_available ?
        <div>
          <Tooltip
            title={props.week_ended ? "Autoschedule not available for past weeks" : "Autoschedule not available"}
          >
            <div className="text-[#bdbdbd] w-10 h-10 flex items-center justify-center">
              {props.autoScheduleIcon()}
            </div>
          </Tooltip>
        </div> :
        <div>
          <Tooltip
            title={
              props.autoScheduleTitle
              //  props.loadedFromAuto
              //</div>    ? "Current Schedule Was Auto Generated"
              //    : "Auto Schedule Shifts"
            }
          >
            <IconButton
              // color="success"
              onClick={handleOpen}
              disabled={props.week_ended || !props.autosched_available}
            >
              {props.autoScheduleIcon()}
            </IconButton>
          </Tooltip>
        </div>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {buttonPressed ? (
            scheduleComparison ? (
              <AutoScheduleComparison
                saveAndClose={saveAndClose}
                cancelAndClose={cancelAndClose}
                calcLetter={calcLetter}
                acceptedLoading={acceptedLoading}
                schedule_differences={props.schedule_differences}
                store_team_member_weekly_info={
                  props.store_team_member_weekly_info
                }
                get_t_str={props.get_t_str}
                actual_schedule={props.actual_schedule}
                role_types={props.role_types}
                handleClose={handleClose}
                role_names={props.role_names}
              />
            ) : (
              <div className="h-80">
                <div className="pb-8 border-b border-slate-200">
                  <h2 className="text-slate-900 text-xl">
                    Optimizing Your Schedule
                  </h2>
                  <p className="text-slate-500 text-sm mt-2">
                    This can take up to 5 minutes. Thank you for being patient!
                    Please don't click outside of this modal.
                  </p>
                </div>
                <div className="mt-8">
                  <div className="flex items-center gap-3">
                    {secondLoadingMessage ? (
                      <CheckCircleIcon
                        sx={{ color: "#059669", fontSize: "26px" }}
                      />
                    ) : (
                      <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                        <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                      </div>
                    )}
                    {secondLoadingMessage ? (
                      <p className="text-emerald-800">
                        Demand Analyzed Successfully
                      </p>
                    ) : (
                      <p>Analyzing Demand...</p>
                    )}
                  </div>
                  <div
                    className={`flex items-center gap-3 mt-5 ${
                      thirdLoadingMessage && "hidden"
                    }`}
                  >
                    {secondLoadingMessage && (
                      <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                        <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                      </div>
                    )}
                    {secondLoadingMessage && <p>Analyzing Availability...</p>}
                  </div>
                  <div className="flex items-center gap-3 mt-5">
                    {thirdLoadingMessage && (
                      <CheckCircleIcon
                        sx={{ color: "#059669", fontSize: "26px" }}
                      />
                    )}
                    {thirdLoadingMessage && (
                      <p className="text-emerald-800">
                        Availability Analyzed Successfully
                      </p>
                    )}
                  </div>
                  <div
                    className={`flex items-center gap-3 mt-5 ${
                      fourthLoadingMessage && "hidden"
                    }`}
                  >
                    {thirdLoadingMessage && (
                      <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                        <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                      </div>
                    )}
                    {thirdLoadingMessage && <p>Creating Feasible Shifts...</p>}
                  </div>
                  <div className="flex items-center gap-3 mt-5">
                    {fourthLoadingMessage && (
                      <CheckCircleIcon
                        sx={{ color: "#059669", fontSize: "26px" }}
                      />
                    )}
                    {fourthLoadingMessage && (
                      <p className="text-emerald-800">
                        Feasible Shifts Created
                      </p>
                    )}
                  </div>
                  <div
                    className={`flex items-center gap-3 mt-5 ${
                      fifthLoadingMessage && "hidden"
                    }`}
                  >
                    {fourthLoadingMessage && (
                      <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                        <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                      </div>
                    )}
                    {fourthLoadingMessage && <p>Finding Optimal Schedule...</p>}
                  </div>
                  <div className="flex items-center gap-3 mt-5">
                    {fifthLoadingMessage && (
                      <CheckCircleIcon
                        sx={{ color: "#059669", fontSize: "26px" }}
                      />
                    )}
                    {fifthLoadingMessage && (
                      <p className="text-emerald-800">Found Optimal Schedule</p>
                    )}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="w-full">
              <div
                className="absolute right-5 top-5 text-slate-500 cursor-pointer"
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
              <div className="w-[70%]">
                <h2 className="text-xl text-slate-900">Autoschedule Options</h2>
                <p className="text-slate-500 text-sm mt-2">
                  Choose an option to use RightWork's algorithms to help build
                  an efficient, optimized schedule. All options take into
                  account employee availability, preferences, and target hours.
                  You will be able to review any changes made before proceeding.
                </p>
              </div>
              <div className="w-full bg-slate-50 rounded-xl flex justify-center p-5 mt-8">
                <div className="w-[70%] bg-white rounded-xl border border-slate-200 py-5">
                  <div className="w-full px-5 pb-5 border-b border-slate-200 flex items-center space-x-5">
                    <div className="w-12 h-12 rounded-lg bg-violet-50 flex items-center justify-center text-violet-700">
                      <TuneIcon style={{ fontSize: "28px" }} />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-slate-900">Autotune</h4>
                      <p className="text-slate-500 text-sm mt-1 pr-8">
                        Alters start and stop times of current shifts to
                        optimize schedule
                      </p>
                    </div>
                    <div
                      className="px-4 py-1 bg-emerald-600  hover:bg-emerald-700 text-white rounded-lg cursor-pointer"
                      onClick={() => handleStart("autotune")}
                    >
                      Go
                    </div>
                  </div>
                  <div className="w-full px-5 py-5 border-b border-slate-200 flex items-center space-x-5">
                    <div className="w-12 h-12 rounded-lg bg-violet-50 flex items-center justify-center text-violet-700">
                      <AutoFixHighIcon style={{ fontSize: "28px" }} />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-slate-900">Autoschedule</h4>
                      <p className="text-slate-500 text-sm mt-1 pr-8">
                        Builds an optimized schedule that perfectly meets demand
                      </p>
                    </div>
                    <div
                      className="px-4 py-1 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg cursor-pointer"
                      onClick={() => handleStart("autoschedule")}
                    >
                      Go
                    </div>
                  </div>
                  <div className="w-full px-5 pt-5 flex items-center space-x-5">
                    <div className="w-12 h-12 rounded-lg bg-violet-50 flex items-center justify-center text-violet-700">
                      <PersonAddAlt1Icon style={{ fontSize: "28px" }} />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-slate-900">Autofill</h4>
                      <p className="text-slate-500 text-sm mt-1 pr-8">
                        Creates new shifts to fill in the demand curve and
                        prevent understaffing
                      </p>
                    </div>
                    <div
                      className="px-4 py-1 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg cursor-pointer"
                      onClick={() => handleStart("autofill")}
                    >
                      Go
                    </div>
                  </div>
                </div>
              </div>
              {/* <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ fontSize: "26px" }}
              >
                {props.autoScheduleTitle}
              </Typography>
              {props.autoScheduleText()}
              <div className="mt-3">
                <Button
                  variant="contained"
                  endIcon={props.autoScheduleIcon()}
                  onClick={() => handleStart()}
                  sx={{ textTransform: "none", borderRadius: "8px" }}
                >
                  {props.autoScheduleButtonText}
                </Button>
              </div> */}
              {/* <div className="w-full flex flex-col items-center">
                <div className="w-[90%]">
                  <h2 className="text-2xl font-bold text-gray-700">
                    Autoscheduling Options
                  </h2>
                  <h5 className="mt-4 text-gray-600 text-sm">
                    These options allow you to use RightWork's algorithms to
                    help you build your schedule to be as efficient and
                    optimized as possible.
                  </h5>
                  <h5 className="mt-4 text-gray-600 text-sm">
                    <b>Note:</b> No need to worry about unwanted changes! After
                    clicking any of these options, you will have the ability to
                    see exactly what changes will be made to your schedule and
                    have the opportunity to accept or reject them.
                  </h5>
                </div>
                <div className="w-full flex items-center justify-between mt-8">
                  <div className="w-[30%] h-[460px] border border-gray-300 rounded-xl p-8">
                    <div>
                      <TuneIcon
                        style={{ fontSize: "28px", color: "#1876d1" }}
                      />
                    </div>
                    <h5 className="font-bold text-lg mt-3 text-gray-800">
                      Autotune
                    </h5>
                    <p className="text-gray-500 text-xs mt-2">
                      Alters start and stop times of current shifts to optimize
                      schedule
                    </p>
                    <button
                      className="w-full rounded-lg py-3 border border-gray-300 text-[#1876d1] hover:border-[#1876d1] hover:bg-gray-100 text-xs font-bold mt-5"
                      onClick={() => handleStart("autotune")}
                    >
                      Run Autotune
                    </button>
                    <div className="w-full text-xs text-left text-gray-500 mt-5 flex flex-col gap-4">
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>Does not affect locked shifts</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>Does not delete or create shifts</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>
                          Only alters start and stop times to meet demand more
                          efficiently
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>
                          Takes into account employee target hours,
                          availability, and preferences
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[30%] h-[460px] border border-gray-300 rounded-xl p-8">
                    <div>
                      <AutoFixHighIcon
                        style={{ fontSize: "28px", color: "#1876d1" }}
                      />
                    </div>
                    <h5 className="font-bold text-lg mt-3 text-gray-800">
                      Autoschedule
                    </h5>
                    <p className="text-gray-500 text-xs mt-2">
                      Builds an optimized schedule that perfectly meets demand
                    </p>
                    <button
                      className="w-full rounded-lg py-3 bg-[#1876d1] hover:bg-blue-900 text-white text-xs font-bold mt-5"
                      onClick={() => handleStart("autoschedule")}
                    >
                      Run Autoschedule
                    </button>
                    <div className="w-full text-xs text-left text-gray-500 mt-5 flex flex-col gap-4">
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>Does not affect locked shifts</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>Can delete unlocked shifts and create new shifts</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>
                          Meets the demand curve as efficiently as possible to
                          avoid over or understaffing
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>
                          Takes into account employee target hours,
                          availability, and preferences
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[30%] h-[460px] border border-gray-300 rounded-xl p-8">
                    <div>
                      <PersonAddAlt1Icon
                        style={{ fontSize: "28px", color: "#1876d1" }}
                      />
                    </div>
                    <h5 className="font-bold text-lg mt-3 text-gray-800">
                      Autofill
                    </h5>
                    <p className="text-gray-500 text-xs mt-2">
                      Keeps all current shifts as they are but creates new
                      shifts to optimize schedule
                    </p>
                    <button
                      className="w-full rounded-lg py-3 border border-gray-300 text-[#1876d1] hover:border-[#1876d1] hover:bg-gray-100 text-xs font-bold mt-5"
                      onClick={() => handleStart("autofill")}
                    >
                      Run Autofill
                    </button>
                    <div className="w-full text-xs text-left text-gray-500 mt-5 flex flex-col gap-4">
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>Locks all current shifts</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>Only creates new shifts</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>
                          Helps fill in the demand curve to avoid any
                          understaffing
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon
                          style={{ fontSize: "18px", color: "#1876d1" }}
                        />
                        <p>
                          Takes into account employee target hours,
                          availability, and preferences
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default AutoScheduleFunction;
