import React from 'react'
import Account from './Account'
import Notifications from './Notifications'

function TopBarDisplayTitleNoLocationSelect(props) {
  return (
    <div className="py-5 px-6 border-b border-slate-200 flex items-center justify-between">
      <h2 className="text-2xl text-slate-900">{props.title}</h2>
      <div className="flex">
        <div className="cursor-pointer">
          <Account />
        </div>
      </div>
    </div>
  )
}

export default TopBarDisplayTitleNoLocationSelect
