import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

import LoadSpinner from "../../../../../utilities/LoadSpinner";

import CircularProgress from "@mui/material/CircularProgress";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function LoadFromScheduleButton(props) {
  const [open, setOpen] = React.useState(false);
  const [buttonPressed, setButtonPressed] = React.useState(false);
  const [secondLoadingMessage, setSecondLoadingMessage] = React.useState(false);
  const [thirdLoadingMessage, setThirdLoadingMessage] = React.useState(false);
  const [fourthLoadingMessage, setFourthLoadingMessage] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleStart = () => {
    props.loadFrom7Shift(weeksBack);
    setButtonPressed(true);
    setTimeout(() => {
      setSecondLoadingMessage(true);
    }, 3000);
    setTimeout(() => {
      setThirdLoadingMessage(true);
    }, 6000);
  };

  const completeOnFinish = (loading_finished) => {
    if (loading_finished) {
      setSecondLoadingMessage(true);
      setThirdLoadingMessage(true);
      setFourthLoadingMessage(true);
      setButtonPressed(false);
      handleClose()
    }
  };

  const handleClose = () => {
    setOpen(false);
    setButtonPressed(false);
    setSecondLoadingMessage(false);
    setThirdLoadingMessage(false);
    setFourthLoadingMessage(false);
    props.setLoad7ShiftsScheduleFinished(false);
    setWeeksBack(0);
    set_load_week_start_date(props.week_start_date);
  };

  // const [state, setState] = React.useState({ weeks_back: 0 });

  // function handleChange(evt) {
  //   const value = evt.target.value;
  //   setState({
  //     ...state,
  //     [evt.target.name]: value,
  //   });
  // }

  const [weeksBack, setWeeksBack] = React.useState(0);

  function incrementWeek(start_date) {
    const new_start_date = addDays(start_date, 1 * 7);
    set_load_week_start_date(new_start_date);
    setWeeksBack((prevState) => prevState - 1);
  }

  function decrementWeek(start_date) {
    const new_start_date = addDays(start_date, -1 * 7);
    set_load_week_start_date(new_start_date);
    setWeeksBack((prevState) => prevState + 1);
  }

  useEffect(() => {
    completeOnFinish(props.load7ShiftsScheduleFinished);
  }, [props.load7ShiftsScheduleFinished]);

  const [load_week_start_date, set_load_week_start_date] = useState(
    props.week_start_date
  );

  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  const start_date = load_week_start_date;
  const end_date = addDays(load_week_start_date, 6);

  const date_options = { month: "short", day: "numeric" };

  useEffect(() => {
    set_load_week_start_date(props.week_start_date);
  }, [props.week_start_date]);

  const tooltipTitle = props.has7ShiftsIntegration
    ? "Load from 7Shifts"
    : "Copy from previous week";
  const modalTitle = props.has7ShiftsIntegration
    ? "Load schedule from 7Shifts"
    : "Copy from previous week";

  return (
    <div>
      {props.week_already_started && !props.has7ShiftsIntegration ?
      <Tooltip title="You cannot copy a schedule from a previous week once the week has already started. Go to a future week to use this feature.">
        <div className="text-[#bdbdbd] w-10 h-10 flex items-center justify-center">
          <EventRepeatIcon
            //color={props.loadedFrom7Shifts ? "success" : "default"}
            />
        </div>
      </Tooltip> :
      <div>
        <Tooltip
          title={
            //props.loadedFrom7Shifts
            //</div>  ? "Current Schedule Was Loaded from 7Shifts"
            //  :
            tooltipTitle
          }
        >
          <IconButton
            onClick={handleOpen}
            disabled={props.week_already_started && !props.has7ShiftsIntegration}
          >
            <EventRepeatIcon
            //color={props.loadedFrom7Shifts ? "success" : "default"}
            />
          </IconButton>
        </Tooltip>
      </div>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {buttonPressed ? (
            // <div className="w-9/12">
            //   <div className="flex flex-col gap-3">
            //     <div className="flex items-center gap-3">
            //       {secondLoadingMessage ? (
            //         <CheckCircleIcon sx={{ color: "#19c22a" }} />
            //       ) : (
            //         <LoadSpinner />
            //       )}
            //       {secondLoadingMessage ? (
            //         <p>Loaded Schedule Successfully</p>
            //       ) : (
            //         <p>Loading Schedule...</p>
            //       )}
            //     </div>
            //     <div
            //       className={`flex items-center gap-3 ${
            //         thirdLoadingMessage && "hidden"
            //       }`}
            //     >
            //       {secondLoadingMessage && <LoadSpinner />}
            //       {secondLoadingMessage && <p>Evaluating Schedule...</p>}
            //     </div>
            //     <div className="flex items-center gap-3">
            //       {thirdLoadingMessage && (
            //         <CheckCircleIcon sx={{ color: "#19c22a" }} />
            //       )}
            //       {thirdLoadingMessage && (
            //         <p>Evaluated Schedule Successfully</p>
            //       )}
            //     </div>
            //     <div
            //       className={`flex items-center gap-3 ${
            //         fourthLoadingMessage && "hidden"
            //       }`}
            //     >
            //       {thirdLoadingMessage && <LoadSpinner />}
            //       {thirdLoadingMessage && <p>Finishing Final Touches...</p>}
            //     </div>
            //     <div className="flex items-center gap-3">
            //       {fourthLoadingMessage && (
            //         <CheckCircleIcon sx={{ color: "#19c22a" }} />
            //       )}
            //       {fourthLoadingMessage && <p>Finished Sucessfully</p>}
            //     </div>
            //   </div>
            // </div>
            <div className="h-[280px]">
              <div className="pb-8 border-b border-slate-200">
                <h2 className="text-slate-900 text-lg">
                  Copying schedule from {start_date.toLocaleDateString("en-us", date_options) +
                        " - " +
                        end_date.toLocaleDateString("en-us", date_options)}
                </h2>
                <p className="text-slate-500 text-sm mt-2">
                  This can take a couple minutes. Thank you for being patient!
                  Please don't click outside of this modal.
                </p>
              </div>
              <div className="mt-8">
                <div className="flex items-center gap-3">
                  {secondLoadingMessage ? (
                    <CheckCircleIcon
                      sx={{ color: "#059669", fontSize: "26px" }}
                    />
                  ) : (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  {secondLoadingMessage ? (
                    <p className="text-emerald-800">
                      Copied Schedule Successfully
                    </p>
                  ) : (
                    <p>Copying Schedule...</p>
                  )}
                </div>
                <div
                  className={`flex items-center gap-3 mt-5 ${
                    thirdLoadingMessage && "hidden"
                  }`}
                >
                  {secondLoadingMessage && (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  {secondLoadingMessage && <p>Evaluating Schedule...</p>}
                </div>
                <div className="flex items-center gap-3 mt-5">
                  {thirdLoadingMessage && (
                    <CheckCircleIcon
                      sx={{ color: "#059669", fontSize: "26px" }}
                    />
                  )}
                  {thirdLoadingMessage && (
                    <p className="text-emerald-800">
                      Evaluated Schedule Successfully
                    </p>
                  )}
                </div>
                <div
                  className={`flex items-center gap-3 mt-5 ${
                    fourthLoadingMessage && "hidden"
                  }`}
                >
                  {thirdLoadingMessage && (
                    <div className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-200 bg-violet-50">
                      <CircularProgress size={14} sx={{ color: "#6d28d9" }} />
                    </div>
                  )}
                  {thirdLoadingMessage && <p>Finishing Final Touches...</p>}
                </div>
                <div className="flex items-center gap-3 mt-5">
                  {fourthLoadingMessage && (
                    <CheckCircleIcon
                      sx={{ color: "#059669", fontSize: "26px" }}
                    />
                  )}
                  {fourthLoadingMessage && (
                    <p className="text-emerald-800">
                      Finished Sucessfully
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full">
              <div className="w-full">
                <div
                  className="absolute right-2 top-2 text-gray-500 cursor-pointer"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </div>
                <h3 className="text-xl text-slate-900">{modalTitle}</h3>
                <p className="text-m text-slate-500 mt-2">
                  Select a schedule week to load in.
                </p>
                <div className="w-full flex items-center mt-7 text-slate-500">
                  <div className="w-10 h-10 bg-slate-50 rounded-full">
                    <IconButton disabled={props.week_already_started} onClick={() => decrementWeek(start_date)}>
                      <ChevronLeftIcon
                      />
                    </IconButton>
                  </div>
                  <div className="flex-1 px-4 py-4 flex justify-center items-center space-x-3 rounded h-12">
                    <div className="text-slate-900 mb-1">
                      <CalendarMonthIcon />
                    </div>
                    <div className="mr-2 text-slate-900">
                      {start_date.toLocaleDateString("en-us", date_options) +
                        " - " +
                        end_date.toLocaleDateString("en-us", date_options)}{" "}
                    </div>
                  </div>
                  <div className="w-10 h-10 bg-slate-50 rounded-full">
                    <IconButton disabled={props.week_already_started} onClick={() => incrementWeek(start_date)}>
                      {
                        <ChevronRightIcon
                        />
                      }
                    </IconButton>
                  </div>
                </div>
                <div className="w-full flex items-center space-x-4 mt-8">
                  <div
                    className="px-5 py-2.5 rounded-full border border-slate-200 text-slate-400 hover:bg-slate-50 flex items-center justify-center cursor-pointer"
                    onClick={handleClose}
                  >
                    <p>Cancel</p>
                  </div>
                  <div className="flex-1">
                    {true ? (
                      <Button
                        fullWidth
                        variant="contained"
                        endIcon={<EventRepeatIcon />}
                        onClick={() => handleStart()}
                        sx={{
                          textTransform: "none",
                          borderRadius: "50px",
                          paddingY: "10px",
                          backgroundColor: "#6d28d9",
                          "&:hover": {
                            backgroundColor: "#5b21b6",
                          },
                        }}
                      >
                        {"Load Schedule"}
                      </Button>
                    ) : (
                      <div className="w-full py-3 rounded-full text-slate-400 text-sm bg-slate-200 flex items-center justify-center gap-x-2">
                        Load Schedule <EventRepeatIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default LoadFromScheduleButton;
