import React from 'react'
import InfoIcon from '@mui/icons-material/Info';

function EmptyState(props) {
  return (
    <div className="mt-12">
      <div className="flex-auto px-6">
        <h1 className="text-3xl text-slate-900">
          {props.title}
        </h1>
      </div>
      <div className="w-full h-72 border-t border-slate-200 mt-12 flex items-center justify-center">
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
            <InfoIcon />
          </div>
          <p className="mt-2 text-sm">{props.description}</p>
        </div>
      </div>
    </div>
  )
}

export default EmptyState
