import React, { useState, useEffect } from "react";
import ButtonFilled from "../requests/shared/helpers/ButtonFilled";
import { getRightworkAPI } from "../requests/utilities/requests";
import { postRightworkAPI } from "../requests/utilities/requests";
import {
  API_get_pay_periods,
  API_get_timecards_for_store,
  API_post_timecard,
  API_delete_timecard,
  API_post_timecards_status,
  API_post_timecard_clock_out,
  API_post_timecard_break_clock_in,
  API_post_timecard_break_clock_out,
  API_get_list_users,
} from "../../../constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TimeClockHeader from "./TimeClockHeader";
import TimeEntriesHeader from "./TimeEntriesHeader";
import {
  formatDate,
  formatDateReadableWithDayOfWeekNoYear,
  formatDateReadableWithDayOfWeek,
} from "../requests/utilities/helpers";
import AlarmIcon from "@mui/icons-material/Alarm";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../requests/shared/helpers/DrawerHeader";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ButtonOutlined from "../requests/shared/helpers/ButtonOutlined";
import DrawerFooterContainerLarge from "../requests/shared/helpers/DrawerFooterContainerLarge";
import ButtonDelete from "../requests/shared/helpers/ButtonDelete";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from "@mui/icons-material/Download";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AddIcon from "@mui/icons-material/Add";
import LoadSpinner from "../../../utilities/LoadSpinner";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TimePickerInput from "./TimePickerInputs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlinkingDot from "./BlinkingDot";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import TimeEntriesSettings from "./TimeEntriesSettings";
import InfoIcon from "@mui/icons-material/Info";

function TimeEntriesDisplay(props) {
  const default_data = {
    clocked_in_at: null,
    clocked_out_at: null,
    clocked_in_at_date_time: new Date(),
    clocked_out_at_date_time: new Date(),
    declared_cash_tips: null,
    published_schedule: null,
    status: null,
    store_team_member: {
      first_name: "",
      last_name: "",
      id: null,
    },
    wage_type: null,
    timecard_breaks: [],
    uuid: null,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  const [showSettings, setShowSettings] = React.useState(false);

  const [payPeriods, setPayPeriods] = useState([]);

  const [timeEntries, setTimeEntries] = useState([]);
  const [selectedPayPeriodDates, setSelectedPayPeriodDates] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);

  const [showTimeEntries, setShowTimeEntries] = useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  // console.log("PAY PERIODS", payPeriods);
  // console.log("TIME ENTRIES", timeEntries);
  // console.log("EMPLOYEELIST", employeeList);

  useEffect(() => {
    setShowTimeEntries(false);
    fetchPayPeriods(true);
    fetchEmployeeList();
  }, [props.homeStore]);

  const fetchPayPeriods = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = { store_id: props.homeStore };
    let api = API_get_pay_periods;
    try {
      const res = await getRightworkAPI(api, api_params);
      console.log(res);
      if (res.status === 200) {
        const data = res.data?.redux?.payload?.pay_periods;
        setPayPeriods(data);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const fetchTimeCardsForStore = async (showLoading, date) => {
    setIsLoading(showLoading);
    let api_params = {
      store_id: props.homeStore,
      date: date,
    };
    let api = API_get_timecards_for_store;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        console.log("TIMECARDS", res.data.redux?.payload?.timecards);
        setTimeEntries(res.data.redux?.payload?.timecards);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const fetchEmployeeList = async () => {
    let api_params = {
      store_id: props.homeStore,
    };
    let api = API_get_list_users;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        setEmployeeList(res.data.redux?.payload?.employees);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const post_timecard = async (
    store_team_member_id,
    clocked_in_at,
    clocked_out_at,
    timecard_breaks,
    declared_cash_tips,
    timecard_uuid
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      store_team_member_id: store_team_member_id,
      clocked_in_at: clocked_in_at,
      clocked_out_at: clocked_out_at,
      timecard_breaks: JSON.stringify(timecard_breaks),
      declared_cash_tips: declared_cash_tips,
      timecard_uuid: timecard_uuid,
    };

    try {
      toast.loading("Saving Time Entry...", { autoClose: false });

      const res = await postRightworkAPI(API_post_timecard, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Time Entry Saved Successfully", { autoClose: 3000 });

        fetchTimeCardsForStore(false, selectedPayPeriodDates[0]);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  // const delete_timecard = async (timecard_uuid) => {
  //   // Prepare data to send
  //   const requestData = {
  //     store_id: props.homeStore,
  //     timecard_uuid: timecard_uuid,
  //   };

  //   try {
  //     toast.loading("Deleting Time Entry...", { autoClose: false });

  //     const res = await postRightworkAPI(API_delete_timecard, requestData);

  //     if (res.status === 200) {
  //       toast.dismiss();
  //       toast.success("Time Entry Deleted Successfully", { autoClose: 3000 });

  //       fetchTimeCardsForStore(false);
  //     }
  //   } catch (error) {
  //     toast.dismiss();

  //     if (error.response?.data?.system?.message) {
  //       toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
  //     } else {
  //       toast.error("Request Failed", { autoClose: 3000 });
  //     }

  //     console.error("Error:", error); // Handle error as needed
  //   }
  // };

  const update_timecard_status = async (timecard_uuids, status) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      timecard_uuids: JSON.stringify(timecard_uuids),
      status: status,
    };

    try {
      toast.loading("Updating Time Entry Status...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_timecards_status,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Time Entry Status Updated Successfully", {
          autoClose: 3000,
        });

        fetchTimeCardsForStore(false, selectedPayPeriodDates[0]);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_time_card_clock_out = async (
    timecard_uuid,
    clocked_out_at,
    stm_id
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      stm_id: stm_id,
      timecard_uuid: timecard_uuid,
      clocked_out_at: clocked_out_at,
    };

    try {
      toast.loading("Clocking Out Employee...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_timecard_clock_out,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Clocked Out Employee Successfully", { autoClose: 3000 });

        fetchTimeCardsForStore(false, selectedPayPeriodDates[0]);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_time_card_break_start = async (
    timecard_uuid,
    clocked_in_at,
    stm_id
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      stm_id: stm_id,
      timecard_uuid: timecard_uuid,
      clocked_in_at: clocked_in_at,
    };

    try {
      toast.loading("Starting Break For Employee...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_timecard_break_clock_in,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Break Started For Employee Successfully", {
          autoClose: 3000,
        });

        fetchTimeCardsForStore(false, selectedPayPeriodDates[0]);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_time_card_break_end = async (
    timecard_break_uuid,
    clocked_out_at,
    stm_id
  ) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      stm_id: stm_id,
      timecard_break_uuid: timecard_break_uuid,
      clocked_out_at: clocked_out_at,
    };

    try {
      toast.loading("Ending Break For Employee...", { autoClose: false });

      const res = await postRightworkAPI(
        API_post_timecard_break_clock_out,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Break Ended For Employee Successfully", {
          autoClose: 3000,
        });

        fetchTimeCardsForStore(false, selectedPayPeriodDates[0]);
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const handleSelectPayPeriod = (beginning_date, end_date) => {
    setShowTimeEntries(true);
    setSelectedPayPeriodDates([beginning_date, end_date]);
    fetchTimeCardsForStore(true, beginning_date);
  };

  const handleGoBack = () => {
    // Deselect the current period and go back to the pay period list
    setShowTimeEntries(false);
  };

  function getHoursAndBreaks(startTime, endTime, breaksArray) {
    // Convert both to milliseconds
    var startTimeMilliseconds = new Date(startTime).getTime();
    var endTimeMilliseconds = new Date(endTime).getTime();

    let sumBreaksMilliseconds = 0;

    breaksArray?.forEach((breakItem) => {
      var startBreakMilliseconds = new Date(breakItem.clocked_in_at).getTime();
      var endBreakMilliseconds = breakItem.clocked_out_at
        ? new Date(breakItem.clocked_out_at).getTime()
        : breakItem.clocked_in_at
        ? new Date().getTime()
        : 0;
      var breakLengthMilliseconds = Math.abs(
        endBreakMilliseconds - startBreakMilliseconds
      );
      sumBreaksMilliseconds += breakLengthMilliseconds;
    });

    var totalTimeWorkedMilliseconds = Math.abs(
      endTimeMilliseconds - startTimeMilliseconds
    );
    var paidTimeWorkedMilliseconds =
      totalTimeWorkedMilliseconds - sumBreaksMilliseconds;

    // Convert milliseconds to hours
    var paidHours = Math.floor(paidTimeWorkedMilliseconds / (1000 * 60 * 60));

    // Calculate remaining milliseconds after subtracting full hours
    var remainingMilliseconds = paidTimeWorkedMilliseconds % (1000 * 60 * 60);

    // Convert remaining milliseconds to minutes
    var paidMinutes = Math.ceil(remainingMilliseconds / (1000 * 60));

    // Calculate break minutes
    var breakMinutes = Math.floor(sumBreaksMilliseconds / (1000 * 60));

    return {
      paidHours: paidHours,
      paidMinutes: paidMinutes,
      breakMinutes: breakMinutes,
    };
  }

  // Enhancing the status lookup to include both label and styling
  const statusStyles = {
    auto_approved: {
      label: "",
      classes: "bg-emerald-50 border border-emerald-300 text-emerald-800",
    },
    auto_flag: {
      label: "Flagged",
      classes: "bg-fuchsia-50 border border-fuchsia-300 text-fuchsia-800",
    },
    approved: {
      label: "Approved",
      classes: "bg-blue-50 border border-blue-300 text-blue-800",
    },
    denied: {
      label: "Denied",
      classes: "bg-rose-50 border border-rose-300 text-red-800",
    },
    pending: {
      label: "Pending",
      classes: "bg-yellow-50 border border-yellow-300 text-yellow-800",
    },
  };

  // Existing states and hooks...
  const [nameFilter, setNameFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // Assuming you've fetched timeEntries, now let's prepare dynamic filter options
  useEffect(() => {
    // This effect could be used to further manipulate timeEntries data if needed
  }, [timeEntries]);

  // Unique names for the Name filter dropdown
  const uniqueNames = [
    ...new Set(
      timeEntries.map(
        (entry) =>
          `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`
      )
    ),
  ];

  const filteredEmployeeList = employeeList.filter(
    (employee) => employee.clock_pin !== null
  );

  //console.log("FILTERED EMPLOYEE LIST", filteredEmployeeList);

  // const uniqueStoreTeamMembers = [];
  // const uniqueIds = new Set();

  // timeEntries.forEach((entry) => {
  //   const memberId = entry.store_team_member.id;
  //   if (!uniqueIds.has(memberId)) {
  //     uniqueIds.add(memberId);
  //     uniqueStoreTeamMembers.push(entry.store_team_member);
  //   }
  // });

  // Unique dates for the Date filter dropdown
  const uniqueDates = [
    ...new Set(
      timeEntries.map((entry) =>
        formatDate(new Date(entry.clocked_in_at).toLocaleDateString())
      )
    ),
  ];

  // Handlers for filter value changes
  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredTimeEntries = timeEntries.filter((entry) => {
    const fullName = `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`;
    const entryDate = formatDate(
      new Date(entry.clocked_in_at).toLocaleDateString()
    );
    // Apply name filter
    const matchesName = nameFilter ? fullName === nameFilter : true;
    // Apply date filter
    const matchesDate = dateFilter ? entryDate === dateFilter : true;
    // Apply status filter
    const matchesStatus = statusFilter ? entry.status === statusFilter : true;

    return matchesName && matchesDate && matchesStatus;
  });

  //console.log("MODAL DATA", modalData);

  const filteredTimeEntriesForApproveAll = filteredTimeEntries.filter(
    (entry) => {
      return entry.status === "pending" || entry.status === "auto_flag";
    }
  );

  let filteredTimeEntriesUUIDs = [];

  filteredTimeEntriesForApproveAll.forEach((timeEntry) =>
    filteredTimeEntriesUUIDs.push(timeEntry.uuid)
  );

  function handleNameChange(event) {
    const selectedStoreTeamMemberId = event.target.value;
    const selectedStoreTeamMember = filteredEmployeeList.find(
      (member) => member.id === selectedStoreTeamMemberId
    );
    setModalData({
      ...modalData,
      store_team_member: selectedStoreTeamMember,
    });
  }

  function handleDateTimeChange(name, value) {
    setModalData((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  }

  function handleBreakTimeChange(event) {
    // Parse clocked_in_at and clocked_out_at into Date objects
    const clockedInAt = modalData.clocked_in_at_date_time;
    const clockedOutAt = modalData.clocked_out_at_date_time;

    // Calculate midpoint time in milliseconds
    const midpoint = (clockedInAt.getTime() + clockedOutAt.getTime()) / 2;

    // Calculate the time difference from the midpoint
    const minutesFromMidpoint = event.target.value / 2;

    // Calculate updated break times
    const updatedBreak = {
      ...modalData.timecard_breaks[0],
      clocked_in_at: new Date(midpoint - minutesFromMidpoint * 60000).getTime(),
      clocked_out_at: new Date(
        midpoint + minutesFromMidpoint * 60000
      ).getTime(),
      is_paid: false,
      uuid: null,
    };

    // Update modal data with the updated break
    setModalData({
      ...modalData,
      timecard_breaks: [updatedBreak],
    });
  }

  function handleTipChange(event) {
    setModalData({
      ...modalData,
      declared_cash_tips: event.target.value,
    });
  }

  function handleSubmit() {
    post_timecard(
      modalData.store_team_member?.id,
      modalData.clocked_in_at_date_time.getTime(),
      modalData.clocked_out_at_date_time.getTime(),
      modalData.timecard_breaks,
      modalData.declared_cash_tips ? modalData.declared_cash_tips : "0",
      modalData.uuid
    );
    handleClose();
  }

  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const csvRows = [];
    // Headers
    const headers = [
      "Name",
      "Date",
      "Timesheet",
      "Paid Hours",
      "Unpaid Breaks",
      "Status",
    ];
    csvRows.push(headers.join(","));

    // Values
    for (const entry of data) {
      const paidHours = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).paidHours;
      const paidMinutes = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).paidMinutes;
      const breakMinutes = getHoursAndBreaks(
        entry.clocked_in_at,
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime(),
        entry.timecard_breaks
      ).breakMinutes;
      entry["clocked_in_at_date_time"] = new Date(entry.clocked_in_at);
      entry["clocked_out_at_date_time"] = new Date(
        entry.clocked_out_at ? entry.clocked_out_at : new Date().getTime()
      );
      entry["status"] = entry.clocked_out_at
        ? entry.status
        : "currently_working";
      entry["sub_status"] =
        !entry.clocked_out_at &&
        (entry.timecard_breaks?.[entry.timecard_breaks?.length - 1]
          ?.clocked_out_at ||
          entry.timecard_breaks?.length === 0)
          ? "Working"
          : !entry.clocked_out_at &&
            !entry.timecard_breaks?.[entry.timecard_breaks?.length - 1]
              ?.clocked_out_at
          ? "On Break"
          : null;

      const values = [
        `${entry.store_team_member.first_name} ${entry.store_team_member.last_name}`,
        `${formatDate(new Date(entry.clocked_in_at).toLocaleDateString())}`,
        `${new Date(entry.clocked_in_at).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${new Date(entry.clocked_out_at).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
        `${paidHours}hrs ${paidMinutes}min`,
        `${breakMinutes}min`,
        `${entry.status}`,
      ];
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  // Function to download CSV file
  const downloadCSV = () => {
    const csvData = convertToCSV(timeEntries);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    const datePayPeriod = `${selectedPayPeriodDates[0]}_to_${selectedPayPeriodDates[1]}`;
    const filename = `time_entries_${datePayPeriod}.csv`; // Add datePayPeriod to filename
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {!isLoading ? (
        !showSettings ?
        <div>
          {showTimeEntries ? (
            <div>
              <div>
                <div>
                  <TimeEntriesHeader
                    title="Back to Pay Periods"
                    storeOptions={props.storeOptions}
                    homeStore={props.homeStore}
                    storeNames={props.storeNames}
                    change_home_store={props.change_home_store}
                    setCurrentTab={props.setCurrentTab}
                    handleGoBack={handleGoBack}
                    showTimeEntries={showTimeEntries}
                    setShowTimeEntries={setShowTimeEntries}
                    showSettings={showSettings}
                    setShowSettings={setShowSettings}
                  />
                </div>
                <div className="px-6 mt-8">
                  <div className="flex justify-between">
                    <div>
                      <h1 className="text-3xl text-slate-900">Time Entries</h1>
                      <h3 className="mt-1 text-slate-500">
                        {`${formatDateReadableWithDayOfWeek(
                          selectedPayPeriodDates[0]
                        )} - ${formatDateReadableWithDayOfWeek(
                          selectedPayPeriodDates[1]
                        )}`}
                      </h3>
                    </div>
                    <div className="flex items-center space-x-3">
                      <Tooltip title="Add new time entry">
                        <div
                          className="h-10 w-10 rounded-full bg-violet-700 hover:bg-violet-800 text-white text-whiten flex items-center justify-center cursor-pointer"
                          onClick={() => {
                            setModalData(default_data);
                            setModalEdit(false);
                            handleOpen();
                          }}
                        >
                          <AddIcon />
                        </div>
                      </Tooltip>
                      {!timeEntries.some((entry) =>
                        ["pending", "currently_working", "auto_flag"].includes(
                          entry.status
                        )
                      ) ? (
                        <Tooltip title="Download all time entries to a .csv file">
                          <div
                            className="px-5 py-2 rounded-full bg-orange-600 hover:bg-orange-700 flex items-center space-x-1 text-white cursor-pointer"
                            onClick={downloadCSV}
                          >
                            <div>
                              <DownloadIcon />
                            </div>
                            <p>Download</p>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Can only download when all time entries are approved or denied">
                          <div className="px-5 py-2 rounded-full bg-slate-200 flex items-center space-x-1 text-slate-400">
                            <div>
                              <DownloadIcon />
                            </div>
                            <p>Download</p>
                          </div>
                        </Tooltip>
                      )}
                      {/* <div className="px-5 py-2 rounded-full bg-emerald-600 hover:bg-emerald-700 flex items-center space-x-1 text-white cursor-pointer">
                        <div>
                          <SendIcon
                            style={{ fontSize: "18px", marginBottom: "2px" }}
                          />
                        </div>
                        <p>Publish</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex items-center space-x-5 mt-12">
                    <div className="w-48">
                      <p className="text-sm text-slate-500">Filter by name</p>
                      <div className="mt-1">
                        <Select
                          fullWidth
                          displayEmpty
                          value={nameFilter}
                          onChange={handleNameFilterChange}
                          size="small"
                          sx={{
                            borderRadius: "8px",
                            paddingY: "1px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                            height: "40px",
                            backgroundColor: "white",
                          }}
                        >
                          <MenuItem value="">All Names</MenuItem>
                          {uniqueNames.map((name, index) => (
                            <MenuItem key={index} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="w-48">
                      <p className="text-sm text-slate-500">Filter by date</p>
                      <div className="mt-1">
                        <Select
                          fullWidth
                          displayEmpty
                          value={dateFilter}
                          onChange={handleDateFilterChange}
                          size="small"
                          sx={{
                            borderRadius: "8px",
                            paddingY: "1px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                            height: "40px",
                            backgroundColor: "white",
                          }}
                        >
                          <MenuItem value="">All Dates</MenuItem>
                          {uniqueDates.map((name, index) => (
                            <MenuItem key={index} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="w-48">
                      <p className="text-sm text-slate-500">Filter by status</p>
                      <div className="mt-1">
                        <Select
                          fullWidth
                          displayEmpty
                          value={statusFilter}
                          onChange={handleStatusFilterChange}
                          size="small"
                          sx={{
                            borderRadius: "8px",
                            paddingY: "1px",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#6d28d9",
                            },
                            height: "40px",
                            backgroundColor: "white",
                          }}
                        >
                          <MenuItem value="">All Statuses</MenuItem>
                          <MenuItem value="approved">Approved</MenuItem>
                          <MenuItem value="denied">Denied</MenuItem>
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="auto_flag">Flagged</MenuItem>
                          <MenuItem value="currently_working">
                            Currently Working
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-slate-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="flex-1 py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Timesheet
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Paid Hours
                              </th>
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Unpaid Breaks
                              </th>

                              {/* <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Cash Tips
                              </th> */}
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="w-1/12 relative py-3.5 pl-3 pr-6 text-xs text-slate-500 font-medium text-right"
                              >
                                <div className="flex justify-end">
                                  <Tooltip title="Approves all filtered entries that are pending or flagged. Doesn't affect entries that are approved, denied, or currently working.">
                                    <div
                                      className="w-28 py-1.5 rounded-full bg-slate-100 hover:bg-emerald-600 text-emerald-800 hover:text-white flex items-center justify-center space-x-1 cursor-pointer"
                                      onClick={() =>
                                        update_timecard_status(
                                          filteredTimeEntriesUUIDs,
                                          "approved"
                                        )
                                      }
                                    >
                                      <div>
                                        <DoneAllIcon
                                          style={{ fontSize: "16px " }}
                                        />
                                      </div>
                                      <p>Approve all</p>
                                    </div>
                                  </Tooltip>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-slate-200 bg-white">
                            {filteredTimeEntries.map((entry, i) => {
                              const paidHours = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).paidHours;
                              const paidMinutes = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).paidMinutes;
                              const breakMinutes = getHoursAndBreaks(
                                entry.clocked_in_at,
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime(),
                                entry.timecard_breaks
                              ).breakMinutes;
                              entry["clocked_in_at_date_time"] = new Date(
                                entry.clocked_in_at
                              );
                              entry["clocked_out_at_date_time"] = new Date(
                                entry.clocked_out_at
                                  ? entry.clocked_out_at
                                  : new Date().getTime()
                              );
                              entry["status"] = entry.clocked_out_at
                                ? entry.status
                                : "currently_working";
                              entry["sub_status"] =
                                !entry.clocked_out_at &&
                                (entry.timecard_breaks?.[
                                  entry.timecard_breaks?.length - 1
                                ]?.clocked_out_at ||
                                  entry.timecard_breaks?.length === 0)
                                  ? "Working"
                                  : !entry.clocked_out_at &&
                                    !entry.timecard_breaks?.[
                                      entry.timecard_breaks?.length - 1
                                    ]?.clocked_out_at
                                  ? "On Break"
                                  : null;
                              return (
                                <tr key={i}>
                                  <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                    <div>
                                      {entry.store_team_member.first_name}{" "}
                                      {entry.store_team_member.last_name}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>
                                      {formatDateReadableWithDayOfWeekNoYear(
                                        formatDate(
                                          new Date(
                                            entry.clocked_in_at
                                          ).toLocaleDateString()
                                        )
                                      )}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    {entry.clocked_out_at ? (
                                      <div>
                                        {new Date(
                                          entry.clocked_in_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}{" "}
                                        -{" "}
                                        {new Date(
                                          entry.clocked_out_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}
                                      </div>
                                    ) : (
                                      <div>
                                        {new Date(
                                          entry.clocked_in_at
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}{" "}
                                        -
                                      </div>
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>{`${paidHours}hrs ${paidMinutes}min`}</div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>{`${breakMinutes}min`}</div>
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    <div>${entry.declared_cash_tips ? entry.declared_cash_tips : "0.00"}</div>
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                    {entry.status !== "currently_working" ? (
                                      <div
                                        className={`inline-block px-3 py-1 rounded-lg text-sm font-medium whitespace-nowrap ${
                                          statusStyles[entry.status]?.classes
                                        }`}
                                      >
                                        {statusStyles[entry.status]?.label ||
                                          ""}
                                      </div>
                                    ) : (
                                      <div
                                        className={`w-fit flex items-center space-x-1.5 px-3 py-1 rounded-lg ${
                                          entry.sub_status === "Working"
                                            ? "bg-violet-50 border border-violet-300 text-violet-800"
                                            : "bg-orange-50 border border-orange-300 text-orange-800"
                                        }`}
                                      >
                                        <div>
                                          <BlinkingDot
                                            sub_status={entry.sub_status}
                                          />
                                        </div>
                                        <div>
                                          <p>{entry.sub_status}</p>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                  <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                                    {entry.status === "approved" ||
                                    entry.status === "auto_approved" ||
                                    entry.status === "denied" ? (
                                      <div className="flex space-x-2">
                                        <Tooltip title="Revert to pending">
                                          <div
                                            className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-100 hover:bg-violet-800 hover:text-white text-violet-700 cursor-pointer"
                                            onClick={() =>
                                              update_timecard_status(
                                                [entry.uuid],
                                                "pending"
                                              )
                                            }
                                          >
                                            <HistoryIcon
                                              style={{ fontSize: "18px" }}
                                            />
                                          </div>
                                        </Tooltip>
                                      </div>
                                    ) : entry.status === "currently_working" ? (
                                      <div>
                                        {entry.sub_status === "On Break" ? (
                                          <div>
                                            <Tooltip
                                              title={`End break for ${entry.store_team_member.first_name}`}
                                            >
                                              <div
                                                className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-100 hover:bg-orange-600 hover:text-white text-orange-600 cursor-pointer"
                                                onClick={() =>
                                                  post_time_card_break_end(
                                                    entry.timecard_breaks?.[
                                                      entry.timecard_breaks
                                                        ?.length - 1
                                                    ]?.uuid,
                                                    Date.now(),
                                                    entry.store_team_member.id
                                                  )
                                                }
                                              >
                                                <PlayCircleOutlineIcon
                                                  style={{
                                                    fontSize: "18px",
                                                  }}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        ) : (
                                          <div className="flex space-x-2">
                                            <Tooltip
                                              title={`Start break for ${entry.store_team_member.first_name}`}
                                            >
                                              <div
                                                className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-100 hover:bg-yellow-600 hover:text-white text-yellow-600 cursor-pointer"
                                                onClick={() =>
                                                  post_time_card_break_start(
                                                    entry.uuid,
                                                    Date.now(),
                                                    entry.store_team_member.id
                                                  )
                                                }
                                              >
                                                <PauseIcon
                                                  style={{
                                                    fontSize: "18px",
                                                  }}
                                                />
                                              </div>
                                            </Tooltip>
                                            <Tooltip
                                              title={`Clock out for ${entry.store_team_member.first_name}`}
                                            >
                                              <div
                                                className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-100 hover:bg-rose-600 hover:text-white text-rose-600 cursor-pointer"
                                                onClick={() =>
                                                  post_time_card_clock_out(
                                                    entry.uuid,
                                                    Date.now(),
                                                    entry.store_team_member.id
                                                  )
                                                }
                                              >
                                                <StopIcon
                                                  style={{
                                                    fontSize: "18px",
                                                  }}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="flex space-x-2">
                                        <Tooltip title="Approve time entry">
                                          <div
                                            className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-100 hover:bg-emerald-600 hover:text-white text-emerald-800 cursor-pointer"
                                            onClick={() =>
                                              update_timecard_status(
                                                [entry.uuid],
                                                "approved"
                                              )
                                            }
                                          >
                                            <CheckIcon
                                              style={{ fontSize: "18px" }}
                                            />
                                          </div>
                                        </Tooltip>
                                        <Tooltip title="Deny time entry">
                                          <div
                                            className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-100 hover:bg-rose-700 hover:text-white text-rose-800 cursor-pointer"
                                            onClick={() =>
                                              update_timecard_status(
                                                [entry.uuid],
                                                "denied"
                                              )
                                            }
                                          >
                                            <ClearIcon
                                              style={{ fontSize: "18px" }}
                                            />
                                          </div>
                                        </Tooltip>
                                        <Tooltip title="Edit time entry">
                                          <div
                                            className="w-8 h-8 rounded-full flex items-center justify-center bg-slate-100 hover:bg-violet-800 hover:text-white text-violet-700 cursor-pointer"
                                            onClick={() => {
                                              setModalData(entry);
                                              setModalEdit(true);
                                              handleOpen();
                                            }}
                                          >
                                            <EditIcon
                                              style={{ fontSize: "14px" }}
                                            />
                                          </div>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="border-b border-slate-200"></div>
                        {filteredTimeEntries.length === 0 && (
                          <div className="w-full mt-24 flex justify-center">
                            <div className="flex flex-col items-center">
                              <div className="text-slate-900">
                                <InfoIcon />
                              </div>
                              <p className="mt-2 text-slate-900">
                                No time entries match this filter criteria
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <TimeEntriesHeader
                  title="Pay Periods"
                  storeOptions={props.storeOptions}
                  homeStore={props.homeStore}
                  storeNames={props.storeNames}
                  change_home_store={props.change_home_store}
                  setCurrentTab={props.setCurrentTab}
                  showSettings={showSettings}
                  setShowSettings={setShowSettings}
                />
              </div>
              <div className="mt-16 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-slate-200">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-[22%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                            >
                              Pay Period Start
                            </th>
                            <th
                              scope="col"
                              className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Pay Period End
                            </th>
                            {/* <th
                              scope="col"
                              className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                            >
                              Status
                            </th> */}
                            <th
                              scope="col"
                              className="w-1/12 relative py-3.5 pl-3 pr-6"
                            >
                              {/* <span className="sr-only">Edit</span> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-200 bg-white">
                          {payPeriods.map((period, i) => (
                            <tr
                              key={i}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                handleSelectPayPeriod(
                                  period.beginning_date,
                                  period.end_date
                                )
                              }
                            >
                              <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                                <div>
                                  {formatDateReadableWithDayOfWeek(
                                    period.beginning_date
                                  )}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                <div>
                                  {formatDateReadableWithDayOfWeek(
                                    period.end_date
                                  )}
                                </div>
                              </td>
                              {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                                In progress
                              </td> */}
                              <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                                <div className="text-slate-500">
                                  <ChevronRightIcon />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="border-b border-slate-200"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div> :
        <div>
          <TimeEntriesSettings
            title={showTimeEntries ? "Back to Time Entries" : "Back to Pay Periods"}
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
            setCurrentTab={props.setCurrentTab}
            handleGoBack={handleGoBack}
            showTimeEntries={showTimeEntries}
            setShowTimeEntries={setShowTimeEntries}
            showSettings={showSettings}
            setShowSettings={setShowSettings}
          />
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
      <Drawer open={open} anchor="right" onClose={handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={modalEdit ? "Edit Time Entry" : "Add Time Entry"}
              onClose={handleClose}
            />
            <div className="w-full px-6">
              <div className="mt-5">
                <p className="text-sm text-slate-500">Name</p>
                <div className="mt-1">
                  <Select
                    fullWidth
                    value={modalData?.store_team_member?.id}
                    onChange={handleNameChange}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "1px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      height: "45px",
                      backgroundColor: "white",
                    }}
                  >
                    {filteredEmployeeList.map((member, index) => (
                      <MenuItem key={index} value={member.id}>
                        {`${member.first_name} ${member.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div>
                <div className="mt-5">
                  <p className="text-sm text-slate-500">Shift Clock In</p>
                  <div className="mt-1">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        // label="Clocked In"
                        views={["month", "day", "hours", "minutes"]}
                        value={modalData.clocked_in_at_date_time}
                        onChange={(newValue) =>
                          handleDateTimeChange(
                            "clocked_in_at_date_time",
                            newValue
                          )
                        }
                        slotProps={{
                          // day: {
                          //   sx: {
                          //     "&.Mui-selected": {
                          //       backgroundColor: "#6d28d9",
                          //     },
                          //   }
                          // },
                          textField: {
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px !important",
                                height: "45px",
                                width: "400px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6d28d9",
                                  },
                                backgroundColor: "white",
                              },
                            },
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="mt-5">
                  <p className="text-sm text-slate-500">Shift Clock Out</p>
                  <div className="mt-1">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        // label="Clocked Out
                        views={["month", "day", "hours", "minutes"]}
                        value={modalData.clocked_out_at_date_time}
                        onChange={(newValue) =>
                          handleDateTimeChange(
                            "clocked_out_at_date_time",
                            newValue
                          )
                        }
                        slotProps={{
                          // day: {
                          //   sx: {
                          //     "&.Mui-selected": {
                          //       backgroundColor: "#6d28d9",
                          //     },
                          //   }
                          // },
                          textField: {
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px !important",
                                height: "45px",
                                width: "400px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#6d28d9",
                                  },
                                backgroundColor: "white",
                              },
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">
                  Total Unpaid Break Time (in minutes)
                </p>
                <div className="mt-1">
                  <TextField
                    placeholder="15"
                    fullWidth
                    variant="outlined"
                    size="small"
                    // type="number"
                    // name="target_column"
                    // value={modalData.labor_driver_sales.target_column}
                    value={
                      getHoursAndBreaks(
                        modalData.clocked_in_at,
                        modalData.clocked_out_at,
                        modalData.timecard_breaks
                      ).breakMinutes
                    }
                    onChange={(event) => {
                      const value = event.target.value;
                      // Check if the value is a valid number
                      if (!isNaN(value)) {
                        handleBreakTimeChange(event);
                      }
                    }}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              {/* <div className="mt-5">
                <p className="text-sm text-slate-500">Cash Tips</p>
                <div className="mt-1">
                  <TextField
                    placeholder="50.75"
                    fullWidth
                    variant="outlined"
                    size="small"
                    // name="target_column"
                    value={modalData.declared_cash_tips}
                    onChange={(event) => {
                      const value = event.target.value;
                      // Check if the value is a valid number
                      if (!isNaN(value)) {
                        handleTipChange(event);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        paddingLeft: "20px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div> */}
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  // disabled={!checkIfValid()}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Changes" : "Save Time Entry"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default TimeEntriesDisplay;
