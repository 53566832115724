import React from 'react';
import Slider from '@mui/material/Slider';

export default function MetricWeightSlider(props) {
  function handleWeightChange(event, newWeight) {
    props.onWeightChange(newWeight);
  }

  return (
    <div className="metric-weight-slider">
      <label htmlFor={props.metricName}>{props.metricName}</label>
      <Slider
        value={props.initialWeight}
        onChange={handleWeightChange}
        min={0}
        max={100}
        step={1}
        valueLabelDisplay="auto"
        aria-labelledby="metric-weight-slider"
      />
    </div>
  );
}
