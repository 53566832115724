import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import { API_resend_welcome_email } from '../../../../../constants';
import { postRightworkAPI } from '../../utilities/requests';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EmployeeNotLoggedInWarningModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 440,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const resend_welcome_email = async (store_id, stm_id) => {
    // Prepare data to send
    const requestData = {
      store_id: store_id,
      stm_id: stm_id
    };

    try {
      toast.loading("Resending Welcome Email...", { autoClose: false });

      const res = await postRightworkAPI(
        API_resend_welcome_email,
        requestData
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Welcome Email Sent Successfully", {
          autoClose: 3000,
        });

      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <div className="text-rose-800">
                <WarningIcon style={{ marginBottom: "1px" }} />
              </div>
              <h3 className="text-2xl text-slate-900">No Log In Warning</h3>
            </div>
            <div>
              <p className="text-slate-500 text-sm mt-4">{`${props.firstName} ${props.lastName} has not logged into Rightwork yet.`}</p>
              <p className="text-slate-500 text-sm mt-4">Would you like to resend the welcome email with their login instructions.</p>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div className="px-8 py-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    resend_welcome_email(props.homeStore, props.stm_id)
                    props.handleClose()
                  }}
                >
                  Resend welcome email
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default EmployeeNotLoggedInWarningModal
