import React from "react";
import DateScheduleTypeGrouping from "./DateScheduleTypeGrouping";
import StoreName from "../../home/top_bar_components/StoreName";
import Account from "../../home/top_bar_components/Account";
import Tooltip from "@mui/material/Tooltip";
import TabsTwo from "../../requests/shared/helpers/TabsTwo";
import ScheduleTypeFilter from "../../home/top_bar_components/ScheduleTypeFilter";
import Notifications from "../../home/top_bar_components/Notifications";

function DailyTopBarDisplayDailyPlan(props) {


  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="w-72">
            <DateScheduleTypeGrouping
              date={props.date}
              increment_day={props.increment_day}
              roleType={props.roleType}
              setRoleType={props.setRoleType}
              roleTypeOptions={props.roleTypeOptions}
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-4">
            <ScheduleTypeFilter
              roleType={props.roleType}
              setRoleType={props.setRoleType}
              roleTypeOptions={props.roleTypeOptions}
            />
          </div>
          <div className="mr-6">
            <StoreName
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          </div>
          <div className="mr-8">
            <Notifications
              homeStore={props.homeStore}
            />
            </div>
          <div className="h-10 border-gray-500 flex items-center justify-center cursor-pointer">
            <Account />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyTopBarDisplayDailyPlan;
