import React from "react";
import TabsThree from "./shared/helpers/TabsThree";
import ShiftPoolRequestsTable from "./manager/shift_pool/ShiftPoolRequestsTable";
import ShiftPoolApprovedTable from "./manager/shift_pool/ShiftPoolApprovedTable";
import ShiftPoolTable from "./shared/shiftswaps/ShiftPoolTable";
import RequestHeader from "./shared/helpers/RequestHeader";

export default function ShiftPoolDisplay(props) {
  const [activeTab, setActiveTab] = React.useState("Requests");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  return (
    <div>
      {/* <RequestHeader title="Shift Pool" /> */}
      <div className="w-1/2 mt-12 px-6">
        <TabsThree
          activeTab={activeTab}
          handleTabButtonPress={handleTabButtonPress}
          tab1="Requests"
          tab2="Acted On"
          tab3="Shift Pool"
        />
      </div>
      <div className="w-full">
        {activeTab === "Requests" ? (
          <ShiftPoolRequestsTable
            shiftPoolData={props.shiftPoolData}
            manager_reject_shift_drop={props.manager_reject_shift_drop}
            manager_approve_shift_pickup={props.manager_approve_shift_pickup}
            manager_shift_pickup_request_response={
              props.manager_shift_pickup_request_response
            }
          />
        ) : activeTab === "Acted On" ? (
          <ShiftPoolApprovedTable
            requestResponsesData={props.requestResponsesData}
            archive_shift_pickup_request={props.archive_shift_pickup_request}
          />
        ) : activeTab === "Shift Pool" ? (
          <ShiftPoolTable
            shiftPoolData={props.shiftPoolData}
            create_pickup_request={props.create_pickup_request}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
