import React from 'react'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getInitials } from '../requests/utilities/helpers';
import { formatTimestamp } from '../../../helpers/helpers';
import Rating from '@mui/material/Rating';
import ImageIcon from '@mui/icons-material/Image';

function CategoryEntry(props) {


  console.log("ENTRY", props.entry)

  return (
    <div className="w-full flex items-center space-x-3">
      <div className="w-full flex space-x-3">
        <div className="w-9 h-9 rounded-full bg-slate-100 text-slate-500 text-sm flex items-center justify-center mt-0.5">
          <p>{getInitials(props.entry.store_team_member.full_name)}</p>
        </div>
        <div className="flex-1">
          {props.categoryType === "star" &&
            <div>
              <Rating name="read-only" value={props.entry.value_float} readOnly />
            </div>
          }
          {props.categoryType === "dollar" && (
            <div>
              <p className="text-slate-900 font-bold text-lg">
                {props.entry.value_float !== null ? `$${parseFloat(props.entry.value_float).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}` : '$0.00'}
              </p>
            </div>
          )}
          {props.categoryType === "percentage" &&
            <div>
              <p className="text-slate-900 font-bold text-lg">{props.entry.value_float}%</p>
            </div>
          }
          {props.categoryType === "number" &&
            <div>
              <p className="text-slate-900 font-bold text-lg">{props.entry.value_float}</p>
            </div>
          }
          <p className="text-slate-900">{props.entry.value_text}</p>
          <p className="text-slate-500 text-sm">{props.entry.store_team_member.full_name} @ {formatTimestamp(props.entry.created_at)}</p>
          {props.entry?.file_uploads?.length > 0 &&
            <div
              className="text-violet-700 hover:text-violet-900 text-sm mt-0.5 w-full cursor-pointer"
              onClick={() => props.get_file_upload(props.entry?.file_uploads?.[0])}
            >
              <span><ImageIcon style={{ fontSize: "18px" }} /></span> 1 Image Attached
            </div>
          }
        </div>
      </div>
      <div
        className="text-slate-500 cursor-pointer"
        onClick={() => props.delete_logbook_category_entry(props.entry.uuid)}
      >
        <DeleteOutlinedIcon style={{ fontSize: "24px" }} />
      </div>
    </div>
  )
}

export default CategoryEntry
