import React from 'react'
import { get_t_str } from '../../../../helpers/helpers'
import ChecklistIcon from '@mui/icons-material/Checklist';
import Tooltip from '@mui/material/Tooltip';
import TaskNameCardModal from './TaskNameCardModal';
import { Lightbox } from "react-modal-image";
import TaskStatusBadge from './TaskStatusBadge';

function TaskNameCard(props) {

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [showLighbox, setShowLightbox] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(null)

  console.log("TASK", props.task)

  return (
    <>
      {showLighbox &&
        <div className="absolute w-screen h-screen z-[9999]">
          <Lightbox
            small={selectedImage}
            medium={selectedImage}
            // large={selectedImage}
            hideDownload={true}
            hideZoom={true}
            onClose={() => setShowLightbox(false)}
          />
        </div>
      }
      <div
        className={`p-3 border border-slate-200 rounded-lg bg-white hover:border hover:border-slate-800 cursor-pointer`}
        onClick={!props.isAssigned ? handleOpenModal : null}
      >
        <div className="flex justify-between space-x-3">
          <div>
            <p className={`text-xs text-slate-900`}>{props.task.name}</p>
            {props.task?.subtasks?.length > 0 &&
              <Tooltip
                title={
                  <React.Fragment>
                    {props.task?.subtasks?.map((subtask) => (
                      <li key={subtask.id}>{subtask.name}</li>
                    ))}
                  </React.Fragment>
                }
              >
                <div className={`mt-1 flex items-center gap-x-1 text-xxs w-fit text-slate-500`}>
                  <div>
                    <ChecklistIcon style={{ fontSize: "16px" }} />
                  </div>
                  <p>{props.task?.subtasks?.length} {props.task?.subtasks?.length === 1 ? "subtask" : "subtasks"}</p>
                </div>
              </Tooltip>
            }
          </div>
          <div>
            <p className={`text-xxs text-slate-900 text-right whitespace-nowrap`}>Complete Between</p>
            <div className="flex justify-end space-x-1 mt-1">
              {(props.task?.lower_bound_qhnum && props.task?.upper_bound_qhnum) ?
                <p className="text-slate-500 text-xxs">
                  {get_t_str(props.task?.lower_bound_qhnum)} - {get_t_str(props.task?.upper_bound_qhnum)}
                </p> :
                <p className="text-slate-500 text-xxs">
                  Anytime
                </p>
              }
            </div>
          </div>
        </div>
        {!props.isAssigned && props.task?.plan_task_assignments?.length > 0 &&
          <div className="mt-1.5">
            <TaskStatusBadge
              status={props.task?.plan_task_assignments?.[0]?.status}
              submitted_at={props.task?.plan_task_assignments?.[0]?.submitted_at_formatted}
            />
          </div>
        }

      </div>
      <TaskNameCardModal
        open={openModal}
        handleClose={handleCloseModal}
        task={props.task}
        cardColor={props.cardColor}
        unassign_plan_task={props.unassign_plan_task}
        setSelectedImage={setSelectedImage}
        setShowLightbox={setShowLightbox}
      />
    </>
  )
}

export default TaskNameCard
