import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const TradeStatusBar = (props) => {
  return (
    <div>
      {props.completion === "trade_rescinded_by_sender_before_approved" ?
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-rose-600 rounded-full flex items-center justify-center text-white">
            <ClearIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div> :
      props.completion === "trade_rescinded_by_sender_after_approved" ?
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-rose-600 rounded-full flex items-center justify-center text-white">
            <ClearIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div> :
      props.completion === "trade_approved_by_employee" ?
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div> :
      props.completion === "trade_denied_by_employee" ?
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-rose-600"></div>
          <div className="w-8 h-8 bg-rose-600 rounded-full flex items-center justify-center text-white">
            <ClearIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div> :
      props.completion === "trade_requested_by_sender" ?
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
          <div className="flex-1 h-1 bg-slate-200"></div>
          <div className="w-8 h-8 rounded-full bg-slate-200"></div>
        </div> :
      props.completion === "trade_approved_by_manager" ?
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
        </div> :
      props.completion === "trade_denied_by_manager" ?
        <div className="w-full flex flex-row items-center mt-2">
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-emerald-600"></div>
          <div className="w-8 h-8 bg-emerald-600 rounded-full flex items-center justify-center text-white">
            <CheckIcon color="white" size={20} />
          </div>
          <div className="flex-1 h-1 bg-rose-600"></div>
          <div className="w-8 h-8 bg-rose-600 rounded-full flex items-center justify-center text-white">
            <ClearIcon color="white" size={20} />
          </div>
        </div> :
        <div></div>
      }
      {props.tradeReceiver ?
        <div className="w-full flex flex-row items-center mt-1">
          <div className="w-1/3">
            <p className="text-slate-500 text-sm">{props.senderName}</p>
            <p className="text-slate-500 text-sm">Requested</p>
          </div>
          <div className="w-1/3 flex flex-col items-center justify-center">
            <p className="text-slate-500 text-sm">Your</p>
            <p className="text-slate-500 text-sm">Response</p>
          </div>
          <div className="w-1/3 flex flex-col items-end justify-center">
            <p className="text-slate-500 text-sm">Manager</p>
            <p className="text-slate-500 text-sm">Response</p>
          </div>
        </div> :
        <div className="w-full flex flex-row items-center mt-1">
          <div className="w-1/3">
            <p className="text-slate-500 text-sm">Your</p>
            <p className="text-slate-500 text-sm">Request</p>
          </div>
          <div className="w-1/3 flex flex-col items-center justify-center">
            <p className="text-slate-500 text-sm">{props.receiverName}</p>
            <p className="text-slate-500 text-sm">Response</p>
          </div>
          <div className="w-1/3 flex flex-col items-end justify-center">
            <p className="text-slate-500 text-sm">Manager</p>
            <p className="text-slate-500 text-sm">Response</p>
          </div>
        </div>
      }
    </div>
  );
};

export default TradeStatusBar;
