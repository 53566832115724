import React from 'react'

export default function Pill(props) {
  return (
    // <div className={`px-2 py-1 rounded-full ${props.status === "approved" ? "bg-green-200" : props.status === "pending" ? "bg-yellow-200" : "bg-red-200"}`}>
    //   <p className={`${props.status === "approved" ? "text-green-700" : props.status === "pending" ? "text-yellow-700" : "text-red-700"}`}>{props.status}</p>
    // </div>
    <div className={`rounded-lg px-2 py-1 ${props.status === "pending" ? "bg-yellow-50 border border-yellow-200" : props.status === "approved" ? "bg-emerald-50 border border-emerald-100" : "bg-rose-50 border border-rose-100"} flex items-center justify-center`}>
      <p className={`${props.status === "pending" ? "text-yellow-800" : props.status === "approved" ? "text-emerald-800" : "text-rose-800"  }`}>{props.status}</p>
    </div>
  )
}
