import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function EmployeeShiftCardUpdatedStatic(props) {

  return (
    <>
      <div className="w-full rounded-2xl bg-white border border-slate-200 px-5 py-5 flex flex-row items-center">
        <div className="flex-1">
          <div>
            <span className="text-slate-600">{props.date}</span>
          </div>
          <div className="flex flex-row items-center mt-1.5">
            <span className="text-slate-900 text-[22px] font-bold">{props.shiftStartNumber}</span>
            <span className="text-slate-600 text-base ml-1">{props.shiftStartLetters}</span>
            <div className="ml-3 text-slate-600">
              <ArrowForwardIcon color="#475569" size={22} />
            </div>
            <span className="text-slate-900 text-[22px] font-bold ml-3">{props.shiftEndNumber}</span>
            <span className="text-slate-600 text-base ml-1">{props.shiftEndLetters}</span>
          </div>
          <div className="mt-1.5">
            <span className="text-slate-600">{props.station ? props.station : props.role ? props.role : props.roleType} @ {props.location}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeShiftCardUpdatedStatic
