import React from "react";
import SettingsHeader from "./SettingsHeader";
import AddIcon from "@mui/icons-material/Add";
import DepartmentSettingsDrawer from "./DepartmentSettingsDrawer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteWarningModal from "./DeleteWarningModal";

function DepartmentSettingsDisplay(props) {
  const default_data = {
    role_type: null,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);


  const [openDeleteWarningModal, setOpenDeleteWarningModal] = React.useState(false);
  const handleOpenDeleteWarningModal = () => setOpenDeleteWarningModal(true);
  const handleCloseDeleteWarningModal = () => setOpenDeleteWarningModal(false);

  const [selectedDepartment, setSelectedDepartment] = React.useState(null)

  return (
    <div>
      {/* <SettingsHeader title="Department Settings" /> */}
      <div className="w-full p-6 flex justify-end">
        <Tooltip title="Add department">
          <button
            className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
            onClick={() => {
              setModalData(default_data);
              setModalEdit(false);
              handleOpen();
            }}
          >
            <AddIcon style={{ fontSize: "22px" }} />
          </button>
        </Tooltip>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-slate-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-[25%] py-3.5 pl-6 pr-3 text-left text-xs text-slate-500 font-medium"
                    >
                      Department
                    </th>
                    <th
                      scope="col"
                      className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                    >
                      Roles
                    </th>
                    <th
                      scope="col"
                      className="w-1/12 relative py-3.5 pl-3 pr-6"
                    >
                      {/* <span className="sr-only">Edit</span> */}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-slate-200 bg-white">
                  {props.departments?.map((department, i) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap py-5 pl-6 pr-3 text-sm">
                        <div>{department.role_type}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                        <div className="w-full flex flex-wrap items-center gap-2">
                          {Array.isArray(props.roles) &&
                            props.roles
                              .filter(
                                (r, i) => r.role_type === department.role_type
                              )
                              .map((role, i) => (
                                <div className="bg-violet-100 text-violet-700 rounded px-3 py-1">
                                  {role.role_name}
                                </div>
                              ))}
                        </div>
                      </td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium flex flex-row items-center gap-5 justify-end">
                        {props.permissionRole !== "company_manager" &&
                          <div className="flex items-center justify-end w-32 space-x-3">
                            <Tooltip title="Edit">
                              <IconButton
                                className="text-slate-500 ml-4"
                                sx={{ backgroundColor: "#f8fafc" }}
                                onClick={() => {
                                  setModalData(department);
                                  setModalEdit(true);
                                  handleOpen();
                                }}
                              >
                                <EditOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                className="text-slate-500"
                                sx={{ backgroundColor: "#f8fafc" }}
                                onClick={() => {
                                  handleOpenDeleteWarningModal()
                                  setSelectedDepartment(department)
                                }}
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="border-b border-slate-200"></div>
            </div>
          </div>
        </div>
      </div>
      <DepartmentSettingsDrawer
        open={open}
        onClose={handleClose}
        modalData={modalData}
        modalEdit={modalEdit}
        edit_department={props.edit_department}
        isSavingData={props.isSavingData}
        store_name={props.store_name}
      />
      <DeleteWarningModal
        type="department"
        open={openDeleteWarningModal}
        handleClose={handleCloseDeleteWarningModal}
        delete_function={props.edit_department}
        selectedItem={selectedDepartment}
      />
    </div>
  );
}

export default DepartmentSettingsDisplay;
