import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  color: "black",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#eeeeee",
    border: "2px solid #1976D2",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 8,
    background: "unset",
    padding: 0,
    width: 40,
    height: 40,
    color: "black",
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#eeeeee",
    border: "1px solid #1976D2",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-225deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -10%) rotate(-225deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(225deg)",
    },
  },
});

export default function ShiftInputsSlider(props) {
  function qh_to_slider(number) {
    return Number(
      (
        ((number - props.shift_min) * (100 - 0)) /
          (props.shift_max - props.shift_min) +
        0
      ).toFixed(2)
    );
  }

  function slider_to_qh(number) {
    return Number(
      (
        ((number - 0) * (props.shift_max - props.shift_min)) / (100 - 0) +
        props.shift_min
      ).toFixed(2)
    );
  }

  const minDistance =
    (props.minGap * 100) / (props.shift_max - props.shift_min);
  const maxDistance =
    (props.maxGap * 100) / (props.shift_max - props.shift_min);

  const [value1, setValue1] = React.useState([
    qh_to_slider(props.shift_start),
    qh_to_slider(props.shift_end),
  ]);

  React.useEffect(() => {
    setValue1([qh_to_slider(props.shift_start), qh_to_slider(props.shift_end)]);
  }, [props.shift_start, props.shift_end]);

  function valueLabelFormat(value) {
    return props.get_t_str(slider_to_qh(value));
  }

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue1([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue1([clamped - minDistance, clamped]);
      }
    } else if (newValue[1] - newValue[0] > maxDistance) {
      if (activeThumb === 0) {
        const clamped = newValue[0];
        setValue1([clamped, clamped + maxDistance]);
      } else {
        const clamped = newValue[1];
        setValue1([clamped - maxDistance, clamped]);
      }
    } else {
      setValue1(newValue);
    }
  };

  const handleCommit = () => {
    props.handleSliderChange(slider_to_qh(value1[0]), slider_to_qh(value1[1]));
  };

  return (
    <Box sx={{ width: 300 }}>
      <Typography id="input-slider" gutterBottom>
        {props.title}
      </Typography>
      <PrettoSlider
        getAriaLabel={() => "Minimum distance"}
        value={value1}
        step={(0.25 * 100) / (props.shift_max - props.shift_min)}
        onChange={handleChange1}
        onChangeCommitted={handleCommit}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="on"
      />
    </Box>
  );
}
